import {CBusNameWithTime, CPhoneNumber} from './Bus'
import {CDate} from './Date'
import {Optional} from '../type/Common'
import {CDateTime} from './DateTime'
import {OccupancyTypeEnum} from '../enum/OccupancyTypeEnum'
import {WeekdayEnum} from '../enum/WeekdayEnum'
import {DispatchTypeEnum} from '../enum/DispatchTypeEnum'

export class CDispatch {
  private constructor(
    readonly code: string,
    readonly name: string,
    readonly startDate: CDate,
    readonly endDate: CDate,
  ) {}

  static create(json: any): CDispatch {
    return new CDispatch(
      json.code,
      json.name,
      CDate.create(json.startDate),
      CDate.create(json.endDate),
    )
  }
}

export class CSeat {
  private constructor(
    readonly occupyCount: number,
    readonly validCount: number,
  ) {}

  static create(json: any): CSeat {
    return new CSeat(json.occupyCount, json.validCount)
  }
}

export class CDispatchWithSeatOccupancy {
  private constructor(
    readonly bus: CBusNameWithTime,
    readonly dispatch: CDispatch,
    readonly seat: CSeat,
  ) {}

  static create(json: any) {
    return new CDispatchWithSeatOccupancy(
      CBusNameWithTime.create(json.bus),
      CDispatch.create(json.dispatch),
      CSeat.create(json.seat),
    )
  }
}

export class CStudent {
  private constructor(
    readonly boardSwitch: boolean,
    readonly id: number,
    readonly name: string,
    readonly nickName: Optional<string>,
    readonly parentPhone: string,
    readonly pickUpStationId: number,
    readonly takeOffStationId: number,
    readonly tickets: Optional<string[]>,
    readonly timeOffBoard: boolean,
    readonly timeOnBoard: boolean,
  ) {}

  static create(json: any) {
    return new CStudent(
      json.boardSwitch,
      json.id,
      json.name,
      json.nickName,
      json.parentPhone,
      json.pickUpStationId,
      json.takeOffStationId,
      json.tickets,
      json.timeOffBoard,
      json.timeOnBoard,
    )
  }
}

export class CStation {
  private constructor(
    readonly id: number,
    readonly lat: number,
    readonly lng: number,
    readonly name: string,
    readonly sort: Optional<number>,
    readonly students: Optional<CStudent[]>,
    readonly time: Optional<string>,
  ) {}

  static create(json: any) {
    return new CStation(
      json.id,
      json.lat,
      json.lng,
      json.name,
      json.sort,
      json.students,
      json.time,
    )
  }
}

export class CDispatchCourse {
  private constructor(readonly station: CStation) {}

  static create(json: any) {
    return new CDispatchCourse(json.station)
  }
}

export class CDriver {
  private constructor(
    readonly name: string,
    readonly phone: CPhoneNumber,
  ) {}

  static create(json: any) {
    return new CDriver(json.name, CPhoneNumber.create(json.phone))
  }
}

export class CManager {
  private constructor(
    readonly name: string,
    readonly phone: CPhoneNumber,
  ) {}

  static create(json: any) {
    return new CManager(json.name, CPhoneNumber.create(json.phone))
  }
}

export class CNotice {
  private constructor(
    readonly message: string,
    readonly noticeDate: CDate,
  ) {}

  static create(json: any) {
    return new CNotice(json.message, CDate.create(json.noticeDate))
  }
}

export class CDispatchDetail {
  private constructor(
    readonly courses: CDispatchCourse[],
    readonly dispatchCode: string,
    readonly drivers: CDriver[],
    readonly manager: CManager,
    readonly notices: CNotice[],
  ) {}

  static create(json: any) {
    return new CDispatchDetail(
      json.courses.map((c: any) => CDispatchCourse.create(c)),
      json.dispatchCode,
      json.drivers.map((d: any) => CDriver.create(d)),
      CManager.create(json.manager),
      json.notices.map((n: any) => CNotice.create(n)),
    )
  }
}

export class CSeatOccupancyRate {
  private constructor(
    readonly ticketDates: CDateTime[],
    readonly totalSeat: number,
    readonly occupySeat: number,
  ) {}

  static create(json: any) {
    return new CSeatOccupancyRate(
      json.ticketDates.map((el: any) => CDateTime.create(el)),
      json.totalSeat,
      json.occupySeat,
    )
  }
}

export class COverBooking {
  private constructor(
    readonly year: string,
    readonly seatOccupancyRates: CSeatOccupancyRate[],
  ) {}

  static create(json: any) {
    return new COverBooking(
      json.year,
      json.seatOccupancyRates.map((so: any) => CSeatOccupancyRate.create(so)),
    )
  }
}

export class CStudentWithCheck {
  private constructor(
    readonly check: boolean,
    readonly id: number,
    readonly name: string,
    readonly nickName: string,
    readonly parentPhone: string,
    readonly studentPhone: string,
  ) {}

  static create(json: any) {
    return new CStudentWithCheck(
      json.check,
      json.id,
      json.name,
      json.nickName,
      json.parentPhone,
      json.studentPhone,
    )
  }
}

export class CDispatchCourseWithStudent {
  private constructor(
    readonly arrivalTime: string,
    readonly sort: number,
    readonly stationID: number,
    readonly stationName: string,
    readonly students: CStudentWithCheck[],
  ) {}

  static create(json: any): CDispatchCourseWithStudent {
    return new CDispatchCourseWithStudent(
      json.arrivalTime,
      json.sort,
      json.stationId,
      json.stationName,
      json.students.map((s: any) => CStudentWithCheck.create(s)),
    )
  }
}

export class CDispatchDetailDate {
  private constructor(
    readonly date: CDate,
    readonly seatCount: number,
    readonly totalSeat: number,
  ) {}

  static create(json: any) {
    return new CDispatchDetailDate(
      CDate.create(json.date),
      json.seatCount,
      json.totalSeat,
    )
  }
}

export class CDispatchDetailWithCodeStation {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly sort: number,
    readonly time: string,
    readonly lat: number,
    readonly lng: number,
  ) {}

  static create(json: any) {
    return new CDispatchDetailWithCodeStation(
      json.id,
      json.name,
      json.sort,
      json.time,
      json.lat,
      json.lng,
    )
  }
}

export class CDispatchDetailDispatch {
  private constructor(
    readonly station: CDispatchDetailWithCodeStation,
    readonly students: CStudentWithCheck[],
  ) {}

  static create(json: any) {
    return new CDispatchDetailDispatch(
      CDispatchDetailWithCodeStation.create(json.station),
      json.students.map((el: any) => CStudentWithCheck.create(el)),
    )
  }
}

export class CDispatchDetailWithCode {
  private constructor(
    readonly type: OccupancyTypeEnum,
    readonly count: number,
    readonly dates: CDispatchDetailDate[],
    readonly dispatches: CDispatchDetailDispatch[],
  ) {}

  static create(json: any) {
    return new CDispatchDetailWithCode(
      json.type,
      json.count,
      json.dates.map((d: any) => CDispatchDetailDate.create(d)),
      json.dispatches.map((ds: any) => CDispatchDetailDispatch.create(ds)),
    )
  }
}

export class CDispatchWeekdayWithCode {
  private constructor(
    readonly dispatchCode: string,
    readonly weekday: WeekdayEnum,
  ) {}

  static create(json: any) {
    return new CDispatchWeekdayWithCode(
      json.dispatchCode,
      WeekdayEnum.parseValueToWeekday(json.weekday),
    )
  }
}

export class CDispatchCirculationTime {
  private constructor(
    readonly dispatchCode: string,
    readonly dispatchTime: string,
    readonly dispatchType: DispatchTypeEnum,
  ) {}

  static create(json: any) {
    return new CDispatchCirculationTime(
      json.dispatchCode,
      json.dispatchTime,
      DispatchTypeEnum.parse(json.dispatchType),
    )
  }
}
