import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import ToolTipVector from '../../../asset/image/tooltip_arrow.svg'
import {color} from '../../../style/CommonStyle'
import {CStudent} from '../../../model/Dispatch'
import {Optional} from '../../../type/Common'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'

type OnBoardProps = {
  stationId: number
  studentId?: Optional<number>
  students: CStudent[]
}

export const OnBoard: React.FC<OnBoardProps> = (props: OnBoardProps) => {
  const [showPeriod, setShowPeriod] = useState<boolean>(false)
  const [studentName, setStudentName] = useState<string>('')

  const hoverStudentName = (name: string) => {
    setShowPeriod(true)
    setStudentName(name)
  }

  const leaveStudentName = () => {
    setShowPeriod(false)
    setStudentName('')
  }

  if (isEmptyArray(props.students)) {
    return null
  }

  return (
    <BoardBox>
      <BoardTitle>
        <span>승차</span>
      </BoardTitle>
      <StudentList>
        {props.students.map((student: CStudent, idx: number) => {
          return (
            <div key={`${student.id}-${idx}`}>
              <Student
                thisStudentId={student.id}
                studentId={props.studentId}
                onMouseEnter={() => hoverStudentName(student.name)}
                onMouseLeave={leaveStudentName}>
                <Name>
                  {student.nickName === '' || student?.nickName === '-'
                    ? ''
                    : `${student.nickName} / `}
                  {`${student?.name} ${
                    !isNil(student.parentPhone)
                      ? ` (${student.parentPhone?.slice(-4)})`
                      : ''
                  }`}
                </Name>
                <div style={{position: 'relative'}}>
                  {showPeriod && studentName === student.name && (
                    <>
                      <ToolTipBoxVector />
                      <ToolTip>{`${student.tickets[0]} 부터 승차합니다.`}</ToolTip>
                    </>
                  )}
                </div>
              </Student>
            </div>
          )
        })}
      </StudentList>
    </BoardBox>
  )
}

export const BoardBox = styled.div`
  display: flex;
`

export const Name = styled.div`
  color: ${color.black};
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-weight: 600;
`

export const BoardTitle = styled.div`
  margin-right: 1.2rem;
  min-width: 3.6rem;
  max-width: 3.6rem;
  height: 1.8rem;
  padding: 0.4rem 0.7rem 0.4rem 0.9rem;
  background-color: ${color.green1};
  border-radius: 3rem;
  display: flex;
  align-items: center;

  span {
    min-width: 2rem;
    line-height: 1.8rem;
    display: inline-flex;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
  }
`
export const StudentList = styled.ul`
  width: 100%;
  margin-bottom: 1rem;
  font-weight: 600;
`

const ToolTip = styled.div`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 1rem; */
  /* flex-direction: column; */
  position: absolute;
  width: 16.3rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
  background: #333;
  top: 0.8rem;
  left: -3.5rem;
  font-size: 1.1rem;
  z-index: 9;
`

const ToolTipBoxVector = styled.div`
  position: absolute;
  top: 0.3rem;
  left: 4rem;
  width: 0.8rem;
  height: 0.5rem;
  background-image: url(${ToolTipVector});
`

interface StudentProps {
  thisStudentId: number | undefined
  studentId: number | undefined
}

export const Student = styled.div<StudentProps>`
  line-height: 1.8rem;
  margin-bottom: 0.6rem;

  ${({studentId, thisStudentId}) =>
    studentId &&
    Number(thisStudentId) !== studentId &&
    css`
      color: #dedede;

      a,
      span {
        color: #dedede !important;
      }
    `};

  p {
    display: block;
    line-height: 1.8;
  }

  &:hover {
    cursor: pointer;
  }
`
