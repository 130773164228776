import {createRef, RefObject, useRef} from 'react'
import {isNil} from '../util/ValidationUtil'

export type SecureRef<T> = {
  readonly ref: RefObject<T>
  current(): T
  isSecure(): boolean
}

export default function useSecureRef<T>(name: string): SecureRef<T> {
  const ref = useRef<T>(null)

  return {
    ref: ref,
    isSecure(): boolean {
      return !isNil(ref.current)
    },
    current(): T {
      if (!isNil(ref.current)) {
        return ref.current
      }

      const error = new Error(`${name} is nil.`)
      alert(error.message)
      throw error
    },
  }
}

export function createSecureRef<T>(name: string): SecureRef<T> {
  const ref = createRef<T>()

  return {
    ref: ref,
    isSecure(): boolean {
      return !isNil(ref.current)
    },
    current(): T {
      if (!isNil(ref.current)) {
        return ref.current
      }

      const error = new Error(`${name} is nil.`)
      alert(error.message)
      throw error
    },
  }
}
