import React, {useCallback} from 'react'
import BusInformation from './BusInfo'
import CourseInfo from '../common/CourseInfo'
import ManagerInfo from '../common/ManagerInfo'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import {CCourse} from '../../../../../../../model/Course'
import {Optional} from '../../../../../../../type/Common'
import {CManager} from '../../../../../../../model/Manager'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import CourseSelect from '../../../add/operation/course/CourseSelect'
import BusInfoExtra from './BusInfoExtra'
import CourseInfoExtra from './CourseInfoExtra'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {isNil} from '../../../../../../../util/ValidationUtil'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'

type Props = {
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusName: Optional<string>
  manager: Optional<CManager>
  dispatchType: DispatchTypeEnum
  extraManager: Optional<CManager>

  showAddExtraBus(): void
  onSubmitCourse(c: CCourse): void
  onChangeManager(m: CManager): void
  onChangeExtraManager(m: CManager): void
  onDeleteExtraCourseSummary(): void

  dispatchPeriod: CDispatchPeriodInfo
}

export default function OperationInfoInputContainer(props: Props) {
  const selectCourseModalRef = useSecureRef<ModalRef>(
    '[OperationInfoInputContainer.tsx] selectCourseModalRef',
  )

  const showSelectStationModal = useCallback(() => {
    selectCourseModalRef.current().show()
  }, [])

  const hideSelectStationModal = useCallback(() => {
    selectCourseModalRef.current().hide()
  }, [])

  const onSubmitCourse = useCallback(
    (c: CCourse) => {
      props.onSubmitCourse(c)
      hideSelectStationModal()
    },
    [hideSelectStationModal, props.onSubmitCourse],
  )

  if (!isNil(props.extraCourse)) {
    return (
      <ColumnContainer>
        <Container>
          <BusInformation busName={props.busName} />
          <CourseInfoExtra
            courseName={props.course.name}
            onClickEdit={props.showAddExtraBus}
          />
          <ManagerInfo
            selectedManager={props.manager}
            onChange={props.onChangeManager}
            isExtra={true}
          />
        </Container>
        <Container>
          <BusInfoExtra
            busName={props.extraBusName}
            onClickEdit={props.showAddExtraBus}
            onClickDelete={props.onDeleteExtraCourseSummary}
          />
          <CourseInfoExtra
            courseName={props.extraCourse.name}
            onClickEdit={props.showAddExtraBus}
          />
          <ManagerInfo
            selectedManager={props.extraManager}
            onChange={props.onChangeExtraManager}
            isExtra={true}
          />
        </Container>
      </ColumnContainer>
    )
  }

  return (
    <Container>
      <Modal zIndex={3} ref={selectCourseModalRef.ref}>
        <CourseSelect
          onSubmit={onSubmitCourse}
          hide={hideSelectStationModal}
          selectedCourse={props.course}
          dispatchType={props.dispatchType}
          dispatchPeriod={props.dispatchPeriod}
        />
      </Modal>
      <BusInformation busName={props.busName} />
      <CourseInfo
        course={props.course}
        onClickCourse={showSelectStationModal}
      />
      <ManagerInfo
        selectedManager={props.manager}
        onChange={props.onChangeManager}
        isExtra={true}
      />
    </Container>
  )
}

const ColumnContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`
