import React from 'react'
import {CCourse} from '../../../../../../model/Course'
import DispatchInfoNormal from './normal/DispatchInfoNormal'
import {CDispatchCourseWithStudent} from '../../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import {Optional} from '../../../../../../type/Common'
import DispatchInfoExtra from './full/DispatchInfoExtra'
import {isNil} from '../../../../../../util/ValidationUtil'
import {CDispatchPeriodInfo} from '../../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../../table/PeriodTable'

type Props = {
  dispatchCourses: CDispatchCourseWithStudent[]
  dispatchCode: string
  type: OccupancyTypeEnum
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusName: Optional<string>
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
}

export default function DispatchInfo(props: Props) {
  if (!isNil(props.extraCourse)) {
    return (
      <DispatchInfoExtra
        dispatchCode={props.dispatchCode}
        occupancyType={props.type}
        dispatchType={props.course?.dispatchType}
        course={props.course}
        extraCourse={props.extraCourse}
        busName={props.busName}
        extraBusName={props.extraBusName}
        defaultDispatchCourses={props.dispatchCourses}
        dispatchPeriod={props.dispatchPeriod}
      />
    )
  }

  return (
    <DispatchInfoNormal
      defaultDispatchCourses={props.dispatchCourses}
      dispatchCode={props.dispatchCode}
      occupancyType={props.type}
      selectedCourse={props.course}
      dispatchPeriod={props.dispatchPeriod}
      selectedBusInfo={props.selectedBusInfo}
    />
  )
}
