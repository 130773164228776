import React, {ForwardedRef, forwardRef, ReactElement} from 'react'
import styled, {css} from 'styled-components'
import DepartureStationIcon from '../../../asset/image/departure_station.svg'
import ArrivalStationIcon from '../../../asset/image/arrival_station.svg'
import {color} from '../../../style/CommonStyle'
import BusIcon from '../../../asset/image/bus_icon.svg'
import {CBusDetail, CBusOperationSchedule} from '../../../model/Bus'
import {CDate} from '../../../model/Date'
import OperationDetailViewBoarding from './OperationDetailViewBoarding'

type OperationDetailViewProps = {
  date: CDate
  details: CBusDetail[]
  schedules: CBusOperationSchedule[]
  handleBoardingButton: (
    name: string,
    status: number,
    scheduleId: number,
  ) => void
}
export type OperationDetailViewRef = {}

function OperationDetailViewBase(
  props: OperationDetailViewProps,
  _: ForwardedRef<OperationDetailViewRef>,
): ReactElement {
  return (
    <StationUl>
      {props.details.map((d, idx) => {
        const pickupSchedules = props.schedules.filter(
          s => s.pickUpStationId === d.station.id,
        )
        const takeOffSchedules = props.schedules.filter(
          s => s.takeOffStationId === d.station.id,
        )

        return (
          <StationLi style={{display: 'flex'}} key={idx}>
            <TimeWrapper>{d.arriveTargetTime}</TimeWrapper>
            <StationBox
              order={idx}
              courseCnt={props.details.length}
              position={d.position}>
              <StationNameWrapper>{d.station.name}</StationNameWrapper>
              <OperationDetailViewBoarding
                isBoarding={true}
                date={props.date}
                time={d.arriveTargetTime}
                schedules={pickupSchedules}
                startTime={props.details[0].arriveTargetTime}
                handleBoardingButton={props.handleBoardingButton}
              />
              <OperationDetailViewBoarding
                isBoarding={false}
                date={props.date}
                time={d.arriveTargetTime}
                schedules={takeOffSchedules}
                startTime={props.details[0].arriveTargetTime}
              />
            </StationBox>
          </StationLi>
        )
      })}
    </StationUl>
  )
}

const OperationDetailView = forwardRef(OperationDetailViewBase)
export default OperationDetailView

const StationUl = styled.ul`
  padding: 6rem 3.2rem 3rem;
  overflow-y: scroll;
`

const StationLi = styled.li`
  min-height: 11rem;
`

const TimeWrapper = styled.div`
  width: 10rem;
  font-size: 1.4rem;
`

const StationNameWrapper = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 1.6rem;

  :hover {
    font-weight: 700;
    cursor: pointer;
  }
`

type StationBoxProps = {
  order: number
  courseCnt: number
  position: string
}
const StationBox = styled.div<StationBoxProps>`
  padding: 0 0 3.5rem 3rem;
  flex-basis: 80%;
  ${({order, courseCnt}) =>
    order + 1 === courseCnt
      ? css`
          border-left: none;
        `
      : order !== courseCnt
        ? css`
            border-left: 0.2rem solid #000;
            position: absolute;
            top: 0;
          `
        : ''};
  position: relative;

  ::before {
    ${({order, courseCnt}) =>
      order === 0
        ? css`
            content: '${order + 1}';
            background-color: #ffcd00;
            border: 2px solid ${color.black};
            /* content: url(${DepartureStationIcon}); */
            /* margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 5rem; */
          `
        : order + 1 === courseCnt
          ? css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
              /* content: url(${ArrivalStationIcon}); */
              /* margin-left: 0.4rem;
              margin-top: 0.3rem; */
            `
          : css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
            `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 3rem;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    left: -1.2rem;
    top: -0.2rem;
    line-height: 1.1;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  ::after {
    content: '';
    position: absolute;
    display: ${({position}) => (position === 'EXISTS' ? 'block' : 'none')};
    width: 3.1rem;
    height: 3.1rem;
    background: url(${BusIcon}) center center no-repeat;
    left: -1.5rem;
    z-index: 1;
    top: ${({order, courseCnt}) =>
      order === 0 || order === courseCnt - 1 ? '-0.8rem' : '3.5rem'};
  }

  #empty {
    margin-left: 5rem;
  }
`
