import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import styled, {keyframes} from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

// Props 타입 정의 추가
type LoadingProps = {
  className?: string // className prop 추가
}

const Overlay = styled.div<{visible: boolean; className?: string}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 16px 0 0 16px;
  ${props =>
    props.className &&
    `
    &.${props.className} {
      border-radius: 0;
    }
  `}
`

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top-color: #666;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`

export type LoadingRef = {
  show(): void
  hide(): void
}

// Props 타입을 LoadingProps로 변경
const LoadingBase = (props: LoadingProps, ref: ForwardedRef<LoadingRef>) => {
  const [visible, setVisible] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true)
    },
    hide: () => {
      setVisible(false)
    },
  }))

  return (
    <Overlay visible={visible} className={props.className}>
      <Loader />
    </Overlay>
  )
}

const Loading = forwardRef(LoadingBase)
export default Loading
