import {CPaging} from './Paging'
import {isEmptyString} from '../util/ValidationUtil'

export class CManager {
  private constructor(
    readonly createdAt: string,
    readonly id: number,
    readonly name: string,
    readonly phone: string,
    readonly userId: string,
  ) {}

  static create(json: any) {
    return new CManager(
      json.createdAt,
      json.id,
      json.name,
      json.phone,
      json.userId,
    )
  }

  isEmpty() {
    return isEmptyString(this.name) && isEmptyString(this.phone)
  }
}

export class CManagerVector {
  private constructor(
    readonly managers: CManager[],
    readonly paging: CPaging,
  ) {}

  static create(json: any) {
    return new CManagerVector(
      json.managers.map((m: any) => CManager.create(m)),
      CPaging.create(json.paging),
    )
  }
}

export class CManagerDispatches {
  private constructor(
    readonly busName: string,
    readonly dispatchName: string,
    readonly managerName: string,
    readonly remainingSeatCount: number,
    readonly willBoardCount: number,
    readonly wontBoardCount: number,
  ) {}

  static create(json: any) {
    return new CManagerDispatches(
      json.busName,
      json.dispatchName,
      json.managerName,
      json.remainingSeatCount,
      json.willBoardCount,
      json.wontBoardCount,
    )
  }
}
