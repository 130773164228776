import {DispatchTypeEnum} from '../enum/DispatchTypeEnum'
import {CPaging} from './Paging'
import {Optional} from '../type/Common'
import {CourseRouteWithCheck} from '../component/busDetail/dispatchInfo/period/edit/operationInfo/full/extraBus/ExtraBusAdd'
import {BusInfo} from '../component/busDetail/dispatchInfo/period/edit/DispatchEdit'
import {isEmptyArray, isEmptyString, isNil} from '../util/ValidationUtil'

export class CCourse {
  private constructor(
    readonly code: string,
    readonly id: number,
    readonly name: string,
    readonly dispatchType: Optional<DispatchTypeEnum>,
  ) {}

  static create(json: any) {
    return new CCourse(
      json.code,
      json.id,
      json.name,
      isEmptyString(json.type) ? null : DispatchTypeEnum.parse(json.type),
    )
  }
}

export class CCourseVector {
  private constructor(
    readonly courses: CCourse[],
    readonly paging: CPaging,
  ) {}

  static create(json: any) {
    return new CCourseVector(
      json.course.map((c: any) => CCourse.create(c)),
      CPaging.create(json.paging),
    )
  }
}

export class CCourseSummary {
  static empty = new CCourseSummary(null, null, null, null, [])

  private constructor(
    readonly id: Optional<number>,
    readonly code: Optional<string>,
    readonly name: Optional<string>,
    readonly busInfo: BusInfo,
    readonly courseRoutes: CourseRouteWithCheck[],
  ) {}

  static create(json: any) {
    return new CCourseSummary(
      json.id,
      json.code,
      json.name,
      json.busInfo,
      json.courseRoutes,
    )
  }

  isEmpty() {
    return (
      isNil(this.id) &&
      isNil(this.code) &&
      isNil(this.name) &&
      isNil(this.busInfo) &&
      isEmptyArray(this.courseRoutes)
    )
  }
}
