import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  color,
  flexColumn,
  flexRow,
  ModalButton,
} from '../../../style/CommonStyle'
import RangeDatePicker, {DatePickerRef} from '../../input/RangeDatePicker'
import useSecureRef from '../../../hook/useSecureRef'
import {CDate} from '../../../model/Date'
import {CDispatchDetail, COverBooking} from '../../../model/Dispatch'
import {Optional} from '../../../type/Common'
import {WeekdayEnum} from '../../../enum/WeekdayEnum'
import RegisterStation, {RegisterStationRef} from './RegisterStation'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {
  postStudentSchedules,
  postStudentSchedulesExists,
  PostStudentSchedulesRequestBody,
} from '../../../service/schedule/Schedule'
import {
  CancelRegisterPopUp,
  RegisterTicketConfirmPopUp,
  RegisterTicketPopUp,
} from '../../modal/PopUpContents'
import TableList from '../../common/TableList'
import HalfPopUp, {PopUpRef} from '../../common/HalfPopUp'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {getDispatchesOverBooking} from '../../../service/dispatch/Dispatch'
import {CDateTime} from '../../../model/DateTime'
import {BaseDateUnitEnum} from '../../../enum/DateUnitEnum'
import CloseIcon from '../../../asset/image/close_button.svg'
import SVGImage from '../../common/SVGImage'

type RegisterTicketProps = {
  validSeat: number
  dispatchDetail: CDispatchDetail
  operationType: OperationTypeEnum
  startDate: Optional<CDate>
  endDate: Optional<CDate>
  weekday: Optional<WeekdayEnum>
  onAddTicket(): void
  hideRegisterModal(): void
  fetchDispatchDetail(dateRange: {start: CDate; end: CDate}): void
}
export type RegisterTicketRef = {
  reset(): void
}

function RegisterTicketBase(
  props: RegisterTicketProps,
  ref: ForwardedRef<RegisterTicketRef>,
): ReactElement {
  const dateRef = useSecureRef<DatePickerRef>('[RegisterTicket.tsx] dateRef')
  const cancelRef = useSecureRef<PopUpRef>('[RegisterTicket.tsx] cancelRef')
  const registerRef = useSecureRef<PopUpRef>('[RegisterTicket.tsx] registerRef')
  const registerConfirmRef = useSecureRef<PopUpRef>(
    '[RegisterTicket.tsx] registerConfirmRef',
  )
  const registerStationRef = useSecureRef<RegisterStationRef>(
    '[RegisterTicket.tsx] registerStationRef',
  )
  const obRef = useSecureRef<PopUpRef>('[RegisterTicket.tsx] obRef')
  const academyID = useRecoilValue(academyIDState)

  const [submitPressable, setSubmitPressable] = useState<boolean>(false)
  const [overBookings, setOverBookings] = useState<COverBooking[]>([])
  const [boardingCount, setBoardingCount] = useState<Optional<number>>(null)

  const weekdayStartDate = useMemo((): CDate => {
    const now = CDateTime.now()
    const nowWeekDay = now.toDate().toDay()

    let diff: number = props.weekday.id - nowWeekDay.id
    if (props.weekday === WeekdayEnum.SUNDAY) {
      diff += 7
    }

    return now.add(diff, BaseDateUnitEnum.DAY).toDate()
  }, [props.weekday])

  const onSubmit = useCallback(async () => {
    if (!submitPressable) {
      return
    }

    const s = registerStationRef.current().getStudents()
    const f = s.filter(fs => fs.boardSwitch === undefined)

    const fs = f.map(st => {
      return {
        id: st.id,
        pickUpStationId: st.pickUpStationId,
        takeOffStationId: st.takeOffStationId,
      }
    })
    const dcs = [props.dispatchDetail.dispatchCode]
    const range = dateRef.current().getRange()

    const data: PostStudentSchedulesRequestBody = {
      dispatchCodes: dcs,
      startDate: range.startDate.toString(),
      endDate: range.endDate.toString(),
      students: fs,
    }

    // try {
    //   const obr = await getDispatchesOverBooking(academyID, {
    //     dispatchCode: props.dispatchDetail.dispatchCode,
    //     boardingCount: boardingCount,
    //     startDate: data.startDate.toString(),
    //     endDate: data.endDate.toString(),
    //   })

    //   if (!isEmptyArray(obr)) {
    //     registerRef.current().hide()
    //     setOverBookings(obr)
    //     return
    //   }
    // } catch (error) {
    //   throw new Error(
    //     `failed to get dispatches over booking. (data: ${JSON.stringify(
    //       data,
    //     )}, error:${error})`,
    //   )
    // }

    try {
      await postStudentSchedulesExists(academyID, data)
    } catch (error) {
      alert('학생 스케줄이 중복되었습니다.')
      throw new Error(
        `failed to post student schedules exists. (data: ${JSON.stringify(
          data,
        )}, error:${error})`,
      )
    }

    try {
      await postStudentSchedules(academyID, data)
    } catch (error) {
      alert('학생 스케줄 등록에 실패하였습니다.')
      throw new Error(
        `failed to post student schedules. (data: ${JSON.stringify(
          data,
        )}, error:${error})`,
      )
    }

    registerRef.current().hide()
    registerConfirmRef.current().show()
    setOverBookings([])
  }, [props.hideRegisterModal, props.fetchDispatchDetail, submitPressable])

  const TITLE_MAP = {
    year: '연도',
    dates: '날짜',
    shareRate: '점유율',
  }

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return overBookings
      .flatMap(ob => ob.seatOccupancyRates)
      .map(sor => {
        const node = new Map<string, ReactNode>()

        node.set(
          TITLE_MAP.year,
          <div style={{background: '#fff'}}>
            {sor.ticketDates[0].format('yyyy')}
          </div>,
        )
        node.set(
          TITLE_MAP.dates,
          <div
            style={{
              width: '20rem',
              lineHeight: '1.4rem',
            }}>
            {sor.ticketDates.map(td => td.format('MM/DD')).join(', ')}
          </div>,
        )
        node.set(
          TITLE_MAP.shareRate,
          <div style={{display: 'flex'}}>
            <div style={{fontSize: 12, color: '#E74D41', fontWeight: 700}}>
              {sor.occupySeat}
            </div>
            &nbsp;/ {sor.totalSeat}
          </div>,
        )
        return node
      })
  }, [overBookings])

  const handleSubmitPressable = useCallback((value: boolean) => {
    setSubmitPressable(value)
  }, [])

  const handleCancelButton = useCallback(() => {
    cancelRef.current().show()
  }, [])

  const handleRegisterButton = useCallback(() => {
    registerRef.current().show()
  }, [])

  const onClickCancelButton = useCallback(() => {
    registerRef.current().hide()
    cancelRef.current().hide()
  }, [])

  const onConfirmCancel = useCallback(() => {
    cancelRef.current().hide()
    props.hideRegisterModal()
    registerStationRef.current().getDefaultTotalStudents()
    setOverBookings([])
  }, [])

  const handleConfirmButton = useCallback(() => {
    registerConfirmRef.current().hide()
    props.fetchDispatchDetail({
      start: dateRef.current().getRange()?.startDate,
      end: dateRef.current().getRange()?.endDate,
    })
    props.hideRegisterModal()
    props.onAddTicket()
  }, [props.fetchDispatchDetail, props.hideRegisterModal, props.onAddTicket])

  const handleOverBookingTable = useCallback(() => {
    obRef.current().show()
  }, [])

  const handleHideOBTable = useCallback(() => {
    obRef.current().hide()
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      reset() {
        registerStationRef.current().getDefaultTotalStudents()
      },
    }),
    [],
  )

  return (
    <Container>
      <Header>{`승차권 등록 (${props.operationType.exposure})`}</Header>
      <BodyWrapper>
        <HalfPopUp ref={obRef.ref} width={'44rem'} height={'37rem'}>
          <OverBookingContainer>
            <Title>좌석 초과</Title>
            <ImgWrapper onClick={handleHideOBTable}>
              <CloseImg source={CloseIcon} />
            </ImgWrapper>
            <TableWrapper>
              <TableList
                tableStyle={{background: '#ffffff'}}
                tableHeaderStyle={{color: '#666666'}}
                keys={Object.values(TITLE_MAP)}
                items={listItems}
                onClickRow={() => {}}
              />
            </TableWrapper>
            <ConfirmButton onClick={handleHideOBTable}>확인</ConfirmButton>
          </OverBookingContainer>
        </HalfPopUp>

        <DatePickerWrapper>
          <TextWrapper>
            <InfoText>승차기간을 선택해 주세요.</InfoText>
            {/* <Text>
              *<BoldText>오늘</BoldText> 승차권 등록은{' '}
              <OrangeText>불가능</OrangeText>합니다.
            </Text> */}
          </TextWrapper>
          <DatePicker>
            <RangeDatePicker
              ref={dateRef.ref}
              onChange={(dateRange: {start: CDate; end: CDate}) => {
                if (!isNil(dateRange.start) && !isNil(dateRange.end)) {
                  props.fetchDispatchDetail(dateRange)
                }
              }}
              maxDate={props.endDate}
              minDate={props.startDate}
              weekday={props.weekday}
              weekdayStartDate={weekdayStartDate}
            />
          </DatePicker>
        </DatePickerWrapper>
        <RegisterStation
          ref={registerStationRef.ref}
          validSeat={props.validSeat}
          showOverBookingBar={!isEmptyArray(overBookings)}
          courses={props.dispatchDetail.courses}
          operationType={props.operationType}
          handleSubmitPressable={handleSubmitPressable}
          handleOverBookingTable={handleOverBookingTable}
          // handleBoardingCount={handleBoardingCount}
          setBoardingCnt={setBoardingCount}
        />
      </BodyWrapper>
      <Bottom>
        <CancelButton onClick={handleCancelButton}>취소</CancelButton>
        <RegisterButton
          pressable={submitPressable}
          onClick={handleRegisterButton}>
          등록
        </RegisterButton>
      </Bottom>
      <HalfPopUp
        width={'36rem'}
        height={'auto'}
        top={'40%'}
        right={'30%'}
        ref={cancelRef.ref}
        contents={
          <CancelRegisterPopUp
            onClose={onClickCancelButton}
            onClickEditButton={onConfirmCancel}
          />
        }
      />
      <HalfPopUp
        width={'36rem'}
        height={'auto'}
        top={'40%'}
        right={'30%'}
        ref={registerRef.ref}
        contents={
          <RegisterTicketPopUp
            onClose={onClickCancelButton}
            onClickEditButton={onSubmit}
          />
        }
      />
      <HalfPopUp
        width={'36rem'}
        height={'auto'}
        top={'40%'}
        right={'30%'}
        ref={registerConfirmRef.ref}
        contents={
          <RegisterTicketConfirmPopUp confirmButton={handleConfirmButton} />
        }
      />
    </Container>
  )
}

const RegisterTicket = forwardRef(RegisterTicketBase)
export default RegisterTicket

const Container = styled.div`
  border-radius: 1.6rem 0 0 1.6rem;
`

export const Header = styled.div`
  height: 6rem;
  padding: 2rem;
  background: ${color.white};
  border-bottom: 0.1rem solid #ebebeb;
  color: #174490;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 1.6rem 0 0 0;
`

const BodyWrapper = styled.div`
  column-gap: 2rem;
  padding: 2rem;
  height: calc(100vh - 12.8rem);
  background: #f5f5f5;
  display: grid;
  grid-template-columns: 30rem 81.5rem;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const OverBookingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 2.2rem;
  padding: 2rem 0;
  position: relative;
`

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
`

const ImgWrapper = styled.div`
  cursor: pointer;
`

const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`

const TableWrapper = styled.div`
  ${flexColumn};
  max-height: 23rem;
  overflow-y: scroll;
  background: #f6f8fb;
  padding: 1.8rem 3rem;
  margin-bottom: 2rem;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`
const ConfirmButton = styled(ModalButton)`
  line-height: 4rem;
  font-size: 1.4rem;
  font-weight: 700;
  background: #ffcd00;
  margin: 0 auto;
`

export const DatePickerWrapper = styled.div`
  border-radius: 0.6rem;
  border: 0.1rem solid #e7e7e7;
  background: #fff;
  padding: 2rem;
  ${flexColumn};
  column-gap: 1.6rem;
  //TODO weekcnt에 따라 높이 조절
  max-height: 470px;
`

const TextWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  margin-bottom: 1.6rem;
`

const DatePicker = styled.div`
  position: relative;
`

export const Text = styled.span`
  font-size: 1.1rem;
  color: #585858;
  font-weight: 400;
  line-height: 2rem;
`

const InfoText = styled(Text)`
  color: #000;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.2rem;
`

const Bottom = styled.div`
  height: 6.8rem;
  padding: 1.6rem 2.4rem;
  background: ${color.white};
  border-radius: 0 0 0 1.6rem;
  justify-content: end;
  ${flexRow}
`

export const CancelButton = styled.button`
  padding: 1rem 2.2rem;
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 3rem;
  margin-left: 0.4rem;
`

const RegisterButton = styled(CancelButton)<{pressable: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.pressable ? 1 : 0.3)};
  cursor: ${props => (props.pressable ? 'pointer' : 'not-allowed')};
`
