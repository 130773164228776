import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {Optional} from '../../type/Common'
import {isNil} from '../../util/ValidationUtil'
import {
  CBusDetail,
  CBusNameWithID,
  CBusOperation,
  CBusOperationCarDispatch,
  CBusOperationSchedule,
} from '../../model/Bus'
import {CDate} from '../../model/Date'
import {API} from '../../Env'

export type GetBusOperationsQueryParams = {
  date: CDate
  busId: Optional<number>
  academyId: string
}

export async function getBusOperationsQuery(
  data: GetBusOperationsQueryParams,
): Promise<CBusOperation[]> {
  const url = `${API}/${data.academyId}/bus-operations/search`
  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      date: data.date.toString(),
      busId: data.busId,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CBusOperation.create(el))
}

export type GetBusesData = {
  academyId: string
  date: CDate
}

export async function getBuses(data: GetBusesData) {
  const url = `${API}/${data.academyId}/bus-operations/buses`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      date: data.date.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CBusNameWithID.create(el))
}

export type GetBusOperationDetailParams = {
  academyId: string
  code: string
  date: CDate
}

export async function getBusOperationDetail(
  data: GetBusOperationDetailParams,
): Promise<CBusDetail[]> {
  const url = `${API}/${data.academyId}/bus-operations/detail`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      code: data.code,
      date: data.date.toString(),
    },
  }

  const axiosResponse = await axios(config)
  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CBusDetail.create(el))
}

export async function getBusOperationsCarDispatchByCode(
  code: string,
  academyID: string,
  date: CDate,
) {
  const url = `${API}/${academyID}/bus-operations/dispatches/${code}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      searchDate: date.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return CBusOperationCarDispatch.create(axiosResponse.data.data)
}

export async function getBusOperationsSchedules(
  code: string,
  date: CDate,
  academyID: string,
) {
  const url = `${API}/${academyID}/bus-operations/schedules`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      code: code,
      date: date.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) =>
    CBusOperationSchedule.create(el),
  )
}

export async function putStudentSchedule(
  academyId: string,
  scheduleId: number,
  isBoarding: boolean,
) {
  const url = `${API}/${academyId}/schedules/${scheduleId}/boarding-switch`

  const config = {
    method: AxiosEnum.PUT.value,
    url: url,
    data: {
      boardSwitch: isBoarding,
    },
  }

  await axios(config)
}
