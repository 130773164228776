import React, {ChangeEvent, Dispatch, SetStateAction, useCallback} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'

// image
import CloseIcon from '../../../asset/image/close_button.svg'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import SearchIcon from '../../../asset/image/yellow_search.svg'
import Pagination from '../../common/Pagination'
import {CDriver} from '../../../model/Driver'
import useSecureRef from '../../../hook/useSecureRef'
import ModalContainer, {ModalContainerRef} from '../../modal/ModalContainer'
import RegisterDriver from '../popupContents/RegisterDriver'

type ModalPaginationProps = {
  width?: string
  height?: string
  onClose: () => void
  PER_PAGE: number
  totalElements: number
  currentPage: number
  data?: CDriver[]
  setCurrentPage: Dispatch<SetStateAction<number>>
  keyword?: string
  setKeyword?: Dispatch<SetStateAction<string>>
  setDriverID?: Dispatch<SetStateAction<number>>
  getNotAssignedDriverList?: () => void
}

function ModalPagination(props: ModalPaginationProps) {
  const registerPopUpRef = useSecureRef<ModalContainerRef>(
    '[ModalPagination.tsx] registerRef',
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleChangeKeyword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setKeyword(e.target.value)
    },
    [props.keyword],
  )

  const handleSelectDriver = useCallback((id: number) => {
    props.setDriverID(id)
    props.onClose()
  }, [])

  const showRegisterModal = useCallback(() => {
    registerPopUpRef.current().show()
  }, [])

  const hideRegisterModal = useCallback(() => {
    registerPopUpRef.current().hide()
  }, [])

  return (
    <Container width={props.width}>
      <Header>
        <Title>기사 선택</Title>
        <ButtonWrapper>
          <RegisterDriverButton onClick={showRegisterModal}>
            <ButtonImg source={PlusIcon} />
            <ButtonText>신규 기사 등록</ButtonText>
          </RegisterDriverButton>
          <CloseImg onClick={props.onClose}>
            <ButtonImg source={CloseIcon} />
          </CloseImg>
        </ButtonWrapper>
      </Header>
      <InputWrapper>
        <ButtonImg source={SearchIcon} />
        <Input
          placeholder="검색어를 입력해주세요."
          onKeyDown={handleKeyDown}
          onChange={handleChangeKeyword}
          value={props.keyword}
        />
      </InputWrapper>
      <ListWrapper>
        {props.data?.map((d, idx) => {
          return (
            <Driver key={idx} onClick={() => handleSelectDriver(d.id)}>
              {d.name}
            </Driver>
          )
        })}
      </ListWrapper>
      <Pagination
        num={props.totalElements}
        perPage={props.PER_PAGE}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        pageExposeCount={5}
        backgroundColor={'#F5F5F5'}
        marginTop={'0'}
        height={'3.8rem'}
        borderRadius={'0 0 0.8rem 0.8rem'}
        columnGap={'0.8rem'}
      />
      <ModalContainer ref={registerPopUpRef.ref} contentWidth={'37.6rem'}>
        <RegisterDriver
          hideRegisterModal={hideRegisterModal}
          getNotAssignedDriverList={props.getNotAssignedDriverList}
        />
      </ModalContainer>
    </Container>
  )
}

export default ModalPagination

type ContainerProps = {
  width?: string
  height?: string
}

const Container = styled.div<ContainerProps>`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.8rem;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
`

const Header = styled.div`
  background: #332a00;
  padding: 0.6rem 0.4rem 0.6rem 0.8rem;
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem 0.8rem 0 0;
`

const Title = styled.div`
  color: #ffd100;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
`

const ButtonWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const RegisterDriverButton = styled.button`
  padding: 0.4rem 0.8rem 0.4rem 0.6rem;
  border-radius: 3.4rem;
  border: 0.1rem solid #ffd100;
  background: #ffd100;
  ${flexRow};
  align-items: center;
  column-gap: 0.2rem;
`

const ButtonImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const ButtonText = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: #332a00;
`

const CloseImg = styled.div``

const InputWrapper = styled.div`
  ${flexRow};
  background: #f5f5f5;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  column-gap: 0.4rem;
`

const Input = styled.input`
  border: none;
  background: #f5f5f5;
  height: 1.8rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.6rem;
  /* grid-template-rows: 3.6rem 3.6rem 3.6rem 3.6rem 3.6rem; */
  width: 100%;
  max-height: 20.4rem;
  background: #fff;
  padding: 1.2rem;
`

const Driver = styled.div`
  min-width: 10.5rem;
  max-height: 3.6rem;
  padding: 0.8rem;
  background: #fff;
  border-bottom: 0.1rem solid #ebebeb;
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  :hover {
    cursor: pointer;
    border-radius: 0.8rem;
    background: #fffbe5;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
    transform: translateY(-0.1rem);
  }
`
