import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router'
import {color, flexColumn} from '../../../style/CommonStyle'
import {isNil} from '../../../util/ValidationUtil'
import TableList from '../../common/TableList'
import Pagination from '../../common/Pagination'
import {Manager} from '../../../page/managerManagement/ManagerManagement'

const TITLE_MAP = {
  name: '매니저명',
  phone: '연락처',
  userId: '아이디',
  createdAt: '등록일',
}

type ManagerListProps = {
  PER_PAGE: number
  managerState: Manager
  totalElements: number
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export type ManagerListRef = {
  getManagerList(): void
}

function ManagerListBase(
  props: ManagerListProps,
  _: ForwardedRef<ManagerListRef>,
) {
  const navigate = useNavigate()

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return props.managerState.filteredManagers.map(m => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.name, <div>{m.name}</div>)
      node.set(TITLE_MAP.phone, <div>{m.phone}</div>)
      node.set(TITLE_MAP.userId, <div>{m.userId}</div>)
      node.set(TITLE_MAP.createdAt, <div>{m.createdAt}</div>)
      return node
    })
  }, [props.managerState])

  const onClickRow = useCallback(
    (idx: number) => {
      if (isNil(props.managerState.filteredManagers)) {
        return
      }

      const managerID = props.managerState.filteredManagers[idx].id
      navigate(`/manager/${managerID}`, {state: {managerID: managerID}})
    },
    [props.managerState],
  )

  return (
    <Container managerCnt={props.totalElements}>
      {props.managerState.filteredManagers.length > 0 && (
        <Title>{`매니저 리스트 (${props.totalElements})`}</Title>
      )}
      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        tableHeaderStyle={{background: '#f5f5f5'}}
        placeholder={'등록된 매니저가 없습니다.'}
      />
      {props.managerState.filteredManagers.length > 0 && (
        <Pagination
          num={props.totalElements}
          perPage={props.PER_PAGE}
          pageExposeCount={5}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
      )}
    </Container>
  )
}

const ManagerList = forwardRef(ManagerListBase)
export default ManagerList

type ContainerProps = {
  managerCnt: number
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${color.white};
  min-height: 6.8rem;
  max-height: calc(100vh - 20rem);
  ${flexColumn};
  padding: 1.2rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 0.8rem;
  margin-left: 1.2rem;
`
