import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import styled from 'styled-components'
import {color} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import {StudentState} from './RegisterStation'
import DragIcon from '../../../asset/image/drag_icon.svg'
import DeleteIcon from '../../../asset/image/delete_button.svg'
import {CStudent} from '../../../model/Dispatch'
import HalfPopup from '../../common/HalfPopUp'
import StudentListPopUp from './StudentListPopUp'
import useSecureRef from '../../../hook/useSecureRef'
import {ModalContainerRef} from '../../modal/ModalContainer'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import {getStudents} from '../../../service/student/Student'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'

const handleStudentBox = (student: CStudent): React.ReactNode => {
  return (
    <PreventCursor>
      <StudentName>
        {student.nickName === '' || student.nickName === '-'
          ? ''
          : `${student?.nickName} / `}
        {`${student?.name} ${
          !isNil(student.parentPhone)
            ? `(${student.parentPhone?.slice(-4)})`
            : ''
        }`}
      </StudentName>
    </PreventCursor>
  )
}

type EditStudentStationProps = {
  studentStates: StudentState[]
  filteredStudentStates: StudentState[]
  stationID: number
  operationState: {editable: boolean; type: OperationTypeEnum}
  onSubmit(students: CStudent[]): void
  onDelete(id: number): void
}
export type EditStudentStationRef = {
  getOperationType(): OperationTypeEnum
  getStationID(): number
}

type StudentStates = {
  originalStudents: CStudent[]
  filteredStudents: CStudent[]
}

function EditStudentStationBase(
  props: EditStudentStationProps,
  ref: ForwardedRef<EditStudentStationRef>,
): ReactElement {
  const studentListPopUpRef = useSecureRef<ModalContainerRef>(
    '[StudentList.tsx] studentListPopUpRef',
  )
  const selectedAcademyID = useRecoilValue(academyIDState)
  const [studentList, setStudentList] = useState<StudentStates>({
    originalStudents: [],
    filteredStudents: [],
  })

  const showStudentListPopUp = useCallback(() => {
    studentListPopUpRef.current().show()
    // getStudents(selectedAcademyID)
    //   .then(ss => {
    //     setStudentList({originalStudents: ss, filteredStudents: ss})
    //   })
    //   .catch(error => {
    //     throw new Error(
    //       `failed to get students. (academyID: ${selectedAcademyID}, error: ${error})`,
    //     )
    //   })
  }, [])

  const onDelete = useCallback(
    (id: number) => {
      if (!props.operationState.editable) {
        return
      }

      props.onDelete(id)
    },
    [props.onDelete, props.operationState],
  )

  const hidePopUp = useCallback(() => studentListPopUpRef.current().hide(), [])

  const studentStates = useMemo(() => {
    if (!props.operationState.editable) {
      return props.filteredStudentStates
    }

    if (props.operationState.type === OperationTypeEnum.INBOUND) {
      return props.filteredStudentStates.filter(
        ss => ss.student.pickUpStationId === props.stationID,
      )
    }

    return props.filteredStudentStates.filter(
      ss => ss.student.takeOffStationId === props.stationID,
    )
  }, [props.filteredStudentStates, props.stationID, props.operationState])

  const onSubmit = useCallback(
    (students: CStudent[]) => {
      hidePopUp()
      const ss = students.map(s => {
        if (props.operationState.type === OperationTypeEnum.INBOUND) {
          return {...s, pickUpStationId: props.stationID}
        }

        return {...s, takeOffStationId: props.stationID}
      })
      props.onSubmit(ss)
    },
    [hidePopUp],
  )

  useImperativeHandle(
    ref,
    () => ({
      getOperationType(): OperationTypeEnum {
        return props.operationState.type
      },
      getStationID(): number {
        return props.stationID
      },
    }),
    [props.operationState, props.stationID],
  )

  const BoardTitleComponent = useMemo(() => {
    if (isEmptyArray(studentStates)) {
      return null
    }

    return (
      <BoardTitle>
        <Board type={props.operationState.type}>
          {props.operationState.type.boarding}
        </Board>
      </BoardTitle>
    )
  }, [props.operationState.type, studentStates])

  const BoardContentComponent = useMemo(() => {
    if (isEmptyArray(studentStates)) {
      return null
    }

    return (
      <EditStudentContainer>
        {studentStates.map((state: StudentState, idx) => {
          return (
            <Draggable
              isDragDisabled={!props.operationState.editable}
              key={`edit_${idx}_${state.student.id}`}
              draggableId={`${JSON.stringify({
                studentID: state.student.id,
                operationType: props.operationState.type,
              })}`}
              index={state.student.id}>
              {(provided, _) => (
                <EditStudentWrapper
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  editable={props.operationState.editable}>
                  <ImageWrapper>
                    <MoveImg source={DragIcon} />
                    {handleStudentBox(state.student)}
                  </ImageWrapper>
                  <div onClick={() => onDelete(state.student.id)}>
                    <DeleteImg source={DeleteIcon} />
                  </div>
                </EditStudentWrapper>
              )}
            </Draggable>
          )
        })}
      </EditStudentContainer>
    )
  }, [
    props.stationID,
    studentStates,
    props.operationState,
    handleStudentBox,
    onDelete,
  ])

  const AddButtonComponent = useMemo(() => {
    if (!props.operationState.editable) {
      return null
    }

    return (
      <AddButton onClick={showStudentListPopUp}>
        <AddImg source={PlusIcon} />
        <AddText>학생 추가</AddText>
      </AddButton>
    )
  }, [props.operationState, showStudentListPopUp])

  return (
    <Droppable key={props.stationID} droppableId={`${props.stationID}`}>
      {(provided, _) => (
        <ButtonWrapper
          ref={provided.innerRef}
          {...provided.droppableProps}
          editable={props.operationState.editable}
          border={isEmptyArray(studentStates)}>
          <EditBoardBox>
            {BoardTitleComponent}
            {BoardContentComponent}
          </EditBoardBox>
          {AddButtonComponent}
          <HalfPopup
            width={'57.5rem'}
            height={'57.4rem'}
            ref={studentListPopUpRef.ref}
            contents={
              <StudentListPopUp
                stationID={null}
                operationState={null}
                onCancel={hidePopUp}
                students={props.studentStates.map(ss => ss.student)}
                onSubmit={onSubmit}
                studentList={studentList}
              />
            }
          />
        </ButtonWrapper>
      )}
    </Droppable>
  )
}

const EditStudentStation = forwardRef(EditStudentStationBase)
export default EditStudentStation

const ButtonWrapper = styled.div<{editable: boolean; border: boolean}>`
  border: 1px solid #e7e7e7;
  background-color: ${props => (props.editable ? color.white : color.grey1)};
  display: ${props => (!props.editable && props.border ? 'none' : 'flex')};
  padding: 1.6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 68rem;
  max-width: 68rem;
  position: relative;
  border-radius: ${props => (props.border ? '0.3rem' : '0.3rem 0.3rem 0 0')};
`

const EditBoardBox = styled.div``

export const BoardTitle = styled.div`
  width: 3.6rem;
  height: 1.8rem;
  margin-right: 1rem;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 1.6rem;
  left: 1.6rem;
`

const Board = styled.span<{type: OperationTypeEnum}>`
  display: inline-flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 3rem;
  background: ${props =>
    props.type === OperationTypeEnum.INBOUND ? color.green1 : color.blue1};
  color: ${color.white};
  padding: 0 0.9rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8rem;
`

const EditStudentContainer = styled.div`
  width: 100%;
  max-width: 58rem;
  margin-left: 4rem;
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.8rem;
  column-gap: 0.8rem;
`

const AddButton = styled.button`
  width: 8.3rem;
  height: 2.4rem;
  display: flex;
  padding: 0.3rem 0.8rem;
  align-items: center;
  column-gap: 0.2rem;
  border-radius: 10rem;
  background: #ffd100;
  border: none;
`

const AddImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const AddText = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
`

const EditStudentWrapper = styled.div<{editable: boolean}>`
  border: 0.1rem solid rgba(208, 208, 208, 0.6);
  border-radius: 0.4rem;
  display: flex;
  background: ${props => (props.editable ? color.white : color.grey1)};
  padding: 0.8rem 0.6rem;
  align-items: center;
  width: 19rem;
  position: relative;
`

const DeleteImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.4rem;
  position: absolute;
  top: 0.8rem;
  right: 0.6rem;
`

const MoveImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.4rem;
`

const PreventCursor = styled.div`
  cursor: default;
`

const StudentName = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #585858;
  font-weight: 500;
  cursor: pointer;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`
