import {unitOfTime} from 'moment-timezone'

export class BaseDateUnitEnum {
  static readonly YEAR = new BaseDateUnitEnum('year', ['years', 'year', 'y'])
  static readonly MONTH = new BaseDateUnitEnum('month', ['months', 'month', 'm'])
  static readonly WEEK = new BaseDateUnitEnum('week', ['weeks', 'week', 'w'])
  static readonly DAY = new BaseDateUnitEnum('day', ['days', 'day', 'd'])
  static readonly HOUR = new BaseDateUnitEnum('hour', ['hours', 'hour', 'h'])
  static readonly MINUTE = new BaseDateUnitEnum('minute', ['minutes', 'minute'])
  static readonly SECOND = new BaseDateUnitEnum('second', ['seconds', 'second', 's'])
  static readonly MILLISECOND = new BaseDateUnitEnum('millisecond', ['milliseconds', 'millisecond', 'ms'])

  static readonly ALL: BaseDateUnitEnum[] = [
    BaseDateUnitEnum.YEAR,
    BaseDateUnitEnum.MONTH,
    BaseDateUnitEnum.WEEK,
    BaseDateUnitEnum.DAY,
    BaseDateUnitEnum.HOUR,
    BaseDateUnitEnum.MINUTE,
    BaseDateUnitEnum.SECOND,
    BaseDateUnitEnum.MILLISECOND,
  ]

  private constructor(
    readonly value: unitOfTime.Base,
    readonly aliases: unitOfTime.Base[],
  ) {}

  static parse(unit: string) {
    const lowered = unit.toLowerCase()

    for (const due of BaseDateUnitEnum.ALL) {
      for (const a of due.aliases) {
        if (a === lowered) {
          return due
        }
      }
    }

    throw new Error(`invalid unit. (unit: ${unit})`)
  }
}
