import {isNil, orElse} from './ValidationUtil'
import {AxiosError} from 'axios'

export function alertError(error: any, errorMessage: string): void {
  let serverMessage = error.response?.data?.data

  if (isNil(serverMessage)) {
    serverMessage = error.response?.data?.message
  }

  alert(orElse(serverMessage, '요청에 실패하였습니다.'))
  throw new Error(errorMessage)
}

export function throwError<T extends {data: string; message: string}>(
  error: AxiosError<T>,
  errorMessage: string,
): void {
  let serverMessage = error.response?.data.data

  if (isNil(serverMessage)) {
    serverMessage = error.response?.data.message
  }

  console.error(`error: ${errorMessage}, serverMessage: ${serverMessage}`)
}
