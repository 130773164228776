import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import {isNil} from '../../util/ValidationUtil'
import styled from 'styled-components'
import {flexColumn} from '../../style/CommonStyle'

type ModalProps = {
  children?: ReactNode
  zIndex?: number
  onClickBackground?(): void
}

export type ModalRef = {
  show(): void
  hide(): void
}

function ModalBase(
  props: ModalProps,
  ref: ForwardedRef<ModalRef>,
): ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const show = useCallback(() => setIsVisible(true), [])
  const hide = useCallback(() => {
    setIsVisible(false)
  }, [])

  const onClickBackground = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      if (isNil(props.onClickBackground)) {
        return
      }

      props.onClickBackground()
    },
    [props.onClickBackground],
  )

  useImperativeHandle(
    ref,
    () => ({
      show: show,
      hide: hide,
    }),
    [show, hide],
  )

  if (!isVisible) {
    return null
  }

  return (
    <Container zIndex={props.zIndex}>
      <BackgroundLayer onClick={onClickBackground} />
      <ContentContainer>{props.children}</ContentContainer>
    </Container>
  )
}

const Modal = forwardRef(ModalBase)
export default Modal

type ContainerProps = {
  zIndex: number
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (!isNil(props.zIndex) ? props.zIndex : 2)};
  padding: 0;
`

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

const ContentContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`
