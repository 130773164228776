import React, {ForwardedRef, forwardRef, ReactElement} from 'react'
import styled from 'styled-components'
import Calendar from '../../asset/image/calendar.svg'
import {CDate} from '../../model/Date'

type DatePickerCustomInputProps = {
  value: string
  onClick?: () => void
}
export type DatePickerCustomInputRef = {}

function DatePickerCustomInputBase(
  props: DatePickerCustomInputProps,
  _: ForwardedRef<DatePickerCustomInputRef>,
): ReactElement {
  const Date = CDate.create(props.value)

  return (
    <DatePickerButton onClick={props.onClick}>
      <CalendarImg />
      <ContourLine />
      <LabelWrapper>
        <DatePickerText>{`${props.value} (${Date.toDay().exposure})`}</DatePickerText>
      </LabelWrapper>
    </DatePickerButton>
  )
}

const DatePickerCustomInput = forwardRef(DatePickerCustomInputBase)
export default DatePickerCustomInput

const DatePickerButton = styled.button`
  padding: 0.8rem 0.6rem 0.8rem 1.4rem;
  min-width: 16rem;
  max-width: 28rem;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.8rem;
  border: 0.1rem solid #d0d0d0;

  &:hover {
    cursor: pointer;
    border: 0.1rem solid #ffcd00;
  }

  &:focus {
    border: 0.1rem solid #ffcd00;
  }
`

const CalendarImg = styled.div`
  background: url(${Calendar}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
`

const ContourLine = styled.div`
  width: 0.1rem;
  height: 1.2rem;
  margin: 0 1rem;
  background: #d0d0d0;
`

const LabelWrapper = styled.div`
  margin-right: 1rem;

  > label {
    cursor: pointer;
  }
`

const DatePickerText = styled.span`
  font-size: 1.4rem;
  height: 1.6rem;
  text-align: center;
  line-height: 1.6rem;
  font-weight: 400;
`
