import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {media} from '../../style/media'
import {isNil} from '../../util/ValidationUtil'

type ModalContainerProps = {
  children?: ReactNode
  contentWidth?: string
  zIndex?: number
  onClickBackground?(): void
}

export type ModalContainerRef = {
  show(): void
  hide(): void
}

function ModalContainerBase(
  props: ModalContainerProps,
  ref: ForwardedRef<ModalContainerRef>,
): ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const show = useCallback(() => setIsVisible(true), [])
  const hide = useCallback(() => {
    setIsVisible(false)
  }, [])

  const onClickBackground = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      if (isNil(props.onClickBackground)) {
        return
      }

      props.onClickBackground()
    },
    [props.onClickBackground],
  )

  useImperativeHandle(
    ref,
    () => ({
      show: show,
      hide: hide,
    }),
    [],
  )

  return (
    <Container isVisible={isVisible}>
      <BackgroundLayer onClick={onClickBackground} />
      <Contents width={props.contentWidth}>{props.children}</Contents>
    </Container>
  )
}

const ModalContainer = forwardRef(ModalContainerBase)
export default ModalContainer

type ContainerProps = {
  isVisible: boolean
}

const Container = styled.div<ContainerProps>`
  display: ${({isVisible: isVisible}) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  padding: 0;
`

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`

type ContentsProps = {
  width?: string
  zIndex?: number
}

const Contents = styled.div<ContentsProps>`
  width: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  position: absolute;

  height: 90%;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 100;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;

  ${media.tablet} {
    width: ${props => (props.width ? props.width : '117.5rem')};
    height: 100%;
    right: 0;
  }
`
