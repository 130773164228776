import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router'
import ScreenContainer from '../../component/layout/ScreenContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import {flexColumn} from '../../style/CommonStyle'
import SearchDriver from '../../component/driverManagement/searchWrapper/SearchDriver'
import useSecureRef from '../../hook/useSecureRef'
import {isNil} from '../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../type/Common'
import {academyIDState} from '../../recoil/Atom'
import DriverList, {
  DriversListRef,
} from '../../component/driverManagement/driverList/DriverList'
import {getDrivers, GetDriversData} from '../../service/driver/Driver'
import {CDriver} from '../../model/Driver'
import {DriverSearchType} from '../../enum/DriverSearchType'
import Toast from '../../component/toast/Toast'

type DriverManagementProps = {}
export type DriverManagementRef = {}

const PER_PAGE = 12

type StateProps = {
  name: string
}

function DriverManagementBase(
  props: DriverManagementProps,
  ref: ForwardedRef<DriverManagementRef>,
) {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as StateProps
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const listRef = useSecureRef<DriversListRef>('[DriverManagement.tsx] listRef')
  const [driverState, setDriverState] = useState<CDriver[]>([])
  const [totalElements, setTotalElements] = useState<Optional<number>>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [select, setSelect] = useState<DriverSearchType>(DriverSearchType.NAME)
  const [keyword, setKeyword] = useState<string>('')
  const [driverName, setDriverName] = useState<Optional<string>>(null)

  const fetchDriverList = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetDriversData = {
      academyID: selectedAcademyID,
      page: currentPage,
      searchType: select,
      searchValue: keyword,
      size: PER_PAGE,
    }

    getDrivers(data)
      .then(dl => {
        setDriverState(dl.drivers)
        setTotalElements(dl.paging.totalElements)
      })
      .catch(error => {
        throw new Error(
          `failed to get driver list. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [selectedAcademyID, currentPage, keyword])

  useEffect(() => {
    if (!isNil(state)) {
      setDriverName(state?.name)
    }
  }, [setDriverName])

  useEffect(() => {
    setCurrentPage(0)
  }, [keyword, selectedAcademyID])

  useEffect(() => {
    fetchDriverList()
  }, [selectedAcademyID, currentPage, keyword, fetchDriverList])

  useEffect(() => {
    if (!isNil(driverName)) {
      const timeoutId = setTimeout(() => {
        setDriverName(null)
        navigate('/driver', {state: {name: null}})
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [setDriverName, driverName])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const savedPageIdx = urlParams.get('page')

    if (savedPageIdx !== null) {
      const pageIndex = parseInt(savedPageIdx) - 1
      const timer = setTimeout(() => {
        setCurrentPage(pageIndex)
      }, 100)
      return () => clearTimeout(timer)
    } else {
      setCurrentPage(0)
    }
  }, [])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>기사 관리</PageHeaderWithAcademies>
      <Container>
        <SearchDriver
          setSelect={setSelect}
          setKeyword={setKeyword}
          keyword={keyword}
          fetchDriverList={fetchDriverList}
        />
        <DriverList
          ref={listRef.ref}
          driverState={driverState}
          PER_PAGE={PER_PAGE}
          totalElements={totalElements}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>

      {!isNil(driverName) && (
        <Toast
          text={`${driverName} 기사님이 탈퇴 처리 되었습니다.`}
          status={!isNil(driverName) ? 'toast' : ''}
        />
      )}
    </ScreenContainer>
  )
}

const DriverManagement = forwardRef(DriverManagementBase)
export default DriverManagement

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 13.8rem);
`
