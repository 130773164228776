import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import DispatchPeriodHeaderInfo from '../manage/DispatchPeriodHeaderInfo'
import {DispatchPeriodInputContainerRef} from '../manage/DispatchPeriodInputContainer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import Header from '../../../common/Header'
import useSecureRef from '../../../../hook/useSecureRef'
import {Optional} from '../../../../type/Common'
import {isEmptyString, isNil} from '../../../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import TextInput from '../../../input/TextInput'
import DispatchPeriodCopyInfo from './DispatchPeriodCopyInfo'
import Footer from '../../../common/Footer'
import InputHeader from '../../../input/InputHeader'
import {
  copyDispatchPeriod,
  CopyDispatchPeriodData,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import SemesterSelect from './SemesterSelect'
import {alertError} from '../../../../util/ErrorUtil'

type DispatchPeriodCopyModalProps = {
  dispatchPeriod: CDispatchPeriod
  onSubmit(): void
  onCancel(): void
  handleLoading(isLoading: boolean): void
}

function DispatchPeriodCopy(props: DispatchPeriodCopyModalProps): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodCopy.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodCopy.tsx] confirmModalRef',
  )
  const inputContainerRef = useSecureRef<DispatchPeriodInputContainerRef>(
    '[DispatchPeriodCopy.tsx] inputContainerRef',
  )

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [selectedSemesterID, setSelectedSemesterID] =
    useState<Optional<number>>(null)
  const [dispatchName, setDispatchName] = useState<string>('')

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const isSufficient = useMemo(() => {
    return (
      isConfirmed && !isNil(selectedSemesterID) && !isEmptyString(dispatchName)
    )
  }, [isConfirmed, selectedSemesterID, dispatchName])

  const onPressSubmitButton = useCallback(async () => {
    if (!isSufficient) {
      alert('필수 항목을 입력해주세요!!')
      return
    }

    confirmModalRef.current().show()
  }, [isSufficient])

  const onSubmitCopy = useCallback(() => {
    props.handleLoading(true)
    const data: CopyDispatchPeriodData = {
      semesterID: selectedSemesterID,
      name: dispatchName,
      academyID: academyID,
      dispatchPeriodID: props.dispatchPeriod.dispatchPeriod.id,
    }

    copyDispatchPeriod(data)
      .then(() => {
        props.onSubmit()
        props.handleLoading(false)
      })
      .catch(error => {
        props.handleLoading(false)
        alertError(
          error,
          `copyDispatchPeriod() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    props.dispatchPeriod,
    isSufficient,
    dispatchName,
    selectedSemesterID,
    academyID,
    props.onSubmit,
    props.handleLoading,
  ])

  const onChangeConfirm = useCallback(() => {
    setIsConfirmed(prev => {
      return !prev
    })
  }, [setIsConfirmed])

  const onChangeName = useCallback(
    (t: string) => {
      setDispatchName(t)
    },
    [setDispatchName],
  )

  const onChangeSemesterID = useCallback(
    (sid: number) => {
      setSelectedSemesterID(sid)
    },
    [setSelectedSemesterID],
  )

  return (
    <Container>
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'배차 기간 복제 확인'}
        content={'배차 기간을 복제 하시겠습니까?'}
        onSubmit={onSubmitCopy}
      />
      <ConfirmModal
        ref={cancelConfirmModalRef.ref}
        header={'배차 기간 복제 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n배차 기간 복제를 취소 하시겠습니까?'
        }
        onSubmit={props.onCancel}
      />

      <StyledHeader>배차 기간 복제</StyledHeader>
      <DispatchPeriodHeaderInfo />
      <InputsContainer>
        <DispatchPeriodCopyInfo
          confirmed={isConfirmed}
          onPress={onChangeConfirm}
        />
        <TopContainer>
          <SemesterContainer>
            <SemesterHeader>선택한 시간표</SemesterHeader>
            <SemesterTextInput
              readOnly={true}
              required={false}
              default={props.dispatchPeriod.dispatchPeriod.name}
            />
          </SemesterContainer>
          <SemesterSelect
            originalSemesterID={props.dispatchPeriod.dispatchPeriod.semesterID}
            onChangeSemesterID={onChangeSemesterID}
          />
        </TopContainer>

        <ContentContainer>
          <DispatchInfoHeader>배차 이용 기간</DispatchInfoHeader>
          <ColumnInputContainer>
            <InputHeader required={true} header={'배차 기간명'} />
            <TextInput
              readOnly={false}
              required={true}
              placeholder={'배차 이용 기간명을 입력해주세요.(ex :1학기)'}
              onChange={onChangeName}
            />
          </ColumnInputContainer>
        </ContentContainer>
      </InputsContainer>
      <Footer
        submitText={'등록'}
        onSubmit={onPressSubmitButton}
        onCancel={showCancelConfirmModal}
        isSufficient={isSufficient}
      />
    </Container>
  )
}

export default DispatchPeriodCopy

const ContentContainer = styled.div`
  ${flexColumn};
  margin-top: 1.6rem;
  row-gap: 0.8rem;
`

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const DispatchInfoHeader = styled.h2`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const ColumnInputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InputsContainer = styled.div`
  flex: 1;
  padding: 1.6rem;
`

const SemesterContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 0.8rem;
  ${flexRow};
  align-items: center;
  padding: 0.8rem 1.2rem;
  column-gap: 0.4rem;
  border: 0.1rem solid #ebebeb;
`

const SemesterHeader = styled.div`
  color: #999999;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  width: 16rem;
`

const SemesterTextInput = styled(TextInput)``

const TopContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`
