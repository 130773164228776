import React, {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import {isEmptyString, isNil} from '../../util/ValidationUtil'
import Reset from '../../asset/image/close_yellow.svg'
import styled from 'styled-components'
import {flexRow} from '../../style/CommonStyle'
import SVGImage from '../common/SVGImage'
import {Optional} from '../../type/Common'

type TimeInputProps = {
  readOnly: boolean
  notEditable?: boolean
  default?: string
  containerStyle?: CSSProperties
  onChange?(value: string): void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>
export type TimeInputRef = {
  getValue(): string
  reset(): void
  setDefault(d: string): void
}

function TimeInputBase(
  props: TimeInputProps,
  ref: ForwardedRef<TimeInputRef>,
): ReactElement {
  const getDefaultHour = useCallback((d: string) => {
    if (isNil(d)) {
      return null
    }
    const temp = d.slice(0, 2)

    const nv = Number(temp)

    if (isNil(nv) || isNaN(nv)) {
      return null
    }

    const sv = String(nv)

    return sv.length === 1 ? `0${sv}` : sv
  }, [])

  const getDefaultMinute = useCallback((d: string) => {
    if (isNil(d)) {
      return null
    }
    const temp = d.slice(3, 5)

    const nv = Number(temp)

    if (isNil(nv) || isNaN(nv)) {
      return null
    }

    if (nv === 0) {
      return '00'
    }

    const sv = String(nv)

    return sv.length === 1 ? `0${sv}` : sv
  }, [])

  const [hourValue, setHourValue] = useState<Optional<string>>(
    getDefaultHour(props.default),
  )
  const [minuteValue, setMinuteValue] = useState<Optional<string>>(
    getDefaultMinute(props.default),
  )

  const onChangeHour = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value
      const nv = Number(v)

      if (isNil(nv) || isNaN(nv)) {
        alert('숫자만 입력해주세요.')
        return
      }

      if (nv > 23) {
        setHourValue('00')
      } else {
        setHourValue(v)
      }

      if (isEmptyString(minuteValue)) {
        setMinuteValue('00')
      }

      if (!isNil(props.onChange)) {
        const sv = nv > 23 ? '00' : String(nv)
        const t = sv.length === 1 ? `0${sv}` : sv

        const m = isEmptyString(minuteValue)
          ? '00'
          : minuteValue.length === 1
            ? `0${minuteValue}`
            : minuteValue

        props.onChange(`${t}:${m}`)
      }
    },
    [setHourValue, minuteValue, props.onChange],
  )

  const onChangeMinute = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value
      const nv = Number(v)

      if (isNil(nv) || isNaN(nv)) {
        alert('숫자만 입력해주세요.')
        return
      }

      if (nv > 59) {
        setMinuteValue('00')
      } else {
        setMinuteValue(v)
      }

      if (!isNil(props.onChange)) {
        const sv = nv > 59 ? '00' : String(nv)
        const t = sv.length === 1 ? `0${sv}` : sv

        const h = isEmptyString(hourValue)
          ? '00'
          : hourValue.length === 1
            ? `0${hourValue}`
            : hourValue

        props.onChange(`${h}:${t}`)
      }
    },
    [setHourValue, hourValue, props.onChange],
  )

  const onReset = useCallback(() => {
    if (props.notEditable || props.readOnly) {
      return
    }

    setHourValue('')
    setMinuteValue('')
    if (!isNil(props.onChange)) {
      props.onChange('')
    }
  }, [setHourValue, props.onChange, props.readOnly, props.notEditable])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): Optional<string> {
        if (isEmptyString(hourValue) || isEmptyString(minuteValue)) {
          return null
        }

        let hv = hourValue
        if (hv.length === 1) {
          hv = `0${hv}`
        }
        let mv = minuteValue
        if (mv.length === 1) {
          mv = `0${mv}`
        }

        return `${hv}:${mv}`
      },
      reset(): void {
        setHourValue(null)
        setMinuteValue(null)
      },
      setDefault(d: string): void {
        setHourValue(getDefaultHour(d))
        setMinuteValue(getDefaultMinute(d))
      },
    }),
    [hourValue, minuteValue, getDefaultMinute, getDefaultHour, setHourValue],
  )

  return (
    <Container
      readOnly={props.readOnly}
      hasValue={!isEmptyString(hourValue) || !isEmptyString(minuteValue)}
      style={props.containerStyle}>
      <InputContainer>
        <Input
          {...props}
          contentEditable={!props.readOnly && !props.notEditable}
          placeholder={'--'}
          value={hourValue}
          onChange={onChangeHour}
          maxLength={3}
        />
        :
        <Input
          {...props}
          contentEditable={!props.readOnly && !props.notEditable}
          placeholder={'--'}
          value={minuteValue}
          onChange={onChangeMinute}
          maxLength={3}
        />
      </InputContainer>
      <ResetButton
        onClick={onReset}
        notEditable={props.notEditable}
        visible={
          (!isEmptyString(hourValue) || !isEmptyString(minuteValue)) &&
          !props.readOnly
        }>
        <ResetImage source={Reset} />
      </ResetButton>
    </Container>
  )
}

const TimeInput = forwardRef(TimeInputBase)
export default TimeInput

const Container = styled.div<{hasValue: boolean; readOnly: boolean}>`
  ${flexRow};
  width: 6rem;
  height: 2.6rem;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.6rem;
  border: 0.1rem solid #ebebeb;
  column-gap: 0.1rem;
  background: ${props =>
    props.readOnly ? '#F5F5F5' : props.hasValue ? '#fffbe5' : '#ffffff'};
`

const ResetButton = styled.button<{visible: boolean; notEditable: boolean}>`
  display: ${props =>
    props.notEditable ? 'none' : props.visible ? 'block' : 'none'};
  border: none;
  width: 1.4rem;
  height: 1.4rem;
`

const ResetImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const InputContainer = styled.div`
  ${flexRow};
  align-items: center;
  width: 3.6rem;
`

const Input = styled.input<{value: string; readOnly: boolean}>`
  width: 100%;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  background: transparent;
  color: ${props => (props.readOnly ? '#555' : '#000000')};
  cursor: ${props => (props.readOnly ? 'no-drop' : 'pointer')};
  &::placeholder {
    font-size: 1.2rem;
    color: #cccccc;
  }
`
