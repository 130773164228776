import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import InputHeader from '../../../input/InputHeader'
import ArrowDown from '../../../../asset/image/arrowDown.svg'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import {Optional} from '../../../../type/Common'
import {isNil} from '../../../../util/ValidationUtil'
import {CSemester} from '../../../../model/Semester'
import useSecureRef from '../../../../hook/useSecureRef'
import DispatchSemesterSelectInputModal, {
  DispatchSemesterSelectInputModalRef,
} from './DispatchSemesterSelectInputModal'

type Props = {
  onChangeSemester(sid: number): void
}
export type DispatchSemesterSelectContainerRef = {
  getSelectedSemester(): CSemester
}

function DispatchSemesterSelectContainerBase(
  props: Props,
  ref: ForwardedRef<DispatchSemesterSelectContainerRef>,
): ReactElement {
  const inputModalRef = useSecureRef<DispatchSemesterSelectInputModalRef>(
    '[DispatchSemesterSelectContainer.tsx] inputModalRef',
  )
  const [selectedSemester, setSelectedSemester] =
    useState<Optional<CSemester>>(null)

  const inputText = useMemo(() => {
    if (isNil(selectedSemester)) {
      return '배차 등록할 학기와 수업시간을 선택해주세요.'
    }

    return selectedSemester.name
  }, [selectedSemester])

  const rangText = useMemo(() => {
    if (isNil(selectedSemester)) {
      return '-'
    }

    return `${selectedSemester.startDate.toString()} ~ ${selectedSemester.endDate.toString()}`
  }, [selectedSemester])

  const showModal = useCallback(() => {
    inputModalRef.current().show()
  }, [])

  const onChangeSemester = useCallback(
    (ss: CSemester) => {
      setSelectedSemester(ss)
      props.onChangeSemester(ss.id)
    },
    [setSelectedSemester, props.onChangeSemester],
  )

  useImperativeHandle(
    ref,
    () => ({
      getSelectedSemester(): CSemester {
        return selectedSemester
      },
    }),
    [selectedSemester],
  )

  return (
    <SelectContainer>
      <StyledInputHeader header={'학기 및 수업시간 선택'} required={true} />
      <InputContainer>
        <DispatchSemesterSelectInputModal
          isCopy={false}
          ref={inputModalRef.ref}
          onChange={onChangeSemester}
          selectedSemesterID={selectedSemester?.id}
        />
        <TimeScheduleInput
          hasValue={!isNil(selectedSemester)}
          onClick={showModal}>
          <TimeScheduleInputText hasValue={!isNil(selectedSemester)}>
            {inputText}
          </TimeScheduleInputText>
          <ArrowDownImage source={ArrowDown}></ArrowDownImage>
        </TimeScheduleInput>
      </InputContainer>
      <RangeText hasValue={!isNil(selectedSemester)}>
        기간 : {rangText}
      </RangeText>
    </SelectContainer>
  )
}

const DispatchSemesterSelectContainer = forwardRef(
  DispatchSemesterSelectContainerBase,
)
export default DispatchSemesterSelectContainer

const SelectContainer = styled.div`
  width: 100%;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
`

const StyledInputHeader = styled(InputHeader)`
  padding: 0.5rem 0.2rem;
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const TimeScheduleInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  width: 100%;
  height: 3rem;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  border: 0.1rem solid #ebebeb;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
  margin-top: 0.7rem;
`

const TimeScheduleInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
`

const RangeText = styled(TimeScheduleInputText)`
  padding-left: 0.6rem;
  margin-top: 0.2rem;
  color: ${props => (props.hasValue ? '#665300' : '#cccccc')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
