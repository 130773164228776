import React, {ForwardedRef, forwardRef, ReactElement, useImperativeHandle, useState} from 'react'
import {GetBusOperationDetailParams} from '../../../service/busOperation/BusOperation'
import styled from 'styled-components'
import {color, flexColumn} from '../../../style/CommonStyle'
import OperationDetailHeader from './OperationDetailHeader'
import OperationDetailContainer from './OperationDetailContainer'
import {isNil} from '../../../util/ValidationUtil'
import {Optional} from '../../../type/Common'
import {CDate} from '../../../model/Date'

type OperationDetailProps = {}
export type OperationDetailRef = {
  getBusOperationDetailParams(data: GetBusOperationDetailParams): void
  setDate(date: CDate): void
}

function OperationDetailBase(props: OperationDetailProps, ref: ForwardedRef<OperationDetailRef>): ReactElement {
  const [date, setDate] = useState<CDate>(CDate.now())
  const [detailParams, setDetailParams] = useState<Optional<GetBusOperationDetailParams>>()

  useImperativeHandle(
    ref,
    () => ({
      getBusOperationDetailParams(data: GetBusOperationDetailParams): void {
        setDetailParams(data)
      },
      setDate(date: CDate): void {
        setDate(date)
      },
    }),
    [],
  )

  if (isNil(detailParams)) {
    return null
  }

  return (
    <Container>
      <OperationDetailHeader code={detailParams.code} date={date} />
      <OperationDetailContainer detailParams={detailParams} date={date} />
    </Container>
  )
}

const OperationDetail = forwardRef(OperationDetailBase)
export default OperationDetail

const Container = styled.div`
  flex: 1;
  background-color: ${color.white};
  width: 100%;
  height: calc(100vh - 4rem);
  ${flexColumn};
  border-radius: 3rem;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`
