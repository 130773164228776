import React, {ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import SVGImage from '../../../../../common/SVGImage'
import Start from '../../../../../../asset/image/station_start.svg'
import Arrive from '../../../../../../asset/image/station_arrive.svg'
import Number from '../../../../../../asset/image/station_number2.svg'
import {CDispatchDetailDispatch} from '../../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import {
  isEmptyArray,
  isEmptyString,
  orElse,
} from '../../../../../../util/ValidationUtil'

type Props = {
  dispatch: CDispatchDetailDispatch
  isFirstItem: boolean
  isLastItem: boolean
  index: number
  type: OccupancyTypeEnum
}

export default function DispatchDetailInfoItem(props: Props): ReactElement {
  const StationBadgeComponent = useMemo(() => {
    const isAvailable = props.type !== OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE

    if (props.isFirstItem) {
      return (
        <StationIconContainer>
          <StationIcon source={isAvailable ? Start : Arrive} />
          <StartContent style={{color: isAvailable ? '#1a468b' : '#333333'}}>
            출발
          </StartContent>
        </StationIconContainer>
      )
    }

    if (props.isLastItem) {
      return (
        <StationIconContainer>
          <StationIcon source={Arrive} />
          <ArriveContent>도착</ArriveContent>
        </StationIconContainer>
      )
    }

    return (
      <StationIconContainer style={{opacity: isAvailable ? 1 : 0.5}}>
        <StationIcon source={isAvailable ? Number : Arrive} />
        <ArriveContent>{props.index}</ArriveContent>
      </StationIconContainer>
    )
  }, [props.isLastItem, props.type, props.isFirstItem])

  const headerColor = useMemo(() => {
    if (props.type === OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE) {
      return '#999999'
    }

    if (props.isFirstItem) {
      return '#1A468B'
    }

    if (props.isLastItem) {
      return '#404040'
    }

    return '#665300'
  }, [props.isLastItem, props.isFirstItem, props.type])

  const StudentListComponent = useMemo(() => {
    if (isEmptyArray(props.dispatch.students)) {
      return null
    }

    return (
      <StudentListContainer>
        {props.dispatch.students.map(s => (
          <StudentTextContainer key={s.name}>
            <StudentName>{s.name}</StudentName>
            <StudentPhone>
              ({isEmptyString(s.nickName) ? '-' : s.nickName} /{' '}
              {s.parentPhone.slice(9)})
            </StudentPhone>
          </StudentTextContainer>
        ))}
      </StudentListContainer>
    )
  }, [props.dispatch])

  const TimeComponent = useMemo(() => {
    if (props.type === OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE) {
      return <Time style={{color: '#cccccc'}}>--:--</Time>
    }

    return <Time>{props.dispatch.station.time}</Time>
  }, [props.type])

  return (
    <Container>
      {StationBadgeComponent}
      {TimeComponent}
      <ContentContainer>
        <StationName color={headerColor}>
          {props.dispatch.station.name}
        </StationName>
        {StudentListComponent}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  align-items: flex-start;
  column-gap: 1rem;
  background: #ffffff;
  padding: 0.8rem 0.8rem;
`

const StationIconContainer = styled.div`
  width: 1.867rem;
  height: 2.133rem;
  position: relative;
  ${flexColumn};
  align-items: center;
  justify-content: center;
`

const StationIcon = styled(SVGImage)`
  width: 1.867rem;
  height: 2.133rem;
  color: red;
`

const StationContent = styled.div`
  position: absolute;
  color: #665300;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.1rem;
`

const StartContent = styled(StationContent)`
  font-size: 0.73rem;
`

const ArriveContent = styled(StationContent)`
  font-size: 0.73rem;
  color: #000000;
`

const ContentContainer = styled.div`
  ${flexColumn};
  flex: 1;
  row-gap: 0.3rem;
`

const Time = styled.div`
  color: #332a00;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.1rem;
  width: 3.6rem;
`

const StationName = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.1rem;
`

const StudentListContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  row-gap: 0.4rem;
  flex-wrap: wrap;
`

const StudentTextContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const StudentName = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
`

const StudentPhone = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
`
