import React from 'react'
import CourseRowItem from '../CourseRowItem'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../style/CommonStyle'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../../model/Dispatch'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {ChangeStudentData} from '../extra/LoadStudentExtra'

type Props = {
  courseWithStudent: CDispatchCourseWithStudent[]
  dispatchType: DispatchTypeEnum
  onChangeStudent(data: ChangeStudentData): void
}
export default function CoursesNormal(props: Props) {
  return (
    <Container>
      {props.courseWithStudent.map((course, idx) => {
        const isFirstItem = idx === 0
        const isLastItem = idx === props.courseWithStudent.length - 1
        const selectedStudents = course.students.filter(s => s.check)
        const onChangeStudent = (s?: CStudentWithCheck, t?: string) =>
          props.onChangeStudent({
            course: course,
            student: s,
            time: t,
          })

        return (
          <CourseRowItem
            key={idx}
            course={course}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            dispatchType={props.dispatchType}
            onChangeStudent={onChangeStudent}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  row-gap: 0.4rem;
  background: #f5f5f5;
`
