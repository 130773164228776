import React from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../../../../../../style/CommonStyle'
import CourseBox from './CourseBox'
import {CDispatchCourseWithStudent} from '../../../../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../../../../enum/OccupancyTypeEnum'
import {Optional} from '../../../../../../../../type/Common'
import {CCourse} from '../../../../../../../../model/Course'

type Props = {
  courses: CDispatchCourseWithStudent[]
  extraCourses: CDispatchCourseWithStudent[]
  busName: string
  extraBusName: Optional<string>
  dispatchCode: string
  type: OccupancyTypeEnum
  selectedCourse: Optional<CCourse>
}

export default function CourseContainer(props: Props) {
  return (
    <Container>
      <CourseBox
        busName={props.busName}
        courses={props.courses}
        occupancyType={props.type}
      />
      <CourseBox
        busName={props.extraBusName}
        courses={props.extraCourses}
        occupancyType={props.type}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`
