import React from 'react'
import styled from 'styled-components'
import {flexRow} from '../../style/CommonStyle'
import SVGImage from '../common/SVGImage'
import SuccessIcon from '../../asset/image/status_success.svg'

type ToastProps = {
  text: string
  status: string
}

function Toast(props: ToastProps) {
  return (
    <ToastWrapper status={props.status}>
      <Img source={SuccessIcon} />
      <Text>{props.text}</Text>
    </ToastWrapper>
  )
}

export default Toast

type ToastAnimationProps = {
  status: string
}

const ToastWrapper = styled.div<ToastAnimationProps>`
  position: absolute;
  transform: translateY(2.6rem);
  transition: top 0.5s ease-in-out;
  top: ${props => (props.status === 'toast' ? '2.6rem' : '-10rem')};
  left: 40%;
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  border-radius: 12rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  z-index: 9999999;
`

const Img = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const Text = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
  color: #fff;
`
