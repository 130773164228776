import React, {
  ForwardedRef,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import Select from '../../input/Select'
import SearchInput from '../../input/SearchInput'

type ManagerListProps = {
  setSelect: React.Dispatch<SetStateAction<string>>
  setKeyword: React.Dispatch<SetStateAction<string>>
  keyword: string
}
export type ManagerListRef = {}

const managerOptions = [
  {
    idx: 0,
    exposure: '아이디',
    value: 'ID',
  },
  {
    idx: 1,
    exposure: '학생 이름',
    value: 'NAME',
  },
  {
    idx: 2,
    exposure: '전화번호',
    value: 'PHONE',
  },
  {
    idx: 3,
    exposure: 'NFC 카드',
    value: 'NFC_CARD',
  },
]

function StudentSearchBase(
  props: ManagerListProps,
  ref: ForwardedRef<ManagerListRef>,
) {
  const onChangeSelectValue = (v: string) => {
    props.setSelect(v)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const onChangeText = useCallback(
    (t: string) => {
      props.setKeyword(t)
    },
    [props.keyword],
  )

  return (
    <Container>
      <SelectSearchContainer>
        <Select
          options={managerOptions}
          onChange={(value: string) => onChangeSelectValue(value)}
          width={8.8}
          height={3.6}
          fontSize={1.2}
          defaultIdx={0}
          boxShadow={false}
          border={false}
        />
        <Line />
        <SearchInput
          onChangeText={onChangeText}
          placeHolder={'검색어를 입력해주세요.'}
          border={false}
          width={21}
          onKeyDown={handleKeyDown}
        />
      </SelectSearchContainer>
    </Container>
  )
}

const StudentSearch = forwardRef(StudentSearchBase)
export default StudentSearch

const Container = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 5.2rem;
  ${flexRow}
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const SelectSearchContainer = styled.div`
  ${flexRow};
  align-items: center;
  min-height: 3.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background-color: ${color.white};

  :focus-within {
    border-color: #ffe880;
    background: #fffbe5;

    input,
    div {
      background: #fffbe5;
    }
  }
`

const RegisterManagerButton = styled.button`
  ${flexRow};
  background-color: #ffcd00;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  align-items: center;
  border: none;
  border-radius: 3.4rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
`

const PlusImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`

const Line = styled.span`
  width: 0.1rem;
  height: 2rem;
  background: #d9d9d9;
  margin-right: 0.4rem;
`
