import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {Header} from '../registerTicket/RegisterTicket'
import SelectDate, {SelectDateRef} from './SelectDate'
import StudentList, {StudentListRef} from './StudentList'
import {CDispatchDetail, CStudent} from '../../../model/Dispatch'
import {Optional} from '../../../type/Common'
import {CDate} from '../../../model/Date'
import {color, flexRow} from '../../../style/CommonStyle'
import useSecureRef from '../../../hook/useSecureRef'
import {
  deleteStudentSchedules,
  DeleteStudentSchedulesRequestBody,
} from '../../../service/schedule/Schedule'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import HalfPopUp, {PopUpRef} from '../../common/HalfPopUp'
import {
  DeleteTicketConfirmPopUp,
  DeleteTicketPopUp,
} from '../../modal/PopUpContents'
import {CDateTime} from '../../../model/DateTime'
import {BaseDateUnitEnum} from '../../../enum/DateUnitEnum'
import {isNil} from '../../../util/ValidationUtil'

type DeleteTicketProps = {
  dispatchDetail: CDispatchDetail
  startDate: Optional<CDate>
  endDate: Optional<CDate>
  onDeleteTicket(): void
  hideDeleteModal(): void
  fetchDispatchDetail(dateRange: {start: CDate; end: CDate}): void
}
export type DeleteTicketRef = {
  resetList(): void
}

function DeleteTicketBase(
  props: DeleteTicketProps,
  ref: ForwardedRef<DeleteTicketRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const dateRef = useSecureRef<SelectDateRef>('[DeleteTicket.tsx] dateRef')
  const listRef = useSecureRef<StudentListRef>('[DeleteTicket.tsx] listRef')
  const deleteRef = useSecureRef<PopUpRef>('[DeleteTicket.tsx] deleteRef')
  const deleteConfirmRef = useSecureRef<PopUpRef>(
    '[DeleteTicket.tsx] deleteConfirmRef',
  )
  const [students, setStudents] = useState<CStudent[]>([])
  const [deletePressable, setDeletePressable] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Optional<CDate>>(null)

  const getDefaultTotalStudents = useCallback(() => {
    const temp: CStudent[] = []
    for (const course of props.dispatchDetail.courses) {
      for (const student of course.station.students) {
        if (temp.some(t => t.id === student.id)) {
          continue
        }

        temp.push(student)
      }
    }

    setStudents(temp)
  }, [props.dispatchDetail.courses])

  const onSubmit = useCallback(async () => {
    const studentIDs = listRef.current().getStudentIDs()
    const sd = dateRef.current().getDate()

    const data: DeleteStudentSchedulesRequestBody = {
      deleteStartDate: sd,
      dispatchCode: props.dispatchDetail.dispatchCode,
      studentIds: studentIDs,
    }

    try {
      await deleteStudentSchedules(academyID, data)
      deleteRef.current().hide()
      deleteConfirmRef.current().show()
    } catch (error) {
      alert('스케줄 삭제에 실패하였습니다.')
      throw new Error(
        `failed to delete student schedules. (data: ${JSON.stringify(
          data,
        )}, error:${error})`,
      )
    }

    listRef.current().reset()
  }, [props.dispatchDetail, deletePressable])

  const handleDeletePressable = useCallback((value: boolean) => {
    setDeletePressable(value)
  }, [])

  const handleDeleteButton = useCallback(() => {
    deleteRef.current().show()
  }, [])

  const onClose = useCallback(() => {
    deleteRef.current().hide()
    listRef.current().reset()
  }, [])

  const handleConfirmButton = useCallback(() => {
    deleteConfirmRef.current().hide()
    props.fetchDispatchDetail({
      start: props.startDate,
      end: props.endDate,
    })
    props.hideDeleteModal()
    dateRef
      .current()
      .setDate(CDateTime.now().add(1, BaseDateUnitEnum.DAY).toDate())
    props.onDeleteTicket()
  }, [
    props.fetchDispatchDetail,
    props.startDate,
    props.endDate,
    props.hideDeleteModal,
    props.onDeleteTicket,
  ])

  const onChangeDate = useCallback((d: CDate) => {
    setSelectedDate(d)
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      resetList() {
        listRef.current().reset()
      },
    }),
    [],
  )

  useEffect(() => {
    getDefaultTotalStudents()
  }, [props.dispatchDetail.courses])

  return (
    <Container>
      <Header>승차권 삭제</Header>
      <Grid>
        <SelectDate
          ref={dateRef.ref}
          startDate={props.startDate}
          endDate={props.endDate}
          onChangeDate={onChangeDate}
        />
        <StudentList
          ref={listRef.ref}
          students={students}
          handleDeletePressable={handleDeletePressable}
        />
      </Grid>
      <Bottom>
        <CancelButton onClick={props.hideDeleteModal}>취소</CancelButton>
        <RegisterButton
          pressable={deletePressable}
          onClick={!deletePressable ? () => {} : handleDeleteButton}>
          삭제
        </RegisterButton>
      </Bottom>
      <HalfPopUp
        width={'36rem'}
        height={'auto'}
        top={'40%'}
        right={'30%'}
        ref={deleteRef.ref}
        contents={
          <DeleteTicketPopUp
            onClose={onClose}
            date={
              isNil(selectedDate)
                ? // TODO: 최선어학원 당일 등록 임시 요청
                  // ? CDateTime.now().add(1, BaseDateUnitEnum.DAY).toDate().toString()
                  CDateTime.now().toDate().toString()
                : selectedDate.toString()
            }
            onClickEditButton={onSubmit}
          />
        }
      />
      <HalfPopUp
        width={'36rem'}
        height={'auto'}
        top={'40%'}
        right={'30%'}
        ref={deleteConfirmRef.ref}
        contents={
          <DeleteTicketConfirmPopUp confirmButton={handleConfirmButton} />
        }
      />
    </Container>
  )
}

const DeleteTicket = forwardRef(DeleteTicketBase)
export default DeleteTicket

const Container = styled.div`
  border-radius: 1.6rem 0 0 1.6rem;
  background: #f5f5f5;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 35rem calc(100% - 37rem);
  column-gap: 20px;
  overflow: auto;
  padding: 2rem;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`
const Bottom = styled.div`
  height: 6.8rem;
  padding: 1.6rem 2.4rem;
  background: ${color.white};
  border-radius: 0 0 0 1.6rem;
  justify-content: end;
  ${flexRow}
`

const CancelButton = styled.button`
  padding: 1rem 2.2rem;
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 3rem;
  margin-left: 0.4rem;
`

const RegisterButton = styled(CancelButton)<{pressable: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.pressable ? 1 : 0.3)};
  cursor: ${props => (props.pressable ? 'pointer' : 'not-allowed')};
`
