import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import {
  getSemesterTime,
  GetSemesterTimeData,
} from '../../../../service/semester/Semester'
import {CClassTime} from '../../../../model/Semester'
import {WeekdayEnum} from '../../../../enum/WeekdayEnum'

type Props = {
  onChangeSelectableWeekdays(wds: WeekdayEnum[]): void
}
export type DispatchSemesterSelectListRef = {
  fetchSemesterTime(data: GetSemesterTimeData): void
}

function DispatchSemesterSelectListBase(
  props: Props,
  ref: ForwardedRef<DispatchSemesterSelectListRef>,
): ReactElement {
  const [classTimes, setClassTimes] = useState<CClassTime[]>([])

  const fetchSemestersTime = useCallback(
    (data: GetSemesterTimeData) => {
      getSemesterTime(data)
        .then(st => {
          setClassTimes(st)
          props.onChangeSelectableWeekdays(st.map(el => el.weekday))
        })
        .catch(error => {
          throw new Error(
            `getSemestersTime() failed. (aid: ${data.academyID}, sid: ${data.semesterID}, error: ${error}))`,
          )
        })
    },
    [props.onChangeSelectableWeekdays],
  )

  const ListComponent = useMemo(() => {
    return (
      <ListContainer>
        {classTimes.map((ct, idx) => {
          return (
            <ListItemContainer key={`${idx}_${ct.weekday.value}`}>
              <ListItemHeader>{ct.weekday.exposure}요일</ListItemHeader>
              <ListItemTimeContentContainer>
                <ListItemStartContainer>
                  <ListItemStartHeader>시작</ListItemStartHeader>
                  <TimeContainer>
                    {ct.startTimes.map((st, idx) => {
                      return <TimeNode key={`${st}_${idx}`}>{st}</TimeNode>
                    })}
                  </TimeContainer>
                </ListItemStartContainer>
                <ListItemEndContainer>
                  <ListItemEndHeader>종료</ListItemEndHeader>
                  <TimeContainer>
                    {ct.endTimes.map((et, idx) => {
                      return <TimeNode key={`${et}_${idx}`}>{et}</TimeNode>
                    })}
                  </TimeContainer>
                </ListItemEndContainer>
              </ListItemTimeContentContainer>
            </ListItemContainer>
          )
        })}
      </ListContainer>
    )
  }, [classTimes])

  useImperativeHandle(
    ref,
    () => ({
      fetchSemesterTime: fetchSemestersTime,
    }),
    [fetchSemestersTime],
  )

  return (
    <ContentContainer>
      <PeriodHeaderContainer>
        <PeriodHeaderText>요일별 수업 시간</PeriodHeaderText>
        <PeriodHeaderQuestionMark>
          <PeriodHeaderQuestionMarkText>?</PeriodHeaderQuestionMarkText>
        </PeriodHeaderQuestionMark>
      </PeriodHeaderContainer>
      {ListComponent}
    </ContentContainer>
  )
}

const DispatchSemesterSelectList = forwardRef(DispatchSemesterSelectListBase)
export default DispatchSemesterSelectList

const ContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 1.6rem;
  overflow: auto;
  margin-top: 1.6rem;

  ::-webkit-scrollbar {
    display: none;
  }
`

const PeriodHeaderContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const PeriodHeaderText = styled.h5`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  padding-left: 0.4rem;
`

const PeriodHeaderQuestionMark = styled.div`
  background: #333333;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 10rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

const PeriodHeaderQuestionMarkText = styled.div`
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
`

const ListContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const ListItemContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background: #ffffff;
`

const ListItemHeader = styled.div`
  padding: 0.8rem 1.6rem 0.4rem 1.6rem;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #665300;
  border-bottom: 0.1rem solid #ebebeb;
`

const ListItemTimeContentContainer = styled.div`
  ${flexColumn};
  padding: 0.4rem 1.2rem 1.2rem 1.2rem;
  row-gap: 0.6rem;
`

const ListItemStartContainer = styled.div`
  ${flexColumn};
`

const ListItemStartHeader = styled.div`
  color: #4b84dd;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
`

const ListItemEndContainer = styled.div`
  ${flexColumn};
`

const ListItemEndHeader = styled(ListItemStartHeader)`
  color: #009a17;
`

const TimeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 0.3rem;
  column-gap: 0.3rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TimeNode = styled.div`
  width: 4.8rem;
  height: 2.1rem;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.4rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
`
