import React, {ForwardedRef, forwardRef, ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import Calendar from '../../asset/image/calendar_blue.svg'
import {CDate} from '../../model/Date'
import {isEmptyString} from '../../util/ValidationUtil'
import SVGImage from '../common/SVGImage'
import ArrowDown from '../../asset/image/arrowDown.svg'
import {flexRow} from '../../style/CommonStyle'

type DatePickerInputProps = {
  onClick?(): void
  value?: string
  placeholder?: string
  readOnly: boolean
  customStyle?: React.CSSProperties
}
export type DatePickerInputRef = {}

function DatePickerInputBase(
  props: DatePickerInputProps,
  ref: ForwardedRef<DatePickerInputRef>,
): ReactElement {
  const date = useMemo(() => {
    if (isEmptyString(props.value)) {
      return CDate.now()
    }

    return CDate.create(props.value)
  }, [props.value])

  const value = useMemo(() => {
    if (isEmptyString(props.value)) {
      return props.placeholder
    }

    return `${date.toString()}`
  }, [date, props.placeholder])

  return (
    <DatePickerButton
      onClick={props.onClick}
      readOnly={props.readOnly}
      hasValue={!isEmptyString(props.value)}
      style={props.customStyle}>
      <ContentContainer>
        <CalendarImg />
        <LabelWrapper>
          <DatePickerText
            hasValue={!isEmptyString(props.value)}
            readOnly={props.readOnly}>
            {value}
          </DatePickerText>
        </LabelWrapper>
      </ContentContainer>
      <ArrowDownImage source={ArrowDown} readOnly={props.readOnly} />
    </DatePickerButton>
  )
}

const DatePickerInput = forwardRef(DatePickerInputBase)
export default DatePickerInput

const DatePickerButton = styled.button<{hasValue: boolean; readOnly: boolean}>`
  ${flexRow};
  justify-content: flex-start;
  border: 0.1rem solid #ebebeb;
  padding-left: 0.8rem;
  width: 100%;
  height: 3rem;
  align-items: center;
  background: ${props =>
    props.readOnly ? '#f5f5f5' : props.hasValue ? '#FFFBE5' : '#ffffff'};
  border-radius: 0.8rem;

  &:hover {
    cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
    border: ${props => (props.readOnly ? 'none' : '0.1rem solid #ffcd00')};
  }

  &:focus {
    border: ${props => (props.readOnly ? 'none' : '0.1rem solid #ffcd00')};
  }
`

const ContentContainer = styled.div`
  ${flexRow};
  align-items: center;
  width: 100%;
`

const CalendarImg = styled.div`
  background: url(${Calendar}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.4rem;
`

const LabelWrapper = styled.div`
  margin-right: 1rem;

  > label {
    cursor: pointer;
  }
`

const DatePickerText = styled.span<{hasValue: boolean; readOnly: boolean}>`
  font-size: 1.4rem;
  height: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${props =>
    props.readOnly ? '#999999' : props.hasValue ? '#000000' : '#cccccc'};
`

const ArrowDownImage = styled(SVGImage)<{readOnly: boolean}>`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.8rem;
  opacity: ${props => (props.readOnly ? 0.2 : 1)};
`
