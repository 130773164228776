export class BoardSwitchEnum {
  static readonly UNBOARD = new BoardSwitchEnum(0, 'WONT_BOARD', '안타요')
  static readonly BOARD = new BoardSwitchEnum(1, 'WILL_BOARD', '타요')
  static readonly ALL = [this.BOARD, this.UNBOARD]

  private constructor(
    readonly id: number,
    readonly value: string,
    readonly exposure: string,
  ) {}

  static parse(value: number) {
    switch (value) {
      case this.BOARD.id:
        return this.BOARD
      case this.UNBOARD.id:
        return this.UNBOARD
      default:
        throw new Error(`invalid value or type. (value: ${value})`)
    }
  }
}
