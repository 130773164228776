import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {API} from '../../Env'
import {Optional} from '../../type/Common'
import {DriverSearchType} from '../../enum/DriverSearchType'
import {CDriverVector} from '../../model/Driver'

export type GetDriversData = {
  academyID: string
  page?: number
  searchType?: DriverSearchType
  searchValue?: Optional<string>
  size?: number
}

export async function getDrivers(data: GetDriversData): Promise<CDriverVector> {
  const url = `${API}/${data.academyID}/drivers`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      searchType: data.searchType.value,
      searchValue: data.searchValue,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CDriverVector.create(axiosResponse.data.data)
}

type ExistDriverParams = {
  academyID: string
  phone: string
}

export async function existDriver(data: ExistDriverParams) {
  const url = `${API}/${data.academyID}/drivers/exists`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      phone: data.phone,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export type PostDriverParams = {
  name: string
  password: string
  phone: string
  userID: string
}

export async function postDriver(
  academyID: string,
  data: PostDriverParams,
): Promise<void> {
  const url = `${API}/${academyID}/drivers`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      name: data.name,
      password: data.password,
      phone: data.phone,
      userId: data.userID,
    },
  }

  await axios(config)
}

export async function postNonExistDriver(
  academyID: string,
  data: PostDriverParams,
) {
  const url = `${API}/${academyID}/drivers`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      name: data.name,
      password: data.password,
      phone: data.phone,
      userId: data.userID,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchDriver(academyID: string, driverID: string) {
  const url = `${API}/${academyID}/drivers/${driverID}`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function getDriverInfo(academyId: string, driverId: string) {
  const url = `${API}/${academyId}/drivers/${driverId}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getDriverDispatches(
  academyId: string,
  driverId: string,
  searchDate: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/dispatches`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      searchDate: searchDate,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function deleteDriver(academyId: string, driverId: string) {
  const url = `${API}/${academyId}/drivers/${driverId}/expire`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function getDriverMemo(academyId: string, driverId: string) {
  const url = `${API}/${academyId}/drivers/${driverId}/memo`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function patchDriverMemo(
  academyId: string,
  driverId: string,
  memo: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/memo`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      memo: memo,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data
}

export async function patchDriverName(
  academyId: string,
  driverId: string,
  name: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/name`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      name: name,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchDriverPassword(
  academyId: string,
  driverId: string,
  password: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/password`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      password: password,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchDriverPhone(
  academyId: string,
  driverId: string,
  phone: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/phone`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      phone: phone,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchDriverUserID(
  academyId: string,
  driverId: string,
  userId: string,
) {
  const url = `${API}/${academyId}/drivers/${driverId}/user-id`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      userId: userId,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type GetNotAssignedDriverParmas = {
  academyID: string
  page?: number
  searchType?: string
  searchValue?: string
  size?: number
  driverId?: number
}

export async function notAssignedDrivers(data: GetNotAssignedDriverParmas) {
  const url = `${API}/${data.academyID}/drivers/not-assigned`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      searchValue: data.searchValue,
      size: data.size,
      searchType: 'NAME',
      driverId: data.driverId,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}
