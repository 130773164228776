import React, {useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import {CBusBrief} from '../../../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../../../model/DispatchPeriod'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import {WeekdayEnum} from '../../../../../../enum/WeekdayEnum'
import {useRecoilValue} from 'recoil'
import {academyState} from '../../../../../../recoil/Atom'

export type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  weekday: WeekdayEnum
  classTime: string
  type: OccupancyTypeEnum
}

export default function BasicInfo(props: Props) {
  const academy = useRecoilValue(academyState)

  const periodName = useMemo(() => {
    return `${props.dispatchPeriod.name} `
  }, [props.dispatchPeriod])

  const periodInfo = useMemo(() => {
    return `${props.dispatchPeriod.startDate.toString()} ~ ${props.dispatchPeriod.endDate.toString()}`
  }, [props.dispatchPeriod])

  const busInfo = useMemo(() => {
    return `${props.busBrief.bus.name}(${props.busBrief.bus.regNum}) | ${props.busBrief.driver[0].name}(${props.busBrief.driver[0].phone}) | 가용 좌석 ${props.busBrief.bus.totalSeat}개`
  }, [props.busBrief])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>기본 정보</HeaderText>
        <HeaderInfoText>등록 학원 정보 : {academy.name}</HeaderInfoText>
      </HeaderContainer>

      <Wrapper>
        <InfoRowContainer>
          <InfoContainer style={{flex: 2}}>
            <Header>배차 기간</Header>
            <Info>
              {periodName}
              <br />
              {periodInfo}
            </Info>
          </InfoContainer>
          <InfoContainer style={{flex: 1}}>
            <Header>배차 요일</Header>
            <Info>{`${props.weekday.exposure}요일`}</Info>
          </InfoContainer>
          <InfoContainer style={{flex: 1.2}}>
            <Header>배차 시간</Header>
            <Info>{props.classTime}</Info>
          </InfoContainer>
        </InfoRowContainer>
        {props.type === OccupancyTypeEnum.FULL_SEAT ? null : (
          <InfoRowContainer>
            <InfoContainer>
              <Header>호차 정보</Header>
              <Info>{busInfo}</Info>
            </InfoContainer>
          </InfoRowContainer>
        )}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const HeaderInfoText = styled.div`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #3575d9;
`

const Wrapper = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
`

const InfoRowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const Header = styled.h5`
  width: 6rem;
  color: #999999;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const InfoContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 1;
`
const Info = styled.div`
  font-size: 12px;
  color: #999;
`
