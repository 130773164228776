import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: Pretendard;
    letter-spacing: -0.03rem;
  }

  body html {
    display: flex;
  }

  button {
    display: flex;
    cursor: pointer;
    outline: none;
    border-radius: 0.5rem;
  }

  input {
    display: flex;
    outline: none;
  }
`

export default GlobalStyle
