import React from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../../model/Dispatch'
import {ChangeStudentData} from './LoadStudentExtra'
import CoursesMovableItem from '../CoursesMovableItem'

type Props = {
  title: string
  courseName: string
  courses: CDispatchCourseWithStudent[]
  isStart: boolean
  onChangeStudent(data: ChangeStudentData): void
}

export default function CourseExtra(props: Props) {
  return (
    <CourseWrapper>
      <ExtraHeaderContainer>
        <ExtraHeader>{props.title}</ExtraHeader>
        <ExtraSubHeader>{props.courseName}</ExtraSubHeader>
      </ExtraHeaderContainer>
      {props.courses.map((course, idx) => {
        const isFirstItem = idx === 0
        const isLastItem = idx === props.courses.length - 1
        const selectedStudents = course.students.filter(s => s.check)
        const onChangeStudent = (s?: CStudentWithCheck, t?: string) =>
          props.onChangeStudent({
            course: course,
            student: s,
            time: t,
          })

        return (
          <CoursesMovableItem
            key={idx}
            course={course}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            isStart={props.isStart}
            onChangeStudent={onChangeStudent}
            selectedStudents={selectedStudents}
          />
        )
      })}
    </CourseWrapper>
  )
}

const CourseWrapper = styled.div`
  ${flexColumn};
  flex: 1;
  display: block;
`

const ExtraHeaderContainer = styled.div`
  width: 100%;
  height: 4.4rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  border-radius: 0.8rem 0.8rem 0 0;
  background: #332a00;
  padding: 0 1.2rem;
`

const ExtraHeader = styled.div`
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.39px;
`

const ExtraSubHeader = styled.div`
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.39px;
`
