export class PathEnum {
  static readonly SIGN_IN = new PathEnum('/auth/sign-in')
  static readonly HOME = new PathEnum('/home')
  static readonly CONTROL = new PathEnum('/control')
  static readonly TICKET = new PathEnum('/ticket')
  static readonly BUSES = new PathEnum('/buses')
  static readonly BUS_DETAIL = new PathEnum('/bus/:id')
  static readonly DISPATCH = new PathEnum('/dispatch')
  static readonly DRIVER = new PathEnum('/driver')
  static readonly DRIVER_DETAIL = new PathEnum('/driver/:id')
  static readonly MANAGER = new PathEnum('/manager')
  static readonly MANAGER_DETAIL = new PathEnum('/manager/:id')
  static readonly STATION = new PathEnum('/station')
  static readonly STUDENT = new PathEnum('/student')

  constructor(readonly path: string) {}
}
