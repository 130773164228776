import React, {useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import Select from '../../input/Select'
import {color, flexRow} from '../../../style/CommonStyle'
import {ContainerProps} from './StationSideBar'
import SearchInput from '../../input/SearchInput'
import {Optional} from '../../../type/Common'

const SelectOptions = [
  {
    idx: 0,
    exposure: '노선명',
    value: 'ROUTE',
  },
  {
    idx: 1,
    exposure: '주소',
    value: 'ADDRESS',
  },
  {
    idx: 2,
    exposure: '정류장명',
    value: 'NAME',
  },
  {
    idx: 3,
    exposure: '장소',
    value: 'PLACE',
  },
]

type SearchHeaderProps = {
  handleSearchButton?: () => void
  keyword: Optional<string>
  setKeyword: React.Dispatch<React.SetStateAction<Optional<string>>>
  detailCard: boolean
  handleReset: () => void
  select: string
  setSelect: React.Dispatch<React.SetStateAction<string>>
  setDetailCard: React.Dispatch<React.SetStateAction<boolean>>
  getDefaultCourseList?: () => void
}

function SearchHeader(props: SearchHeaderProps) {
  const onChangeSelectValue = (v: string) => {
    props.setSelect(v)
  }

  const onChangeText = useCallback(
    (t: string) => {
      props.setKeyword(t)
    },
    [props.keyword],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      e.nativeEvent.isComposing === false &&
      props.keyword?.length === 0 &&
      props.select === 'ROUTE'
    ) {
      e.preventDefault()
      props.getDefaultCourseList()
    }
    if (
      (e.key === 'Enter' &&
        e.nativeEvent.isComposing === false &&
        props.keyword?.length > 0 &&
        props.select === 'ROUTE') ||
      (e.key === 'Enter' &&
        e.nativeEvent.isComposing === false &&
        props.keyword?.length > 0 &&
        props.select !== 'ROUTE')
    ) {
      e.preventDefault()
      props.handleSearchButton()
    }
    if (
      e.key === 'Enter' &&
      e.nativeEvent.isComposing === false &&
      props.keyword?.length === 0 &&
      props.select !== 'ROUTE'
    ) {
      e.preventDefault()
      props.handleReset()
    }
  }

  const handleClick = () => {
    if (props.keyword?.length === 0 && props.select === 'ROUTE') {
      props.getDefaultCourseList()
    }
    if (
      (props.keyword?.length > 0 && props.select === 'ROUTE') ||
      (props.keyword?.length > 0 && props.select !== 'ROUTE')
    ) {
      props.handleSearchButton()
    }
    if (props.keyword?.length === 0 && props.select !== 'ROUTE') {
      props.handleReset()
    }
  }

  const handleDefaultIdx = useMemo(() => {
    if (props.select === 'ROUTE') {
      return 0
    }
    if (props.select === 'ADDRESS') {
      return 1
    }
    if (props.select === 'NAME') {
      return 2
    }
    if (props.select === 'PLACE') {
      return 3
    }
  }, [props.select])

  useEffect(() => {
    props.setDetailCard(false)
    props.getDefaultCourseList()
  }, [props.select, props.setDetailCard])

  return (
    <SearchHeaderWrapper sideDetailStatus={props.detailCard}>
      <SelectSearchContainer>
        <Select
          options={SelectOptions}
          onChange={(value: string) => onChangeSelectValue(value)}
          width={8.8}
          height={3.6}
          fontSize={1.2}
          defaultIdx={handleDefaultIdx}
          boxShadow={false}
          border={false}
        />
        <Line />
        <SearchInput
          onChangeText={onChangeText}
          placeHolder={'검색어를 입력해주세요.'}
          border={false}
          padding={'0 3rem 0 0'}
          width={14}
          onKeyDown={handleKeyDown}
          searchIconRight={'0.8rem'}
          handleClick={handleClick}
        />
      </SelectSearchContainer>
    </SearchHeaderWrapper>
  )
}

export default SearchHeader

const SearchHeaderWrapper = styled.div<ContainerProps>`
  padding: 1.2rem;
  border-radius: ${props => (props.sideDetailStatus ? '0' : '0 1.6rem 0 0')};
  background: #f5f5f5;
`

const SelectSearchContainer = styled.div`
  ${flexRow};
  align-items: center;
  min-height: 3.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background-color: ${color.white};

  :focus-within {
    border-color: #ffe880;
    background: #fffbe5;

    input,
    div {
      background: #fffbe5;
    }
  }
`

const Line = styled.span`
  width: 0.1rem;
  height: 2rem;
  background: #d9d9d9;
  margin-right: 0.4rem;
`
