import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useNavigate} from 'react-router'
import ScreenContainer from '../../component/layout/ScreenContainer'
import useSecureRef from '../../hook/useSecureRef'
import {ModalContainerRef} from '../../component/modal/ModalContainer'
import HalfPopup from '../../component/common/HalfPopUp'
import DeleteStation from '../../component/stationManagement/modalContents/DeleteStation'
import {Optional} from '../../type/Common'
import {CStation} from '../../model/Station'
import ModifyStation from '../../component/stationManagement/modalContents/ModifyStation'
import KakaoMap from '../../component/stationManagement/kakaoMap/KakaoMap'
import {deleteStation, putStation} from '../../service/station/Station'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../recoil/Atom'
import FailedDeleteStation from '../../component/stationManagement/modalContents/FailedDeleteStation'
import DeleteConfirm from '../../component/stationManagement/modalContents/DeleteConfirm'
import ConfirmModifyStation from '../../component/stationManagement/modalContents/ConfirmModifyStation'
import Toast from '../../component/toast/Toast'
import CourseDelete from '../../component/stationManagement/modalContents/CourseDelete'
import CancelModifyCourse from '../../component/stationManagement/modalContents/CancelModifyCourse'
import DisableCirculation from '../../component/stationManagement/modalContents/DisableCircultaion'
import ConfirmModifyCourse from '../../component/stationManagement/modalContents/ConfirmModifyCourse'
import StationSideBarAddCourse, {
  StationSideBarAddCourseRef,
} from '../../component/stationManagement/stationSideBar/StationSideBarAddCourse'
import CancelCopyCourse from '../../component/stationManagement/modalContents/CancelCopyCourse'
import ConfirmAddCourse from '../../component/stationManagement/modalContents/ConfirmAddCourse'

type StationManagementProps = {}

export type StationManagementRef = {}

function StationManagementBase(
  _: StationManagementProps,
  __: ForwardedRef<StationManagementRef>,
) {
  const navigate = useNavigate()
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const deleteRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] deleteRef',
  )
  const deleteConfirmRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] deleteConfirmRef',
  )
  const modifyRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] modifyRef',
  )
  const modifyConfirmRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] modifyConfirmRef',
  )
  const failedDeleteRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] failedDeleteRef',
  )
  const courseDeleteRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] courseDeleteRef',
  )
  const cancelCourseModifyRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] cancelCourseModifyRef',
  )
  const cancelCourseCopyRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] cancelCourseCopyRef',
  )
  const confirmCourseModifyRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] confirmCourseModifyRef',
  )
  const confirmAddCourseRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] confirmAddCourseRef',
  )
  const disableCirculationRef = useSecureRef<ModalContainerRef>(
    '[StationManagement.tsx] disableCirculationRef',
  )

  const addCourseRef = useRef<StationSideBarAddCourseRef>(null)

  const handleSubmit = () => {
    addCourseRef.current?.onSubmit()
  }

  const [station, setStation] = useState<Optional<CStation>>(null)
  const [stationName, setStationName] = useState<string>('')
  const [academyType, setAcademyType] = useState<Optional<string>>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [stationDeleteRefresh, setStationDeleteRefresh] =
    useState<boolean>(false)
  const [toast, setToast] = useState<boolean>(false)
  const [courseDeleteToast, setCourseDeleteToast] = useState<boolean>(false)
  const [courseModifyToast, setCourseModifyToast] = useState<boolean>(false)
  const [courseAddToast, setCourseAddToast] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false)
  const [modifyCourseStatus, setModifyCourseStatus] = useState<boolean>(false)
  const [copyCourseStatus, setCopyCourseStatus] = useState<boolean>(false)
  const [courseDelete, setCourseDelete] = useState<Optional<boolean>>(null)
  const [courseName, setCourseName] = useState<Optional<string>>(null)
  const [cancelCloseModifyModal, setCancelCloseModifyModal] =
    useState<boolean>(false)
  const [disableCirculation, setDisableCirculation] = useState<boolean>(false)
  const [resetCourseRouteData, setResetCourseRouteData] =
    useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)

  const [confirmModifyCourseModal, setConfirmModifyCourseModal] =
    useState<boolean>(false)

  const [confirmAddCourseModal, setConfirmAddCourseModal] =
    useState<boolean>(false)

  const [reloadOriginalCourse, setReloadOriginalCourse] =
    useState<boolean>(false)

  const changeStationName = useCallback((v: string) => {
    setStationName(v)
  }, [])

  const changeAcademyType = useCallback(
    (v: string) => {
      setAcademyType(v)
    },
    [station],
  )

  const showDeleteModal = useCallback(() => {
    deleteRef.current().show()
  }, [])

  const showModifyModal = useCallback((type: boolean) => {
    modifyRef.current().show()
    setUpdateStatus(type)
  }, [])

  const hideModal = useCallback(() => {
    deleteRef.current().hide()
    failedDeleteRef.current().hide()
    modifyRef.current().hide()
    modifyConfirmRef.current().hide()
    courseDeleteRef.current().hide()
    cancelCourseModifyRef.current().hide()
    cancelCourseCopyRef.current().hide()
    confirmCourseModifyRef.current().hide()
    confirmAddCourseRef.current().hide()
    disableCirculationRef.current().hide()
    setDeleteStatus(false)
    setModifyCourseStatus(false)
    setCopyCourseStatus(false)
    setDisableCirculation(false)
  }, [])

  const deleteConfirmHide = useCallback(() => {
    deleteConfirmRef.current().hide()
    setStationDeleteRefresh(true)
  }, [setStationDeleteRefresh])

  const deleteStationData = useCallback(() => {
    const data = {
      academyID: selectedAcademyID,
      stationID: station?.id.toString(),
    }
    deleteStation(data)
      .then(res => {
        if (res === 'OK') {
          deleteRef.current().hide()
          deleteConfirmRef.current().show()
        }
      })
      .catch(() => {
        deleteRef.current().hide()
        failedDeleteRef.current().show()
      })
  }, [station])

  const putStationData = useCallback(() => {
    const data = {
      academyID: selectedAcademyID,
      stationID: station?.id.toString(),
      address: station?.address,
      name: stationName,
      roadAddress: station?.roadAddress,
      type: academyType,
      lat: station.lat,
      lng: station.lng,
    }
    putStation(data)
      .then(res => {
        if (res === 'OK') {
          setRefresh(true)
          setStationName('')
          modifyRef.current().hide()
          modifyConfirmRef.current().show()
        }
      })
      .catch(error => {
        // throw new Error(
        //   `failed to put station info. (data: ${JSON.stringify(
        //     selectedAcademyID,
        //   )}, error: ${error})`,
        // )
        setError(error.response?.data.data)
      })
  }, [station, stationName])

  const handleDeleteCourse = useCallback(() => {
    setDeleteStatus(false)
    setCourseDelete(true)
  }, [])

  const handleCloseModifyCourseModal = useCallback(() => {
    setCancelCloseModifyModal(true)
    cancelCourseModifyRef.current().hide()
    setModifyCourseStatus(false)

    setReloadOriginalCourse(true)
    // setResetCourseRouteData(true)
  }, [])

  const handleCloseCopyCourseModal = useCallback(() => {
    setCancelCloseModifyModal(true)
    cancelCourseCopyRef.current().hide()
    setCopyCourseStatus(false)

    setReloadOriginalCourse(true)
    // setResetCourseRouteData(true)
  }, [])

  useEffect(() => {
    setError(null)
  }, [stationName])

  useEffect(() => {
    setAcademyType(station?.type)
  }, [station])

  useEffect(() => {
    if (confirmModifyCourseModal) {
      confirmCourseModifyRef.current().show()
    } else {
      confirmCourseModifyRef.current().hide()
    }
  }, [confirmModifyCourseModal])

  const closeConfirmModifyCourseModal = useCallback(() => {
    setConfirmModifyCourseModal(false)
  }, [])

  useEffect(() => {
    if (confirmAddCourseModal) {
      confirmAddCourseRef.current().show()
    } else {
      confirmAddCourseRef.current().hide()
    }
  }, [confirmAddCourseModal])

  const closeConfirmAddCourseModal = useCallback(() => {
    setConfirmAddCourseModal(false)
  }, [])

  useEffect(() => {
    if (deleteStatus) {
      courseDeleteRef.current().show()
    }
    if (courseDelete) {
      courseDeleteRef.current().hide()
    }
    if (modifyCourseStatus) {
      cancelCourseModifyRef.current().show()
    }
    if (!modifyCourseStatus) {
      cancelCourseModifyRef.current().hide()
    }
    if (copyCourseStatus) {
      cancelCourseCopyRef.current().show()
    }
    if (!copyCourseStatus) {
      cancelCourseCopyRef.current().hide()
    }
    if (disableCirculation) {
      disableCirculationRef.current().show()
    }
  }, [
    deleteStatus,
    courseDelete,
    modifyCourseStatus,
    copyCourseStatus,
    cancelCourseModifyRef,
    cancelCourseCopyRef,
    disableCirculation,
  ])

  const toastStatus = useCallback((b: boolean) => {
    setToast(b)
  }, [])

  useEffect(() => {
    if (toast) {
      const timeoutId = setTimeout(() => {
        setToast(false)
      }, 3000)
      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (courseDeleteToast) {
      const timeoutId = setTimeout(() => {
        setCourseDeleteToast(false)
      }, 3000)
      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (courseModifyToast) {
      const timeoutId = setTimeout(() => {
        setCourseModifyToast(false)
      }, 3000)
      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (courseAddToast) {
      const timeoutId = setTimeout(() => {
        setCourseAddToast(false)
      }, 3000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [toast, courseAddToast, courseDeleteToast, courseModifyToast])

  return (
    <ScreenContainer>
      <KakaoMap
        showDeleteModal={showDeleteModal}
        showModifyModal={showModifyModal}
        station={station}
        setStation={setStation}
        refresh={refresh}
        setRefresh={setRefresh}
        stationDeleteRefresh={stationDeleteRefresh}
        setStationDeleteRefresh={setStationDeleteRefresh}
        toastStatus={toastStatus}
        setDeleteStatus={setDeleteStatus}
        setModifyCourseStatus={setModifyCourseStatus}
        setCopyCourseStatus={setCopyCourseStatus}
        courseDelete={courseDelete}
        setCourseDelete={setCourseDelete}
        setCourseName={setCourseName}
        setCourseDeleteToast={setCourseDeleteToast}
        cancelCloseModifyModal={cancelCloseModifyModal}
        setCancelCloseModifyModal={setCancelCloseModifyModal}
        setCourseModifyToast={setCourseModifyToast}
        setCourseAddToast={setCourseAddToast}
        setDisableCirculation={setDisableCirculation}
        resetCourseRouteData={resetCourseRouteData}
        setResetCourseRouteData={setResetCourseRouteData}
        setConfirmModifyCourseModal={setConfirmModifyCourseModal}
        setConfirmAddCourseModal={setConfirmAddCourseModal}
        addCourseRef={addCourseRef}
        reloadOriginalCourse={reloadOriginalCourse}
        setReloadOriginalCourse={setReloadOriginalCourse}
      />

      <HalfPopup
        ref={deleteRef.ref}
        contents={
          <DeleteStation
            onClose={hideModal}
            station={station}
            deleteStation={deleteStationData}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={modifyRef.ref}
        contents={
          <ModifyStation
            onClose={hideModal}
            station={station}
            putStationData={putStationData}
            stationName={stationName}
            changeStationName={changeStationName}
            changeAcademyType={changeAcademyType}
            updateStatus={updateStatus}
            error={error}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'40rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={modifyConfirmRef.ref}
        contents={<ConfirmModifyStation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={failedDeleteRef.ref}
        contents={<FailedDeleteStation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={deleteConfirmRef.ref}
        contents={<DeleteConfirm onClose={deleteConfirmHide} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={courseDeleteRef.ref}
        contents={
          <CourseDelete
            onClose={hideModal}
            deleteCourse={handleDeleteCourse}
            courseName={courseName}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={cancelCourseModifyRef.ref}
        contents={
          <CancelModifyCourse
            onClose={hideModal}
            cancelModifyCourse={handleCloseModifyCourseModal}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={cancelCourseCopyRef.ref}
        contents={
          <CancelCopyCourse
            onClose={hideModal}
            cancelCopyCourse={handleCloseCopyCourseModal}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={confirmCourseModifyRef.ref}
        contents={
          <ConfirmModifyCourse
            onClose={closeConfirmModifyCourseModal}
            handleSubmit={handleSubmit}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={confirmAddCourseRef.ref}
        contents={
          <ConfirmAddCourse
            onClose={closeConfirmAddCourseModal}
            handleSubmit={handleSubmit}
          />
        }
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <HalfPopup
        ref={disableCirculationRef.ref}
        contents={<DisableCirculation onClose={hideModal} />}
        top={'25%'}
        right={'60%'}
        width={'32rem'}
        height={'auto'}
      />

      <Toast
        text={'정류장 추가가 완료되었습니다.'}
        status={toast ? 'toast' : ''}
      />

      <Toast
        text={`${courseName} 노선이 삭제 되었습니다.`}
        status={courseDeleteToast ? 'toast' : ''}
      />

      <Toast
        text={`${courseName} 노선 수정이 완료 되었습니다.`}
        status={courseModifyToast ? 'toast' : ''}
      />

      <Toast
        text={`노선이 생성 되었습니다.`}
        status={courseAddToast ? 'toast' : ''}
      />
    </ScreenContainer>
  )
}

const StationManagement = forwardRef(StationManagementBase)
export default StationManagement
