import React from 'react'
import StationSelect from './StationSelect'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../../style/CommonStyle'
import {DispatchTypeEnum} from '../../../../../../../../enum/DispatchTypeEnum'
import {CCourse} from '../../../../../../../../model/Course'
import {isNil} from '../../../../../../../../util/ValidationUtil'
import {Optional} from '../../../../../../../../type/Common'

type Props = {
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusName: Optional<string>
  dispatchType: DispatchTypeEnum
  dispatchCode: string
  onChangeCourse(c: CCourse): void
  onChangeExtraCourse(c: CCourse): void
}

export default function StationSelectContainer(props: Props) {
  return (
    <Container>
      {/* <Header>정류장 선택</Header> */}
      <Header>노선 선택</Header>
      <SelectContainer>
        <StationSelect
          busName={props.busName}
          dispatchCode={props.dispatchCode}
          course={props.course}
          type={props.dispatchType}
          onChange={props.onChangeCourse}
          isAddingBus={true} //쪽차 추가용 노선 선택 상태 전달
        />
        {isNil(props.extraBusName) ? null : (
          <StationSelect
            busName={props.extraBusName}
            dispatchCode={props.dispatchCode}
            course={props.extraCourse}
            type={props.dispatchType}
            onChange={props.onChangeExtraCourse}
            isAddingBus={true}
          />
        )}
      </SelectContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
  flex: 1;
`

const Header = styled.div`
  color: #332a00;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const SelectContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`
