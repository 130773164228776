import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {CDate} from '../../model/Date'
import {API} from '../../Env'

export type StudentScheduleStationsParam = {
  pickupStationId: number
  scheduleId: number
  takeoffStationId: number
}

export async function putStudentScheduleStations(academyId: string, data: StudentScheduleStationsParam[]) {
  const url = `${API}/${academyId}/schedules/stations`

  const config = {
    method: AxiosEnum.PUT.value,
    url: url,
    data: {scheduleStationsList: data},
  }

  await axios(config)
}

export type PostStudentSchedulesRequestBody = {
  dispatchCodes: string[]
  endDate: string
  startDate: string
  students: {
    id: number
    pickUpStationId: number
    takeOffStationId: number
  }[]
}

export async function postStudentSchedules(academyId: string, data: PostStudentSchedulesRequestBody) {
  const url = `${API}/${academyId}/schedules`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      academyId: academyId,
      dispatchCodes: data.dispatchCodes,
      endDate: data.endDate,
      startDate: data.startDate,
      students: data.students,
    },
  }

  await axios(config)
}

export type PostStudentSchedulesExistsRequestBody = {
  dispatchCodes: string[]
  endDate: string
  startDate: string
  students: {
    id: number
    pickUpStationId: number
    takeOffStationId: number
  }[]
}

export async function postStudentSchedulesExists(academyId: string, data: PostStudentSchedulesExistsRequestBody) {
  const url = `${API}/${academyId}/schedules/exists`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      academyId: academyId,
      dispatchCodes: data.dispatchCodes,
      endDate: data.endDate,
      startDate: data.startDate,
      students: data.students,
    },
  }

  await axios(config)
}

export type DeleteStudentSchedulesRequestBody = {
  deleteStartDate: CDate
  dispatchCode: string
  studentIds: number[]
}

export async function deleteStudentSchedules(academyId: string, data: DeleteStudentSchedulesRequestBody) {
  const url = `${API}/${academyId}/schedules`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
    data: {
      deleteStartDate: data.deleteStartDate.toString(),
      dispatchCode: data.dispatchCode,
      studentIds: data.studentIds,
    },
  }

  const res = await axios(config)
}
