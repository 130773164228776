import {API} from '../../Env'
import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {CAcademy} from '../../model/Academy'

export async function getAcademy(academyID: string) {
  const url = `${API}/${academyID}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return CAcademy.create(axiosResponse.data.data)
}
