import React, {Fragment, ReactElement, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  flexRow,
  justifyCenter,
} from '../../../../style/CommonStyle'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import useSecureRef from '../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../common/Modal'
import DispatchPeriodEdit from '../edit/DispatchPeriodEdit'
import DispatchPeriodCopy from '../copy/DispatchPeriodCopy'

type Props = {
  item: CDispatchPeriod
  deletable: boolean
  editable: boolean
  onRefresh(): void
  onPressDelete(): void
  setToast(tc: string): void
  handleLoading(isLoading: boolean): void
}

export default function DispatchPeriodSettingListItemBase(
  props: Props,
): ReactElement {
  const copyModalRef = useSecureRef<ModalRef>(
    '[DispatchPeriodSettingListItem.tsx] copyModalRef',
  )
  const editModalRef = useSecureRef<ModalRef>(
    '[DispatchPeriodSettingListItem.tsx] editModalRef',
  )

  const showEditModal = useCallback(() => {
    editModalRef.current().show()
  }, [])

  const hideEditModal = useCallback(() => {
    editModalRef.current().hide()
  }, [])

  const EditButtonComponent = useMemo(() => {
    if (!props.editable) {
      return null
    }

    return <EditButton onClick={showEditModal}>수정</EditButton>
  }, [props.editable, showEditModal])

  const DeleteButtonComponent = useMemo(() => {
    if (!props.deletable) {
      return null
    }

    return <DeleteButton onClick={props.onPressDelete}>삭제</DeleteButton>
  }, [props.deletable, props.onPressDelete])

  const onSubmitEdit = useCallback(() => {
    props.onRefresh()
    props.setToast('배차 기간 수정이 완료 되었습니다.')
  }, [props.onRefresh, props.setToast])

  const showCopyModal = useCallback(() => {
    copyModalRef.current().show()
  }, [])

  const hideCopyModal = useCallback(() => {
    copyModalRef.current().hide()
  }, [])

  const onSubmitCopy = useCallback(() => {
    props.onRefresh()
    hideCopyModal()
    props.setToast('배차 기간 복제가 완료 되었습니다.')
  }, [props.onRefresh, props.setToast, hideCopyModal])

  return (
    <Fragment>
      <Modal ref={editModalRef.ref}>
        <DispatchPeriodEdit
          dispatchPeriod={props.item}
          onSubmit={onSubmitEdit}
          onCancel={hideEditModal}
          handleLoading={props.handleLoading}
        />
      </Modal>
      <Modal ref={copyModalRef.ref}>
        <DispatchPeriodCopy
          dispatchPeriod={props.item}
          onSubmit={onSubmitCopy}
          onCancel={hideCopyModal}
          handleLoading={props.handleLoading}
        />
      </Modal>

      <ListItemContainer editable={props.editable}>
        <ListItemHeaderContainer>
          <ListItemHeader>
            {props.item.dispatchPeriod.name} |{' '}
            {props.item.dispatchPeriod.startDate.toString()} ~{' '}
            {props.item.dispatchPeriod.endDate.toString()}{' '}
          </ListItemHeader>
          <ListItemButtonContainer>
            {DeleteButtonComponent}
            {EditButtonComponent}
            <CopyButton onClick={showCopyModal}>복제</CopyButton>
          </ListItemButtonContainer>
        </ListItemHeaderContainer>
        <ListItemContentContainer>
          <ListItemContent>
            등원시 : 수업 {props.item.operation.inboundBeforeMinute}분 전 도착
          </ListItemContent>
          <Bar />
          <ListItemContent>
            하원시 : 수업 {props.item.operation.outboundAfterMinute}분 후 출발
          </ListItemContent>
          <Bar />
          <ListItemContent>
            운행 요일 :{' '}
            {props.item.operation.weekdays.map(wd => wd.exposure).join(', ')}
          </ListItemContent>
        </ListItemContentContainer>
      </ListItemContainer>
    </Fragment>
  )
}

const ListItemContainer = styled.div<{editable: boolean}>`
  width: 100%;
  height: 6.8rem;
  background: ${props => (props.editable ? '#ffffff' : '#f5f5f5')};
  padding: 1.2rem 1rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  position: relative;
`

const ListItemHeaderContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const ListItemHeader = styled.div`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #3575d9;
`

const ListItemButtonContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 0.4rem;
`

const ListItemButton = styled.button`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: 3.5rem;
  height: 1.7rem;
  border: 1px solid black;
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
`

const DeleteButton = styled(ListItemButton)`
  background: #333333;
  color: #fff1b3;
  border-color: #333333;
`

const CopyButton = styled(ListItemButton)`
  background: #ffe366;
  color: #997d00;
  border: none;
`

const EditButton = styled(ListItemButton)`
  background: #ffffff;
  color: #2767ce;
  border-color: #2767ce;
`

const ListItemContentContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 0.66rem;
`

const Bar = styled.div`
  width: 0.1rem;
  height: 1.8rem;
  background: #d9d9d9;
`

const ListItemContent = styled.p`
  color: #332a00;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
