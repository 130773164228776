import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import Cancel from '../../../../asset/image/close.svg'
import Search from '../../../../asset/image/search.svg'
import PaginationContainer, {
  PaginationContainerRef,
} from '../../../common/PaginationContainer'
import SVGImage from '../../../common/SVGImage'
import TextInput, {TextInputRef} from '../../../input/TextInput'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import Plus from '../../../../asset/image/plus_icon.svg'
import {CDriver, CDriverVector} from '../../../../model/Driver'
import {Optional} from '../../../../type/Common'
import {getDrivers, GetDriversData} from '../../../../service/driver/Driver'
import {DriverSearchType} from '../../../../enum/DriverSearchType'
import useSecureRef from '../../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import Arrow from '../../../../asset/image/checked_yellow.svg'
import {alertError} from '../../../../util/ErrorUtil'

const PER_PAGE = 20
const PAGE_EXPOSE_COUNT = 3

type Props = {
  showAddDriverModal(): void
  onChange(d: Optional<CDriver>): void
}
export type DispatchBusAddDriverSelectRef = {
  show(): void
  getSelectedDriver(): Optional<CDriver>
  fetchDrivers(): void
}

function DispatchBusAddDriverSelectBase(
  props: Props,
  ref: ForwardedRef<DispatchBusAddDriverSelectRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const pageRef = useSecureRef<PaginationContainerRef>(
    '[DispatchBusAddDriverSelect.tsx] pageRef',
  )
  const queryRef = useSecureRef<TextInputRef>(
    '[DispatchBusAddDriverSelect.tsx] queryRef',
  )

  const [selectedDriver, setSelectedDriver] = useState<Optional<CDriver>>(null)
  const [driverVector, setDriverVector] =
    useState<Optional<CDriverVector>>(null)
  const [visible, setVisible] = useState<boolean>(false)

  const fetchDrivers = useCallback(
    (query: string, page: number) => {
      const data: GetDriversData = {
        academyID: academyID,
        searchType: DriverSearchType.NAME,
        page: page,
        searchValue: query,
        size: PER_PAGE,
      }

      getDrivers(data)
        .then(res => {
          setDriverVector(res)
        })
        .catch(error => {
          alertError(
            error,
            `getDrivers() failed. (aid: ${academyID}, query: ${query}, error: ${error})`,
          )
        })
    },
    [setSelectedDriver],
  )

  const onChangeText = useCallback(
    (t: string) => {
      const page = pageRef.current().getPage()
      fetchDrivers(t, page - 1)
    },
    [fetchDrivers],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const query = queryRef.current().getValue()

      fetchDrivers(query, page - 1)
    },
    [fetchDrivers],
  )

  const onClickAddNewDriverButton = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      props.showAddDriverModal()
    },
    [props.showAddDriverModal],
  )

  const onClickDriver = useCallback(
    (e: MouseEvent<HTMLElement>, d: CDriver) => {
      e.stopPropagation()
      setSelectedDriver(d)
      setVisible(false)
      props.onChange(d)
    },
    [setDriverVector, setVisible, props.onChange],
  )

  const onClickInput = useCallback(
    (_: MouseEvent<HTMLElement>) => {
      setVisible(true)
    },
    [setVisible],
  )

  const onClickCancel = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setVisible(false)
    },
    [setVisible],
  )

  const ListComponent = useMemo(() => {
    if (isNil(driverVector)) {
      return null
    }

    if (isEmptyArray(driverVector.drivers)) {
      return (
        <EmptyContainer>
          <EmptyText>운행 기사가 존재하지 않습니다.</EmptyText>
        </EmptyContainer>
      )
    }

    return (
      <ListContainer>
        {driverVector.drivers.map((d, idx) => {
          const dn = d.name.length > 3 ? `${d.name.slice(0, 3)}...` : d.name
          const selected = d.id === selectedDriver?.id
          return (
            <ListItemContainer
              key={`${d.name}_${idx}`}
              onClick={e => onClickDriver(e, d)}>
              <ListItemText selected={selected}>
                {dn} ({d.phone.slice(9)})
              </ListItemText>
              <ListItemImage source={Arrow} selected={selected} />
            </ListItemContainer>
          )
        })}
      </ListContainer>
    )
  }, [driverVector, selectedDriver])

  const SelectComponent = useMemo(() => {
    if (!visible) {
      return null
    }

    return (
      <SelectionContainer>
        <SelectionHeaderContainer>
          <SelectionHeader>운행 기사 선택</SelectionHeader>
          <ButtonContainer>
            <BusesSettingButton onClick={onClickAddNewDriverButton}>
              <SettingImage source={Plus} />
              <SettingText>신규 기사 등록</SettingText>
            </BusesSettingButton>
            <SelectCancel onClick={onClickCancel}>
              <SelectCancelImage source={Cancel} />
            </SelectCancel>
          </ButtonContainer>
        </SelectionHeaderContainer>
        <SearchContainer>
          <SearchImage source={Search} />
          <StyledTextInput
            ref={queryRef.ref}
            onChange={onChangeText}
            placeholder={'검색어를 입력해주세요.'}
            readOnly={false}
            required={false}
          />
        </SearchContainer>
        {ListComponent}

        <PaginationContainer
          ref={pageRef.ref}
          totalElementCount={driverVector?.paging.totalElements}
          pageExposeCount={PAGE_EXPOSE_COUNT}
          perPage={PER_PAGE}
          onChange={onChangePage}
        />
      </SelectionContainer>
    )
  }, [
    visible,
    onClickCancel,
    onChangePage,
    onClickAddNewDriverButton,
    ListComponent,
    driverVector,
    selectedDriver,
  ])

  const driverInputText = useMemo(() => {
    if (isNil(selectedDriver)) {
      return '기사를 선택해주세요.'
    }

    return `${selectedDriver.name} (${selectedDriver.phone.slice(9)})`
  }, [selectedDriver])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
      getSelectedDriver(): Optional<CDriver> {
        return selectedDriver
      },
      fetchDrivers(): void {
        fetchDrivers('', 0)
      },
    }),
    [setVisible, selectedDriver, fetchDrivers],
  )

  useEffect(() => {
    fetchDrivers('', 0)
  }, [])

  return (
    <Container onClick={onClickInput}>
      <InputText hasValue={!isNil(selectedDriver)}>{driverInputText}</InputText>
      {SelectComponent}
    </Container>
  )
}

const DispatchBusAddDriverSelect = forwardRef(DispatchBusAddDriverSelectBase)
export default DispatchBusAddDriverSelect

const Container = styled.div`
  width: 100%;
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
`

const InputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

const SelectionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 2;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const ButtonContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const BusesSettingButton = styled.button`
  border: none;
  border-radius: 3.4rem;
  padding: 0 0.6rem;
  height: 2.2rem;
  ${flexRow};
  column-gap: 0.2rem;
  justify-content: center;
  align-items: center;
  background: #ffcd00;
`

const SettingImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const SettingText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const SelectionHeaderContainer = styled.div`
  ${flexRow};
  height: 3.4rem;
  background: #332a00;
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  align-items: center;
  justify-content: space-between;
`

const SelectCancel = styled.div``

const SelectCancelImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.4rem;
`

const SelectionHeader = styled.div`
  color: #ffd100;
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.6rem;
  grid-template-columns: repeat(4, 1fr);
`

const ListItemContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  width: 11.5rem;
  height: 3.6rem;
  border-bottom: 0.1rem solid #ebebeb;
  padding: 0.8rem;

  &:hover {
    border-radius: 0.8rem;
    background: #fffbe5;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`

const ListItemText = styled.div<{selected: boolean}>`
  color: ${props => (props.selected ? '#665300' : '#000000')};
  font-weight: ${props => (props.selected ? 800 : 500)};
  font-size: 1.3rem;
  font-style: normal;
  line-height: 150%;
`

const ListItemImage = styled(SVGImage)<{selected: boolean}>`
  display: ${props => (props.selected ? 'block' : 'none')};
  width: 1.2rem;
  height: 1.2rem;
`

const EmptyContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
`

const EmptyText = styled.p`
  color: #999999;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
