import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {color, flexColumn} from '../../../style/CommonStyle'
import {Title} from './SelectDate'
import {CStudent} from '../../../model/Dispatch'
import TableList, {TableListRef} from '../../common/TableList'
import useSecureRef from '../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'

const TITLE_MAP = {grade: '학년', studentName: '학생 이름'}

type StudentListProps = {
  students: CStudent[]
  handleDeletePressable(value: boolean): void
}
export type StudentListRef = {
  getStudentIDs(): number[]
  reset(): void
}

function StudentListBase(
  props: StudentListProps,
  ref: ForwardedRef<StudentListRef>,
): ReactElement {
  const listRef = useSecureRef<TableListRef>('[StudentList.tsx] listRef')
  const onClickRow = useCallback((_: number) => {}, [])

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return props.students.map(s => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.grade, <div>{s.nickName}</div>)
      node.set(
        TITLE_MAP.studentName,
        <div>
          {`${s?.name} ${
            !isNil(s.parentPhone) ? `(${s.parentPhone?.slice(-4)})` : ''
          }`}{' '}
        </div>,
      )

      return node
    })
  }, [props.students])

  const onChangeIndices = useCallback(
    (indices: number[]) => {
      if (isEmptyArray(indices)) {
        props.handleDeletePressable(false)
        return
      }

      props.handleDeletePressable(true)
    },
    [props.handleDeletePressable],
  )

  useImperativeHandle(
    ref,
    () => ({
      getStudentIDs(): number[] {
        const cis = listRef.current().getCheckedIndices()
        return cis.map(ci => props.students.find((_, idx) => ci === idx)?.id)
      },
      reset(): void {
        listRef.current().reset()
      },
    }),
    [props.students],
  )

  return (
    <Container>
      <Title>승차권을 삭제할 학생을 선택해 주세요.</Title>
      <TableWrapper>
        <TableList
          ref={listRef.ref}
          useCheckBox={true}
          keys={Object.values(TITLE_MAP)}
          items={listItems}
          onClickRow={onClickRow}
          onChangeIndices={onChangeIndices}
          placeholder={'검색 결과가 없습니다.'}
        />
      </TableWrapper>
    </Container>
  )
}

const StudentList = forwardRef(StudentListBase)
export default StudentList

const Container = styled.div`
  max-height: calc(100vh - 17rem);
  ${flexColumn};
  padding: 2rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #e7e7e7;
  background: ${color.white};
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TableWrapper = styled.div`
  min-height: calc(100vh - 25rem);
  border: 0.1rem solid #dfdfdf;
  border-radius: 0.8rem;
  padding: 0.8rem 1.6rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`
