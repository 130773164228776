import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../style/CommonStyle'
import Header from '../../common/Header'
import Select, {SelectRef, toSelectOptions} from '../../input/Select'
import {isEmptyArray} from '../../../util/ValidationUtil'
import {Optional} from '../../../type/Common'
import useSecureRef from '../../../hook/useSecureRef'
import {isNil} from 'lodash'
import {CDispatchWithSeatOccupancy} from '../../../model/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'

type TicketListHeaderProps = {
  tickets: CDispatchWithSeatOccupancy[]
  onChangeListHeader(busName: Optional<string>, time: Optional<string>): void
}
export type TicketListHeaderRef = {}

function TicketListHeaderBase(
  props: TicketListHeaderProps,
  _: ForwardedRef<TicketListHeaderRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const busRef = useSecureRef<SelectRef<string>>(
    '[TicketListHeader.tsx], busRef',
  )
  const timeRef = useSecureRef<SelectRef<string>>(
    '[TicketListHeader.tsx], timeRef',
  )

  const getBuses = useCallback(() => {
    return props.tickets.reduce((acc, curr) => {
      if (!acc.includes(curr.bus.name)) {
        acc.push(curr.bus.name)
      }

      return acc
    }, [])
  }, [props.tickets])

  const resetSelectBoxes = useCallback(() => {
    if (!busRef.isSecure() || !timeRef.isSecure()) {
      return
    }

    busRef.current().setIdx(0)
    timeRef.current().setIdx(0)
  }, [])

  const getTimes = useCallback(() => {
    return props.tickets.reduce((acc, curr) => {
      if (!acc.includes(curr.bus.time)) {
        acc.push(curr.bus.time)
      }

      return acc
    }, [])
  }, [props.tickets])

  const busOptions = useMemo(() => {
    const buses = getBuses().sort()

    if (isEmptyArray(buses)) {
      return []
    }

    return toSelectOptions(buses, b => b, true)
  }, [getBuses])

  const timeOptions = useMemo(() => {
    const times = getTimes().sort()

    if (isEmptyArray(times)) {
      return []
    }

    return toSelectOptions(times, t => t, true)
  }, [getTimes])

  const onChangeBus = useCallback(
    (value: string) => {
      const time = timeRef.current().getValue()
      let timeValue = null
      if (!isNil(time)) {
        timeValue = time.value
      }

      localStorage.setItem('bus', JSON.stringify(value))
      props.onChangeListHeader(value, timeValue)
    },
    [busOptions],
  )

  const onChangeTime = useCallback((value: string) => {
    const bus = busRef.current().getValue()
    let busValue = null
    if (!isNil(bus)) {
      busValue = bus.value
    }

    props.onChangeListHeader(busValue, value)
  }, [])

  const defaultBus = useMemo(() => {
    const sb = JSON.parse(localStorage.getItem('bus'))

    return busOptions.find(bo => bo.value === sb)
  }, [busOptions, localStorage])

  useEffect(() => {
    if (isNil(defaultBus)) {
      busRef.current().setIdx(0)
      props.onChangeListHeader(null, null)
      localStorage.setItem('bus', JSON.stringify(null))
      return
    }

    busRef.current().setIdx(defaultBus.idx)
    props.onChangeListHeader(defaultBus.value, null)
  }, [defaultBus])

  useEffect(() => {
    resetSelectBoxes()
  }, [academyID])

  return (
    <SearchContainer>
      <BusSelectContainer>
        <BusSelectHeader>버스</BusSelectHeader>
        <BusSelect
          ref={busRef.ref}
          onChange={onChangeBus}
          options={busOptions}
          height={3.2}
          fontSize={1.2}
          borderRadius={3}
          boxShadow={false}
        />
      </BusSelectContainer>
      <TimeSelectContainer>
        <TimeSelectHeader>시간</TimeSelectHeader>
        <TimeSelect
          ref={timeRef.ref}
          onChange={onChangeTime}
          options={timeOptions}
          width={7}
          height={3.2}
          fontSize={1.2}
          borderRadius={3}
          boxShadow={false}
        />
      </TimeSelectContainer>
    </SearchContainer>
  )
}

const TicketListHeader = forwardRef(TicketListHeaderBase)
export default TicketListHeader

const SearchContainer = styled.div`
  width: 100%;
  padding: 1.2rem 1.4rem;
  border-radius: 0.4rem;
  background: #f7f7f7;
  column-gap: 1.2rem;
  align-items: center;
  margin-bottom: 3rem;
  ${flexRow};
`

const BusSelectContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const BusSelectHeader = styled(Header)`
  width: 3rem;
  color: #174490;
  font-size: 1.3rem;
  line-height: 3rem;
`

const TimeSelectContainer = styled.div`
  width: 100%;
  ${flexRow}
`
const TimeSelectHeader = styled(BusSelectHeader)``

const BusSelect = styled(Select)`
  width: 21rem;
`

const TimeSelect = styled(Select)`
  width: 7rem;
`
