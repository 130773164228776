import React, {MouseEvent} from 'react'
import DisabledChecked from '../../asset/image/checked_disabled.svg'
import Checked from '../../asset/image/checked_brown.svg'
import styled from 'styled-components'
import {flexRow} from '../../style/CommonStyle'
import SVGImage from './SVGImage'

type Props = {
  readOnly: boolean
  isChecked: boolean
  onClick?(e?: MouseEvent<HTMLDivElement>): void
}

function CheckBox(props: Props) {
  return (
    <Container onClick={props.onClick} readOnly={props.readOnly}>
      {props.isChecked ? (
        props.readOnly ? (
          <DisabledCheckImage source={DisabledChecked} />
        ) : (
          <CheckedImage source={Checked} />
        )
      ) : null}
    </Container>
  )
}

export default CheckBox

const Container = styled.div<{readOnly: boolean}>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: ${props =>
    props.readOnly ? '0.1rem solid #f5f5f5' : '0.1rem solid #d9d9d9'};
  background: ${props => (props.readOnly ? '#F5F5F5' : '#ffffff')};
  ${flexRow};
  align-items: center;
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
  justify-content: center;
`

const CheckedImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
`
const DisabledCheckImage = styled(CheckedImage)`
  cursor: not-allowed;
`
