import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {color, flexColumn, flexRow} from '../../../style/CommonStyle'
import {CDate} from '../../../model/Date'
import {WeekdayEnum} from '../../../enum/WeekdayEnum'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import CheckSelect, {CheckSelectOption, CheckSelectRef, toCheckSelectOptions} from '../../input/CheckSelect'
import {Optional} from '../../../type/Common'
import useSecureRef from '../../../hook/useSecureRef'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {isNil} from '../../../util/ValidationUtil'

const NOW = CDate.now()
export const DEFAULT_WEEKDAY = NOW.toDay()

type TicketControllerProps = {
  getListTicket(wd: WeekdayEnum, types: OperationTypeEnum[]): void
  setSelectedDate(wd: WeekdayEnum): void
}
export type TicketControllerRef = {
  getWeekDay(): WeekdayEnum
  getOperationType(): OperationTypeEnum
}

function TicketControllerBase(props: TicketControllerProps, ref: ForwardedRef<TicketControllerRef>): ReactElement {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)

  const weekdayRef = useSecureRef<CheckSelectRef<WeekdayEnum>>('[TicketController.tsx] weekdayRef')
  const operationTypeRef = useSecureRef<CheckSelectRef<OperationTypeEnum>>('[TicketController.tsx] operationTypeRef')

  const onChangeWeekDay = useCallback(
    (wd: WeekdayEnum) => {
      props.setSelectedDate(wd)

      const ot = operationTypeRef.current().getValue()
      if (isNil(ot)) {
        props.getListTicket(wd, OperationTypeEnum.ALL)
        return
      }

      props.getListTicket(wd, [ot.value])
    },
    [props.getListTicket],
  )

  const onChangeOperationType = useCallback(
    (ot: OperationTypeEnum) => {
      const wd = weekdayRef.current().getValue().value

      if (isNil(ot)) {
        props.getListTicket(wd, OperationTypeEnum.ALL)
        return
      }
      props.getListTicket(wd, [ot])
    },
    [props.getListTicket],
  )

  const weekdayOptions = useMemo(() => {
    return toCheckSelectOptions(WeekdayEnum.ALL, (w: WeekdayEnum) => w.exposure)
  }, [])

  const weekdayDefaultIdx = useMemo(() => {
    return weekdayOptions.find(wo => wo.value === DEFAULT_WEEKDAY).idx
  }, [weekdayOptions])

  const operationTypeOptions: CheckSelectOption<OperationTypeEnum>[] = useMemo(() => {
    return toCheckSelectOptions(OperationTypeEnum.ALL, (ot: OperationTypeEnum) => ot.exposure)
  }, [])

  useEffect(() => {
    props.getListTicket(DEFAULT_WEEKDAY, OperationTypeEnum.ALL)
  }, [])

  useLayoutEffect(() => {
    weekdayRef.current().setIdx(weekdayDefaultIdx)
  }, [selectedAcademyID])

  useImperativeHandle(
    ref,
    () => ({
      getWeekDay(): WeekdayEnum {
        return weekdayRef.current().getValue().value
      },
      getOperationType(): OperationTypeEnum {
        const otso = operationTypeRef.current().getValue()

        return !isNil(otso) ? otso.value : null
      },
    }),
    [],
  )

  return (
    <Container>
      <WeekdayWrapper>
        <Title>운행 요일</Title>
        <RadioButtonWrapper>
          <CheckSelect
            hasAll={false}
            ref={weekdayRef.ref}
            defaultIdx={weekdayDefaultIdx}
            options={weekdayOptions}
            onChange={onChangeWeekDay}
          />
        </RadioButtonWrapper>
      </WeekdayWrapper>
      <Categorize>
        <Title>분류</Title>
        <RadioButtonWrapper>
          <CheckSelect
            hasAll={true}
            ref={operationTypeRef.ref}
            options={operationTypeOptions}
            onChange={onChangeOperationType}
          />
        </RadioButtonWrapper>
      </Categorize>
    </Container>
  )
}

const TicketController = forwardRef(TicketControllerBase)
export default TicketController

const Container = styled.div`
  ${flexRow};
  padding: 2.4rem;
  border-radius: 3rem;
  background: ${color.white};
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`

const WeekdayWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
  margin-right: 2rem;
`

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: #174490;
`

const Categorize = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const RadioButtonWrapper = styled.div`
  ${flexRow};
  height: 3rem;
  align-items: center;
`
