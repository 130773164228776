import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Footer from '../../../../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import ArrowDown from '../../../../../../../asset/image/arrowDown.svg'
import SVGImage from '../../../../../../common/SVGImage'
import {isNil} from '../../../../../../../util/ValidationUtil'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {ModalRef} from '../../../../../../common/Modal'
import InputHeader from '../../../../../../input/InputHeader'
import CoursePicker from './CoursePicker'
import {Optional} from '../../../../../../../type/Common'
import CourseSelectMap from './CourseSelectMap'
import {CCourse} from '../../../../../../../model/Course'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'

type CourseSelectProps = {
  selectedCourse: Optional<CCourse>
  dispatchType: DispatchTypeEnum
  onSubmit(s: CCourse): void
  hide(): void
  dispatchPeriod: CDispatchPeriodInfo
}

export default function CourseSelect(props: CourseSelectProps): ReactElement {
  const coursePickerRef = useSecureRef<ModalRef>(
    '[LoadStudentExtra.tsx] coursePickerRef',
  )

  const [selectedCourse, setSelectedCourse] = useState<Optional<CCourse>>(
    props.selectedCourse,
  )

  const onClickCourse = useCallback(() => {
    coursePickerRef.current().show()
  }, [])

  const onChangeCourse = useCallback(
    (c: CCourse) => {
      setSelectedCourse(c)
    },
    [setSelectedCourse],
  )

  const onSubmit = useCallback(() => {
    props.onSubmit(selectedCourse)
    props.hide()
  }, [selectedCourse, props.onSubmit, props.hide])

  const CourseComponent = useMemo((): ReactNode => {
    if (isNil(selectedCourse)) {
      return (
        <CourseText hasValue={!isNil(selectedCourse)}>
          운행노선을 선택해주세요.
        </CourseText>
      )
    }

    if (selectedCourse.dispatchType === DispatchTypeEnum.CIRCULATION) {
      return (
        <CourseTextContainer>
          <CourseText hasValue={!isNil(selectedCourse)}>
            {selectedCourse.name}
          </CourseText>
          <CourseDispatchTypeText>
            {selectedCourse.dispatchType.exposure}
          </CourseDispatchTypeText>
        </CourseTextContainer>
      )
    }

    return (
      <CourseText hasValue={!isNil(selectedCourse)}>
        {selectedCourse.name}
      </CourseText>
    )
  }, [selectedCourse])

  useEffect(() => {
    coursePickerRef.current().show()
  }, [])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>노선 선택</HeaderText>
      </HeaderContainer>
      <ContentContainer>
        <OperationRouteContainer>
          <InputHeaderContainer>
            <InputHeader header={'노선 검색 및 선택'} required={true} />
          </InputHeaderContainer>
          <OperationRouteInputContainer>
            <CoursePicker
              ref={coursePickerRef.ref}
              selectedCourse={selectedCourse}
              onChange={onChangeCourse}
              dispatchType={props.dispatchType}
              columnCount={2}
            />
            <OperationRouteInput
              hasValue={!isNil(selectedCourse)}
              onClick={onClickCourse}>
              {CourseComponent}
              <ArrowDownImage source={ArrowDown}></ArrowDownImage>
            </OperationRouteInput>
          </OperationRouteInputContainer>
        </OperationRouteContainer>
        <MapContainer>
          <MapHeader>선택 노선 지도 보기</MapHeader>
          {!isNil(selectedCourse) && (
            <CourseSelectMap
              selectedCourse={selectedCourse}
              dispatchPeriod={props.dispatchPeriod}
            />
          )}
        </MapContainer>
      </ContentContainer>

      <Footer
        submitText={'선택'}
        onCancel={props.hide}
        onSubmit={onSubmit}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const ContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 1.6rem;
  background: #f5f5f5;
  padding: 1.6rem;
`
const OperationRouteContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
`
const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const OperationRouteInputContainer = styled.div`
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
  position: relative;
`

const OperationRouteInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
const MapContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
  flex: 1;
`

const MapHeader = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  color: #332a00;
`
const CourseTextContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const CourseText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

const CourseDispatchTypeText = styled.div`
  color: #997d00;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 0.2rem;
`
