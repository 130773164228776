import React, {ReactElement, useMemo} from 'react'
import Checked from '../../../../asset/image/checked_brown.svg'
import styled from 'styled-components'
import {alignCenter, flexColumn, flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'

type DispatchPeriodCopyInfoProps = {
  confirmed: boolean
  onPress(): void
}

function DispatchPeriodCopyInfo(
  props: DispatchPeriodCopyInfoProps,
): ReactElement {
  const CheckedImageComponent = useMemo(() => {
    if (!props.confirmed) {
      return null
    }

    return <CheckedImage source={Checked} />
  }, [props.confirmed])

  return (
    <CopyContainer>
      <CopyHeaderContainer>
        <CopyHeader>배차 복제란?</CopyHeader>
        <RequiredText>*</RequiredText>
      </CopyHeaderContainer>
      <CopyInfoContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>
            선택한 기간에 등록된 모든 정보(호차 정보, 노선 운행 정보, 학생 정보,
            매니저 정보)를 가져옵니다.
          </Text>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>단, 등록하려는 기간에 등록된</Text>
          <EmphasizedText style={{marginRight: 2}}>
            수업시간와 학생정보가 다른 경우 해당 수업시간의 배차 정보와 변경된
            학생정보는
          </EmphasizedText>
        </InfoTextContainer>
        <InfoTextContainer>
          <EmphasizedText style={{marginLeft: 8}}>
            등록되지 않습니다.
          </EmphasizedText>
        </InfoTextContainer>
        <CopyInfoConfirmContainer onClick={props.onPress}>
          <CheckBox>{CheckedImageComponent}</CheckBox>
          <Text>예, 확인했습니다.</Text>
        </CopyInfoConfirmContainer>
      </CopyInfoContainer>
    </CopyContainer>
  )
}

export default DispatchPeriodCopyInfo

const CopyContainer = styled.div`
  ${flexColumn};
  margin-bottom: 1.8rem;
`

const CopyHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.3rem;
`

const CopyHeader = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  height: 1.4rem;
  margin-bottom: 0.2rem;
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.pre`
  color: #404040;
  font-size: 1.1rem;
`

const EmphasizedText = styled.pre`
  color: #ff7500;
  font-size: 1.1rem;
`

const RequiredText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;

  color: #ff7500;
`

const CopyInfoContainer = styled.div`
  ${flexColumn};
  padding: 0.8rem 1.6rem 0.8rem 0.8rem;
  background: #ebebeb;
`

const CopyInfoConfirmContainer = styled.div`
  ${flexRow};
  padding-left: 0.6rem;
  column-gap: 0.6rem;
  margin-top: 0.8rem;
  cursor: pointer;
`
const CheckBox = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #d9d9d9;
  background: #ffffff;
  ${flexRow};
  align-items: center;
  justify-content: center;
`

const CheckedImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
`
