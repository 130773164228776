import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {CBusBrief} from '../../../model/Bus'
import PeriodTable from './period/table/PeriodTable'
import {DispatchOptionEnum} from '../../../enum/DispatchOptionEnum'
import DateTable from './date/DateTable'
import {CDispatchPeriodInfo} from '../../../model/DispatchPeriod'
import {CDate} from '../../../model/Date'
import {Optional} from '../../../type/Common'
import {isNil} from '../../../util/ValidationUtil'
import {BusDetailLocationEnum} from '../../../enum/BusDetailLocationEnum'
import {toPickerOptions} from '../../input/Picker'

type Props = {
  busBrief: CBusBrief
  dispatchPeriods: CDispatchPeriodInfo[]
  defaultDispatchPeriodID: Optional<number>
  location: BusDetailLocationEnum
}

export default function DispatchInfo(props: Props) {
  const [date, setDate] = useState<CDate>(CDate.now())

  const defaultSelectedDispatch = useMemo(() => {
    if (props.location === BusDetailLocationEnum.DISPATCH) {
      return DispatchOptionEnum.PERIOD
    }

    return DispatchOptionEnum.DATE
  }, [props.location])

  const [selectedDispatch, setSelectedDispatch] = useState<DispatchOptionEnum>(
    defaultSelectedDispatch,
  )

  const defaultSdp = useMemo(() => {
    const now = CDate.now()

    const includeTodayDp = props.dispatchPeriods.find(
      dp => now.isAfter(dp.startDate) && now.isBefore(dp.endDate),
    )

    const defaultDP = props.dispatchPeriods.find(
      dp => dp.id === props.defaultDispatchPeriodID,
    )

    if (!isNil(defaultDP)) {
      return defaultDP
    }

    if (isNil(includeTodayDp)) {
      return null
    }

    return includeTodayDp
  }, [props.dispatchPeriods])

  const [selectedDispatchPeriod, setSelectedDispatchPeriod] =
    useState<Optional<CDispatchPeriodInfo>>(defaultSdp)

  const onChangeDispatch = useCallback(
    (value: DispatchOptionEnum) => {
      setSelectedDispatch(value)
    },
    [setSelectedDispatch],
  )

  const onChangeDispatchPeriod = useCallback(
    (value: CDispatchPeriodInfo) => {
      setSelectedDispatchPeriod(value)
      setDate(CDate.now())
    },
    [setSelectedDispatchPeriod, setDate],
  )

  const onChangeDate = useCallback(
    (d: CDate) => {
      setDate(d)
    },
    [setDate],
  )

  const dispatchOptions = useMemo(() => {
    return toPickerOptions(
      DispatchOptionEnum.ALL,
      (v: DispatchOptionEnum) => (
        <div style={{fontSize: 11, paddingLeft: 6}}>{v.exposure}</div>
      ),
      (v: DispatchOptionEnum) => (
        <div style={{fontSize: 11, paddingLeft: 6}}>{v.exposure}</div>
      ),
    )
  }, [])

  const defaultDispatchIndex = useMemo(() => {
    return DispatchOptionEnum.ALL.findIndex(dio => dio === selectedDispatch)
  }, [selectedDispatch])

  const dispatchPeriodOptions = useMemo(() => {
    return toPickerOptions(
      props.dispatchPeriods,
      (v: CDispatchPeriodInfo) => (
        <div style={{fontSize: 11, paddingLeft: 6}}>{v.name}</div>
      ),
      (v: CDispatchPeriodInfo) => (
        <div style={{fontSize: 11, paddingLeft: 6}}>{v.name}</div>
      ),
    )
  }, [props.dispatchPeriods])

  const defaultDispatchPeriodIndex = useMemo(() => {
    if (isNil(selectedDispatchPeriod)) {
      return -1
    }

    return props.dispatchPeriods.findIndex(
      dp => dp.id === selectedDispatchPeriod.id,
    )
  }, [props.dispatchPeriods, selectedDispatchPeriod])

  if (selectedDispatch === DispatchOptionEnum.DATE) {
    return (
      <Container>
        <DateTable
          date={date}
          busBrief={props.busBrief}
          selectedDispatchPeriod={selectedDispatchPeriod}
          defaultDispatchIndex={defaultDispatchIndex}
          defaultDispatchPeriodIndex={defaultDispatchPeriodIndex}
          dispatchOptions={dispatchOptions}
          dispatchPeriodOptions={dispatchPeriodOptions}
          onChangeDispatch={onChangeDispatch}
          onChangeDispatchPeriod={onChangeDispatchPeriod}
          onChangeDate={onChangeDate}
        />
      </Container>
    )
  }

  return (
    <Container>
      <PeriodTable
        busBrief={props.busBrief}
        selectedDispatchPeriod={selectedDispatchPeriod}
        dispatchOptions={dispatchOptions}
        dispatchPeriodOptions={dispatchPeriodOptions}
        defaultDispatchIndex={defaultDispatchIndex}
        defaultDispatchPeriodIndex={defaultDispatchPeriodIndex}
        onChangeDispatch={onChangeDispatch}
        onChangeDispatchPeriod={onChangeDispatchPeriod}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 1.2rem;
  padding: 1.2rem;
  background: #fff;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  overflow: auto;
`
