import React, {useCallback, useEffect, useState} from 'react'
import DispatchInfoHeader from '../common/DispatchInfoHeader'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../style/CommonStyle'
import CourseContainer from './course/CourseContainer'
import {CDispatchCourseWithStudent} from '../../../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../../../enum/OccupancyTypeEnum'
import {Optional} from '../../../../../../../type/Common'
import {CCourse} from '../../../../../../../model/Course'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {isNil} from '../../../../../../../util/ValidationUtil'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import LoadStudentExtra from '../../../common/loadStudent/extra/LoadStudentExtra'
import {StudentLoadTypeEnum} from '../../../../../../../enum/StudentLoadTypeEnum'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
  postDispatchesCoursesStudents,
  PostDispatchesCoursesStudentsData,
} from '../../../../../../../service/dispatch/Dispatch'
import {throwError} from '../../../../../../../util/ErrorUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'

import {AxiosError} from 'axios'

interface ErrorResponse {
  data: string
  message: string
}

type Props = {
  dispatchCode: string
  occupancyType: OccupancyTypeEnum
  dispatchType: Optional<DispatchTypeEnum>
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusName: Optional<string>
  defaultDispatchCourses: CDispatchCourseWithStudent[]
  dispatchPeriod: CDispatchPeriodInfo
}

export default function DispatchInfoExtra(props: Props) {
  const academyID = useRecoilValue(academyIDState)

  const loadStudentRef = useSecureRef<ModalRef>(
    '[DispatchInfoFull.tsx] loadStudentRef',
  )

  const [courses, setCourses] = useState<CDispatchCourseWithStudent[]>([])
  const [extraCourses, setExtraCourses] = useState<
    CDispatchCourseWithStudent[]
  >([])

  const showLoadStudent = useCallback(() => {
    loadStudentRef.current().show()
  }, [props.course])

  const hideLoadStudent = useCallback(() => {
    loadStudentRef.current().hide()
  }, [])

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData, isExtra: boolean) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          if (isExtra) {
            setExtraCourses(cws)
            return
          }
          setCourses(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourses, setExtraCourses],
  )

  const initCourses = useCallback(
    async (data: GetDispatchesCoursesWithStudentsData) => {
      try {
        const cws = await getDispatchesCoursesWithStudent(data)

        const cwsAppliedDefaultCourses = cws.map(c => {
          const defaultCourse = props.defaultDispatchCourses.find(
            dc => dc.stationID === c.stationID,
          )
          if (defaultCourse) {
            return {
              ...c,
              students: c.students.map(s => {
                const defaultStudent = defaultCourse.students.find(
                  ds => ds.id === s.id,
                )
                return {
                  ...s,
                  check: defaultStudent ? defaultStudent.check : false,
                }
              }),
            }
          }
          return {
            ...c,
            students: c.students.map(s => ({...s, check: false})),
          }
        })

        setCourses(cwsAppliedDefaultCourses)

        const dataForRedis: PostDispatchesCoursesStudentsData = {
          dispatchCode: data.dispatchCode,
          academyID: academyID,
          courseCode: data.courseCode,
          courses: cwsAppliedDefaultCourses,
          type: data.type,
        }

        await postDispatchesCoursesStudents(dataForRedis)
        return cwsAppliedDefaultCourses // Return the courses for the next chain
      } catch (error) {
        if (error instanceof Error) {
          const axiosError = error as AxiosError<ErrorResponse>
          throwError(
            axiosError,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error.message})`,
          )
        }
        throw error
      }
    },
    [props.defaultDispatchCourses, academyID],
  )

  const initExtraCourses = useCallback(
    async (
      data: GetDispatchesCoursesWithStudentsData,
      currentCourses: CDispatchCourseWithStudent[],
    ) => {
      try {
        const cws = await getDispatchesCoursesWithStudent(data)

        const duplicatedStudentCheckedCws = cws.map(cw => ({
          ...cw,
          students: cw.students.map(cwStudent => {
            const isDuplicateStudent = currentCourses.some(course =>
              course.students.some(
                courseStudent =>
                  courseStudent.check === true &&
                  courseStudent.id === cwStudent.id,
              ),
            )
            return {
              ...cwStudent,
              check: isDuplicateStudent ? false : cwStudent.check,
            }
          }),
        }))

        setExtraCourses(duplicatedStudentCheckedCws)

        if (props.course.name !== props.extraCourse.name) {
          const extraDataForRedis: PostDispatchesCoursesStudentsData = {
            dispatchCode: data.dispatchCode,
            academyID: academyID,
            courseCode: data.courseCode,
            courses: duplicatedStudentCheckedCws,
            type: data.type,
          }
          await postDispatchesCoursesStudents(extraDataForRedis)
        }
      } catch (error) {
        if (error instanceof Error) {
          const axiosError = error as AxiosError<ErrorResponse>
          throwError(
            axiosError,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error.message})`,
          )
        }
      }
    },
    [props.course.name, props.extraCourse.name, academyID],
  )

  const onSubmit = useCallback(() => {
    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.course.code,
      type: StudentLoadTypeEnum.EDIT,
    }

    fetchCourses(data, false)

    if (!isNil(props.extraCourse)) {
      const extraData: GetDispatchesCoursesWithStudentsData = {
        dispatchCode: props.dispatchCode,
        academyID: academyID,
        courseCode: props.extraCourse.code,
        type: StudentLoadTypeEnum.EDIT,
      }

      fetchCourses(extraData, true)
    }
  }, [
    props.dispatchCode,
    props.course,
    props.extraCourse,
    academyID,
    fetchCourses,
  ])

  useEffect(() => {
    const initializeData = async () => {
      if (!isNil(props.course)) {
        const data: GetDispatchesCoursesWithStudentsData = {
          dispatchCode: props.dispatchCode,
          academyID: academyID,
          courseCode: props.course.code,
          type: StudentLoadTypeEnum.EDIT,
        }

        try {
          // First initialize courses and get the result
          const initializedCourses = await initCourses(data)

          // Then initialize extra courses with the current courses data
          if (!isNil(props.extraCourse)) {
            const extraData: GetDispatchesCoursesWithStudentsData = {
              dispatchCode: props.dispatchCode,
              academyID: academyID,
              courseCode: props.extraCourse.code,
              type: StudentLoadTypeEnum.EDIT,
            }
            await initExtraCourses(extraData, initializedCourses)
          }
        } catch (error) {
          console.error('Failed to initialize courses:', error)
        }
      }
    }

    initializeData()
  }, [
    props.dispatchCode,
    props.course,
    props.extraCourse,
    academyID,
    initCourses,
    initExtraCourses,
  ])

  return (
    <Container>
      <Modal zIndex={3} ref={loadStudentRef.ref}>
        <LoadStudentExtra
          courseCode={props.course.code}
          extraCourseCode={props.extraCourse.code}
          courseName={props.course.name}
          extraCourseName={props.extraCourse.name}
          dispatchCode={props.dispatchCode}
          extraDispatchCode={props.dispatchCode}
          dispatchType={props.course.dispatchType}
          busName={props.busName}
          extraBusName={props.extraBusName}
          studentLoadType={StudentLoadTypeEnum.EDIT}
          onCancel={hideLoadStudent}
          onSubmit={onSubmit}
        />
      </Modal>

      <DispatchInfoHeader onClickLoad={showLoadStudent} />
      <CourseContainer
        courses={courses}
        extraCourses={extraCourses}
        busName={props.busName}
        extraBusName={props.extraBusName}
        dispatchCode={props.dispatchCode}
        type={props.occupancyType}
        selectedCourse={props.course}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`
