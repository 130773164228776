import React from 'react'
import LoadIcon from '../../../../../../../asset/image/student.svg'
import styled from 'styled-components'
import {flexRow} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'

type Props = {
  onClickLoad(): void
}

export default function DispatchInfoHeader(props: Props) {
  return (
    <HeaderContainer>
      <HeaderText>배차 상세 정보</HeaderText>

      <ButtonContainer>
        <LoadButton onClick={props.onClickLoad}>
          <LoadButtonImage source={LoadIcon} />
          <LoadButtonText>이용 학생 관리</LoadButtonText>
        </LoadButton>
      </ButtonContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const ButtonContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const LoadButton = styled.div`
  width: 9.8rem;
  height: 2.2rem;
  background: #ffd100;
  ${flexRow};
  align-items: center;
  justify-content: center;
  border-radius: 3.4rem;
  cursor: pointer;
`

const LoadButtonText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`

const LoadButtonImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
