import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Cancel from '../../../../../../../../asset/image/close.svg'
import Search from '../../../../../../../../asset/image/search.svg'
import PlusIcon from '../../../../../../../../asset/image/plus_icon.svg'
import {Optional} from '../../../../../../../../type/Common'
import {useRecoilValue} from 'recoil'
import {isEmptyArray, isNil} from '../../../../../../../../util/ValidationUtil'
import {academyIDState} from '../../../../../../../../recoil/Atom'
import PaginationContainer from '../../../../../../../common/PaginationContainer'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../../common/SVGImage'
import TextInput from '../../../../../../../input/TextInput'
import {
  getBusesWithDispatchPeriodID,
  GetBusesWithDispatchPeriodIDData,
} from '../../../../../../../../service/buses/Buses'
import {CBus, CBusVector} from '../../../../../../../../model/Bus'
import useSecureRef from '../../../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../../../common/Modal'
import DispatchBusAdd from '../../../../../../../dispatch/dispatch/busAdd/DispatchBusAdd'
import {BusInfo} from '../../../DispatchEdit'

const LIMIT = 5
const PAGE_EXPOSE_COUNT = 3

type BusSelectProps = {
  width?: number
  busName: Optional<string>
  disabled?: boolean
  dispatchPeriodID: number
  onChange(bi: BusInfo): void
  busNamesToExclude?: [string]
}
export type BusSelectRef = {
  show(): void
  hide(): void
}

function BusSelectBase(
  props: BusSelectProps,
  ref: ForwardedRef<BusSelectRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const busAddModalRef = useSecureRef<ModalRef>('[BusSelect.tsx] addModalRef')

  const [visible, setVisible] = useState<boolean>(false)
  const [busVector, setBusVector] = useState<Optional<CBusVector>>(null)

  const fetchBusVector = useCallback(
    (data: GetBusesWithDispatchPeriodIDData) => {
      getBusesWithDispatchPeriodID(data)
        .then(sv => {
          const filteredBuses = sv.buses.filter(
            bus => !props.busNamesToExclude.includes(bus.name),
          )
          const updatedBusVector = {...sv, buses: filteredBuses}
          setBusVector(updatedBusVector)
        })
        .catch(error => {
          throw new Error(
            `getBuses() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setBusVector, props.busNamesToExclude],
  )

  const showAddBusModal = useCallback(() => {
    busAddModalRef.current().show()
  }, [])

  const onChangePage = useCallback(
    (page: number) => {
      const data: GetBusesWithDispatchPeriodIDData = {
        academyID: academyID,
        page: page - 1,
        size: LIMIT,
        dispatchPeriodID: props.dispatchPeriodID,
      }

      fetchBusVector(data)
    },
    [fetchBusVector, academyID, props.dispatchPeriodID],
  )

  const onChangeText = useCallback(
    (t: string) => {
      const data: GetBusesWithDispatchPeriodIDData = {
        academyID: academyID,
        dispatchPeriodID: props.dispatchPeriodID,
        page: 0,
        searchType: 'NAME',
        searchValue: t,
        size: LIMIT,
      }

      fetchBusVector(data)
    },
    [academyID, fetchBusVector, props.dispatchPeriodID],
  )

  const onClickBus = useCallback(
    (bus: CBus) => {
      props.onChange({id: bus.id, name: bus.name})
      setVisible(false)
    },
    [props.onChange, setVisible],
  )

  const ListComponent = useMemo(() => {
    if (isNil(busVector)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    if (isEmptyArray(busVector.buses)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    return (
      <ListContainer>
        {busVector.buses.map((value, idx) => {
          return (
            <BusContainer
              key={`${value.name}_${idx}`}
              onClick={e => {
                e.stopPropagation()
                onClickBus(value)
              }}>
              <BusTitleContainer>
                <BusText selected={props.busName === value.name}>
                  {value.name}({value.regNum}) | {value.drivers[0].name}(
                  {value.drivers[0].phone.slice(9)})
                </BusText>
              </BusTitleContainer>
            </BusContainer>
          )
        })}
      </ListContainer>
    )
  }, [busVector, props.busName, props.busNamesToExclude])

  useEffect(() => {
    const data: GetBusesWithDispatchPeriodIDData = {
      academyID: academyID,
      dispatchPeriodID: props.dispatchPeriodID,
      page: 0,
      size: LIMIT,
    }

    if (!visible) {
      return
    }

    fetchBusVector(data)
  }, [visible, academyID, props.dispatchPeriodID])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
      hide(): void {
        setVisible(false)
      },
    }),
    [setVisible],
  )

  const hideBusModal = useCallback(() => {
    busAddModalRef.current().hide()
  }, [])

  const onSubmit = useCallback(() => {
    const data: GetBusesWithDispatchPeriodIDData = {
      academyID: academyID,
      page: 0,
      size: LIMIT,
      dispatchPeriodID: props.dispatchPeriodID,
    }

    fetchBusVector(data)
    hideBusModal()
  }, [hideBusModal, academyID, fetchBusVector, props.dispatchPeriodID])

  if (!visible) {
    return null
  }

  if (isNil(busVector)) {
    return null
  }

  return (
    <Container style={{width: props.width}}>
      <Modal zIndex={4} ref={busAddModalRef.ref}>
        <DispatchBusAdd
          onSubmit={onSubmit}
          onCancel={hideBusModal}
          setToast={() => {}}
        />
      </Modal>

      <HeaderContainer>
        <Header>호차 선택</Header>
        <HeaderButtonContainer>
          {props.disabled ? null : (
            <RouteButton onClick={showAddBusModal}>
              <PlusIconImg source={PlusIcon} />
              신규 호차 등록
            </RouteButton>
          )}
          <CancelButton
            onClick={e => {
              e.stopPropagation()
              setVisible(false)
            }}>
            <CancelImage source={Cancel} />
          </CancelButton>
        </HeaderButtonContainer>
      </HeaderContainer>
      <SearchContainer>
        <SearchImage source={Search} />
        <StyledTextInput
          onChange={onChangeText}
          placeholder={'검색어를 입력해주세요.'}
          readOnly={false}
          required={false}
        />
      </SearchContainer>
      {ListComponent}
      <PaginationContainer
        totalElementCount={busVector?.paging.totalElements}
        pageExposeCount={PAGE_EXPOSE_COUNT}
        perPage={LIMIT}
        onChange={onChangePage}
      />
    </Container>
  )
}

const BusSelect = forwardRef(BusSelectBase)
export default BusSelect

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 1000;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const HeaderContainer = styled.div`
  ${flexRow};
  background: #332a00;
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  align-items: center;
  justify-content: space-between;
  height: 3.8rem;
`

const HeaderButtonContainer = styled.div`
  ${flexRow};
  column-gap: 0.2rem;
  align-items: center;
`

const CancelButton = styled.div``

const CancelImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.4rem;
`

const Header = styled.div`
  color: #ffd100;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div`
  ${flexColumn};
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
`

const EmptyListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 0 0 0.8rem 0.8rem;
`

const BusContainer = styled.div`
  ${flexColumn};
  width: 100%;
  padding: 0.8rem;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
`

const BusTitleContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const BusText = styled.h1<{selected: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 500)};
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#332A00')};
`

const RouteButton = styled.button`
  border: none;
  background: #ffd100;
  border-radius: 3.4rem;
  width: 11rem;
  height: 2.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  padding: 0.4rem 0.8rem 0.4rem 0.6rem;
  ${flexRow};
  align-items: center;
`

const PlusIconImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`
