import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import Picker, {PickerRef, toPickerOptions} from '../../../input/Picker'
import SVGImage from '../../../common/SVGImage'
import Bus from '../../../../asset/image/bus.svg'
import {SortEnum} from '../../../../enum/SortEnum'
import useSecureRef from '../../../../hook/useSecureRef'
import {isNil} from '../../../../util/ValidationUtil'
import {Optional} from '../../../../type/Common'
import DispatchBusSetting from '../busSetting/DispatchBusSetting'
import {CDispatchPeriodBusVector} from '../../../../model/DispatchPeriod'
import Modal, {ModalRef} from '../../../common/Modal'

type Props = {
  isEmpty: boolean
  dispatchPeriodID: number
  dispatchPeriodBusVectors: CDispatchPeriodBusVector[]
  onChangeSort(sort: SortEnum): void
  onSubmit(dpID: number): void
  showToast(t: string): void
}
export type DispatchListHeaderRef = {
  getSort(): SortEnum
}

function DispatchListHeaderBase(
  props: Props,
  ref: ForwardedRef<DispatchListHeaderRef>,
): ReactElement {
  const pickerRef = useSecureRef<PickerRef<SortEnum>>(
    '[DispatchListHeader.tsx] pickerRef',
  )
  const modalRef = useSecureRef<ModalRef>('[DispatchListHeader.tsx] modalRef')

  const onClickButton = useCallback(() => {
    modalRef.current().show()
  }, [])

  const sortOptions = useMemo(() => {
    return toPickerOptions(
      SortEnum.ALL,
      sort => (
        <div
          style={{
            fontSize: 13,
            paddingLeft: 6,
          }}>
          {sort.exposure}
        </div>
      ),
      sort => (
        <div
          style={{
            fontSize: 13,
            paddingLeft: 6,
          }}>
          {sort.exposure}
        </div>
      ),
    )
  }, [props.isEmpty])

  const onChangeSort = useCallback(
    (value: SortEnum) => {
      props.onChangeSort(value)
    },
    [props.onChangeSort],
  )

  const hideModal = useCallback(() => {
    modalRef.current().hide()
  }, [])

  const onSubmit = useCallback(() => {
    props.onSubmit(props.dispatchPeriodID)
    hideModal()
  }, [props.dispatchPeriodID])

  useImperativeHandle(ref, () => ({
    getSort(): Optional<SortEnum> {
      const v = pickerRef.current().getValue()
      if (isNil(v)) {
        return null
      }

      return pickerRef.current().getValue().value
    },
  }))

  return (
    <Container>
      <Modal ref={modalRef.ref}>
        <DispatchBusSetting
          dispatchPeriodID={props.dispatchPeriodID}
          dispatchPeriodBusVectors={props.dispatchPeriodBusVectors}
          onSubmit={onSubmit}
          onCancel={hideModal}
          showToast={props.showToast}
        />
      </Modal>
      <Picker
        ref={pickerRef.ref}
        minWidth={138}
        height={28}
        placeholder={'호차 정렬 선택'}
        onChange={onChangeSort}
        options={sortOptions}
        readOnly={props.isEmpty}
        defaultIdx={0}
      />
      <BusesSettingButton onClick={onClickButton}>
        <SettingImage source={Bus} />
        <SettingText>호차 설정</SettingText>
      </BusesSettingButton>
    </Container>
  )
}

const DispatchListHeader = forwardRef(DispatchListHeaderBase)
export default DispatchListHeader

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const BusesSettingButton = styled.button`
  border: none;
  border-radius: 3.4rem;
  width: 8.4rem;
  height: 2.6rem;
  ${flexRow};
  column-gap: 0.2rem;
  justify-content: center;
  align-items: center;
  background: #ffcd00;
`

const SettingImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const SettingText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
