import React from 'react'
import styled from 'styled-components'
import {ModalButton, flexColumn} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import CloseButton from '../../../asset/image/close_button.svg'

type PopUpProps = {
  onClose: () => void
}

export function DisabledDeleteDriver(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>기사 탈퇴 처리 불가</Title>
      <SubTitle>운행 예정인 배차가 있어 탈퇴 처리가 불가능합니다.</SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.onClose}>확인</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 1.6rem 1.2rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 3rem;
  position: relative;
`
const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`
const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
`
const SubTitle = styled.div`
  font-size: 1.3rem;
  margin-bottom: 2.4rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a468b;
  > span {
    font-size: 1.3rem;
    font-weight: 800;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`

const ConfirmButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
  background: #ffcd00;
`
