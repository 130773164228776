import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import ArrowLeftDouble from '../../asset/image/arrowLeftDouble.svg'
import ArrowLeft from '../../asset/image/arrowLeft.svg'
import styled from 'styled-components'
import {alignCenter, flexRow, justifyCenter} from '../../style/CommonStyle'
import SVGImage from './SVGImage'
import {isNil, orElse} from '../../util/ValidationUtil'

const INITIAL_INDEX = 1

type PaginationContainerProps = {
  totalElementCount: number
  perPage: number
  pageExposeCount: number
  defaultPage?: number
  onChange(page: number): void
}
export type PaginationContainerRef = {
  getPage(): number
}

function PaginationContainerBase(
  props: PaginationContainerProps,
  ref: ForwardedRef<PaginationContainerRef>,
): ReactElement {
  const defaultStartIndex = useMemo(() => {
    if (isNil(props.defaultPage)) {
      return INITIAL_INDEX
    }

    return (
      Math.ceil(props.defaultPage / props.pageExposeCount - 1) *
        props.pageExposeCount +
      1
    )
  }, [props.defaultPage, props.pageExposeCount])

  const [startIndex, setStartIndex] = useState<number>(defaultStartIndex)
  const [selectedIndex, setSelectedIndex] = useState<number>(
    orElse(props.defaultPage, INITIAL_INDEX),
  )

  const totalPages = useMemo(() => {
    const p = props.totalElementCount / props.perPage
    const temp = []
    for (let i = 0; i < p; i++) {
      temp.push(i + 1)
    }

    return temp
  }, [props.totalElementCount, props.perPage])

  const exposePages = useMemo(() => {
    return totalPages.slice(
      startIndex - 1,
      startIndex + props.pageExposeCount - 1,
    )
  }, [totalPages, startIndex, props.pageExposeCount])

  const hasNext = useMemo(() => {
    if (totalPages.length === 0) {
      return false
    }

    return (
      Math.ceil(totalPages.length / props.pageExposeCount) !==
      Math.ceil(startIndex / props.pageExposeCount)
    )
  }, [totalPages, props.pageExposeCount, startIndex])

  const hasPrev = useMemo(() => {
    return startIndex / props.pageExposeCount > 1
  }, [props.pageExposeCount, startIndex])

  const onClickNext = useCallback(() => {
    if (!hasNext) {
      return
    }

    setStartIndex(
      prev =>
        Math.ceil(prev / props.pageExposeCount) * props.pageExposeCount + 1,
    )
    setSelectedIndex(prev => {
      const idx =
        Math.ceil(prev / props.pageExposeCount) * props.pageExposeCount + 1
      props.onChange(idx)
      return idx
    })
  }, [
    hasNext,
    setStartIndex,
    setSelectedIndex,
    props.pageExposeCount,
    props.onChange,
  ])

  const onClickNextDouble = useCallback(() => {
    if (!hasNext) {
      return
    }

    const idx =
      Math.ceil(totalPages.length / props.pageExposeCount - 1) *
        props.pageExposeCount +
      1

    setStartIndex(idx)
    setSelectedIndex(idx)
    props.onChange(idx)
  }, [
    totalPages,
    setStartIndex,
    setSelectedIndex,
    props.pageExposeCount,
    props.onChange,
  ])

  const onClickPrev = useCallback(() => {
    if (!hasPrev) {
      return
    }

    setStartIndex(
      prev =>
        (Math.ceil(prev / props.pageExposeCount) - 2) * props.pageExposeCount +
        1,
    )
    setSelectedIndex(prev => {
      const idx =
        (Math.ceil(prev / props.pageExposeCount) - 2) * props.pageExposeCount +
        1
      props.onChange(idx)
      return idx
    })
  }, [
    setStartIndex,
    setSelectedIndex,
    hasPrev,
    props.pageExposeCount,
    props.onChange,
  ])

  const onClickPrevDouble = useCallback(() => {
    setStartIndex(INITIAL_INDEX)
    setSelectedIndex(INITIAL_INDEX)
    props.onChange(INITIAL_INDEX)
  }, [setStartIndex, setSelectedIndex, props.onChange])

  const onClickPage = useCallback(
    (idx: number) => {
      setSelectedIndex(idx)
      props.onChange(idx)
    },
    [setStartIndex, setSelectedIndex, props.onChange],
  )

  useImperativeHandle(
    ref,
    () => ({
      getPage(): number {
        return selectedIndex
      },
    }),
    [selectedIndex],
  )

  if (props.totalElementCount === 0) {
    return null
  }

  return (
    <Container>
      <PaginationInnerContainer>
        <PrevArrowContainer hasPrev={hasPrev} onClick={onClickPrevDouble}>
          <ArrowLeftImage source={ArrowLeftDouble} />
        </PrevArrowContainer>
        <PrevArrowContainer hasPrev={hasPrev} onClick={onClickPrev}>
          <ArrowLeftImage source={ArrowLeft} />
        </PrevArrowContainer>
        <PaginationNumberContainer>
          {exposePages.map((p, idx) => {
            return (
              <PaginationNumber
                key={`p_${idx}`}
                selected={p === selectedIndex}
                onClick={() => onClickPage(p)}>
                {p}
              </PaginationNumber>
            )
          })}
        </PaginationNumberContainer>
        <NextArrowContainer hasNext={hasNext} onClick={onClickNext}>
          <ArrowRightImage source={ArrowLeft} />
        </NextArrowContainer>
        <NextArrowContainer hasNext={hasNext} onClick={onClickNextDouble}>
          <ArrowRightImage source={ArrowLeftDouble} />
        </NextArrowContainer>
      </PaginationInnerContainer>
    </Container>
  )
}

const PaginationContainer = forwardRef(PaginationContainerBase)
export default PaginationContainer

const Container = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  width: 100%;
  height: 3.8rem;
  background: #f5f5f5;
  border-radius: 0 0 0.8rem 0.8rem;
`
const PaginationInnerContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  column-gap: 0.8rem;
  width: 18.6rem;
  height: 2.2rem;
`
const ArrowLeftImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const ArrowRightImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  transform: rotateY(180deg);
`
const PaginationNumberContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  column-gap: 1.2rem;
  padding: 0 0.5rem;
`
const PaginationNumber = styled.div<{selected: boolean}>`
  width: 2.2rem;
  height: 2.2rem;
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  color: ${props => (props.selected ? '#000000' : '#999999')};
  background: ${props => (props.selected ? '#FFD100' : '#f5f5f5')};
  border-radius: 10rem;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 300)};
  line-height: 150%;
  cursor: pointer;
`
const PrevArrowContainer = styled.div<{hasPrev: boolean}>`
  cursor: pointer;
  opacity: ${props => (props.hasPrev ? 1 : 0.2)};
`

const NextArrowContainer = styled.div<{hasNext: boolean}>`
  cursor: pointer;
  opacity: ${props => (props.hasNext ? 1 : 0.2)};
`
