import React, {ReactElement, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import SVGImage from '../../../../common/SVGImage'
import {
  COperationClass,
  COperationClassTime,
} from '../../../../../model/Semester'
import {WeekdayEnum} from '../../../../../enum/WeekdayEnum'
import TimeItem from './TimeItem'
import CheckBox from '../../../../../asset/image/checked_brown.svg'

type Props = {
  operationClass: COperationClass
  onSelect(wd: WeekdayEnum, time: COperationClassTime, isStart: boolean): void
  onSelectAll(wd: WeekdayEnum): void
}

export default function ClassItem(props: Props): ReactElement {
  const CheckBoxComponent = useMemo(() => {
    if (props.operationClass.isChecked) {
      return <WeekdayCheckBox source={CheckBox} />
    }

    return null
  }, [props.operationClass])

  const onSelectAll = useCallback(() => {
    props.onSelectAll(props.operationClass.weekday)
  }, [props.onSelectAll])

  return (
    <Container>
      <HeaderContainer onClick={onSelectAll}>
        <WeekdayCheckBoxContainer>{CheckBoxComponent}</WeekdayCheckBoxContainer>
        <Header selectable={props.operationClass.isEnable}>
          {props.operationClass.weekday.exposure}요일
        </Header>
      </HeaderContainer>
      <ContentContainer>
        <StartContainer>
          <StartHeader selectable={props.operationClass.isEnable}>
            등원 운행 시간
          </StartHeader>
          <TimeContainer>
            {props.operationClass.startTimes.map((st, index) => {
              return (
                <TimeItem
                  key={`${st.time}_${index}`}
                  time={st}
                  onSelect={t =>
                    props.onSelect(props.operationClass.weekday, t, true)
                  }
                  isChecked={st.isChecked}
                  isEnable={st.isEnable}
                />
              )
            })}
          </TimeContainer>
        </StartContainer>
        <Separator />
        <EndContainer>
          <EndHeader selectable={props.operationClass.isEnable}>
            하원 운행 시간
          </EndHeader>
          <TimeContainer>
            {props.operationClass.endTimes.map((et, index) => {
              return (
                <TimeItem
                  key={`${et.time}_${index}`}
                  time={et}
                  onSelect={t =>
                    props.onSelect(props.operationClass.weekday, t, false)
                  }
                  isChecked={et.isChecked}
                  isEnable={et.isEnable}
                />
              )
            })}
          </TimeContainer>
        </EndContainer>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background: #ffffff;
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  padding: 0.8rem;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
`

const WeekdayCheckBoxContainer = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  ${flexRow};
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  border: 0.1rem solid #d9d9d9;
  border-radius: 0.4rem;
`

const WeekdayCheckBox = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const Header = styled.div<{selectable: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selectable ? 700 : 500)};
  line-height: 150%;
  color: ${props => (props.selectable ? '#000000' : '#999999')};
`

const ContentContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const StartContainer = styled.div`
  ${flexColumn};
  padding: 0.4rem 1.2rem 1.2rem 3rem;
`

const Separator = styled.div`
  width: 100%;
  height: 0.1rem;
  background: #ebebeb;
`

const StartHeader = styled.div<{selectable: boolean}>`
  color: ${props => (props.selectable ? '#009a17' : '#999999')};
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 0.4rem;
  margin-top: 0.6rem;
`

const EndContainer = styled.div`
  ${flexColumn};
  padding: 0.4rem 1.2rem 1.2rem 3rem;
`

const EndHeader = styled(StartHeader)`
  color: ${props => (props.selectable ? '#4b84dd' : '#999999')};
  margin-top: 0;
`

const TimeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 0.6rem;
  column-gap: 0.6rem;
  overflow: auto;
`
