import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import DragIcon from '../../../asset/image/drag_icon.svg'
import DepartureIcon from '../../../asset/image/departure_station_blue.svg'
import StationSideBarSelect, {StationSelectRef} from './StationSideBarSelect'
import Picker, {toPickerOptions} from '../../input/Picker'
import PinIcon from '../../../asset/image/pin.svg'
import DisabledPinIcon from '../../../asset/image/disabled_pin.svg'
import YellowPinIcon from '../../../asset/image/pin_yellow.svg'
import styled from 'styled-components'
import {flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {CCourseDetail, CStation} from '../../../model/Station'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import useSecureRef from '../../../hook/useSecureRef'
import {Optional} from '../../../type/Common'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {StationInfo} from './StationSideBarCourseSetting'
import StationSideBarTimeInputContainer from './StationSideBarTimeInputContainer'

type StationSideBarDepartureProps = {
  operationType: OperationTypeEnum
  stations: CStation[]
  setFixedCenterLatLng(center: {lat: number; lng: number}): void
  onChangeDeparture(s: Optional<CStation>, st: Optional<number>): void
  addStationInfo(): void
  modifyType: Optional<number>
  courseDetail?: CCourseDetail
  addNewCourse: boolean
  setSelectedDepartureStation: React.Dispatch<React.SetStateAction<CStation>>
  selectedIdx: Optional<number>
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>
  handleSelectDepartureStation: (s: CStation) => void
  checkedStation: Optional<CStation>
  handleSelectedStation: React.Dispatch<
    React.SetStateAction<Optional<CStation>>
  >
  centerStation: CStation[]
}
export type StationSideBarDepartureRef = {
  getStationInfo(): Optional<StationInfo>
}

function StationSideBarDepartureBase(
  props: StationSideBarDepartureProps,
  ref: ForwardedRef<StationSideBarDepartureRef>,
): ReactElement {
  const selectRef = useSecureRef<StationSelectRef>(
    '[StationSideBarDeparture.tsx] selectRef',
  )

  // const defaultSelectedStation = useMemo(() => {
  //   console.log(props)
  //   console.log(props.operationType)
  //   console.log(OperationTypeEnum.INBOUND)
  //   if (
  //     props.operationType === OperationTypeEnum.INBOUND &&
  //     props.stations.length === 1
  //   ) {
  //     console.log('here!!!')
  //     return props.stations[0]
  //   }

  //   return null
  // }, [props.operationType, props.stations])

  const [selectedStation, setSelectedStation] = useState<Optional<CStation>>(
    // defaultSelectedStation,
    null,
  )
  const [spendTime, setSpendTime] = useState<Optional<number>>(null)

  const onSelectStation = useCallback(
    (s: CStation) => {
      setSelectedStation(s)
      setSpendTime(null)
      props.onChangeDeparture(s, null)
    },
    [setSelectedStation, spendTime, props.onChangeDeparture, setSpendTime],
  )
  const onChangeSpendTime = useCallback(
    (st: number) => {
      if (isNil(st)) {
        setSpendTime(null) // spendTime을 null로 설정
      } else {
        setSpendTime(st) // spendTime 업데이트
      }

      props.onChangeDeparture(selectedStation, st)
    },
    [setSpendTime, selectedStation, props.onChangeDeparture],
  )

  const handleCenterLatLng = useCallback(() => {
    if (isNil(selectedStation?.name)) {
      return
    }

    props.setSelectedIdx(0)
    props.setFixedCenterLatLng({
      lat: selectedStation.lat,
      lng: selectedStation.lng,
    })

    props.handleSelectedStation(null)
  }, [
    props.setFixedCenterLatLng,
    selectedStation,
    props.setSelectedIdx,
    props.handleSelectedStation,
  ])

  const academyOptions = useMemo(() => {
    return toPickerOptions(
      props.stations,
      v => <div style={{fontSize: 13, paddingLeft: 6}}>{v.name}</div>,
      v => <div style={{fontSize: 13}}>{v.name}</div>,
    )
  }, [props.stations])

  useEffect(() => {
    console.log(props.courseDetail)
    const station = props?.courseDetail?.course.stations[0]

    setSelectedStation({
      type: station?.type,
      id: station?.id,
      name: station?.name,
      roadAddress: station?.roadAddress,
      address: station?.address,
      lat: station?.lat,
      lng: station?.lng,
      spendTime: station?.spendTime,
    })

    setSpendTime(station?.spendTime)
  }, [props?.courseDetail?.course])

  // useEffect(() => {
  //   if (!isNil(selectedStation)) {
  //     props.handleSelectDepartureStation(selectedStation)
  //   }
  // }, [selectedStation])

  // useEffect(() => {
  //   if (props?.stations?.length === 1) {
  //     onSelectStation(props.stations[0])
  //   }
  // }, [props.stations, props.operationType])

  const lastStationName = props?.courseDetail?.course.stations[0]
  const selectedAcademyStation = academyOptions.filter(
    el => el.value?.name === lastStationName?.name,
  )

  const SelectInputComponent = useMemo(() => {
    if (props.operationType === OperationTypeEnum.INBOUND) {
      return (
        <StationSideBarSelect
          type="departure"
          ref={selectRef.ref}
          onChange={onSelectStation}
          selectedStation={props.addNewCourse ? null : selectedStation}
        />
      )
    }

    if (props.stations.length === 1) {
      return (
        <AcademyStationInput
          placeholder={props.stations[0].name}
          readOnly={true}
        />
      )
    }

    return (
      <>
        {!isEmptyArray(selectedAcademyStation) && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            onChange={onSelectStation}
            defaultIdx={
              !isNil(props?.modifyType) ? selectedAcademyStation[0]?.idx : null
            }
          />
        )}
        {props.addNewCourse && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            zIndex={2}
            onChange={onSelectStation}
          />
        )}
        {!props.addNewCourse && isEmptyArray(selectedAcademyStation) && (
          <Picker
            options={academyOptions}
            placeholder={'학원 정류장 선택'}
            minWidth={190}
            height={30}
            onChange={onSelectStation}
          />
        )}
      </>
    )
  }, [
    props.operationType,
    props.stations,
    academyOptions,
    onSelectStation,
    selectedStation,
    props.courseDetail,
    selectedAcademyStation,
    props.addNewCourse,
  ])

  useImperativeHandle(
    ref,
    () => ({
      getStationInfo(): Optional<StationInfo> {
        if (isNil(selectedStation) || isNil(spendTime)) {
          return null
        }

        return {
          stationId: selectedStation.id,
          spendTime: spendTime,
          lat: selectedStation.lat,
          lng: selectedStation.lng,
          name: selectedStation.name,
        }
      },
    }),
    [selectedStation, spendTime],
  )

  return (
    <Fragment>
      <DepartureStation>
        <DefaultImg source={DepartureIcon} />
        {SelectInputComponent}
        <MapIcon
          onClick={handleCenterLatLng}
          selectedStation={selectedStation}
          src={
            isNil(selectedStation?.name)
              ? DisabledPinIcon
              : isEmptyArray(props.centerStation)
                ? PinIcon
                : props.selectedIdx === 0 && isNil(props.checkedStation)
                  ? YellowPinIcon
                  : PinIcon
          }
        />
      </DepartureStation>
      <StationSideBarTimeInputContainer
        selectedStation={selectedStation}
        value={spendTime !== null ? spendTime : null} // spendTime이 null일 때 빈 문자열로 설정
        onChange={onChangeSpendTime}
        addStationInfo={props.addStationInfo}
      />
    </Fragment>
  )
}

const StationSideBarDeparture = forwardRef(StationSideBarDepartureBase)
export default StationSideBarDeparture

const DepartureStation = styled.div`
  position: relative;
  ${flexRow};
  height: 3rem;
  column-gap: 0.2rem;
  align-items: center;
`

const DefaultImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`

const StationInput = styled.input`
  width: 18rem;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #ebebeb;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
  color: #000;
  cursor: pointer;

  ::placeholder {
    color: #000;
  }
`

const AcademyStationInput = styled(StationInput)`
  background: #f5f5f5;
  font-weight: 500;
  color: #999;
`

type PinProps = {
  selectedStation?: Optional<CStation>
}

const MapIcon = styled.img<PinProps>`
  width: 1.8rem;
  height: 1.8rem;
  cursor: ${props =>
    isNil(props.selectedStation?.name) ? 'not-allowed' : 'pointer'};
`
