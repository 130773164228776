import React from 'react'
import styled from 'styled-components'
import {flexColumn, ModalButton} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import CloseButton from '../../../asset/image/close_button.svg'

type PopUpProps = {
  onClose?: () => void
  onClickEditButton?: () => void
}

export function CancelRegisterPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>호차 등록 취소</Title>
      <SubTitle>
        작성 중인 내용은 저장되지 않습니다.
        <br />
        호차 등록을 취소 하시겠습니까?
      </SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.onClickEditButton}>예</ConfirmButton>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
      </ButtonWrapper>
    </Container>
  )
}

export function CancelDriverRegisterPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>신규 기사 등록 취소</Title>
      <SubTitle>
        작성 중인 내용은 저장되지 않습니다.
        <br />
        기사 등록을 취소 하시겠습니까?
      </SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.onClickEditButton}>예</ConfirmButton>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 1.6rem 1.2rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 3rem;
  position: relative;
`
const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`
const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
`
const SubTitle = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a468b;
  margin-bottom: 2rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`
const CancelButton = styled(ModalButton)``
const ConfirmButton = styled(ModalButton)`
  background: #ffcd00;
`
