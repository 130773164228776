import React, {useCallback} from 'react'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {Optional} from '../../../../../../../type/Common'
import {CManager} from '../../../../../../../model/Manager'
import {CCourse} from '../../../../../../../model/Course'
import SVGImage from '../../../../../../common/SVGImage'
import Bus from '../../../../../../../asset/image/bus_yellow.svg'
import {CDispatchCourseWithStudent} from '../../../../../../../model/Dispatch'
import ExtraBusAdd from './extraBus/ExtraBusAdd'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {isNil} from '../../../../../../../util/ValidationUtil'
import OperationInfoInputContainer from './OperationInfoInputContainer'
import {BusInfo} from '../../DispatchEdit'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'
import {StudentLoadTypeEnum} from '../../../../../../../enum/StudentLoadTypeEnum'
import {
  deleteDispatchesCoursesWithStudents,
  DeleteDispatchesCoursesWithStudentsData,
} from '../../../../../../../service/dispatch/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {throwError} from '../../../../../../../util/ErrorUtil'

type Props = {
  dispatchCourses: CDispatchCourseWithStudent[]
  dispatchType: DispatchTypeEnum
  dispatchCode: string
  dispatchPeriod: CDispatchPeriodInfo
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusInfo: Optional<BusInfo>
  manager: Optional<CManager>
  extraManager: Optional<CManager>

  onChangeCourse(c: CCourse): void
  onChangeExtraCourse(c: CCourse): void
  onChangeExtraBus(bi: BusInfo): void
  onChangeManager(m: CManager): void
  onChangeExtraManager(m: CManager): void
  onDeleteExtra(): void
}

export default function OperationInfoFull(props: Props) {
  const academyID = useRecoilValue(academyIDState)
  const addExtraBusRef = useSecureRef<ModalRef>(
    '[OperationInfoFull.tsx] selectCourseModalRef',
  )

  const deleteRadisSavedTempDispatchDetails = useCallback(
    (data: DeleteDispatchesCoursesWithStudentsData) => {
      deleteDispatchesCoursesWithStudents(data)
        .then(cws => {
          // console.log(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  const showAddExtraBus = useCallback(() => {
    if (isNil(props.course)) {
      alert('운행 노선을 선택해주세요.')
      return
    }

    // REDIS 정보 삭제
    const couseData: DeleteDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.course.code,
      type: StudentLoadTypeEnum.EDIT,
    }
    deleteRadisSavedTempDispatchDetails(couseData)
    if (props.extraCourse) {
      const extraCourseData: DeleteDispatchesCoursesWithStudentsData = {
        dispatchCode: props.dispatchCode,
        academyID: academyID,
        courseCode: props.extraCourse.code,
        type: StudentLoadTypeEnum.EDIT,
      }
      deleteRadisSavedTempDispatchDetails(extraCourseData)
    }

    addExtraBusRef.current().show()
  }, [props.course])

  const hideAddExtraBus = useCallback(() => {
    addExtraBusRef.current().hide()
  }, [])

  const onChangeManager = useCallback(
    (sm: CManager) => {
      props.onChangeManager(sm)
    },
    [props.onChangeManager],
  )

  const onSubmitCourse = useCallback(
    (c: CCourse) => {
      props.onChangeCourse(c)
    },
    [props.onChangeCourse],
  )

  const onSubmitExtraBusAdd = useCallback(
    (c: CCourse, ec: CCourse, bi: BusInfo) => {
      props.onChangeCourse(c)
      props.onChangeExtraCourse(ec)
      props.onChangeExtraBus(bi)
      hideAddExtraBus()
    },
    [
      props.onChangeCourse,
      props.onChangeExtraCourse,
      props.onChangeExtraBus,
      hideAddExtraBus,
    ],
  )

  return (
    <Container>
      <Modal zIndex={3} ref={addExtraBusRef.ref}>
        <ExtraBusAdd
          dispatchCourses={props.dispatchCourses}
          dispatchPeriodID={props.dispatchPeriod.id}
          course={props.course}
          extraCourse={props.extraCourse}
          busName={props.busName}
          extraBusName={props.extraBusInfo?.name}
          dispatchCode={props.dispatchCode}
          dispatchType={props.dispatchType}
          onCancel={hideAddExtraBus}
          onSubmit={onSubmitExtraBusAdd}
        />
      </Modal>

      <HeaderContainer>
        <HeaderText>호차 및 운행 정보</HeaderText>
        {isNil(props.extraCourse) ? (
          <AddExtraBusButton onClick={showAddExtraBus}>
            <AddExtraBusImage source={Bus} />
            <AddExtraBusText>쪽차 추가</AddExtraBusText>
          </AddExtraBusButton>
        ) : null}
      </HeaderContainer>
      <OperationInfoInputContainer
        course={props.course}
        manager={props.manager}
        extraManager={props.extraManager}
        dispatchType={props.dispatchType}
        onChangeExtraManager={props.onChangeExtraManager}
        onSubmitCourse={onSubmitCourse}
        onChangeManager={onChangeManager}
        onDeleteExtraCourseSummary={props.onDeleteExtra}
        showAddExtraBus={showAddExtraBus}
        extraCourse={props.extraCourse}
        busName={props.busName}
        extraBusName={props.extraBusInfo?.name}
        dispatchPeriod={props.dispatchPeriod}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`

const HeaderContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 1rem;
`

const AddExtraBusButton = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 2.2rem;
  background: #332a00;
  border-radius: 1.6rem;
  column-gap: 0.4rem;
  cursor: pointer;
`

const AddExtraBusImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const AddExtraBusText = styled.div`
  color: #ffd100;
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
