import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import ReactDatePicker, {ReactDatePickerCustomHeaderProps} from 'react-datepicker'
import '../../style/DatePicker.css'
import {CDate} from '../../model/Date'
import {CDateTime} from '../../model/DateTime'
import {isNil} from '../../util/ValidationUtil'
import PrevButton from '../../asset/image/prev_button.svg'
import NextButton from '../../asset/image/next_button.svg'
import {ko} from 'date-fns/locale'
import DatePickerCustomInput from './DatePickerCustomInput'
import {BaseDateUnitEnum} from '../../enum/DateUnitEnum'
import DatePickerBlueInput from './DatePickerBlueInput'

type DatePickerProps = {
  defaultDate?: CDate
  minDate?: CDate
  maxDate?: CDate
  onChange(date: CDate): void
  theme?: string
}
export type DatePickerRef = {
  getValue(): CDate
  setValue(value: CDate): void
}

function DatePickerBase(props: DatePickerProps, ref: ForwardedRef<DatePickerRef>): ReactElement {
  // TODO: 최선어학원 임시 당일 등록 삭제가능
  // const [value, setValue] = useState<CDate>(CDateTime.now().add(1, BaseDateUnitEnum.DAY).toDate())
  const [value, setValue] = useState<CDate>(CDateTime.now().toDate())
  const onChange = useCallback(
    (dateRaw: Date) => {
      const dt = CDateTime.create(dateRaw)
      const date = dt.toDate()
      props.onChange(date)
      setValue(date)
    },
    [props.onChange],
  )

  const renderCustomHeader = useCallback((params: ReactDatePickerCustomHeaderProps) => {
    const Date = CDateTime.create(params.date)

    return (
      <CustomHeaderContainer>
        <MonthArrowWrapper>
          <DecreaseMonthButton onClick={params.decreaseMonth} />
          <CurrentMonth>{Date.format(CDateTime.FORMAT_YYYY_MM)}</CurrentMonth>
          <NextMonthButton onClick={params.increaseMonth} />
        </MonthArrowWrapper>
      </CustomHeaderContainer>
    )
  }, [])

  useEffect(() => {
    if (isNil(props.defaultDate)) {
      return
    }

    setValue(props.defaultDate)
  }, [props.defaultDate])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): CDate {
        return value
      },
      setValue(value: CDate) {
        setValue(value)
      },
    }),
    [value],
  )

  return (
    <ReactDatePicker
      locale={ko}
      selected={value.toDateRaw()}
      dateFormat={'yyyy-MM-dd'}
      minDate={props.minDate?.toDateRaw()}
      maxDate={props.maxDate?.toDateRaw()}
      onChange={onChange}
      popperPlacement="top-end"
      showPopperArrow={false}
      className={'singleDatePicker'}
      value={value.toString()}
      customInput={
        props.theme === 'blue' ? (
          <DatePickerBlueInput value={value.toString()} />
        ) : (
          <DatePickerCustomInput value={value.toString()} />
        )
      }
      renderCustomHeader={renderCustomHeader}
    />
  )
}

const DatePicker = forwardRef(DatePickerBase)
export default DatePicker

const MonthArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 0 1rem;
  padding-bottom: 1.3rem;
  max-height: 5.3rem;
`

const DecreaseMonthButton = styled.div`
  background: url(${PrevButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const CurrentMonth = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.042rem;
`

const NextMonthButton = styled.div`
  background: url(${NextButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const CustomHeaderContainer = styled.div`
  width: 22.4rem;
`
