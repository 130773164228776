import React, {Fragment, ReactElement} from 'react'
import styled from 'styled-components'
import Header from '../../../common/Header'
import {alignCenter, flexColumn, flexRow} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchPeriodSettingHeader(_: Props): ReactElement {
  return (
    <Fragment>
      <StyledHeader>배차 기간 설정</StyledHeader>
      <InfoContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <EmphasizedText>
            배차 당일 등록 및 당일 수정은 할 수 없습니다.
          </EmphasizedText>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>등록된 배차 기간은</Text>
          <EmphasizedText>
            {' '}
            운행이 시작 된 이후 삭제 할 수 없습니다.
          </EmphasizedText>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>
            운행이 시작된 이후에는 [배차 기간명], [배차 종료일]만 변경 가능하며,
            배차 종료일이 줄어드는 경우
          </Text>
          <EmphasizedText>변경일 이후 배차는 모두 삭제 됩니다.</EmphasizedText>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>배차 기간에 배차가 등록된 경우,</Text>
          <EmphasizedText>
            {' '}
            등하원 도착 출발 시간 변경은 할 수 없습니다.
          </EmphasizedText>
        </InfoTextContainer>
      </InfoContainer>
    </Fragment>
  )
}

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexColumn};
  row-gap: 0.3rem;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.p`
  color: #999999;
  font-size: 1.1rem;
`

const EmphasizedText = styled.p`
  color: #ff7500;
  font-size: 1.1rem;
`
