export class WeekdayEnum {
  static readonly MONDAY = new WeekdayEnum(1, '월', 'MONDAY')
  static readonly TUESDAY = new WeekdayEnum(2, '화', 'TUESDAY')
  static readonly WEDNESDAY = new WeekdayEnum(3, '수', 'WEDNESDAY')
  static readonly THURSDAY = new WeekdayEnum(4, '목', 'THURSDAY')
  static readonly FRIDAY = new WeekdayEnum(5, '금', 'FRIDAY')
  static readonly SATURDAY = new WeekdayEnum(6, '토', 'SATURDAY')
  static readonly SUNDAY = new WeekdayEnum(0, '일', 'SUNDAY')

  static readonly ALL = [
    this.MONDAY,
    this.TUESDAY,
    this.WEDNESDAY,
    this.THURSDAY,
    this.FRIDAY,
    this.SATURDAY,
    this.SUNDAY,
  ]

  private constructor(
    readonly id: number,
    readonly exposure: string,
    readonly value: string,
  ) {}

  static parseIDtoWeekday(id: number) {
    switch (id) {
      case this.SUNDAY.id:
        return this.SUNDAY
      case this.MONDAY.id:
        return this.MONDAY
      case this.TUESDAY.id:
        return this.TUESDAY
      case this.WEDNESDAY.id:
        return this.WEDNESDAY
      case this.THURSDAY.id:
        return this.THURSDAY
      case this.FRIDAY.id:
        return this.FRIDAY
      case this.SATURDAY.id:
        return this.SATURDAY
      default:
        throw new Error(`invalid weekday id. (id: ${id})`)
    }
  }

  static parseValueToWeekday(value: string) {
    switch (value) {
      case this.SUNDAY.value:
        return this.SUNDAY
      case this.MONDAY.value:
        return this.MONDAY
      case this.TUESDAY.value:
        return this.TUESDAY
      case this.WEDNESDAY.value:
        return this.WEDNESDAY
      case this.THURSDAY.value:
        return this.THURSDAY
      case this.FRIDAY.value:
        return this.FRIDAY
      case this.SATURDAY.value:
        return this.SATURDAY
      default:
        throw new Error(`invalid weekday id. (value: ${value})`)
    }
  }
}

export class WeekdayStringEnum {
  static readonly SUNDAY = new WeekdayStringEnum('SUNDAY', '일')
  static readonly MONDAY = new WeekdayStringEnum('MONDAY', '월')
  static readonly TUESDAY = new WeekdayStringEnum('TUESDAY', '화')
  static readonly WEDNESDAY = new WeekdayStringEnum('WEDNESDAY', '수')
  static readonly THURSDAY = new WeekdayStringEnum('THURSDAY', '목')
  static readonly FRIDAY = new WeekdayStringEnum('FRIDAY', '금')
  static readonly SATURDAY = new WeekdayStringEnum('SATURDAY', '토')

  static readonly ALL = [
    this.MONDAY,
    this.TUESDAY,
    this.WEDNESDAY,
    this.THURSDAY,
    this.FRIDAY,
    this.SATURDAY,
    this.SUNDAY,
  ]

  private constructor(
    readonly day: string,
    readonly value: string,
  ) {}

  static parseIDtoWeekday(day: string) {
    switch (day) {
      case this.SUNDAY.day:
        return this.SUNDAY
      case this.MONDAY.day:
        return this.MONDAY
      case this.TUESDAY.day:
        return this.TUESDAY
      case this.WEDNESDAY.day:
        return this.WEDNESDAY
      case this.THURSDAY.day:
        return this.THURSDAY
      case this.FRIDAY.day:
        return this.FRIDAY
      case this.SATURDAY.day:
        return this.SATURDAY
      default:
        throw new Error(`invalid weekday id. (id: ${day})`)
    }
  }
}
