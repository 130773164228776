import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {useRecoilValue} from 'recoil'
import styled from 'styled-components'
import {Optional} from '../../../type/Common'
import {academyIDState} from '../../../recoil/Atom'
import {color, flexColumn, flexRow} from '../../../style/CommonStyle'
import useSecureRef from '../../../hook/useSecureRef'
import HalfPopup, {PopUpRef} from '../../common/HalfPopUp'
import {RegisterDispatchPopUp} from '../popupContents/RegisterDispatchPopUp'
import {CancelRegisterPopUp} from '../popupContents/CancelRegisterDispatch'
import {notAssignedDrivers} from '../../../service/driver/Driver'
import {GetBusesParams, postBus} from '../../../service/buses/Buses'
import {CDriver} from '../../../model/Driver'
import {isEmptyString, isNil} from '../../../util/ValidationUtil'
import ModalPagination from '../modalPagination/ModalPagination'
import DownArrow from '../../../asset/image/down_arrow.svg'
import SVGImage from '../../common/SVGImage'
import TextInput, {TextInputRef} from '../../input/TextInput'
import {NumberInputRef} from '../../input/NumberInput'
import {
  TimeInput,
  TimeInputContainer,
} from '../../managerManagement/registerManager/RegisterManager'

type RegisterDriverProps = {
  hideRegisterModal(): void
  fetchDispatchesList(): void
  setName: Dispatch<SetStateAction<string>>
}
export type RegisterDriverRef = {
  reset(): void
}

const PER_PAGE = 8

function RegisterBusBase(
  props: RegisterDriverProps,
  ref: ForwardedRef<RegisterDriverRef>,
): ReactElement {
  const cancelRef = useSecureRef<PopUpRef>('[RegisterDriver.tsx] cancelRef')
  const registerRef = useSecureRef<PopUpRef>('[RegisterDriver.tsx] registerRef')
  const nameInputRef = useSecureRef<TextInputRef>(
    '[RegisterBus.tsx] nameInputRef',
  )
  const licensePlateInputRef = useSecureRef<TextInputRef>(
    '[RegisterBus.tsx] licensePlateInputRef',
  )
  const validSeatInputRef = useSecureRef<NumberInputRef>(
    '[RegisterBus.tsx] validInputRef',
  )
  const companyInputRef = useSecureRef<TextInputRef>(
    '[RegisterBus.tsx] companyInputRef',
  )
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [submitPressable, setSubmitPressable] = useState<boolean>(false)
  const [driverPopUp, setDriverPopUp] = useState(false)
  const [name, setName] = useState<string>('')
  const [driverId, setDriverId] = useState<Optional<number>>(null)
  const [licensePlate, setLicensePlate] = useState<string>('')
  const [validSeat, setValidSeat] = useState<Optional<number>>(null)
  const [transportCompany, setTransportCompany] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [keyword, setKeyword] = useState<string>('')
  const [totalElements, setTotalElements] = useState<Optional<number>>(null)
  const [driverState, setDriverState] = useState<Optional<CDriver[]>>(null)
  const [selectedDriver, setSelectedDriver] = useState<Optional<CDriver[]>>([])

  const handleCancelButton = useCallback(() => {
    cancelRef.current().show()
  }, [])

  const handleRegisterButton = useCallback(() => {
    if (!submitPressable) {
      return
    }
    return registerRef.current().show()
  }, [submitPressable])

  const getNotAssignedDriverList = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetBusesParams = {
      academyID: selectedAcademyID,
      page: currentPage,
      searchValue: keyword,
      size: PER_PAGE,
    }

    notAssignedDrivers(data)
      .then(dl => {
        setDriverState(dl.drivers)
        setTotalElements(dl.paging.totalElements)
      })
      .catch(error => {})
  }, [selectedAcademyID, currentPage, keyword])

  const onChangeName = useCallback(
    (s: string) => {
      return setName(s)
    },
    [setName],
  )

  const onChangeValidSeat = useCallback(
    (n: number) => {
      return setValidSeat(n)
    },
    [setValidSeat],
  )

  const onChangeLicensePlate = useCallback(
    (s: string) => {
      return setLicensePlate(s)
    },
    [setLicensePlate],
  )

  const onChangeCompany = useCallback(
    (s: string) => {
      return setTransportCompany(s)
    },
    [setTransportCompany],
  )

  const onClickCancelButton = useCallback(() => {
    registerRef.current().hide()
    cancelRef.current().hide()
    setDriverId(null)
  }, [])

  const onConfirmCancel = useCallback(() => {
    props.hideRegisterModal()
    cancelRef.current().hide()
    nameInputRef.current().reset()
    licensePlateInputRef.current().reset()
    validSeatInputRef.current().reset()
    companyInputRef.current().reset()
    setName('')
    setLicensePlate('')
    setTransportCompany('')
    setValidSeat(null)
    setErrorMessage('')
    setSelectedDriver([])
    setDriverPopUp(false)
    setDriverId(null)
  }, [])

  const onSubmit = useCallback(() => {
    if (!submitPressable) {
      return
    }

    if (submitPressable) {
      const data = {
        driverID: driverId,
        name: name,
        licensePlate: licensePlate,
        validSeat: validSeat,
        transportCompany: transportCompany,
      }

      postBus(selectedAcademyID, data)
        .then(_ => {
          registerRef.current().hide()
          props.hideRegisterModal()
          props.fetchDispatchesList()
          props.setName(name)
          nameInputRef.current().setValue('')
          licensePlateInputRef.current().setValue('')
          companyInputRef.current().setValue('')
          validSeatInputRef.current().reset()
          setName('')
          setLicensePlate('')
          setTransportCompany('')
          setValidSeat(null)
          setErrorMessage('')
          setSelectedDriver([])
        })
        .catch(error => {
          setErrorMessage(error.response.data.data)
          registerRef.current().hide()
        })
    }
  }, [
    submitPressable,
    driverId,
    name,
    licensePlate,
    validSeat,
    transportCompany,
    academyIDState,
  ])

  useEffect(() => {
    if (
      name !== '' &&
      licensePlate !== '' &&
      validSeat !== null &&
      driverId !== null
    ) {
      setSubmitPressable(true)
    } else setSubmitPressable(false)
  }, [name, licensePlate, validSeat, driverId])

  useEffect(() => {
    getNotAssignedDriverList()
  }, [selectedAcademyID, currentPage, keyword])

  useEffect(() => {
    if (!isNil(driverState)) {
      setSelectedDriver(driverState?.filter(el => el.id === driverId))
    }
  }, [driverState, driverId])

  return (
    <Container>
      <Header>호차 등록</Header>
      <Noti>
        • <span>*</span> 는 필수 입력 항목입니다.
        <br />• 인승 정보는 해당 차량의 모든 좌석수를 의미하며{' '}
        <span>
          가용 좌석 정보는 기사 좌석 등을 제외한 학생 이용 가능 좌석수
        </span>
        를 의미합니다.
      </Noti>
      <BodyWrapper>
        <Title>기본 정보</Title>
        <Info>
          <CardWrapper>
            <Card>
              <SubTitle>
                호차명 <span>*</span>
              </SubTitle>
              <BusNameWrapper>
                <CustomTextInput
                  ref={nameInputRef.ref}
                  onChange={onChangeName}
                  placeholder={'호차를 지정해 주세요.'}
                  value={name}
                  error={
                    errorMessage.includes('name') ||
                    errorMessage.includes('중복된 호차명')
                  }
                  readOnly={false}
                  required={false}
                />
                <BusNameTitle>호차</BusNameTitle>
              </BusNameWrapper>
              {errorMessage.includes('중복된 호차명') && (
                <Error>중복된 호차명 입니다.</Error>
              )}
              {errorMessage.includes('name') && (
                <Error>
                  호차명은 최소 1자 ~ 최대 43자, 띄어쓰기를 허용하지 않습니다.
                  <br />- 하이픈은 _ 언더바로 입력해야합니다.
                </Error>
              )}
            </Card>
            <Card>
              <SubTitle>
                차량 번호 <span>*</span>
              </SubTitle>
              <CustomTextInput
                ref={licensePlateInputRef.ref}
                onChange={onChangeLicensePlate}
                placeholder={'차량번호를 입력해주세요.'}
                value={licensePlate}
                error={
                  errorMessage.includes('licensePlate') ||
                  errorMessage.includes('R611-1')
                }
                readOnly={false}
                required={false}
              />
              {errorMessage.includes('licensePlate') && (
                <Error>올바른 차량 번호 양식이 아닙니다.</Error>
              )}
              {errorMessage.includes('R611-1') && (
                <Error>중복된 차량 번호 입니다.</Error>
              )}
            </Card>
            <Card>
              <SelectDriverWrapper>
                <SubTitle>
                  운행 기사 <span>*</span>
                </SubTitle>
                <DriverInput
                  onClick={() => setDriverPopUp(true)}
                  error={errorMessage.includes('R999')}
                  value={selectedDriver[0]?.name}>
                  {selectedDriver.length !== 0
                    ? `${selectedDriver[0]?.name}기사님`
                    : '변경할 기사를 선택해 주세요.'}
                  <ArrowWrapper>
                    <DownArrowImg source={DownArrow} />
                  </ArrowWrapper>
                </DriverInput>
                {errorMessage.includes('R999') && (
                  <Error>사용자를 찾을 수 없습니다.</Error>
                )}
                {driverPopUp && (
                  <ModalPagination
                    width={'24rem'}
                    onClose={() => setDriverPopUp(false)}
                    data={driverState}
                    PER_PAGE={PER_PAGE}
                    totalElements={totalElements}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    setDriverID={setDriverId}
                    getNotAssignedDriverList={getNotAssignedDriverList}
                  />
                )}
              </SelectDriverWrapper>
            </Card>
          </CardWrapper>
        </Info>
        <Info>
          <BottomCardWrapper>
            <Card>
              <SubTitle>
                가용 좌석 <span>*</span>
              </SubTitle>

              <TimeInputContainer>
                <CustomTimeInput
                  ref={validSeatInputRef.ref}
                  maxLength={11}
                  required={false}
                  readOnly={false}
                  placeholder={'좌석수를 입력해주세요.'}
                  value={validSeat}
                  error={errorMessage.includes('validSeat')}
                  resetOff={true}
                  onChange={onChangeValidSeat}
                />
              </TimeInputContainer>
              {errorMessage.includes('validSeat') && (
                <Error>0보다 커야 합니다.</Error>
              )}
            </Card>
            <Card>
              <SubTitle>운수사</SubTitle>
              <CustomTextInput
                ref={companyInputRef.ref}
                onChange={onChangeCompany}
                placeholder={'운수사를 입력해주세요.'}
                value={transportCompany}
                error={false}
                readOnly={false}
                required={false}
              />
            </Card>
          </BottomCardWrapper>
        </Info>
      </BodyWrapper>
      <Bottom>
        <CancelButton onClick={handleCancelButton}>취소</CancelButton>
        <RegisterButton
          pressable={submitPressable}
          onClick={handleRegisterButton}>
          등록
        </RegisterButton>
      </Bottom>
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={registerRef.ref}
        contents={
          <RegisterDispatchPopUp
            onClose={onClickCancelButton}
            onSubmitButton={onSubmit}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={cancelRef.ref}
        contents={
          <CancelRegisterPopUp
            onClose={onClickCancelButton}
            onClickEditButton={onConfirmCancel}
          />
        }
      />
    </Container>
  )
}

const RegisterBus = forwardRef(RegisterBusBase)
export default RegisterBus

const Container = styled.div`
  border-radius: 1.6rem 0 0 1.6rem;
  width: 100%;
`

export const Header = styled.div`
  height: 6rem;
  padding: 2rem;
  background: ${color.white};
  border-bottom: 0.1rem solid #ebebeb;
  color: #332a00;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 1.6rem 0 0 0;
`

const Noti = styled.div`
  height: 4.6rem;
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #999;

  span {
    color: #ff7500;
    font-size: 1.1rem;
  }
`

const BodyWrapper = styled.div`
  ${flexColumn};
  width: 100%;
  height: calc(100vh - 17.4rem);
  padding: 1.6rem;
  row-gap: 0.8rem;
  background: #f5f5f5;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const Info = styled.div`
  padding: 0.4rem;
  ${flexColumn}
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.7rem;
  margin-left: 0.4rem;
`

const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr;
  column-gap: 0.8rem;
`

const BottomCardWrapper = styled(CardWrapper)`
  grid-template-columns: 1fr 2.83fr;
`

const Card = styled.div`
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #fff;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
`

const SelectDriverWrapper = styled.div`
  position: relative;
`

const SubTitle = styled.div`
  padding: 0.2rem;
  line-height: 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: #997d00;

  span {
    color: #ff7500;
    font-size: 1.3rem;
  }
`

const BusNameWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const BusNameTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
`

type DriverInputProps = {
  value: string
  error: boolean
}

const DriverInput = styled.div<DriverInputProps>`
  position: relative;
  width: 100%;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${props => (isEmptyString(props.value) ? '300' : '500')};
  background: ${props =>
    isEmptyString(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props =>
    props.error ? '0.1rem solid #ED766E' : '0.1rem solid #EBEBEB'};
  color: ${props =>
    isEmptyString(props.value)
      ? 'rgba(0, 0, 0, 0.3)'
      : props.error
        ? '#DA291C'
        : '#000'};

  &::placeholder {
    font-weight: 300;
  }
  :hover {
    cursor: pointer;
  }
`

const ArrowWrapper = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
`

const DownArrowImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

type InputProps = {
  error?: boolean
}

const Input = styled.input<InputProps>`
  border: 0.1rem solid #ebebeb;
  border-color: ${props => (props.error ? 'red' : '#ebebeb')};
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  width: 100%;
  line-height: 1.8rem;
  color: ${props => (props.error ? '#DA291C' : 'black')};
  background: ${props => (props.error ? '#FCE6E4' : '')};

  :focus {
    background: ${props => (props.error ? '' : '#fffbe5')};
  }
`

const Bottom = styled.div`
  height: 6.8rem;
  padding: 1.6rem 2.4rem;
  background: ${color.white};
  border-radius: 0 0 0 1.6rem;
  justify-content: end;
  ${flexRow}
`

export const CancelButton = styled.button`
  padding: 1rem 2.2rem;
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 3rem;
  margin-left: 0.4rem;
`

const RegisterButton = styled(CancelButton)<{pressable: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.pressable ? 1 : 0.3)};
  cursor: ${props => (props.pressable ? 'pointer' : 'not-allowed')};
`

const Error = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #e74d41;
  margin-top: 0.6rem;
`

type CustomTextInputProps = {
  error: boolean
  value: string
}
const CustomTextInput = styled(TextInput)<CustomTextInputProps>`
  font-weight: 500;
  background: ${props =>
    isEmptyString(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props =>
    props.error ? '0.1rem solid #ED766E' : '0.1rem solid #EBEBEB'};
  color: ${props => (props.error ? '#DA291C' : '#000')};
  &::placeholder {
    font-weight: 300;
  }
`

type CustomTimeInputProps = {
  error: boolean
  value: Optional<number>
}
const CustomTimeInput = styled(TimeInput)<CustomTimeInputProps>`
  font-weight: 500;
  background: ${props =>
    isNil(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props => (props.error ? '0.1rem solid #ED766E' : 'none')};
  color: ${props => (props.error ? '#DA291C' : '#000')};
  &::placeholder {
    font-weight: 300;
  }
`
