import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  CheckboxLabel,
  color,
  flexRow,
  RadioButton,
} from '../../style/CommonStyle'
import styled from 'styled-components'
import {SelectOption} from './Select'
import {Optional} from '../../type/Common'
import {isEmptyArray, isEmptyString, isNil} from '../../util/ValidationUtil'

const INVALID_IDX = -1

export function toCheckSelectOptions<T>(
  values: T[],
  getExposure: (v: T) => string,
): SelectOption<T>[] {
  return values.map((v, idx) => {
    return {
      idx: idx,
      exposure: getExposure(v),
      value: v,
    }
  })
}

export type CheckSelectOption<T> = {
  idx: number
  exposure: string
  value: T
}

type CheckSelectProps<T> = {
  defaultIdx?: number
  options: CheckSelectOption<T>[]
  hasAll: boolean
  placeholder?: string
  onChange(value: Optional<T>): void
}

export type CheckSelectRef<T> = {
  setIdx(idx: number): void
  setOptions(opts: SelectOption<T>[]): void
  getValue(): Optional<SelectOption<T>>
}

function CheckSelectorBase<T>(
  props: CheckSelectProps<T>,
  ref: ForwardedRef<CheckSelectRef<T>>,
): ReactElement {
  const [idx, setIdx] = useState<number>(
    isNil(props.defaultIdx) ? INVALID_IDX : props.defaultIdx,
  )
  const [options, setOptions] = useState<CheckSelectOption<T>[]>(props.options)

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const id = event.target.id
      const idx = Number(id)
      setIdx(idx)

      if (idx === INVALID_IDX) {
        props.onChange(null)
        return
      }

      props.onChange(options[idx]?.value)
    },
    [props.onChange, options],
  )

  const OptionsComponent = useMemo(() => {
    if (isEmptyArray(options)) {
      return (
        <option style={{color: color.grey2}} value={INVALID_IDX}>
          {isEmptyString(props.placeholder)
            ? '선택해주세요.'
            : props.placeholder}
        </option>
      )
    }

    return (
      <>
        {props.hasAll ? (
          <DisplayFlex>
            <RadioButton
              checked={idx === INVALID_IDX}
              id={`${INVALID_IDX}`}
              type="radio"
              onChange={onChange}
            />
            <CheckboxLabel htmlFor={`${idx}`}>전체</CheckboxLabel>
          </DisplayFlex>
        ) : null}
        {options.map((o: SelectOption<T>) => {
          return (
            <DisplayFlex key={`${idx}_${o.idx}_${o.value}`}>
              <RadioButton
                type="radio"
                id={`${o.idx}`}
                checked={o.idx === idx}
                onChange={onChange}
              />
              <CheckboxLabel htmlFor={`${idx}`}>{o.exposure}</CheckboxLabel>
            </DisplayFlex>
          )
        })}
      </>
    )
  }, [options, props.hasAll, props.placeholder, idx])

  useImperativeHandle(
    ref,
    () => ({
      setIdx(idx: number): void {
        setIdx(idx)
      },
      setOptions(opts: SelectOption<T>[]): void {
        setOptions(opts)
      },
      getValue(): Optional<SelectOption<T>> {
        return options[idx]
      },
    }),
    [options, idx],
  )

  return <RadioButtonWrapper>{OptionsComponent}</RadioButtonWrapper>
}

const CheckSelect = forwardRef(CheckSelectorBase)
export default CheckSelect

const RadioButtonWrapper = styled.div`
  ${flexRow};
  height: 3rem;
  align-items: center;
`

const DisplayFlex = styled.div`
  ${flexRow};
  align-items: center;
`
