import React from 'react'
import styled from 'styled-components'
import {ModalButton, flexColumn} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'

// image
import CloseIcon from '../../../asset/image/close_button.svg'

type ModalProps = {
  onClose: () => void
}

function ConfirmModifyStation(props: ModalProps) {
  return (
    <Container>
      <CloseButton onClick={props.onClose}>
        <CloseImg source={CloseIcon} />
      </CloseButton>
      <Title>정류장 수정 완료</Title>
      <Contents>정류장 수정이 완료 되었습니다.</Contents>
      <ButtonWrapper>
        <ConfirmButton onClick={props.onClose}>확인</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export default ConfirmModifyStation

const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 1.6;
  position: relative;
  padding: 1.6rem 1.2rem;
`

const CloseButton = styled.div``

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
`

const Contents = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a468b;
  margin: 1.6rem 0 2.4rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`

const ConfirmButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
  background: #ffcd00;
`
