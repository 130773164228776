export class DispatchOptionEnum {
  static readonly DATE = new DispatchOptionEnum('DATE', '일일 배차')
  static readonly PERIOD = new DispatchOptionEnum('PERIOD', '기간 배차')

  static readonly ALL = [DispatchOptionEnum.DATE, DispatchOptionEnum.PERIOD]

  private constructor(
    readonly value: string,
    readonly exposure: string,
  ) {}

  static parse(value: string) {
    switch (value) {
      case DispatchOptionEnum.DATE.value:
        return DispatchOptionEnum.DATE
      case DispatchOptionEnum.PERIOD.value:
        return DispatchOptionEnum.PERIOD
    }
  }
}
