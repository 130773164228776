import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Checked from '../../../../asset/image/checked_yellow.svg'
import styled from 'styled-components'
import {alignCenter, flexColumn, flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import {Optional} from '../../../../type/Common'
import {CSemester, CSemesterVector} from '../../../../model/Semester'
import {
  getSemestersWithPaging,
  GetSemestersWithPagingData,
} from '../../../../service/semester/Semester'
import Cancel from '../../../../asset/image/close.svg'
import Search from '../../../../asset/image/search.svg'
import PaginationContainer from '../../../common/PaginationContainer'
import TextInput from '../../../input/TextInput'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import {CDate} from '../../../../model/Date'

const LIMIT = 20
const PAGE_EXPOSE_COUNT = 3

type Props = {
  selectedSemesterID: Optional<number>
  isCopy: boolean
  onChange(ss: CSemester): void
}
export type DispatchSemesterSelectInputModalRef = {
  show(): void
}

function DispatchSemesterSelectInputModalBase(
  props: Props,
  ref: ForwardedRef<DispatchSemesterSelectInputModalRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const [semesterVector, setSemesterVector] =
    useState<Optional<CSemesterVector>>(null)
  const [visible, setVisible] = useState<boolean>(false)

  const fetchSemesters = useCallback(
    (academyID: string, data: GetSemestersWithPagingData) => {
      getSemestersWithPaging(academyID, data)
        .then(sv => setSemesterVector(sv))
        .catch(error => {
          throw new Error(
            `getSemesters() failed. (error: ${error}, aid: ${academyID})`,
          )
        })
    },
    [],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const data: GetSemestersWithPagingData = {
        page: page - 1,
        size: LIMIT,
      }

      fetchSemesters(academyID, data)
    },
    [fetchSemesters, academyID],
  )

  const onChangeText = useCallback(
    (t: string) => {
      const data: GetSemestersWithPagingData = {
        page: 0,
        searchWord: t,
        size: LIMIT,
      }

      fetchSemesters(academyID, data)
    },
    [academyID, fetchSemesters],
  )

  const onClickSemester = useCallback(
    (ss: CSemester) => {
      props.onChange(ss)
      setVisible(false)
    },
    [props.onChange, setVisible],
  )

  const semesters = useMemo(() => {
    const now = CDate.now()

    if (isNil(semesterVector)) {
      return []
    }

    return semesterVector.semesters.filter(el => {
      if (props.isCopy) {
        return el.endDate.isAfter(now) && props.selectedSemesterID !== el.id
      }
      return el.endDate.isAfter(now)
    })
  }, [semesterVector])

  useEffect(() => {
    const data: GetSemestersWithPagingData = {
      page: 0,
      size: LIMIT,
    }

    if (!visible) {
      return
    }

    fetchSemesters(academyID, data)
  }, [visible, academyID])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
    }),
    [setVisible],
  )

  if (!visible) {
    return null
  }

  if (isNil(semesterVector)) {
    return null
  }

  if (isEmptyArray(semesters)) {
    return (
      <SelectionContainer>
        <SelectionHeaderContainer>
          <SelectionHeader>학기 선택</SelectionHeader>
          <SelectCancel onClick={() => setVisible(false)}>
            <SelectCancelImage source={Cancel} />
          </SelectCancel>
        </SelectionHeaderContainer>
        <SearchContainer>
          <SearchImage source={Search} />
          <StyledTextInput
            onChange={onChangeText}
            placeholder={'검색어를 입력해주세요.'}
            readOnly={false}
            required={false}
          />
        </SearchContainer>

        <EmptyContainer>선택 가능한 학기가 존재하지 않습니다.</EmptyContainer>
      </SelectionContainer>
    )
  }

  return (
    <SelectionContainer>
      <SelectionHeaderContainer>
        <SelectionHeader>학기 선택</SelectionHeader>
        <SelectCancel onClick={() => setVisible(false)}>
          <SelectCancelImage source={Cancel} />
        </SelectCancel>
      </SelectionHeaderContainer>
      <SearchContainer>
        <SearchImage source={Search} />
        <StyledTextInput
          onChange={onChangeText}
          placeholder={'검색어를 입력해주세요.'}
          readOnly={false}
          required={false}
        />
      </SearchContainer>
      <ListContainer>
        {semesters.map((value, idx) => {
          return (
            <RangeContainer
              key={`${value.name}_${idx}`}
              onClick={() => onClickSemester(value)}>
              <RangeTitleContainer>
                <RangeTitle selected={props.selectedSemesterID === value.id}>
                  {value.name}
                </RangeTitle>
                <CheckedImage
                  selected={props.selectedSemesterID === value.id}
                  source={Checked}
                />
              </RangeTitleContainer>
              <RangeContent>
                기간 : {value.startDate.toString()}~{value.endDate.toString()}
              </RangeContent>
            </RangeContainer>
          )
        })}
      </ListContainer>

      <PaginationContainer
        totalElementCount={semesterVector?.paging.totalElements}
        pageExposeCount={PAGE_EXPOSE_COUNT}
        perPage={LIMIT}
        onChange={onChangePage}
      />
    </SelectionContainer>
  )
}

const DispatchSemesterSelectInputModal = forwardRef(
  DispatchSemesterSelectInputModalBase,
)
export default DispatchSemesterSelectInputModal

const SelectionContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 50rem;
  overflow: auto;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 3;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);

  ::-webkit-scrollbar {
    display: none;
  }
`

const SelectionHeaderContainer = styled.div`
  ${flexRow};
  background: #332a00;
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  align-items: center;
  justify-content: space-between;
`

const SelectCancel = styled.div``

const SelectCancelImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.4rem;
`

const SelectionHeader = styled.div`
  color: #ffd100;
  height: 3rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div`
  ${flexColumn};
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
`

const RangeContainer = styled.div`
  ${flexColumn};
  width: 100%;
  padding: 0.8rem 1.2rem;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
  &:last-child {
    border-bottom-width: 0;
  }
`

const RangeTitleContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const CheckedImage = styled(SVGImage)<{selected: boolean}>`
  display: ${props => (props.selected ? 'block' : 'none')};
  width: 1.8rem;
  height: 1.8rem;
`

const RangeTitle = styled.h1<{selected: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 500)};
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#332A00')};
`

const RangeContent = styled.p`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #999999;
`

const EmptyContainer = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  color: #999999;
  width: 100%;
  height: 5rem;
  font-size: 1.2rem;
`
