import React, {Fragment, ReactElement} from 'react'
import styled from 'styled-components'
import Header from '../../../common/Header'
import {alignCenter, flexColumn, flexRow} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchBusAddHeaderBase(_: Props): ReactElement {
  return (
    <Fragment>
      <StyledHeader>호차 등록</StyledHeader>
      <InfoContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>
            인승 정보는 해당 차량의 모든 좌석수를 의미하며
          </Text>
          <EmphasizedText>
            가용 좌석 정보는 기사 좌석 등을 제외한 학생 이용 가능 좌석수
          </EmphasizedText>
          <Text>를</Text>
        </InfoTextContainer>
        <Text style={{marginLeft: 8}}>의미합니다.</Text>
      </InfoContainer>
    </Fragment>
  )
}

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  background: #ffffff;
  border-top-left-radius: 1.6rem;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexColumn};
  row-gap: 0.3rem;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.pre`
  color: #999999;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`
const EmphasizedText = styled.pre`
  color: #ff7500;
  font-size: 1.1rem;
`
