import React, {Fragment, ReactElement} from 'react'
import styled from 'styled-components'
import Header from '../../../common/Header'
import {alignCenter, flexColumn, flexRow} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchBusSettingHeader(_: Props): ReactElement {
  return (
    <Fragment>
      <StyledHeader>호차 설정</StyledHeader>
      <InfoContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text>정규 배차로 등록할 호차를 모두 선택해 주세요.</Text>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text>
            선택 해제가 불가능한 호차는 이미 배차가 등록되어 있는 호차입니다.
          </Text>
        </InfoTextContainer>
      </InfoContainer>
    </Fragment>
  )
}

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  background: #ffffff;
  border-top-left-radius: 1.6rem;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexColumn};
  row-gap: 0.3rem;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.p`
  color: #999999;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`
