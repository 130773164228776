import {BusOperationStatusEnum} from '../enum/BusOperationStatusEnum'
import {CDateTime} from './DateTime'
import {CDate} from './Date'
import {isNil} from 'lodash'
import {CStation} from './Dispatch'
import {Optional} from '../type/Common'
import {BoardSwitchEnum} from '../enum/BoardSwitchEnum'
import {CPaging} from './Paging'
import {WeekdayEnum} from '../enum/WeekdayEnum'
import {DispatchTypeEnum} from '../enum/DispatchTypeEnum'
import {isEmptyString} from '../util/ValidationUtil'
import {CCourse} from './Course'
import {CManager} from './Manager'

export class CBusName {
  private constructor(readonly name: string) {}

  static create(json: any) {
    return new CBusName(json.name)
  }
}

export class CBusOperationDispatch {
  private constructor(
    readonly academyId: number,
    readonly code: string,
    readonly name: string,
    readonly courseCode: string,
  ) {}

  static create(json: any) {
    return new CBusOperationDispatch(
      json.academyId,
      json.code,
      json.name,
      json.courseCode,
    )
  }
}

export class CBusOperation {
  private constructor(
    readonly bus: CBusName,
    readonly dispatch: CBusOperationDispatch,
    readonly endTime: string,
    readonly id: number,
    readonly serviceDate: CDate,
    readonly startTime: string,
    readonly status: BusOperationStatusEnum,
    readonly tempSeat: number,
    readonly totalSeat: number,
    readonly usedSeat: number,
    readonly validSeat: number,
    readonly realSeat: number,
  ) {}

  static create(json: any): CBusOperation {
    return new CBusOperation(
      CBusName.create(json.bus),
      CBusOperationDispatch.create(json.dispatch),
      json.endTime,
      json.id,
      CDateTime.create(json.serviceDate).toDate(),
      json.startTime,
      BusOperationStatusEnum.parse(json.status),
      json.tempSeat,
      json.totalSeat,
      json.usedSeat,
      json.validSeat,
      json.realSeat,
    )
  }
}

export class CBusDetail {
  private constructor(
    readonly arriveTargetTime: string,
    readonly arriveTime: string,
    readonly departureTime: string,
    readonly position: string,
    readonly station: CStation,
  ) {}

  static create(json: any): CBusDetail {
    return new CBusDetail(
      json.arriveTargetTime,
      json.arriveTime,
      json.departureTime,
      json.position,
      CStation.create(json.station),
    )
  }
}

export class CBusNameWithID {
  private constructor(
    readonly id: number,
    readonly name: string,
  ) {}

  static create(json: any): CBusNameWithID {
    return new CBusNameWithID(json.id, json.name)
  }
}

export class CBusNameWithTime {
  private constructor(
    readonly name: string,
    readonly time: string,
  ) {}

  static create(json: any): CBusNameWithTime {
    return new CBusNameWithTime(json.name, json.time)
  }
}

export class CPhoneNumber {
  constructor(readonly value: string) {}

  static create(pn: any) {
    return new CPhoneNumber(pn)
  }

  toNumberWithHyphen(): string {
    if (isNil(this.value)) {
      return ''
    }

    return `${this.value.slice(0, 3)}-${this.value.slice(
      3,
      7,
    )}-${this.value.slice(7, 11)}`
  }
}

export class CBusOperationCarDispatch {
  private constructor(
    readonly bus: {
      driver: string
      driverPhone: CPhoneNumber
      name: string
    },
    readonly dispatch: {
      name: string
    },
    readonly manager: {
      name: string
      phone: CPhoneNumber
    },
    readonly status: BusOperationStatusEnum,
  ) {}

  static create(json: any): CBusOperationCarDispatch {
    return new CBusOperationCarDispatch(
      {
        driver: json.bus.driver,
        driverPhone: CPhoneNumber.create(json.bus.driverPhone),
        name: json.bus.name,
      },
      json.dispatch,
      {name: json.manager.name, phone: CPhoneNumber.create(json.manager.phone)},
      BusOperationStatusEnum.parse(json.status),
    )
  }
}

export class CScheduleStudentInfo {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly nickName: string,
    readonly parentPhone: Optional<CPhoneNumber>,
  ) {}

  static create(json: any) {
    return new CScheduleStudentInfo(
      json.id,
      json.name,
      json.nickName,
      CPhoneNumber.create(json.parentPhone),
    )
  }
}

export class CBusOperationSchedule {
  private constructor(
    readonly boardSwitch: BoardSwitchEnum,
    readonly isB2CUser: boolean,
    readonly pickUpStationId: number,
    readonly scheduleId: number,
    readonly status: BusOperationStatusEnum,
    readonly student: CScheduleStudentInfo,
    readonly takeOffStationId: number,
    readonly timeOffBoard: string,
    readonly timeOnBoard: string,
  ) {}

  static create(json: any) {
    return new CBusOperationSchedule(
      BoardSwitchEnum.parse(json.boardSwitch),
      json.isB2CUser,
      json.pickUpStation.id,
      json.scheduleId,
      BusOperationStatusEnum.parse(json.status),
      CScheduleStudentInfo.create(json.student),
      json.takeOffStation.id,
      json.timeOffBoard,
      json.timeOnBoard,
    )
  }
}
export class CBusDispatchInfo {
  private constructor(
    readonly code: string,
    readonly endStation: string,
    readonly name: string,
    readonly startStation: string,
  ) {}

  static create(json: any) {
    return new CBusDispatchInfo(
      json.code,
      json.endStation,
      json.name,
      json.startStation,
    )
  }
}

export class CBusDispatchManager {
  private constructor(readonly name: string) {}

  static create(json: any) {
    return new CBusDispatchManager(json.name)
  }
}
export class CBusDispatchSeat {
  private constructor(
    readonly emptySeat: string,
    readonly willBoard: string,
    readonly wontBoard: string,
  ) {}

  static create(json: any) {
    return new CBusDispatchSeat(json.emptySeat, json.willBoard, json.wontBoard)
  }
}
export class CBusDispatchSummary {
  private constructor(
    readonly dispatch: CBusDispatchInfo,
    readonly manager: CBusDispatchManager,
    readonly seat: CBusDispatchSeat,
  ) {}

  static create(json: any) {
    return new CBusDispatchSummary(
      CBusDispatchInfo.create(json.dispatch),
      CBusDispatchManager.create(json.manager),
      CBusDispatchSeat.create(json.seat),
    )
  }
}

export class CBusDriver {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly phone: string,
  ) {}

  static create(json: any): CBusDriver {
    return new CBusDriver(json.id, json.name, json.phone)
  }
}

export class CBus {
  private constructor(
    readonly drivers: CBusDriver[],
    readonly id: number,
    readonly name: string,
    readonly regNum: string,
    readonly totalSeat: number,
    readonly transportCompany: string,
  ) {}

  static create(json: any): CBus {
    return new CBus(
      json.drivers.map((d: any) => CBusDriver.create(d)),
      json.id,
      json.name,
      json.regNum,
      json.totalSeat,
      json.transportCompany,
    )
  }
}

export class CBusWithoutDriver {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly regNum: string,
    readonly totalSeat: number,
    readonly transportCompany: string,
  ) {}

  static create(json: any): CBusWithoutDriver {
    return new CBusWithoutDriver(
      json.id,
      json.name,
      json.regNum,
      json.totalSeat,
      json.transportCompany,
    )
  }
}

export class CBusVector {
  private constructor(
    readonly buses: CBus[],
    readonly paging: CPaging,
  ) {}

  static create(json: any): CBusVector {
    return new CBusVector(
      json.buses.map((b: any) => CBus.create(b)),
      CPaging.create(json.paging),
    )
  }
}

export class CBusBrief {
  private constructor(
    readonly bus: CBusWithoutDriver,
    readonly driver: CBusDriver[],
  ) {}

  static create(json: any): CBusBrief {
    return new CBusBrief(
      CBusWithoutDriver.create(json.bus),
      json.driver.map((d: any) => CBusDriver.create(d)),
    )
  }
}

export class CBusMemo {
  private constructor(readonly memo: string) {}

  static create(json: any): CBusMemo {
    return new CBusMemo(json.memo)
  }
}

export class CBusDispatchTableStatus {
  private constructor(
    readonly isAssigned: boolean,
    readonly isCanDelete: boolean,
    readonly isExistsDispatch: boolean,
    readonly isFullSeat: boolean,
    readonly isRegisterAllSchedule: boolean,
  ) {}

  static create(json: any) {
    return new CBusDispatchTableStatus(
      json.isAssigned,
      json.isCanDelete,
      json.isExistsDispatch,
      json.isFullSeat,
      json.isRegisterAllSchedule,
    )
  }
}

export class CBusDispatch {
  private constructor(
    readonly classTime: string,
    readonly dispatchCode: string,
    readonly courseName: string,
    readonly courseTime: string,
    readonly dispatchType: DispatchTypeEnum,
    readonly endDate: CDate,
    readonly startDate: CDate,
    readonly weekday: WeekdayEnum,
  ) {}

  static create(json: any) {
    return new CBusDispatch(
      json.classTime,
      json.code,
      json.courseName,
      json.courseTime,
      DispatchTypeEnum.parse(json.dispatchType),
      CDate.create(json.endDate),
      CDate.create(json.startDate),
      WeekdayEnum.parseValueToWeekday(json.weekday),
    )
  }
}

export class CBusDriversWithTotalSeat {
  private constructor(
    readonly driver: CBusDriver[],
    readonly totalSeat: number,
  ) {}

  static create(json: any) {
    return new CBusDriversWithTotalSeat(
      json.driver.map((d: any) => CBusDriver.create(d)),
      json.totalSeat,
    )
  }
}

export class CBusManager {
  private constructor(
    readonly name: string,
    readonly phone: string,
  ) {}

  static create(json: any) {
    return new CBusManager(json.name, json.phone)
  }

  isEmpty() {
    return isEmptyString(this.name) && isEmptyString(this.phone)
  }
}

export class CBusDispatchTable {
  private constructor(
    readonly name: string,
    readonly course: CCourse,
    readonly status: CBusDispatchTableStatus,
    readonly bus: CBusDriversWithTotalSeat,
    readonly dispatch: CBusDispatch,
    readonly manager: CManager,
  ) {}

  static create(json: any) {
    return new CBusDispatchTable(
      json.name,
      CCourse.create(json.course),
      CBusDispatchTableStatus.create(json.status),
      CBusDriversWithTotalSeat.create(json.bus),
      CBusDispatch.create(json.dispatch),
      CManager.create(json.manager),
    )
  }
}

export class CBusPeriod {
  private constructor(
    readonly classTimes: string[],
    readonly dispatchTables: CBusDispatchTable[][][],
    readonly weekdays: WeekdayEnum[],
  ) {}

  static create(json: any) {
    return new CBusPeriod(
      json.classTimes,
      json.dispatchTables.map((dt: any) =>
        dt.map((els: any) =>
          els.map((el: any) => CBusDispatchTable.create(el)),
        ),
      ),
      json.weekdays.map((wd: any) => WeekdayEnum.parseValueToWeekday(wd)),
    )
  }
}
