import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import {Optional} from '../../../../../../../type/Common'
import {CCourse} from '../../../../../../../model/Course'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import CourseSelect from '../../../add/operation/course/CourseSelect'
import {CManager} from '../../../../../../../model/Manager'
import CourseInfo from '../common/CourseInfo'
import ManagerInfo from '../common/ManagerInfo'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'

export type OperationInfoProps = {
  course: Optional<CCourse>
  manager: Optional<CManager>
  dispatchType: DispatchTypeEnum
  onChangeCourse(c: CCourse): void
  onChangeManager(m: CManager): void
  dispatchPeriod: CDispatchPeriodInfo
}

export default function OperationInfoNormal(props: OperationInfoProps) {
  const selectCourseModalRef = useSecureRef<ModalRef>(
    '[OperationInfoNormal.tsx] selectCourseModalRef',
  )

  const [selectedManager, setSelectedManager] = useState<Optional<CManager>>(
    props.manager,
  )

  const [selectedCourse, setSelectedCourse] = useState<Optional<CCourse>>(
    props.course,
  )

  const onClickCourse = useCallback(() => {
    selectCourseModalRef.current().show()
  }, [])

  const hideSelectStationModal = useCallback(() => {
    selectCourseModalRef.current().hide()
  }, [])

  const onSubmitCourse = useCallback(
    (c: CCourse) => {
      setSelectedCourse(c)
      props.onChangeCourse(c)
      hideSelectStationModal()
    },
    [selectedCourse, props.onChangeCourse, hideSelectStationModal],
  )

  const onChangeManager = useCallback(
    (sm: CManager) => {
      setSelectedManager(sm)
      props.onChangeManager(sm)
    },
    [setSelectedManager, props.onChangeManager],
  )

  return (
    <Container>
      <Modal zIndex={3} ref={selectCourseModalRef.ref}>
        <CourseSelect
          selectedCourse={selectedCourse}
          dispatchType={props.dispatchType}
          onSubmit={onSubmitCourse}
          hide={hideSelectStationModal}
          dispatchPeriod={props.dispatchPeriod}
        />
      </Modal>

      <HeaderContainer>
        <HeaderText>운행 정보</HeaderText>
      </HeaderContainer>
      <ContentContainer>
        <CourseInfo course={selectedCourse} onClickCourse={onClickCourse} />
        <ManagerInfo
          selectedManager={selectedManager}
          onChange={onChangeManager}
        />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const ContentContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`
