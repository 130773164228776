import {WeekdayEnum} from '../enum/WeekdayEnum'
import {CDate} from './Date'

export class CDispatchPeriodInfo {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly semesterID: number,
    readonly startDate: CDate,
    readonly endDate: CDate,
  ) {}

  static create(json: any): CDispatchPeriodInfo {
    return new CDispatchPeriodInfo(
      json.id,
      json.name,
      json.semesterId,
      CDate.create(json.startDate),
      CDate.create(json.endDate),
    )
  }
}

export class CDispatchPeriodOperation {
  private constructor(
    readonly inboundBeforeMinute: number,
    readonly outboundAfterMinute: number,
    readonly weekdays: WeekdayEnum[],
  ) {}

  static create(json: any): CDispatchPeriodOperation {
    return new CDispatchPeriodOperation(
      json.inboundBeforeMinute,
      json.outboundAfterMinute,
      json.weekdays.map((wd: string) => WeekdayEnum.parseValueToWeekday(wd)),
    )
  }
}

export class CDispatchPeriodButton {
  private constructor(
    readonly isDelete: boolean,
    readonly isModify: boolean,
  ) {}

  static create(json: any): CDispatchPeriodButton {
    return new CDispatchPeriodButton(json.isDelete, json.isModify)
  }
}

export class CDispatchPeriod {
  private constructor(
    readonly button: CDispatchPeriodButton,
    readonly dispatchPeriod: CDispatchPeriodInfo,
    readonly operation: CDispatchPeriodOperation,
  ) {}

  static create(json: any): CDispatchPeriod {
    return new CDispatchPeriod(
      CDispatchPeriodButton.create(json.button),
      CDispatchPeriodInfo.create(json.dispatchPeriod),
      CDispatchPeriodOperation.create(json.operation),
    )
  }
}

export class CDispatchPeriodExist {
  private constructor(
    readonly dispatchPeriodName: string,
    readonly startDate: string,
    readonly endDate: string,
  ) {}

  static create(json: any): CDispatchPeriodExist {
    return new CDispatchPeriodExist(
      json.dispatchPeriodName,
      json.startDate,
      json.endDate,
    )
  }
}

export class CDispatchPeriodBus {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly operatingWeekDays: WeekdayEnum[],
    readonly isFullSeat: boolean,
    readonly isExistsDispatch: boolean,
    readonly isBookMark: boolean,
    readonly plateNumber: string,
  ) {}

  static create(json: any): CDispatchPeriodBus {
    return new CDispatchPeriodBus(
      json.id,
      json.name,
      json.operatingWeekDays.map((wd: any) =>
        WeekdayEnum.parseValueToWeekday(wd),
      ),
      json.isFullSeat,
      json.isExistsDispatch,
      json.isBookMark,
      json.plateNumber,
    )
  }
}

export class CDispatchPeriodDriver {
  private constructor(
    readonly name: string,
    readonly phone: string,
  ) {}

  static create(json: any): CDispatchPeriodDriver {
    return new CDispatchPeriodDriver(json.name, json.phone)
  }
}

export class CDispatchPeriodBusVector {
  private constructor(
    readonly bus: CDispatchPeriodBus,
    readonly driver: CDispatchPeriodDriver,
  ) {}

  static create(json: any) {
    return new CDispatchPeriodBusVector(
      CDispatchPeriodBus.create(json.bus),
      CDispatchPeriodDriver.create(json.driver),
    )
  }
}
