import React, {Fragment, ReactElement, useCallback} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../style/CommonStyle'
import Header from '../../../../common/Header'
import SVGImage from '../../../../common/SVGImage'
import ClockIcon from '../../../../../asset/image/clock.svg'
import useSecureRef from '../../../../../hook/useSecureRef'
import {ModalContainerRef} from '../../../../modal/ModalContainer'
import HalfPopup from '../../../../common/HalfPopUp'

type Props = {
  busName: string
}

export default function DispatchSettingHeader(props: Props): ReactElement {
  const timeRef = useSecureRef<ModalContainerRef>(
    '[DispatchSettingHeader.tsx] timeRef',
  )

  const handleTimeButton = useCallback(() => {
    timeRef.current().show()
  }, [])

  return (
    <Fragment>
      <HeaderWrapper>
        <StyledHeader>
          <span>{props.busName}</span> 운행 설정
        </StyledHeader>
        {/* <AddTimeButton onClick={handleTimeButton}>
          <ClockImg source={ClockIcon} />
          <ButtonText>운행 시간 추가</ButtonText>
        </AddTimeButton> */}
      </HeaderWrapper>
      <InfoContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>
            해당 호차가 운행하는 요일만 선택해 주세요.
          </Text>
        </InfoTextContainer>
        <InfoTextContainer>
          <Dot>·</Dot>
          <Text style={{marginRight: 2}}>
            운행 수업 시간은 특정요일에만 운행해도 선택하여야 합니다.
          </Text>
        </InfoTextContainer>
      </InfoContainer>
      <HalfPopup ref={timeRef.ref} />
    </Fragment>
  )
}

const HeaderWrapper = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
`

const AddTimeButton = styled.div`
  ${flexRow};
  width: 13.5rem;
  align-items: center;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  background: #332a00;
  margin-right: 1.6rem;
  border-radius: 3.4rem;
  column-gap: 0.2rem;
  cursor: pointer;
`

const ClockImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const ButtonText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: -0.36px;
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
  > span {
    color: #2767ce;
    font-size: 1.6rem;
    font-weight: 800;
  }
`

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexColumn};
  row-gap: 0.3rem;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.p`
  color: #999999;
  font-size: 1.1rem;
`
