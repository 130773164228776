import styled from 'styled-components'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import {
  getBusPeriod,
  GetBusPeriodData,
} from '../../../../../service/buses/Buses'
import {
  CBusBrief,
  CBusDispatchTable,
  CBusPeriod,
} from '../../../../../model/Bus'
import {Optional} from '../../../../../type/Common'
import {isEmptyArray, isNil} from '../../../../../util/ValidationUtil'
import Modal, {ModalRef} from '../../../../common/Modal'
import DispatchAdd from '../add/DispatchAdd'
import useSecureRef from '../../../../../hook/useSecureRef'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {WeekdayEnum} from '../../../../../enum/WeekdayEnum'
import DispatchDetail from '../detail/DispatchDetail'
import Toast, {ToastRef} from '../../../../modal/Toast'
import PeriodTableHeader from './PeriodTableHeader'
import {DispatchOptionEnum} from '../../../../../enum/DispatchOptionEnum'
import PeriodTableBody from './PeriodTableBody'
import {
  deleteDispatches,
  updateStudents,
  UpdateStudentsTargetData,
} from '../../../../../service/dispatch/Dispatch'
import {alertError} from '../../../../../util/ErrorUtil'
import ConfirmModal, {ConfirmModalRef} from '../../../../modal/ConfirmModal'
import {PickerOption} from '../../../../input/Picker'
import {CDate} from '../../../../../model/Date'
import Loading, {LoadingRef} from '../../../../common/Loading'

type Props = {
  busBrief: CBusBrief
  selectedDispatchPeriod: Optional<CDispatchPeriodInfo>
  dispatchOptions: PickerOption<DispatchOptionEnum>[]
  dispatchPeriodOptions: PickerOption<CDispatchPeriodInfo>[]
  defaultDispatchIndex: number
  defaultDispatchPeriodIndex: number
  onChangeDispatch(d: DispatchOptionEnum): void
  onChangeDispatchPeriod(dp: CDispatchPeriodInfo): void
}

export type SelectedBusInfo = {
  isStart: boolean
  classTime: string
  weekday: WeekdayEnum
  dispatchTable: CBusDispatchTable
}

export default function PeriodTable(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const loadingRef = useRef<LoadingRef | null>(null)

  const toastRef = useSecureRef<ToastRef>(
    '[DispatchPeriodSetting.tsx] toastRef',
  )
  const addModalRef = useSecureRef<ModalRef>('[PeriodTable.tsx] addModalRef')
  const detailModalRef = useSecureRef<ModalRef>(
    '[PeriodTable.tsx] detailModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[PeriodTable.tsx] confirmModalRef',
  )
  const confirmUpdateStudentsModalRef = useSecureRef<ConfirmModalRef>(
    '[PeriodTable.tsx] confirmUpdateStudentsModalRef',
  )

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading === true) {
        loadingRef.current?.show()
      } else {
        loadingRef.current?.hide()
      }
    },
    [loadingRef],
  )

  const [busPeriod, setBusPeriod] = useState<Optional<CBusPeriod>>(undefined)
  const [selectedInfo, setSelectedInfo] =
    useState<Optional<SelectedBusInfo>>(null)
  const [selectedDispatchTables, setSelectedDispatchTables] = useState<
    CBusDispatchTable[]
  >([])
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [deleteDate, setDeleteDate] = useState<CDate>(CDate.now())

  const [isUpdatingStudents, setIsUpdatingStudents] = useState<boolean>(false)
  const [updateStudentsDate, setUpdateStudentsDate] = useState<CDate>(
    CDate.now(),
  )

  const fetchBusPeriod = useCallback(
    (data: GetBusPeriodData) => {
      console.log(data)
      handleLoading(true)
      getBusPeriod(data)
        .then(bp => {
          console.log(bp)
          setBusPeriod(bp)
          handleLoading(false)
        })
        .catch(error => {
          handleLoading(false)
          setBusPeriod(null)
          throw new Error(
            `getBusPeriod() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setBusPeriod],
  )

  const refresh = useCallback(() => {
    if (isNil(props.selectedDispatchPeriod)) {
      setBusPeriod(null)
      return
    }

    const data: GetBusPeriodData = {
      academyID: academyID,
      busID: props.busBrief.bus.id,
      dispatchPeriodID: props.selectedDispatchPeriod.id,
    }

    fetchBusPeriod(data)
  }, [academyID, props.selectedDispatchPeriod, props.busBrief])

  const onClickAdd = useCallback(
    (ct: string, wd: WeekdayEnum, isStart: boolean, dt: CBusDispatchTable) => {
      setSelectedInfo({
        classTime: ct,
        isStart: isStart,
        weekday: wd,
        dispatchTable: dt,
      })
      addModalRef.current().show()
    },
    [setSelectedInfo],
  )

  const hideAddModal = useCallback(() => {
    addModalRef.current().hide()
  }, [])

  const onClickInfo = useCallback(
    (ct: string, wd: WeekdayEnum, isStart: boolean, dt: CBusDispatchTable) => {
      setSelectedInfo({
        classTime: ct,
        isStart: isStart,
        weekday: wd,
        dispatchTable: dt,
      })

      detailModalRef.current().show()
    },
    [setSelectedInfo],
  )

  const hideDetailModal = useCallback(() => {
    detailModalRef.current().hide()
  }, [])

  const setToast = useCallback((tc: string) => {
    toastRef.current().setContent(tc)
    toastRef.current().show()
  }, [])

  const onClickCell = useCallback(
    (dt: CBusDispatchTable) => {
      setSelectedDispatchTables(prev => {
        if (
          prev.some(
            sdt => sdt.dispatch.dispatchCode === dt.dispatch.dispatchCode,
          )
        ) {
          return prev.filter(
            sdt => sdt.dispatch.dispatchCode !== dt.dispatch.dispatchCode,
          )
        }

        return [...prev, dt]
      })
    },
    [setSelectedDispatchTables],
  )

  const onClickDelete = useCallback(() => {
    setIsDeleting(true)
    setDeleteDate(
      props.selectedDispatchPeriod.startDate.isAfter(CDate.now())
        ? props.selectedDispatchPeriod.startDate
        : deleteDate,
    )
  }, [setIsDeleting])

  const onClickCancelDelete = useCallback(() => {
    setIsDeleting(false)
    setSelectedDispatchTables([])
  }, [setIsDeleting, setSelectedDispatchTables])

  const onClickUpdatingStudents = useCallback(() => {
    setIsUpdatingStudents(true)
    setUpdateStudentsDate(
      props.selectedDispatchPeriod.startDate.isAfter(CDate.now())
        ? props.selectedDispatchPeriod.startDate
        : updateStudentsDate,
    )
  }, [setIsUpdatingStudents, updateStudentsDate])

  const onClickCancelUpdatingStudents = useCallback(() => {
    setIsUpdatingStudents(false)
    setSelectedDispatchTables([])
  }, [setIsUpdatingStudents, setSelectedDispatchTables])

  const showConfirmDeleteModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])
  const showConfirmUpdateStudentsModal = useCallback(() => {
    confirmUpdateStudentsModalRef.current().show()
  }, [])

  const onSubmitDelete = useCallback(() => {
    handleLoading(true)

    const _deleteDate = deleteDate.toString().split('T')[0]

    const data = {
      academyID: academyID,
      dispatchTables: selectedDispatchTables,
      date: _deleteDate,
      busId: props.busBrief.bus.id,
      dispatchPeriodId: props.selectedDispatchPeriod.id,
    }

    if (isEmptyArray(selectedDispatchTables)) {
      return
    }

    deleteDispatches(data)
      .then(() => {
        setIsDeleting(false)
        setSelectedDispatchTables([])

        setBusPeriod(busPeriod)
        refresh()

        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        alertError(
          error,
          `deleteDispatches() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [academyID, selectedDispatchTables, refresh, deleteDate])

  const onUpdateStudents = useCallback(() => {
    if (isEmptyArray(selectedDispatchTables)) {
      return
    }

    // handleLoading(true)

    const _updateStudentsDate = updateStudentsDate.toString().split('T')[0]
    const dispatchList: UpdateStudentsTargetData[] = []

    selectedDispatchTables.forEach(selectedDispatch => {
      console.log(selectedDispatch)
      const updateStudentsData: UpdateStudentsTargetData = {
        dispatchCode: selectedDispatch.dispatch.dispatchCode,
        courseCode: selectedDispatch.course.code,
        managerId: selectedDispatch.manager.id,
      }
      dispatchList.push(updateStudentsData)
    })

    const data = {
      academyID: academyID,
      dispatchPeriodId: props.selectedDispatchPeriod.id,
      body: {
        updateDate: _updateStudentsDate,
        dispatchList: dispatchList,
      },
    }
    console.log(data)

    updateStudents(data)
      .then(() => {
        setIsUpdatingStudents(false)
        setSelectedDispatchTables([])

        setBusPeriod(busPeriod)
        refresh()

        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        alertError(
          error,
          `updateStudents() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [academyID, selectedDispatchTables, refresh, updateStudentsDate])

  const deletableDispatchTables = useMemo(() => {
    if (isNil(busPeriod)) {
      return []
    }

    return busPeriod.dispatchTables
      .flat()
      .flat()
      .filter(
        bp => bp.status.isCanDelete,
        // const deletable = bp.status.isCanDelete
        // {
        // const deletable =
        //   !isEmptyString(bp.dispatch.courseName) &&
        //   !isEmptyString(bp.dispatch.courseTime)

        // return deletable
        // }
      )
  }, [busPeriod])

  const onClickSelectAll = useCallback(() => {
    if (deletableDispatchTables.length === selectedDispatchTables.length) {
      setSelectedDispatchTables([])
      return
    }

    return setSelectedDispatchTables(
      deletableDispatchTables.filter(el => el.status.isCanDelete),
    )
  }, [busPeriod, deletableDispatchTables, selectedDispatchTables])

  useEffect(() => {
    if (isNil(props.selectedDispatchPeriod)) {
      setBusPeriod(null)
      return
    }

    const data: GetBusPeriodData = {
      academyID: academyID,
      busID: props.busBrief.bus.id,
      dispatchPeriodID: props.selectedDispatchPeriod.id,
    }

    fetchBusPeriod(data)
  }, [academyID, props.busBrief, props.selectedDispatchPeriod])

  return (
    <Container>
      <StyledLoading ref={loadingRef} />
      <Toast ref={toastRef.ref} />
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'배차 삭제'}
        content={
          '정말로 배차를 삭제 하시겠습니까? \n(삭제한 배차는 복원되지 않으며 \n등록되어있는 학생 승차권도 같이 삭제 됩니다.)'
        }
        onSubmit={onSubmitDelete}
      />
      <ConfirmModal
        ref={confirmUpdateStudentsModalRef.ref}
        key={updateStudentsDate.toString()}
        header={'학생 정보 최신화'}
        content={`최신화 적용일(${updateStudentsDate}) 기준으로\n 선택한 배차들의 학생 정보를 최신화 하시겠습니까?\n (다수의 배차를 선택한 경우 최신화의\n 시간이 오래 걸릴 수 있습니다.)`}
        onSubmit={onUpdateStudents}
      />
      <Modal ref={addModalRef.ref}>
        <DispatchAdd
          dispatchPeriod={props.selectedDispatchPeriod}
          busBrief={props.busBrief}
          selectedBusInfo={selectedInfo}
          onCancel={hideAddModal}
          setToast={setToast}
          hide={hideAddModal}
          onSubmit={refresh}
          handleLoading={handleLoading}
        />
      </Modal>
      <Modal ref={detailModalRef.ref}>
        <DispatchDetail
          dispatchPeriod={props.selectedDispatchPeriod}
          busBrief={props.busBrief}
          selectedBusInfo={selectedInfo}
          hide={hideDetailModal}
          setToast={setToast}
          onSubmitEdit={refresh}
        />
      </Modal>

      <PeriodTableHeader
        busBrief={props.busBrief}
        dispatchOptions={props.dispatchOptions}
        dispatchPeriodOptions={props.dispatchPeriodOptions}
        selectedDispatchPeriod={props.selectedDispatchPeriod}
        defaultDispatchIndex={props.defaultDispatchIndex}
        defaultDispatchPeriodIndex={props.defaultDispatchPeriodIndex}
        selectedDispatchTables={selectedDispatchTables}
        isSelectedAll={
          deletableDispatchTables.length === selectedDispatchTables.length &&
          deletableDispatchTables.length !== 0
        }
        isDeleting={isDeleting}
        isUpdatingStudents={isUpdatingStudents}
        onChangeDispatch={props.onChangeDispatch}
        onChangeDispatchPeriod={props.onChangeDispatchPeriod}
        onClickSelectAll={onClickSelectAll}
        onClickDelete={onClickDelete}
        onClickCancelDelete={onClickCancelDelete}
        onClickUpdatingStudents={onClickUpdatingStudents}
        onClickCancelUpdatingStudents={onClickCancelUpdatingStudents}
        onSubmitSetting={refresh}
        onSubmitDelete={showConfirmDeleteModal}
        showConfirmUpdateStudentsModal={showConfirmUpdateStudentsModal}
        setToast={setToast}
        deleteDate={deleteDate}
        setDeleteDate={setDeleteDate}
        updateStudentsDate={updateStudentsDate}
        setUpdateStudentsDate={setUpdateStudentsDate}
      />
      <PeriodTableBody
        busPeriod={busPeriod}
        selectedDispatchTables={selectedDispatchTables}
        isDeleting={isDeleting}
        isUpdatingStudents={isUpdatingStudents}
        onClickAdd={onClickAdd}
        onClickInfo={onClickInfo}
        onClickCell={onClickCell}
      />
    </Container>
  )
}

const Container = styled.div``

const StyledLoading = styled(Loading)`
  border-radius: 0;
`
