import React, {ForwardedRef, forwardRef, ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import {CBusBrief} from '../../../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../../table/PeriodTable'
import {useRecoilValue} from 'recoil'
import {academyState} from '../../../../../../recoil/Atom'
import {Optional} from '../../../../../../type/Common'
import {isEmptyString} from '../../../../../../util/ValidationUtil'

type DispatchDetailOperationProps = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  courseName: Optional<string>
  managerName: Optional<string>
  managerPhone: Optional<string>
}
export type DispatchDetailOperationRef = {}

function DispatchDetailOperationBase(
  props: DispatchDetailOperationProps,
  _: ForwardedRef<DispatchDetailOperationRef>,
): ReactElement {
  const academy = useRecoilValue(academyState)

  const periodInfo = useMemo(() => {
    return `${
      props.dispatchPeriod.name
    } | ${props.dispatchPeriod.startDate.toString()} ~ ${props.dispatchPeriod.endDate.toString()}`
  }, [props.dispatchPeriod])

  const driverInfo = useMemo(() => {
    return `${props.busBrief.driver[0].name}(${props.busBrief.driver[0].phone})`
  }, [props.busBrief])

  const managerInfo = useMemo(() => {
    if (isEmptyString(props.managerName)) {
      return '-'
    }

    if (isEmptyString(props.managerPhone)) {
      return props.managerName
    }

    return `${props.managerName}(${props.managerPhone})`
  }, [props.managerPhone, props.managerName])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>운행 정보</HeaderText>
        <HeaderInfoText>등록 학원 정보 : {academy.name}</HeaderInfoText>
      </HeaderContainer>

      <Wrapper>
        <InfoRowContainer>
          <InfoContainer>
            <Header>배차 기간</Header>
            <InfoText>{periodInfo}</InfoText>
          </InfoContainer>
          <InfoContainer>
            <Header>운행 기사</Header>
            <InfoText>{driverInfo}</InfoText>
          </InfoContainer>
        </InfoRowContainer>
        <InfoRowContainer>
          <InfoContainer>
            <Header>운행 노선</Header>
            <InfoText>{props.courseName}</InfoText>
          </InfoContainer>
          <InfoContainer>
            <Header>매니저</Header>
            <InfoText>{managerInfo}</InfoText>
          </InfoContainer>
        </InfoRowContainer>
      </Wrapper>
    </Container>
  )
}

const DispatchDetailOperation = forwardRef(DispatchDetailOperationBase)
export default DispatchDetailOperation

const Container = styled.div`
  ${flexColumn};
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
const HeaderInfoText = styled.div`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #3575d9;
`
const Wrapper = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
`

const InfoRowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const Header = styled.h5`
  width: 6.2rem;
  font-size: 1.2rem;
  color: #1a468b;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const InfoContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 1;
`

const InfoText = styled.div`
  color: #665300;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`
