import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import InputHeader from '../../../../../../input/InputHeader'
import Footer from '../../../../../../common/Footer'
import SVGImage from '../../../../../../common/SVGImage'
import {Optional} from '../../../../../../../type/Common'
import TextInput from '../../../../../../input/TextInput'
import {isNil} from '../../../../../../../util/ValidationUtil'
import ArrowDown from '../../../../../../../asset/image/arrowDown.svg'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import ClassTimePicker, {ClassTimePickerRef} from './ClassTimePicker'
import {SelectedBusInfo} from '../../../table/PeriodTable'
import {CDispatchCirculationTime} from '../../../../../../../model/Dispatch'

type Props = {
  onChange(s: CDispatchCirculationTime): void
  hide(): void
  selectedBusInfo: SelectedBusInfo
  selectedClassTime: CDispatchCirculationTime
}

export default function ClassTimeAdd(props: Props): ReactElement {
  const timePickerRef = useSecureRef<ClassTimePickerRef>(
    '[ClassTimeInput.tsx] timePickerRef',
  )

  const [selectedClassTime, setSelectedClassTime] = useState<
    Optional<CDispatchCirculationTime>
  >(props.selectedClassTime)

  const onSubmit = useCallback(() => {
    props.onChange(selectedClassTime)
    props.hide()
  }, [selectedClassTime])

  const onClickTime = useCallback(() => {
    timePickerRef.current().show()
  }, [])

  const onChangeTime = useCallback(
    (c: CDispatchCirculationTime) => {
      setSelectedClassTime(c)
    },
    [setSelectedClassTime],
  )

  const ClassTimeComponent = useMemo((): ReactNode => {
    if (isNil(selectedClassTime)) {
      return (
        <CourseText hasValue={!isNil(selectedClassTime)}>
          순환으로 배정할 수업 시간을 선택해주세요.
        </CourseText>
      )
    }

    return (
      <CourseText hasValue={!isNil(selectedClassTime)}>
        {selectedClassTime.dispatchTime}
      </CourseText>
    )
  }, [selectedClassTime])

  useEffect(() => {
    timePickerRef.current().show()
  }, [])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>수업 시간 추가 선택</HeaderText>
      </HeaderContainer>
      <ContentContainer>
        <OriginalTimeContainer>
          <OriginalTimeHeader>기존 시간</OriginalTimeHeader>
          <OriginalTimeInput
            readOnly={true}
            required={false}
            default={`${props.selectedBusInfo.dispatchTable.dispatch.classTime} | ${props.selectedBusInfo.dispatchTable.dispatch.dispatchType.exposure}`}
          />
        </OriginalTimeContainer>

        <ClassTimeContainer>
          <ClassTimeHeader>추가 시간 선택</ClassTimeHeader>
          <OperationRouteContainer>
            <InputHeaderContainer>
              <InputHeader header={'노선 검색 및 선택'} required={true} />
            </InputHeaderContainer>
            <OperationRouteInputContainer>
              <ClassTimePicker
                ref={timePickerRef.ref}
                selectedCirculationTIme={selectedClassTime}
                selectedBusInfo={props.selectedBusInfo}
                onChange={onChangeTime}
              />
              <OperationRouteInput
                hasValue={!isNil(selectedClassTime)}
                onClick={onClickTime}>
                {ClassTimeComponent}
                <ArrowDownImage source={ArrowDown}></ArrowDownImage>
              </OperationRouteInput>
            </OperationRouteInputContainer>
          </OperationRouteContainer>
        </ClassTimeContainer>
      </ContentContainer>

      <Footer
        submitText={'선택'}
        onCancel={props.hide}
        onSubmit={onSubmit}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const OriginalTimeContainer = styled.div`
  ${flexRow};
  align-items: center;
  row-gap: 0.4rem;
  height: 4.6rem;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  column-gap: 1.2rem;
`

const OriginalTimeHeader = styled.pre`
  color: #999999;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.39px;
`

const OriginalTimeInput = styled(TextInput)``

const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const ContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 1.6rem;
  background: #f5f5f5;
  padding: 1.6rem;
`
const OperationRouteContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
`
const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const OperationRouteInputContainer = styled.div`
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
  position: relative;
`

const OperationRouteInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
const ClassTimeContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const ClassTimeHeader = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  color: #332a00;
`

const CourseText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`
