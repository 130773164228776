import React, {useMemo} from 'react'
import {academyIDState, isSignedInState} from '../../recoil/Atom'
import AppLayout from '../layout/AppLayout'
import {Navigate} from 'react-router'
import {PathEnum} from '../../enum/PathEnum'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {isEmptyString} from '../../util/ValidationUtil'

const MESSAGE = 'message'

export default function PrivateRoute() {
  const isSignedIn = useRecoilState(isSignedInState)
  const setAcademyID = useSetRecoilState(academyIDState)

  window.addEventListener(MESSAGE, handleMessage)

  function handleMessage(e: any) {
    const academyID = e.data.academyId
    const message = {height: document.body.scrollHeight}

    window.top.postMessage(message, '*')

    if (!isEmptyString(academyID)) {
      setAcademyID(academyID)
    }
  }

  const Layer = useMemo(() => {
    if (isSignedIn) {
      return <AppLayout />
    }
    return <Navigate to={PathEnum.SIGN_IN.path} />
  }, [isSignedIn])

  return <div>{Layer}</div>
}
