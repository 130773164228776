import React from 'react'
import {CStudentWithCheck} from '../../../../../../../model/Dispatch'
import DisabledChecked from '../../../../../../../asset/image/checked_disabled.svg'
import Checked from '../../../../../../../asset/image/checked_brown.svg'
import styled from 'styled-components'
import {flexRow} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'

type Props = {
  isChecked: boolean
  readOnly: boolean
  student: CStudentWithCheck
}

export default function CourseStudent(props: Props) {
  return (
    <Container>
      <CheckBox readOnly={props.readOnly}>
        {props.isChecked ? (
          props.readOnly ? (
            <DisabledCheckImage source={DisabledChecked} />
          ) : (
            <CheckedImage source={Checked} />
          )
        ) : null}
      </CheckBox>
      <Name readOnly={props.readOnly} isChecked={props.isChecked}>
        {props.student.name}
      </Name>
      <Phone readOnly={props.readOnly} isChecked={props.isChecked}>
        ({props.student.nickName}
        {props.student.parentPhone.slice(9)})
      </Phone>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
  cursor: not-allowed;
`

const Name = styled.div<{isChecked: boolean; readOnly: boolean}>`
  color: ${props =>
    props.readOnly ? '#cccccc' : props.isChecked ? '#000000' : '#999999'};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const Phone = styled.div<{isChecked: boolean; readOnly: boolean}>`
  color: ${props =>
    props.readOnly ? '#cccccc' : props.isChecked ? '#000000' : '#999999'};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
const CheckBox = styled.div<{readOnly: boolean}>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: ${props =>
    props.readOnly ? '0.1rem solid #f5f5f5' : '0.1rem solid #d9d9d9'};
  background: ${props => (props.readOnly ? '#F5F5F5' : '#ffffff')};
  ${flexRow};
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
`

const CheckedImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
  cursor: not-allowed;
`
const DisabledCheckImage = styled(CheckedImage)``
