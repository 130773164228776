import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Modal, {ModalRef} from '../../../common/Modal'
import Toast, {ToastRef} from '../../../modal/Toast'
import useSecureRef from '../../../../hook/useSecureRef'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'
import DispatchBusSettingHeader from './DispatchBusSettingHeader'
import DispatchBusSettingList, {
  DispatchBusSettingListRef,
} from './DispatchBusSettingList'
import Footer from '../../../common/Footer'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import DispatchBusAdd from '../busAdd/DispatchBusAdd'
import {postDispatchPeriodBuses} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {alertError} from '../../../../util/ErrorUtil'
import {CDispatchPeriodBusVector} from '../../../../model/DispatchPeriod'
import Loading, {LoadingRef} from '../../../common/Loading'

type Props = {
  dispatchPeriodID: number
  dispatchPeriodBusVectors: CDispatchPeriodBusVector[]
  onSubmit(): void
  onCancel(): void
  showToast(t: string): void
}

export default function DispatchBusSetting(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const loadingRef = useRef<LoadingRef | null>(null)
  const toastRef = useSecureRef<ToastRef>('[DispatchBusSetting.tsx] toastRef')
  const busAddModalRef = useSecureRef<ModalRef>(
    '[DispatchBusSetting.tsx] addModalRef',
  )
  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusSetting.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusSetting.tsx] confirmModalRef',
  )
  const listRef = useSecureRef<DispatchBusSettingListRef>(
    '[DispatchBusSetting.tsx] listRef',
  )

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading === true) {
        loadingRef.current?.show()
      } else {
        loadingRef.current?.hide()
      }
    },
    [loadingRef],
  )

  const [isSufficient, setIsSufficient] = useState<boolean>(false)

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])

  const showAddBusModal = useCallback(() => {
    busAddModalRef.current().show()
  }, [])

  const submitSelectBuses = useCallback(() => {
    handleLoading(true)
    const sbs = listRef.current().getSelectedBuses()
    console.log(sbs)
    postDispatchPeriodBuses(academyID, props.dispatchPeriodID, sbs)
      .then(() => {
        props.onSubmit()
        props.showToast('호차 설정이 완료되었습니다.')
        handleLoading(false)
      })
      .catch(error => {
        handleLoading(false)
        alertError(
          error,
          `postDispatchPeriodBuses() failed. (aid: ${academyID}, dpid: ${props.dispatchPeriodID}, error: ${error})`,
        )
      })
  }, [academyID, props.dispatchPeriodID, props.onSubmit])

  const setToast = useCallback((content: string) => {
    toastRef.current().setContent(content)
    toastRef.current().show()
  }, [])

  const hideBusModal = useCallback(() => {
    busAddModalRef.current().hide()
  }, [])

  const onSubmit = useCallback(() => {
    listRef.current().fetchBuses()
    hideBusModal()
  }, [hideBusModal])

  const onChangeSufficiency = useCallback(
    (isSufficient: boolean) => {
      setIsSufficient(isSufficient)
    },
    [setIsSufficient],
  )

  return (
    <Container>
      <Loading ref={loadingRef}></Loading>
      <Toast ref={toastRef.ref} />
      <Modal zIndex={2} ref={busAddModalRef.ref}>
        <DispatchBusAdd
          onSubmit={onSubmit}
          onCancel={hideBusModal}
          setToast={setToast}
        />
      </Modal>
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'호차 선택 확인'}
        content={'현재 설정을 적용하시겠습니까?'}
        onSubmit={submitSelectBuses}
      />
      <ConfirmModal
        ref={cancelConfirmModalRef.ref}
        header={'호차 선택 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n호차 선택을 취소 하시겠습니까?'
        }
        onSubmit={props.onCancel}
      />
      <ContentContainer>
        <DispatchBusSettingHeader />
        <DispatchBusSettingList
          ref={listRef.ref}
          defaultBusVectors={props.dispatchPeriodBusVectors}
          showAddBusModal={showAddBusModal}
          onChange={onChangeSufficiency}
        />
        <Footer
          isSufficient={true}
          onCancel={showCancelConfirmModal}
          onSubmit={showConfirmModal}
          submitText={'적용'}
        />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  ${flexColumn};

  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
`
