export class SortEnum {
  static readonly DESC = new SortEnum('DESC', '내림차순')
  static readonly ASC = new SortEnum('ASC', '오름차순')

  static readonly ALL = [SortEnum.DESC, SortEnum.ASC]

  private constructor(
    readonly value: string,
    readonly exposure: string,
  ) {}

  static parse(value: string): SortEnum {
    switch (value) {
      case SortEnum.DESC.value:
        return SortEnum.DESC
      case SortEnum.ASC.value:
        return SortEnum.ASC
      default: {
        throw new Error(`invalid value. (value: ${value})`)
      }
    }
  }
}
