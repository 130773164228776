import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  color,
  flexColumn,
  flexRow,
  Icon,
} from '../../../style/CommonStyle'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import TrashIcon from '../../../asset/image/trash_icon.svg'
import {
  getDispatchesDetail,
  GetDispatchesDetailData,
} from '../../../service/dispatch/Dispatch'
import {
  CDispatchDetail,
  CDispatchWithSeatOccupancy,
} from '../../../model/Dispatch'
import {Optional} from '../../../type/Common'
import {isNil} from 'lodash'
import {isEmptyArray} from '../../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import TicketDetailStation from './TicketDetailStation'
import ModalContainer, {ModalContainerRef} from '../../modal/ModalContainer'
import useSecureRef from '../../../hook/useSecureRef'
import {WeekdayEnum} from '../../../enum/WeekdayEnum'
import DeleteTicket, {DeleteTicketRef} from '../deleteTicket/DeleteTicket'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import {DEFAULT_WEEKDAY} from '../ticketController/TicketController'
import RegisterTicket, {
  RegisterTicketRef,
} from '../registerTicket/RegisterTicket'
import {CDate} from '../../../model/Date'

type TicketDetailProps = {
  refreshListTicket(): void
}
export type TicketDetailRef = {
  setTicket(ticket: CDispatchWithSeatOccupancy): void
  setSelectedWeekday(wd: WeekdayEnum): void
}

function TicketDetailBase(
  props: TicketDetailProps,
  ref: ForwardedRef<TicketDetailRef>,
): ReactElement {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const registerTicketRef = useSecureRef<RegisterTicketRef>(
    '[TicketDetail.tsx] registerTicketRef',
  )
  const registerPopUpRef = useSecureRef<ModalContainerRef>(
    '[TicketDetail.tsx] registerPopUpRef',
  )
  const deletePopUpRef = useSecureRef<ModalContainerRef>(
    '[TicketDetail.tsx] deletePopUpRef',
  )
  const deleteTicketRef = useSecureRef<DeleteTicketRef>(
    '[TicketDetail.tsx] deleteTicketRef',
  )

  const [ticket, setTicket] =
    useState<Optional<CDispatchWithSeatOccupancy>>(null)
  const [dispatchDetail, setDispatchDetail] =
    useState<Optional<CDispatchDetail>>(null)
  const [dispatchCode, setDispatchCode] = useState<Optional<string>>(null)
  const [selectedWeekday, setSelectedWeekday] =
    useState<Optional<WeekdayEnum>>(DEFAULT_WEEKDAY)

  const fetchDispatchDetail = useCallback(
    (
      dispatchCode: string,
      selectedAcademyID: string,
      dateRange: {start: CDate; end: CDate},
    ) => {
      const data: GetDispatchesDetailData = {
        academyID: selectedAcademyID,
        dispatchCode: dispatchCode,
        dateRange: dateRange,
      }

      getDispatchesDetail(data)
        .then((td: CDispatchDetail) => {
          setDispatchDetail(td)
        })
        .catch(error => {
          throw new Error(
            `failed to get dispatches detail. (code: ${dispatchCode}, error: ${error})`,
          )
        })
    },
    [],
  )

  const driver = useMemo(() => {
    if (isNil(dispatchDetail)) {
      return null
    }

    if (isEmptyArray(dispatchDetail.drivers)) {
      return null
    }

    return dispatchDetail.drivers[0]
  }, [dispatchDetail])

  const showRegisterModal = useCallback(() => {
    registerPopUpRef.current().show()
  }, [])

  const hideRegisterModal = useCallback(() => {
    registerPopUpRef.current().hide()
  }, [])

  const showDeleteModal = useCallback(() => {
    deletePopUpRef.current().show()
  }, [])

  const reset = useCallback(() => {
    deleteTicketRef.current().resetList()
    registerTicketRef.current().reset()
  }, [])

  const hideDeleteModal = useCallback(() => {
    reset()
    deletePopUpRef.current().hide()
  }, [reset])

  const operationType = useMemo(() => {
    if (isNil(ticket)) {
      return null
    }

    const otStr = ticket.dispatch.name.slice(-2)
    return OperationTypeEnum.parseExposure(otStr)
  }, [ticket])

  useImperativeHandle(
    ref,
    () => ({
      setTicket(ticket: CDispatchWithSeatOccupancy): void {
        setTicket(ticket)
      },
      setSelectedWeekday(wd: WeekdayEnum): void {
        setSelectedWeekday(wd)
      },
    }),
    [],
  )

  useEffect(() => {
    if (isNil(ticket)) {
      return
    }

    setDispatchCode(ticket.dispatch.code)

    fetchDispatchDetail(ticket.dispatch.code, selectedAcademyID, {
      start: ticket.dispatch.startDate,
      end: ticket.dispatch.endDate,
    })
  }, [ticket])

  if (isNil(dispatchDetail) || isNil(selectedWeekday)) {
    return null
  }

  return (
    dispatchCode === ticket.dispatch.code && (
      <Container>
        <TicketDetailHeader>
          <BusName>{ticket.dispatch.name}</BusName>
          <InfoWrapper>
            <Info>
              • 동승자 : {dispatchDetail.manager.name}(
              {dispatchDetail.manager.phone.value})
            </Info>
            <Info>
              • 운전사 : {driver?.name}({driver?.phone.value})
            </Info>
          </InfoWrapper>
        </TicketDetailHeader>
        <ButtonWrapper>
          <RegisterButton onClick={showRegisterModal}>
            <Icon img={PlusIcon} width="1.8rem" height="1.8rem" />
            승차권 등록
          </RegisterButton>
          <DeleteButton onClick={showDeleteModal}>
            <Icon img={TrashIcon} width="1.8rem" height="1.8rem" />
            삭제
          </DeleteButton>
        </ButtonWrapper>
        <TicketDetailStation details={dispatchDetail.courses} />
        <ModalContainer onClickBackground={reset} ref={registerPopUpRef.ref}>
          <RegisterTicket
            ref={registerTicketRef.ref}
            validSeat={ticket.seat.validCount}
            hideRegisterModal={hideRegisterModal}
            startDate={ticket.dispatch.startDate}
            endDate={ticket.dispatch.endDate}
            weekday={selectedWeekday}
            onAddTicket={props.refreshListTicket}
            dispatchDetail={dispatchDetail}
            operationType={operationType}
            fetchDispatchDetail={(dateRange: {start: CDate; end: CDate}) => {
              fetchDispatchDetail(
                ticket.dispatch.code,
                selectedAcademyID,
                dateRange,
              )
            }}
          />
        </ModalContainer>
        <ModalContainer onClickBackground={reset} ref={deletePopUpRef.ref}>
          <DeleteTicket
            ref={deleteTicketRef.ref}
            startDate={ticket.dispatch.startDate}
            endDate={ticket.dispatch.endDate}
            dispatchDetail={dispatchDetail}
            onDeleteTicket={props.refreshListTicket}
            hideDeleteModal={hideDeleteModal}
            fetchDispatchDetail={(dateRange: {start: CDate; end: CDate}) =>
              fetchDispatchDetail(
                ticket.dispatch.code,
                selectedAcademyID,
                dateRange,
              )
            }
          />
        </ModalContainer>
      </Container>
    )
  )
}

const TicketDetail = forwardRef(TicketDetailBase)
export default TicketDetail

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 16rem);
  background-color: ${color.white};
  ${flexColumn};
  border-radius: 3rem;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`

const TicketDetailHeader = styled.div`
  background: #ffcd00;
  padding: 3rem;
  border-radius: 3rem 3rem 0 0;
  height: 13.8rem;
`

const BusName = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
`
const InfoWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.6rem;
`

const Info = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`

const ButtonWrapper = styled.div`
  ${flexRow};
  background: #f7f7f7;
  column-gap: 0.4rem;
  padding: 1.2rem 2rem;
`

const RegisterButton = styled.button`
  ${flexRow}
  ${alignCenter}
  width: 9.2rem;
  height: 2.6rem;
  padding: 0.3rem 0.8rem 0.3rem 0.6rem;
  margin-left: auto;
  column-gap: 0.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.4rem;
  border: 0.1rem solid #ffcd00;
  background: #ffcd00;
`

const DeleteButton = styled.button`
  ${flexRow}
  ${alignCenter}
  width: 5.7rem;
  height: 2.6rem;
  padding: 0.3rem 0.8rem 0.3rem 0.6rem;
  color: #ffcd00;
  column-gap: 0.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.4rem;
  border: 0.1rem solid #444;
  background: #444;
`
