import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../style/CommonStyle'
import {isNil} from '../../util/ValidationUtil'

type FooterProps = {
  isSufficient: boolean
  submitText: string
  onSubmit(): void
  onCancel?(): void
}
export type FooterRef = {}

function FooterBase(
  props: FooterProps,
  ref: ForwardedRef<FooterRef>,
): ReactElement {
  const CancelButtonComponent = useMemo(() => {
    if (isNil(props.onCancel)) {
      return null
    }

    return <CancelButton onClick={props.onCancel}>취소</CancelButton>
  }, [props.onCancel])

  const onSubmit = useCallback(() => {
    if (!props.isSufficient) {
      return
    }

    props.onSubmit()
  }, [props.isSufficient, props.onSubmit])

  return (
    <FooterContainer>
      <ButtonContainer>
        {CancelButtonComponent}
        <SubmitButton isSufficient={props.isSufficient} onClick={onSubmit}>
          {props.submitText}
        </SubmitButton>
      </ButtonContainer>
    </FooterContainer>
  )
}

const Footer = forwardRef(FooterBase)
export default Footer

const FooterContainer = styled.div`
  ${flexRow};
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border-bottom-left-radius: 1.6rem;
  border-top: 0.1rem solid #d9d9d9;
`

const ButtonContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.6rem;
`

const Button = styled.button`
  width: 7.2rem;
  height: 3.4rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 3.4rem;
  border: none;
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

const SubmitButton = styled(Button)<{isSufficient: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.isSufficient ? 1 : 0.2)};
  cursor: ${props => (props.isSufficient ? 'pointer' : 'not-allowed')};
`

const CancelButton = styled(Button)`
  background: #ebebeb;
  color: #000000;
`
