import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import {CDate} from '../../../model/Date'
import {CBusDetail, CBusOperationSchedule} from '../../../model/Bus'
import styled, {css} from 'styled-components'
import DepartureStationIcon from '../../../asset/image/departure_station.svg'
import ArrivalStationIcon from '../../../asset/image/arrival_station.svg'
import {color, flexRow} from '../../../style/CommonStyle'
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import OperationDetailEditOnBoard from './OperationDetailEditOnBoard'
import OperationDetailEditOffBoard from './OperationDetailEditOffBoard'

type OperationDetailEditProps = {
  date: CDate
  details: CBusDetail[]
  schedules: CBusOperationSchedule[]
}

export type OperationDetailEditRef = {
  getSchedules(): CBusOperationSchedule[]
}

function OperationDetailEditBase(
  props: OperationDetailEditProps,
  ref: ForwardedRef<OperationDetailEditRef>,
): ReactElement {
  const [schedules, setSchedules] = useState<CBusOperationSchedule[]>(
    props.schedules,
  )

  const onDragEnd = useCallback(
    (result: DropResult, _: ResponderProvided) => {
      const ps: {
        schedule: CBusOperationSchedule
        type: string
        stationIndex: number
      } = JSON.parse(result.draggableId)
      const station: {
        stationIndex: number
        stationID: number
      } = JSON.parse(result.destination.droppableId)

      const message = '승하차 정류장 위치가 맞지 않습니다.'
      const pickUpIndex = props.details.findIndex(
        d => d.station.id === ps.schedule.pickUpStationId,
      )
      const isSameOnBoard = schedules.some(
        s =>
          s.takeOffStationId === station.stationID &&
          s.student.id === ps.schedule.student.id,
      )
      const isSameOffBoard = schedules.some(
        s =>
          s.pickUpStationId === station.stationID &&
          s.student.id === ps.schedule.student.id,
      )

      if (ps.type === 'on') {
        if (isSameOnBoard) {
          alert(message)
          return
        }
      }

      if (ps.type === 'off') {
        if (isSameOffBoard) {
          alert(message)
          return
        }

        if (pickUpIndex >= station.stationIndex) {
          alert(message)
          return
        }
      }

      setSchedules(prev =>
        prev.map(s => {
          if (ps.schedule.student.id === s.student.id) {
            if (ps.type === 'on') {
              return {...s, pickUpStationId: station.stationID}
            }

            return {...s, takeOffStationId: station.stationID}
          }

          return s
        }),
      )
    },
    [schedules, props.details],
  )

  const getOnBoardingSchedules = useCallback(
    (stationID: number) => {
      return schedules.filter(s => s.pickUpStationId === stationID)
    },
    [schedules],
  )

  const getOffBoardingSchedules = useCallback(
    (stationID: number) => {
      return schedules.filter(s => s.takeOffStationId === stationID)
    },
    [schedules],
  )

  useImperativeHandle(
    ref,
    () => ({
      getSchedules(): CBusOperationSchedule[] {
        return schedules
      },
    }),
    [schedules],
  )

  return (
    <StationUl>
      <DragDropContext onDragEnd={onDragEnd}>
        {props.details.map((ds, idx) => {
          return (
            <StationLi key={ds.station.id}>
              <TimeWrapper>{ds.arriveTargetTime}</TimeWrapper>
              <Droppable
                key={ds.station.id}
                droppableId={JSON.stringify({
                  stationIndex: idx,
                  stationID: ds.station.id,
                })}>
                {(provided, _) => (
                  <>
                    <StationBox
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      order={idx}
                      courseCnt={props.details.length}
                      position={ds.position}>
                      <StationNameWrapper>{ds.station.name}</StationNameWrapper>
                      <OperationDetailEditOnBoard
                        stationIndex={idx}
                        schedules={getOnBoardingSchedules(ds.station.id)}
                      />
                      <OperationDetailEditOffBoard
                        stationIndex={idx}
                        schedules={getOffBoardingSchedules(ds.station.id)}
                      />
                    </StationBox>
                    {provided.placeholder}
                  </>
                )}
              </Droppable>
            </StationLi>
          )
        })}
      </DragDropContext>
    </StationUl>
  )
}

const OperationDetailEdit = forwardRef(OperationDetailEditBase)
export default OperationDetailEdit

const StationUl = styled.ul`
  padding: 6rem 3.2rem 3rem;
  overflow-y: scroll;
`

const StationLi = styled.li`
  ${flexRow};
  min-height: 11rem;
  position: relative;
`

const TimeWrapper = styled.div`
  min-width: 9.25rem;
  max-width: 9.25rem;
  font-size: 1.4rem;
`

const StationNameWrapper = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.6rem;

  :hover {
    font-weight: 700;
    cursor: pointer;
  }
`

type StationBoxProps = {
  order: number
  courseCnt: number
  position: string
}
const StationBox = styled.div<StationBoxProps>`
  padding: 0 0 3.5rem 3rem;
  flex-basis: 80%;
  min-width: 27rem;
  max-width: 27rem;
  ${({order, courseCnt}) =>
    order + 1 === courseCnt
      ? css`
          border-left: none;
        `
      : order !== courseCnt
        ? css`
            border-left: 0.2rem solid black;
            position: absolute;
            top: 0;
            right: 0;
          `
        : ''};
  position: relative;

  ::before {
    ${({order, courseCnt}) =>
      order === 0
        ? css`
            content: '${order + 1}';
            background-color: #ffcd00;
            border: 2px solid ${color.black};
            /* content: url(${DepartureStationIcon});
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 5rem; */
          `
        : order + 1 === courseCnt
          ? css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
              /* content: url(${ArrivalStationIcon});
              margin-left: 0.4rem;
              margin-top: 0.3rem; */
            `
          : css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
            `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 3rem;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    left: -1.2rem;
    top: -0.2rem;
    line-height: 1.1;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  #empty {
    margin-left: 5rem;
  }
`
