export class CAcademy {
  private constructor(
    readonly academyID: number,
    readonly dybID: string,
    readonly name: string,
    readonly lat: number,
    readonly lng: number,
  ) {}

  static create(json: any) {
    return new CAcademy(
      json.academyId,
      json.dybId,
      json.name,
      json.lat,
      json.lng,
    )
  }
}
