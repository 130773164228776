import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {color} from '../../style/CommonStyle'
import SearchIcon from '../../asset/image/yellow_search.svg'
import SVGImage from '../../component/common/SVGImage'
import {isNil} from '../../util/ValidationUtil'

type SearchInputProps = {
  onChangeText?(t: string): void
  placeHolder?: string
  border?: boolean
  width?: number
  onKeyDown?: any
  padding?: string
  searchIconRight?: string
  handleClick?(): void
}
export type SearchInputRef = {
  getValue(): string
  setValue(t: string): void
}

function SearchInputBase(
  props: SearchInputProps,
  ref: ForwardedRef<SearchInputRef>,
): ReactElement {
  const [value, setValue] = useState<string>('')

  const onChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.target.value
      setValue(t)

      if (!isNil(props.onChangeText)) {
        props.onChangeText(t)
      }
    },
    [props.onChangeText],
  )

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue(): string {
          return value
        },
        setValue(t: string): void {
          setValue(t)
        },
      }
    },
    [value],
  )

  return (
    <Container>
      <InputWrapper width={props.width}>
        <Input
          value={value}
          onChange={onChangeText}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeHolder}
          border={props.border}
          padding={props.padding}
        />
        <div onClick={props.handleClick}>
          <SearchImg source={SearchIcon} right={props.searchIconRight} />
        </div>
      </InputWrapper>
    </Container>
  )
}

const SearchInput = forwardRef(SearchInputBase)
export default SearchInput

const Container = styled.form`
  flex: 1;
`

type WrapperProps = {
  width?: number
}

const InputWrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${props => (isNil(props.width) ? '42.6' : props.width)}rem;
  height: 3.4rem;
`

type InputProps = {
  border?: boolean
  padding?: string
}

const Input = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  border: ${props => (isNil(props.border) ? '0.1rem solid #cecece' : 'none')};
  background: ${color.white};
  padding: ${props => (isNil(props.padding) ? '0.8rem 1.2rem' : props.padding)};
  font-size: 1.2rem;
`
type ImgProps = {
  right?: string
}
const SearchImg = styled(SVGImage)<ImgProps>`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 0.8rem;
  right: ${props => (isNil(props.right) ? '1.2rem' : props.right)};
`
