import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../../style/CommonStyle'
import {
  CDispatchCourseWithStudent,
  CDispatchDetailDate,
  CDispatchDetailWithCode,
} from '../../../../../model/Dispatch'
import {Optional} from '../../../../../type/Common'
import {CBusBrief} from '../../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../table/PeriodTable'
import Footer from '../../../../common/Footer'
import useSecureRef from '../../../../../hook/useSecureRef'
import ConfirmModal, {ConfirmModalRef} from '../../../../modal/ConfirmModal'
import {CCourse} from '../../../../../model/Course'
import {CManager} from '../../../../../model/Manager'
import {
  deleteDispatchesCoursesWithStudents,
  DeleteDispatchesCoursesWithStudentsData,
  editDispatches,
  EditDispatchesData,
  editDispatchesExtraBus,
  EditDispatchesExtraBusData,
} from '../../../../../service/dispatch/Dispatch'
import {alertError, throwError} from '../../../../../util/ErrorUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import SelectDate from './selectDates/SelectDate'
import BasicInfo from './basicInfo/BasicInfo'
import DispatchEditHeader from './DispatchEditHeader'
import OperationInfo from './operationInfo/full/OperationInfo'
import DispatchInfo from './dispatchInfo/DispatchInfo'
import {isEmptyArray, isNil} from '../../../../../util/ValidationUtil'
import {StudentLoadTypeEnum} from '../../../../../enum/StudentLoadTypeEnum'

type Props = {
  dispatchDetail: Optional<CDispatchDetailWithCode>
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
  onCancel(): void
  setToast(tc: string): void
  onSubmit(): void
}

export type BusInfo = {
  id: number
  name: string
}

export default function DispatchEdit(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchAdd.tsx] confirmModalRef',
  )
  const cancelModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchAdd.tsx] cancelModalRef',
  )

  const [course, setCourse] = useState<Optional<CCourse>>(
    props.selectedBusInfo.dispatchTable.course,
  )
  const [extraCourse, setExtraCourse] = useState<Optional<CCourse>>(null)
  const [extraBusInfo, setExtraBusInfo] = useState<Optional<BusInfo>>(null)
  const [manager, setManager] = useState<Optional<CManager>>(
    props.selectedBusInfo.dispatchTable.manager,
  )
  const [extraManager, setExtraManager] = useState<Optional<CManager>>(null)
  const [dispatchDates, setDispatchDates] = useState<CDispatchDetailDate[]>([])

  const showCancelModal = useCallback(() => {
    cancelModalRef.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])

  const onDeleteExtra = useCallback(() => {
    setExtraCourse(null)
    setExtraBusInfo(null)
  }, [setExtraCourse, setExtraBusInfo])

  const onChangeCourse = useCallback(
    (course: CCourse) => {
      setCourse(course)
    },
    [setCourse],
  )

  const onChangeExtraCourse = useCallback(
    (course: CCourse) => {
      setExtraCourse(course)
    },
    [setExtraCourse],
  )

  const onChangeExtraBus = useCallback(
    (bi: BusInfo) => {
      setExtraBusInfo(bi)
    },
    [setExtraBusInfo],
  )

  const onChangeManager = useCallback(
    (manager: CManager) => {
      setManager(manager)
    },
    [setManager],
  )

  const onChangeExtraManager = useCallback(
    (manager: CManager) => {
      setExtraManager(manager)
    },
    [setExtraManager],
  )

  const onChangeDates = useCallback(
    (dates: CDispatchDetailDate[]) => {
      setDispatchDates(dates)
    },
    [setDispatchDates],
  )

  const editNormal = useCallback(
    (courseCode: string) => {
      const data: EditDispatchesData = {
        academyID: academyID,
        courseCode: courseCode,
        managerID: manager?.id,
        dispatchDates: dispatchDates,
        dispatchCode: props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
      }

      editDispatches(data)
        .then(() => {
          props.setToast('배차 수정이 완료되었습니다.')
          props.onSubmit()
        })
        .catch(error => {
          alertError(
            error,
            `editDispatches() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [
      academyID,
      dispatchDates,
      manager,
      props.selectedBusInfo,
      props.onCancel,
      props.setToast,
      props.onSubmit,
    ],
  )

  const editExtra = useCallback(() => {
    const data: EditDispatchesExtraBusData = {
      busID: extraBusInfo.id,
      academyID: academyID,
      courseCode: extraCourse.code,
      managerID: extraManager?.id,
      dispatchDates: dispatchDates,
      dispatchCode: props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
    }

    editDispatchesExtraBus(data)
      .then(() => {
        editNormal(course.code)
      })
      .catch(error => {
        alertError(
          error,
          `editDispatchesExtraBus() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    academyID,
    dispatchDates,
    extraManager,
    extraCourse,
    editNormal,
    props.selectedBusInfo,
    props.onCancel,
    props.onSubmit,
  ])

  const onSubmit = useCallback(() => {
    if (isNil(extraCourse)) {
      editNormal(course.code)
      return
    }

    editExtra()
  }, [course, extraCourse, editNormal, editExtra])

  const confirmContent = useMemo(() => {
    return (
      <ConfirmContentContainer>
        <ConfirmContentText1>
          {
            '노선을 변경하시겠습니까? \n\n 노선 변경시 기존 노선을 이용하던 학생의\n승차권은 모두 삭제 됩니다.'
          }
        </ConfirmContentText1>
        <ConfirmContentText2>
          변경 전 노선 :{' '}
          {props.selectedBusInfo.dispatchTable.dispatch.courseName}
        </ConfirmContentText2>
      </ConfirmContentContainer>
    )
  }, [props.selectedBusInfo])

  const dispatchCourses = useMemo(() => {
    return props.dispatchDetail.dispatches.map(d => {
      return CDispatchCourseWithStudent.create({
        arrivalTime: d.station.time,
        sort: d.station.sort,
        stationId: d.station.id,
        stationName: d.station.name,
        students: d.students,
      })
    })
  }, [props.dispatchDetail])

  const isSufficient = useMemo(() => {
    if (isNil(extraCourse)) {
      return !isNil(course) && !isEmptyArray(dispatchDates)
    }

    return (
      !isNil(course) &&
      !isNil(extraCourse) &&
      !isNil(extraBusInfo) &&
      !isEmptyArray(dispatchDates)
    )
  }, [course, extraCourse, extraBusInfo, dispatchDates])

  const deleteRadisSavedTempDispatchDetails = useCallback(
    (data: DeleteDispatchesCoursesWithStudentsData) => {
      deleteDispatchesCoursesWithStudents(data)
        .then(cws => {
          // console.log(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  return (
    <Container>
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'배차 수정'}
        content={confirmContent}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        ref={cancelModalRef.ref}
        header={'배차 수정 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n배차 수정을 취소 하시겠습니까?'
        }
        onSubmit={() => {
          const courseData: DeleteDispatchesCoursesWithStudentsData = {
            dispatchCode:
              props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
            academyID: academyID,
            courseCode: course.code,
            type: StudentLoadTypeEnum.EDIT,
          }
          deleteRadisSavedTempDispatchDetails(courseData)
          if (extraCourse) {
            console.log(extraCourse)
            const extraCourseData: DeleteDispatchesCoursesWithStudentsData = {
              dispatchCode:
                props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
              academyID: academyID,
              courseCode: extraCourse.code,
              type: StudentLoadTypeEnum.EDIT,
            }
            deleteRadisSavedTempDispatchDetails(extraCourseData)
          }

          props.onCancel()
        }}
      />
      <DispatchEditHeader
        selectedBusInfo={props.selectedBusInfo}
        busName={props.busBrief.bus.name}
      />

      <EditContainer>
        <BasicInfo
          busBrief={props.busBrief}
          dispatchPeriod={props.dispatchPeriod}
          weekday={props.selectedBusInfo.weekday}
          classTime={props.selectedBusInfo.classTime}
          type={props.dispatchDetail.type}
        />
        <SelectDate
          type={props.dispatchDetail.type}
          dates={props.dispatchDetail.dates}
          count={props.dispatchDetail.count}
          onChangeDispatchDates={onChangeDates}
        />
        <OperationInfo
          type={props.dispatchDetail.type}
          dispatchPeriod={props.dispatchPeriod}
          dispatchCourses={dispatchCourses}
          dispatchType={
            props.selectedBusInfo.dispatchTable.dispatch.dispatchType
          }
          dispatchCode={
            props.selectedBusInfo.dispatchTable.dispatch.dispatchCode
          }
          course={course}
          extraCourse={extraCourse}
          busName={props.busBrief.bus.name}
          extraBusInfo={extraBusInfo}
          manager={manager}
          extraManager={extraManager}
          onChangeCourse={onChangeCourse}
          onChangeExtraCourse={onChangeExtraCourse}
          onChangeExtraBus={onChangeExtraBus}
          onChangeManager={onChangeManager}
          onChangeExtraManager={onChangeExtraManager}
          onDeleteExtra={onDeleteExtra}
        />

        <DispatchInfo
          dispatchCourses={dispatchCourses}
          dispatchCode={
            props.selectedBusInfo.dispatchTable.dispatch.dispatchCode
          }
          type={props.dispatchDetail.type}
          course={course}
          extraCourse={extraCourse}
          busName={props.busBrief.bus.name}
          extraBusName={extraBusInfo?.name}
          dispatchPeriod={props.dispatchPeriod}
          selectedBusInfo={props.selectedBusInfo}
        />
      </EditContainer>

      <Footer
        submitText={'수정'}
        onCancel={showCancelModal}
        onSubmit={showConfirmModal}
        isSufficient={isSufficient}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const EditContainer = styled.div`
  ${flexColumn};
  flex: 1;
  row-gap: 1.6rem;
  padding: 1.6rem;
  overflow: auto;
`

const ConfirmContentContainer = styled.div`
  ${flexColumn};
`

const ConfirmContentText1 = styled.div`
  color: #1a468b;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
const ConfirmContentText2 = styled.div`
  color: #ff7500;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
