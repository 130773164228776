import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {Optional} from '../../type/Common'
import {isEmptyArray, isNil} from '../../util/ValidationUtil'
import {color, flexColumn, justifyCenter} from '../../style/CommonStyle'
import Arrow from '../../asset/image/selectbox_downarrow.svg'
import SVGImage from '../common/SVGImage'

const INVALID_IDX = -1
const DEFAULT_HEIGHT = 3.5
const DEFAULT_FONT_SIZE = 1.4

export function toSelectOptions<T>(
  values: T[],
  getExposure: (v: T) => string,
  hasAll: boolean,
): SelectOption<T>[] {
  const a = values
  if (hasAll) {
    a.unshift(null)
  }

  return a.map((v, idx) => {
    if (isNil(v)) {
      return {
        idx: idx,
        exposure: '전체',
        value: v,
      }
    }

    return {
      idx: idx,
      exposure: getExposure(v),
      value: v,
    }
  })
}

export type SelectOption<T> = {
  idx: number
  exposure: string
  value: T
}

type SelectProps<T> = {
  defaultIdx?: number
  placeholder?: string
  hideArrow?: boolean
  options: SelectOption<T>[]
  width?: number
  height?: number
  boxShadow?: boolean
  border?: boolean
  fontSize?: number
  borderRadius?: number
  editable?: boolean
  background?: string

  onChange?(value: Optional<T>): void
} & Omit<PropsWithChildren<SelectHTMLAttributes<HTMLSelectElement>>, 'onChange'>
export type SelectRef<T> = {
  setIdx(idx: number): void
  setOptions(opts: SelectOption<T>[]): void
  getValue(): Optional<SelectOption<T>>
}

function SelectBase<T>(
  props: SelectProps<T>,
  ref: ForwardedRef<SelectRef<T>>,
): ReactElement {
  const [idx, setIdx] = useState<number>(
    isNil(props.defaultIdx) ? 0 : props.defaultIdx,
  )
  const [options, setOptions] = useState<SelectOption<T>[]>(props.options)
  const [visible, setVisible] = useState<boolean>(false)

  const getValue = useCallback((): Optional<SelectOption<T>> => {
    if (idx === INVALID_IDX) {
      const message = `invalid select value. (idx: ${idx})`
      alert(message)
      throw new Error(message)
    }

    return options[idx]
  }, [options, idx])

  const onClickOption = (e: any) => {
    e.stopPropagation()

    const value = e.target.value
    const idx = Number(value)
    setIdx(idx)
    setVisible(false)

    if (!isNil(props.onChange)) {
      const option = options[idx]
      props.onChange(option?.value)
    }
  }

  const OptionsComponent = useMemo(() => {
    if (!visible) {
      return null
    }

    if (isEmptyArray(options)) {
      return null
    }

    return (
      <SelectOptions visible={visible} height={props.height}>
        {options.map((option: SelectOption<T>) => {
          return (
            <Option
              onClick={onClickOption}
              value={option.idx}
              key={`${option.exposure}_${option.idx}`}
              fontSize={props.fontSize}
              visible={visible}
              height={props.height}>
              {option.exposure}
            </Option>
          )
        })}
      </SelectOptions>
    )
  }, [options, onClickOption, visible, props.height, props.fontSize])

  const ArrowComponent = useMemo(() => {
    if (!props.hideArrow) {
      return <ArrowImage source={Arrow} />
    }

    return null
  }, [props.hideArrow])

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  useImperativeHandle(
    ref,
    () => ({
      getValue: getValue,
      setIdx: setIdx,
      setOptions: (opts: SelectOption<T>[]): void => {
        setOptions(opts)
        setIdx(INVALID_IDX)
      },
    }),
    [getValue],
  )

  const labelText = useMemo(() => {
    const o = options[idx]
    if (isNil(o)) {
      if (!isNil(props.placeholder)) {
        return props.placeholder
      }

      return '선택해주세요.'
    }

    return o.exposure
  }, [options, idx])

  return (
    <SelectBox
      width={props.width}
      height={props.height}
      borderRadius={props.borderRadius}
      empty={isEmptyArray(options)}
      boxShadow={props.boxShadow}
      border={props.border}
      background={props.background}
      tabIndex={0}
      editable={props.editable}
      onBlur={props.editable ? () => {} : () => setVisible(false)}
      onClick={props.editable ? () => {} : () => setVisible(prev => !prev)}>
      <Label
        empty={isEmptyArray(options)}
        fontSize={props.fontSize}
        editable={props.editable}>
        {labelText}
      </Label>
      {OptionsComponent}
      {ArrowComponent}
    </SelectBox>
  )
}

const Select = forwardRef(SelectBase)
export default Select
const SelectBox = styled.div<{
  width: number
  empty: boolean
  height: number
  boxShadow: boolean
  borderRadius: number
  editable: boolean
  border: boolean
  background: string
}>`
  flex: 1;
  max-width: ${props => (props.width ? props.width : 20)}rem;
  min-width: ${props => (props.width ? props.width : 20)}rem;
  height: ${props => (isNil(props.height) ? DEFAULT_HEIGHT : props.height)}rem;
  ${flexColumn};
  ${justifyCenter};
  position: relative;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 0.8)}rem;
  border: ${props => (!props.border ? 'none' : '0.1rem solid #ebebeb')};
  background-color: ${props =>
    props.background
      ? props.background
      : props.editable
        ? '#eeeeee'
        : '#ffffff'};
  align-self: center;
  box-shadow: ${props =>
    isNil(props.boxShadow) ? '0 0.4rem 0.4rem rgba(0, 0, 0, 0.25)' : 'none'};
  cursor: ${props =>
    !props.empty || !props.editable ? 'pointer' : 'not-allowed'};

  :focus-within {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
    border: 0.1rem solid #fff1b3;
    background: #fffbe5;
  }
`
const Label = styled.label<{
  fontSize: number
  empty: boolean
  editable: boolean
}>`
  width: 80%;
  overflow: hidden;
  color: ${props => (props.editable ? '#666666' : '')};
  padding-left: 1rem;
  font-size: ${props =>
    isNil(props.fontSize) ? DEFAULT_FONT_SIZE : props.fontSize}rem;
  cursor: ${props => (!props.empty ? 'pointer' : 'not-allowed')};

  &:hover {
    cursor: ${props => (!props.editable ? 'pointer' : 'not-allowed')};
  }
`
const SelectOptions = styled.ul<{height: number; visible: boolean}>`
  position: absolute;
  list-style: none;
  top: ${props =>
    isNil(props.height) ? DEFAULT_HEIGHT + 0.5 : props.height + 0.5}rem;
  left: 0;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border-radius: 0.8rem;
  border: 0.1rem solid #fff1b3;
  background-color: ${color.white};
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.15);
  padding: 0.6rem;
  z-index: 100;
`
const Option = styled.li<{
  height: number
  fontSize: number
  visible: boolean
}>`
  ${flexColumn};
  ${justifyCenter};
  padding-left: 1rem;
  width: 100%;
  cursor: pointer;
  height: ${props => (isNil(props.height) ? DEFAULT_HEIGHT : props.height)}rem;
  border-radius: 0.8rem;
  transition: background-color 0.2s ease-in;
  font-size: ${props =>
    isNil(props.fontSize) ? DEFAULT_FONT_SIZE : props.fontSize}rem;

  &:hover {
    background-color: #fffbe5;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`
const ArrowImage = styled(SVGImage)`
  position: absolute;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
`
