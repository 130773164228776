import moment from 'moment-timezone'
import {WeekdayEnum} from '../enum/WeekdayEnum'
import {BaseDateUnitEnum} from '../enum/DateUnitEnum'

export class CDate {
  static readonly FORMAT_DEFAULT = 'YYYY-MM-DD'
  private value: moment.Moment

  private constructor(input: moment.Moment) {
    this.value = moment(input).startOf('day')
  }

  static create(input: moment.MomentInput) {
    const m = moment(input)
    if (!m.isValid()) {
      throw new Error(`invalid input. (input: ${input})`)
    }

    return new CDate(m)
  }

  static now() {
    return CDate.create(moment.now())
  }

  add(amount: number, unit: BaseDateUnitEnum): CDate {
    const m = this.value.add(amount, unit.value)

    return CDate.create(m)
  }

  isValid(): boolean {
    return this.value.isValid()
  }

  equals(input: CDate): boolean {
    return this.value.isSame(input.value)
  }

  isBefore(input: CDate): boolean {
    return this.value.isBefore(input.value)
  }

  isAfter(input: CDate): boolean {
    return this.value.isAfter(input.value)
  }

  toString(): string {
    return this.value.format(CDate.FORMAT_DEFAULT)
  }

  toDay(): WeekdayEnum {
    const id = this.value.day()
    return WeekdayEnum.parseIDtoWeekday(id)
  }

  toYear(): number {
    const ty = this.value.format('yyyy')
    return Number(ty)
  }

  toMonth(): number {
    const ty = this.value.format('MM')
    return Number(ty)
  }

  toDate(): number {
    const ty = this.value.format('DD')
    return Number(ty)
  }

  toDateRaw(): Date {
    return new Date(this.value.toISOString())
  }
}
