import React, {ReactElement, useCallback, useState} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import SVGImage from '../../../../../common/SVGImage'
import RadioOn from '../../../../../../asset/image/radio_on.svg'
import RadioOff from '../../../../../../asset/image/radio_off.svg'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import {CDispatchDetailWithCode} from '../../../../../../model/Dispatch'

type Props = {
  dispatchDetails: CDispatchDetailWithCode[]
  onChangeType(dd: CDispatchDetailWithCode): void
}

export default function DispatchDetailInfoMixedHeader(
  props: Props,
): ReactElement {
  const [selectedType, setSelectedType] = useState<OccupancyTypeEnum>(
    OccupancyTypeEnum.NORMAL,
  )

  const renderRadioIcon = useCallback(
    (type: OccupancyTypeEnum) => {
      if (selectedType === type) {
        return <RadioIcon source={RadioOn}></RadioIcon>
      }

      return <RadioIcon source={RadioOff}></RadioIcon>
    },
    [selectedType],
  )

  const onClickButton = useCallback(
    (dd: CDispatchDetailWithCode) => {
      setSelectedType(dd.type)
      props.onChangeType(dd)
    },
    [setSelectedType, props.onChangeType],
  )

  return (
    <Container>
      <HeaderContainer>
        {props.dispatchDetails.map((dd, idx) => {
          return (
            <RadioContainer onClick={() => onClickButton(dd)} key={idx}>
              {renderRadioIcon(dd.type)}
              <RadioItem selected={selectedType === dd.type}>
                {dd.type.exposure}({dd.count})
              </RadioItem>
            </RadioContainer>
          )
        })}
      </HeaderContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`
const HeaderContainer = styled.div`
  border-radius: 0.8rem 0.8rem 0 0;
  background: #332a00;
  width: 100%;
  height: 3.3rem;
  padding: 0.8rem 1.6rem;
  ${flexRow};
  justify-content: space-between;
  align-content: center;
`
const RadioContainer = styled.div`
  ${flexRow};
  align-content: center;
  column-gap: 0.4rem;
  cursor: pointer;
`

const RadioIcon = styled(SVGImage)``

const RadioItem = styled.pre<{selected: boolean}>`
  color: ${props => (props.selected ? '#ffd100' : '#ffffff')};
  font-size: 1.1rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 500 : 300)};
  line-height: 150%;
`
