import React, {ForwardedRef, forwardRef, ReactElement, ReactNode, useImperativeHandle} from 'react'
import styled from 'styled-components'
import Header from './Header'

type PageHeaderWithAcademiesProps = {
  children?: ReactNode
}
export type PageHeaderWithAcademiesRef = {}

function PageHeaderWithAcademiesBase(
  props: PageHeaderWithAcademiesProps,
  ref: ForwardedRef<PageHeaderWithAcademiesRef>,
): ReactElement {
  // const onChange = useCallback((value: CAcademy) => {
  //   setSelectedAcademy(value.id.toString())
  // }, [])
  //
  // const defaultAcademyIdx = useMemo(() => {
  //   if (isNil(selectedAcademyID)) {
  //     return null
  //   }
  //
  //   if (isEmptyArray(academyOptions)) {
  //     return null
  //   }
  //
  //   return academyOptions.find(ao => ao.value.id === parseInt(selectedAcademyID)).idx
  // }, [academyOptions, selectedAcademyID])

  useImperativeHandle(ref, () => ({}), [])

  return (
    <Container>
      <StyledHeader>{props.children}</StyledHeader>
      {/*<StyledSelect*/}
      {/*  readOnly={false}*/}
      {/*  required={false}*/}
      {/*  options={academyOptions}*/}
      {/*  defaultIdx={defaultAcademyIdx}*/}
      {/*  onChange={onChange}*/}
      {/*/>*/}
    </Container>
  )
}

const PageHeaderWithAcademies = forwardRef(PageHeaderWithAcademiesBase)
export default PageHeaderWithAcademies

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.8rem;
`

const StyledHeader = styled(Header)`
  display: flex;
  column-gap: 0.8rem;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
`
