import React, {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {isEmptyString, isNil, orElse} from '../../util/ValidationUtil'
import {flexRow} from '../../style/CommonStyle'
import SVGImage from '../common/SVGImage'
import Reset from '../../asset/image/close_yellow.svg'

type TextInputProps = {
  readOnly: boolean
  required: boolean
  default?: string
  containerStyle?: CSSProperties
  resetOff?: boolean
  onChange?(value: string): void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>
export type TextInputRef = {
  getValue(): string
  setValue(t: string): void
  reset(): void
}

function TextInputBase(
  props: TextInputProps,
  ref: ForwardedRef<TextInputRef>,
): ReactElement {
  const [value, setValue] = useState<string>(orElse(props.default, ''))
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value
      setValue(v)

      if (!isNil(props.onChange)) {
        props.onChange(v)
      }
    },
    [props.onChange, setValue],
  )

  const onFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const onReset = useCallback(() => {
    setValue('')
    if (!isNil(props.onChange)) {
      props.onChange('')
    }
  }, [setValue, props.onChange])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): string {
        return value
      },
      setValue(t: string): void {
        setValue(t)
      },
      reset(): void {
        setValue('')
      },
    }),
    [value, setValue],
  )
  return (
    <Container style={props.containerStyle}>
      <Input
        {...props}
        contentEditable={!props.readOnly}
        value={value}
        onChange={onChange}
        resetOff={props.resetOff}
        className={props.className}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
      />
      {!props.resetOff ? (
        <ResetButton
          onClick={onReset}
          visible={!isEmptyString(value) && !props.readOnly}>
          <ResetImage source={Reset} />
        </ResetButton>
      ) : null}
    </Container>
  )
}

const TextInput = forwardRef(TextInputBase)
export default TextInput

const Container = styled.div`
  ${flexRow};
  flex: 1;
  width: 100%;
  align-items: center;
  position: relative;
`

const ResetButton = styled.button<{visible: boolean}>`
  display: ${props => (props.visible ? 'block' : 'none')};
  border: none;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  right: 1rem;
`

const ResetImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const Input = styled.input<{
  value: string
  resetOff: boolean
  isFocused: boolean
}>`
  width: 100%;
  height: 3rem;
  border: ${props =>
    props.isFocused ? '0.1rem solid #FFD100' : '0.1rem solid #ebebeb'};
  padding-left: 0.8rem;
  padding-right: ${props => (props.resetOff ? 0 : 30)}px;
  border-radius: 0.6rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  background: ${props =>
    props.readOnly ? '#f5f5f5' : props.isFocused ? '#FFFBE5' : '#ffffff'};
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'default')};
  color: ${props => (props.readOnly ? '#999999' : '#000000')};
  box-shadow: ${props =>
    props.isFocused ? '4px 0px 16px 0px rgba(0, 0, 0, 0.15)' : null};

  &::placeholder {
    color: #cccccc;
  }
`
