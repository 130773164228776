import React, {Suspense} from 'react'
import {RecoilRoot} from 'recoil'
import RouteContainer from './RouteContainer'

function Routes() {
  return (
    <RecoilRoot>
      <Suspense>
        <RouteContainer />
      </Suspense>
    </RecoilRoot>
  )
}

export default Routes
