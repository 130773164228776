import React, {ReactElement, ReactNode, useMemo} from 'react'
import {isNil} from '../../../../util/ValidationUtil'
import InputHeader from '../../../input/InputHeader'
import ArrowDown from '../../../../asset/image/arrowDown.svg'
import styled from 'styled-components'
import {alignCenter, flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import {CSemester} from '../../../../model/Semester'
import {Optional} from '../../../../type/Common'

type Props = {
  selectedSemester: Optional<CSemester>
  showSemesterSelectModal(): void
}

export default function DispatchPeriodSemesterPicker(
  props: Props,
): ReactElement {
  const SemesterComponent = useMemo((): ReactNode => {
    if (isNil(props.selectedSemester)) {
      return (
        <SemesterInputText hasValue={!isNil(props.selectedSemester)}>
          배차 등록할 학기와 수업시간을 선택해주세요.
        </SemesterInputText>
      )
    }

    const t = props.selectedSemester.name
    const maxLength = 11

    const name = t.length > maxLength ? `${t.slice(0, maxLength)}...` : t

    return (
      <SemesterInputTextContainer>
        <SemesterInputText hasValue={!isNil(props.selectedSemester)}>
          {name} |{' '}
        </SemesterInputText>
        <SemesterRangeText>
          {props.selectedSemester.startDate.toString()} ~{' '}
          {props.selectedSemester.endDate.toString()}
        </SemesterRangeText>
      </SemesterInputTextContainer>
    )
  }, [props.selectedSemester])

  return (
    <SemesterInputContainer>
      <InputHeader required={true} header={'학기 및 수업시간 선택'} />
      <SemesterInput onClick={props.showSemesterSelectModal}>
        {SemesterComponent}
        <ArrowDownImage source={ArrowDown}></ArrowDownImage>
      </SemesterInput>
    </SemesterInputContainer>
  )
}

const SemesterInputContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
`

const SemesterInput = styled.div`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  width: 29.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
`
const SemesterInputTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`

const SemesterInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

const SemesterRangeText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: #2767ce;
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
