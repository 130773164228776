import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {CDispatchDetailDate} from '../../../../../../model/Dispatch'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../style/CommonStyle'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import SVGImage from '../../../../../common/SVGImage'
import DisabledChecked from '../../../../../../asset/image/checked_disabled.svg'
import Checked from '../../../../../../asset/image/checked_brown.svg'
import ChairIcon from '../../../../../../asset/image/chair.svg'
import {CDate} from '../../../../../../model/Date'

type SelectDateProps = {
  type: OccupancyTypeEnum
  count: number
  dates: CDispatchDetailDate[]
  onChangeDispatchDates(dds: CDispatchDetailDate[]): void
}

export default function SelectDate(props: SelectDateProps) {
  const [indices, setIndices] = useState<number[]>(
    props.dates.map((_, idx) => idx),
  )

  const headerColor = useMemo(() => {
    switch (props.type) {
      case OccupancyTypeEnum.ALL:
      case OccupancyTypeEnum.NORMAL:
        return '#404040'
      case OccupancyTypeEnum.FULL_SEAT:
        return '#AC2016'
      case OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE:
        return '#FF7500'

      default:
        throw new Error(`invalid dispatch detail type. (type:${props.type})`)
    }
  }, [props.type])

  const onClickButton = useCallback(
    (idx: number) => {
      if (indices.includes(idx)) {
        setIndices(prev => prev.filter(index => index !== idx))
        return
      }

      setIndices(prev => [...prev, idx])
    },
    [setIndices, indices],
  )

  const filteredDates = useMemo(() => {
    return props.dates.filter(d => !d.date.isBefore(CDate.now()))
  }, [props.dates])

  const onClickAll = useCallback(() => {
    if (indices.length === filteredDates.length) {
      setIndices([])
      return
    }

    const t: number[] = []
    props.dates.forEach((d, idx) => {
      if (d.date.isBefore(CDate.now())) {
        return
      }

      t.push(idx)
    })

    setIndices(t)
  }, [indices, props.dates, filteredDates, setIndices])

  useEffect(() => {
    const dds = props.dates.filter((_, idx) => indices.includes(idx))

    props.onChangeDispatchDates(dds)
  }, [props.onChangeDispatchDates, indices, props.dates])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>배차일</HeaderText>
        <HeaderInfoText color={headerColor}>
          {props.type.exposure}({props.count})
        </HeaderInfoText>
      </HeaderContainer>
      <SelectContainer>
        <SelectAllContainer>
          <CheckBoxButton readOnly={false} onClick={onClickAll}>
            <CheckBox readOnly={false}>
              {filteredDates.length === indices.length &&
              indices.length !== 0 ? (
                <CheckedImage source={Checked} />
              ) : null}
            </CheckBox>
            <SelectAllText selected={false}>
              전체 선택(
              {filteredDates.length})
            </SelectAllText>
          </CheckBoxButton>
        </SelectAllContainer>
        <OptionContainer
          isFullSeat={props.type === OccupancyTypeEnum.FULL_SEAT}>
          {props.dates
            .sort((a, b) => {
              if (b.date.isAfter(a.date)) {
                return -1
              }

              return 0
            })
            .map((d, idx) => {
              const ro = d.date.isBefore(CDate.now())
              const selected = indices.includes(idx)

              return (
                <CheckBoxButton
                  readOnly={ro}
                  key={idx}
                  onClick={() => {
                    if (ro) {
                      return
                    }

                    onClickButton(idx)
                  }}>
                  <CheckBox readOnly={ro}>
                    {selected ? (
                      ro ? (
                        <DisabledCheckImage source={DisabledChecked} />
                      ) : (
                        <CheckedImage source={Checked} />
                      )
                    ) : null}
                  </CheckBox>
                  <CheckBoxButtonText readOnly={ro} selected={selected}>
                    {d.date.toString().slice(5)}
                  </CheckBoxButtonText>
                  {props.type === OccupancyTypeEnum.FULL_SEAT ? (
                    <FullSeatInfoContainer>
                      <SeatIcon source={ChairIcon} />
                      <FullSeatText>
                        {d.seatCount} / {d.totalSeat}
                      </FullSeatText>
                    </FullSeatInfoContainer>
                  ) : null}
                </CheckBoxButton>
              )
            })}
        </OptionContainer>
      </SelectContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
const HeaderInfoText = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const SelectContainer = styled.div`
  ${flexColumn};
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 8px;
  border: 0.1rem solid #ebebeb;
`

const SelectAllContainer = styled.div`
  ${flexRow};
  flex: 1;
  padding: 0.4rem 0 0.8rem;
  height: 3rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const SelectAllText = styled.div<{selected: boolean}>`
  color: ${props => (props.selected ? '#665300' : '#999999')};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 500 : 500)};
  line-height: 150%;
`

const OptionContainer = styled.div<{isFullSeat: boolean}>`
  display: grid;
  grid-template-columns: ${props =>
    props.isFullSeat ? 'repeat(5, 1fr)' : 'repeat(9, 1fr)'};
  flex-wrap: wrap;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  padding-top: 0.8rem;
`

const CheckBoxButton = styled.div<{readOnly: boolean}>`
  ${flexRow};
  ${alignCenter};
  column-gap: 0.6rem;
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
`

const CheckBoxButtonText = styled.span<{selected: boolean; readOnly: boolean}>`
  color: ${props =>
    props.readOnly ? '#ebebeb' : props.selected ? '#665300' : '#999999'};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.36px;
`

const CheckBox = styled.div<{readOnly: boolean}>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: ${props =>
    props.readOnly ? '0.1rem solid #f5f5f5' : '0.1rem solid #d9d9d9'};
  background: ${props => (props.readOnly ? '#F5F5F5' : '#ffffff')};
  ${flexRow};
  align-items: center;
  justify-content: center;
`

const CheckedImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
`
const DisabledCheckImage = styled(CheckedImage)``

const FullSeatInfoContainer = styled.div`
  ${flexRow};
  align-items: center;
`
const SeatIcon = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const FullSeatText = styled.div`
  color: #ac2016;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 14px; /* 127.273% */
  letter-spacing: -0.33px;
`
