import React, {ReactElement, useState} from 'react'
import {CStudent} from '../../../model/Dispatch'
import styled from 'styled-components'
import ToolTipVertor from '../../../asset/image/tooltip_vector.svg'
import SVGImage from '../../common/SVGImage'
import {color} from '../../../style/CommonStyle'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import {StudentState} from './RegisterStation'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'

type Props = {
  stationID: number
  studentStates: StudentState[]
  operationState: {editable: boolean; type: OperationTypeEnum}
}

export default function FixedStudentStation(props: Props): ReactElement {
  const [isShowPeriod, setIsShowPeriod] = useState<boolean>(false)
  const [studentName, setStudentName] = useState<string>('')

  const handleMouseEnter = (student: CStudent) => {
    setIsShowPeriod(true)
    setStudentName(student.name)
  }

  const handleMouseLeave = () => {
    setIsShowPeriod(false)
    setStudentName('')
  }

  const handleStudentNotation = (student: CStudent): React.ReactNode => {
    return (
      <>
        <StudentName>
          {student.nickName === '' || student.nickName === '-'
            ? ''
            : `${student.nickName} / `}
          {`${student?.name} ${
            !isNil(student.parentPhone)
              ? ` (${student.parentPhone?.slice(-4)})`
              : ''
          }`}
        </StudentName>
        <ToolTipWrapper>
          {isShowPeriod && studentName === student.name && (
            <>
              <ToolTipBoxVector source={ToolTipVertor} />
              <ToolTip>{`${student.tickets[0]} 부터 승차합니다.`}</ToolTip>
            </>
          )}
        </ToolTipWrapper>
      </>
    )
  }

  if (isEmptyArray(props.studentStates)) {
    return null
  }

  return (
    <RegisteredBox>
      <BoardTitle>
        <Board type={props.operationState.type}>
          {props.operationState.type.boarding}
        </Board>
      </BoardTitle>
      <StudentWrapper>
        {props.studentStates.map((s: StudentState, idx: number) => {
          return (
            <div
              key={`${s.student.id}_${s.student.name}_fixed_${idx}`}
              onMouseEnter={() => handleMouseEnter(s.student)}
              onMouseLeave={handleMouseLeave}>
              {handleStudentNotation(s.student)}
            </div>
          )
        })}
      </StudentWrapper>
    </RegisteredBox>
  )
}

const RegisteredBox = styled.div`
  display: flex;
  background: ${color.grey1};
  min-width: 68rem;
  max-width: 68rem;
  padding: 1.6rem;
  row-gap: 1.2rem;
  align-items: center;
  position: relative;
  border-right: 0.1rem solid #e7e7e7;
  border-bottom: 0.1rem solid #e7e7e7;
  border-left: 0.1rem solid #e7e7e7;
  border-radius: 0.3rem;
`

const StudentWrapper = styled.div`
  width: 100%;
  max-width: 68rem;
  margin-left: 6.2rem;
  font-weight: 600;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.2rem;
`

const Board = styled.span<{type: OperationTypeEnum}>`
  display: inline-flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 3rem;
  background: ${props =>
    props.type === OperationTypeEnum.INBOUND ? color.green1 : color.blue1};
  color: ${color.white};
  padding: 0 0.9rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8rem;
`
const StudentName = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #585858;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
`

const ToolTipWrapper = styled.div`
  position: relative;
`

const ToolTipBoxVector = styled(SVGImage)`
  position: absolute;
  top: 0.3rem;
  left: 4rem;
  width: 0.8rem;
  height: 0.5rem;
`

const ToolTip = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  flex-direction: column; */
  position: absolute;
  width: 16.3rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
  background: #333;
  color: #dedede;
  top: 0.8rem;
  font-size: 1.1rem;
  left: -3.5rem;
  z-index: 999;
`

const PeriodSpan = styled.span`
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
`

const BoardTitle = styled.div`
  width: 3.6rem;
  height: 1.8rem;
  margin-right: 1rem;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 1.6rem;
  left: 1.6rem;
`
