import React from 'react'
import DateHeader from './DateHeader'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import {DispatchOptionEnum} from '../../../../enum/DispatchOptionEnum'
import {CDispatchPeriodInfo} from '../../../../model/DispatchPeriod'
import {CDate} from '../../../../model/Date'
import {Optional} from '../../../../type/Common'
import {isNil} from '../../../../util/ValidationUtil'
import Picker, {PickerOption} from '../../../input/Picker'

type Props = {
  date: CDate
  selectedDispatchPeriod: Optional<CDispatchPeriodInfo>
  defaultDispatchIndex: number
  defaultDispatchPeriodIndex: number
  dispatchOptions: PickerOption<DispatchOptionEnum>[]
  dispatchPeriodOptions: PickerOption<CDispatchPeriodInfo>[]
  onChangeDispatch(d: DispatchOptionEnum): void
  onChangeDispatchPeriod(dp: CDispatchPeriodInfo): void
  onChangeDate(d: CDate): void
}

export default function DateTableHeader(props: Props) {
  return (
    <HeaderContainer>
      <TitleWrapper>
        <Title>배차 정보</Title>
        <SelectContainer>
          <Picker
            options={props.dispatchOptions}
            defaultIdx={props.defaultDispatchIndex}
            onChange={props.onChangeDispatch}
            minWidth={84}
            height={28}
          />
          {/*<Picker*/}
          {/*  minWidth={120}*/}
          {/*  height={28}*/}
          {/*  options={props.dispatchPeriodOptions}*/}
          {/*  defaultIdx={props.defaultDispatchPeriodIndex}*/}
          {/*  onChange={props.onChangeDispatchPeriod}*/}
          {/*  readOnly={true}*/}
          {/*/>*/}
        </SelectContainer>
      </TitleWrapper>
      {!isNil(props.selectedDispatchPeriod) ? (
        <DateHeader
          date={props.date}
          startDate={props.selectedDispatchPeriod.startDate}
          endDate={props.selectedDispatchPeriod.endDate}
          onChangeDate={props.onChangeDate}
        />
      ) : null}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 4rem;
`

const TitleWrapper = styled.div`
  ${flexRow};
  align-items: center;
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-left: 0.8rem;
  margin-right: 1.2rem;
`

const SelectContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`
