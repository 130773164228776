import React, {
  ChangeEvent,
  ForwardedRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import StationSideBar from '../stationSideBar/StationSideBar'
import {Optional} from '../../../type/Common'
import {CAcademyData, CCourseDetail, CStation} from '../../../model/Station'
import {
  PutStationParams,
  getAcademyAddress,
  getAcademyStationList,
  getAddress,
  getCourseRoute,
  getStationInfo,
  postAcademyStation,
  putStation,
} from '../../../service/station/Station'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import useSecureRef from '../../../hook/useSecureRef'
import SVGImage from '../../common/SVGImage'
import {flexColumn, flexRow, Icon} from '../../../style/CommonStyle'
import {isEmptyArray, isEmptyString, isNil} from '../../../util/ValidationUtil'
import StationSideBarAddCourse, {
  StationSideBarAddCourseRef,
} from '../stationSideBar/StationSideBarAddCourse'

// image
import StationIcon from '../../../asset/image/station_gray.svg'
import SelectedStationIcon from '../../../asset/image/selected_station.svg'
import SelectedStationNoneShadow from '../../../asset/image/selected_station_none_shadow.svg'
import StudentIcon from '../../../asset/image/student.svg'
import RouteIcon from '../../../asset/image/route.svg'
import RightArrow from '../../../asset/image/right_arrow_yellow.svg'
import CloseIcon from '../../../asset/image/close_button.svg'
import CheckIcon from '../../../asset/image/checked_brown.svg'
import UncheckIcon from '../../../asset/image/unchecked.svg'
import TriangleIcon from '../../../asset/image/rectangle.svg'
import AcademyIcon from '../../../asset/image/academy.svg'
import DragIcon from '../../../asset/image/drag_station.svg'
import DeleteIcon from '../../../asset/image/close_yellow.svg'
import DepartureIcon from '../../../asset/image/departure_station_blue.svg'
import ArrivalIcon from '../../../asset/image/arrival_station_black.svg'
import AcademyIcon2 from '../../../asset/image/academy_icon.svg'

// kakao
import {CustomOverlayMap, Map, MapMarker, Polyline} from 'react-kakao-maps-sdk'

import {StationInfo} from '../stationSideBar/StationSideBarCourseSetting'
import DispatchHeaderPicker from '../../dispatch/dispatchPeriod/header/DispatchHeaderPicker'
import {getDispatchPeriodExtractYears} from '../../../service/dispatchPeriod/DispatchPeriod'
import {CDispatchPeriod} from '../../../model/DispatchPeriod'
import {CDate} from '../../../model/Date'

type MapProps = {
  showDeleteModal: () => void
  showModifyModal: (type: boolean) => void
  station: CStation
  setStation: React.Dispatch<React.SetStateAction<CStation>>
  refresh: boolean
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  stationDeleteRefresh: boolean
  setStationDeleteRefresh: React.Dispatch<React.SetStateAction<boolean>>
  toastStatus: (b: boolean) => void
  setDeleteStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setModifyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setCopyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  courseDelete: boolean
  setCourseDelete?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseName?: React.Dispatch<React.SetStateAction<Optional<string>>>
  setCourseDeleteToast?: React.Dispatch<React.SetStateAction<boolean>>
  cancelCloseModifyModal: boolean
  setCancelCloseModifyModal?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseModifyToast?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseAddToast?: React.Dispatch<React.SetStateAction<boolean>>
  setDisableCirculation?: React.Dispatch<React.SetStateAction<boolean>>
  resetCourseRouteData: boolean
  setResetCourseRouteData: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmModifyCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmAddCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  addCourseRef?: React.Ref<StationSideBarAddCourseRef>
  reloadDispatchPeriod?: boolean
  reloadOriginalCourse: boolean
  setReloadOriginalCourse: React.Dispatch<React.SetStateAction<boolean>>
}

export type Coordinate = {
  lat: number
  lng: number
}

type AddressProps = {
  address: Optional<string>
  roadAddress: Optional<string>
}

type CenterProps = Coordinate

type PositionProps = {
  swLatLng: Coordinate
  neLatLng: Coordinate
} & Coordinate

type DragStationProps = {
  id: number
  name: string
}
const KakaoMap: React.FC<MapProps> = props => {
  const {addCourseRef} = props

  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)

  const mapRef = useSecureRef('[KakaoMap.tsx] mapRef')
  const markerRef = useRef(null)

  const [selectedStation, setSelectedStation] =
    useState<Optional<CStation>>(null)
  const [stations, setStations] = useState<CStation[]>([])
  const [courseDetail, setCourseDetail] =
    useState<Optional<CCourseDetail>>(null)
  const [isShowStationName, setIsShowStationName] = useState<boolean>(false)
  const [isShowStationDetail, setIsShowStationDetail] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [markers, setMarkers] = useState<any>([]) // 지도 클릭시 좌표 값
  const [academyStationStatus, setAcademyStationStatus] =
    useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [address, setAddress] = useState<Optional<AddressProps>>(null)
  const [academyData, setAcademyData] = useState<Optional<CAcademyData>>(null)
  const [center, setCenter] = useState<Optional<CenterProps>>(null)
  const [courseCnt, setCourseCnt] = useState<Optional<number>>(null)
  const [studentCnt, setStudentCnt] = useState<Optional<number>>(null)
  const [dragStation, setDragStation] =
    useState<Optional<DragStationProps>>(null)
  const [isOpenAddCourse, setIsOpenAddCourse] =
    useState<Optional<boolean>>(null)
  const [isOpenModifyCourse, setIsOpenModifyCourse] =
    useState<Optional<boolean>>(null)
  const [addStationWarning, setAddStationWarning] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<Coordinate[]>([])
  const [mapSelectStation, setMapSelectStation] =
    useState<Optional<CStation>>(null)
  const [addStationStatus, setAddStationStatus] = useState<boolean>(false)
  const [stationModalStatus, setStationModalStatus] = useState<boolean>(false)
  const [containStations, setContainStations] = useState<StationInfo[]>([])
  const [courseRouteData, setCourseRouteData] = useState<any>(null)
  const [centerStation, setCenterStation] = useState<CStation[]>([])
  const [position, setPosition] = useState<Optional<Coordinate>>(null)
  const [currentSelect, setCurrentSelect] = useState<Optional<string>>(null)
  const [stationRefresh, setStationRefresh] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)
  const [operationType, setOperationType] = useState<Optional<string>>(null)
  const [routeStationName, setRouteStationName] = useState([])
  const [years, setYears] = useState<number[]>([])
  const [semesterID, setSemesterID] = useState<Optional<number>>(null)
  const [addNewStation, setAddNewStation] = useState<boolean>(false)
  const [originalPosition, setOriginalPosition] = useState<Coordinate>(null)
  const [isStationDragged, setIsStationDragged] = useState(false)
  const [dispatchPeriodId, setDispatchPeriodId] =
    useState<Optional<number>>(null)

  const [selectedYear, setSelectedYear] = useState<Optional<number>>(null)

  type Coordinate = {
    lat: number
    lng: number
  }

  const [markerPositions, setMarkerPositions] = useState<
    Record<number, Coordinate>
  >(
    stations.reduce(
      (acc, station) => {
        acc[station.id] = {lat: station.lat, lng: station.lng}
        return acc
      },
      {} as Record<number, Coordinate>,
    ),
  )

  const handleDragEnd = (stationId: number, map: any) => {
    const newPosition = {
      lat: map.getPosition().getLat(),
      lng: map.getPosition().getLng(),
    }

    if (
      newPosition.lat !== originalPosition.lat ||
      newPosition.lng !== originalPosition.lng
    ) {
      setIsStationDragged(true) // Mark as dragged only when position has changed
    }

    setMarkerPositions(prev => ({
      ...prev,
      [stationId]: newPosition,
    }))
  }

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setSelectedStation(null)
    setIsShowStationDetail(false)
    setIsStationDragged(false)
  }, [selectedAcademyID, dispatchPeriodId])

  const handleChangeKeyword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value)
    },
    [keyword],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const addCourseStatus = useCallback((v: boolean) => {
    setIsOpenAddCourse(v)
  }, [])

  const getAcademyStations = useCallback(() => {
    if (!isNil(academyData)) {
      getAcademyStationList(
        selectedAcademyID,
        academyData.lat.toString(),
        academyData.lng.toString(),
      )
        .then(sl => {
          setStations(sl)

          if (!mapRef.isSecure()) {
            return
          }
        })

        .catch(error => {
          throw new Error(
            `failed to get Academy Station List. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    }
  }, [academyData, setStations, selectedAcademyID])

  const getAcademyLatLng = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    getAcademyAddress(selectedAcademyID)
      .then(res => {
        setAcademyData(res)
      })
      .catch(error => {
        throw new Error(
          `failed to get Academy LatLng. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      })
  }, [selectedAcademyID])

  const setFixedCenterLatLng = useCallback(
    (center: {lat: number; lng: number}) => {
      setCenter({lat: center.lat, lng: center.lng})
    },
    [setCenter],
  )

  // 정류장 추가 버튼
  const addStation = useCallback(() => {
    const data = {
      address: address.address,
      lat: markers[0].position.lat,
      lng: markers[0].position.lng,
      name: keyword,
      roadAddress: address.roadAddress,
      type: academyStationStatus ? 'ACADEMY' : 'NORMAL',
    }
    postAcademyStation(selectedAcademyID, data)
      .then(_ => {
        setKeyword('')
        setMarkers([])
        setAddress(null)
        setAcademyStationStatus(false)
        getAcademyStations()
        props.toastStatus(true)
        setAddStationWarning(false)
        setAddNewStation(true)
      })
      .catch(error => {
        console.log(error)
        setError(error.response.data.message)
        setAddStationWarning(true)
      })
  }, [address, keyword, academyStationStatus, markers, setAddNewStation])

  // 주소, 장소 리스트에서 클릭 시 정류장 추가 모달 좌표 set
  const fixedMarker = useCallback((lat: number, lng: number) => {
    setMarkers([{position: {lat: lat, lng: lng}}])
  }, [])

  useEffect(() => {
    if (props.refresh) {
      getAcademyStations()
      props.setRefresh(false)
    }
  }, [props.refresh, props.setRefresh, selectedAcademyID])

  useEffect(() => {
    if (!isEmptyArray(markers)) {
      getAddress(markers[0].position.lat, markers[0].position.lng)
        .then(res => setAddress(res))
        .catch(error => {
          throw new Error(
            `failed to get LatLng. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
      setFixedCenterLatLng({
        lat: markers[0].position.lat,
        lng: markers[0].position.lng,
      })
    }

    if (!isNil(position)) {
      getAddress(position.lat, position.lng)
        .then(res => setAddress(res))
        .catch(error => {
          throw new Error(
            `failed to get LatLng. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
      setFixedCenterLatLng({
        lat: position.lat,
        lng: position.lng,
      })
    }
  }, [markers, position])

  useEffect(() => {
    if (stationRefresh) {
      getAcademyStations()
      setStationRefresh(false)
    }
  }, [stationRefresh, setStationRefresh])

  useEffect(() => {
    if (props.stationDeleteRefresh) {
      getAcademyStations()
      props.setStationDeleteRefresh(false)
      setIsShowStationDetail(false)
    }
  }, [
    props.stationDeleteRefresh,
    props.setStationDeleteRefresh,
    setIsShowStationDetail,
    selectedAcademyID,
  ])

  useEffect(() => {
    if (!isNil(academyData)) {
      setCenter({lat: academyData?.lat, lng: academyData?.lng})
    }
  }, [academyData])

  useEffect(() => {
    props.setStation(selectedStation)
  }, [selectedStation])

  useEffect(() => {
    getAcademyLatLng()
  }, [selectedAcademyID])

  useEffect(() => {
    getAcademyStations()
  }, [academyData])

  useEffect(() => {
    if (!isNil(selectedStation)) {
      const data = {
        academyID: selectedAcademyID,
        stationID: selectedStation?.id.toString(),
        dispatchPeriodId: dispatchPeriodId,
        year: selectedYear,
      }
      getStationInfo(data).then(res => {
        setCourseCnt(res.courses?.length)
        setStudentCnt(res.student?.length)
      })
    }
  }, [selectedStation])

  const onChangeCoordinate = useCallback(
    (cs: StationInfo[]) => {
      setRouteStationName(cs.map(s => ({name: s.name})))
      setCoordinates(cs)
      if (isEmptyArray(cs)) {
        return
      }

      if (
        !isEmptyArray(cs) &&
        !isNil(selectedAcademyID) &&
        currentSelect === 'ROUTE'
      ) {
        const wayPoints = cs.slice(1, cs.length - 1)
        const wayPointsData = wayPoints.map(el => {
          return {
            lat: el.lat,
            lng: el.lng,
            stationId: el.stationId,
            stationName: el.name,
          }
        })

        const data = {
          academyID: selectedAcademyID,
          stations: {
            origin: {
              lat: cs[0].lat,
              lng: cs[0].lng,
              stationId: cs[0].stationId,
              stationName: cs[0].name,
            },
            destination: {
              lat: cs[cs.length - 1].lat,
              lng: cs[cs.length - 1].lng,
              stationId: cs[cs.length - 1].stationId,
              stationName: cs[cs.length - 1].name,
            },
            waypoints: cs?.length < 3 ? [] : wayPointsData,
          },
        }
        getCourseRoute(data).then(res => setCourseRouteData(res))
      }
    },
    [courseRouteData, selectedAcademyID, currentSelect, setRouteStationName],
  )

  // 모든 좌표 합치기
  const combinedVertexes = courseRouteData?.sections?.flatMap(
    (s: any) => s?.roads?.flatMap((r: {vertexes: any}) => r.vertexes),
  )

  const withoutFirstAndLast = combinedVertexes?.slice(
    2,
    combinedVertexes?.length - 2,
  )

  const vertexesAsObjects = withoutFirstAndLast?.reduce(
    (acc: {lat: any; lng: any}[], curr: any, i: number) => {
      if (i % 2 === 0) {
        acc?.push({lng: curr, lat: withoutFirstAndLast[i + 1]})
      }
      return acc
    },
    [],
  )

  const patchStationInfo = useCallback(
    (data: PutStationParams) => {
      putStation(data)
        .then(res => {
          if (res === 'OK') {
            getAcademyStations()
            setDragStation(null)
            setSelectedStation(null)
            setStationRefresh(true)
          }
        })
        .catch(error => {
          throw new Error(
            `failed to put station info. (data: ${JSON.stringify(
              selectedAcademyID,
            )}, error: ${error})`,
          )
        })
    },
    [setDragStation],
  )

  const fetchDispatchPeriodYears = useCallback(() => {
    if (selectedAcademyID !== null) {
      getDispatchPeriodExtractYears(selectedAcademyID)
        .then(ys => {
          setYears(
            ys.length === 0 || ys === null || ys === undefined
              ? [CDate.now().toYear()]
              : ys,
          )
        })
        .catch(error => {
          throw new Error(
            `getDispatchPeriodExtractYears() failed. (error: ${error}, aid: ${selectedAcademyID}) `,
          )
        })
    }
  }, [setYears, selectedAcademyID])

  useEffect(() => {
    fetchDispatchPeriodYears()
  }, [selectedAcademyID])

  useEffect(() => {
    if (!isOpenAddCourse) {
      setCoordinates([])
      setCourseRouteData(null)
      setCenterStation([])
    }
    setSelectedStation(null)
  }, [isOpenAddCourse])

  useEffect(() => {
    if (currentSelect !== 'ROUTE') {
      setCoordinates([])
      setCourseRouteData(null)
    }
  }, [currentSelect, setCoordinates, setCourseRouteData])

  useEffect(() => {
    if (props.resetCourseRouteData) {
      setCourseRouteData(null)
      setRouteStationName([])
      props.setResetCourseRouteData(false)
    }
  }, [props.resetCourseRouteData, selectedAcademyID])

  useEffect(() => {
    if (isOpenAddCourse || isOpenModifyCourse) {
      setCenterStation(
        stations.filter(el => el.lat === center.lat && el.lng === center.lng),
      )
    }
  }, [stations, center])

  useEffect(() => {
    if (isOpen) {
      setMarkers([])
      setPosition(null)
    }
  }, [isOpen, setMarkers, setPosition])

  useEffect(() => {
    if (!isEmptyArray(markers)) {
      setSelectedStation(null)
      setIsOpen(false)
      setPosition(null)
    }
  }, [markers, setIsOpen, setSelectedStation, setPosition])

  useEffect(() => {
    if (!isNil(position)) {
      setIsOpen(false)
      setMarkers([])
    }
  }, [position, setIsOpen, setMarkers])

  useEffect(() => {
    if (
      (isOpenAddCourse && stationModalStatus) ||
      (isOpenModifyCourse && stationModalStatus)
    ) {
      setCenterStation([])
    }
  }, [isOpenAddCourse, isOpenModifyCourse, stationModalStatus])

  useEffect(() => {
    setError(null)
  }, [keyword])

  useEffect(() => {
    if (!isNil(selectedStation)) {
      setCenterStation([])
    }
  }, [selectedStation])

  useEffect(() => {
    if (!isEmptyArray(centerStation)) {
      setSelectedStation(null)
    }
  }, [centerStation])

  useEffect(() => {
    const routeArray = courseDetail?.course?.route.split(' - ') || []
    const routeObjects = routeArray.map(station => ({name: station}))
    setRouteStationName(routeObjects)
  }, [courseDetail, setRouteStationName])

  const routeNameStation = useMemo(() => {
    return routeStationName.reduce((acc, item) => {
      acc[item.name] = true
      return acc
    }, {})
  }, [routeStationName])

  // const onChangeDispatchPeriod = useCallback(
  //   (v: CDispatchPeriod) => {
  //     if (!isNil(v)) {
  //       setSemesterID(v.dispatchPeriod.semesterID)
  //     }
  //   },
  //   [setSemesterID],
  // )
  const onChangeDispatchPeriod = useCallback(
    (v: CDispatchPeriod) => {
      if (!isNil(v)) {
        setDispatchPeriodId(v.dispatchPeriod.id)
      }
    },
    [setDispatchPeriodId],
  )

  return (
    !isNil(academyData) &&
    !isNil(center) && (
      <Container style={{width: innerWidth - 60, height: innerHeight - 40}}>
        {currentSelect === 'ROUTE' && (
          <DispatchHeaderWrapper>
            <DispatchHeaderPicker
              years={years}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              onChangeDispatchPeriod={onChangeDispatchPeriod}
              reloadDispatchPeriod={props.reloadDispatchPeriod}
            />
          </DispatchHeaderWrapper>
        )}

        <MapIconInfo
          isShowStationName={isShowStationName}
          setIsShowStationName={setIsShowStationName}
        />
        <StationSideBar
          selectedStation={selectedStation}
          setSelectedStation={setSelectedStation}
          isShowStationDetail={isShowStationDetail}
          setIsShowStationDetail={setIsShowStationDetail}
          showDeleteModal={props.showDeleteModal}
          showModifyModal={props.showModifyModal}
          setStation={props.setStation}
          station={props.station}
          courseDetail={courseDetail}
          setCourseDetail={setCourseDetail}
          addCourseStatus={addCourseStatus}
          refresh={props.refresh}
          stationDeleteRefresh={props.stationDeleteRefresh}
          innerHeight={innerHeight}
          setFixedCenterLatLng={setFixedCenterLatLng}
          fixedMarker={fixedMarker}
          onChangeCoordinate={onChangeCoordinate}
          setDeleteStatus={props.setDeleteStatus}
          setModifyCourseStatus={props.setModifyCourseStatus}
          setCopyCourseStatus={props.setCopyCourseStatus}
          courseDelete={props.courseDelete}
          setCourseDelete={props.setCourseDelete}
          setCourseName={props.setCourseName}
          setCourseDeleteToast={props.setCourseDeleteToast}
          cancelCloseModifyModal={props.cancelCloseModifyModal}
          setCancelCloseModifyModal={props.setCancelCloseModifyModal}
          setCourseModifyToast={props.setCourseModifyToast}
          setCourseAddToast={props.setCourseAddToast}
          setDisableCirculation={props.setDisableCirculation}
          mapSelectStation={mapSelectStation}
          addStationStatus={addStationStatus}
          setAddStationStatus={setAddStationStatus}
          setContainStations={setContainStations}
          setIsOpenModifyCourse={setIsOpenModifyCourse}
          getCourseRoute={onChangeCoordinate}
          setCourseRouteData={setCourseRouteData}
          setCurrentSelect={setCurrentSelect}
          setOperationType={setOperationType}
          semesterID={semesterID}
          addNewStation={addNewStation}
          setAddNewStation={setAddNewStation}
          centerStation={centerStation}
          setConfirmModifyCourseModal={props.setConfirmModifyCourseModal}
          setConfirmAddCourseModal={props.setConfirmAddCourseModal}
          addCourseRef={addCourseRef}
          dispatchPeriodId={dispatchPeriodId}
          selectedYear={selectedYear}
          setResetCourseRouteData={props.setResetCourseRouteData}
          reloadOriginalCourse={props.reloadOriginalCourse}
          setReloadOriginalCourse={props.setReloadOriginalCourse}
        />
        <Map
          className="map"
          center={{
            lat: center?.lat,
            lng: center?.lng,
          }}
          isPanto={true}
          style={{
            width: '100%',
            height: '100%',
          }}
          level={4}
          onRightClick={(_target, mouseEvent) => {
            setMarkers([
              {
                position: {
                  lat: mouseEvent.latLng.getLat(),
                  lng: mouseEvent.latLng.getLng(),
                },
              },
            ])
          }}>
          {!isEmptyArray(markers) && (
            <MapMarker
              position={{
                lat: markers[0].position.lat,
                lng: markers[0].position.lng,
              }}
              image={{
                src: SelectedStationIcon,
                size: {width: 45, height: 45},
                options: {
                  offset: {
                    x: 23,
                    y: 36,
                  },
                },
              }}>
              {/* 정류장 추가 */}
              {!isEmptyArray(markers) && (
                <CustomOverlayMap
                  position={{
                    lng: markers[0]?.position?.lng,
                    lat: markers[0]?.position?.lat,
                  }}
                  yAnchor={1}
                  xAnchor={0.5}
                  clickable={true}
                  zIndex={9999999}>
                  {AddStationPopUp(
                    academyStationStatus,
                    setAcademyStationStatus,
                    handleChangeKeyword,
                    keyword,
                    handleKeyDown,
                    addStationWarning,
                    error,
                    address,
                    setMarkers,
                    addStation,
                    setAddStationWarning,
                    setKeyword,
                  )}
                </CustomOverlayMap>
              )}
            </MapMarker>
          )}

          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={7}
              strokeColor="#332a00"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}

          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={5}
              strokeColor="#FFD100"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}

          {/* 노선 추가 시 지점 표시 */}
          {/* {!isNil(courseRouteData) && isOpenAddCourse && (
            <CustomOverlayMap
              position={{
                lat: coordinates[0]?.lat,
                lng: coordinates[0]?.lng,
              }}>
              {coordinates.map((coord, i) => (
                <CustomOverlayMap key={`marker-${i}`} position={coord}>
                  <StationIdxWrapper>
                    <img
                      src={
                        i === 0
                          ? DepartureIcon
                          : i === coordinates?.length - 1
                            ? ArrivalIcon
                            : SelectedStationNoneShadow
                      }
                      style={{
                        width: '4rem',
                        height: '4rem',
                      }}
                      alt=""
                    />
                    <StationIdx>
                      {i === 0 ? '' : i === coordinates?.length - 1 ? '' : i}
                    </StationIdx>
                  </StationIdxWrapper>
                </CustomOverlayMap>
              ))}
            </CustomOverlayMap>
          )} */}

          {stations?.map(station => {
            const lat: number = station?.lat
            const lng: number = station?.lng

            const getStationIcon = (station: any, isDragging: boolean) => {
              if (routeStationName[0]?.name === station.name)
                return DepartureIcon
              if (
                routeStationName[routeStationName.length - 1]?.name ===
                station.name
              )
                return ArrivalIcon
              if (routeNameStation[station.name])
                return SelectedStationNoneShadow
              if (isDragging) return DragIcon
              if (station.type === 'ACADEMY') return AcademyIcon
              if (
                selectedStation?.name === station?.name ||
                centerStation[0]?.name === station?.name
              )
                return SelectedStationIcon
              return StationIcon
            }
            const position = markerPositions[station.id] || {
              lat: station.lat,
              lng: station.lng,
            } // Fallback to initial position if state is undefined

            return (
              <div key={station.id}>
                <MapMarker
                  position={position}
                  ref={markerRef}
                  draggable={routeNameStation[station.name] ? false : true}
                  onClick={() => {
                    setSelectedStation(station)
                    setIsOpen(true)
                    setStationModalStatus(true)
                    setFixedCenterLatLng({lat: lat, lng: lng})
                  }}
                  onDragStart={() => {
                    setDragStation({id: station.id, name: station.name})
                    setSelectedStation(station)
                    setOriginalPosition({lat: lat, lng: lng})
                  }}
                  onDragEnd={map => {
                    handleDragEnd(station.id, map)
                    setPosition({
                      lat: map.getPosition().getLat(),
                      lng: map.getPosition().getLng(),
                    })
                  }}
                  image={{
                    src: getStationIcon(
                      station,
                      dragStation?.id === station?.id,
                    ),
                    size: {width: 45, height: 45},
                    options: {
                      offset: {
                        x: 23,
                        y: 30,
                      },
                    },
                  }}
                />
                {routeStationName
                  .slice(1, routeStationName.length - 1)
                  .some(s => s.name === station.name) && (
                  <CustomOverlayMap
                    position={{lat: lat, lng: lng}}
                    yAnchor={-0.5}>
                    <RouteIdx
                      onClick={() => {
                        setMarkers([])
                        setSelectedStation(station)
                        setIsOpen(true)
                        setStationModalStatus(true)
                        setFixedCenterLatLng({lat: lat, lng: lng})
                      }}>
                      {routeStationName.findIndex(
                        item => item.name === station.name,
                      ) === 0
                        ? ''
                        : routeStationName.findIndex(
                            item => item.name === station.name,
                          )}
                    </RouteIdx>
                  </CustomOverlayMap>
                )}
                {/* 정류장명 보기 */}
                {isShowStationName && (
                  <CustomOverlayMap
                    position={{lat: lat, lng: lng}}
                    yAnchor={-0.5}>
                    <BusStationName>{station.name}</BusStationName>
                  </CustomOverlayMap>
                )}

                {/* 지도 핀 클릭된 정류장 정보 */}
                {!isEmptyArray(centerStation) && isNil(selectedStation) && (
                  <CustomOverlayMap
                    position={{
                      lat: centerStation[0]?.lat,
                      lng: centerStation[0]?.lng,
                    }}
                    yAnchor={1.3}>
                    <SelectStationInfo>
                      <CloseImage onClick={() => setCenterStation([])}>
                        <CloseImg source={CloseIcon} />
                      </CloseImage>
                      <StationName>{centerStation[0]?.name}</StationName>
                      <RoadAddress>{centerStation[0]?.roadAddress}</RoadAddress>
                      <Address>{centerStation[0]?.address}</Address>
                    </SelectStationInfo>
                  </CustomOverlayMap>
                )}

                {/* 정류장 위치 수정 */}
                {isStationDragged &&
                  selectedStation?.name === station?.name && (
                    <CustomOverlayMap
                      position={{
                        lat: position.lat,
                        lng: position.lng,
                      }}
                      yAnchor={1.15}>
                      {ChangeStationPosition(
                        position,
                        setPosition,
                        station,
                        studentCnt,
                        courseCnt,
                        selectedAcademyID,
                        patchStationInfo,
                        setStationRefresh,
                        address,
                        setMarkers,
                        originalPosition,
                        setDragStation,
                        () => setSelectedStation(null),
                        setMarkerPositions,
                        setIsStationDragged,
                      )}
                    </CustomOverlayMap>
                  )}

                {/* 정류장 자세히보기 */}
                {isOpen && selectedStation?.name === station?.name && (
                  <CustomOverlayMap
                    position={{lat: lat, lng: lng}}
                    yAnchor={1.2}
                    xAnchor={0.5}
                    clickable={true}>
                    {/* 노선 추가, 수정 시 정류장 선택할 때 */}
                    {(isOpenAddCourse &&
                      stationModalStatus &&
                      !isNil(operationType)) ||
                    (isOpenModifyCourse && stationModalStatus) ? (
                      AddCourseStationPopUp(
                        station,
                        isOpenAddCourse,
                        isOpenModifyCourse,
                        setMapSelectStation,
                        setAddStationStatus,
                        setStationModalStatus,
                        setSelectedStation,
                      )
                    ) : // 노선 추가 시 등하원 구분 없을 때 정류장 선택 시
                    (isOpenAddCourse &&
                        stationModalStatus &&
                        isNil(operationType)) ||
                      (isOpenModifyCourse &&
                        stationModalStatus &&
                        isNil(operationType)) ? (
                      <SelectStationInfo>
                        <StationName>{selectedStation.name}</StationName>
                        <RoadAddress>{selectedStation.roadAddress}</RoadAddress>
                        <Address>{selectedStation.address}</Address>
                      </SelectStationInfo>
                    ) : // 노선 추가, 수정 열리지 않았을 때 정류장 선택하면
                    (!isOpenAddCourse && stationModalStatus) ||
                      (!isOpenModifyCourse && stationModalStatus) ? (
                      StationPopUp(
                        station,
                        setIsShowStationDetail,
                        closeModal,
                        studentCnt,
                        courseCnt,
                        setStationModalStatus,
                      )
                    ) : (
                      ''
                    )}
                  </CustomOverlayMap>
                )}
              </div>
            )
          })}
        </Map>
      </Container>
    )
  )
}

export default KakaoMap

function AddCourseStationPopUp(
  station: CStation,
  isOpenAddCourse: Optional<boolean>,
  isOpenModifyCourse: Optional<boolean>,
  setMapSelectStation: React.Dispatch<React.SetStateAction<CStation>>,
  setAddStationStatus: React.Dispatch<React.SetStateAction<boolean>>,
  setStationModalStatus: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedStation: React.Dispatch<React.SetStateAction<any>>,
) {
  return (
    <SelectStation>
      <StationInfoWrapper
        isOpenAddCourse={isOpenAddCourse}
        isOpenModifyCourse={isOpenModifyCourse}>
        <StationBorder>
          <StationName>{station.name}</StationName>
          <StationRoadAddress>{station?.roadAddress}</StationRoadAddress>
          <StationAddress>{station?.address}</StationAddress>
        </StationBorder>
      </StationInfoWrapper>
      <AddStationNoti>
        선택하신 정류장을 노선에 추가하시겠습니까?
        <ButtonWrapper>
          <CancelButton
            onClick={() => {
              setStationModalStatus(false)
              setSelectedStation(null)
            }}>
            취소
          </CancelButton>
          <AddButton
            onClick={() => {
              setMapSelectStation(station)
              setAddStationStatus(true)
              setStationModalStatus(false)
              // setSelectedStation(null)
            }}>
            추가
          </AddButton>
        </ButtonWrapper>
      </AddStationNoti>
      <TriangleImg source={TriangleIcon} />
    </SelectStation>
  )
}

function ChangeStationPosition(
  position: Coordinate,
  setPosition: React.Dispatch<React.SetStateAction<Optional<Coordinate>>>,
  station: CStation,
  studentCnt: number,
  courseCnt: number,
  selectedAcademyID: string,
  patchStationInfo: (data: PutStationParams) => void,
  setStationRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  address?: AddressProps,
  setMarkers?: any,
  originalPosition?: Coordinate, // 원래 위치
  setDragStation?: React.Dispatch<React.SetStateAction<any>>, // setDragStation 함수
  setSelectedStation?: React.Dispatch<React.SetStateAction<any>>,
  setMarkerPositions?: React.Dispatch<React.SetStateAction<any>>,
  setIsStationDragged?: React.Dispatch<React.SetStateAction<any>>,
) {
  const data = {
    academyID: selectedAcademyID,
    stationID: String(station.id),
    address: address?.address,
    roadAddress: address?.roadAddress,
    name: station.name,
    type: station.type,
    lat: position.lat,
    lng: position.lng,
  }

  const resetStationState = () => {
    setIsStationDragged(false)

    if (originalPosition) {
      setPosition(originalPosition)

      // Update marker positions
      setMarkerPositions((prev: any) => ({
        ...prev,
        [station.id]: originalPosition, // Reset the marker to its original position
      }))
    } else {
      setPosition(null)
    }

    if (originalPosition) {
      setPosition(originalPosition)
    } else {
      setPosition(null)
    }
    setMarkers([])
    setStationRefresh(true)
    if (setDragStation) {
      setDragStation(null)
    }
    if (setSelectedStation) {
      setSelectedStation(null)
    }
  }

  return (
    <AddStationModal>
      <AddressContainer>
        <SelectAddressWrapper style={{height: '13.6rem'}}>
          <Title>정류장을 여기로 이동하시겠습니까?</Title>
          <StationInfoWrapper
            style={{
              width: '29.6rem',
              borderRadius: '0.8rem',
              padding: '1.2rem',
              height: '8.8rem',
            }}>
            <StationName>{station.name}</StationName>
            <StationRoadAddress>
              {isEmptyString(address?.roadAddress) ? '-' : address?.roadAddress}
            </StationRoadAddress>
            <StationAddress>{address?.address}</StationAddress>
          </StationInfoWrapper>
        </SelectAddressWrapper>
        <AddressBottom>
          <AcademyStatusWrapper>
            <StudentRoute>
              <IconImg source={StudentIcon} /> <span>{studentCnt}명</span>
              <IconImg source={RouteIcon} /> <span>{courseCnt}개</span>
            </StudentRoute>
          </AcademyStatusWrapper>
          <ButtonWrapper>
            <CancelButton
              onClick={() => {
                resetStationState()
              }}>
              취소
            </CancelButton>
            <AddButton
              onClick={() => {
                setMarkers([])
                patchStationInfo(data)
                if (setDragStation) {
                  setDragStation(null)
                }
                if (setSelectedStation) {
                  setSelectedStation(null)
                }
                setIsStationDragged(false)
              }}>
              이동
            </AddButton>
          </ButtonWrapper>
        </AddressBottom>
        <TriangleImg source={TriangleIcon} />
      </AddressContainer>
    </AddStationModal>
  )
}

function AddStationPopUp(
  academyStationStatus: boolean,
  setAcademyStationStatus: React.Dispatch<React.SetStateAction<boolean>>,
  handleChangeKeyword: any,
  keyword: string,
  handleKeyDown: any,
  addStationWarning: boolean,
  error: Optional<string>,
  address?: AddressProps,
  setMarkers?: any,
  addStation?: () => void,
  setAddStationWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  setKeyword?: React.Dispatch<React.SetStateAction<string>>,
) {
  const cancelAddStation = () => {
    setMarkers([])
    setAddStationWarning(false)
  }

  return (
    <AddStationModal>
      <AddressContainer>
        <SelectAddressWrapper addStationWarning={addStationWarning}>
          <Title>선택하신 위치를 정류장으로 추가하시겠습니까?</Title>
          <InputAddress addStationWarning={addStationWarning}>
            <InputWrapper addStationWarning={addStationWarning}>
              <Input
                placeholder="정류장명을 입력해 주세요."
                onKeyDown={handleKeyDown}
                onChange={handleChangeKeyword}
                value={keyword}
                addStationWarning={error?.includes('R101')}
              />
              {keyword?.length > 0 && (
                <DeleteButtonWrapper onClick={() => setKeyword('')}>
                  <DeleteButton source={DeleteIcon} />
                </DeleteButtonWrapper>
              )}
            </InputWrapper>
            {error?.includes('R101') && (
              <AddStationWarning>
                이미 등록되어 있는 정류장명입니다.
              </AddStationWarning>
            )}
            {error?.includes('could not execute statement') && (
              <AddStationWarning>
                정류장명의 길이 한계를 초과하였습니다.
              </AddStationWarning>
            )}
            <RoadAddress>
              {address?.roadAddress === '' ? '-' : address?.roadAddress}
            </RoadAddress>
            <Address>
              {address?.address === '' ? '-' : address?.address}
            </Address>
          </InputAddress>
        </SelectAddressWrapper>
        <AddressBottom>
          <AcademyStatusWrapper>
            <AcademyStatusButton
              onClick={() => setAcademyStationStatus(!academyStationStatus)}>
              <CheckImg
                source={academyStationStatus ? CheckIcon : UncheckIcon}
              />
            </AcademyStatusButton>
            학원 정류장으로 지정
          </AcademyStatusWrapper>
          <ButtonWrapper>
            <CancelButton onClick={cancelAddStation}>취소</CancelButton>
            <AddButton onClick={addStation}>추가</AddButton>
          </ButtonWrapper>
        </AddressBottom>
        <TriangleImg source={TriangleIcon} />
      </AddressContainer>
      <SelectPlaceImg source={SelectedStationNoneShadow} />
    </AddStationModal>
  )
}

function StationPopUp(
  station: CStation,
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void,
  studentCnt: number,
  courseCnt: number,
  setStationModalStatus: React.Dispatch<React.SetStateAction<boolean>>,
) {
  return (
    <SelectStation>
      <StationInfoWrapper>
        <ImgWrapper onClick={closeModal}>
          <CloseImg source={CloseIcon} />
        </ImgWrapper>
        <StationName>{station.name}</StationName>
        <StationRoadAddress>{station?.roadAddress}</StationRoadAddress>
        <StationAddress>{station?.address}</StationAddress>
      </StationInfoWrapper>
      <StudentRouteWrapper>
        {station.type === 'ACADEMY' ? (
          <StationType>
            <AcademyIconImg source={AcademyIcon2} /> <span>학원정류장</span>
          </StationType>
        ) : (
          <StudentRoute>
            <IconImg source={StudentIcon} /> <span>{studentCnt}명</span>
            <IconImg source={RouteIcon} /> <span>{courseCnt}개</span>
          </StudentRoute>
        )}
        <DetailButton
          onClick={() => {
            setIsShowStationDetail(true)
            // setStationModalStatus(false)
          }}>
          자세히 보기
          <RightArrowImg source={RightArrow} />
        </DetailButton>
      </StudentRouteWrapper>
      <TriangleImg source={TriangleIcon} />
    </SelectStation>
  )
}

type MapInfoProps = {
  isShowStationName: boolean
  setIsShowStationName: React.Dispatch<React.SetStateAction<boolean>>
}

function MapIconInfo(props: MapInfoProps) {
  return (
    <MapInfo className="map-info">
      <Ul>
        <Li
          onClick={() => props.setIsShowStationName(!props.isShowStationName)}>
          <CheckImg
            source={props.isShowStationName ? CheckIcon : UncheckIcon}
          />
          정류장명 보기
        </Li>
      </Ul>
    </MapInfo>
  )
}

const Container = styled.div`
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: 16px;
  .map {
    border-radius: 16px;
  }
`

const DispatchHeaderWrapper = styled.div`
  z-index: 1002;
  max-width: calc(100% - 56px);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 2rem;
  right: 15rem;
`

const BusStationName = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.2rem 0.4rem;
  text-align: center;
  border-radius: 0.4rem;
`

const SelectStation = styled.div`
  ${flexColumn};
  align-items: center;
  justify-content: center;
`

type StationInfoProps = {
  isOpenAddCourse?: boolean
  isOpenModifyCourse?: boolean
}

const StationInfoWrapper = styled.div<StationInfoProps>`
  width: ${props =>
    props.isOpenAddCourse || props.isOpenModifyCourse ? '32rem' : '24rem'};
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0.8rem 1.2rem;
  background: #fff;
  position: relative;
  border: 0.1rem solid #f5f5f5;
`

const StationBorder = styled.div`
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  padding: 0.8rem;
`

const StationName = styled.div`
  width: 19.5rem;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 2.2rem;
  margin-bottom: 0.6rem;
  white-space: normal;
`

const StationAddress = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #666;
`

const StationRoadAddress = styled(StationAddress)`
  color: #000;
  margin-bottom: 0.4rem;
`

const ImgWrapper = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 1.2rem;
`

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const StudentRouteWrapper = styled.div`
  ${flexRow};
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  background: #f5f5f5;
  border-radius: 0 0 0.8rem 0.8rem;
  width: 24rem;
`

const AddStationNoti = styled.div`
  ${flexRow}
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  background: #f5f5f5;
  border-radius: 0 0 0.8rem 0.8rem;
  width: 32rem;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 150%;
`

const AddStationButton = styled.div`
  border-radius: 1.6rem;
  background: #ffd100;
  padding: 0.2rem 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const StudentRoute = styled.div`
  ${flexRow};
  align-items: center;

  > span {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4rem;
    color: #666;
    margin-right: 0.8rem;
  }
`

const IconImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
`

const DetailButton = styled.button`
  border: none;
  border-radius: 1.6rem;
  background: #333;
  padding: 0.2rem 0.4rem 0.2rem 0.8rem;
  color: #fff1b3;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`

const RightArrowImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.2rem;
`

const MapInfo = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1002;
  max-width: calc(100% - 56px);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: default;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 10px;

      ${Icon} {
        margin-right: 4px;
      }
    }
  }
`

const Ul = styled.ul``
const Li = styled.li`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #665300;
`

const CheckImg = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  margin-left: 0.2rem;
`

const RouteIdx = styled.div`
  position: absolute;
  top: -4.8rem;
  left: -0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 500%;
  color: #665300;
  height: 4rem;
  width: 4rem;
`

const AddStationModal = styled.div`
  ${flexColumn};
  align-items: center;
  justify-content: center;
  cursor: default;
`

const TriangleImg = styled(SVGImage)`
  width: 1.4rem;
  height: 0.7rem;
`

const AddressContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

const SelectAddressWrapper = styled.div<InputProps>`
  width: 32rem;
  height: ${props => (props.addStationWarning ? '16.8rem' : '14.8rem')};
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  background: #fff;
  border: 0.1rem solid #f5f5f5;
`

const AddressBottom = styled.div`
  ${flexRow};
  justify-content: space-between;
  width: 32rem;
  height: 3.4rem;
  background: #f5f5f5;
  padding: 0.8rem 1.2rem;
  border-radius: 0 0 0.8rem 0.8rem;
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 2.2rem;
  color: #332a00;
  margin-bottom: 0.8rem;
`

const InputAddress = styled.div<InputProps>`
  padding: 1.2rem;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  height: ${props => (props.addStationWarning ? '12rem' : '10rem')};
`

type InputProps = {
  addStationWarning?: boolean
}
const InputWrapper = styled.div<InputProps>`
  ${flexRow};
  border-radius: 0.8rem;
  border: ${props =>
    props.addStationWarning ? '0.1rem solid #ED766E' : '0.1rem solid #ebebeb'};
  background: ${props => (props.addStationWarning ? '#FCE6E4' : '#f5f5f5')};
  height: 3rem;
  padding: 0.6rem 0.8rem;
  column-gap: 0.4rem;
  margin-bottom: 0.8rem;

  :focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ebebeb;
  }
`

const AddStationWarning = styled.div`
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  color: #e74d41;
  margin-bottom: 0.8rem;
  padding-left: 0;
`

const Input = styled.input<InputProps>`
  border: none;
  background: ${props => (props.addStationWarning ? '#FCE6E4' : '#f5f5f5')};
  color: ${props => (props.addStationWarning ? '#DA291C' : '#000')};
  height: 1.8rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;

  :focus-within {
    background: #fffbe5;
  }
  ::placeholder {
    font-weight: 300;
  }
`

const DeleteButtonWrapper = styled.div``

const DeleteButton = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const RoadAddress = styled.div`
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 400;
  color: #000;
`

const Address = styled(RoadAddress)`
  color: #666666;
`

const AcademyStatusWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  height: 1.8rem;
  border-radius: 0 0 0.8rem 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #999999;
`

const ButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const CancelButton = styled.button`
  border-radius: 1.6rem;
  border: none;
  background: #d9d9d9;
  padding: 0.2rem 0.8rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const AddButton = styled(CancelButton)`
  background: #ffd100;
`

const AcademyStatusButton = styled.div``

const SelectPlaceImg = styled(SVGImage)`
  width: 27px;
  height: 27px;
  background-color: transparent;
`

const SelectStationInfo = styled.div`
  position: relative;
  width: 32rem;
  height: 9rem;
  border-radius: 0.8rem;
  background: #fffbe5;
  padding: 1.2rem;
  border: 0.1rem solid #ebebeb;
  ${flexColumn};
`

const CloseImage = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.8rem;
  height: 1.8rem;
`

const StationType = styled.div`
  color: #6100ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 300;
`
const AcademyIconImg = styled(IconImg)`
  width: 14px;
  height: 18px;
  padding-bottom: 3px;
  margin-right: 3px;
`
