import React from 'react'
import {alignCenter, flexRow} from '../../../../../../../../style/CommonStyle'
import styled from 'styled-components'

type Props = {}

export default function ExtraBusHeader(_: Props) {
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>쪽차 추가</HeaderText>
      </HeaderContainer>
      <InfoContainer>
        <Dot>·</Dot>
        <InfoTextContainer>
          <Text style={{marginRight: 2}}>
            인승 정보는 해당 차량의 모든 좌석수를 의미하며
          </Text>
          <EmphasizedText>
            가용 좌석 정보는 기사 좌석 등을 제외한 학생 이용 가능 좌석수
          </EmphasizedText>
          <Text>를 의미합니다.</Text>
        </InfoTextContainer>
      </InfoContainer>
    </Container>
  )
}

const Container = styled.div``

const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexRow};
`

const InfoTextContainer = styled.div`
  ${flexRow};
  flex-wrap: wrap;
  ${alignCenter};
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.pre`
  color: #999999;
  font-size: 1.1rem;
`

const EmphasizedText = styled.pre`
  color: #ff7500;
  font-size: 1.1rem;
`
