import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled, {css} from 'styled-components'

import {color, flexColumn, flexRow} from '../../../style/CommonStyle'
import {CDispatchCourse, CStudent} from '../../../model/Dispatch'

// image
import DepartureStationIcon from '../../../asset/image/departure_station.svg'
import ArrivalStationIcon from '../../../asset/image/arrival_station.svg'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import FixedStudentStation from './FixedStudentStation'
import EditStudentStation, {EditStudentStationRef} from './EditStudentStation'
import useSecureRef from '../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import Noti from '../../../asset/image/red_noti.svg'
import SVGImage from '../../common/SVGImage'

export type StudentState = {student: CStudent; fixed: boolean}

type RegisterStationProps = {
  courses: CDispatchCourse[]
  operationType: OperationTypeEnum
  validSeat: number
  showOverBookingBar: boolean
  handleSubmitPressable(value: boolean): void
  handleOverBookingTable(): void
  // handleBoardingCount(value: number): void
  setBoardingCnt: Dispatch<SetStateAction<number>>
}
export type RegisterStationRef = {
  getStudents(): CStudent[]
  getDefaultTotalStudents(): void
}

function RegisterStationBase(
  props: RegisterStationProps,
  ref: ForwardedRef<RegisterStationRef>,
): ReactElement {
  const editRef = useSecureRef<EditStudentStationRef>(
    '[RegisterStation.tsx] editRef',
  )
  const [studentStates, setStudentStates] = useState<StudentState[]>([])

  const getDefaultTotalStudents = useCallback(() => {
    const temp: StudentState[] = []
    for (const course of props.courses) {
      for (const student of course.station.students) {
        if (temp.some(t => t.student.id === student.id)) {
          continue
        }

        temp.push({student: student, fixed: true})
      }
    }

    setStudentStates(temp)
  }, [props.courses])

  useEffect(() => {
    getDefaultTotalStudents()
  }, [props.courses])

  const getOperationState = useCallback(
    (idx: number) => {
      if (props.operationType === OperationTypeEnum.INBOUND) {
        if (idx === props.courses.length - 1) {
          return {editable: false, type: OperationTypeEnum.OUTBOUND}
        }

        return {editable: true, type: OperationTypeEnum.INBOUND}
      }

      if (idx === 0) {
        return {editable: false, type: OperationTypeEnum.INBOUND}
      }

      return {editable: true, type: OperationTypeEnum.OUTBOUND}
    },
    [props.operationType, props.courses],
  )

  const onSubmit = useCallback(
    (students: CStudent[]) => {
      setStudentStates(prev => [
        ...prev,
        ...students.map(s => {
          return {student: s, fixed: false}
        }),
      ])
    },
    [studentStates],
  )

  const onDelete = useCallback(
    (id: number) => {
      setStudentStates(prev => prev.filter(el => el.student.id !== id))
    },
    [studentStates],
  )

  const FilledStudentsData = useMemo(() => {
    const isChanged = !isEmptyArray(studentStates.filter(ss => !ss.fixed))
    const isInbound =
      isChanged &&
      !isNil(
        studentStates
          .filter(ss => !ss.fixed)
          .find(el => el.student.pickUpStationId),
      )
    props.setBoardingCnt(studentStates.filter(el => !el.fixed).length)
    if (!isChanged) return studentStates.map(ss => ss.student)

    if (isInbound) {
      return studentStates
        .map(ss => {
          if (!ss.fixed) {
            return {
              ...ss,
              student: {
                ...ss.student,
                takeOffStationId:
                  props.courses[props.courses.length - 1].station.id,
              },
            }
          }

          return ss
        })
        .map(s => s.student)
    }

    return studentStates
      .map(ss => {
        if (!ss.fixed) {
          return {
            ...ss,
            student: {
              ...ss.student,
              pickUpStationId: props.courses[0].station.id,
            },
          }
        }

        return ss
      })
      .map(s => s.student)
  }, [studentStates, props.courses])

  useImperativeHandle(
    ref,
    () => ({
      getStudents(): CStudent[] {
        return FilledStudentsData
      },
      getDefaultTotalStudents: getDefaultTotalStudents,
    }),
    [studentStates, getDefaultTotalStudents],
  )

  const onDragEnd = useCallback(
    (result: DropResult, _: ResponderProvided) => {
      const dt = result.destination.droppableId
      const student = JSON.parse(result.draggableId)
      const os = getOperationState(
        props.courses.findIndex(c => c.station.id === Number(dt)),
      )
      setStudentStates(prev => {
        return prev.map(el => {
          if (el.student.id === student.studentID) {
            if (os.type === OperationTypeEnum.INBOUND) {
              return {
                ...el,
                student: {
                  ...el.student,
                  pickUpStationId: Number(dt),
                },
              }
            }

            return {
              ...el,
              student: {
                ...el.student,
                takeOffStationId: Number(dt),
              },
            }
          }

          return el
        })
      })
    },
    [getOperationState, props.courses, studentStates],
  )

  useEffect(() => {
    if (studentStates.filter(ss => !ss.fixed).length > 0) {
      props.handleSubmitPressable(true)
      return
    }

    props.handleSubmitPressable(false)
  }, [studentStates])

  return (
    <RegisterStudentWrapper>
      <TextWrapper>
        <InfoText>각 정류장에 승차할 학생을 등록해 주세요.</InfoText>
        <Text>
          *<BoldText>선택하신 승차 기간에 등록되어 있는 학생</BoldText>들이{' '}
          <BoldText>모두 노출</BoldText>됩니다.
          <br />
          *승차 인원 : <BoldText>{props.validSeat}명</BoldText>
        </Text>
      </TextWrapper>
      {props.showOverBookingBar && (
        <OverBookingNoti>
          <NotiWrapper>
            <NotiImg source={Noti} />
            <Text>
              <RedText>승차 인원</RedText>이 <RedText>초과</RedText>되었습니다.
              기간 안에 등록 가능한 인원은{' '}
              <BoldText>{props.validSeat}명</BoldText>입니다.{' '}
            </Text>
          </NotiWrapper>
          <OverBookingButton onClick={props.handleOverBookingTable}>
            초과 인원 확인하기
          </OverBookingButton>
        </OverBookingNoti>
      )}

      <StationUl>
        <DragDropContext onDragEnd={onDragEnd}>
          {props.courses.map((course, idx) => {
            const fss = studentStates.filter(ss =>
              course.station.students.some(s => s.id === ss.student.id),
            )
            return (
              <StationLi key={`station_${course.station.id}_${idx}`}>
                <TimeWrapper>{course.station.time}</TimeWrapper>
                <StationBox order={idx} courseCnt={props.courses.length}>
                  <StationNameWrapper>
                    <StationName>{course.station.name}</StationName>
                    <BoardWrapper>
                      <EditStudentStation
                        ref={editRef.ref}
                        onDelete={onDelete}
                        stationID={course.station.id}
                        studentStates={studentStates}
                        filteredStudentStates={studentStates.filter(
                          fs => !fs.fixed,
                        )}
                        operationState={getOperationState(idx)}
                        onSubmit={onSubmit}
                      />
                      <FixedStudentStation
                        stationID={course.station.id}
                        studentStates={fss.filter(fs => fs.fixed)}
                        operationState={getOperationState(idx)}
                      />
                    </BoardWrapper>
                  </StationNameWrapper>
                </StationBox>
              </StationLi>
            )
          })}
        </DragDropContext>
      </StationUl>
    </RegisterStudentWrapper>
  )
}

const RegisterStation = forwardRef(RegisterStationBase)
export default RegisterStation

const DatePickerWrapper = styled.div`
  border-radius: 0.6rem;
  border: 0.1rem solid #e7e7e7;
  background: #fff;
  padding: 2rem;
  //TODO weekcnt에 따라 높이 조절
  max-height: 500px;
`

const RegisterStudentWrapper = styled(DatePickerWrapper)`
  max-height: calc(100vh - 17rem);
  ${flexColumn};
`

const Text = styled.span`
  font-size: 1.1rem;
  color: #585858;
  font-weight: 400;
  line-height: 2rem;
`

const RedText = styled(Text)`
  color: #e74d41;
  font-weight: 700;
`

const BoldText = styled(Text)`
  font-weight: 700;
`
const InfoText = styled(Text)`
  color: #000;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.2rem;
`

const TextWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  margin-bottom: 1rem;
`

const OverBookingNoti = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  background: rgba(231, 77, 65, 0.1);
  padding: 0.8rem;
  margin-bottom: 2rem;
`

const NotiWrapper = styled.div`
  ${flexRow};
  align-items: center;
`

const NotiImg = styled(SVGImage)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.4rem;
`

const OverBookingButton = styled.button`
  border: none;
  height: 2.4rem;
  padding: 0 0.8rem;
  font-size: 1.1rem;
  background: #e74d41;
  color: #fff;
  border-radius: 0.4rem;
  line-height: 2.4rem;
`

const StationUl = styled.ul`
  padding: 1.4rem 0 3rem 0;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const StationLi = styled.li`
  display: flex;
  min-height: 11rem;
`

const TimeWrapper = styled.div`
  width: 6rem;
  font-size: 1.4rem;
`

const StationNameWrapper = styled.div`
  padding: 0 0 3.5rem 3rem;
  position: relative;
`

const StationName = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
`
type StationBoxProps = {
  order: number
  courseCnt: number
}

const StationBox = styled.div<StationBoxProps>`
  flex-basis: 80%;
  ${({order, courseCnt}) =>
    order + 1 === courseCnt
      ? css`
          border-left: none;
        `
      : order !== courseCnt
        ? css`
            border-left: 0.2rem solid #000;
            position: absolute;
            top: 0;
          `
        : ''};
  position: relative;

  ::before {
    ${({order, courseCnt}) =>
      order === 0
        ? css`
            content: '${order + 1}';
            background-color: #ffcd00;
            border: 2px solid ${color.black};
            /* content: url(${DepartureStationIcon});
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 5rem; */
          `
        : order + 1 === courseCnt
          ? css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
              /* content: url(${ArrivalStationIcon});
              margin-left: 0.4rem;
              margin-top: 0.3rem; */
            `
          : css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
            `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 3rem;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    left: -1.2rem;
    top: -0.2rem;
    line-height: 1.1;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
`
const BoardWrapper = styled.div`
  min-height: 4rem;
`
