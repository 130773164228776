import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {Optional} from '../../type/Common'
import {API} from '../../Env'
import {CManagerVector} from '../../model/Manager'

export type GetManagersData = {
  academyID: string
  page?: number
  searchType?: string
  searchValue?: Optional<string>
  size?: number
}

export async function getManagers(
  data: GetManagersData,
): Promise<CManagerVector> {
  const url = `${API}/${data.academyID}/managers`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      searchType: data.searchType,
      searchValue: data.searchValue,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CManagerVector.create(axiosResponse.data.data)
}

type ExistManagerParams = {
  academyID: string
  phone: string
}

export async function existManager(data: ExistManagerParams) {
  const url = `${API}/${data.academyID}/managers/exists`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      phone: data.phone,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export type PostManagerParams = {
  name: string
  password: string
  phone: string
  userId: string
}

export async function postManager(academyID: string, data: PostManagerParams) {
  const url = `${API}/${academyID}/managers`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      name: data.name,
      password: data.password,
      phone: data.phone,
      userId: data.userId,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchManager(academyID: string, managerID: string) {
  const url = `${API}/${academyID}/managers/${managerID}`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function getManagerInfo(academyId: string, managerId: string) {
  const url = `${API}/${academyId}/managers/${managerId}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getManagerDispatches(
  academyId: string,
  managerId: string,
  searchDate: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/dispatches`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      searchDate: searchDate,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function deleteManager(academyId: string, managerId: string) {
  const url = `${API}/${academyId}/managers/${managerId}/expire`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function getManagerMemo(academyId: string, managerId: string) {
  const url = `${API}/${academyId}/managers/${managerId}/memo`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function patchManagerMemo(
  academyId: string,
  managerId: string,
  memo: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/memo`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      memo: memo,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data
}

export async function patchManagerName(
  academyId: string,
  managerId: string,
  name: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/name`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      name: name,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchManagerPassword(
  academyId: string,
  managerId: string,
  password: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/password`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      password: password,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchManagerPhone(
  academyId: string,
  managerId: string,
  phone: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/phone`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      phone: phone,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function patchManagerUserID(
  academyId: string,
  managerId: string,
  userId: string,
) {
  const url = `${API}/${academyId}/managers/${managerId}/user-id`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      userId: userId,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}
