import React, {ReactElement, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import DispatchDetailInfoMixedHeader from './DispatchDetailInfoMixedHeader'
import Picker, {toPickerOptions} from '../../../../../input/Picker'
import {
  CDispatchDetailDate,
  CDispatchDetailWithCode,
} from '../../../../../../model/Dispatch'
import {isNil} from '../../../../../../util/ValidationUtil'
import CalendarIcon from '../../../../../../asset/image/calendar_blue.svg'
import SVGImage from '../../../../../common/SVGImage'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'
import ChairIcon from '../../../../../../asset/image/chair.svg'

type Props = {
  dispatchDetails: CDispatchDetailWithCode[]
  selectedDispatchDetail: CDispatchDetailWithCode
  onChangeType(dd: CDispatchDetailWithCode): void
}

export default function DispatchDetailInfoHeader(props: Props): ReactElement {
  const renderSeatStatus = useCallback(
    (v: CDispatchDetailDate) => {
      if (props.selectedDispatchDetail.type !== OccupancyTypeEnum.FULL_SEAT) {
        return null
      }

      return (
        <SeatStatusContainer>
          <SeatStatusIcon source={ChairIcon} />
          <SeatStatusText>
            {v.seatCount}/{v.totalSeat}
          </SeatStatusText>
        </SeatStatusContainer>
      )
    },
    [props.selectedDispatchDetail],
  )

  const stationPickerOptions = useMemo(() => {
    if (isNil(props.selectedDispatchDetail)) {
      return []
    }

    return toPickerOptions(
      props.selectedDispatchDetail.dates,
      v => (
        <OptionExposureContainer>
          <OptionExposureText>{v.date.toString()}</OptionExposureText>
          {renderSeatStatus(v)}
        </OptionExposureContainer>
      ),
      v => (
        <BoxExposureContainer>
          <Calendar source={CalendarIcon} />
          <BoxExposureText type={props.selectedDispatchDetail.type}>
            {v.date.toString()}
          </BoxExposureText>
        </BoxExposureContainer>
      ),
      {
        getOptionExposure: () => (
          <AllOptionExposureText>{`${props.selectedDispatchDetail.type.exposure} 전체(${props.selectedDispatchDetail.count})`}</AllOptionExposureText>
        ),
        getBoxExposure: () => (
          <BoxExposureContainer>
            <Calendar source={CalendarIcon} />
            <BoxExposureText
              type={
                props.selectedDispatchDetail.type
              }>{`${props.selectedDispatchDetail.type.exposure} 전체(${props.selectedDispatchDetail.count})`}</BoxExposureText>
          </BoxExposureContainer>
        ),
      },
    )
  }, [props.selectedDispatchDetail, renderSeatStatus])

  return (
    <Container>
      <DispatchDetailInfoMixedHeader
        onChangeType={props.onChangeType}
        dispatchDetails={props.dispatchDetails}
      />
      <StationPickerContainer>
        <StationPickerHeader>정류장 이용정보</StationPickerHeader>
        <Picker
          options={stationPickerOptions}
          minWidth={152}
          height={32}
          defaultIdx={0}
        />
      </StationPickerContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`

const StationPickerContainer = styled.div`
  padding: 1.2rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`

const StationPickerHeader = styled.div`
  color: #332a00;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.39px;
`

const BoxExposureContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`
const Calendar = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const BoxExposureText = styled.div<{type: OccupancyTypeEnum}>`
  color: ${props =>
    props.type === OccupancyTypeEnum.NORMAL
      ? '#332A00'
      : props.type === OccupancyTypeEnum.FULL_SEAT
        ? '#AC2016'
        : '#FF7500'};

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const OptionExposureContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const OptionExposureText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-left: 1.2rem;
`

const AllOptionExposureText = styled.div`
  color: #665300;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  padding-left: 1.2rem;
`
const SeatStatusContainer = styled.div`
  ${flexRow};
  margin-left: 1.2rem;
`

const SeatStatusIcon = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const SeatStatusText = styled.div`
  color: #ac2016;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4rem;
`
