import React, {useMemo} from 'react'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../../model/Dispatch'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {ChangeStudentData} from '../extra/LoadStudentExtra'
import CourseColumnItem from '../CourseColumnItem'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../style/CommonStyle'

type Props = {
  coursesWithStudent: CDispatchCourseWithStudent[]
  title: string
  isStart: boolean
  onChangeStudent(data: ChangeStudentData): void
}

export default function CoursesCirculation(props: Props) {
  const dispatchType = useMemo(() => {
    if (props.isStart) {
      return DispatchTypeEnum.INBOUND
    }

    return DispatchTypeEnum.OUTBOUND
  }, [props.isStart])

  return (
    <Container>
      <Header isStart={props.isStart}>
        {props.title} | {dispatchType.exposure}
      </Header>
      {props.coursesWithStudent.map((course, idx) => {
        const isFirstItem = idx === 0
        const isLastItem = idx === props.coursesWithStudent.length - 1
        const selectedStudents = course.students.filter(s => s.check)
        const onChangeStudent = (s?: CStudentWithCheck, t?: string) =>
          props.onChangeStudent({
            course: course,
            student: s,
            time: t,
          })

        return (
          <CourseColumnItem
            key={idx}
            course={course}
            dispatchType={dispatchType}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            isStart={props.isStart}
            onChangeStudent={onChangeStudent}
            selectedStudents={selectedStudents}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  background: #f5f5f5;
`

const Header = styled.div<{isStart: boolean}>`
  width: 100%;
  border-radius: 0.8rem 0.8rem 0 0;
  background: ${props => (props.isStart ? '#009a17' : '#1A468B')};
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.39px;
  justify-content: center;
  padding: 1.25rem 1.2rem;
`
