import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import InputHeader from '../../../input/InputHeader'
import NumberInput, {NumberInputRef} from '../../../input/NumberInput'
import useSecureRef from '../../../../hook/useSecureRef'
import TextInput, {TextInputRef} from '../../../input/TextInput'
import DispatchBusAddDriverSelect, {
  DispatchBusAddDriverSelectRef,
} from './DispatchBusAddDriverSelect'
import {isEmptyString, isNil} from '../../../../util/ValidationUtil'
import {Optional} from '../../../../type/Common'
import {CDriver} from '../../../../model/Driver'
import {PostBusData} from '../../../../service/buses/Buses'
import DispatchDriverAdd from '../busDriverAdd/DispatchDriverAdd'
import Modal, {ModalRef} from '../../../common/Modal'
import {useRecoilValue} from 'recoil'
import {academyState} from '../../../../recoil/Atom'

type Props = {
  readOnly: boolean
  errorMessage: string
  setToast(): void
  onChange(isSufficient: boolean): void
}
export type DispatchBusAddInputContainerRef = {
  getAddBusInputData(): PostBusData
}

function DispatchBusAddInputContainerBase(
  props: Props,
  ref: ForwardedRef<DispatchBusAddInputContainerRef>,
): ReactElement {
  const academy = useRecoilValue(academyState)

  const driverSelectRef = useSecureRef<DispatchBusAddDriverSelectRef>(
    '[DispatchBusAddInputContainer.tsx] driverSelectRef',
  )
  const nameRef = useSecureRef<TextInputRef>(
    '[DispatchBusAddInputContainer.tsx] nameRef',
  )
  const plateRef = useSecureRef<TextInputRef>(
    '[DispatchBusAddInputContainer.tsx] plateRef',
  )
  const seatRef = useSecureRef<NumberInputRef>(
    '[DispatchBusAddInputContainer.tsx] seatRef',
  )
  const companyRef = useSecureRef<TextInputRef>(
    '[DispatchBusAddInputContainer.tsx] companyRef',
  )
  const driverAddModalRef = useSecureRef<ModalRef>(
    '[DispatchBusAddInputContainer.tsx] addModalRef',
  )

  const [name, setName] = useState<string>('')
  const [plate, setPlate] = useState<string>('')

  const onChangeName = useCallback(
    (value: string) => {
      setName(value)
      const plate = plateRef.current().getValue()
      const seat = seatRef.current().getValue()
      const driver = driverSelectRef.current().getSelectedDriver()

      props.onChange(
        !isEmptyString(value) &&
          !isEmptyString(plate) &&
          !isNil(seat) &&
          !isNil(driver),
      )
    },
    [props.onChange],
  )

  const onChangePlate = useCallback(
    (value: string) => {
      setPlate(value)
      const name = nameRef.current().getValue()
      const seat = seatRef.current().getValue()
      const driver = driverSelectRef.current().getSelectedDriver()

      props.onChange(
        !isEmptyString(value) &&
          !isEmptyString(name) &&
          !isNil(seat) &&
          !isNil(driver),
      )
    },
    [props.onChange],
  )

  const onChangeSeat = useCallback(
    (value: Optional<number>) => {
      const plate = plateRef.current().getValue()
      const name = nameRef.current().getValue()
      const driver = driverSelectRef.current().getSelectedDriver()

      props.onChange(
        !isNil(value) &&
          !isEmptyString(plate) &&
          !isEmptyString(name) &&
          !isNil(driver),
      )
    },
    [props.onChange],
  )

  const onChangeDriver = useCallback(
    (value: Optional<CDriver>) => {
      const plate = plateRef.current().getValue()
      const seat = seatRef.current().getValue()
      const name = nameRef.current().getValue()

      props.onChange(
        !isNil(value) && !isEmptyString(plate) && !isNil(seat) && !isNil(name),
      )
    },
    [props.onChange],
  )

  const onChangeCompany = useCallback(
    (value: string) => {
      const plate = plateRef.current().getValue()
      const seat = seatRef.current().getValue()
      const driver = driverSelectRef.current().getSelectedDriver()
      const name = nameRef.current().getValue()

      props.onChange(
        !isEmptyString(value) &&
          !isEmptyString(plate) &&
          !isNil(seat) &&
          !isNil(driver) &&
          !isEmptyString(name),
      )
    },
    [props.onChange],
  )

  const showDriverAddModal = useCallback(() => {
    driverAddModalRef.current().show()
  }, [])

  const onCancel = useCallback(() => {
    driverAddModalRef.current().hide()
  }, [])

  const onSubmit = useCallback(() => {
    driverSelectRef.current().fetchDrivers()
    onCancel()
  }, [onCancel])

  useImperativeHandle(
    ref,
    () => ({
      getAddBusInputData(): PostBusData {
        return {
          driverID: driverSelectRef.current().getSelectedDriver().id,
          name: nameRef.current().getValue(),
          licensePlate: plateRef.current().getValue(),
          validSeat: seatRef.current().getValue(),
          transportCompany: companyRef.current().getValue(),
        }
      },
    }),
    [],
  )

  return (
    <Container>
      <Modal zIndex={3} ref={driverAddModalRef.ref}>
        <DispatchDriverAdd
          onSubmit={onSubmit}
          onCancel={onCancel}
          setToast={props.setToast}
        />
      </Modal>
      <HeaderContainer>
        <StyledHeader>기본 정보</StyledHeader>
        <AcademyInfo>등록 학원 정보 : {academy.name}</AcademyInfo>
      </HeaderContainer>
      <InputContainer>
        <RowContainer>
          <ColumnInputContainer>
            <InputHeader required={true} header={'호차명'} />
            <TextInputContainer>
              <CustomTextInput
                ref={nameRef.ref}
                readOnly={props.readOnly}
                required={true}
                placeholder={'호차명을 입력해주세요.'}
                onChange={onChangeName}
                error={
                  props.errorMessage.includes('name') ||
                  props.errorMessage.includes('중복된 호차명')
                }
                value={name}
              />
              <TextInputText style={{width: 24}}>호차</TextInputText>
            </TextInputContainer>
            {props.errorMessage.includes('name') && (
              <Error>
                호차명은 최소 1자 ~ 최대 43자, 띄어쓰기를 허용하지 않습니다.
                <br />- 하이픈은 _ 언더바로 입력해야합니다.
              </Error>
            )}
            {props.errorMessage.includes('중복된 호차명') && (
              <Error>중복된 호차명 입니다.</Error>
            )}
          </ColumnInputContainer>
          <ColumnInputContainer>
            <InputHeader required={true} header={'차량 번호'} />
            <CustomTextInput
              ref={plateRef.ref}
              readOnly={props.readOnly}
              required={true}
              placeholder={'차량 번호를 입력해주세요.'}
              onChange={onChangePlate}
              value={plate}
              error={
                props.errorMessage.includes('licensePlate') ||
                props.errorMessage.includes('R611-1')
              }
            />
            {props.errorMessage.includes('licensePlate') && (
              <Error>올바른 차량 번호 양식이 아닙니다.</Error>
            )}
            {props.errorMessage.includes('R611-1') && (
              <Error>중복된 차량 번호 입니다.</Error>
            )}
          </ColumnInputContainer>
        </RowContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'운행 기사'} />
          <DispatchBusAddDriverSelect
            ref={driverSelectRef.ref}
            onChange={onChangeDriver}
            showAddDriverModal={showDriverAddModal}
          />
        </ColumnInputContainer>
        <RowContainer>
          <ColumnInputContainer style={{flex: 1}}>
            <InputHeader required={true} header={'가용 좌석'} />
            <TextInputContainer>
              <NumberInput
                ref={seatRef.ref}
                readOnly={props.readOnly}
                required={true}
                placeholder={'좌석수를 입력해주세요.'}
                onChange={onChangeSeat}
              />
            </TextInputContainer>
          </ColumnInputContainer>
          <ColumnInputContainer style={{flex: 1.4}}>
            <InputHeader required={false} header={'운수사'} />
            <TextInput
              ref={companyRef.ref}
              readOnly={props.readOnly}
              required={false}
              placeholder={'운수사를 입력해주세요.'}
              onChange={onChangeCompany}
            />
          </ColumnInputContainer>
        </RowContainer>
      </InputContainer>
    </Container>
  )
}

const DispatchBusAddInputContainer = forwardRef(
  DispatchBusAddInputContainerBase,
)
export default DispatchBusAddInputContainer

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  padding: 1.6rem;
`
const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const StyledHeader = styled.h2`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const AcademyInfo = styled.div`
  color: #3575d9;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
const InputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const ColumnInputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
`

const RowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const TextInputContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const TextInputText = styled.pre`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.036rem;
`

type CustomTextInputProps = {
  error: boolean
  value?: string
}

const CustomTextInput = styled(TextInput)<CustomTextInputProps>`
  font-weight: 300;
  background: ${props => (props.error ? '#FCE6E4' : '#fff')};
  border: ${props =>
    props.error ? '0.1rem solid #ED766E' : '0.1rem solid #EBEBEB'};
  color: ${props => (props.error ? '#DA291C' : '#000')};

  &:focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ffd100;
  }
  &::placeholder {
    font-weight: 300;
  }
`

const Error = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #e74d41;
  margin-top: 0.6rem;
`
