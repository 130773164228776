import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {Optional} from '../../type/Common'
import styled from 'styled-components'
import {isEmptyArray, isNil} from '../../util/ValidationUtil'
import {
  alignCenter,
  color,
  flexColumn,
  flexRow,
  justifyCenter,
} from '../../style/CommonStyle'
import SVGImage from './SVGImage'
import CheckedIcon from '../../asset/image/checked.svg'
import UncheckedIcon from '../../asset/image/unchecked.svg'

type TableListProps = {
  keys: string[]
  items: Optional<Map<string, ReactNode>[]>
  useCheckBox?: boolean
  checkedIconSource?: string
  defaultCheckedIndices?: number[]
  disabledIndices?: number[]
  placeholder?: string
  tableContainerStyle?: CSSProperties
  tableStyle?: CSSProperties
  tableHeaderStyle?: CSSProperties
  tableHeaderRowStyle?: CSSProperties
  tableBodyStyle?: CSSProperties
  tableBodyRowStyle?: CSSProperties
  checkBoxStyle?: CSSProperties
  onClickRow(idx: number): void
  onClickAll?(indices: number[]): void
  onChangeIndices?(value: number[]): void
}
export type TableListRef = {
  setItems(items: Map<string, ReactNode>[]): void
  getCheckedIndices(): number[]
  reset(): void
}

function TableListBase(
  props: TableListProps,
  ref: ForwardedRef<TableListRef>,
): ReactElement {
  const {useCheckBox = false, disabledIndices = []} = props
  const [listItems, setListItems] = useState<
    Optional<Map<string, ReactNode>[]>
  >([])
  const [checkedIndices, setCheckedIndices] = useState<number[]>([])

  const getIsChecked = useCallback(
    (index: number): boolean => {
      return checkedIndices.some(idx => idx === index)
    },
    [checkedIndices],
  )

  const toggleCheckBox = useCallback(
    (index: number): void => {
      if (disabledIndices.includes(index)) {
        return
      }

      if (getIsChecked(index)) {
        setCheckedIndices(prev => prev.filter(idx => idx !== index))
      } else {
        setCheckedIndices(prev => [...prev, index])
      }
    },
    [getIsChecked, setCheckedIndices, disabledIndices],
  )

  const onClickRow = useCallback(
    (idx: number) => {
      if (!disabledIndices.includes(idx)) {
        props.onClickRow(idx)
        toggleCheckBox(idx)
      }
    },
    [props.onClickRow, toggleCheckBox],
  )

  const isCheckedAll = useMemo(() => {
    return checkedIndices.length === listItems.length - disabledIndices.length
  }, [checkedIndices, listItems, disabledIndices])

  const toggleAll = useCallback(() => {
    if (isCheckedAll) {
      if (!isNil(props.onClickAll)) {
        props.onClickAll([])
      }

      setCheckedIndices([])
      if (disabledIndices) {
        setCheckedIndices(disabledIndices)
      }
      return
    }

    const targetItems = listItems
      .map((_, idx) => idx)
      .filter((_, idx) => !disabledIndices.includes(idx))

    if (!isNil(props.onClickAll)) {
      props.onClickAll(targetItems)
    }

    setCheckedIndices(targetItems)
  }, [
    listItems,
    isCheckedAll,
    disabledIndices,
    props.onClickAll,
    setCheckedIndices,
  ])

  const CheckedAllIconComponent = useMemo(() => {
    if (isCheckedAll) {
      const source = !isNil(props.checkedIconSource)
        ? props.checkedIconSource
        : CheckedIcon

      return <CheckIcon source={source} />
    }

    return <CheckIcon source={UncheckedIcon} />
  }, [props.checkedIconSource, isCheckedAll])

  const HeadComponent = useMemo(() => {
    return (
      <tr>
        {useCheckBox ? (
          <th style={{width: 80}}>
            <CheckBoxContainer>
              <CheckBox style={props.checkBoxStyle} onClick={toggleAll}>
                {CheckedAllIconComponent}
              </CheckBox>
            </CheckBoxContainer>
          </th>
        ) : null}
        {props.keys.map((key: string, idx: number) => {
          return (
            <th key={`${key}_${idx}`} style={props.tableHeaderRowStyle}>
              <ContentContainer>{key}</ContentContainer>
            </th>
          )
        })}
      </tr>
    )
  }, [props.keys, useCheckBox, toggleAll, CheckedAllIconComponent])

  const renderCheckedIcon = useCallback(
    (idx: number) => {
      if (getIsChecked(idx)) {
        const source = !isNil(props.checkedIconSource)
          ? props.checkedIconSource
          : CheckedIcon

        return <CheckIcon source={source} />
      }

      return <CheckIcon source={UncheckedIcon} />
    },
    [props.checkedIconSource, getIsChecked],
  )

  const getTableBodyStyle = useCallback(
    (idx: number) => {
      if (disabledIndices.includes(idx)) {
        return {
          ...props.tableBodyStyle,
          opacity: 0.3,
        }
      }

      return props.tableBodyStyle
    },
    [disabledIndices],
  )

  const BodyComponent = useMemo(() => {
    return listItems.map((item, idx) => {
      return (
        <tr
          style={getTableBodyStyle(idx)}
          key={idx}
          onClick={() => onClickRow(idx)}>
          {useCheckBox ? (
            <td style={{width: 80}}>
              <CheckBoxContainer>
                <CheckBox
                  style={props.checkBoxStyle}
                  onClick={() => toggleCheckBox(idx)}>
                  {renderCheckedIcon(idx)}
                </CheckBox>
              </CheckBoxContainer>
            </td>
          ) : null}
          {props.keys.map(k => {
            return (
              <td key={`${k}_${idx}`} style={props.tableBodyRowStyle}>
                <ContentContainer>{item.get(k)}</ContentContainer>
              </td>
            )
          })}
        </tr>
      )
    })
  }, [
    listItems,
    props.keys,
    props.checkedIconSource,
    props.checkBoxStyle,
    renderCheckedIcon,
    getTableBodyStyle,
    onClickRow,
    toggleCheckBox,
  ])

  useEffect(() => {
    setListItems(props.items)
  }, [props.items])

  useEffect(() => {
    if (isNil(props.onChangeIndices)) {
      return
    }

    props.onChangeIndices(checkedIndices)
  }, [checkedIndices])

  useEffect(() => {
    if (isNil(props.defaultCheckedIndices)) {
      return
    }

    setCheckedIndices(props.defaultCheckedIndices)
  }, [props.defaultCheckedIndices, setCheckedIndices])

  useImperativeHandle(
    ref,
    () => ({
      setItems(items: Map<string, React.ReactNode>[]) {
        setListItems(items)
      },
      getCheckedIndices(): number[] {
        return checkedIndices
      },
      reset() {
        setCheckedIndices([])
      },
    }),
    [checkedIndices],
  )

  if (isNil(listItems)) {
    return null
  }

  return (
    <Container style={props.tableContainerStyle}>
      {isEmptyArray(listItems) ? (
        <EmptyContainer>
          <Text>{props.placeholder}</Text>
        </EmptyContainer>
      ) : (
        <Table cellPadding={0} cellSpacing={0} style={props.tableStyle}>
          <Thead style={props.tableHeaderStyle}>{HeadComponent}</Thead>
          <TBody style={props.tableBodyStyle}>{BodyComponent}</TBody>
        </Table>
      )}
    </Container>
  )
}

const TableList = forwardRef(TableListBase)
export default TableList

const Container = styled.div`
  border-collapse: collapse;
  overflow: auto;
  flex: 1;
  background: #ffffff;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const Table = styled.table`
  width: 100%;
  font-family: 'Pretendard', 'Noto Sans Mono', monospace;
`

const Thead = styled.thead`
  tr {
    padding: 1.2rem;
  }

  th {
    font-size: 2rem;
    text-align: center;
    height: 4rem;
    overflow: hidden;
    color: #174490;
    font-weight: 700;
    border-bottom: 1px solid ${color.grey1};
  }
`

const TBody = styled.tbody`
  tr {
    cursor: pointer;
    border: none;
    height: 5.5rem;
  }

  td {
    font-size: 3rem;
    text-align: center;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid ${color.grey1};
  }
`

const ContentContainer = styled.div`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const EmptyContainer = styled.div`
  ${flexColumn};
  width: 100%;
  justify-content: center;
  text-align: center;
  height: 10rem;
`

const Text = styled.div`
  font-size: 1.4rem;
  color: ${color.grey3};
  line-height: 2rem;
`
const CheckBoxContainer = styled.div`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  height: 100%;
`

const CheckBox = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`

const CheckIcon = styled(SVGImage)`
  width: 100%;
  height: 100%;
`
