import React, {ForwardedRef, forwardRef, ReactElement} from 'react'
import OperationDetailEditBoarding from './OperationDetailEditBoarding'
import styled from 'styled-components'
import {alignCenter, color, flexColumn, flexRow, justifyCenter} from '../../../style/CommonStyle'
import {CBusOperationSchedule} from '../../../model/Bus'
import {isEmptyArray} from '../../../util/ValidationUtil'

type OperationDetailEditOnBoardProps = {
  schedules: CBusOperationSchedule[]
  stationIndex: number
}
export type OperationDetailEditOnBoardRef = {}

function OperationDetailEditOnBoardBase(
  props: OperationDetailEditOnBoardProps,
  _: ForwardedRef<OperationDetailEditOnBoardRef>,
): ReactElement {
  if (isEmptyArray(props.schedules)) {
    return null
  }

  return (
    <DraggableContainer>
      <Boarding>승차</Boarding>
      <StudentsContainer>
        {props.schedules.map(s => {
          return (
            <OperationDetailEditBoarding
              stationIndex={props.stationIndex}
              type={'on'}
              key={s.student.id}
              schedule={s}
              index={s.student.id}
            />
          )
        })}
      </StudentsContainer>
    </DraggableContainer>
  )
}

const OperationDetailEditOnBoard = forwardRef(OperationDetailEditOnBoardBase)
export default OperationDetailEditOnBoard

const DraggableContainer = styled.div`
  ${flexRow};
  column-gap: 1rem;
`

const Boarding = styled.p`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  padding: 0 0.8rem;
  margin-top: 0.3rem;
  min-width: 3.7rem;
  max-width: 3.7rem;
  height: 2.1rem;
  background-color: ${color.green1};
  color: ${color.white};
  font-size: 1.2rem;
  border-radius: 1.2rem;
`

const StudentsContainer = styled.div`
  ${flexColumn};
  row-gap: 1.2rem;
  margin-top: 0.3rem;
`
