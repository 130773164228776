import {CDate} from './Date'
import {WeekdayEnum} from '../enum/WeekdayEnum'
import {CPaging} from './Paging'

export class CSemester {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly startDate: CDate,
    readonly endDate: CDate,
  ) {}

  static create(json: any) {
    return new CSemester(
      json.id,
      json.name,
      CDate.create(json.startDate),
      CDate.create(json.endDate),
    )
  }
}

export class CSemesterVector {
  private constructor(
    readonly semesters: CSemester[],
    readonly paging: CPaging,
  ) {}

  static create(json: any) {
    return new CSemesterVector(
      json.semesters.map((s: any) => CSemester.create(s)),
      CPaging.create(json.paging),
    )
  }
}

export class CClassTime {
  private constructor(
    readonly startTimes: string[],
    readonly endTimes: string[],
    readonly weekday: WeekdayEnum,
  ) {}

  static create(json: any) {
    return new CClassTime(
      json.startTimes,
      json.endTimes,
      WeekdayEnum.parseValueToWeekday(json.weekday),
    )
  }
}

export class COperationClassTime {
  private constructor(
    readonly time: string,
    readonly isEnable: boolean,
    readonly isChecked: boolean,
  ) {}

  static create(json: any) {
    return new COperationClassTime(json.time, json.isEnable, json.isEnable)
  }
}

export class COperationClass {
  private constructor(
    readonly startTimes: COperationClassTime[],
    readonly endTimes: COperationClassTime[],
    readonly isEnable: boolean,
    readonly isChecked: boolean,
    readonly weekday: WeekdayEnum,
  ) {}

  static create(json: any) {
    return new COperationClass(
      json.startTimes.map((st: any) => COperationClassTime.create(st)),
      json.endTimes.map((et: any) => COperationClassTime.create(et)),
      json.isEnable,
      json.isEnable,
      WeekdayEnum.parseValueToWeekday(json.weekday),
    )
  }
}
