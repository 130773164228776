import React from 'react'
import {OccupancyTypeEnum} from '../../../../../../../enum/OccupancyTypeEnum'
import OperationInfoFull from './OperationInfoFull'
import OperationInfoNormal from '../normal/OperationInfoNormal'
import {CDispatchCourseWithStudent} from '../../../../../../../model/Dispatch'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {CCourse} from '../../../../../../../model/Course'
import {CManager} from '../../../../../../../model/Manager'
import {Optional} from '../../../../../../../type/Common'
import {BusInfo} from '../../DispatchEdit'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'

type Props = {
  type: OccupancyTypeEnum
  dispatchCourses: CDispatchCourseWithStudent[]
  dispatchCode: string
  dispatchType: Optional<DispatchTypeEnum>
  dispatchPeriod: CDispatchPeriodInfo
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  extraBusInfo: Optional<BusInfo>
  busName: string
  manager: Optional<CManager>
  extraManager: Optional<CManager>

  onChangeCourse(c: CCourse): void
  onChangeExtraCourse(c: CCourse): void
  onChangeExtraBus(bi: BusInfo): void
  onChangeManager(m: CManager): void
  onChangeExtraManager(m: CManager): void
  onDeleteExtra(): void
}

export default function OperationInfo(props: Props) {
  if (props.type === OccupancyTypeEnum.FULL_SEAT) {
    return (
      <OperationInfoFull
        dispatchCourses={props.dispatchCourses}
        dispatchType={props.dispatchType}
        dispatchCode={props.dispatchCode}
        course={props.course}
        manager={props.manager}
        extraCourse={props.extraCourse}
        extraBusInfo={props.extraBusInfo}
        extraManager={props.extraManager}
        busName={props.busName}
        onChangeCourse={props.onChangeCourse}
        onChangeExtraCourse={props.onChangeExtraCourse}
        onChangeExtraBus={props.onChangeExtraBus}
        onChangeExtraManager={props.onChangeExtraManager}
        onChangeManager={props.onChangeManager}
        onDeleteExtra={props.onDeleteExtra}
        dispatchPeriod={props.dispatchPeriod}
      />
    )
  }

  return (
    <OperationInfoNormal
      dispatchType={props.dispatchType}
      course={props.course}
      manager={props.manager}
      onChangeCourse={props.onChangeCourse}
      onChangeManager={props.onChangeManager}
      dispatchPeriod={props.dispatchPeriod}
    />
  )
}
