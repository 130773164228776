import React, {forwardRef, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import ScreenContainer from '../../component/layout/ScreenContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import {flexColumn} from '../../style/CommonStyle'
import useSecureRef from '../../hook/useSecureRef'
import {isNil} from '../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../type/Common'
import {academyIDState} from '../../recoil/Atom'
import {getBuses, GetBusesParams} from '../../service/buses/Buses'
import DispatchList, {
  DispatchListRef,
} from '../../component/busesManagement/dispatchList/DispatchList'
import SearchBuses from '../../component/busesManagement/searchWrapper/SearchBuses'
import {CBus} from '../../model/Bus'

const PER_PAGE = 12

type StateProps = {
  state: {
    toast: boolean
  }
}

function BusesManagementBase() {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const listRef = useSecureRef<DispatchListRef>('[Dispatch.tsx] listRef')
  const [dispatchState, setDispatchState] = useState<CBus[]>([])
  const [totalElements, setTotalElements] = useState<Optional<number>>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [select, setSelect] = useState<string>('NAME')
  const [keyword, setKeyword] = useState<string>('')

  const fetchDispatchesList = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetBusesParams = {
      academyID: selectedAcademyID,
      page: currentPage,
      searchType: select,
      searchValue: keyword,
      size: PER_PAGE,
    }

    getBuses(data)
      .then(bl => {
        setDispatchState(bl.buses)
        setTotalElements(bl.paging.totalElements)
      })
      .catch(error => {
        throw new Error(
          `failed to get buses list. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [currentPage, selectedAcademyID, keyword])

  useEffect(() => {
    setCurrentPage(0)
  }, [keyword, selectedAcademyID])

  useEffect(() => {
    fetchDispatchesList()
  }, [selectedAcademyID, currentPage, keyword, fetchDispatchesList])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const savedPageIdx = urlParams.get('page')

    if (savedPageIdx !== null) {
      const pageIndex = parseInt(savedPageIdx) - 1
      const timer = setTimeout(() => {
        setCurrentPage(pageIndex)
      }, 100)
      return () => clearTimeout(timer)
    } else {
      setCurrentPage(0)
    }
  }, [])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>호차 관리</PageHeaderWithAcademies>
      <Container>
        <SearchBuses
          setSelect={setSelect}
          setKeyword={setKeyword}
          keyword={keyword}
          fetchDispatchesList={fetchDispatchesList}
        />
        <DispatchList
          ref={listRef.ref}
          dispatchState={dispatchState}
          PER_PAGE={PER_PAGE}
          totalElements={totalElements}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </ScreenContainer>
  )
}

const BusesManagement = forwardRef(BusesManagementBase)
export default BusesManagement

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 13.8rem);
`
