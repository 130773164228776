import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import InputHeader from '../../../input/InputHeader'
import TextInput, {TextInputRef} from '../../../input/TextInput'
import DatePickerContainer, {
  DatePickerContainerRef,
} from '../../../input/DatePickerContainer'
import DatePickerInput from '../../../input/DatePickerInput'
import CheckMultiSelect, {
  CheckMultiSelectRef,
  toCheckMultiSelectOptions,
} from '../../../input/CheckMultiSelect'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import useSecureRef from '../../../../hook/useSecureRef'
import {WeekdayEnum} from '../../../../enum/WeekdayEnum'
import {CSemester} from '../../../../model/Semester'
import {Optional} from '../../../../type/Common'
import {
  isEmptyArray,
  isEmptyString,
  isNil,
} from '../../../../util/ValidationUtil'
import {CDate} from '../../../../model/Date'
import NumberInput, {NumberInputRef} from '../../../input/NumberInput'

export type PeriodInformation = {
  name: string
  startDate: CDate
  endDate: CDate
  inboundBeforeMinute: number
  outboundAfterMinute: number
  weekdays: WeekdayEnum[]
}

type Props = {
  selectedSemester: Optional<CSemester>
  selectableWeekdays: WeekdayEnum[]
  defaultData: Optional<PeriodInformation>
  hasData: boolean
  readOnly: boolean
  onChangeInformation(pi: PeriodInformation): void
}
export type DispatchPeriodInputContainerRef = {
  getInputValues(): PeriodInformation
  resetAll(): void
}

function DispatchPeriodInputContainerBase(
  props: Props,
  ref: ForwardedRef<DispatchPeriodInputContainerRef>,
): ReactElement {
  const nameRef = useSecureRef<TextInputRef>(
    '[DispatchPeriodInformation.tsx] nameRef',
  )
  const startDateRef = useSecureRef<DatePickerContainerRef>(
    '[DispatchPeriodInformation.tsx] startDateRef',
  )
  const endDateRef = useSecureRef<DatePickerContainerRef>(
    '[DispatchPeriodInformation.tsx] endDateRef',
  )
  const inboundMinuteRef = useSecureRef<NumberInputRef>(
    '[DispatchPeriodInformation.tsx] inboundMinuteRef',
  )
  const outboundMinuteRef = useSecureRef<NumberInputRef>(
    '[DispatchPeriodInformation.tsx] outboundMinuteRef',
  )
  const weekdayRef = useSecureRef<CheckMultiSelectRef<WeekdayEnum>>(
    '[DispatchPeriodInformation.tsx] operateDayRef',
  )

  const startDate = useMemo((): Optional<CDate> => {
    if (!isNil(props.defaultData)) {
      return props.defaultData.startDate
    }

    if (isNil(props.selectedSemester)) {
      return null
    }

    return props.selectedSemester.startDate
  }, [props.selectedSemester, props.defaultData])

  const endDate = useMemo((): Optional<CDate> => {
    if (!isNil(props.defaultData)) {
      return props.defaultData.endDate
    }

    if (isNil(props.selectedSemester)) {
      return null
    }

    return props.selectedSemester.endDate
  }, [props.selectedSemester, props.defaultData])

  const dispatchDayOptions = useMemo(() => {
    return toCheckMultiSelectOptions(
      WeekdayEnum.ALL.map(wd => {
        const disabled = !props.selectableWeekdays
          .map(el => el.value)
          .includes(wd.value)

        return {
          value: wd,
          disabled: disabled,
        }
      }),
      (v: WeekdayEnum, disabled) => (
        <CheckBoxExposure readOnly={props.readOnly || disabled}>
          {v.exposure}
        </CheckBoxExposure>
      ),
    )
  }, [props.readOnly, props.selectableWeekdays])

  const defaultDispatchDayIndices = useMemo(() => {
    if (isNil(props.defaultData)) {
      return []
    }

    return dispatchDayOptions
      .filter(ddo => props.defaultData.weekdays.some(wd => wd === ddo.value))
      .map(ddo => ddo.idx)
  }, [props.defaultData])

  const getValues = useCallback(() => {
    const name = nameRef.current().getValue()
    const startDate = startDateRef.current().getValue()
    const endDate = endDateRef.current().getValue()
    const inboundBeforeMinute = inboundMinuteRef.current().getValue()
    const outboundBeforeMinute = outboundMinuteRef.current().getValue()
    const weekdays = weekdayRef.current().getValue()

    if (
      isEmptyString(name) ||
      isNil(startDate) ||
      isNil(endDate) ||
      isNil(inboundBeforeMinute) ||
      isNil(outboundBeforeMinute) ||
      isEmptyArray(weekdays)
    ) {
      return null
    }

    return {
      name: name,
      startDate: startDate,
      endDate: endDate,
      inboundBeforeMinute: inboundBeforeMinute,
      outboundAfterMinute: outboundBeforeMinute,
      weekdays: weekdays,
    }
  }, [])

  const resetAll = useCallback(() => {
    nameRef.current().reset()
    startDateRef.current().reset()
    endDateRef.current().reset()
    inboundMinuteRef.current().reset()
    outboundMinuteRef.current().reset()
    weekdayRef.current().reset()
  }, [])

  const onChangeName = useCallback(
    (name: string) => {
      props.onChangeInformation({
        name: name,
        startDate: startDateRef.current().getValue(),
        endDate: startDateRef.current().getValue(),
        inboundBeforeMinute: inboundMinuteRef.current().getValue(),
        outboundAfterMinute: outboundMinuteRef.current().getValue(),
        weekdays: weekdayRef.current().getValue(),
      })
    },
    [props.onChangeInformation],
  )

  const onChangeStartDate = useCallback(
    (sd: Optional<CDate>) => {
      props.onChangeInformation({
        name: nameRef.current().getValue(),
        startDate: sd,
        endDate: startDateRef.current().getValue(),
        inboundBeforeMinute: inboundMinuteRef.current().getValue(),
        outboundAfterMinute: outboundMinuteRef.current().getValue(),
        weekdays: weekdayRef.current().getValue(),
      })
    },
    [props.onChangeInformation],
  )

  const onChangeEndDate = useCallback(
    (ed: Optional<CDate>) => {
      props.onChangeInformation({
        name: nameRef.current().getValue(),
        startDate: startDateRef.current().getValue(),
        endDate: ed,
        inboundBeforeMinute: inboundMinuteRef.current().getValue(),
        outboundAfterMinute: outboundMinuteRef.current().getValue(),
        weekdays: weekdayRef.current().getValue(),
      })
    },
    [props.onChangeInformation],
  )

  const onChangeInboundMinute = useCallback(
    (im: number) => {
      props.onChangeInformation({
        name: nameRef.current().getValue(),
        startDate: startDateRef.current().getValue(),
        endDate: startDateRef.current().getValue(),
        inboundBeforeMinute: im,
        outboundAfterMinute: outboundMinuteRef.current().getValue(),
        weekdays: weekdayRef.current().getValue(),
      })
    },
    [props.onChangeInformation],
  )

  const onChangeOutboundMinute = useCallback(
    (om: number) => {
      props.onChangeInformation({
        name: nameRef.current().getValue(),
        startDate: startDateRef.current().getValue(),
        endDate: startDateRef.current().getValue(),
        inboundBeforeMinute: inboundMinuteRef.current().getValue(),
        outboundAfterMinute: om,
        weekdays: weekdayRef.current().getValue(),
      })
    },
    [props.onChangeInformation],
  )

  const onChangeWeekdays = useCallback(
    (wds: WeekdayEnum[]) => {
      props.onChangeInformation({
        name: nameRef.current().getValue(),
        startDate: startDateRef.current().getValue(),
        endDate: startDateRef.current().getValue(),
        inboundBeforeMinute: inboundMinuteRef.current().getValue(),
        outboundAfterMinute: outboundMinuteRef.current().getValue(),
        weekdays: wds,
      })
    },
    [props.onChangeInformation],
  )

  useImperativeHandle(
    ref,
    () => ({
      getInputValues(): PeriodInformation {
        return getValues()
      },
      resetAll() {
        resetAll()
        return
      },
    }),
    [resetAll],
  )

  if (props.hasData && isNil(props.defaultData)) {
    return null
  }

  return (
    <Container>
      <DispatchInfoHeader>배차 정보</DispatchInfoHeader>
      <ColumnInputContainer>
        <InputHeader required={true} header={'배차 기간명'} />
        <TextInput
          ref={nameRef.ref}
          readOnly={false}
          required={true}
          placeholder={'배차 이용 기간명을 입력해주세요.(ex :1학기)'}
          default={props.defaultData?.name}
          onChange={onChangeName}
        />
      </ColumnInputContainer>
      <RowContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'시작일'} />
          <DatePickerContainer
            ref={startDateRef.ref}
            customInput={<DatePickerInput readOnly={props.readOnly} />}
            minDate={startDate}
            maxDate={endDate}
            defaultValue={startDate}
            onChange={onChangeStartDate}
            placeholder={'2024-03-14'}
            readOnly={props.readOnly}
          />
        </ColumnInputContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'종료일'} />
          <DatePickerContainer
            ref={endDateRef.ref}
            customInput={<DatePickerInput readOnly={false} />}
            minDate={startDate}
            maxDate={endDate}
            defaultValue={endDate}
            onChange={onChangeEndDate}
            placeholder={'2024-03-14'}
            readOnly={false}
          />
        </ColumnInputContainer>
      </RowContainer>

      <RowContainer>
        <ColumnInputContainer>
          <InputHeaderContainer>
            <InputHeader required={true} header={'등원 시간'} />
            <InputHeaderInfo>(ex:10분 전)</InputHeaderInfo>
          </InputHeaderContainer>
          <TextInputContainer>
            <TextInputText style={{width: 48}}>수업 시작</TextInputText>
            <NumberInput
              ref={inboundMinuteRef.ref}
              readOnly={props.readOnly}
              required={true}
              placeholder={'수업 전 차량 도착 시간'}
              onChange={onChangeInboundMinute}
              default={props.defaultData?.inboundBeforeMinute}
            />
            <TextInputText style={{width: 24}}>분 전</TextInputText>
          </TextInputContainer>
        </ColumnInputContainer>
        <ColumnInputContainer>
          <InputHeaderContainer>
            <InputHeader required={true} header={'하원 시간'} />
            <InputHeaderInfo>(ex:10분 후)</InputHeaderInfo>
          </InputHeaderContainer>
          <TextInputContainer>
            <TextInputText style={{width: 48}}>수업 종료</TextInputText>
            <NumberInput
              ref={outboundMinuteRef.ref}
              readOnly={props.readOnly}
              required={true}
              placeholder={'수업 종료 후 차량 출발 시'}
              onChange={onChangeOutboundMinute}
              default={props.defaultData?.outboundAfterMinute}
            />
            <TextInputText style={{width: 24}}>분 후</TextInputText>
          </TextInputContainer>
        </ColumnInputContainer>
      </RowContainer>

      <ColumnInputContainer>
        <InputHeader required={true} header={'차량 운행 요일'} />
        <CheckMultiSelect
          ref={weekdayRef.ref}
          options={dispatchDayOptions}
          defaultIndices={defaultDispatchDayIndices}
          hasAll={false}
          required={true}
          readOnly={props.readOnly}
          onChange={onChangeWeekdays}
        />
      </ColumnInputContainer>
    </Container>
  )
}

const DispatchPeriodInputContainer = forwardRef(
  DispatchPeriodInputContainerBase,
)
export default DispatchPeriodInputContainer

const Container = styled.div`
  ${flexColumn};
  margin-top: 1.6rem;
  row-gap: 0.8rem;
`

const DispatchInfoHeader = styled.h2`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const ColumnInputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  position: relative;
`

const RowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`
const InputHeaderInfo = styled.p`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: #cccccc;
`

const TextInputContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const TextInputText = styled.pre`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.036rem;
`

const CheckBoxExposure = styled.p<{readOnly: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${props => (props.readOnly ? '#CCCCCC' : '#000000')};
`
