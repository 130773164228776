import React, {ForwardedRef, forwardRef, ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {CBusDetail, CBusOperationSchedule} from '../../../model/Bus'
import OperationDetailView from './OperationDetailView'
import {CDate} from '../../../model/Date'
import {
  getBusOperationDetail,
  GetBusOperationDetailParams,
  getBusOperationsSchedules,
  putStudentSchedule,
} from '../../../service/busOperation/BusOperation'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {Optional} from '../../../type/Common'
import {CDateTime} from '../../../model/DateTime'
import {BaseDateUnitEnum} from '../../../enum/DateUnitEnum'
import {color} from '../../../style/CommonStyle'
import OperationDetailEdit, {OperationDetailEditRef} from './OperationDetailEdit'
import useSecureRef from '../../../hook/useSecureRef'
import {putStudentScheduleStations, StudentScheduleStationsParam} from '../../../service/schedule/Schedule'
import {ModalContainerRef} from '../../modal/ModalContainer'
import HalfPopUp from '../../common/HalfPopUp'
import {BoardingPopUp, CancelPopUp, ConfirmPopUp, SavePopUp} from '../../modal/PopUpContents'
import {BoardSwitchEnum} from '../../../enum/BoardSwitchEnum'

type OperationDetailContainerProps = {
  date: CDate
  detailParams: GetBusOperationDetailParams
}
export type OperationDetailContainerRef = {}

function OperationDetailContainerBase(
  props: OperationDetailContainerProps,
  _: ForwardedRef<OperationDetailContainerRef>,
): ReactElement {
  const cancelRef = useSecureRef<ModalContainerRef>('[OperationDetailContainer.tsx] cancelRef')
  const saveRef = useSecureRef<ModalContainerRef>('[OperationDetailContainer.tsx] saveRef')
  const confirmRef = useSecureRef<ModalContainerRef>('[OperationDetailContainer.tsx] confirmRef')
  const editRef = useSecureRef<OperationDetailEditRef>('[OperationDetailContainer.tsx] editRef')
  const boardingRef = useSecureRef<ModalContainerRef>('[OperationDetailContainer.tsx] boardingRef')
  const [details, setDetails] = useState<CBusDetail[]>([])
  const [schedules, setSchedules] = useState<CBusOperationSchedule[]>([])
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [studentName, setStudentName] = useState<Optional<string>>(null)
  const [boardingStatus, setBoardingStatus] = useState<Optional<number>>(null)
  const [scheduleId, setScheduleId] = useState<Optional<number>>(null)
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)

  const fetchOperationDetails = useCallback((data: GetBusOperationDetailParams) => {
    getBusOperationDetail(data)
      .then(ds => setDetails(ds))
      .catch(error => {
        throw new Error(`failed to get bus operation detail. (data: ${JSON.stringify(data)}, error: ${error})`)
      })
  }, [])

  const fetchSchedules = useCallback(
    (code: string, date: CDate, academyID: string) => {
      getBusOperationsSchedules(code, date, academyID)
        .then(ss => setSchedules(ss))
        .catch(error => {
          throw new Error(
            `failed to get bus operations schedules. (code: ${code}, academyID: ${academyID}, error: ${error})`,
          )
        })
    },
    [scheduleId],
  )

  const handleCancelButton = useCallback(() => {
    cancelRef.current().show()
  }, [])

  const onClickCancelButton = useCallback(() => {
    cancelRef.current().hide()
    saveRef.current().hide()
    boardingRef.current().hide()
  }, [])

  const handleSaveButton = useCallback(() => {
    saveRef.current().show()
  }, [])

  const onClickEditButton = useCallback(() => {
    cancelRef.current().hide()
    setIsEditable(prev => !prev)
  }, [isEditable])

  const handleConfirmButton = useCallback(() => {
    confirmRef.current().hide()
  }, [])

  const handleBoardingButton = useCallback((name: string, status: number, scheduleId: number) => {
    boardingRef.current().show()
    setStudentName(name)
    setBoardingStatus(status)
    setScheduleId(scheduleId)
  }, [])

  const editable = useMemo(() => {
    if (isEmptyArray(details)) {
      return false
    }

    const d = `${props.date}T${details[0].arriveTargetTime}`
    const dt = CDateTime.create(d)
    const AfterThirtyMinute = dt.add(-30, BaseDateUnitEnum.MINUTE)

    return CDateTime.now().isBefore(AfterThirtyMinute)
  }, [details])

  const onSubmit = useCallback(() => {
    const schedules = editRef.current().getSchedules()
    const parsedSchedules: StudentScheduleStationsParam[] = schedules.map(s => {
      return {
        pickupStationId: s.pickUpStationId,
        scheduleId: s.scheduleId,
        takeoffStationId: s.takeOffStationId,
      }
    })

    putStudentScheduleStations(selectedAcademyID, parsedSchedules)
      .then(() => {
        saveRef.current().hide()
        confirmRef.current().show()
        setIsEditable(prev => !prev)
        fetchSchedules(props.detailParams.code, props.detailParams.date, selectedAcademyID)
      })
      .catch(error => {
        throw new Error(
          `failed to put student schedule stations. (id: ${selectedAcademyID}, data: ${JSON.stringify(
            parsedSchedules,
          )}, error: ${error})`,
        )
      })
  }, [isEditable, fetchSchedules, props.detailParams, selectedAcademyID])

  const EditButtonComponent = useMemo(() => {
    if (!isEditable) {
      return (
        <Button disabled={!editable} editable={editable} onClick={onClickEditButton}>
          수정
        </Button>
      )
    }

    return (
      <ButtonWrapper>
        <CancelButton editable={true} onClick={handleCancelButton}>
          취소
        </CancelButton>
        <Button onClick={handleSaveButton} editable={true}>
          저장
        </Button>
      </ButtonWrapper>
    )
  }, [isEditable, editable, onClickEditButton])

  const DetailComponent = useMemo(() => {
    if (!isEditable) {
      return (
        <OperationDetailView
          details={details}
          schedules={schedules}
          date={props.date}
          handleBoardingButton={handleBoardingButton}
        />
      )
    }

    return <OperationDetailEdit ref={editRef.ref} details={details} schedules={schedules} date={props.date} />
  }, [isEditable, details, schedules, props.date])

  useEffect(() => {
    if (isNil(props.detailParams)) {
      return
    }

    fetchOperationDetails(props.detailParams)
    fetchSchedules(props.detailParams.code, props.detailParams.date, selectedAcademyID)

    const intervalId = setInterval(() => {
      fetchOperationDetails(props.detailParams)
      fetchSchedules(props.detailParams.code, props.detailParams.date, selectedAcademyID)
    }, 60000)

    return () => clearInterval(intervalId)
  }, [props.detailParams, selectedAcademyID])

  const changeBoardingButton = useCallback(() => {
    putStudentSchedule(selectedAcademyID, scheduleId, boardingStatus === 0 ? true : false)
      .then(() => {
        boardingRef.current().hide()
        fetchOperationDetails(props.detailParams)
        fetchSchedules(props.detailParams.code, props.detailParams.date, selectedAcademyID)
      })
      .catch(error => {
        alert('변경에 실패하였습니다.')
        throw new Error(
          `failed to put student schedule. (academyId: ${selectedAcademyID}, scheduledId: ${scheduleId}, error: ${error})`,
        )
      })
  }, [scheduleId, props.detailParams, boardingStatus, selectedAcademyID])

  return (
    <>
      <OperationEdit>
        수정은 첫 정류장 출발 30분 전까지만 가능합니다.
        {EditButtonComponent}
      </OperationEdit>
      {DetailComponent}
      <HalfPopUp
        width={'30rem'}
        height={'17.5rem'}
        top={'40%'}
        right={'50%'}
        ref={cancelRef.ref}
        contents={<CancelPopUp onClose={onClickCancelButton} onClickEditButton={onClickEditButton} />}
      />
      <HalfPopUp
        width={'30rem'}
        height={'17.5rem'}
        top={'40%'}
        right={'50%'}
        ref={saveRef.ref}
        contents={<SavePopUp onClose={onClickCancelButton} onClickEditButton={onSubmit} />}
      />
      <HalfPopUp
        width={'30rem'}
        height={'17.5rem'}
        top={'40%'}
        right={'50%'}
        ref={confirmRef.ref}
        contents={<ConfirmPopUp confirmButton={handleConfirmButton} />}
      />
      <HalfPopUp
        width={'30rem'}
        height={'auto'}
        top={'40%'}
        right={'50%'}
        ref={boardingRef.ref}
        contents={
          <BoardingPopUp
            onClose={onClickCancelButton}
            onClickEditButton={changeBoardingButton}
            name={studentName}
            status={boardingStatus}
          />
        }
      />
    </>
  )
}

const OperationDetailContainer = forwardRef(OperationDetailContainerBase)
export default OperationDetailContainer

const OperationEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  background: #f7f7f7;
  padding: 1.6rem 2rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

const Button = styled.button<{editable: boolean}>`
  width: 4.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2.2rem;
  cursor: ${props => (props.editable ? 'pointer' : 'not-allowed')};
  background-color: ${props => (props.editable ? '#444444' : color.grey2)};
  border: none;
`

const CancelButton = styled(Button)`
  background: #e9e9e9;
  border: 0.1rem solid #e9e9e9;
  color: #000;
`
