import React from 'react'
import styled from 'styled-components'
import {ModalButton, flexColumn} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import CloseButton from '../../../asset/image/close_button.svg'

type PopUpProps = {
  onClose?: () => void
  onSubmitButton: () => void
}

export function RegisterDispatchPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>호차 등록</Title>
      <SubTitle>호차를 등록하시겠습니까?</SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <ConfirmButton onClick={props.onSubmitButton}>등록</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 3rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 3rem;
  position: relative;
`
const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`
const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
`
const SubTitle = styled.div`
  font-size: 1.4rem;
  margin-bottom: 2rem;
  line-height: 2rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`
const CancelButton = styled(ModalButton)``
const ConfirmButton = styled(ModalButton)`
  background: #ffcd00;
`
