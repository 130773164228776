import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, flexColumn} from '../../../../style/CommonStyle'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import {Optional} from '../../../../type/Common'
import DispatchList from '../list/DispatchList'
import {isNil} from '../../../../util/ValidationUtil'
import DispatchEmptyList from './DispatchEmptyList'

type Props = {
  showToast(t: string): void
}
export type DispatchListContainerRef = {
  setDispatchPeriod(v: CDispatchPeriod): void
}

function DispatchListContainerBase(
  props: Props,
  ref: ForwardedRef<DispatchListContainerRef>,
): ReactElement {
  const [dispatchPeriod, setDispatchPeriod] =
    useState<Optional<CDispatchPeriod>>(null)

  const ListComponent = useMemo(() => {
    if (isNil(dispatchPeriod)) {
      return <DispatchEmptyList />
    }

    return (
      <DispatchList
        dispatchPeriod={dispatchPeriod}
        showToast={props.showToast}
      />
    )
  }, [dispatchPeriod])

  useImperativeHandle(
    ref,
    () => ({
      setDispatchPeriod(v: CDispatchPeriod): void {
        setDispatchPeriod(v)
      },
    }),
    [setDispatchPeriod],
  )

  return <Container>{ListComponent}</Container>
}

const DispatchListContainer = forwardRef(DispatchListContainerBase)
export default DispatchListContainer

const Container = styled.div`
  width: 100%;
  ${flexColumn};
  background: ${color.white};
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  padding: 1.6rem;
  overflow: hidden;
`
