import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router'
import ScreenContainer from '../../component/layout/ScreenContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import {flexColumn} from '../../style/CommonStyle'
import SearchManager from '../../component/managerManagement/searchWrapper/SearchManager'
import ManagerList, {
  ManagerListRef,
} from '../../component/managerManagement/managerList/ManagerList'
import useSecureRef from '../../hook/useSecureRef'
import {isNil} from '../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../type/Common'
import {academyIDState} from '../../recoil/Atom'
import {getManagers, GetManagersData} from '../../service/manager/Manager'
import {CManager} from '../../model/Manager'
import Toast from '../../component/toast/Toast'

type ManagerManagementProps = {}
export type ManagerManagementRef = {}

const PER_PAGE = 12

export type Manager = {
  originalManagers: CManager[]
  filteredManagers: CManager[]
}

type Props = {}
export type StateProps = {
  name: string
}

function ManagerManagementBase(
  props: ManagerManagementProps,
  ref: ForwardedRef<ManagerManagementRef>,
) {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as StateProps

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const listRef = useSecureRef<ManagerListRef>(
    '[ManagerManagement.tsx] listRef',
  )
  const [managerState, setManagerState] = useState<Manager>({
    originalManagers: [],
    filteredManagers: [],
  })
  const [totalElements, setTotalElements] = useState<Optional<number>>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [select, setSelect] = useState<string>('NAME')
  const [keyword, setKeyword] = useState<string>('')
  const [managerName, setManagerName] = useState<Optional<string>>(null)

  const fetchManagerList = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetManagersData = {
      academyID: selectedAcademyID,
      page: currentPage,
      searchType: select,
      searchValue: keyword,
      size: PER_PAGE,
    }

    getManagers(data)
      .then(ml => {
        setManagerState({
          originalManagers: ml.managers,
          filteredManagers: ml.managers,
        })
        setTotalElements(ml.paging.totalElements)
      })
      .catch(error => {
        throw new Error(
          `failed to get manager list. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [selectedAcademyID, currentPage, keyword])

  useEffect(() => {
    if (!isNil(state)) {
      setManagerName(state?.name)
    }
  }, [setManagerName])

  useEffect(() => {
    setCurrentPage(0)
  }, [keyword, selectedAcademyID])

  useEffect(() => {
    fetchManagerList()
  }, [selectedAcademyID, currentPage, keyword, fetchManagerList])

  useEffect(() => {
    if (!isNil(managerName)) {
      const timeoutId = setTimeout(() => {
        setManagerName(null)
        navigate('/manager', {state: {name: null}})
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [setManagerName, managerName])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const savedPageIdx = urlParams.get('page')

    if (savedPageIdx !== null) {
      const pageIndex = parseInt(savedPageIdx) - 1
      const timer = setTimeout(() => {
        setCurrentPage(pageIndex)
      }, 100)
      return () => clearTimeout(timer)
    } else {
      setCurrentPage(0)
    }
  }, [])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>매니저 관리</PageHeaderWithAcademies>
      <Container>
        <SearchManager
          setSelect={setSelect}
          setKeyword={setKeyword}
          keyword={keyword}
          fetchManagerList={fetchManagerList}
        />
        <ManagerList
          ref={listRef.ref}
          managerState={managerState}
          PER_PAGE={PER_PAGE}
          totalElements={totalElements}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
      {!isNil(managerName) && (
        <Toast
          text={`${managerName} 매니저님이 탈퇴 처리 되었습니다.`}
          status={!isNil(managerName) ? 'toast' : ''}
        />
      )}
    </ScreenContainer>
  )
}

const ManageManagement = forwardRef(ManagerManagementBase)
export default ManageManagement

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 13.8rem);
`
