import React, {ReactNode} from 'react'
import styled from 'styled-components'

type HeaderProps = {
  className?: string
  children?: ReactNode
}

export default function Header(props: HeaderProps) {
  return <HeaderText className={props.className}>{props.children}</HeaderText>
}

const HeaderText = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
`
