import React from 'react'
import styled from 'styled-components'
import {flexColumn, ModalButton} from '../../style/CommonStyle'
import CloseButton from '../../asset/image/close_button.svg'
import SVGImage from '../common/SVGImage'
import {Optional} from '../../type/Common'

type PopUpProps = {
  onClose?: () => void
  onClickEditButton?: () => void
  confirmButton?: () => void
  date?: string
  name?: Optional<string>
  status?: Optional<number>
}

export function CancelPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>취소 확인</Title>
      <SubTitle>수정을 취소하시겠습니까?</SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
        <ConfirmButton onClick={props.onClickEditButton}>예</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function BoardingPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차 여부 변경</Title>
      <SubTitle>{props.name} 학생을</SubTitle>
      <SubTitle
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <DeleteDate>{`${
          props.status === 0 ? `'타요'` : `'안타요'`
        }`}</DeleteDate>
        &nbsp;로 변경하시겠습니까?
      </SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <ConfirmButton onClick={props.onClickEditButton}>변경</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function SavePopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>저장 확인</Title>
      <SubTitle>저장 하시겠습니까?</SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
        <ConfirmButton onClick={props.onClickEditButton}>예</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function ConfirmPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>저장 확인</Title>
      <SubTitle>저장되었습니다.</SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.confirmButton}>확인</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function CancelRegisterPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차권 등록 취소</Title>
      <SubTitle>
        승차권 등록을 취소하시겠습니까?
        <br />
        지금까지 입력한 정보는 저장되지 않습니다.
      </SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.onClickEditButton}>예</ConfirmButton>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
      </ButtonWrapper>
    </Container>
  )
}

export function RegisterTicketPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차권 등록</Title>
      <SubTitle>승차권을 등록하시겠습니까?</SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <ConfirmButton onClick={props.onClickEditButton}>등록</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function RegisterTicketConfirmPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차권 등록</Title>
      <SubTitle>승차권이 등록되었습니다.</SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.confirmButton}>확인</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function DeleteTicketPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차권 삭제</Title>
      <SubTitle>
        <DeleteDateWrapper>
          <DeleteDate>{props.date}</DeleteDate>부터
        </DeleteDateWrapper>
        등록된 모든 승차권이 삭제됩니다.
        <br />
        승차권을 삭제하시겠습니까?
      </SubTitle>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <ConfirmButton onClick={props.onClickEditButton}>삭제</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export function DeleteTicketConfirmPopUp(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>승차권 삭제 완료</Title>
      <SubTitle style={{marginBottom: '3rem'}}>
        승차권이 삭제되었습니다.
      </SubTitle>
      <ButtonWrapper>
        <ConfirmButton onClick={props.confirmButton}>확인</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 3rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 3rem;
  position: relative;
`
const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`
const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
`
const SubTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2.5rem;
`
const DeleteDateWrapper = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
`
const DeleteDate = styled.div`
  color: #ff7900;
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.4rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
  margin-top: 2rem;
`
const CancelButton = styled(ModalButton)``
const ConfirmButton = styled(ModalButton)`
  background: #ffcd00;
`
