import React, {Dispatch, SetStateAction, useMemo, useState} from 'react'
import styled from 'styled-components'
import SVGImage from './SVGImage'
import LeftArrow from '../../asset/image/left_arrow.svg'
import DoubleLeftArrow from '../../asset/image/double_left_arrow.svg'
import RightArrow from '../../asset/image/right_arrow.svg'
import DoubleRightArrow from '../../asset/image/double_right_arrow.svg'
import {useSearchParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

type PaginationProps = {
  num: number
  perPage: number
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  pageExposeCount: number
  backgroundColor?: string
  marginTop?: string
  height?: string
  borderRadius?: string
  columnGap?: string
}

function Pagination(props: PaginationProps) {
  const location: any = useLocation()
  const pageList: number[] = []
  const totalPages = Math.ceil(props.num / props.perPage)
  const [searchParams, setSearchParams] = useSearchParams()

  for (let i = 1; i <= totalPages; i++) {
    pageList.push(i)
  }

  const goToFirstPage = () => {
    if (props.currentPage <= 0) {
      return
    }

    props.setCurrentPage(0)
  }

  const goToLastPage = () => {
    if (props.currentPage >= totalPages - 1) {
      return
    }

    props.setCurrentPage(totalPages - 1)
  }

  const goToNextPage = () => {
    if (props.currentPage >= totalPages - 1) {
      return
    }
    props.setCurrentPage(props.currentPage + 1)
  }

  const goToPrevPage = () => {
    if (props.currentPage <= 0) {
      return
    }

    props.setCurrentPage(props.currentPage - 1)
  }

  const handleCurrentPageNumber = (idx: number) => {
    const pageNumber = idx + 1

    if (!location.pathname.includes('/bus/')) {
      setSearchParams({page: pageNumber.toString()})
    }
    props.setCurrentPage(idx)
  }

  const pageNumbers = useMemo(() => {
    const halfWay = Math.floor(props.pageExposeCount / 2)
    let startPage = Math.max(props.currentPage + 1 - halfWay, 1)
    let endPage = startPage + props.pageExposeCount - 1

    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - props.pageExposeCount + 1, 1)
    }

    return Array.from(
      {length: endPage - startPage + 1},
      (_, i) => startPage + i,
    )
  }, [props.currentPage, totalPages, props.pageExposeCount])

  return (
    <Container
      backgroundColor={props.backgroundColor}
      marginTop={props.marginTop}
      height={props.height}
      borderRadius={props.borderRadius}
      columnGap={props.columnGap}>
      <ImgWrapper onClick={goToFirstPage}>
        <Img source={DoubleLeftArrow} />
      </ImgWrapper>
      <ImgWrapper onClick={goToPrevPage}>
        <Img source={LeftArrow} />
      </ImgWrapper>

      {pageNumbers.map((page: number, idx: number) => (
        <PageNumber
          currentPage={props.currentPage}
          page={page}
          key={idx}
          onClick={() => handleCurrentPageNumber(page - 1)}>
          {page}
        </PageNumber>
      ))}
      <ImgWrapper onClick={goToNextPage}>
        <Img source={RightArrow} />
      </ImgWrapper>
      <ImgWrapper onClick={goToLastPage}>
        <Img source={DoubleRightArrow} />
      </ImgWrapper>
    </Container>
  )
}

export default Pagination

type ContainerProps = {
  backgroundColor?: string
  marginTop?: string
  height?: string
  borderRadius?: string
  columnGap?: string
}

const Container = styled.div<ContainerProps>`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  height: 2.2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  column-gap: ${props => (props.columnGap ? props.columnGap : '2rem')};
  height: ${props => (props.height ? props.height : 'auto')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '1.3rem')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '')};
`

const ImgWrapper = styled.div`
  width: 1.4rem;
  height: 1.4rem;
`
const Img = styled(SVGImage)`
  width: 100%;
  height: 100%;
`

type PageNumberProps = {
  currentPage: number
  page: number
}

const PageNumber = styled.div<PageNumberProps>`
  font-size: 1.1rem;
  background: ${props =>
    props.currentPage + 1 === props.page ? '#ffcd00' : ''};
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 100%;
  text-align: center;
  font-weight: ${props =>
    props.currentPage + 1 === props.page ? '800' : '300'};
  color: ${props => (props.currentPage + 1 === props.page ? '#000' : '#999')};
  line-height: 1.8rem;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`
