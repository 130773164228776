import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../style/CommonStyle'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import {CDispatchCourseWithStudent} from '../../../../../../../model/Dispatch'
import CourseContainer from './course/CourseContainer'
import {OccupancyTypeEnum} from '../../../../../../../enum/OccupancyTypeEnum'
import {CCourse} from '../../../../../../../model/Course'
import {Optional} from '../../../../../../../type/Common'
import {isNil} from '../../../../../../../util/ValidationUtil'
import DispatchInfoHeader from '../common/DispatchInfoHeader'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
  postDispatchesCoursesStudents,
  PostDispatchesCoursesStudentsData,
} from '../../../../../../../service/dispatch/Dispatch'
import {throwError} from '../../../../../../../util/ErrorUtil'
import {StudentLoadTypeEnum} from '../../../../../../../enum/StudentLoadTypeEnum'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import LoadStudentNormal from '../../../common/loadStudent/normal/LoadStudentNormal'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../../../table/PeriodTable'

type Props = {
  defaultDispatchCourses: CDispatchCourseWithStudent[]
  dispatchCode: string
  occupancyType: OccupancyTypeEnum
  selectedCourse: Optional<CCourse>
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
}

export default function DispatchInfoNormal(props: Props) {
  const academyID = useRecoilValue(academyIDState)
  const loadStudentRef = useSecureRef<ModalRef>(
    '[DispatchInfoNormal.tsx] loadStudentRef',
  )

  const defaultDcs: CDispatchCourseWithStudent[] = useMemo(() => {
    const dcs = props.defaultDispatchCourses

    if (
      props.occupancyType === OccupancyTypeEnum.NORMAL ||
      props.occupancyType === OccupancyTypeEnum.FULL_SEAT
    ) {
      return dcs
    }

    return dcs.map(d => {
      return {
        ...d,
        students: [],
      }
    })
  }, [props.occupancyType, props.defaultDispatchCourses])

  const [dispatchCourses, setDispatchCourses] =
    useState<CDispatchCourseWithStudent[]>(defaultDcs)

  // const [dispatchCourses, setDispatchCourses] = useState<
  //   CDispatchCourseWithStudent[]
  // >(props.defaultDispatchCourses)

  const onClickLoadCourse = useCallback(() => {
    if (isNil(props.selectedCourse)) {
      alert('운행 노선을 선택해주세요.')
      return
    }

    loadStudentRef.current().show()
  }, [props.selectedCourse])

  const hideStudentLoadModal = useCallback(() => {
    loadStudentRef.current().hide()
  }, [])

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          console.log(cws)
          setDispatchCourses(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setDispatchCourses],
  )

  const onSubmit = useCallback(() => {
    if (isNil(props.selectedCourse)) {
      alert('노선을 선택해주세요.')
      return
    }

    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.selectedCourse.code,
      type: StudentLoadTypeEnum.EDIT,
    }

    fetchCourses(data)
  }, [props.dispatchCode, props.selectedCourse, academyID, fetchCourses])

  const initCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          const cwsAppliedDefaultCourses = cws.map(c => {
            const defaultCourse = props.defaultDispatchCourses.find(
              dc => dc.stationID === c.stationID,
            )
            if (defaultCourse) {
              return {
                ...c,
                students: c.students.map(s => {
                  const defaultStudent = defaultCourse.students.find(
                    ds => ds.id === s.id,
                  )
                  return {
                    ...s,
                    check: defaultStudent ? defaultStudent.check : false,
                  }
                }),
              }
            }
            return {
              ...c,
              students: c.students.map(s => ({...s, check: false})),
            }
          })
          setDispatchCourses(cwsAppliedDefaultCourses)

          const dataForRedis: PostDispatchesCoursesStudentsData = {
            dispatchCode: data.dispatchCode,
            academyID: academyID,
            courseCode: data.courseCode,
            courses: cwsAppliedDefaultCourses,
            type: data.type,
          }
          postDispatchesCoursesStudents(dataForRedis)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setDispatchCourses],
  )

  useEffect(() => {
    if (isNil(props.selectedCourse)) {
      return
    }

    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.selectedCourse?.code,
      type: StudentLoadTypeEnum.EDIT,
    }

    initCourses(data)
  }, [props.selectedCourse])

  return (
    <Container>
      <Modal ref={loadStudentRef.ref}>
        <LoadStudentNormal
          dispatchCode={props.dispatchCode}
          course={props.selectedCourse}
          studentLoadType={StudentLoadTypeEnum.EDIT}
          onCancel={hideStudentLoadModal}
          onSubmit={onSubmit}
          selectedBusInfo={props.selectedBusInfo}
          defaultDispatchCourses={props.defaultDispatchCourses}
        />
      </Modal>

      <DispatchInfoHeader onClickLoad={onClickLoadCourse} />
      <CourseContainer
        selectedCourse={props.selectedCourse}
        dispatchCourses={dispatchCourses}
        occupancyType={props.occupancyType}
        dispatchPeriod={props.dispatchPeriod}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`
