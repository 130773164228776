import React, {ChangeEvent, Dispatch, SetStateAction, useCallback} from 'react'
import styled from 'styled-components'
import {Button, color, flexColumn, flexRow} from '../../../style/CommonStyle'
import CloseButton from '../../../asset/image/close_button.svg'
import SVGImage from '../../common/SVGImage'
import {Optional} from '../../../type/Common'
import {orElse} from '../../../util/ValidationUtil'
import NumberInput, {NumberInputRef} from '../../input/NumberInput'
import useSecureRef from '../../../hook/useSecureRef'

export type ModalContainerRef = {
  show(): void
  hide(): void
}

type PopUpProps = {
  totalSeat: number
  originalTotalSeat: number
  setValidSeat?: Dispatch<SetStateAction<Optional<number>>>
  onClose?: () => void
  confirmButton?: () => void
  error: Optional<string>
}

function ChangeTotalSeat(props: PopUpProps) {
  const seatRef = useSecureRef<NumberInputRef>(
    '[DispatchBusAddInputContainer.tsx] seatRef',
  )
  const handleChangePhone = useCallback((value: Optional<number>) => {
    props.setValidSeat(Number(value))
  }, [])

  const error = props.error?.split(':')

  return (
    <Container>
      <ImgWrapper onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </ImgWrapper>
      <Header>가용 좌석 수정</Header>
      <Noti>
        • <OrangeText>*</OrangeText> 는 필수 입력 항목입니다.
      </Noti>
      <NameWrapper>
        <OriginalInputWrapper>
          <SubTitle>기존 좌석 정보</SubTitle>
          <OriginalValue>{props.totalSeat}개</OriginalValue>
        </OriginalInputWrapper>
        <Wrapper>
          <BusTotalSeat>
            <ChangeInputWrapper error={props.error}>
              <ChangeSubTitle>
                변경할 좌석 정보<OrangeText>*</OrangeText>
              </ChangeSubTitle>
            </ChangeInputWrapper>
            <NumberInput
              ref={seatRef.ref}
              readOnly={false}
              required={true}
              placeholder={'변경할 좌석 정보를 입력하세요.'}
              onChange={handleChangePhone}
            />
            <SeatText>개</SeatText>
          </BusTotalSeat>
          {props.error !== null && <Text>{error[1]}</Text>}
        </Wrapper>
      </NameWrapper>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <RegisterButton
          disabled={props.originalTotalSeat === null}
          onClick={props.confirmButton}>
          확인
        </RegisterButton>
      </ButtonWrapper>
    </Container>
  )
}

export default ChangeTotalSeat

const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 1.6rem;
  position: relative;
`

const ImgWrapper = styled.div``

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
`

const Noti = styled.div`
  height: 3rem;
  background: #ebebeb;
  padding: 0.8rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: left;
  color: #999;
`

const OrangeText = styled.span`
  color: ${color.orange};
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const NameWrapper = styled.div`
  ${flexColumn};
  background: #f5f5f5;
  padding: 2rem;
  row-gap: 1.2rem;
`

const OriginalInputWrapper = styled.div`
  ${flexRow};
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  padding: 0.5rem 0 0.5rem 1.2rem;
`

const SubTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
  width: 12.8rem;
  text-align: left;
  border-right: 0.1rem solid #d9d9d9;
  margin-right: 0.6rem;
`

const OriginalValue = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
`

const Wrapper = styled.div`
  ${flexColumn};
  text-align: left;
`

const BusTotalSeat = styled.div`
  ${flexRow};
  align-items: center;
`

const SeatText = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
`

type ErrorProps = {
  error: Optional<string>
}

const ChangeInputWrapper = styled(OriginalInputWrapper)<ErrorProps>`
  border-color: ${props => (props.error === null ? '#ebebeb' : '#ED766E')};
  background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};
  border-radius: 0.6rem;
  max-height: 3rem;

  > input {
    background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};
    color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
  }

  :focus-within {
    background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
    border-color: ${props => (props.error === null ? '#ffe880' : '#ED766E')};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);

    > input {
      background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
      color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
    }
  }
`

const Text = styled.div`
  color: #e74d41;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  padding-left: 1.2rem;
  margin-top: 0.6rem;
`

const ChangeSubTitle = styled(SubTitle)`
  color: #332a00;
  min-width: 12.8rem;
  border-right: none;
  margin-right: 0;
`

const Input = styled.input`
  border: none;
  font-size: 1.3rem;
  width: 100%;
  max-width: 19.6rem;

  :focus {
    background: #fffbe5;
  }
`
const ButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.6rem;
  justify-content: center;
  padding: 1.2rem;
`

const CancelButton = styled(Button)``

type ButtonProps = {
  disabled?: boolean
}

const RegisterButton = styled(Button)<ButtonProps>`
  background: ${props => (props.disabled ? '#FFF1B3' : '#ffcd00')};
  color: ${props => (props.disabled ? '#cccccc' : '#000')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
