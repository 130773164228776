import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router'
import {PathEnum} from './enum/PathEnum'
import OperationControl from './page/operationControl/OperationControl'
import TicketManagement from './page/ticketManagement/TicketManagement'
import PrivateRoute from './component/route/PrivateRoute'
import ManagerManagement from './page/managerManagement/ManagerManagement'
import ManagerDetail from './component/managerManagement/managerDetail/ManagerDetail'
import DriverManagement from './page/driverManagement/DriverManagement'
import DriverDetail from './component/driverManagement/driverDetail/DriverDetail'
import BusesManagement from './page/busesManagement/BusesManagement'
import Dispatch from './page/dispatch/Dispatch'
import StationManagement from './page/stationManagement/StationManagement'
import BusDetail from './page/busDetail/BusDetail'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {academyState, academyIDState} from './recoil/Atom'
import {getAcademy} from './service/academy/Academy'
import StudentManagement from './page/studentManagement/StudentManagement'

export default function RouteContainer() {
  const academyID = useRecoilValue(academyIDState)
  const setAcademy = useSetRecoilState(academyState)

  useEffect(() => {
    if (academyID !== null) {
      getAcademy(academyID)
        .then(res => {
          console.log(res)
          setAcademy(res)
        })
        .catch(error => {
          throw new Error(
            `getAcademy() failed. (aid: ${academyID}, error: ${error})`,
          )
        })
    }
  }, [academyID])

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path={PathEnum.CONTROL.path} element={<OperationControl />} />
        <Route path={PathEnum.TICKET.path} element={<TicketManagement />} />
        <Route path={PathEnum.BUSES.path} element={<BusesManagement />} />
        <Route path={PathEnum.BUS_DETAIL.path} element={<BusDetail />} />
        <Route path={PathEnum.DISPATCH.path} element={<Dispatch />} />
        <Route path={PathEnum.DRIVER.path} element={<DriverManagement />} />
        <Route path={PathEnum.DRIVER_DETAIL.path} element={<DriverDetail />} />
        <Route path={PathEnum.MANAGER.path} element={<ManagerManagement />} />
        <Route path={PathEnum.STUDENT.path} element={<StudentManagement />} />
        <Route
          path={PathEnum.MANAGER_DETAIL.path}
          element={<ManagerDetail />}
        />
        <Route path={PathEnum.STATION.path} element={<StationManagement />} />
      </Route>
    </Routes>
  )
}
