import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../../style/CommonStyle'
import Footer from '../../../../../../../common/Footer'
import {Optional} from '../../../../../../../../type/Common'
import {CDispatchCourseWithStudent} from '../../../../../../../../model/Dispatch'
import BusSelectContainer from './BusSelectContainer'
import ExtraBusHeader from './ExtraBusHeader'
import StationSelectContainer from './StationSelectContainer'
import {DispatchTypeEnum} from '../../../../../../../../enum/DispatchTypeEnum'
import {CCourse} from '../../../../../../../../model/Course'
import {isNil} from '../../../../../../../../util/ValidationUtil'
import {BusInfo} from '../../../DispatchEdit'

type Props = {
  dispatchCourses: CDispatchCourseWithStudent[]
  dispatchPeriodID: number
  dispatchCode: string
  course: Optional<CCourse>
  extraCourse: Optional<CCourse>
  busName: string
  extraBusName: Optional<string>
  dispatchType: DispatchTypeEnum
  onCancel(): void
  onSubmit(c: CCourse, ec: CCourse, bi: BusInfo): void
}

export type CourseRouteWithCheck = {
  isChecked: boolean
  sort: number
  spendTime: number
  course: CDispatchCourseWithStudent
}

export default function ExtraBusAdd(props: Props) {
  const [course, setCourse] = useState<Optional<CCourse>>(null)
  const [extraCourse, setExtraCourse] = useState<Optional<CCourse>>(null)
  const [extraBusInfo, setExtraBusInfo] = useState<Optional<BusInfo>>(null)

  const onSubmit = useCallback(() => {
    props.onSubmit(course, extraCourse, extraBusInfo)
  }, [props.onSubmit, course, extraCourse, extraBusInfo])

  const onChangeCourse = useCallback(
    (c: CCourse) => {
      if (c.name === extraCourse?.name) {
        alert(
          '같은 노선은 등록할 수 없습니다.\n같은 노선 사용을 원하실 경우 해당 노선을 복제하여 이용해 주세요.',
        )
        return
      }
      setCourse(c)
    },
    [setCourse, extraCourse],
  )

  const onChangeExtraCourse = useCallback(
    (c: CCourse) => {
      if (c.name === course?.name) {
        alert(
          '같은 노선은 등록할 수 없습니다.\n같은 노선 사용을 원하실 경우 해당 노선을 복제하여 이용해 주세요.',
        )
        return
      }
      setExtraCourse(c)
    },
    [setExtraCourse, course],
  )

  const onChangeExtraBus = useCallback(
    (bi: BusInfo) => {
      setExtraBusInfo(bi)
    },
    [setExtraBusInfo],
  )

  return (
    <Container>
      <ExtraBusHeader />
      <ContentContainer>
        <BusSelectContainer
          defaultBusName={props.busName}
          onChange={onChangeExtraBus}
          dispatchPeriodID={props.dispatchPeriodID}
          extraBusName={extraBusInfo?.name}
        />
        <StationSelectContainer
          busName={props.busName}
          course={course}
          extraCourse={extraCourse}
          extraBusName={extraBusInfo?.name}
          dispatchType={props.dispatchType}
          dispatchCode={props.dispatchCode}
          onChangeCourse={onChangeCourse}
          onChangeExtraCourse={onChangeExtraCourse}
        />
      </ContentContainer>
      <Footer
        submitText={'등록'}
        onCancel={props.onCancel}
        onSubmit={onSubmit}
        isSufficient={
          !isNil(course) && !isNil(extraCourse) && !isNil(extraBusInfo)
        }
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 1.6rem;
  background: #f5f5f5;
  padding: 1.6rem;
  overflow: auto;
`
