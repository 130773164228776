import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Picker, {toPickerOptions} from '../../../input/Picker'
import Calendar from '../../../../asset/image/calendar_blue.svg'
import styled from 'styled-components'
import SVGImage from '../../../common/SVGImage'
import {CDate} from '../../../../model/Date'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import {
  GetDispatchPeriodListData,
  getDispatchPeriods,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import {Optional} from '../../../../type/Common'

type Props = {
  year: Optional<number>
  onChangeDispatchPeriod(v: CDispatchPeriod): void
  reloadDispatchPeriod: boolean
}

export default function DispatchPeriodPicker(props: Props) {
  const academyID = useRecoilValue(academyIDState)

  const [dispatchPeriods, setDispatchPeriods] = useState<CDispatchPeriod[]>([])
  const onChangeDispatchPeriod = useCallback(
    (dp: CDispatchPeriod) => {
      props.onChangeDispatchPeriod(dp)
      const selectedPeriodId = dispatchPeriods.find(period => period === dp)
        ?.dispatchPeriod.id
      sessionStorage.setItem(
        'dyb-dispatchPeriod-id',
        selectedPeriodId.toString(),
      )
    },
    [props.onChangeDispatchPeriod, dispatchPeriods],
  )

  const getDefaultIndex = useCallback(
    (dps: CDispatchPeriod[]) => {
      const savedPeriodId = sessionStorage.getItem('dyb-dispatchPeriod-id')

      if (
        savedPeriodId !== '' &&
        savedPeriodId !== 'null' &&
        savedPeriodId !== null
        // dps[Number(savedPeriodId)] !== undefined
      ) {
        const savedPeriodIndex = dps.findIndex(
          period => period.dispatchPeriod?.id === Number(savedPeriodId),
        )
        if (savedPeriodIndex !== -1) {
          return Number(savedPeriodIndex)
        }
      }

      const now = CDate.now()
      const foundPeriod = dps.find(
        dp =>
          dp.dispatchPeriod?.startDate.isBefore(now) &&
          dp.dispatchPeriod?.endDate.isAfter(now),
      )

      const currentPeriodId = foundPeriod
        ? foundPeriod.dispatchPeriod?.id
        : undefined

      sessionStorage.removeItem('dyb-dispatchPeriod-id')

      const currentPeriodIndex = dps.findIndex(
        period => period.dispatchPeriod?.id === currentPeriodId,
      )

      // Return index or 0 if not found
      return currentPeriodIndex !== undefined && currentPeriodIndex !== -1
        ? currentPeriodIndex
        : 0
    },
    [props.year],
  )

  const fetchDispatchPeriods = useCallback(
    (aid: string, year: number) => {
      const data: GetDispatchPeriodListData = {
        academyID: aid,
        dispatchPeriodYear: year,
      }

      getDispatchPeriods(data)
        .then(dps => {
          if (window.location.href.includes('/station')) {
            dps.push({
              button: null,
              dispatchPeriod: {
                name: '전체 보기',
                startDate: CDate.now(),
                endDate: CDate.now(),
                id: -1,
                semesterID: null,
              },
              operation: null,
            })
          }
          setDispatchPeriods(dps)
          const idx = getDefaultIndex(dps)
          props.onChangeDispatchPeriod(dps[idx])
        })
        .catch(error => {
          throw new Error(
            `getDispatchPeriods() failed. (error: ${error}, aid: ${aid}, sy:${year})`,
          )
        })
    },
    [
      setDispatchPeriods,
      getDefaultIndex,
      props.onChangeDispatchPeriod,
      props.year,
    ],
  )

  const boxStyle: CSSProperties = useMemo(() => {
    return {
      border: 'none',
    }
  }, [])

  const dispatchPeriodOptions = useMemo(() => {
    return toPickerOptions(
      dispatchPeriods,
      v => (
        <PickerOptionTextContainer>
          <PickerOptionText1>{v.dispatchPeriod.name}</PickerOptionText1>
          <PickerOptionText2
            style={
              v.dispatchPeriod.id === -1
                ? {
                    display: 'none',
                  }
                : {}
            }>
            기간: {v.dispatchPeriod.startDate.toString()} ~{' '}
            {v.dispatchPeriod.endDate.toString()}
          </PickerOptionText2>
        </PickerOptionTextContainer>
      ),
      v => (
        <PickerBoxText style={{fontSize: 13, color: '#2767CE'}}>
          {v.dispatchPeriod.name}
          <span
            style={
              v.dispatchPeriod.id === -1
                ? {
                    color: 'transparent',
                  }
                : {}
            }>
            | {v.dispatchPeriod.startDate.toString()} ~{' '}
            {v.dispatchPeriod.endDate.toString()}
          </span>
        </PickerBoxText>
      ),
    )
  }, [dispatchPeriods, props.onChangeDispatchPeriod])

  useEffect(() => {
    if (isNil(props.year)) {
      return
    }
    setDispatchPeriods([])
    fetchDispatchPeriods(academyID, props.year)
  }, [academyID, props.year, props.reloadDispatchPeriod])

  if (isEmptyArray(dispatchPeriods)) {
    return null
  }

  return (
    <Picker
      defaultIdx={getDefaultIndex(dispatchPeriods)}
      minWidth={200}
      height={34}
      placeholder={'학기와 배차기간을 선택해주세요.'}
      options={dispatchPeriodOptions}
      extraNode={<CalendarImage source={Calendar} />}
      onChange={onChangeDispatchPeriod}
      boxStyle={boxStyle}
      zIndex={2}
    />
  )
}

const CalendarImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const PickerBoxText = styled.pre`
  span {
    font-size: 1.3rem;
  }
`

const PickerOptionTextContainer = styled.div`
  padding: 0.6rem 1.2rem;
`

const PickerOptionText1 = styled.div`
  font-size: 1.3rem;
  font-style: normal;
  line-height: 150%;
`

const PickerOptionText2 = styled.div`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #999999;
`
