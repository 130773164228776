import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import RadioOff from '../../../../asset/image/radio_off.svg'
import RadioOn from '../../../../asset/image/radio_on.svg'
import {Optional} from '../../../../type/Common'
import {isNil} from '../../../../util/ValidationUtil'
import {CDispatchPeriodExist} from '../../../../model/DispatchPeriod'

type Props = {
  dispatchPeriodExists: CDispatchPeriodExist[]
  onSubmit(agree: boolean): void
  onCancel(): void
}

export default function DispatchPeriodAddExist(props: Props): ReactElement {
  const [agreeOverlap, setAgreeOverlap] = useState<Optional<boolean>>(null)

  const agreeButton = useMemo(() => {
    if (agreeOverlap) {
      return <StyledRadioButton source={RadioOn} />
    }

    return (
      <RadioButtonContainer>
        <StyledRadioButton source={RadioOff} />
      </RadioButtonContainer>
    )
  }, [agreeOverlap])

  const disagreeButton = useMemo(() => {
    if (!isNil(agreeOverlap) && !agreeOverlap) {
      return <StyledRadioButton source={RadioOn} />
    }

    return (
      <RadioButtonContainer>
        <StyledRadioButton source={RadioOff} />
      </RadioButtonContainer>
    )
  }, [agreeOverlap])

  const overlapText = useMemo(() => {
    return props.dispatchPeriodExists
      .map(
        dpe =>
          `${
            dpe.dispatchPeriodName
          }(${dpe.startDate.toString()} ~ ${dpe.endDate.toString()})`,
      )
      .join('\n')
  }, [props.dispatchPeriodExists])

  const onSubmit = useCallback(() => {
    props.onSubmit(agreeOverlap)
  }, [props.onSubmit, agreeOverlap])

  return (
    <Container>
      <ContentContainer>
        <Header>배차 기간 중복 선택</Header>
        <Text1>추가하려는 배차 기간에 이미 등록된 배차기간이 있습니다.</Text1>
        <Text2>
          {`추가하려는 배차를 사용하려면 아래의 두 가지 방법 중 
하나를 선택하여 사용해야 합니다.`}
        </Text2>
        <Text3Container>
          <Text3>중복기간 : </Text3>
          <Text3>{overlapText}</Text3>
        </Text3Container>
        <RadioContainer>
          <Text4Container onClick={() => setAgreeOverlap(true)}>
            {agreeButton}
            <Text4>기존 배차와 추가하려는 배차를 별도로 모두 사용</Text4>
          </Text4Container>
          <Text4Container onClick={() => setAgreeOverlap(false)}>
            {disagreeButton}
            <Text4>기존 배차를 추가하려는 배차로 변경</Text4>
          </Text4Container>
        </RadioContainer>

        <ButtonContainer>
          <CancelButton onClick={props.onCancel}>취소</CancelButton>
          <ConfirmButton isSelected={!isNil(agreeOverlap)} onClick={onSubmit}>
            등록
          </ConfirmButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}
const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  background: transparent;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
`

const ContentContainer = styled.div`
  width: 32rem;
  padding: 1.6rem 1.2rem;
  background: #ffffff;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.6rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
  z-index: 101;
`
const Header = styled.h5`
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  margin-bottom: 1.6rem;
`

const Text1 = styled.pre`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #1a468b;
  margin-bottom: 1.6rem;
`

const Text2 = styled.pre`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-bottom: 1.6rem;
  text-align: center;
  color: #1a468b;
`

const Text3Container = styled.div`
  ${flexRow};
  align-items: flex-start;
`

const Text3 = styled.pre`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #ff7500;
  margin-bottom: 1.6rem;
`

const Text4 = styled.div`
  color: #999999;
`

const ButtonContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
`

const ConfirmButton = styled.button<{isSelected?: boolean}>`
  ${flexRow};
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 3.4rem;
  color: #000000;
  border: none;
  font-size: 1.2rem;
  background: #ffcd00;
  font-weight: 800;
  border-radius: 3.4rem;
  opacity: ${props => (props.isSelected ? 1 : 0.2)};
  cursor: ${props => (props.isSelected ? 'pointer' : 'not-allowed')};
`
const CancelButton = styled(ConfirmButton)`
  background: #ebebeb;
  font-weight: 500;
  opacity: 1;
  cursor: pointer;
`

const RadioContainer = styled.div`
  margin-bottom: 1.5rem;
`

const Text4Container = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  margin-bottom: 0.9rem;
  cursor: pointer;
`

const QuestionMark = styled.div`
  background: #333333;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 10rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

const QuestionMarkText = styled.div`
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
`

const RadioButtonContainer = styled.div`
  cursor: pointer;
`

const StyledRadioButton = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
