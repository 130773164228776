import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TableList from '../../../common/TableList'
import {CBusBrief, CBusDispatchSummary} from '../../../../model/Bus'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import useSecureRef from '../../../../hook/useSecureRef'
import {
  getBusesDispatchList,
  GetBusesDispatchListRequestData,
} from '../../../../service/buses/Buses'
import {academyIDState} from '../../../../recoil/Atom'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../../type/Common'
import {CDate} from '../../../../model/Date'
import Modal, {ModalRef} from '../../../common/Modal'
import {CDispatchPeriodInfo} from '../../../../model/DispatchPeriod'
import DispatchDetailOneDay from './DispatchDetailOneDay'
import {DispatchOptionEnum} from '../../../../enum/DispatchOptionEnum'
import DateTableHeader from './DateTableHeader'
import {PickerOption} from '../../../input/Picker'

const TITLE_MAP = {
  dispatch: '배차명',
  manager: '매니저명',
  departureStationName: '시작 정류장',
  arrivalStationName: '마지막 정류장',
  seatInfo: '좌석 정보',
}

type Props = {
  date: CDate
  busBrief: CBusBrief
  selectedDispatchPeriod: Optional<CDispatchPeriodInfo>
  dispatchOptions: PickerOption<DispatchOptionEnum>[]
  dispatchPeriodOptions: PickerOption<CDispatchPeriodInfo>[]
  defaultDispatchIndex: number
  defaultDispatchPeriodIndex: number
  onChangeDispatch(d: DispatchOptionEnum): void
  onChangeDispatchPeriod(dp: CDispatchPeriodInfo): void
  onChangeDate(d: CDate): void
}

export default function DateTable(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const detailRef = useSecureRef<ModalRef>('[DateTable.tsx] detailRef')

  const [dispatches, setDispatches] = useState<CBusDispatchSummary[]>([])
  const [selectedDispatch, setSelectedDispatch] =
    useState<Optional<CBusDispatchSummary>>(null)

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return dispatches.map(d => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.dispatch, <div>{d.dispatch.name}</div>)
      node.set(TITLE_MAP.manager, <div>{d.manager.name}</div>)
      node.set(
        TITLE_MAP.departureStationName,
        <div>{d.dispatch.startStation}</div>,
      )
      node.set(TITLE_MAP.arrivalStationName, <div>{d.dispatch.endStation}</div>)
      node.set(
        TITLE_MAP.seatInfo,
        <div>
          잔여{d.seat.emptySeat}개, 타요{d.seat.willBoard}명, 안타요
          {d.seat.wontBoard}명
        </div>,
      )
      return node
    })
  }, [dispatches])

  const fetchDispatches = useCallback(
    (aid: string, bid: number, sd: CDate) => {
      const data: GetBusesDispatchListRequestData = {
        academyID: aid,
        busID: bid,
        searchDate: sd,
      }

      getBusesDispatchList(data)
        .then(dd => {
          setDispatches(dd)
        })
        .catch(error => {
          throw new Error(
            `getBusesDispatchList() failed. (academyID: ${academyID}, error: ${error})`,
          )
        })
    },
    [setDispatches],
  )

  const hideDetailModal = useCallback(() => {
    detailRef.current().hide()
  }, [])

  const onClickRow = useCallback(
    (n: number) => {
      if (isEmptyArray(dispatches)) {
        return
      }
      setSelectedDispatch(dispatches[n])

      detailRef.current().show()
    },
    [dispatches, setSelectedDispatch],
  )

  useEffect(() => {
    if (isNil(props.selectedDispatchPeriod)) {
      return
    }

    fetchDispatches(academyID, props.busBrief.bus.id, props.date)
  }, [academyID, props.busBrief, props.selectedDispatchPeriod, props.date])

  return (
    <Fragment>
      <Modal ref={detailRef.ref}>
        {isNil(selectedDispatch) ? null : (
          <DispatchDetailOneDay
            dispatchPeriod={props.selectedDispatchPeriod}
            busBrief={props.busBrief}
            dispatchSummary={selectedDispatch}
            hide={hideDetailModal}
            date={props.date}
          />
        )}
      </Modal>

      <DateTableHeader
        date={props.date}
        selectedDispatchPeriod={props.selectedDispatchPeriod}
        defaultDispatchIndex={props.defaultDispatchIndex}
        defaultDispatchPeriodIndex={props.defaultDispatchPeriodIndex}
        dispatchOptions={props.dispatchOptions}
        dispatchPeriodOptions={props.dispatchPeriodOptions}
        onChangeDispatch={props.onChangeDispatch}
        onChangeDispatchPeriod={props.onChangeDispatchPeriod}
        onChangeDate={props.onChangeDate}
      />
      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        tableHeaderStyle={{background: '#f5f5f5'}}
        placeholder={'등록된 배차 정보가 없습니다.'}
      />
    </Fragment>
  )
}
