import axios from 'axios'
import {API} from '../../Env'
import {AxiosEnum} from '../../enum/AxiosEnum'
import {
  CClassTime,
  COperationClass,
  CSemesterVector,
} from '../../model/Semester'
import {isNil} from '../../util/ValidationUtil'
import {ClassTimeByWeekday} from '../buses/Buses'

export type GetSemestersWithPagingData = {
  page?: number
  searchWord?: string
  size?: number
}

export async function getSemestersWithPaging(
  academyID: string,
  data: GetSemestersWithPagingData,
): Promise<CSemesterVector> {
  const url = `${API}/${academyID}/semesters`

  const config = {
    method: AxiosEnum.GET.value,
    params: data,
    url: url,
  }

  const axiosResponse = await axios(config)

  return CSemesterVector.create(axiosResponse.data.data)
}

export type GetSemesterTimeData = {
  academyID: string
  semesterID: number
}

export async function getSemesterTime(
  data: GetSemesterTimeData,
): Promise<CClassTime[]> {
  const url = `${API}/${data.academyID}/semesters/${data.semesterID}/time`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data.classTimes.map((ct: any) =>
    CClassTime.create(ct),
  )
}

export type GetSemesterOperationTimeData = {
  academyID: string
  semesterID: number
  busID: number
  dispatchPeriodID: number
}
export async function getSemesterOperationTime(
  data: GetSemesterOperationTimeData,
): Promise<COperationClass[]> {
  const url = `${API}/${data.academyID}/semesters/${data.semesterID}/operation/time`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      busId: data.busID,
      dispatchPeriodId: data.dispatchPeriodID,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data.data.classTimes)) {
    return []
  }

  return axiosResponse.data.data.classTimes.map((ct: any) =>
    COperationClass.create(ct),
  )
}

export type PostSemesterOperationTimeData = {
  academyID: string
  semesterID: number
  busID: number
  dispatchPeriodID: number
  invisible: ClassTimeByWeekday[]
}
export async function postSemesterOperationTime(
  data: PostSemesterOperationTimeData,
): Promise<void> {
  const url = `${API}/${data.academyID}/semesters/${data.semesterID}/operation/time`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    params: {
      busId: data.busID,
      dispatchPeriodId: data.dispatchPeriodID,
    },
    data: {
      dispatchPeriodId: data.dispatchPeriodID,
      invisible: data.invisible.map(el => {
        return {
          times: el.times,
          weekday: el.weekday.value,
        }
      }),
    },
  }

  await axios(config)
}
