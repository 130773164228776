import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, flexRow} from '../../../style/CommonStyle'
import Setting from '../../../asset/image/setting.svg'
import SVGImage from '../../common/SVGImage'
import DispatchHeaderPicker from './header/DispatchHeaderPicker'
import {CDispatchPeriod} from '../../../model/DispatchPeriod'
import useSecureRef from '../../../hook/useSecureRef'
import DispatchPeriodSetting from './setting/DispatchPeriodSetting'
import {getDispatchPeriodExtractYears} from '../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {isEmptyArray} from '../../../util/ValidationUtil'
import Modal, {ModalRef} from '../../common/Modal'

type Props = {
  onChangeDispatchPeriod(v: CDispatchPeriod): void
  selectedYear?: number
  setSelectedYear?: React.Dispatch<React.SetStateAction<number>>
}

export default function DispatchPeriod(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const modalRef = useSecureRef<ModalRef>('[DispatchPeriod.tsx] modalRef')
  const [years, setYears] = useState<number[]>([])
  const [reloadDispatchPeriod, setReloadDispatchPeriod] =
    useState<boolean>(false)

  const fetchDispatchPeriodYears = useCallback(() => {
    console.log('Fetching years')
    if (academyID !== null) {
      getDispatchPeriodExtractYears(academyID)
        .then(ys => {
          setYears(ys)
        })
        .catch(error => {
          throw new Error(
            `getDispatchPeriodExtractYears() failed. (error: ${error}, aid: ${academyID}) `,
          )
        })
    }
  }, [setYears, academyID])

  const showSettingModal = useCallback(() => {
    setReloadDispatchPeriod(false)
    modalRef.current().show()
  }, [])

  const hideSettingModal = useCallback(() => {
    setReloadDispatchPeriod(true)
    modalRef.current().hide()
  }, [setReloadDispatchPeriod])

  const HeaderPickerComponent = useMemo(() => {
    if (isEmptyArray(years)) {
      return <div />
    }

    return (
      <DispatchHeaderPicker
        years={years}
        onChangeDispatchPeriod={props.onChangeDispatchPeriod}
        reloadDispatchPeriod={reloadDispatchPeriod}
        selectedYear={props.selectedYear}
        setSelectedYear={props.setSelectedYear}
      />
    )
  }, [years, props.onChangeDispatchPeriod, reloadDispatchPeriod])

  useEffect(() => {
    fetchDispatchPeriodYears()
  }, [academyID])

  return (
    <Container>
      <Modal ref={modalRef.ref} zIndex={4}>
        <DispatchPeriodSetting
          fetchDispatchPeriodYears={fetchDispatchPeriodYears}
          onCancel={hideSettingModal}
          years={years}
        />
      </Modal>
      {HeaderPickerComponent}
      <RangeSettingButton onClick={showSettingModal}>
        <ButtonImage source={Setting}></ButtonImage>
        <ButtonText>배차 기간 설정</ButtonText>
      </RangeSettingButton>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 5.2rem;
  background: ${color.white};
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  padding: 0.8rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`

const RangeSettingButton = styled.button`
  width: 10.6rem;
  height: 2.6rem;
  border-radius: 3.4rem;
  border: none;
  background: #332a00;
  ${flexRow};
  align-items: center;
  justify-content: center;
  padding-right: 0.4rem;
`

const ButtonText = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
`
const ButtonImage = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  margin-right: 0.3rem;
`
