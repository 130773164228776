import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Picker, {toPickerOptions} from '../../../input/Picker'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import {CDate} from '../../../../model/Date'
import DispatchPeriodPicker from './DispatchPeriodPicker'
import {Optional} from '../../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'

type Props = {
  years: number[]
  onChangeDispatchPeriod(v: CDispatchPeriod): void
  reloadDispatchPeriod: boolean
  selectedYear?: number
  setSelectedYear?: React.Dispatch<React.SetStateAction<number>>
}

export default function DispatchHeaderPicker(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const defaultYearIdx = useMemo(() => {
    const savedPeriodYear = sessionStorage.getItem('dyb-dispatchPeriodYear')
    if (
      savedPeriodYear !== '' &&
      savedPeriodYear !== 'null' &&
      savedPeriodYear !== null
    ) {
      const savedPeriodYearIdx = props.years.findIndex(
        y => y === Number(savedPeriodYear),
      )
      if (savedPeriodYearIdx !== -1) {
        return savedPeriodYearIdx
      }
    }

    const now = CDate.now()
    const thisYear = now.toYear()

    if (props.years.findIndex(y => y === thisYear) !== -1) {
      sessionStorage.removeItem('dyb-dispatchPeriod-id')
      sessionStorage.removeItem('dyb-dispatchPeriodYear')

      const defaultYearIndex = props.years.findIndex(y => y === thisYear)
      sessionStorage.setItem('dyb-dispatchPeriodYear', `${thisYear}`)
      return defaultYearIndex
    } else {
      return 0
    }
  }, [props.years, academyID])

  const onChangeYear = useCallback(
    (v: number) => {
      props.setSelectedYear(v)
      sessionStorage.setItem('dyb-dispatchPeriodYear', v.toString())
      sessionStorage.removeItem('dyb-dispatchPeriod-id')
    },
    [props.years, props.setSelectedYear],
  )

  const yearOptions = useMemo(() => {
    return toPickerOptions(
      props.years,
      v => <div style={{fontSize: 13, paddingLeft: 6}}>{v}년</div>,
      v => <div style={{fontSize: 13}}>{v}년</div>,
    )
  }, [props.years])

  const boxStyle: CSSProperties = useMemo(() => {
    return {
      border: 'none',
    }
  }, [])

  useEffect(() => {
    props.setSelectedYear(props.years[defaultYearIdx])
  }, [props.years, academyID])

  return (
    <PickerContainer>
      <Picker
        defaultIdx={defaultYearIdx}
        options={yearOptions}
        minWidth={102}
        height={34}
        zIndex={2}
        placeholder={'선택해주세요.'}
        onChange={onChangeYear}
        boxStyle={boxStyle}
      />
      <DivideBar />
      <DispatchPeriodPicker
        year={props.selectedYear}
        onChangeDispatchPeriod={props.onChangeDispatchPeriod}
        reloadDispatchPeriod={props.reloadDispatchPeriod}
      />
    </PickerContainer>
  )
}

const PickerContainer = styled.div`
  ${flexRow};
  align-items: center;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  background: #fffbe5;
`

const DivideBar = styled.div`
  width: 0.1rem;
  height: 2rem;
  background: #ebebeb;
`
