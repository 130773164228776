import React, {ForwardedRef, SetStateAction, forwardRef, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {color, flexColumn, flexRow} from '../../../style/CommonStyle'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import SVGImage from '../../common/SVGImage'
import Select, {SelectRef} from '../../input/Select'
import SearchInput from '../../input/SearchInput'
import useSecureRef from '../../../hook/useSecureRef'
import RegisterDriver, {RegisterDriverRef} from '../registerBus/RegisterBus'
import ModalContainer, {ModalContainerRef} from '../../modal/ModalContainer'
import Toast from '../../toast/Toast'
import RegisterBus from '../registerBus/RegisterBus'

type DispatchListProps = {
  setSelect: React.Dispatch<SetStateAction<string>>
  setKeyword: React.Dispatch<SetStateAction<string>>
  keyword: string
  fetchDispatchesList(): void
}
export type DispatchListRef = {}

const managerOptions = [
  {
    idx: 0,
    exposure: '호차명',
    value: 'NAME',
  },
  {
    idx: 1,
    exposure: '차량번호',
    value: 'LICENSE_PLATE',
  },
  {
    idx: 2,
    exposure: '운행 기사',
    value: 'DRIVER',
  },
]

function SearchBusesBase(props: DispatchListProps, ref: ForwardedRef<DispatchListRef>) {
  const registerDispatchRef = useSecureRef<RegisterDriverRef>('[SearchDispatch.tsx] registerDispatchRef')
  const registerPopUpRef = useSecureRef<ModalContainerRef>('[SearchDispatch.tsx] registerPopUpRef')
  const [toast, setToast] = useState<boolean>(false)
  const [name, setName] = useState<string>('')

  const showRegisterModal = useCallback(() => {
    registerPopUpRef.current().show()
  }, [])

  const hideRegisterModal = useCallback(() => {
    registerPopUpRef.current().hide()
  }, [])

  const onChangeSelectValue = (v: string) => {
    props.setSelect(v)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const onChangeText = useCallback(
    (t: string) => {
      props.setKeyword(t)
    },
    [props.keyword],
  )

  useEffect(() => {
    if (name !== '') {
      setToast(true)
    }
  }, [name])

  useEffect(() => {
    if (toast) {
      const timeoutId = setTimeout(() => {
        setToast(false)
      }, 3000)

      const timeoutName = setTimeout(() => {
        setName('')
      }, 4000)

      return () => {
        clearTimeout(timeoutId)
        clearTimeout(timeoutName)
      }
    }
  }, [toast])

  return (
    <Container>
      <SelectSearchContainer>
        <Select
          options={managerOptions}
          onChange={(value: string) => onChangeSelectValue(value)}
          width={11.8}
          height={3.6}
          fontSize={1.2}
          defaultIdx={0}
          boxShadow={false}
          border={false}
        />
        <Line />
        <SearchInput
          onChangeText={onChangeText}
          placeHolder={'검색어를 입력해주세요.'}
          border={false}
          width={21}
          onKeyDown={handleKeyDown}
        />
      </SelectSearchContainer>
      <RegisterManagerButton onClick={showRegisterModal}>
        <PlusImg source={PlusIcon} />
        호차 등록
      </RegisterManagerButton>
      <ModalContainer ref={registerPopUpRef.ref}>
        <RegisterBus
          ref={registerDispatchRef.ref}
          hideRegisterModal={hideRegisterModal}
          fetchDispatchesList={props.fetchDispatchesList}
          setName={setName}
        />
      </ModalContainer>
      <Toast text={`${name} 호차가 등록되었습니다.`} status={toast ? 'toast' : ''} />
    </Container>
  )
}

const SearchBuses = forwardRef(SearchBusesBase)
export default SearchBuses

const Container = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 5.2rem;
  ${flexRow}
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const SelectSearchContainer = styled.div`
  ${flexRow};
  align-items: center;
  min-height: 3.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background-color: ${color.white};

  :focus-within {
    border-color: #ffe880;
    background: #fffbe5;

    input,
    div {
      background: #fffbe5;
    }
  }
`

const RegisterManagerButton = styled.button`
  ${flexRow};
  background-color: #ffcd00;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  align-items: center;
  border: none;
  border-radius: 3.4rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
`

const PlusImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`

const Line = styled.span`
  width: 0.1rem;
  height: 2rem;
  background: #d9d9d9;
  margin-right: 0.4rem;
`
