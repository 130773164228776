import React, {ReactElement, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import InputHeader from '../../../../../input/InputHeader'
import CheckMultiSelect, {
  CheckMultiSelectRef,
  toCheckMultiSelectOptions,
} from '../../../../../input/CheckMultiSelect'
import {WeekdayEnum} from '../../../../../../enum/WeekdayEnum'
import useSecureRef from '../../../../../../hook/useSecureRef'
import {Optional} from '../../../../../../type/Common'
import {CCourse} from '../../../../../../model/Course'
import {DispatchTypeEnum} from '../../../../../../enum/DispatchTypeEnum'
import {
  CDispatchCirculationTime,
  CDispatchWeekdayWithCode,
} from '../../../../../../model/Dispatch'
import CourseInput from './course/CourseInput'
import ClassTimeInput from './classTime/ClassTimeInput'
import {SelectedBusInfo} from '../../table/PeriodTable'
import {CDispatchPeriodInfo} from '../../../../../../model/DispatchPeriod'

type Props = {
  dispatchPeriod: CDispatchPeriodInfo
  selectedCourse: Optional<CCourse>
  selectedBusInfo: SelectedBusInfo
  sameCourseWeekdays: CDispatchWeekdayWithCode[]
  originalTime: string
  onChangeCourse(C: CCourse): void
  onChangeWeekdays(wds: CDispatchWeekdayWithCode[]): void
  onChangeCirculationTime(ct: CDispatchCirculationTime): void
}

export default function OperationInfo(props: Props): ReactElement {
  const weekdayRef = useSecureRef<CheckMultiSelectRef<WeekdayEnum>>(
    '[OperationInfoNormal.tsx] weekdayRef',
  )

  const dispatchDayOptions = useMemo(() => {
    return toCheckMultiSelectOptions(
      WeekdayEnum.ALL.map(wd => {
        const disabled = !props.sameCourseWeekdays.some(
          swd => swd.weekday === wd,
        )

        if (wd === props.selectedBusInfo.weekday) {
          return {
            value: wd,
            disabled: true,
          }
        }

        return {
          value: wd,
          disabled: disabled,
        }
      }),
      (v: WeekdayEnum, disabled: boolean) => (
        <CheckBoxExposure readOnly={disabled}>{v.exposure}</CheckBoxExposure>
      ),
    )
  }, [props.sameCourseWeekdays, props.selectedBusInfo])

  const onChangeWeekdays = useCallback(
    (wds: WeekdayEnum[]) => {
      const sameCourseWeekdays = wds.map(wd =>
        props.sameCourseWeekdays.find(scw => scw.weekday === wd),
      )

      props.onChangeWeekdays(sameCourseWeekdays)
    },
    [props.onChangeWeekdays, props.sameCourseWeekdays],
  )

  const defaultIndices = useMemo(() => {
    const idx = WeekdayEnum.ALL.findIndex(
      w => w === props.selectedBusInfo.weekday,
    )

    if (idx === -1) {
      return []
    }

    return [idx]
  }, [props.selectedBusInfo])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>운행 정보</HeaderText>
      </HeaderContainer>
      <InputContainer
        isCirculation={
          props.selectedCourse?.dispatchType === DispatchTypeEnum.CIRCULATION
        }>
        <RowContainer>
          <CourseInput
            dispatchType={
              props.selectedBusInfo.dispatchTable.dispatch.dispatchType
            }
            selectedCourse={props.selectedCourse}
            onSubmit={props.onChangeCourse}
            dispatchPeriod={props.dispatchPeriod}
          />
          <ClassTimeInput
            selectedCourse={props.selectedCourse}
            selectedBusInfo={props.selectedBusInfo}
            onChangeCirculationTime={props.onChangeCirculationTime}
          />
        </RowContainer>
        <OperationDayContainer>
          <InputHeaderContainer>
            <InputHeader header={'동일 노선 운행 요일'} required={false} />
            {/* <QuestionMark>
              <QuestionMarkText>?</QuestionMarkText>
            </QuestionMark> */}
          </InputHeaderContainer>
          <CheckMultiSelect
            ref={weekdayRef.ref}
            options={dispatchDayOptions}
            defaultIndices={defaultIndices}
            hasAll={false}
            required={true}
            readOnly={false}
            onChange={onChangeWeekdays}
          />
        </OperationDayContainer>
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`
const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const InputContainer = styled.div<{isCirculation: boolean}>`
  display: flex;
  flex-direction: ${props => (props.isCirculation ? 'column' : 'row')};
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  position: relative;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const RowContainer = styled.div`
  ${flexRow};
  align-items: center;
  flex: 1;
  column-gap: 0.8rem;
`

const CourseContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
  position: relative;
`

const OperationDayContainer = styled(CourseContainer)``

const QuestionMark = styled.div`
  background: #333333;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 10rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
`

const QuestionMarkText = styled.div`
  color: #ffffff;
  font-weight: 800;
  font-size: 1rem;
`

const CheckBoxExposure = styled.p<{readOnly: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${props => (props.readOnly ? '#CCCCCC' : '#000000')};
`
