export class OccupancyTypeEnum {
  static readonly NORMAL = new OccupancyTypeEnum('normal', '정상배차')
  static readonly FULL_SEAT = new OccupancyTypeEnum('fullSeat', '만차')
  static readonly NOT_REGISTERED_SCHEDULE = new OccupancyTypeEnum(
    'notRegisteredSchedule',
    '학생미등록',
  )
  static readonly ALL = new OccupancyTypeEnum('all', '전체')

  static readonly ALL_TYPES = [
    OccupancyTypeEnum.ALL,
    OccupancyTypeEnum.NORMAL,
    OccupancyTypeEnum.FULL_SEAT,
    OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE,
  ]

  private constructor(
    readonly value: string,
    readonly exposure: string,
  ) {}
}
