import React, {ForwardedRef, forwardRef, ReactElement, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {alignCenter, color, flexColumn, flexRow, justifyCenter} from '../../../style/CommonStyle'
import Select, {toSelectOptions} from '../../input/Select'
import {BoardSwitchEnum} from '../../../enum/BoardSwitchEnum'
import {CBusOperationSchedule} from '../../../model/Bus'
import {CDateTime} from '../../../model/DateTime'
import {BaseDateUnitEnum} from '../../../enum/DateUnitEnum'
import {CDate} from '../../../model/Date'
import {isEmptyArray} from '../../../util/ValidationUtil'
import {putStudentSchedule} from '../../../service/busOperation/BusOperation'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import BoardingSelect from '../../input/BoardingSelect'

type OperationDetailViewBoardingProps = {
  date: CDate
  time: string
  schedules: CBusOperationSchedule[]
  isBoarding: boolean
  startTime: string
  handleBoardingButton?: (name: string, status: number, scheduleId: number) => void
}
export type OperationDetailViewBoardingRef = {}

function OperationDetailViewBoardingBase(
  props: OperationDetailViewBoardingProps,
  _: ForwardedRef<OperationDetailViewBoardingRef>,
): ReactElement {
  const selectAcademyId = useRecoilValue(academyIDState)

  const boardSwitchOptions = useMemo(() => {
    return toSelectOptions(BoardSwitchEnum.ALL, bse => bse.exposure, false)
  }, [])

  const onChangeBoardSwitch = useCallback((academyId: string, scheduleId: number, bs: BoardSwitchEnum) => {
    // putStudentSchedule(academyId, scheduleId, bs === BoardSwitchEnum.BOARD)
    //   .then(() => alert('변경되었습니다.'))
    //   .catch(error => {
    //     alert('변경에 실패하였습니다.')
    //     throw new Error(
    //       `failed to put student schedule. (academyId: ${academyId}, scheduledId: ${scheduleId}, error: ${error})`,
    //     )
    //   })
  }, [])

  const editable = useMemo(() => {
    const d = `${props.date}T${props.startTime}:00`
    const dt = CDateTime.create(d)
    const AfterThirtyMinute = dt.add(-30, BaseDateUnitEnum.MINUTE)

    return CDateTime.now().isAfter(AfterThirtyMinute)
  }, [props.date, props.startTime])

  const BoardingTitle = useMemo(() => {
    if (props.isBoarding) {
      return '승차'
    }

    return '하차'
  }, [props.isBoarding])

  if (isEmptyArray(props.schedules)) {
    return null
  }

  return (
    <BoardingContainer>
      <Boarding isBoarding={props.isBoarding}>{BoardingTitle}</Boarding>
      <StudentsContainer>
        {props.schedules.map((s, idx) => {
          const defaultIdx = boardSwitchOptions.find(o => o.value === s.boardSwitch).idx
          return (
            <StudentContainer key={idx}>
              <StudentName>{s.student.name}</StudentName>
              <BoardingContentContainer>
                <BoardingSelect
                  // defaultIdx={s.boardSwitch.exposure === 'WILL_BOARD' ? 0 : 1}
                  defaultIdx={defaultIdx}
                  status={s.boardSwitch.exposure}
                  options={boardSwitchOptions}
                  handleBoardingButton={props.handleBoardingButton}
                  width={8}
                  height={2.6}
                  fontSize={1.2}
                  borderRadius={6}
                  boxShadow={false}
                  border={props.isBoarding ? true : false}
                  editable={!props.isBoarding ? true : editable}
                  name={s.student.name}
                  scheduleId={s.scheduleId}
                />
                <BoardingStatus>
                  {s.boardSwitch.exposure === '안타요'
                    ? ''
                    : props.isBoarding && s.timeOnBoard === ''
                      ? '탑승 대기'
                      : !props.isBoarding && s.timeOffBoard === '' && s.timeOnBoard !== ''
                        ? ''
                        : !props.isBoarding && s.timeOffBoard !== ''
                          ? s.timeOffBoard
                          : s.timeOnBoard}
                </BoardingStatus>
              </BoardingContentContainer>
            </StudentContainer>
          )
        })}
      </StudentsContainer>
    </BoardingContainer>
  )
}

const OperationDetailViewBoarding = forwardRef(OperationDetailViewBoardingBase)
export default OperationDetailViewBoarding

const BoardingContainer = styled.div`
  ${flexRow};
  column-gap: 1rem;
`

const StudentsContainer = styled.div`
  ${flexColumn};
  row-gap: 1.2rem;
  margin-top: 0.3rem;
`

const Boarding = styled.p<{isBoarding: boolean}>`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  padding: 0 0.8rem;
  height: 2.1rem;
  background-color: ${props => (props.isBoarding ? color.green1 : '#1a468b;')};
  color: ${color.white};
  font-size: 1.2rem;
  border-radius: 1.2rem;
`

const StudentContainer = styled.div``
const StudentName = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
`

const BoardingContentContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 0.7rem;
`

const BoardingStatus = styled.p`
  font-size: 1.2rem;
`
