import React from 'react'
import ReactDOM from 'react-dom/client'
import Routes from './Routes'
import {BrowserRouter} from 'react-router-dom'
import GlobalStyle from './style/GlobalStyle'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <GlobalStyle />
    <Routes />
  </BrowserRouter>,
)
