import React, {ReactNode, useMemo} from 'react'
import InputHeader from '../../../../../../input/InputHeader'
import {isNil} from '../../../../../../../util/ValidationUtil'
import ArrowDown from '../../../../../../../asset/image/arrowDown.svg'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {Optional} from '../../../../../../../type/Common'
import {CCourse} from '../../../../../../../model/Course'

type Props = {
  course: Optional<CCourse>
  onClickCourse(): void
}

export default function CourseInfo(props: Props) {
  const CourseComponent = useMemo((): ReactNode => {
    if (isNil(props.course)) {
      return (
        <CourseText hasValue={!isNil(props.course)}>
          운행노선을 선택해주세요.
        </CourseText>
      )
    }

    if (props.course.dispatchType === DispatchTypeEnum.CIRCULATION) {
      return (
        <CourseTextContainer>
          <CourseText hasValue={!isNil(props.course)}>
            {props.course.name}
          </CourseText>
          <CourseDispatchTypeText>
            {props.course.dispatchType.exposure}
          </CourseDispatchTypeText>
        </CourseTextContainer>
      )
    }

    return (
      <CourseText hasValue={!isNil(props.course)}>
        {props.course.name}
      </CourseText>
    )
  }, [props.course])

  return (
    <CourseContainer>
      <InputHeaderContainer>
        <InputHeader header={'운행 노선 선택'} required={true} />
      </InputHeaderContainer>
      <CourseInputContainer>
        <CourseInput
          hasValue={!isNil(props.course)}
          onClick={props.onClickCourse}>
          {CourseComponent}
          <ArrowDownImage source={ArrowDown}></ArrowDownImage>
        </CourseInput>
      </CourseInputContainer>
    </CourseContainer>
  )
}

const CourseContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const CourseInputContainer = styled.div`
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
`

const CourseInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const CourseTextContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const CourseText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

const CourseDispatchTypeText = styled.div`
  color: #997d00;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 0.2rem;
`
