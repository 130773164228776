import moment from 'moment-timezone'
import {CDate} from './Date'
import {BaseDateUnitEnum} from '../enum/DateUnitEnum'

export type InputType = moment.MomentInput

export class CDateTime {
  static readonly FORMAT_YYYY_MM = 'YYYY년 MM월'
  static readonly FORMAT_MD = 'M월 D일'
  static readonly FORMAT_ahmm = 'a h:mm'
  static readonly FORMAT_YYMMDD = 'YY-MM-DD'
  static readonly FORMAT_YYYYMMDD = 'YYYY-MM-DD'
  static readonly FORMAT_YYYYMMDDAhhmm = 'YYYY/MM/DD A hh:mm'
  static readonly FORMAT_YYYYMMDDAhhmmss = 'YYYY/MM/DD A hh:mm:ss'
  static readonly FORMAT_MMDDHHmm = 'MM.DD HH:mm'

  private readonly value: moment.Moment

  private constructor(input: moment.Moment) {
    this.value = input
  }

  static create(input: InputType) {
    const m = moment(input)
    if (!m.isValid()) {
      throw new Error(`invalid input. (input: ${input})`)
    }

    return new CDateTime(m)
  }

  static now(): CDateTime {
    return CDateTime.create(moment.now())
  }

  isValid(): boolean {
    return this.value.isValid()
  }

  format(f: string): string {
    return this.value.format(f)
  }

  getValue(): moment.Moment {
    return this.value.clone()
  }

  toUnixMilli(): number {
    return this.value.unix() * 1000
  }

  toDate(): CDate {
    return CDate.create(this.value)
  }

  toISOString(): string {
    return this.value.toISOString(true)
  }

  toString(): string {
    return this.value.toISOString()
  }

  add(amount: number, unit: BaseDateUnitEnum): CDateTime {
    const m = this.value.clone().add(amount, unit.value)

    return CDateTime.create(m)
  }

  equals(input: CDateTime): boolean {
    return this.value.isSame(input.value)
  }

  isBefore(input: CDateTime): boolean {
    return this.value.isBefore(input.value)
  }

  isAfter(input: CDateTime): boolean {
    return this.value.isAfter(input.value)
  }

  diffUnixMilli(time: CDateTime): number {
    return this.value.diff(time.value)
  }

  diffUnixOfTime(time: CDateTime, unit: BaseDateUnitEnum): number {
    return this.value.diff(time.value, unit.value)
  }

  fromNow(): string {
    return this.value.fromNow()
  }
}
