import React, {ReactElement, useCallback, useState} from 'react'
import ScreenContainer from '../../component/layout/ScreenContainer'
import DispatchPeriod from '../../component/dispatch/dispatchPeriod/DispatchPeriod'
import DispatchListContainer, {
  DispatchListContainerRef,
} from '../../component/dispatch/dispatch/list/DispatchListContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import styled from 'styled-components'
import {flexColumn} from '../../style/CommonStyle'
import useSecureRef from '../../hook/useSecureRef'
import {CDispatchPeriod} from '../../model/DispatchPeriod'
import Toast, {ToastRef} from '../../component/modal/Toast'
import {Optional} from '../../type/Common'

type Props = {}
export default function Dispatch(_: Props): ReactElement {
  const [selectedYear, setSelectedYear] = useState<Optional<number>>(null)

  const toastRef = useSecureRef<ToastRef>('[Dispatch.tsx] toastRef')
  const listRef = useSecureRef<DispatchListContainerRef>(
    '[Dispatch.tsx] listRef',
  )

  const onChangeDispatchPeriod = useCallback(
    (v: CDispatchPeriod) => {
      listRef.current().setDispatchPeriod(v)
    },
    [selectedYear],
  )

  const showToast = useCallback((content: string) => {
    toastRef.current().setContent(content)
    toastRef.current().show()
  }, [])

  return (
    <ScreenContainer>
      <Toast ref={toastRef.ref} />
      <PageHeaderWithAcademies>배차 관리</PageHeaderWithAcademies>
      <Container>
        <DispatchPeriod
          onChangeDispatchPeriod={onChangeDispatchPeriod}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
        <DispatchListContainer ref={listRef.ref} showToast={showToast} />
      </Container>
    </ScreenContainer>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  width: 100%;
  height: calc(100vh - 14.1rem);
  row-gap: 1.2rem;
`
