import React, {useCallback, useEffect, useState} from 'react'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../../model/Dispatch'
import Footer from '../../../../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
  postDispatchesCoursesStudents,
  PostDispatchesCoursesStudentsData,
} from '../../../../../../../service/dispatch/Dispatch'
import {StudentLoadTypeEnum} from '../../../../../../../enum/StudentLoadTypeEnum'
import {Optional} from '../../../../../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {alertError, throwError} from '../../../../../../../util/ErrorUtil'
import {isNil} from '../../../../../../../util/ValidationUtil'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import CourseExtra from './CourseExtra'

type Props = {
  dispatchCode: string
  extraDispatchCode: string
  courseCode: string
  extraCourseCode: Optional<string>
  courseName: string
  extraCourseName: Optional<string>
  dispatchType: DispatchTypeEnum
  busName: Optional<string>
  extraBusName: Optional<string>
  studentLoadType: StudentLoadTypeEnum
  onCancel(): void
  onSubmit(): void
}
export type ChangeStudentData = {
  course: CDispatchCourseWithStudent
  student?: CStudentWithCheck
  time?: string
}

export default function LoadStudentExtra(props: Props) {
  const academyID = useRecoilValue(academyIDState)
  const [courses, setCourses] = useState<CDispatchCourseWithStudent[]>([])
  const [extraCourses, setExtraCourses] = useState<
    CDispatchCourseWithStudent[]
  >([])

  const onSubmit = useCallback(() => {
    const data: PostDispatchesCoursesStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.courseCode,
      courses: courses,
      type: props.studentLoadType,
    }

    const extraData: PostDispatchesCoursesStudentsData = {
      dispatchCode: props.extraDispatchCode,
      academyID: academyID,
      courseCode: props.extraCourseCode,
      courses: extraCourses,
      type: props.studentLoadType,
    }

    console.log('data', data)
    console.log('extraData', extraData)

    Promise.all([
      postDispatchesCoursesStudents(data),
      postDispatchesCoursesStudents(extraData),
    ])
      .then(() => {
        props.onSubmit()
        props.onCancel()
      })
      .catch(error => {
        alertError(
          error,
          `postDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    academyID,
    courses,
    extraCourses,
    props.courseCode,
    props.extraCourseCode,
    props.dispatchCode,
    props.extraDispatchCode,
    props.studentLoadType,
    props.onSubmit,
    props.onCancel,
  ])

  const getCourse = useCallback(
    (
      courses: CDispatchCourseWithStudent[],
      data: ChangeStudentData,
      isExtra = false,
    ) => {
      return courses.map((course, idx) => {
        if (course.stationID === data.course.stationID) {
          if (isNil(data.student) && !isNil(data.time)) {
            return {
              ...course,
              arrivalTime: data.time,
            }
          }
          return {
            ...course,
            students: course.students.map(ss => {
              if (ss.id === data.student.id) {
                return {
                  ...ss,
                  check: !data.student.check,
                }
              }
              return ss
            }),
          }
        }
        if (isNil(data.student) && !isNil(data.time)) {
          return course
        }
        if (
          props.dispatchType === DispatchTypeEnum.INBOUND &&
          idx === courses.length - 1
        ) {
          return {
            ...course,
            students: course.students.map(ss => {
              if (ss.id === data.student.id) {
                return {
                  ...ss,
                  check: !data.student.check,
                }
              }
              return ss
            }),
          }
        }
        if (props.dispatchType === DispatchTypeEnum.OUTBOUND && idx === 0) {
          return {
            ...course,
            students: course.students.map(ss => {
              if (ss.id === data.student.id) {
                return {
                  ...ss,
                  check: !data.student.check,
                }
              }
              return ss
            }),
          }
        }
        return course
      })
    },
    [],
  )

  const onChangeStudent = useCallback(
    (data: ChangeStudentData) => {
      setCourses(prev => {
        // 체크를 하려고 할 때만 다른 쪽의 체크를 해제
        console.log(data)
        if (!data.student?.check) {
          // extraCourses에서 같은 학생의 체크를 해제
          setExtraCourses(extraPrev =>
            getCourse(
              extraPrev,
              {
                ...data,
                student: {...data.student, check: true}, // 체크를 강제로 false로 만들기 위해 현재 상태를 true로 전달
              },
              true,
            ),
          )
        }
        return getCourse(prev, data)
      })
    },
    [setCourses, setExtraCourses, getCourse],
  )

  const onChangeExtraStudent = useCallback(
    (data: ChangeStudentData) => {
      setExtraCourses(prev => {
        // 체크를 하려고 할 때만 다른 쪽의 체크를 해제
        if (!data.student?.check) {
          // courses에서 같은 학생의 체크를 해제
          setCourses(coursesPrev =>
            getCourse(coursesPrev, {
              ...data,
              student: {...data.student, check: true}, // 체크를 강제로 false로 만들기 위해 현재 상태를 true로 전달
            }),
          )
        }
        return getCourse(prev, data, true)
      })
    },
    [setExtraCourses, setCourses, getCourse],
  )

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData, isExtra: boolean) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          if (isExtra) {
            setExtraCourses(cws)
            return
          }
          setCourses(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourses, setExtraCourses],
  )

  useEffect(() => {
    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.courseCode,
      type: props.studentLoadType,
    }

    const extraData: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.extraDispatchCode,
      academyID: academyID,
      courseCode: props.extraCourseCode,
      type: props.studentLoadType,
    }

    fetchCourses(data, false)
    fetchCourses(extraData, true)
  }, [
    props.dispatchCode,
    props.extraDispatchCode,
    props.courseCode,
    props.extraCourseCode,
    props.studentLoadType,
    academyID,
  ])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>학생 불러 오기</HeaderText>
      </HeaderContainer>
      <CourseContainer>
        <CourseExtra
          title={props.busName}
          courseName={props.courseName}
          onChangeStudent={onChangeStudent}
          courses={courses}
          isStart={props.dispatchType === DispatchTypeEnum.INBOUND}
        />
        {isNil(props.extraBusName) ? null : (
          <CourseExtra
            title={props.extraBusName}
            courseName={props.extraCourseName}
            onChangeStudent={onChangeExtraStudent}
            courses={extraCourses}
            isStart={props.dispatchType === DispatchTypeEnum.INBOUND}
          />
        )}
      </CourseContainer>
      <Footer
        submitText={'확인'}
        onCancel={props.onCancel}
        onSubmit={onSubmit}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 54rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`
const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const CourseContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  flex: 1;
  overflow: auto;
  padding: 1.6rem;
  background: #f5f5f5;
  margin-bottom: 1.6rem;

  ::-webkit-scrollbar {
    display: none;
  }
`
