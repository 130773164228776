export class DispatchTypeEnum {
  static readonly INBOUND = new DispatchTypeEnum('INBOUND', '등원')
  static readonly OUTBOUND = new DispatchTypeEnum('OUTBOUND', '하원')
  static readonly CIRCULATION = new DispatchTypeEnum('CIRCULATION', '순환')

  constructor(
    readonly value: string,
    readonly exposure: string,
  ) {}

  static parse(value: string): DispatchTypeEnum {
    switch (value) {
      case DispatchTypeEnum.INBOUND.value:
        return DispatchTypeEnum.INBOUND
      case DispatchTypeEnum.OUTBOUND.value:
        return DispatchTypeEnum.OUTBOUND
      case DispatchTypeEnum.CIRCULATION.value:
        return DispatchTypeEnum.CIRCULATION
      default:
        throw new Error(`invalid dispatch type. (type: ${value})`)
    }
  }
}
