import React, {ForwardedRef, forwardRef, ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {color, flexColumn} from '../../../style/CommonStyle'
import {BusOperationStatusEnum} from '../../../enum/BusOperationStatusEnum'
import {isEmptyString, isNil} from '../../../util/ValidationUtil'
import {CBusOperationCarDispatch} from '../../../model/Bus'
import {getBusOperationsCarDispatchByCode} from '../../../service/busOperation/BusOperation'
import {Optional} from '../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {CDate} from '../../../model/Date'

type OperationDetailHeaderProps = {
  date: CDate
  code: string
}
export type OperationDetailHeaderRef = {}

function OperationDetailHeaderBase(
  props: OperationDetailHeaderProps,
  _: ForwardedRef<OperationDetailHeaderRef>,
): ReactElement {
  const [carDispatch, setCarDispatch] = useState<Optional<CBusOperationCarDispatch>>(null)

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)

  const fetchCarDispatch = useCallback((code: string, selectedAcademyID: string, date: CDate) => {
    getBusOperationsCarDispatchByCode(code, selectedAcademyID, date)
      .then(cd => setCarDispatch(cd))
      .catch(error => {
        throw new Error(`failed to get bus operations car dispatch by code. (code: ${code}, error: ${error})`)
      })
  }, [])

  const parsedScheduleName = useMemo(() => {
    if (isNil(carDispatch) || isEmptyString(carDispatch.dispatch.name)) {
      return ''
    }

    const result = carDispatch.dispatch.name.split('-').slice(2)
    return result.join('-')
  }, [carDispatch])

  const dispatchStatus = useMemo(() => {
    if (isNil(carDispatch)) {
      return null
    }

    return (
      <>
        {carDispatch.status.exposure.slice(0, 2)} <br />
        {carDispatch.status.exposure.slice(2, 4)}
      </>
    )
  }, [carDispatch])

  useEffect(() => {
    if (isNil(props.code)) {
      return
    }

    fetchCarDispatch(props.code, selectedAcademyID, props.date)
  }, [props.code, props.date])

  if (isNil(carDispatch)) {
    return null
  }

  return (
    <Container status={carDispatch.status}>
      <StatusContainer>
        <StatusWrapper status={carDispatch.status}>{dispatchStatus}</StatusWrapper>
      </StatusContainer>
      <HeaderBusName status={carDispatch.status}>{carDispatch.bus.name}</HeaderBusName>
      <HeaderSchedule status={carDispatch.status}>{parsedScheduleName}</HeaderSchedule>
      <InfoContainer>
        <Info status={carDispatch.status}>
          • 동승자 : {carDispatch.manager.name} ({carDispatch.manager.phone.toNumberWithHyphen()})
        </Info>
        <Info status={carDispatch.status}>
          • 운전자 : {carDispatch.bus.driver} ({carDispatch.bus.driverPhone.toNumberWithHyphen()})
        </Info>
      </InfoContainer>
    </Container>
  )
}

const OperationDetailHeader = forwardRef(OperationDetailHeaderBase)
export default OperationDetailHeader

const Container = styled.div<StatusProps>`
  background-color: ${props =>
    props.status === BusOperationStatusEnum.READY
      ? '#800080'
      : props.status === BusOperationStatusEnum.PROCEED
        ? '#079f00'
        : '#e9e9e9'};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  padding: 3rem;
  position: relative;
`
const HeaderBusName = styled.div<StatusProps>`
  color: ${props =>
    props.status === BusOperationStatusEnum.NOT_RUNNING || props.status === BusOperationStatusEnum.DONE
      ? '#afafaf'
      : '#fff'};
  font-size: 1.5rem;
  height: 2.25rem;
  line-height: 2rem;
  font-weight: 600;
`

const HeaderSchedule = styled(HeaderBusName)`
  margin-bottom: 2rem;
`

const InfoContainer = styled.div`
  ${flexColumn};
  row-gap: 1.2rem;
`

const Info = styled.div<StatusProps>`
  color: ${props =>
    props.status === BusOperationStatusEnum.NOT_RUNNING || props.status === BusOperationStatusEnum.DONE
      ? '#afafaf'
      : '#fff'};
  font-size: 1.2rem;
  font-weight: 600;
`

type StatusProps = {
  status: BusOperationStatusEnum
}

const StatusContainer = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${color.white};
  background-color: ${color.white};
  position: absolute;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2rem;
  top: 2rem;
`

const StatusWrapper = styled.div<StatusProps>`
  color: ${props =>
    props.status === BusOperationStatusEnum.READY
      ? '#800080'
      : props.status === BusOperationStatusEnum.PROCEED
        ? '#079f00'
        : '#afafaf'};
  font-weight: 700;
  font-size: 1rem;
`
