import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {Optional} from '../../../../../../../type/Common'
import {isEmptyArray, isNil} from '../../../../../../../util/ValidationUtil'
import Search from '../../../../../../../asset/image/search.svg'
import PaginationContainer from '../../../../../../common/PaginationContainer'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import TextInput, {TextInputRef} from '../../../../../../input/TextInput'
import {CCourse, CCourseVector} from '../../../../../../../model/Course'
import {
  getSearchCourses,
  GetSearchCoursesData,
} from '../../../../../../../service/course/Course'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'

const LIMIT = 10
const PAGE_EXPOSE_COUNT = 3

type Props = {
  onChange(s: CCourse): void
  selectedCourse: Optional<CCourse>
  dispatchType: DispatchTypeEnum
  columnCount: number
  isAddingBus?: boolean
}
export type CoursePickerRef = {
  show(): void
  hide(): void
}

function CoursePickerBase(
  props: Props,
  ref: ForwardedRef<CoursePickerRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const inputRef = useSecureRef<TextInputRef>('CoursePicker.tsx inputRef')

  const [visible, setVisible] = useState<boolean>(false)
  const [courseVector, setCourseVector] =
    useState<Optional<CCourseVector>>(null)

  const fetchSearchCourses = useCallback(
    (data: GetSearchCoursesData) => {
      getSearchCourses(data)
        .then(courseVector => {
          const filteredCourses = courseVector.courses.filter(
            course => course.dispatchType.value !== 'CIRCULATION',
          )
          const courseVectorWithoutCirculation = {
            ...courseVector,
            courses: filteredCourses,
          }

          setCourseVector(
            //쪽차 추가시에는 순환노선 선택 불가 처리
            props.isAddingBus ? courseVectorWithoutCirculation : courseVector,
          )
        })
        .catch(error => {
          throw new Error(
            `getSearchCourses() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourseVector],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const keyword = inputRef.current().getValue()

      const data: GetSearchCoursesData = {
        academyID: academyID,
        page: page - 1,
        size: LIMIT,
        keyword: keyword,
        dispatchType: [props.dispatchType],
      }

      fetchSearchCourses(data)
    },
    [academyID, fetchSearchCourses, props.dispatchType],
  )

  const onChangeText = useCallback(
    (t: string) => {
      const data: GetSearchCoursesData = {
        academyID: academyID,
        page: 0,
        size: LIMIT,
        keyword: t,
        dispatchType: [props.dispatchType],
      }

      fetchSearchCourses(data)
    },
    [academyID, fetchSearchCourses, props.dispatchType],
  )

  const onClickCourse = useCallback(
    (c: CCourse) => {
      props.onChange(c)
      setVisible(false)
    },
    [props.onChange, setVisible],
  )

  const ListComponent = useMemo(() => {
    if (isNil(courseVector)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    if (isEmptyArray(courseVector.courses)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    return (
      <ListContainer columnCount={props.columnCount}>
        {courseVector.courses.map((value, idx) => {
          const name =
            value.name?.length > 13
              ? `${value.name.slice(0, 13)}...`
              : value.name

          return (
            <SelectContainer
              key={`${value.id}_${idx}`}
              onClick={() => onClickCourse(value)}>
              <TitleContainer>
                <Text selected={props.selectedCourse?.id === value.id}>
                  {name}({value.dispatchType.exposure})
                </Text>
              </TitleContainer>
            </SelectContainer>
          )
        })}
      </ListContainer>
    )
  }, [courseVector, props.selectedCourse, props.columnCount, onClickCourse])

  useEffect(() => {
    const data: GetSearchCoursesData = {
      academyID: academyID,
      page: 0,
      size: LIMIT,
      keyword: '',
      dispatchType: [props.dispatchType],
    }

    if (!visible) {
      return
    }

    fetchSearchCourses(data)
  }, [visible, academyID, props.dispatchType])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
      hide(): void {
        setVisible(false)
      },
    }),
    [setVisible],
  )

  if (!visible) {
    return null
  }

  if (isNil(courseVector)) {
    return null
  }

  return (
    <Container>
      <ContentContainer>
        <SearchContainer>
          <SearchImage source={Search} />
          <StyledTextInput
            ref={inputRef.ref}
            onChange={onChangeText}
            placeholder={'검색어를 입력해주세요.'}
            readOnly={false}
            required={false}
          />
        </SearchContainer>
        {ListComponent}
      </ContentContainer>
      <PaginationContainer
        totalElementCount={courseVector?.paging.totalElements}
        pageExposeCount={PAGE_EXPOSE_COUNT}
        perPage={LIMIT}
        onChange={onChangePage}
      />
    </Container>
  )
}

const CoursePicker = forwardRef(CoursePickerBase)
export default CoursePicker

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 100;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const ContentContainer = styled.div`
  padding: 0.8rem 0.6rem;
  ${flexColumn};
`

const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div<{columnCount: number}>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columnCount}, 1fr)`};
  column-gap: 1.2rem;
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
`

const EmptyListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 0 0 0.8rem 0.8rem;
  margin-top: 1rem;
`

const SelectContainer = styled.div`
  ${flexColumn};
  width: 100%;
  padding: 1.2rem 0 1.2rem 1.2rem;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
  &:hover {
    background: #fffbe5;
    border-radius: 0.8rem;

    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`

const TitleContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const Text = styled.h1<{selected: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 500)};
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#332A00')};
`
