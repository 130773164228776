import React, {ReactElement, useCallback, useState} from 'react'
import Toast, {ToastRef} from '../../../modal/Toast'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import Footer from '../../../common/Footer'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'
import useSecureRef from '../../../../hook/useSecureRef'
import Header from '../../../common/Header'
import DispatchDriverAddInputs, {
  DispatchDriverAddInputsRef,
} from './DispatchDriverAddInputs'
import {postDriver, PostDriverParams} from '../../../../service/driver/Driver'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {alertError} from '../../../../util/ErrorUtil'

type Props = {
  onSubmit(): void
  onCancel(): void
  setToast(): void
}

export default function DispatchDriverAdd(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const toastRef = useSecureRef<ToastRef>('[DispatchBusAdd.tsx] toastRef')
  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusAdd.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusAdd.tsx] confirmModalRef',
  )
  const addInputModalRef = useSecureRef<DispatchDriverAddInputsRef>(
    '[DispatchBusAdd.tsx] addInputModalRef',
  )

  const [isSufficient, setIsSufficient] = useState<boolean>(false)

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])

  const onChangeSufficiency = useCallback(
    (isSufficient: boolean) => {
      setIsSufficient(isSufficient)
    },
    [setIsSufficient],
  )

  const submitAddDriver = useCallback(() => {
    const data: PostDriverParams = addInputModalRef
      .current()
      .getDriverAddInputData()

    postDriver(academyID, data)
      .then(() => {
        props.setToast()
        props.onSubmit()
      })
      .catch(error => {
        alertError(
          error,
          `postDriver() failed. (aid: ${academyID}, data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [academyID, props.onSubmit, props.setToast])

  return (
    <Container>
      <Toast ref={toastRef.ref} />
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'신규 기사 등록 확인'}
        content={'신규 기사를 등록하시겠습니까?'}
        onSubmit={submitAddDriver}
      />
      <ConfirmModal
        ref={cancelConfirmModalRef.ref}
        header={'신규 기사 등록 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n신규 기사 등록을 취소 하시겠습니까?'
        }
        onSubmit={props.onCancel}
      />
      <ContentContainer>
        <StyledHeader>신규 기사 등록</StyledHeader>
        <DispatchDriverAddInputs
          ref={addInputModalRef.ref}
          readOnly={false}
          onChange={onChangeSufficiency}
        />
        <Footer
          isSufficient={isSufficient}
          onCancel={showCancelConfirmModal}
          onSubmit={showConfirmModal}
          submitText={'등록'}
        />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 36.7%;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  ${flexColumn};
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  background: #ffffff;
  border-top-left-radius: 1.6rem;
  border-bottom: 0.1rem solid #d9d9d9;
`
