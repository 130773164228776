import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker'

import '../../style/RangeDatePicker.css'
import {CDate} from '../../model/Date'
import {CDateTime} from '../../model/DateTime'
import {Optional} from '../../type/Common'
import {isNil} from '../../util/ValidationUtil'
import PrevButton from '../../asset/image/prev_button.svg'
import NextButton from '../../asset/image/next_button.svg'
import {ko} from 'date-fns/esm/locale'
import {WeekdayEnum} from '../../enum/WeekdayEnum'
import {flexColumn, flexRow} from '../../style/CommonStyle'
import {BaseDateUnitEnum} from '../../enum/DateUnitEnum'

type DatePickerProps = {
  minDate?: CDate
  maxDate?: CDate
  weekday?: WeekdayEnum
  weekdayStartDate?: CDate
  onChange(dates: {start: CDate; end: Optional<CDate>}): void
}

export type DateRange = {
  startDate: CDate
  endDate: CDate
}

export type DatePickerRef = {
  getRange(): DateRange
  setStartDate(value: CDate): void
  setEndDate(value: CDate): void
}

function RangeDatePickerBase(
  props: DatePickerProps,
  ref: ForwardedRef<DatePickerRef>,
): ReactElement {
  const minDate = useMemo(() => {
    const now = CDateTime.now()

    if (isNil(props.weekdayStartDate)) {
      return now.add(7, BaseDateUnitEnum.DAY).toDate()
    }

    if (props.minDate.isBefore(props.weekdayStartDate)) {
      if (now.toDate().equals(props.weekdayStartDate)) {
        // TODO: 최선어학원 임시 당일등록
        // return now.add(7, BaseDateUnitEnum.DAY).toDate()
        return now.toDate()
      }

      if (now.toDate().isAfter(props.weekdayStartDate)) {
        return CDateTime.create(props.weekdayStartDate.toString())
          .add(7, BaseDateUnitEnum.DAY)
          .toDate()
      }
      return props.weekdayStartDate
    }

    if (now.toDate().isAfter(props.weekdayStartDate)) {
      return CDateTime.create(props.weekdayStartDate.toString())
        .add(7, BaseDateUnitEnum.DAY)
        .toDate()
    }

    return props.minDate
  }, [props.minDate, props.weekdayStartDate])

  const [startDate, setStartDate] = useState<CDate>(minDate)
  const [endDate, setEndDate] = useState<Optional<CDate>>(props.maxDate)

  const onChange = useCallback((dates: Date[]) => {
    const [start, end] = dates
    const s = CDate.create(start)
    setStartDate(s)

    // if (isNil(end)) {
    //   setEndDate(null)
    //   props.onChange({start: s, end: null})
    // } else {
    //   setEndDate(CDate.create(end))
    //   props.onChange({start: s, end: CDate.create(end)})
    // }
  }, [])

  const renderCustomHeader = useCallback(
    (params: ReactDatePickerCustomHeaderProps) => {
      const Date = CDateTime.create(params.date)

      return (
        <CustomDateHeaderContainer>
          <DateWrapper>
            <StartDate>
              <DateText>시작일</DateText>
              <DateValue>{startDate.toString()}</DateValue>
            </StartDate>
            <EndDate>
              <DateText>종료일</DateText>
              <DateValue
                style={{
                  borderColor:
                    !isNil(startDate) && isNil(endDate) ? '#ffcd00' : '#cecece',
                }}>
                {endDate ? endDate.toString() : '선택해주세요.'}
              </DateValue>
            </EndDate>
          </DateWrapper>
          <CustomHeaderContainer>
            <MonthArrowWrapper>
              <DecreaseMonthButton onClick={params.decreaseMonth} />
              <CurrentMonth>
                {Date.format(CDateTime.FORMAT_YYYY_MM)}
              </CurrentMonth>
              <NextMonthButton onClick={params.increaseMonth} />
            </MonthArrowWrapper>
          </CustomHeaderContainer>
        </CustomDateHeaderContainer>
      )
    },
    [startDate, endDate],
  )

  const getFilterDate = useCallback(
    (d: Date) => {
      return d.getDay() === props.weekday?.id
    },
    [props.weekday],
  )

  useImperativeHandle(
    ref,
    () => {
      return {
        getRange(): DateRange {
          return {
            startDate: startDate,
            endDate: endDate,
          }
        },
        setStartDate(value: CDate) {
          setStartDate(value)
        },
        setEndDate(value: CDate) {
          setEndDate(value)
        },
      }
    },
    [startDate, endDate],
  )

  useEffect(() => {
    setStartDate(minDate)
    setEndDate(props.maxDate)
  }, [minDate, props.maxDate])

  return (
    <ReactDatePicker
      maxDate={props.maxDate.toDateRaw()}
      minDate={minDate.toDateRaw()}
      filterDate={getFilterDate}
      locale={ko}
      dateFormat={'yyyy-MM-dd'}
      onChange={onChange}
      showPopperArrow={false}
      selectsRange
      open={true}
      className={'rangeDatePicker'}
      startDate={startDate.toDateRaw()}
      endDate={endDate?.toDateRaw()}
      renderCustomHeader={renderCustomHeader}
    />
  )
}

const RangeDatePicker = forwardRef(RangeDatePickerBase)
export default RangeDatePicker

const MonthArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 1.6rem 1rem;
  max-height: 5.3rem;
`

const DecreaseMonthButton = styled.div`
  background: url(${PrevButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const CurrentMonth = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.042rem;
`

const NextMonthButton = styled.div`
  background: url(${NextButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const CustomDateHeaderContainer = styled.div`
  margin-bottom: 4rem;
`

const CustomHeaderContainer = styled.div`
  width: 22.4rem;
  height: 0.1rem;
  background: #f0f0f0;
  margin: 1.6rem auto 0;
`

const DateWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const DateText = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
  color: #585858;
`

const StartDate = styled.div`
  min-width: 10.5rem;
  ${flexColumn};
  row-gap: 0.2rem;
`

const EndDate = styled(StartDate)``

const DateValue = styled.div`
  padding: 0.7rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #cecece;
  background: #fff;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 400;
`
