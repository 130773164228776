import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import GearIcon from '../../../../asset/image/gear_icon.svg'
import Trash from '../../../../asset/image/trash2.svg'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import {getDispatchPeriodExtractYears} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {isEmptyArray} from '../../../../util/ValidationUtil'
import Calendar from '../../../../asset/image/calendar_blue.svg'
import {CDispatchPeriodInfo} from '../../../../model/DispatchPeriod'
import CheckBox from '../../../common/CheckBox'
import {CBusDispatchTable} from '../../../../model/Bus'
import {CDate} from '../../../../model/Date'
import DatePickerContainer from '../../../input/DatePickerContainer'
import DatePickerInput from '../../../input/DatePickerInput'

type Props = {
  dispatchPeriod: CDispatchPeriodInfo
  selectedDispatchTables: CBusDispatchTable[]
  isSelectedAll: boolean
  isDeleting: boolean
  isUpdatingStudents: boolean
  showSettingModal(): void
  onClickSelectAll(): void
  onClickDelete(): void
  onClickCancelDelete(): void
  onClickUpdatingStudents(): void
  onClickCancelUpdatingStudents(): void
  onSubmitDelete(): void
  showConfirmUpdateStudentsModal(): void
  deleteDate: CDate
  setDeleteDate: (date: CDate) => void
  updateStudentsDate: CDate
  setUpdateStudentsDate: (date: CDate) => void
}

export default function PeriodHeaderBase(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const [years, setYears] = useState<number[]>([])

  const fetchDispatchPeriodYears = useCallback(
    (aid: string) => {
      getDispatchPeriodExtractYears(aid)
        .then(ys => setYears(ys))
        .catch(error => {
          throw new Error(
            `getDispatchPeriodExtractYears() failed. (aid: ${aid}, error: ${error}) `,
          )
        })
    },
    [setYears],
  )

  const HeaderPickerComponent = useMemo(() => {
    if (isEmptyArray(years)) {
      return <div />
    }

    return (
      <SemesterContainer>
        <CalendarIcon source={Calendar} />
        <SemesterText>{props.dispatchPeriod.name}</SemesterText>
        <DispatchPeriodText>
          {props.dispatchPeriod.startDate.toString()}~
          {props.dispatchPeriod.endDate.toString()}
        </DispatchPeriodText>
      </SemesterContainer>
    )
  }, [years, props.dispatchPeriod])

  const onDelete = useCallback(() => {
    if (isEmptyArray(props.selectedDispatchTables)) {
      return
    }

    props.onSubmitDelete()
  }, [props.selectedDispatchTables, props.onSubmitDelete])

  const showConfirmUpdateStudentsModal = useCallback(() => {
    if (isEmptyArray(props.selectedDispatchTables)) {
      return
    }

    props.showConfirmUpdateStudentsModal()
  }, [props.selectedDispatchTables, props.showConfirmUpdateStudentsModal])

  useEffect(() => {
    fetchDispatchPeriodYears(academyID)
  }, [academyID])

  const onChangeDate = useCallback(
    (d: CDate) => {
      props.setDeleteDate(d)
    },
    [props.setDeleteDate],
  )

  const onChangeUpdateStudentsDate = useCallback(
    (d: CDate) => {
      props.setUpdateStudentsDate(d)
    },
    [props.setUpdateStudentsDate],
  )

  useEffect(() => {
    props.setDeleteDate(
      props.dispatchPeriod.startDate.isAfter(CDate.now())
        ? props.dispatchPeriod.startDate
        : props.deleteDate,
    )
  }, [])

  return (
    <DispatchHeader>
      {HeaderPickerComponent}

      {props.isDeleting ? (
        <DeleteWrapper>
          <SelectDeleteDateWrapper>
            <SelectDeleteDateText>배차 종료 및 삭제일</SelectDeleteDateText>
            <DatePickerContainer
              customInput={
                <DatePickerInput
                  readOnly={false}
                  customStyle={{
                    backgroundColor: '#fff',
                    borderRadius: '0 0.8rem 0.8rem 0',
                    height: '28px',
                    color: '#3067CB',
                  }}
                />
              }
              defaultValue={props.deleteDate}
              minDate={
                props.dispatchPeriod.startDate.isAfter(CDate.now())
                  ? props.dispatchPeriod.startDate
                  : CDate.now()
              }
              maxDate={props.dispatchPeriod.endDate}
              onChange={onChangeDate}
              placeholder={`${
                props.dispatchPeriod.startDate.isAfter(CDate.now())
                  ? props.dispatchPeriod.startDate
                  : props.deleteDate
              }`}
              readOnly={false}
            />
          </SelectDeleteDateWrapper>
          <SelectAllContainer>
            <CheckBox
              onClick={props.onClickSelectAll}
              readOnly={false}
              isChecked={props.isSelectedAll}
            />
            <SelectAllText onClick={props.onClickSelectAll}>
              전체 선택({props.selectedDispatchTables.length})
            </SelectAllText>
            <ButtonContainer>
              <CancelButton onClick={props.onClickCancelDelete}>
                취소
              </CancelButton>
              <SubmitButton
                disabled={isEmptyArray(props.selectedDispatchTables)}
                onClick={onDelete}>
                삭제
              </SubmitButton>
            </ButtonContainer>
          </SelectAllContainer>
        </DeleteWrapper>
      ) : props.isUpdatingStudents ? (
        <UpdateStudentsWrapper>
          <SelectUpdateStudentsDateWrapper>
            <SelectUpdateStudentsDateText>
              최신화 적용일
            </SelectUpdateStudentsDateText>
            <DatePickerContainer
              customInput={
                <DatePickerInput
                  readOnly={false}
                  customStyle={{
                    backgroundColor: '#fff',
                    borderRadius: '0 0.8rem 0.8rem 0',
                    height: '28px',
                    color: '#3067CB',
                  }}
                />
              }
              defaultValue={props.updateStudentsDate}
              minDate={
                props.dispatchPeriod.startDate.isAfter(CDate.now())
                  ? props.dispatchPeriod.startDate
                  : CDate.now()
              }
              maxDate={props.dispatchPeriod.endDate}
              onChange={onChangeUpdateStudentsDate}
              placeholder={`${
                props.dispatchPeriod.startDate.isAfter(CDate.now())
                  ? props.dispatchPeriod.startDate
                  : props.updateStudentsDate
              }`}
              readOnly={false}
            />
          </SelectUpdateStudentsDateWrapper>
          <SelectAllContainer>
            <CheckBox
              onClick={props.onClickSelectAll}
              readOnly={false}
              isChecked={props.isSelectedAll}
            />
            <SelectAllText onClick={props.onClickSelectAll}>
              전체 선택({props.selectedDispatchTables.length})
            </SelectAllText>
            <ButtonContainer>
              <CancelButton onClick={props.onClickCancelUpdatingStudents}>
                취소
              </CancelButton>
              <SubmitButton
                disabled={isEmptyArray(props.selectedDispatchTables)}
                onClick={showConfirmUpdateStudentsModal}>
                이용 학생 최신화
              </SubmitButton>
            </ButtonContainer>
          </SelectAllContainer>
        </UpdateStudentsWrapper>
      ) : (
        <Fragment>
          {/* {HeaderPickerComponent} */}
          <DeleteButton onClick={props.onClickDelete}>
            <ModifyImg source={Trash} />
            배차 종료 및 삭제
          </DeleteButton>
          <DeleteButton onClick={props.onClickUpdatingStudents}>
            <ModifyImg source={Trash} />
            배차 이용 학생 최신화
          </DeleteButton>
          {/* <ModifyScheduleButton onClick={props.showSettingModal}>
            <ModifyImg source={GearIcon} />
            운행 설정
          </ModifyScheduleButton> */}
        </Fragment>
      )}
    </DispatchHeader>
  )
}

const DispatchHeader = styled.div`
  ${flexRow};
  align-items: center;
`

const ModifyScheduleButton = styled.button`
  ${flexRow};
  align-items: center;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  background: #332a00;
  border: none;
  border-radius: 3.4rem;
  color: #fff;
  margin-left: 0.8rem;
`

const DeleteButton = styled.button`
  ${flexRow};
  align-items: center;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  background: #ffffff;
  border: 0.1rem solid #997d00;
  border-radius: 3.4rem;
  color: #997d00;
  margin-left: 0.8rem;
`

const ModifyImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const SemesterContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const CalendarIcon = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const SemesterText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #2767ce;
  margin-right: 0.2rem;
`

const DispatchPeriodText = styled.div`
  color: #2767ce;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const SelectAllContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 1rem;
`

const SelectAllText = styled.div`
  color: #665300;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 0.2rem;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
  margin-left: 1.6rem;
`

const CancelButton = styled.div`
  width: 5.2rem;
  height: 3rem;
  ${flexRow};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #332a00;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  letter-spacing: -0.36px;
  background: #ebebeb;
  border-radius: 3.4rem;
`

const SubmitButton = styled.div<{disabled: boolean}>`
  min-width: 5.2rem;
  height: 3rem;
  ${flexRow};
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: #332a00;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 18px; /* 150% */
  letter-spacing: -0.36px;
  background: #ffd100;
  border-radius: 3.4rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 0 15px;
`

const DeleteWrapper = styled.div`
  ${flexRow}
  align-items:center;
  margin-left: 20px;
`

const SelectDeleteDateWrapper = styled.div`
  ${flexRow}
  align-items:center;
  font-size: 12px;
`

const SelectDeleteDateText = styled.div`
  ${flexRow}
  align-items:center;
  font-size: 12px;
  display: flex;
  height: 28px;
  padding: 0px 6px 0px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #ebebeb;
  border-right: 0;
  background: #fffbe5;
`

const UpdateStudentsWrapper = styled.div`
  ${flexRow}
  align-items:center;
  margin-left: 20px;
`

const SelectUpdateStudentsDateWrapper = styled.div`
  ${flexRow}
  align-items:center;
  font-size: 12px;
`

const SelectUpdateStudentsDateText = styled.div`
  ${flexRow}
  align-items:center;
  font-size: 12px;
  display: flex;
  height: 28px;
  padding: 0px 6px 0px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #ebebeb;
  border-right: 0;
  background: #fffbe5;
`
