import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../style/CommonStyle'
import LeftArrow from '../../../asset/image/left_arrow_black.svg'
import RightArrow from '../../../asset/image/right_arrow_black.svg'
import SVGImage from '../../common/SVGImage'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../type/Common'
import {academyIDState} from '../../../recoil/Atom'
import {useLocation} from 'react-router'
import {isNil} from '../../../util/ValidationUtil'
import {CManagerDispatches} from '../../../model/Manager'
import TableList from '../../common/TableList'
import {CDate} from '../../../model/Date'
import {CDateTime} from '../../../model/DateTime'
import DatePicker from '../../input/DatePicker'
import {BaseDateUnitEnum} from '../../../enum/DateUnitEnum'
import {getDriverDispatches} from '../../../service/driver/Driver'

const TITLE_MAP = {
  bus: '호차',
  dispatch: '배차명',
  driver: '매니저명',
  seatInfo: '좌석 정보',
}

type StateProps = {
  state: {
    driverID: number
  }
}

function DriverBuses() {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const {state} = useLocation() as StateProps
  const driverID = state.driverID
  const [dispatches, setDispatches] = useState<CManagerDispatches[]>([])
  const [dispatchDate, setDispatchDate] = useState<CDate>(CDate.now())

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return dispatches.map(d => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.bus, <div>{d.busName}</div>)
      node.set(TITLE_MAP.dispatch, <div>{d.dispatchName}</div>)
      node.set(TITLE_MAP.driver, <div>{d.managerName}</div>)
      node.set(
        TITLE_MAP.seatInfo,
        <div>
          잔여 {d.remainingSeatCount}개, 타요 {d.willBoardCount}명, 안타요{' '}
          {d.wontBoardCount}명
        </div>,
      )
      return node
    })
  }, [dispatches])

  const onClickRow = useCallback(
    (idx: number) => {
      if (isNil(dispatches)) {
        return
      }
    },
    // TODO: 배차 상세페이지 로직 필요
    [dispatches],
  )

  const fetchDriverDispathes = useCallback(() => {
    getDriverDispatches(
      selectedAcademyID,
      driverID.toString(),
      dispatchDate.toString(),
    )
      .then(dd => setDispatches(dd))
      .catch(error => {
        throw new Error(
          `failed to get manager dispatches. (academyID: ${selectedAcademyID}, error: ${error})`,
        )
      })
  }, [dispatchDate])

  useEffect(() => {
    fetchDriverDispathes()
  }, [selectedAcademyID, driverID, dispatchDate])

  const handlePrevDay = () => {
    const day = CDateTime.create(dispatchDate.toString())
      .add(-1, BaseDateUnitEnum.DAY)
      .toDate()
    setDispatchDate(day)
  }

  const handleNextDay = () => {
    const day = CDateTime.create(dispatchDate.toString())
      .add(+1, BaseDateUnitEnum.DAY)
      .toDate()
    setDispatchDate(day)
  }

  return (
    <Container>
      <DateContainer>
        <Title>
          배차 정보 {!isNil(dispatches) && `(${dispatches?.length})`}
        </Title>
        <DateWrapper>
          <ImgWrapper onClick={handlePrevDay}>
            <Img source={LeftArrow} />
          </ImgWrapper>
          <Date onClick={() => {}}>
            <DatePicker
              onChange={(date: CDate) => setDispatchDate(date)}
              theme={'blue'}
              defaultDate={dispatchDate}
            />
          </Date>
          <ImgWrapper onClick={handleNextDay}>
            <Img source={RightArrow} />
          </ImgWrapper>
          <TodayButton
            onClick={() => setDispatchDate(CDateTime.now().toDate())}>
            오늘
          </TodayButton>
        </DateWrapper>
      </DateContainer>

      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        tableHeaderStyle={{background: '#f5f5f5'}}
        placeholder={'등록된 배차 정보가 없습니다.'}
      />
    </Container>
  )
}

export default DriverBuses

const Container = styled.div`
  margin-top: 1.2rem;
  padding: 1.2rem;
  background: #fff;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const DateContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const DateWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.6rem;
`

const Date = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.2rem;
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-left: 0.8rem;
`

const Img = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const TodayButton = styled.button`
  border: 0.1rem solid #ebebeb;
  border-radius: 3.4rem;
  background: #fff;
  padding: 0.4rem 0.8rem;

  :hover {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  }
`

const ImgWrapper = styled.div``
