import React, {ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import InputHeader from '../../../../../input/InputHeader'
import TextInput from '../../../../../input/TextInput'
import {CDispatchPeriodInfo} from '../../../../../../model/DispatchPeriod'
import {CBusBrief} from '../../../../../../model/Bus'
import {SelectedBusInfo} from '../../table/PeriodTable'
import {useRecoilValue} from 'recoil'
import {academyState} from '../../../../../../recoil/Atom'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
  isStart: boolean
}

export default function BasicInfo(props: Props): ReactElement {
  const academy = useRecoilValue(academyState)

  const defaultPeriod = useMemo(() => {
    return `${
      props.dispatchPeriod.name
    } | ${props.dispatchPeriod.startDate.toString()} ~ ${props.dispatchPeriod.endDate.toString()}`
  }, [props.dispatchPeriod])

  const defaultBusInfo = useMemo(() => {
    return `${props.busBrief.bus.name}(${props.busBrief.bus.regNum}) | ${props.busBrief.driver[0].name}(${props.busBrief.driver[0].phone}) | 기용 좌석 ${props.busBrief.bus.totalSeat}개`
  }, [props.busBrief])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>기본 정보</HeaderText>
        <HeaderInfoText>등록 학원 정보 : {academy.name}</HeaderInfoText>
      </HeaderContainer>
      <InputContainer>
        <InputRowContainer>
          <DispatchPeriodInputContainer>
            <StyledInputHeader
              headerColor={'#999999'}
              header={'배차 기간'}
              required={false}
            />
            <TextInput
              readOnly={true}
              required={false}
              default={defaultPeriod}
            />
          </DispatchPeriodInputContainer>
          <DispatchDayInputContainer>
            <StyledInputHeader
              headerColor={'#999999'}
              header={'배차 요일'}
              required={false}
            />
            <TextInput
              readOnly={true}
              required={false}
              default={`${props.selectedBusInfo.weekday.exposure}요일`}
            />
          </DispatchDayInputContainer>
          <DispatchTimeInputContainer>
            <StyledInputHeader
              headerColor={'#999999'}
              header={'배차 시간'}
              required={false}
            />
            <TextInput
              readOnly={true}
              required={false}
              default={`${props.selectedBusInfo.classTime} | ${
                props.isStart ? '등원' : '하원'
              }`}
            />
          </DispatchTimeInputContainer>
        </InputRowContainer>
        <BusInfoContainer>
          <StyledInputHeader
            headerColor={'#999999'}
            header={'호차 정보'}
            required={false}
          />
          <TextInput
            readOnly={true}
            required={false}
            default={defaultBusInfo}
          />
        </BusInfoContainer>
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
`
const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
  margin-bottom: 0.5rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
const HeaderInfoText = styled.div`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #3575d9;
`

const InputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
`

const InputRowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const StyledInputHeader = styled(InputHeader)`
  width: 5.4rem;
`

const DispatchPeriodInputContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 45;
`

const DispatchDayInputContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 25;
`

const DispatchTimeInputContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 30;
`

const BusInfoContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  height: 4.6rem;
  flex: 1;
`
