import React, {ForwardedRef, forwardRef, ReactElement, useCallback} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import DragIcon from '../../../asset/image/drag_icon.svg'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {CBusOperationSchedule} from '../../../model/Bus'

type OperationDetailEditBoardingProps = {
  schedule: CBusOperationSchedule
  index: number
  type: string
  stationIndex: number
}
export type OperationDetailEditBoardingRef = {}

function OperationDetailEditBoardingBase(
  props: OperationDetailEditBoardingProps,
  _: ForwardedRef<OperationDetailEditBoardingRef>,
): ReactElement {
  const getDraggableID = useCallback(
    (s: CBusOperationSchedule) => {
      return JSON.stringify({
        schedule: s,
        type: props.type,
        stationIndex: props.stationIndex,
      })
    },
    [props.type, props.stationIndex],
  )

  return (
    <StudentsContainer>
      <Draggable draggableId={getDraggableID(props.schedule)} index={props.index}>
        {(provided, _) => (
          <StudentContent ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <DragImg source={DragIcon} />
            <StudentName>{props.schedule.student.name}</StudentName>
          </StudentContent>
        )}
      </Draggable>
    </StudentsContainer>
  )
}

const OperationDetailEditBoarding = forwardRef(OperationDetailEditBoardingBase)
export default OperationDetailEditBoarding

const StudentsContainer = styled.div`
  ${flexColumn};
  row-gap: 1.2rem;
  margin-top: 0.3rem;
  max-width: 20rem;
`

const StudentContent = styled.div`
  ${flexRow};
  width: 19rem;
  height: 3rem;
  padding: 0 0.6rem;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 0.1rem solid rgba(208, 208, 208, 0.6);
  border-radius: 0.4rem;
`

const DragImg = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const StudentName = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.7rem 0.5rem 0.5rem;
  font-weight: 400;
`
