import React, {ReactElement, useCallback, useMemo} from 'react'
import TimeInput, {TimeInputRef} from '../../../../../input/TimeInput'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../model/Dispatch'
import useSecureRef from '../../../../../../hook/useSecureRef'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import SVGImage from '../../../../../common/SVGImage'
import Start from '../../../../../../asset/image/station_start.svg'
import Arrive from '../../../../../../asset/image/station_arrive.svg'
import Number from '../../../../../../asset/image/station_number2.svg'
import StudentItem from './StudentItem'

type Props = {
  course: CDispatchCourseWithStudent
  isFirstItem: boolean
  isLastItem: boolean
  isStart: boolean
  selectedStudents: CStudentWithCheck[]
  onChangeStudent(ss?: CStudentWithCheck, time?: string): void
}

export default function CoursesMovableItem(props: Props): ReactElement {
  const timeInputRef = useSecureRef<TimeInputRef>(
    '[CourseColumnItem.tsx] timeInputRef',
  )

  const CountComponent = useMemo(() => {
    if (props.isFirstItem) {
      return <StationIcon source={Start} />
    }

    if (props.isLastItem) {
      return <StationIcon source={Arrive} />
    }

    return <StationIcon source={Number} />
  }, [props.isLastItem, props.isFirstItem])

  const StationContentComponent = useMemo(() => {
    if (props.isFirstItem) {
      return <StartContent>출발</StartContent>
    }

    if (props.isLastItem) {
      return <ArriveContent>도착</ArriveContent>
    }

    return <StationContent>{props.course.sort - 1}</StationContent>
  }, [props.isLastItem, props.isFirstItem, props.course])

  const headerColor = useMemo(() => {
    if (props.isFirstItem) {
      return '#1a1a1a'
    }

    if (props.isLastItem) {
      return '#404040'
    }

    return '#665300'
  }, [props.isLastItem, props.isFirstItem])

  const onChangeStudent = useCallback(
    (s: CStudentWithCheck) => {
      props.onChangeStudent(s)
    },
    [props.onChangeStudent],
  )

  const onChangeTime = useCallback(
    (value: string) => {
      props.onChangeStudent(null, value)
    },
    [props.onChangeStudent],
  )

  const StudentListComponent = useMemo(() => {
    return (
      <ListContainer>
        {props.course.students.map((student, idx) => {
          return (
            <StudentItem
              readOnly={
                (props.isStart && props.isLastItem) ||
                (!props.isStart && props.isFirstItem)
              }
              student={student}
              isChecked={props.selectedStudents.some(
                ss => ss.id === student.id,
              )}
              onChangeStudent={onChangeStudent}
              key={idx}
            />
          )
        })}
      </ListContainer>
    )
  }, [
    props.course,
    props.selectedStudents,
    props.isFirstItem,
    props.isLastItem,
    props.isStart,
  ])

  return (
    <Container
      style={{borderRadius: props.isLastItem ? '0 0 0.8rem 0.8rem' : 0}}>
      <TimeContainer>
        <StationIconContainer>
          {CountComponent}
          {StationContentComponent}
        </StationIconContainer>
      </TimeContainer>
      <ContentContainer>
        <HeaderAndTimeInputContainer>
          <TimeInput
            ref={timeInputRef.ref}
            readOnly={
              (props.isStart && props.isLastItem) ||
              (!props.isStart && props.isFirstItem)
            }
            default={props.course.arrivalTime}
            onChange={onChangeTime}
          />
          <Header color={headerColor}>{props.course.stationName}</Header>
        </HeaderAndTimeInputContainer>
        {StudentListComponent}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  padding: 0.8rem 0.8rem 1.2rem 0.8rem;
  background: #ffffff;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TimeContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const StationIconContainer = styled.div`
  width: 1.867rem;
  height: 2.133rem;

  position: relative;
  ${flexColumn};
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
`

const StationIcon = styled(SVGImage)`
  width: 1.867rem;
  height: 2.133rem;
`

const StationContent = styled.div`
  position: absolute;
  color: #665300;
  text-align: center;
  font-size: 0.8667rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const StartContent = styled(StationContent)`
  font-size: 0.73rem;
  color: #1a468b;
`

const ArriveContent = styled(StationContent)`
  font-size: 0.73rem;
  color: #000000;
`

const ContentContainer = styled.div`
  ${flexColumn};
  flex: 1;
  overflow: auto;
  row-gap: 1.3rem;

  ::-webkit-scrollbar {
    display: none;
  }
`

const HeaderAndTimeInputContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const Header = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.39px;
`

const ListContainer = styled.div`
  ${flexColumn};
  row-gap: 0.6rem;
  padding-left: 0.4rem;
`
