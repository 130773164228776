import React, {ReactNode, useCallback, useMemo, useState} from 'react'
import {isNil} from '../../../../../../../util/ValidationUtil'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import InputHeader from '../../../../../../input/InputHeader'
import ArrowDown from '../../../../../../../asset/image/arrowDown.svg'
import {CCourse} from '../../../../../../../model/Course'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import {Optional} from '../../../../../../../type/Common'
import Modal, {ModalRef} from '../../../../../../common/Modal'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import ClassTimeAdd from './ClassTimeAdd'
import {SelectedBusInfo} from '../../../table/PeriodTable'
import {CDispatchCirculationTime} from '../../../../../../../model/Dispatch'

type Props = {
  selectedCourse: CCourse
  selectedBusInfo: SelectedBusInfo
  onChangeCirculationTime(ct: CDispatchCirculationTime): void
}

export default function ClassTimeInput(props: Props) {
  const addClassTimeModalRef = useSecureRef<ModalRef>(
    '[OperationInfoNormal.tsx] addClassTimeModalRef',
  )

  const [selectedClassTime, setSelectedClassTime] =
    useState<Optional<CDispatchCirculationTime>>(null)

  const onClickAddClassTime = useCallback(() => {
    addClassTimeModalRef.current().show()
  }, [])

  const hideAddClassTime = useCallback(() => {
    addClassTimeModalRef.current().hide()
  }, [])

  const onSubmitClassTime = useCallback(
    (ct: CDispatchCirculationTime) => {
      setSelectedClassTime(ct)
      props.onChangeCirculationTime(ct)
      hideAddClassTime()
    },
    [hideAddClassTime, props.onChangeCirculationTime],
  )

  const ClassTimeComponent = useMemo((): ReactNode => {
    if (isNil(selectedClassTime)) {
      return (
        <ClassTimeText hasValue={!isNil(props.selectedCourse)}>
          순환으로 배정할 수업 시간을 선택해주세요.
        </ClassTimeText>
      )
    }

    return (
      <ClassTimeText hasValue={!isNil(props.selectedCourse)}>
        {selectedClassTime.dispatchTime}
      </ClassTimeText>
    )
  }, [selectedClassTime])

  if (isNil(props.selectedCourse)) {
    return null
  }

  if (props.selectedCourse.dispatchType !== DispatchTypeEnum.CIRCULATION) {
    return null
  }

  return (
    <Container>
      <Modal zIndex={3} ref={addClassTimeModalRef.ref}>
        <ClassTimeAdd
          onChange={onSubmitClassTime}
          hide={hideAddClassTime}
          selectedBusInfo={props.selectedBusInfo}
          selectedClassTime={selectedClassTime}
        />
      </Modal>

      <InputHeaderContainer>
        <InputHeader header={'수업 시간 추가'} required={true} />
      </InputHeaderContainer>
      <InputContainer>
        <InputBox
          hasValue={!isNil(selectedClassTime)}
          onClick={onClickAddClassTime}>
          {ClassTimeComponent}
          <ArrowDownImage source={ArrowDown}></ArrowDownImage>
        </InputBox>
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const InputContainer = styled.div`
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
`

const InputBox = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const ClassTimeText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`
