import React, {ForwardedRef, forwardRef, ReactElement} from 'react'
import styled from 'styled-components'
import BlueCalendar from '../../asset/image/calendar_blue.svg'
import {CDate} from '../../model/Date'
import SVGImage from '../common/SVGImage'

type DatePickerCustomInputProps = {
  value: string
  onClick?: () => void
}
export type DatePickerCustomInputRef = {}

function DatePickerBlueInputBase(
  props: DatePickerCustomInputProps,
  _: ForwardedRef<DatePickerCustomInputRef>,
): ReactElement {
  const Date = CDate.create(props.value)

  return (
    <DatePickerButton onClick={props.onClick}>
      <Calendar source={BlueCalendar} />
      <LabelWrapper>
        <DatePickerText>{`${props.value} (${Date.toDay().exposure})`}</DatePickerText>
      </LabelWrapper>
    </DatePickerButton>
  )
}

const DatePickerBlueInput = forwardRef(DatePickerBlueInputBase)
export default DatePickerBlueInput

const DatePickerButton = styled.button`
  padding: 0.1rem;
  min-width: 11rem;
  max-width: 11rem;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.8rem;
  border: none;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  }
`

const Calendar = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`

const LabelWrapper = styled.div`
  > label {
    cursor: pointer;
  }
`

const DatePickerText = styled.span`
  font-size: 1.2rem;
  text-align: center;
  line-height: 2rem;
  font-weight: 500;
  color: #2767ce;
`
