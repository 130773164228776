import React from 'react'
import styled from 'styled-components'
import {SearchStationEnum} from '../../../enum/SearchStationEnum'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import PlusIcon from '../../../asset/image/plus_icon.svg'

type StationSideBarEmptyProps = {
  select: string
  handleAddCourseButton: () => void
}

function StationSideBarEmpty(props: StationSideBarEmptyProps) {
  return (
    <EmptyRouteList>
      <EmptyText>
        등록된{' '}
        {props.select === SearchStationEnum.NAME.value
          ? `정류장이 `
          : props.select === SearchStationEnum.ADDRESS.value
            ? `주소가 `
            : props.select === SearchStationEnum.PLACE.value
              ? `장소가 `
              : `노선이 `}
        없습니다.
      </EmptyText>
      {props.select === SearchStationEnum.ROUTE.value && (
        <RouteButton onClick={props.handleAddCourseButton}>
          <PlusIconImg source={PlusIcon} />
          노선 추가
        </RouteButton>
      )}
    </EmptyRouteList>
  )
}

export default StationSideBarEmpty

const EmptyRouteList = styled.div`
  padding: 6rem 1.6rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  align-items: center;
`

const EmptyText = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #999;
  margin-bottom: 1.6rem;
`

export const RouteButton = styled.button`
  border: none;
  background: #ffd100;
  border-radius: 3.4rem;
  width: 8.3rem;
  height: 2.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  ${flexRow};
  align-items: center;
`

export const PlusIconImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`
