import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker'
import {ko} from 'date-fns/locale'
import {CDate} from '../../model/Date'
import {CDateTime} from '../../model/DateTime'
import styled from 'styled-components'
import PrevButton from '../../asset/image/prev_button.svg'
import NextButton from '../../asset/image/next_button.svg'
import {isNil} from '../../util/ValidationUtil'
import {Optional} from '../../type/Common'

type DatePickerContainerProps = {
  defaultValue?: CDate
  minDate?: CDate
  maxDate?: CDate
  placeholder?: string
  readOnly: boolean
  onChange?(date: CDate): void
} & Omit<ReactDatePickerProps, 'onChange' | 'minDate' | 'maxDate'>
export type DatePickerContainerRef = {
  getValue(): Optional<CDate>
  reset(): void
}

function DatePickerContainerBase(
  props: DatePickerContainerProps,
  ref: ForwardedRef<DatePickerContainerRef>,
): ReactElement {
  const [value, setValue] = useState<Optional<CDate>>(props.defaultValue)

  const onChange = useCallback(
    (date: Date, e: React.SyntheticEvent<any>) => {
      const d = CDate.create(date)
      setValue(d)

      if (!isNil(props.onChange)) {
        props.onChange(d)
      }
    },
    [props.onChange],
  )

  const selectedValue = useMemo(() => {
    if (isNil(value)) {
      return null
    }

    return value.toDateRaw()
  }, [value])

  const stringifiedValue = useMemo(() => {
    if (isNil(value)) {
      return null
    }

    return value.toString()
  }, [value])

  const minDate = useMemo(() => {
    const md = props.minDate

    if (isNil(md)) {
      return null
    }

    return md.toDateRaw()
  }, [props.minDate])

  const maxDate = useMemo(() => {
    const md = props.maxDate

    if (isNil(md)) {
      return null
    }

    return md.toDateRaw()
  }, [props.maxDate])

  const renderCustomHeader = useCallback(
    (params: ReactDatePickerCustomHeaderProps) => {
      const d = CDateTime.create(params.date).format('YYYY년 MM월')

      return (
        <MonthArrowWrapper>
          <DecreaseMonthButton onClick={params.decreaseMonth} />
          <CurrentMonth>{d}</CurrentMonth>
          <NextMonthButton onClick={params.increaseMonth} />
        </MonthArrowWrapper>
      )
    },
    [],
  )

  useEffect(() => {
    if (isNil(props.defaultValue)) {
      return
    }

    setValue(props.defaultValue)
  }, [props.defaultValue])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): Optional<CDate> {
        return value
      },
      reset(): void {
        setValue(null)
      },
    }),
    [value, setValue],
  )

  return (
    <Container>
      <ReactDatePicker
        locale={ko}
        selected={selectedValue}
        dateFormat={'yyyy-MM-dd'}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        readOnly={props.readOnly}
        showPopperArrow={true}
        disabled={props.readOnly}
        customInput={props.customInput}
        renderCustomHeader={renderCustomHeader}
        placeholderText={props.placeholder}
      />
    </Container>
  )
}

const DatePickerContainer = forwardRef(DatePickerContainerBase)
export default DatePickerContainer

const Container = styled.div`
  .react-datepicker-popper {
    border-width: 0;
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .react-datepicker {
    padding: 1.6rem 1.2rem;
    width: 27.2rem;
    position: absolute;
    background: #ffffff;
    top: 1rem;
    z-index: 1000;
    border-radius: 0.8rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
    right: 0;
    .react-datepicker__header {
      background-color: #fff;
      color: #000000;
      border-bottom: none;
      border-radius: 0;
    }

    .react-datepicker__header.react-datepicker__header--custom {
      width: 100%;
    }

    .react-datepicker__day-names {
      width: 100%;
      max-width: 100rem;
      display: flex;
      justify-content: space-between;

      .react-datepicker__day-name {
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__day {
      width: 3.2rem;
      height: 3.2rem;
      font-weight: 300;

      &:hover {
        background: #ffe880;
      }
    }

    .react-datepicker__day--selected {
      width: 3.2rem;
      height: 3.2rem;
      background: #ffe880;
    }
  }
`

const MonthArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 0 0.8rem;
  margin-bottom: 1.4rem;
`

const DecreaseMonthButton = styled.div`
  background: url(${PrevButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const CurrentMonth = styled.span`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.042rem;
`

const NextMonthButton = styled.div`
  background: url(${NextButton}) center center no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`
