export class CPaging {
  private constructor(
    readonly pageContentSize: number,
    readonly currentPageNumber: number,
    readonly totalPages: number,
    readonly totalElements: number,
    readonly lastPage: boolean,
    readonly firstPage: boolean,
  ) {}

  static create(json: any): CPaging {
    return new CPaging(
      json.pageContentSize,
      json.currentPageNumber,
      json.totalPages,
      json.totalElements,
      json.lastPage,
      json.firstPage,
    )
  }
}
