import React, {ReactElement, useCallback} from 'react'
import useSecureRef from '../../../../hook/useSecureRef'
import styled from 'styled-components'
import Header from '../../../common/Header'
import {flexColumn} from '../../../../style/CommonStyle'
import DispatchSemesterSelectContainer, {
  DispatchSemesterSelectContainerRef,
} from './DispatchSemesterSelectContainer'
import DispatchSemesterSelectList, {
  DispatchSemesterSelectListRef,
} from './DispatchSemesterSelectList'
import {CSemester} from '../../../../model/Semester'
import Footer from '../../../common/Footer'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {WeekdayEnum} from '../../../../enum/WeekdayEnum'

type DispatchSemesterSelectModalProps = {
  onSubmit(ss: CSemester): void
  onChangeSelectableWeekdays(wds: WeekdayEnum[]): void
  onCancel(): void
}

export default function DispatchSemesterSelect(
  props: DispatchSemesterSelectModalProps,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const selectRef = useSecureRef<DispatchSemesterSelectContainerRef>(
    '[DispatchSemesterSelect.tsx]',
  )
  const listRef = useSecureRef<DispatchSemesterSelectListRef>(
    '[DispatchSemesterSelect.tsx] listRef',
  )

  const onSubmit = useCallback(() => {
    const ss = selectRef.current().getSelectedSemester()
    props.onSubmit(ss)
  }, [props.onSubmit])

  const onChangeSemester = useCallback(
    (sid: number) => {
      listRef.current().fetchSemesterTime({
        academyID: academyID,
        semesterID: sid,
      })
    },
    [academyID],
  )

  return (
    <Container>
      <StyledHeader>학기 및 수업시간 선택</StyledHeader>
      <PeriodWrapper>
        <DispatchSemesterSelectContainer
          ref={selectRef.ref}
          onChangeSemester={onChangeSemester}
        />
        <DispatchSemesterSelectList
          ref={listRef.ref}
          onChangeSelectableWeekdays={props.onChangeSelectableWeekdays}
        />
      </PeriodWrapper>
      <Footer
        submitText={'선택'}
        onSubmit={onSubmit}
        onCancel={props.onCancel}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  position: absolute;
  right: 0;
  width: 37.6rem;
  height: 100%;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  border-radius: 1.6rem 0 0 1.6rem;
  background: #ffffff;
`
const PeriodWrapper = styled.div`
  flex: 1;
  padding: 1.6rem;
  background: #f5f5f5;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
  ${flexColumn};
  justify-content: center;
  padding-left: 1.6rem;
`
