import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../style/CommonStyle'
import ScreenContainer from '../../component/layout/ScreenContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import BackArrow from '../../asset/image/back_page.svg'
import SVGImage from '../../component/common/SVGImage'
import {useLocation, useNavigate, useParams} from 'react-router'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../recoil/Atom'
import {isNil} from '../../util/ValidationUtil'
import {Optional} from '../../type/Common'
import {getBusBrief, getDispatchDetailByBusID} from '../../service/buses/Buses'
import {CBusBrief} from '../../model/Bus'
import DispatchInfo from '../../component/busDetail/dispatchInfo/DispatchInfo'
import BasicInfo from '../../component/busDetail/basicInfo/BasicInfo'
import {CDispatchPeriodInfo} from '../../model/DispatchPeriod'
import {BusDetailLocationEnum} from '../../enum/BusDetailLocationEnum'

type Props = {}
export type StateProps = {
  from: string
  dispatchPeriodID: number
}

export default function BusDetail(_: Props): ReactElement {
  const navigate = useNavigate()
  const academyID = useRecoilValue(academyIDState)
  const params = useParams()
  const location = useLocation()

  const locationFrom = useMemo(() => {
    const state = location.state as StateProps
    return BusDetailLocationEnum.parse(state.from)
  }, [location])

  const defaultDispatchPeriodID = useMemo(() => {
    const state = location.state as StateProps

    if (isNil(state.dispatchPeriodID)) {
      return null
    }

    return state.dispatchPeriodID
  }, [location])

  const [busBrief, setBusBrief] = useState<Optional<CBusBrief>>(null)
  const [dispatchPeriods, setDispatchPeriods] =
    useState<Optional<CDispatchPeriodInfo[]>>(null)

  const busID = useMemo((): Optional<number> => {
    const temp: any = params.id

    if (isNil(temp)) {
      return null
    }

    const ni = Number(temp)
    if (isNil(ni) || isNaN(ni)) {
      return null
    }

    return ni
  }, [params])

  const fetchBusBrief = useCallback(
    (aid: string, bid: number) => {
      getBusBrief(aid, bid)
        .then((bd: CBusBrief) => {
          setBusBrief(bd)
        })
        .catch(error => {
          navigate(-1)
          throw new Error(
            `getBusBrief() failed. (academyID: ${aid}, busID: ${bid}, error: ${error})`,
          )
        })
    },
    [setBusBrief, navigate],
  )

  const fetchDispatchPeriod = useCallback(
    (aid: string, bid: number) => {
      getDispatchDetailByBusID(academyID, busID)
        .then((dps: any) => {
          setDispatchPeriods(dps)
        })
        .catch(error => {
          throw new Error(
            `getDispatchDetailByBusID() failed. (academyID: ${aid}, busID: ${bid}, error: ${error})`,
          )
        })
    },
    [setDispatchPeriods],
  )

  const refreshBusBrief = useCallback(() => {
    fetchBusBrief(academyID, busID)
  }, [academyID, busID])

  useEffect(() => {
    fetchBusBrief(academyID, busID)
    fetchDispatchPeriod(academyID, busID)
  }, [busID, academyID])

  if (isNil(busID)) {
    alert('배차 기간 정보가 없습니다.')
    navigate(-1)
  }

  if (isNil(busBrief) || isNil(dispatchPeriods)) {
    return null
  }

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>
        <ImgWrapper onClick={() => navigate(-1)}>
          <BackImg source={BackArrow} />
        </ImgWrapper>
        <BusName>{busBrief.bus.name}</BusName> 상세 정보
      </PageHeaderWithAcademies>
      <Container>
        <BasicInfo busBrief={busBrief} fetchBusBrief={refreshBusBrief} />
        <DispatchInfo
          busBrief={busBrief}
          dispatchPeriods={dispatchPeriods}
          defaultDispatchPeriodID={defaultDispatchPeriodID}
          location={locationFrom}
        />
      </Container>
    </ScreenContainer>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  width: 100%;
  height: calc(100vh - 14.1rem);
  row-gap: 1.2rem;
`

const ImgWrapper = styled.div``

const BackImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`
const BusName = styled.span`
  color: #2767ce;
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 3rem;
`
