import React, {useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import ChairIcon from '../../../../asset/image/chair.svg'
import SVGImage from '../../../common/SVGImage'
import DispatchDetailInfoItem from '../period/detail/info/DispatchDetailInfoItem'
import {OccupancyTypeEnum} from '../../../../enum/OccupancyTypeEnum'
import {CDispatchDetailDispatch} from '../../../../model/Dispatch'
import {CBusBrief, CBusDispatchSummary} from '../../../../model/Bus'

type Props = {
  busBrief: CBusBrief
  dispatches: CDispatchDetailDispatch[]
  dispatchSummary: CBusDispatchSummary
}

export default function DispatchDetailOneDayInfo(props: Props) {
  const studentCount = useMemo(() => {
    return props.dispatches.reduce((acc, curr) => {
      if (acc < curr.students.length) {
        acc = curr.students.length
      }

      return acc
    }, 0)
  }, [props.dispatches])

  return (
    <Container>
      <StationPickerContainer>
        <StationPickerHeader>정류장 이용정보</StationPickerHeader>
      </StationPickerContainer>
      <SeatInfoContainer>
        <OperationContainer>
          <OperationText>운행 예정</OperationText>
          <BoardCheckContainer>
            <BoardText>
              · 타요 {props.dispatchSummary.seat.willBoard}명
            </BoardText>
            <NotBoardText>
              · 안타요 {props.dispatchSummary.seat.wontBoard}명
            </NotBoardText>
          </BoardCheckContainer>
        </OperationContainer>
        <SeatStatusContainer>
          <SeatStatusIcon source={ChairIcon} />
          <SeatStatusText>
            {studentCount}/{props.busBrief.bus.totalSeat}
          </SeatStatusText>
        </SeatStatusContainer>
      </SeatInfoContainer>
      <DispatchDetailListContainer>
        {props.dispatches.map((dispatch, idx) => {
          return (
            <DispatchDetailInfoItem
              key={idx}
              isFirstItem={idx === 0}
              isLastItem={idx === props.dispatches.length - 1}
              dispatch={dispatch}
              type={OccupancyTypeEnum.NORMAL}
              index={idx}
            />
          )
        })}
      </DispatchDetailListContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  overflow: auto;
  border-radius: 0.8rem;
`

const StationPickerContainer = styled.div`
  padding: 1.2rem;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background-color: #332a00;
`

const StationPickerHeader = styled.div`
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.39px;
`
const SeatInfoContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  background: #fffbe5;
  padding: 0.6rem 1.2rem;
`

const OperationContainer = styled.div`
  ${flexRow};
  column-gap: 3.6rem;
  align-items: center;
`

const OperationText = styled.div`
  color: #332a00;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 16.5px */
  letter-spacing: -0.33px;
`

const BoardCheckContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const BoardText = styled.div`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.33px;
`

const NotBoardText = styled(BoardText)`
  color: #999999;
`

const SeatStatusContainer = styled.div`
  ${flexRow};
`

const SeatStatusIcon = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const SeatStatusText = styled.div`
  color: #ac2016;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4rem;
`

const DispatchDetailListContainer = styled.div`
  ${flexColumn};
  flex: 1;
  background: #ffffff;
  padding: 0.8rem;
`
