export class BusOperationStatusEnum {
  static readonly READY = new BusOperationStatusEnum(0, '운행대기')
  static readonly PROCEED = new BusOperationStatusEnum(1, '운행중')
  static readonly DONE = new BusOperationStatusEnum(2, '운행완료')
  static readonly NOT_RUNNING = new BusOperationStatusEnum(3, '미운행')

  private constructor(
    readonly value: number,
    readonly exposure: string,
  ) {}

  static parse(value: number) {
    if (value === BusOperationStatusEnum.READY.value) {
      return BusOperationStatusEnum.READY
    }

    if (value === BusOperationStatusEnum.PROCEED.value) {
      return BusOperationStatusEnum.PROCEED
    }

    if (value === BusOperationStatusEnum.DONE.value) {
      return BusOperationStatusEnum.DONE
    }

    if (value === BusOperationStatusEnum.NOT_RUNNING.value) {
      return BusOperationStatusEnum.NOT_RUNNING
    }

    throw new Error(`invalid value or type. (value: ${value})`)
  }

  getBackgroundColor() {
    switch (this) {
      case BusOperationStatusEnum.READY:
        return {
          color: '#7951ce',
          backgroundColor: '#f1eaff',
        }
      case BusOperationStatusEnum.PROCEED:
        return {
          color: '#079f00',
          backgroundColor: 'rgba(7, 159, 0, 0.12)',
        }
      case BusOperationStatusEnum.DONE:
        return {
          color: '#afafaf',
          backgroundColor: '#e9e9e9',
        }
      case BusOperationStatusEnum.NOT_RUNNING:
        return {
          color: '#afafaf',
          backgroundColor: '#e9e9e9',
        }
    }
  }
}
