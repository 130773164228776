import React, {
  Dispatch,
  ForwardedRef,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {color, flexColumn} from '../../../style/CommonStyle'
import {CStudentNfc} from '../../../model/Student'
import TableList from '../../common/TableList'
import Pagination from '../../common/Pagination'
import {isEmptyString} from '../../../util/ValidationUtil'

const TITLE_MAP = {
  id: 'ID',
  name: '학생',
  parent: '학부모',
  nfc: 'NFC카드',
}

type StudentListProps = {
  studentState: CStudentNfc[]
  PER_PAGE: number
  totalElements: number
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export type StudentListRef = {}

function StudentListBase(
  props: StudentListProps,
  _: ForwardedRef<StudentListRef>,
) {
  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return props.studentState.map(s => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.id, <div>{s.student.id}</div>)
      node.set(
        TITLE_MAP.name,
        <div>
          {s.student.name} /{' '}
          {isEmptyString(s.student.phone) ? '-' : s.student.phone}
        </div>,
      )
      node.set(
        TITLE_MAP.parent,
        <div>
          {s.parent.name} /{' '}
          {isEmptyString(s.parent.phone) ? '-' : s.parent.phone}
        </div>,
      )
      node.set(
        TITLE_MAP.nfc,
        <div>
          {isEmptyString(s.nfc.nfcId) && isEmptyString(s.nfc.cardNumber)
            ? '-'
            : `${s.nfc.nfcId} (ID : ${s.nfc.cardNumber})`}
        </div>,
      )
      return node
    })
  }, [props.studentState])

  const onClickRow = () => {}

  return (
    <Container studentCnt={props.totalElements}>
      {props.studentState.length > 0 && (
        <Title>{`학생 리스트 (${props.totalElements})`}</Title>
      )}

      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        tableHeaderStyle={{background: '#f5f5f5'}}
        tableHeaderRowStyle={{
          width: '25%',
          position: 'sticky',
          top: 0,
          background: '#f5f5f5',
        }}
        placeholder={'등록된 학생이 없습니다.'}
      />

      {props.studentState.length > 0 && (
        <Pagination
          num={props.totalElements}
          perPage={props.PER_PAGE}
          pageExposeCount={5}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
      )}
    </Container>
  )
}

const StudentList = forwardRef(StudentListBase)
export default StudentList

type ContainerProps = {
  studentCnt: number
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${color.white};
  min-height: 6.8rem;
  max-height: calc(100vh - 18rem);
  ${flexColumn};
  padding: 1.2rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 0.8rem;
  margin-left: 1.2rem;
`
