import React, {
  ChangeEvent,
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router'
import {Optional} from '../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import ManagerIcon from '../../../asset/image/manager_icon.svg'
import SVGImage from '../../common/SVGImage'
import {isNil} from '../../../util/ValidationUtil'
import {
  deleteManager,
  getManagerInfo,
  getManagerMemo,
  patchManagerMemo,
  patchManagerName,
  patchManagerPassword,
  patchManagerPhone,
  patchManagerUserID,
} from '../../../service/manager/Manager'
import {CManager} from '../../../model/Manager'
import {CDateTime} from '../../../model/DateTime'
import HalfPopup from '../../common/HalfPopUp'
import useSecureRef from '../../../hook/useSecureRef'
import ChangeName, {ModalContainerRef} from '../popupContents/ChangeName'
import {DeleteManager} from '../popupContents/DeleteManager'
import Toast from '../../toast/Toast'
import ChangePhone from '../popupContents/ChangePhone'
import ChangeId from '../popupContents/ChangeId'
import ChangePassword from '../popupContents/ChangePassword'
import {DisabledDeleteManager} from '../popupContents/DisabledDeleteManager'

type ManagerBasicInfoProps = {
  setManagerName: Dispatch<SetStateAction<Optional<string>>>
}

type StateProps = {
  state: {
    managerID: number
  }
}

export type ManagerNameRef = {
  getValue(): string
}

function ManagerBasicInfoBase(
  props: ManagerBasicInfoProps,
  ref: ForwardedRef<ManagerNameRef>,
) {
  const {state} = useLocation() as StateProps
  const navigate = useNavigate()
  const managerID = state.managerID
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [managerInfo, setManagerInfo] = useState<CManager>()
  const createdAt = CDateTime.create(managerInfo?.createdAt)
  const nameRef = useSecureRef<ModalContainerRef>(
    '[ManagerBasicInfo.tsx] nameRef',
  )
  const phoneRef = useSecureRef<ModalContainerRef>(
    '[ManagerBasicInfo.tsx] phoneRef',
  )
  const idRef = useSecureRef<ModalContainerRef>('[ManagerBasicInfo.tsx] idRef')
  const pwRef = useSecureRef<ModalContainerRef>('[ManagerBasicInfo.tsx] pwRef')
  const deleteManagerRef = useSecureRef<ModalContainerRef>(
    '[ManagerBasicInfo.tsx] deleteManagerRef',
  )
  const disabledDeleteManagerRef = useSecureRef<ModalContainerRef>(
    '[ManagerBasicInfo.tsx] disabledDeleteManagerRef',
  )

  const [memoStatus, setMemoStatus] = useState<boolean>(false)
  const [name, setName] = useState<Optional<string>>(null)
  const [phone, setPhone] = useState<Optional<string>>(null)
  const [id, setID] = useState<Optional<string>>(null)
  const [pw, setPW] = useState<Optional<string>>(null)
  const [memo, setMemo] = useState<string>('')
  const [initialMemo, setInitialMemo] = useState<Optional<boolean>>(false)
  const [nameToast, setNameToast] = useState<boolean>(false)
  const [phoneToast, setPhoneToast] = useState<boolean>(false)
  const [idToast, setIdToast] = useState<boolean>(false)
  const [pwToast, setPwToast] = useState<boolean>(false)
  const [registerMemoToast, setRegisterMemoToast] = useState<boolean>(false)
  const [deleteMemoToast, setDeleteMemoToast] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)

  const fetchManagerDetail = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    getManagerInfo(selectedAcademyID, managerID.toString())
      .then(md => {
        setManagerInfo(md)
        props.setManagerName(md?.name)
      })
      .catch(error => {
        throw new Error(`failed to get manager info. error: ${error})`)
      })

    getManagerMemo(selectedAcademyID, managerID.toString())
      .then(mm => {
        setMemo(mm.memo)
        if (isNil(mm.memo) || mm.memo === '') {
          setInitialMemo(false)
        } else setInitialMemo(true)
      })
      .catch(error => {
        throw new Error(
          `failed to get students. (academyID: ${selectedAcademyID}, error: ${error})`,
        )
      })
  }, [])

  useEffect(() => {
    fetchManagerDetail()
  }, [selectedAcademyID, managerID])

  const showNamePopUp = useCallback(() => {
    nameRef.current().show()
  }, [])

  const showPhonePopUp = useCallback(() => {
    phoneRef.current().show()
  }, [])

  const showIdPopUp = useCallback(() => {
    idRef.current().show()
  }, [])

  const showPwPopUp = useCallback(() => {
    pwRef.current().show()
  }, [])

  const handleCloseButton = useCallback(() => {
    nameRef.current().hide()
    phoneRef.current().hide()
    idRef.current().hide()
    pwRef.current().hide()
    deleteManagerRef.current().hide()
    disabledDeleteManagerRef.current().hide()
    setName('')
    setPhone('')
    setID('')
    setPW('')
    setError(null)
  }, [setName, setPhone, setID, setPW, setError])

  const deleteManagerPopUp = useCallback(() => {
    deleteManagerRef.current().show()
  }, [])

  const onClickDeleteButton = useCallback(() => {
    deleteManager(selectedAcademyID, managerID.toString())
      .then(dm => {
        if (dm === 'OK') {
          navigate('/manager', {state: {name: managerInfo.name}})
        }
      })
      .catch(error => {
        setError(error.response.data.data)
        deleteManagerRef.current().hide()
      })
  }, [setError, managerInfo])

  const onChangeMemo = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setMemo(e.target.value)
    },
    [setMemo],
  )

  const registerMemo = useCallback(() => {
    patchManagerMemo(selectedAcademyID, managerID.toString(), memo)
      .then(_ => {
        setRegisterMemoToast(true)
        fetchManagerDetail()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo, setError, setRegisterMemoToast])

  const deleteMemo = useCallback(() => {
    patchManagerMemo(selectedAcademyID, managerID.toString(), '')
      .then(_ => {
        setDeleteMemoToast(true)
        fetchManagerDetail()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo, setDeleteMemoToast, setError])

  const changeManagerName = useCallback(() => {
    patchManagerName(selectedAcademyID, managerID.toString(), name)
      .then(cn => {
        if (cn === 'OK') {
          nameRef.current().hide()
          setName('')
          setNameToast(true)
          fetchManagerDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [name, setError, setName, setNameToast])

  const changeManagerPhone = useCallback(() => {
    patchManagerPhone(selectedAcademyID, managerID.toString(), phone)
      .then(cp => {
        if (cp === 'OK') {
          phoneRef.current().hide()
          setPhone('')
          setPhoneToast(true)
          fetchManagerDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [phone, setError, setPhone, setPhoneToast])

  const changeManagerID = useCallback(() => {
    patchManagerUserID(selectedAcademyID, managerID.toString(), id)
      .then(ci => {
        if (ci === 'OK') {
          idRef.current().hide()
          setID('')
          setIdToast(true)
          fetchManagerDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [id, setID, setIdToast])

  const changeManagerPw = useCallback(() => {
    patchManagerPassword(selectedAcademyID, managerID.toString(), pw)
      .then(cp => {
        if (cp === 'OK') {
          pwRef.current().hide()
          setPW('')
          setPwToast(true)
          fetchManagerDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [pw, setError, setPwToast, setPW])

  useEffect(() => {
    if (!isNil(error)) {
      const errorMessage = error?.split(':')

      if (errorMessage[0]?.includes('ME022')) {
        disabledDeleteManagerRef.current().show()
      }

      return
    }
  }, [error])

  useEffect(() => {
    if (nameToast) {
      const timeoutId = setTimeout(() => {
        setNameToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
    if (phoneToast) {
      const timeoutId = setTimeout(() => {
        setPhoneToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (idToast) {
      const timeoutId = setTimeout(() => {
        setIdToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (pwToast) {
      const timeoutId = setTimeout(() => {
        setPwToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (registerMemoToast) {
      const timeoutId = setTimeout(() => {
        setRegisterMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (deleteMemoToast) {
      const timeoutId = setTimeout(() => {
        setDeleteMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [
    nameToast,
    phoneToast,
    idToast,
    pwToast,
    registerMemoToast,
    deleteMemoToast,
  ])

  return (
    <Container>
      <Title>기본 정보</Title>
      {!isNil(managerInfo) && (
        <InfoWrapper>
          <InfoCard>
            <ManagerImgExpireWrapper>
              <ManagerImg source={ManagerIcon} />
              <ExpireButton onClick={deleteManagerPopUp}>탈퇴</ExpireButton>
            </ManagerImgExpireWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>이름</Category>
                <ValueButtonWrapper>
                  <Value>{managerInfo.name}</Value>
                  <ModifyButton onClick={showNamePopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>연락처</Category>
                <ValueButtonWrapper>
                  <Value>{managerInfo.phone}</Value>
                  <ModifyButton onClick={showPhonePopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>회원상태 및 가입정보</Category>
                <ValueButtonWrapper>
                  <Value>
                    정상회원<span>({createdAt.format('YYYY-MM-DD')} 가입)</span>
                  </Value>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>아이디</Category>
                <ValueButtonWrapper>
                  <Value>{managerInfo.userId}</Value>
                  <ModifyButton onClick={showIdPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>비밀번호</Category>
                <ValueButtonWrapper>
                  <Value>*******************</Value>
                  <ModifyButton onClick={showPwPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
          </InfoCard>
          <MemoContainer>
            <HeaderWrapper>
              <Category>메모</Category>
              {!initialMemo ? (
                <MemoButton onClick={registerMemo}>메모 등록</MemoButton>
              ) : (
                <MemoButtonWrapper>
                  <ModifyMemoButton onClick={registerMemo}>
                    수정
                  </ModifyMemoButton>
                  <DeleteMemoButton onClick={deleteMemo}>삭제</DeleteMemoButton>
                </MemoButtonWrapper>
              )}
            </HeaderWrapper>
            <TextArea
              placeholder="작성된 메모가 없습니다."
              value={memo}
              onChange={e => onChangeMemo(e)}
              disabled={memoStatus}
            />
          </MemoContainer>
        </InfoWrapper>
      )}
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={nameRef.ref}
        contents={
          <ChangeName
            onClose={handleCloseButton}
            confirmButton={changeManagerName}
            name={managerInfo?.name}
            originalName={name}
            setName={setName}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={phoneRef.ref}
        contents={
          <ChangePhone
            onClose={handleCloseButton}
            confirmButton={changeManagerPhone}
            phone={managerInfo?.phone}
            originalPhone={phone}
            setPhone={setPhone}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={idRef.ref}
        contents={
          <ChangeId
            onClose={handleCloseButton}
            confirmButton={changeManagerID}
            originalId={id}
            setId={setID}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={pwRef.ref}
        contents={
          <ChangePassword
            onClose={handleCloseButton}
            confirmButton={changeManagerPw}
            setPassword={setPW}
            error={error}
            originalPassword={pw}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'17.4rem'}
        top={'25%'}
        right={'60%'}
        ref={deleteManagerRef.ref}
        contents={
          <DeleteManager
            onClose={handleCloseButton}
            name={managerInfo?.name}
            onClickDeleteButton={onClickDeleteButton}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={disabledDeleteManagerRef.ref}
        contents={<DisabledDeleteManager onClose={handleCloseButton} />}
      />
      {nameToast && (
        <Toast
          text={`매니저님 이름이 수정 되었습니다.`}
          status={nameToast ? 'toast' : ''}
        />
      )}
      {phoneToast && (
        <Toast
          text={`${managerInfo?.name}매니저님 연락처가 수정 되었습니다.`}
          status={phoneToast ? 'toast' : ''}
        />
      )}
      {idToast && (
        <Toast
          text={`${managerInfo?.name}매니저님 아이디가 수정 되었습니다.`}
          status={idToast ? 'toast' : ''}
        />
      )}
      {pwToast && (
        <Toast
          text={`${managerInfo?.name} 매니저님 비밀번호가 수정 되었습니다.`}
          status={pwToast ? 'toast' : ''}
        />
      )}
      {registerMemoToast && (
        <Toast
          text={`메모 등록이 완료되었습니다.`}
          status={registerMemoToast ? 'toast' : ''}
        />
      )}
      {deleteMemoToast && (
        <Toast
          text={`${managerInfo?.name}매니저님에 등록된 메모를 삭제 하였습니다.`}
          status={deleteMemoToast ? 'toast' : ''}
        />
      )}
    </Container>
  )
}

const ManagerBasicInfo = forwardRef(ManagerBasicInfoBase)
export default ManagerBasicInfo

const Container = styled.div``

const HeaderWrapper = styled.div`
  ${flexRow};
  width: 100%;
  height: 1.8rem;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
`

const MemoButton = styled.button`
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  height: 1.8rem;
  color: #fff1b3;
  background: #333333;
  border-radius: 1.6rem;
  padding: 0.2rem 0.8rem;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.17fr 1fr;
  align-items: center;
  column-gap: 0.8rem;
`

const InfoCard = styled.div`
  ${flexRow};
  width: 100%;
  height: 18.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1.6rem;
  padding: 1.6rem 1.2rem;
`

const ManagerImgExpireWrapper = styled.div`
  ${flexColumn};
  align-items: center;
  row-gap: 1.2rem;
  width: 4.8rem;
  margin-right: 1.6rem;
`

const ManagerImg = styled(SVGImage)`
  width: 4.8rem;
  height: 4.8rem;
`

const ExpireButton = styled(MemoButton)``

const CategoryColumnWrapper = styled.div`
  ${flexColumn};
  width: 20rem;
  row-gap: 1.2rem;
`

const CategoryWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.2rem;
`

const Category = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
  color: #1a468b;
`

const Value = styled(Category)`
  font-size: 1.4rem;
  color: #000;
  > span {
    font-size: 1.2rem;
    font-weight: 300;
  }
`

const ValueButtonWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const ModifyButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  padding: 0.2rem 0.4rem;
  color: #2767ce;
  background: #fff;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border: none;
  resize: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.65rem;

  :disabled {
    background: #fff;
  }
  :focus {
    outline: none;
    background: #fffbe5;
  }
`

const MemoContainer = styled(InfoCard)`
  ${flexColumn}

  :focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ffd100;

    > textarea {
      background: #fffbe5;
    }
  }
`

const MemoButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const ModifyMemoButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  background: #fff;
  color: #2767ce;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const DeleteMemoButton = styled.button`
  border: 0.05rem solid #333;
  background: #333;
  border-radius: 1.6rem;
  color: #fff1b3;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`
