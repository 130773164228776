import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import Search from '../../../asset/image/search.svg'
import PaginationContainer, {
  PaginationContainerRef,
} from '../../common/PaginationContainer'
import SVGImage from '../../common/SVGImage'
import TextInput, {TextInputRef} from '../../input/TextInput'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {Optional} from '../../../type/Common'
import useSecureRef from '../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import Arrow from '../../../asset/image/checked_yellow.svg'
import {alertError} from '../../../util/ErrorUtil'
import BusStop from '../../../asset/image/bus_stop_yellow.svg'
import {
  getSearchStation,
  GetSearchStationData,
} from '../../../service/station/Station'
import {CStation, CStationVector} from '../../../model/Station'

const PER_PAGE = 5
const PAGE_EXPOSE_COUNT = 3

type DispatchBusAddDriverSelectProps = {
  onChange(s: CStation): void
  selectedStation?: CStation
  type?: string
}

export type StationSelectRef = {
  show(): void
  getSelectedStation(): Optional<CStation>
}

function StationSideBarSelectBase(
  props: DispatchBusAddDriverSelectProps,
  ref: ForwardedRef<StationSelectRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const containerRef = useRef<HTMLDivElement>(null)
  const pageRef = useSecureRef<PaginationContainerRef>(
    '[StationSideBarSelect.tsx] pageRef',
  )
  const queryRef = useSecureRef<TextInputRef>(
    '[StationSideBarSelect.tsx] queryRef',
  )

  const [selectedStation, setSelectedStation] = useState<Optional<CStation>>(
    props.selectedStation || undefined,
  )
  const [stationVector, setStationVector] =
    useState<Optional<CStationVector>>(null)
  const [visible, setVisible] = useState<boolean>(false)

  const fetchStations = useCallback((query: string, page: number) => {
    const data: GetSearchStationData = {
      academyID: academyID,
      page: page,
      keyword: query,
      size: PER_PAGE,
    }

    getSearchStation(data)
      .then(res => {
        setStationVector(res)
      })
      .catch(error => {
        alertError(
          error,
          `getStations() failed. (aid: ${academyID}, query: ${query}, error: ${error})`,
        )
      })
  }, [])

  const onChangeText = useCallback(
    (t: string) => {
      const page = pageRef.current().getPage()

      fetchStations(t, page - 1)
    },
    [fetchStations],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const query = queryRef.current().getValue()
      fetchStations(query, page - 1)
    },
    [fetchStations],
  )

  const onClickStation = useCallback(
    (e: MouseEvent<HTMLElement>, s: CStation) => {
      e.stopPropagation()
      setSelectedStation(s)
      setVisible(false)
      props.onChange(s)
    },
    [setSelectedStation, setVisible, props.onChange],
  )

  const onClickInput = useCallback(
    (_: MouseEvent<HTMLElement>) => {
      setVisible(true)
    },
    [setVisible],
  )

  useEffect(() => {
    if (!isNil(props.selectedStation)) {
      setSelectedStation(props.selectedStation)
    } else {
      setSelectedStation(null)
    }
  }, [props.selectedStation])

  const ListComponent = useMemo(() => {
    if (isNil(stationVector)) {
      return null
    }

    if (isEmptyArray(stationVector.stations)) {
      return (
        <EmptyContainer>
          <EmptyText>정류장이 존재하지 않습니다.</EmptyText>
        </EmptyContainer>
      )
    }

    return (
      <ListContainer>
        {stationVector.stations.map((s, idx) => {
          const selected = s.id === selectedStation?.id
          return (
            <ListItemContainer
              key={`${s.name}_${idx}`}
              onClick={e => onClickStation(e, s)}>
              <StationItem>
                <BusStopImg source={BusStop} />
                <ListItemText selected={selected}>{s.name}</ListItemText>
              </StationItem>
              <ListItemImage source={Arrow} selected={selected} />
            </ListItemContainer>
          )
        })}
      </ListContainer>
    )
  }, [stationVector, selectedStation])

  const SelectComponent = useMemo(() => {
    if (!visible) {
      return null
    }

    return (
      <SelectionContainer>
        <SearchContainer>
          <SearchImage source={Search} />
          <StyledTextInput
            ref={queryRef.ref}
            onChange={onChangeText}
            placeholder={'정류장명 검색'}
            readOnly={false}
            required={false}
          />
        </SearchContainer>
        {ListComponent}

        <PaginationContainer
          ref={pageRef.ref}
          totalElementCount={stationVector?.paging.totalElements}
          pageExposeCount={PAGE_EXPOSE_COUNT}
          perPage={PER_PAGE}
          onChange={onChangePage}
        />
      </SelectionContainer>
    )
  }, [visible, onChangePage, ListComponent, stationVector, selectedStation])

  const stationInputText = useMemo(() => {
    if (isNil(selectedStation) && props.type) {
      return '검색'
    }
    if (isNil(selectedStation)) {
      return '검색 또는 지도 선택'
    }

    return `${selectedStation.name}`
  }, [selectedStation])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
      getSelectedStation(): Optional<CStation> {
        return selectedStation
      },
    }),
    [setVisible, selectedStation, fetchStations],
  )

  useEffect(() => {
    fetchStations('', 0)
  }, [])

  useEffect(() => {
    const handleClick = (e: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setVisible(false)
      }
    }

    window.addEventListener('mousedown', handleClick)
    return () => window.removeEventListener('mousedown', handleClick)
  }, [setVisible])

  return (
    <Container
      ref={containerRef}
      onClick={onClickInput}
      hasValue={!isNil(selectedStation)}>
      <InputText hasValue={!isNil(selectedStation)}>
        {stationInputText}
      </InputText>
      {SelectComponent}
    </Container>
  )
}

const StationSideBarSelect = forwardRef(StationSideBarSelectBase)
export default StationSideBarSelect

type ContainerProps = {
  hasValue: boolean
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#fff')};
  cursor: pointer;
`

const InputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 110%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: #000000;
  margin-right: 0.2rem;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#fff')};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const SelectionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 24rem;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 1000;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const BusStopImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
`

const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  height: 3rem;
  border-radius: 0.8rem;
  margin: 0.8rem 0.6rem;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  border: 0;
  box-shadow: none;
  background: #f5f5f5;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: #332a00;

  &::placeholder {
    color: #cccccc;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
  }
`

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 0 1.2rem 1.2rem;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.6rem;
  grid-template-columns: repeat(4, 1fr);
`

const ListItemContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.4rem;
  border-bottom: 0.1rem solid #ebebeb;
  padding: 1.2rem 0.8rem;

  &:hover {
    border-radius: 0.8rem;
    background: #fffbe5;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`

const StationItem = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const ListItemText = styled.div<{selected: boolean}>`
  /* color: ${props => (props.selected ? '#665300' : '#000000')}; */
  color: #665300;
  font-weight: ${props => (props.selected ? 800 : 500)};
  font-size: 1.3rem;
  font-style: normal;
  line-height: 150%;
`

const ListItemImage = styled(SVGImage)<{selected: boolean}>`
  display: ${props => (props.selected ? 'block' : 'none')};
  width: 1.2rem;
  height: 1.2rem;
`

const EmptyContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.6rem 1.8rem;
`

const EmptyText = styled.p`
  color: #999999;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
