import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, Icon} from '../../style/CommonStyle'
import StationIcon from '../../asset/image/station_icon_mini.svg'
import CourseLineIcon from '../../asset/image/course_line.svg'
import SelectedStationNoneShadow from '../../asset/image/selected_station_none_shadow.svg'
import {CBusDetail} from '../../model/Bus'
import {
  getBusOperationDetail,
  GetBusOperationDetailParams,
} from '../../service/busOperation/BusOperation'
import {Optional} from '../../type/Common'
import {isEmptyArray, isNil} from '../../util/ValidationUtil'
import useSecureRef from '../../hook/useSecureRef'

import {CustomOverlayMap, Map, Polyline} from 'react-kakao-maps-sdk'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../recoil/Atom'
import {getCourseRoute} from '../../service/station/Station'

type NavigationMapProps = {}
export type NavigationMapRef = {
  getOperationDetailParams(data: GetBusOperationDetailParams): void
}

function NavigationMapBase(
  props: NavigationMapProps,
  ref: ForwardedRef<NavigationMapRef>,
): ReactElement {
  const mapRef = useSecureRef('[NavigationMap.tsx] mapRef')

  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [detailParams, setDetailParams] =
    useState<Optional<GetBusOperationDetailParams>>()
  const [operationDetails, setOperationDetails] = useState<CBusDetail[]>([])

  const fetchOperationDetail = useCallback(
    (data: GetBusOperationDetailParams) => {
      getBusOperationDetail(data)
        .then(ods => {
          setOperationDetails(ods)

          if (!mapRef.isSecure()) {
            return
          }

          // mapRef.current().setView([ods[0].station.lat, ods[0].station.lng])
        })
        .catch(error => {
          throw new Error(
            `failed to get bus operation detail. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  const lineCoordinate = useMemo(() => {
    return operationDetails.map(od => {
      return {lat: od.station.lat, lng: od.station.lng}
    })
  }, [operationDetails])

  const [courseRouteData, setCourseRouteData] = useState(null)

  const onChangeCoordinate = useCallback(() => {
    if (isEmptyArray(operationDetails)) {
      return
    }
    if (!isEmptyArray(operationDetails)) {
      const stations = operationDetails.map(s => s.station)
      const wayPoints = stations.slice(1, stations.length - 1)
      const wayPointsData = wayPoints.map(el => {
        return {
          lat: el.lat,
          lng: el.lng,
          stationId: el.id,
          stationName: el.name,
        }
      })

      const data = {
        academyID: selectedAcademyID,
        stations: {
          origin: {
            lat: stations[0].lat,
            lng: stations[0].lng,
            stationId: stations[0].id,
            stationsName: stations[0].name,
          },
          destination: {
            lat: stations[stations.length - 1].lat,
            lng: stations[stations.length - 1].lng,
            stationId: stations[stations.length - 1].id,
            stationsName: stations[stations.length - 1].name,
          },
          waypoints: stations.length < 3 ? [] : wayPointsData,
        },
      }
      getCourseRoute(data).then(res => setCourseRouteData(res))
    }
  }, [operationDetails, selectedAcademyID, courseRouteData])

  // 모든 좌표 합치기
  const combinedVertexes = courseRouteData?.sections?.flatMap(
    (s: any) => s?.roads?.flatMap((r: {vertexes: any}) => r.vertexes),
  )

  const withoutFirstAndLast = combinedVertexes?.slice(
    2,
    combinedVertexes?.length - 2,
  )

  const vertexesAsObjects = withoutFirstAndLast?.reduce(
    (acc: {lat: any; lng: any}[], curr: any, i: number) => {
      if (i % 2 === 0) {
        acc?.push({lng: curr, lat: withoutFirstAndLast[i + 1]})
      }
      return acc
    },
    [],
  )

  const defaultCenter = useMemo(() => {
    const od = operationDetails[0]
    if (isNil(od)) {
      return
    }
    return {lat: od.station.lat, lng: od.station.lng}
  }, [operationDetails])

  useEffect(() => {
    onChangeCoordinate()
  }, [operationDetails])

  useEffect(() => {
    setDetailParams(null)
  }, [])

  useEffect(() => {
    if (isNil(detailParams)) {
      setOperationDetails([])
      return
    }

    fetchOperationDetail(detailParams)
  }, [detailParams])

  useImperativeHandle(
    ref,
    () => ({
      getOperationDetailParams(data: GetBusOperationDetailParams) {
        setDetailParams(data)
      },
    }),
    [],
  )

  if (isEmptyArray(operationDetails)) {
    return null
  }

  return (
    !isNil(defaultCenter) && (
      <Container>
        <Map
          className="map"
          center={{
            lat: defaultCenter.lat,
            lng: defaultCenter.lng,
          }}
          isPanto={true}
          style={{width: '100%', height: '100%'}}
          level={4}>
          <MapIconInfo />
          {!isNil(lineCoordinate) && (
            <CustomOverlayMap
              position={{
                lat: lineCoordinate[0]?.lat,
                lng: lineCoordinate[0]?.lng,
              }}>
              {lineCoordinate.map((coord, i) => (
                <CustomOverlayMap key={`marker-${i}`} position={coord}>
                  <StationIdxWrapper>
                    <img
                      src={SelectedStationNoneShadow}
                      style={{
                        width: '4rem',
                        height: '4re,',
                      }}
                      alt=""></img>
                    <StationIdx>{i + 1}</StationIdx>
                  </StationIdxWrapper>
                </CustomOverlayMap>
              ))}
            </CustomOverlayMap>
          )}
          {!isNil(vertexesAsObjects) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={7}
              strokeColor="#332a00"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}
          {!isNil(vertexesAsObjects) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={5}
              strokeColor="#FFD100"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}
        </Map>
      </Container>
    )
  )
}

const NavigationMap = forwardRef(NavigationMapBase)
export default NavigationMap

function MapIconInfo() {
  return (
    <MapInfo className="map-info">
      <Ul>
        <Li>
          <Icon img={StationIcon} />
          정류장
        </Li>
        <Li>
          <Icon img={CourseLineIcon} />
          예상 경로
        </Li>
      </Ul>
    </MapInfo>
  )
}

const Container = styled.div`
  flex: 1;
  background-color: ${color.white};
  width: 100%;
  border-radius: 3rem;
  position: relative;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`

const MapInfo = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1002;
  max-width: calc(100% - 56px);
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 10px;

      ${Icon} {
        margin-right: 4px;
      }
    }
  }
`

const Ul = styled.ul``
const Li = styled.li`
  font-size: 1.1rem;
  font-weight: 500;
`

const StationIdxWrapper = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
`

const StationIdx = styled.div`
  position: absolute;
  top: -2rem;
  left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 500%;
  color: #665300;
`
