import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {Button, color, flexColumn, flexRow} from '../../../style/CommonStyle'
import StudentList from './StudentList'
import {CStudent} from '../../../model/Dispatch'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import StudentListSearchBar, {
  StudentListSearchBarRef,
} from './StudentListSearchBar'
import StudentListSelected from './StudentListSelected'
import useSecureRef from '../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {getStudentsWithSearch} from '../../../service/student/Student'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'

const SELECT_GRADE_ALL = '전체'
const TITLE_MAP = {grade: '학년', studentName: '학생 이름'}

type StudentListProps = {
  students: CStudent[]
  stationID: number
  operationState: {editable: boolean; type: OperationTypeEnum}
  onSubmit(students: CStudent[]): void
  onCancel(): void
  studentList: StudentStates
}

type StudentStates = {
  originalStudents: CStudent[]
  filteredStudents: CStudent[]
}

function StudentListPopUp(props: StudentListProps) {
  const selectedAcademyID = useRecoilValue(academyIDState)

  const searchBarRef = useSecureRef<StudentListSearchBarRef>(
    '[StudentListPopUp.tsx] searchBarRef',
  )
  const [studentStates, setStudentStates] = useState<StudentStates>({
    originalStudents: [],
    filteredStudents: [],
  })
  const [selectedStudents, setSelectedStudents] = useState<CStudent[]>([])

  const filteredStudents = useMemo(() => {
    return studentStates.filteredStudents
  }, [studentStates, props.students])

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return filteredStudents.map(s => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.grade, <div>{s.nickName}</div>)
      node.set(
        TITLE_MAP.studentName,
        <div>{`${s?.name} ${
          !isNil(s.parentPhone) ? ` (${s.parentPhone?.slice(-4)})` : ''
        }`}</div>,
      )

      return node
    })
  }, [filteredStudents])

  const checkedIndices = useMemo(() => {
    return filteredStudents.reduce((acc, fs, idx) => {
      if (selectedStudents.some(ss => ss.id === fs.id)) {
        acc.push(idx)
      }

      return acc
    }, [])
  }, [filteredStudents, selectedStudents])

  const disableIndices = useMemo(() => {
    return filteredStudents
      .map((fs, idx) => {
        if (props.students.some(ss => ss.id === fs.id)) {
          return idx
        }
      })
      .filter(el => !isNil(el))
  }, [filteredStudents, props.students])

  const onClickRow = useCallback(
    (idx: number) => {
      const selected = filteredStudents[idx]
      if (selectedStudents.some(ss => ss.id === selected.id)) {
        setSelectedStudents(prev => prev.filter(el => el.id !== selected.id))
        return
      }

      setSelectedStudents(prev => [...prev, selected])
    },
    [filteredStudents, selectedStudents],
  )

  const onClickAll = useCallback(
    (indices: number[]) => {
      if (isEmptyArray(indices)) {
        setSelectedStudents(prev => {
          return prev.filter(
            ss => !filteredStudents.map(fs => fs.id).includes(ss.id),
          )
        })
        return
      }

      setSelectedStudents(prev =>
        [
          ...prev,
          ...indices.map(i => filteredStudents.find((_, idx) => idx === i)),
        ].reduce((acc, curr) => {
          if (!acc.map(a => a.id).includes(curr.id)) {
            acc.push(curr)
          }

          return acc
        }, []),
      )
    },
    [filteredStudents, setSelectedStudents, selectedStudents, disableIndices],
  )

  const onCancelStudent = useCallback(
    (id: number) => {
      setSelectedStudents(prev => prev.filter(el => el.id !== id))
    },
    [selectedStudents],
  )

  const onClickReset = useCallback(() => {
    setSelectedStudents([])
  }, [])

  const onCancel = useCallback(() => {
    props.onCancel()
    setStudentStates({
      originalStudents: [],
      filteredStudents: [],
    })
    setSelectedStudents([])
    searchBarRef.current().reset()
  }, [props.onCancel])

  const onSubmit = useCallback(() => {
    props.onSubmit(selectedStudents)
    setStudentStates({
      originalStudents: [],
      filteredStudents: [],
    })
    setSelectedStudents([])
    searchBarRef.current().reset()
    setStudentStates(prev => {
      return {
        ...prev,
        filteredStudents: prev.originalStudents,
      }
    })
  }, [props.onSubmit, selectedStudents, studentStates])

  const onChangeCondition = useCallback(
    async (text: string, grade: string) => {
      const isNumber = !isNaN(Number(text))
      const hasValidLength = isNumber ? text.length > 3 : text.length > 0

      if (hasValidLength && text) {
        const keyword = text.toString()

        try {
          const students = await getStudentsWithSearch(
            selectedAcademyID,
            keyword,
          )

          const filteredStudents = students.filter(
            (student: {
              name: string | string[]
              parentPhone: string | any[]
              nickName: string
            }) => {
              const matchesName = student.name.includes(keyword)
              const matchesPhone = student.parentPhone
                ?.slice(-4)
                .includes(keyword)
              const matchesGrade =
                grade === SELECT_GRADE_ALL || student.nickName === grade

              return (matchesName || matchesPhone) && matchesGrade
            },
          )

          setStudentStates({
            originalStudents: students,
            filteredStudents: filteredStudents,
          })
        } catch (error) {
          console.error(
            `Failed to get students. (academyID: ${selectedAcademyID}, keyword: ${text})`,
            error,
          )
        }
      } else {
        setStudentStates({
          originalStudents: [],
          filteredStudents: [],
        })
      }
    },
    [selectedAcademyID], // studentStates를 의존성에서 제거하여 무한 루프 방지
  )

  useEffect(() => {
    if (!isEmptyArray(props.studentList.originalStudents)) {
      setStudentStates(props.studentList)
    }
  }, [props.studentList])

  return (
    <Container>
      <StudentListSearchBar
        ref={searchBarRef.ref}
        students={studentStates.originalStudents}
        onChangeCondition={onChangeCondition}
      />
      <StudentContainer>
        <TableWrapper>
          <Text>
            *이미 동시간대 승차권이 등록되어 있는 학생은 선택할 수 없습니다.
          </Text>
          <StudentList
            keys={Object.values(TITLE_MAP)}
            checkedIndices={checkedIndices}
            listItems={listItems}
            onClickRow={onClickRow}
            onClickAll={onClickAll}
            disabledIndices={disableIndices}
          />
        </TableWrapper>
        <SelectedList>
          <TextHeader>
            <Text>
              총<BoldText>&nbsp;{selectedStudents.length}명&nbsp;</BoldText>{' '}
              선택
            </Text>
            <UnderlineText onClick={onClickReset}>선택 초기화</UnderlineText>
          </TextHeader>
          <StudentListSelected
            selectedStudents={selectedStudents}
            onClickCancel={onCancelStudent}
          />
        </SelectedList>
      </StudentContainer>
      <ButtonWrapper>
        <CancelButton onClick={onCancel}>취소</CancelButton>
        <SubmitButton onClick={onSubmit}>저장</SubmitButton>
      </ButtonWrapper>
    </Container>
  )
}

export default StudentListPopUp

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color.white};
  border-radius: 1.2rem;
`

const StudentContainer = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 30.3rem 21.2rem;
  column-gap: 2rem;
`

const TableWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
`

const Text = styled.span`
  color: #585858;
  font-size: 1.1rem;
  ${flexRow};
`

const BoldText = styled.div`
  font-weight: 700;
  color: #585858;
  font-size: 1.1rem;
`

const UnderlineText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

const SelectedList = styled.div`
  width: 100%;
  height: 42.4rem;
  padding: 1.6rem;
  border: 0.1rem solid #dfdfdf;
  border-radius: 0.8rem;
  background-color: ${color.white};
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TextHeader = styled.div`
  ${flexRow};
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
  ${flexRow};
  width: 100%;
  justify-content: end;
  height: 6.6rem;
  padding: 1.6rem 2rem;
  column-gap: 0.6rem;
  border-top: 0.1rem solid #eaeaea;
  border-radius: 0 0 1.2rem 1.2rem;
`

const CancelButton = styled(Button)`
  background-color: #eaeaea;
`

const SubmitButton = styled(Button)`
  background-color: #ffcd00;
`
