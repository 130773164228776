import React from 'react'
import InputHeader from '../../../../../../input/InputHeader'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import TextInput from '../../../../../../input/TextInput'

type Props = {
  busName: string
}

export default function BusInfo(props: Props) {
  return (
    <Container>
      <InputHeaderContainer>
        <InputHeader header={'운행 호차'} required={true} />
      </InputHeaderContainer>
      <TextInput readOnly={true} required={false} default={props.busName} />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 17.6rem;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`
