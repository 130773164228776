import React, {ReactNode, useCallback, useMemo} from 'react'
import InputHeader from '../../../../../../input/InputHeader'
import ManagerSelect, {
  ManagerSelectRef,
} from '../../../add/route/manager/ManagerSelect'
import {isNil} from '../../../../../../../util/ValidationUtil'
import ArrowDown from '../../../../../../../asset/image/arrowDown.svg'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {CManager} from '../../../../../../../model/Manager'
import {Optional} from '../../../../../../../type/Common'

type Props = {
  selectedManager: Optional<CManager>
  isExtra?: boolean
  onChange(m: CManager): void
}

export default function ManagerInfo(props: Props) {
  const managerSelectRef = useSecureRef<ManagerSelectRef>(
    '[OperationInfoNormal.tsx] managerSelectRef',
  )

  const onClickSelectManager = useCallback(() => {
    managerSelectRef.current().show()
  }, [])

  const ManagerComponent = useMemo((): ReactNode => {
    if (isNil(props.selectedManager)) {
      return (
        <OperationRouteInputText hasValue={!isNil(props.selectedManager)}>
          매니저 선택
        </OperationRouteInputText>
      )
    }

    return (
      <OperationRouteInputTextContainer>
        <OperationRouteInputText hasValue={!isNil(props.selectedManager)}>
          {props.selectedManager.name}({props.selectedManager.phone.slice(9)})
        </OperationRouteInputText>
      </OperationRouteInputTextContainer>
    )
  }, [props.selectedManager])

  return (
    <ManagerContainer>
      <InputHeaderContainer>
        <InputHeader
          header={`${props.isExtra ? '동승 매니저' : '매니저 선택'}`}
          required={true}
        />
      </InputHeaderContainer>
      <ManagerInputContainer>
        <ManagerSelect
          ref={managerSelectRef.ref}
          selectedManager={props.selectedManager}
          style={{left: 0}}
          onChange={props.onChange}
        />
        <ManagerInput
          hasValue={!isNil(props.selectedManager)}
          onClick={onClickSelectManager}>
          {ManagerComponent}
          <ArrowDownImage source={ArrowDown} />
        </ManagerInput>
      </ManagerInputContainer>
    </ManagerContainer>
  )
}

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const ManagerContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
`

const ManagerInputContainer = styled.div`
  position: relative;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
  height: 3rem;
`

const ManagerInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const OperationRouteInputTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`

const OperationRouteInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`
