import React, {ForwardedRef, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  CCourse,
  CCourseDetail,
  CDefaultCourse,
  CStation,
} from '../../../model/Station'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'

// image
import CloseIcon from '../../../asset/image/close_button.svg'
import ArrowIcon from '../../../asset/image/down_arrow.svg'

import {isEmptyArray} from '../../../util/ValidationUtil'
import StationSideBarAddCourse, {
  StationSideBarAddCourseRef,
} from './StationSideBarAddCourse'
import {Coordinate} from '../kakaoMap/KakaoMap'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../type/Common'
import {academyIDState} from '../../../recoil/Atom'
import {deleteCourse} from '../../../service/station/Station'
import {CourseProps} from './SearchList'
import {StationInfo} from './StationSideBarCourseSetting'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'

type StationDetailProps = {
  courseName: string
  onClose?: () => void
  courseDetail: CCourseDetail
  setIsShowStationDetail: React.Dispatch<React.SetStateAction<boolean>>
  courses?: CCourse[]
  handleCloseAddCourseButton: () => void
  setFixedCenterLatLng: (center: Coordinate) => void
  onChangeCoordinate(cs: Coordinate[]): void
  getDefaultCourseList(): void
  handleSearchButton(): void
  setCard?: React.Dispatch<React.SetStateAction<CourseProps | CDefaultCourse>>
  setDeleteStatus?: React.Dispatch<React.SetStateAction<boolean>>
  courseDelete: boolean
  setCourseDelete?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseName?: React.Dispatch<React.SetStateAction<string>>
  setCourseDeleteToast?: React.Dispatch<React.SetStateAction<boolean>>
  cancelCloseModifyModal: boolean
  closeModifyCourseStatus: boolean
  setCloseModifyCourseStatus?: React.Dispatch<React.SetStateAction<boolean>>
  setCourseModifyToast?: React.Dispatch<React.SetStateAction<boolean>>
  setDisableCirculation: React.Dispatch<React.SetStateAction<boolean>>
  mapSelectStation: Optional<CStation>
  setContainStations: React.Dispatch<React.SetStateAction<StationInfo[]>>
  addStationStatus: boolean
  setAddStationStatus: React.Dispatch<React.SetStateAction<boolean>>
  selectedStation: Optional<CStation>
  setSelectedStation: React.Dispatch<React.SetStateAction<Optional<CStation>>>
  setIsOpenModifyCourse: React.Dispatch<React.SetStateAction<boolean>>
  addNewStation: boolean
  setAddNewStation: React.Dispatch<React.SetStateAction<boolean>>
  centerStation: CStation[]
  setConfirmModifyCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmAddCourseModal: React.Dispatch<React.SetStateAction<boolean>>
  addCourseRef?: React.Ref<StationSideBarAddCourseRef>
  setAddCourse: React.Dispatch<React.SetStateAction<boolean>>
}

const SideBarCourseDetail: React.FC<StationDetailProps> = props => {
  const {addCourseRef} = props
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [isShowRoute, setIsShowRoute] = useState<boolean>(false)
  const [courseModifyStatus, setCourseModifyStatus] = useState<boolean>(false)
  const courseID = props?.courseDetail?.course?.id

  const handleDeleteCourse = useCallback(() => {
    props.setDeleteStatus(true)
    props.setCourseName(props.courseDetail?.course.name)
  }, [courseID, props.courseDetail])

  const handleModifyCourse = useCallback(() => {
    setCourseModifyStatus(true)
    props.setIsOpenModifyCourse(true)
  }, [])

  useEffect(() => {
    if (props.cancelCloseModifyModal) {
      setCourseModifyStatus(false)
      props.setIsOpenModifyCourse(false)
    }
    if (props.closeModifyCourseStatus) {
      props.setCourseName(props.courseDetail?.course.name)
      setCourseModifyStatus(false)
      props.setIsOpenModifyCourse(false)
    }
  }, [props.cancelCloseModifyModal, props.closeModifyCourseStatus])

  useEffect(() => {
    if (props.courseDelete) {
      const data = {
        academyID: selectedAcademyID,
        courseID: String(courseID),
      }
      deleteCourse(data)
        .then(res => {
          props.onClose()
          props.getDefaultCourseList()
          props.handleSearchButton()
          props.setCard(null)
          props.setCourseDelete(false)
          props.setCourseDeleteToast(true)
        })
        .catch(error => {
          throw new Error(
            `failed to Delete Course Data. (academyID: ${selectedAcademyID}, error: ${error})`,
          )
        })
    }
  }, [props.courseDelete])

  return !courseModifyStatus ? (
    <CourseDetail>
      <RouteLabelName>
        <RouteLabel
          type={props.courseDetail?.course.type}
          style={{marginTop: '0.8rem'}}>
          {props.courseDetail?.course.type.exposure}
        </RouteLabel>
        <CourseName>
          <CloseImgWrapper onClick={props.onClose}>
            <DetailCloseImg source={CloseIcon} />
          </CloseImgWrapper>

          {props.courseDetail?.course.name}
        </CourseName>
      </RouteLabelName>
      <CourseInfoCard>
        <CourseTitle>노선 정보</CourseTitle>
        <CourseContent>{props.courseDetail?.course.route}</CourseContent>
      </CourseInfoCard>
      <RouteStudentWrapper>
        <RegisterRoute>
          <RegisterRouteHeader>
            <TextWrapper>
              <Title>등록 배차</Title>
              <SubTitle>{props.courseDetail?.dispatches.length}개</SubTitle>
            </TextWrapper>
            <ArrowButton
              onClick={() => setIsShowRoute(!isShowRoute)}
              isShowRoute={isShowRoute}>
              <ArrowImg source={ArrowIcon} />
            </ArrowButton>
          </RegisterRouteHeader>
          {isEmptyArray(props.courseDetail?.dispatches) && isShowRoute && (
            <EmptyText>등록된 배차가 없습니다.</EmptyText>
          )}
          {!isEmptyArray(props.courseDetail?.dispatches) &&
            isShowRoute &&
            props.courseDetail?.dispatches?.map((c, i) => {
              return (
                <RouteCard key={i}>
                  <DispatchNameWrapper>
                    <DispatchName>{c.busName}</DispatchName>
                    <DispatchName>{`출발 ${c.startTime}  - 도착 ${c.endTime}`}</DispatchName>
                  </DispatchNameWrapper>
                  <DispatchContent>
                    <LabelDateWrapper>
                      <RouteLabel type={c.type}>{c.type.exposure}</RouteLabel>
                      <DispatchDate>{`${c.startDate} ~ ${c.endDate}`}</DispatchDate>
                    </LabelDateWrapper>
                    <DispatchWeekDay>{c.weekday.exposure}</DispatchWeekDay>
                  </DispatchContent>
                </RouteCard>
              )
            })}
        </RegisterRoute>
      </RouteStudentWrapper>
      {props.courseDetail?.dispatches.length > 0 && (
        <Noti>
          <NotiText>배차가 등록되어있는 노선은 수정이 불가능합니다.</NotiText>
          <NotiText>
            <span>[노선 복제]</span>를 이용하여 새로 노선을 추가해주세요.
          </NotiText>
        </Noti>
      )}
      <StationButtonWrapper>
        {props.courseDetail?.dispatches.length === 0 && (
          <CourseDeleteButton onClick={handleDeleteCourse}>
            노선 삭제
          </CourseDeleteButton>
        )}
        <CourseCopyButton
          status={props.courseDetail?.dispatches.length}
          onClick={handleModifyCourse}>
          {props.courseDetail?.dispatches.length > 0
            ? '노선 복제'
            : '노선 수정'}
        </CourseCopyButton>
      </StationButtonWrapper>
    </CourseDetail>
  ) : (
    <StationSideBarAddCourse
      handleCloseAddCourseButton={props.handleCloseAddCourseButton}
      courseDetail={props.courseDetail}
      fixedCenterLatLng={props.setFixedCenterLatLng}
      onChangeCoordinate={props.onChangeCoordinate}
      modifyType={props.courseDetail?.dispatches.length}
      getDefaultCourseList={props.getDefaultCourseList}
      handleSearchButton={props.handleSearchButton}
      setCloseModifyCourseStatus={props.setCloseModifyCourseStatus}
      setCourseModifyToast={props.setCourseModifyToast}
      setDisableCirculation={props.setDisableCirculation}
      setContainStations={props.setContainStations}
      mapSelectStation={props.mapSelectStation}
      addStationStatus={props.addStationStatus}
      setAddStationStatus={props.setAddStationStatus}
      selectedStation={props.selectedStation}
      setSelectedStation={props.setSelectedStation}
      setIsOpenModifyCourse={props.setIsOpenModifyCourse}
      addNewStation={props.addNewStation}
      setAddNewStation={props.setAddNewStation}
      centerStation={props.centerStation}
      setConfirmModifyCourseModal={props.setConfirmModifyCourseModal}
      setConfirmAddCourseModal={props.setConfirmAddCourseModal}
      ref={addCourseRef}
      setAddCourse={props.setAddCourse}
    />
  )
}

export default SideBarCourseDetail

const CourseDetail = styled.div`
  position: relative;
  padding: 1.6rem 0;
  max-width: 26rem;
  height: 100%;
`
const CourseName = styled.div`
  ${flexRow};
  width: calc(100% - 3rem);
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
`
const CloseImgWrapper = styled.div``

const CourseInfoCard = styled.div`
  ${flexColumn};
  padding: 1.6rem;
  row-gap: 0.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`
const CourseTitle = styled.div`
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.3rem;
`
const CourseContent = styled.div`
  font-weight: 400;
  line-height: 1.8rem;
  font-size: 1.2rem;
`

const DetailCloseImg = styled(SVGImage)`
  position: absolute;
  top: 2.2rem;
  right: 1.5rem;
  width: 1.8rem;
  height: 1.8rem;
`

const RouteStudentWrapper = styled.div`
  height: calc(100% - 250px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Noti = styled.div`
  position: absolute;
  bottom: 6.6rem;
  left: 0;
  width: 100%;
  height: 34px;
  background: #fff;
  ${flexColumn};
  justify-content: center;
  align-items: center;
`

const NotiText = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.7rem;
  color: #999;
  > span {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.7rem;
    color: #999;
  }
`

const RegisterRoute = styled.div`
  padding: 1.6rem;
`

const RegisterRouteHeader = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`

const RouteCard = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background: #f5f5f5;
  margin-top: 0.6rem;
`

const DispatchNameWrapper = styled.div`
  ${flexColumn};
  background: #fff;
  padding: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
`

const DispatchName = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DispatchContent = styled.div`
  ${flexRow};
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  align-items: center;
`

const DispatchWeekDay = styled.div`
  font-size: 0.9rem;
  line-height: 150%;
  font-weight: 500;
  color: #666;
`

const RouteLabelName = styled.div`
  display: grid;
  grid-template-columns: 2.8rem calc(100% - 2rem);
  column-gap: 0.4rem;
  padding: 0 1.6rem 1.6rem;
  border-bottom: 0.1rem solid #ebebeb;
`

const LabelDateWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

const DispatchDate = styled.div`
  color: #55024d;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 150%; /* 13.5px */
  letter-spacing: -0.27px;
`

type LabelProps = {
  type: OperationTypeEnum
}

const RouteLabel = styled.div<LabelProps>`
  width: 2.8rem;
  height: 1.4rem;
  padding: 0.1rem 0.3rem;
  background: ${props =>
    props.type === OperationTypeEnum.INBOUND
      ? '#009A17'
      : props.type === OperationTypeEnum.OUTBOUND
        ? '#6092E1'
        : '#FFD100'};
  border-radius: 1.2rem;
  color: ${props =>
    props.type === OperationTypeEnum.INBOUND ||
    props.type === OperationTypeEnum.OUTBOUND
      ? '#fff'
      : '#332a00'};
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 700;
  letter-spacing: -0.024rem;
  margin-top: 0.2rem;
`
const TextWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
`

type ArrowProps = {
  isShowRoute: boolean
}

const ArrowButton = styled.div<ArrowProps>`
  transform: ${props => (props.isShowRoute ? 'rotate(180deg)' : '')};
  transition: transform 0.5s ease-in-out;
`

const ArrowImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
`

const Title = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 700;
`

const EmptyText = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: #999;
  margin-top: 0.6rem;
`

const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #666;
`

const StationButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1.6rem 0;
  background: #fff;
  border-radius: 0 0 1.6rem 0;
  ${flexRow};
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  width: 100%;
  border-top: 0.1rem solid #ebebeb;
`

const CourseDeleteButton = styled.button`
  background: #332a00;
  color: #fff1b3;
  border-radius: 3.4rem;
  padding: 0.4rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  border: 0.1rem solid #332a00;
  height: 2.6rem;
  ${flexRow};
`

type ButtonProps = {
  status: number
}
const CourseCopyButton = styled.button<ButtonProps>`
  border-radius: 3.4rem;
  background: ${props => (props.status === 0 ? '#fff' : '#ffe366')};
  border: ${props =>
    props.status === 0 ? '0.1rem solid #2767CE' : '0.1rem solid #ffe366'};
  padding: 0.4rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${props => (props.status === 0 ? '#2767ce' : '#997d00')};
  height: 2.6rem;
`
