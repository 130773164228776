import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  color,
  flexColumn,
  flexRow,
} from '../../../../../../../style/CommonStyle'
import HalfPopup, {PopUpRef} from '../../../../../../common/HalfPopUp'
import {RegisterManagerPopUp} from '../../../../../../managerManagement/popupContents/RegisterManagerPopUp'
import {CancelRegisterPopUp} from '../../../../../../managerManagement/popupContents/CancelRegisterManager'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../../../../../type/Common'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {
  existManager,
  patchManager,
  postManager,
} from '../../../../../../../service/manager/Manager'
import {ManagerProps} from '../../../../../../managerManagement/registerManager/RegisterManager'
import {ExistManager} from '../../../../../../managerManagement/popupContents/ExistManager'
import {isNil} from '../../../../../../../util/ValidationUtil'

type Props = {
  hideRegisterModal(): void
  fetchManagerList(): void
}

function ManagerAdd(props: Props) {
  const cancelRef = useSecureRef<PopUpRef>('[RegisterManager.tsx] cancelRef')
  const registerRef = useSecureRef<PopUpRef>(
    '[RegisterManager.tsx] registerRef',
  )
  const existManagerRef = useSecureRef<PopUpRef>(
    '[existManager.tsx] existManagerRef',
  )
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [submitPressable, setSubmitPressable] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [id, setId] = useState<string>('')
  const [pw, setPw] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [existManagerState, setExistManagerState] =
    useState<Optional<ManagerProps>>(null)

  const onSubmit = useCallback(() => {
    const data = {
      name: name,
      password: pw,
      phone: phone,
      userId: id,
    }

    const existData = {
      academyID: selectedAcademyID,
      phone: phone,
    }

    if (!submitPressable) {
      return
    } else
      existManager(existData)
        .then(res => {
          if (res.status) {
            registerRef.current().hide()
            existManagerRef.current().show()
            setExistManagerState({id: res.id, name: res.name, phone: res.phone})
          }
          if (!res.status) {
            postManager(selectedAcademyID, data)
              .then(res => {
                if (res === 'OK') {
                  registerRef.current().hide()
                  props.hideRegisterModal()
                  props.fetchManagerList()
                  setName('')
                  setId('')
                  setPw('')
                  setPhone('')
                }
              })
              .catch(error => {
                setErrorMessage(error.response.data.data)
                registerRef.current().hide()
              })
          }
        })
        .catch(error => {
          setErrorMessage(error.response.data.data)
          registerRef.current().hide()
        })
  }, [name, pw, phone, id])

  const registerExistManager = useCallback(() => {
    if (!isNil(existManagerState)) {
      patchManager(selectedAcademyID, String(existManagerState?.id))
        .then(res => {
          if (res === 'OK') {
            existManagerRef.current().hide()
            props.hideRegisterModal()
            props.fetchManagerList()
            setName('')
            setId('')
            setPw('')
            setPhone(null)
            setErrorMessage('')
          }
        })
        .catch(error => {
          setErrorMessage(error.response.data.data)
          existManagerRef.current().hide()
        })
    }
  }, [existManagerState])

  const handleCancelButton = useCallback(() => {
    cancelRef.current().show()
  }, [])

  const handleRegisterButton = useCallback(() => {
    if (!submitPressable) {
      return
    }

    registerRef.current().show()
  }, [submitPressable])

  useEffect(() => {
    if (name !== '' && phone !== '' && id !== '' && pw !== '') {
      setSubmitPressable(true)
    } else setSubmitPressable(false)
  }, [name, phone, id, pw])

  const onClickCancelButton = useCallback(() => {
    registerRef.current().hide()
    existManagerRef.current().hide()
    setName('')
    setId('')
    setPw('')
    setPhone('')
  }, [])

  const onConfirmCancel = useCallback(() => {
    cancelRef.current().hide()
    props.hideRegisterModal()
  }, [])

  return (
    <Container>
      <Header>매니저 등록</Header>
      <Noti>
        • <span>*</span> 는 필수 입력 항목입니다.
      </Noti>
      <BodyWrapper>
        <Info>
          <Title>기본 정보</Title>
          <CardWrapper>
            <Card>
              <SubTitle>
                매니저명 <span>*</span>
              </SubTitle>
              <Input
                placeholder="이름을 입력해주세요."
                value={name}
                onChange={e => setName(e.target.value)}
                error={errorMessage.includes('name')}
              />
              {errorMessage.includes('name') && (
                <Error>
                  이름은 한글, 영문, 숫자를 이용하여 최소 2자 ~ 10자까지 입력할
                  수 있습니다.
                </Error>
              )}
            </Card>
            <Card>
              <SubTitle>
                연락처 <span>*</span>
              </SubTitle>
              <Input
                placeholder="전화번호를 입력해주세요.('-'제외)"
                value={phone}
                type="number"
                onChange={e => setPhone(e.target.value)}
                error={
                  errorMessage.includes('phone') ||
                  errorMessage.includes('R902')
                }
              />
              {errorMessage.includes('phone') && (
                <Error>올바른 연락처 양식이 아닙니다.</Error>
              )}
              {errorMessage.includes('R902') && (
                <Error>이미 등록되어 있는 연락처입니다.</Error>
              )}
            </Card>
          </CardWrapper>
        </Info>
        <Info>
          <Title>계정 정보</Title>
          <CardWrapper>
            <Card>
              <SubTitle>
                아이디 <span>*</span>
              </SubTitle>
              <Input
                placeholder="영문, 숫자 조합 최소 5자 ~ 최대 20자"
                value={id}
                onChange={e => setId(e.target.value)}
                error={
                  errorMessage.includes('userId') ||
                  errorMessage.includes('R901')
                }
              />
              {errorMessage.includes('userId') && (
                <Error>
                  아이디는 영문, 숫자를 이용하여 최소 5자 ~ 최대 20자까지 입력
                  가능합니다.
                </Error>
              )}
              {errorMessage.includes('R901') && (
                <Error>이미 등록되어 있는 아이디입니다.</Error>
              )}
            </Card>
            <Card>
              <SubTitle>
                비밀번호 <span>*</span>
              </SubTitle>

              <Input
                placeholder={'영문, 숫자, 특수문자 조합 최소 10자 ~ 최대 30자'}
                type="password"
                value={pw}
                onChange={e => setPw(e.target.value)}
                error={errorMessage.includes('password')}
              />
              {errorMessage.includes('password') && (
                <Error>
                  비밀번호는 영문, 숫자, 특수문자 기입 가능하며 최소 10자 ~ 최대
                  30자 까지 입력 가능합니다.
                </Error>
              )}
            </Card>
          </CardWrapper>
        </Info>
      </BodyWrapper>
      <Bottom>
        <CancelButton onClick={handleCancelButton}>취소</CancelButton>
        <RegisterButton
          pressable={submitPressable}
          onClick={handleRegisterButton}>
          등록
        </RegisterButton>
      </Bottom>
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={registerRef.ref}
        contents={
          <RegisterManagerPopUp
            onClose={onClickCancelButton}
            onSubmit={onSubmit}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={cancelRef.ref}
        contents={
          <CancelRegisterPopUp
            onClose={onClickCancelButton}
            onClickEditButton={onConfirmCancel}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={existManagerRef.ref}
        contents={
          <ExistManager
            onClose={onClickCancelButton}
            handleRegisterExistManager={registerExistManager}
            existManager={existManagerState}
          />
        }
      />
    </Container>
  )
}

export default ManagerAdd

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

export const Header = styled.div`
  height: 6rem;
  padding: 2rem;
  background: ${color.white};
  border-bottom: 0.1rem solid #ebebeb;
  color: #332a00;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 1.6rem 0 0 0;
`

const Noti = styled.div`
  height: 3rem;
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #999;

  span {
    color: #ff7500;
    font-size: 1.1rem;
  }
`

const BodyWrapper = styled.div`
  ${flexColumn};
  width: 100%;
  height: calc(100vh - 15.8rem);
  padding: 1.6rem;
  row-gap: 1.6rem;
  background: #f5f5f5;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const Info = styled.div`
  padding: 0.4rem;
  ${flexColumn}
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.7rem;
  margin-bottom: 0.4rem;
`

const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.8rem;
`

const Card = styled.div`
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #fff;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
`

const SubTitle = styled.div`
  padding: 0.2rem;
  line-height: 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: #997d00;
  span {
    color: #ff7500;
    font-size: 1.3rem;
  }
`

type InputProps = {
  error: boolean
}

const Input = styled.input<InputProps>`
  border: 0.1rem solid #ebebeb;
  border-color: ${props => (props.error ? 'red' : '#ebebeb')};
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  width: 100%;
  line-height: 1.8rem;
  color: ${props => (props.error ? '#DA291C' : 'black')};
  background: ${props => (props.error ? '#FCE6E4' : '')};

  :focus {
    background: ${props => (props.error ? '' : '#fffbe5')};
  }
`

const Bottom = styled.div`
  height: 6.8rem;
  padding: 1.6rem 2.4rem;
  background: ${color.white};
  border-radius: 0 0 0 1.6rem;
  justify-content: end;
  ${flexRow}
`

export const CancelButton = styled.button`
  padding: 1rem 2.2rem;
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 3rem;
  margin-left: 0.4rem;
`

const RegisterButton = styled(CancelButton)<{pressable: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.pressable ? 1 : 0.3)};
  cursor: ${props => (props.pressable ? 'pointer' : 'not-allowed')};
`

const Error = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #e74d41;
  margin-top: 0.6rem;
`
