import React, {ReactElement, useCallback, useRef, useState} from 'react'
import Toast, {ToastRef} from '../../../modal/Toast'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import Footer from '../../../common/Footer'
import useSecureRef from '../../../../hook/useSecureRef'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'
import DispatchBusAddHeader from './DispatchBusAddHeader'
import DispatchBusAddInputContainer, {
  DispatchBusAddInputContainerRef,
} from './DispatchBusAddInputContainer'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {alertError} from '../../../../util/ErrorUtil'
import {postBus} from '../../../../service/buses/Buses'
import Loading, {LoadingRef} from '../../../common/Loading'

type Props = {
  setToast(t: string): void
  onSubmit(): void
  onCancel(): void
}

export default function DispatchBusAdd(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const toastRef = useSecureRef<ToastRef>('[DispatchBusAdd.tsx] toastRef')
  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusAdd.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchBusAdd.tsx] confirmModalRef',
  )
  const addInputRef = useSecureRef<DispatchBusAddInputContainerRef>(
    '[DispatchBusAdd.tsx] confirmModalRef',
  )
  const loadingRef = useRef<LoadingRef | null>(null)

  const [isSufficient, setIsSufficient] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState('')

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])

  const onChangeSufficiency = useCallback(
    (isSufficient: boolean) => {
      setIsSufficient(isSufficient)
    },
    [setIsSufficient],
  )

  const submitAddBus = useCallback(() => {
    const data = addInputRef.current().getAddBusInputData()
    loadingRef.current?.show()

    postBus(academyID, data)
      .then(() => {
        props.onSubmit()
        props.setToast('호차 등록이 완료되었습니다.')
      })
      .catch(error => {
        setErrorMessage(error.response.data.data)
        // alertError(
        //   error,
        //   `postBus() failed. (aid: ${academyID}, data: ${JSON.stringify(
        //     data,
        //   )}, error: ${error})`,
        // )
      })
    loadingRef.current?.hide()
  }, [academyID, props.onSubmit, props.setToast])

  const setDriverAddToast = useCallback(() => {
    toastRef.current().setContent('기사 등록이 완료되었습니다.')
    toastRef.current().show()
  }, [])

  return (
    <Container>
      <Toast ref={toastRef.ref} />
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'호차 등록 확인'}
        content={'호차를 등록하시겠습니까?'}
        onSubmit={submitAddBus}
      />
      <ConfirmModal
        ref={cancelConfirmModalRef.ref}
        header={'호차 등록 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n호차 등록을 취소 하시겠습니까?'
        }
        onSubmit={props.onCancel}
      />
      <ContentContainer>
        <Loading ref={loadingRef}></Loading>
        <DispatchBusAddHeader />
        <DispatchBusAddInputContainer
          ref={addInputRef.ref}
          onChange={onChangeSufficiency}
          setToast={setDriverAddToast}
          readOnly={false}
          errorMessage={errorMessage}
        />
        <Footer
          isSufficient={isSufficient}
          onCancel={showCancelConfirmModal}
          onSubmit={showConfirmModal}
          submitText={'등록'}
        />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 54rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  ${flexColumn};

  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
`
