import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, flexColumn} from '../../../style/CommonStyle'
import TicketListHeader, {TicketListHeaderRef} from './TicketListHeader'
import TableList from '../../common/TableList'
import {WeekdayEnum} from '../../../enum/WeekdayEnum'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import {
  getDispatchesWithSeatOccupancy,
  GetDispatchesWithSeatOccupancyParams,
} from '../../../service/dispatch/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {Optional} from '../../../type/Common'
import {isNil} from 'lodash'
import {DEFAULT_WEEKDAY} from '../ticketController/TicketController'
import {GetBusOperationDetailParams} from '../../../service/busOperation/BusOperation'
import {CDispatchWithSeatOccupancy} from '../../../model/Dispatch'
import useSecureRef from '../../../hook/useSecureRef'

const TITLE_MAP = {
  period: '운행 기간',
  busName: '운행 버스',
  shareRate: '점유율',
}

type TicketListProps = {
  getTicketDetail(ticket: CDispatchWithSeatOccupancy): void
  getTicketMap(data: GetBusOperationDetailParams): void
}
export type TicketListRef = {
  getTicketList(wd: WeekdayEnum, type: OperationTypeEnum[]): void
}

export type Ticket = {
  originalTickets: CDispatchWithSeatOccupancy[]
  filteredTickets: CDispatchWithSeatOccupancy[]
}

function TicketListBase(
  props: TicketListProps,
  ref: ForwardedRef<TicketListRef>,
): ReactElement {
  const headerRef = useSecureRef<TicketListHeaderRef>(
    '[TicketList.tsx] headerRef',
  )
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [ticketState, setTicketState] = useState<Ticket>({
    originalTickets: [],
    filteredTickets: [],
  })

  const onChangeListHeader = useCallback(
    (busName: Optional<string>, time: Optional<string>) => {
      setTicketState(prev => {
        return {
          ...prev,
          filteredTickets: prev.originalTickets.filter(t => {
            if (isNil(busName) && isNil(time)) {
              return t
            }

            if (isNil(busName)) {
              return t.bus.time === time
            }

            if (isNil(time)) {
              return t.bus.name === busName
            }

            return t.bus.name === busName && t.bus.time === time
          }),
        }
      })
    },
    [ticketState],
  )

  const fetchTicketList = useCallback(
    (wd: WeekdayEnum, type: OperationTypeEnum[]): void => {
      if (isNil(selectedAcademyID)) {
        return
      }

      const data: GetDispatchesWithSeatOccupancyParams = {
        academyId: selectedAcademyID,
        type: type,
        weekday: wd,
      }

      getDispatchesWithSeatOccupancy(data)
        .then(ts => {
          setTicketState({originalTickets: ts, filteredTickets: ts})
        })
        .catch(error => {
          throw new Error(
            `failed to get dispatches with seat occupancy. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [selectedAcademyID],
  )

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return ticketState.filteredTickets.map(t => {
      const node = new Map<string, ReactNode>()
      node.set(
        TITLE_MAP.period,
        <div>
          {t.dispatch.startDate.toString()}
          <br />~{t.dispatch.endDate.toString()}
        </div>,
      )
      node.set(TITLE_MAP.busName, <div>{t.dispatch.name}</div>)
      node.set(
        TITLE_MAP.shareRate,
        <div>
          {t.seat.occupyCount} / {t.seat.validCount}
        </div>,
      )
      return node
    })
  }, [ticketState])

  const onClickRow = useCallback(
    (idx: number) => {
      const ticket = ticketState.filteredTickets[idx]
      props.getTicketDetail(ticket)
      props.getTicketMap({
        academyId: selectedAcademyID,
        code: ticket.dispatch.code,
        date: ticket.dispatch.startDate,
      })
    },
    [ticketState],
  )

  useEffect(() => {
    fetchTicketList(DEFAULT_WEEKDAY, OperationTypeEnum.ALL)
  }, [selectedAcademyID])

  useImperativeHandle(
    ref,
    () => ({
      getTicketList: fetchTicketList,
    }),
    [fetchTicketList],
  )

  return (
    <Container>
      <TicketListHeader
        ref={headerRef.ref}
        tickets={ticketState.originalTickets}
        onChangeListHeader={onChangeListHeader}
      />
      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        placeholder={'검색 결과가 없습니다.'}
      />
    </Container>
  )
}

const TicketList = forwardRef(TicketListBase)
export default TicketList

const Container = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: calc(100vh - 16rem);
  ${flexColumn};
  padding: 2.4rem;
  border-radius: 3rem;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`
