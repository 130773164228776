import React, {useEffect, useMemo, useState} from 'react'
import TimeInput, {TimeInputRef} from '../../../../../../input/TimeInput'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import Start from '../../../../../../../asset/image/station_start.svg'
import Arrive from '../../../../../../../asset/image/station_arrive.svg'
import Number from '../../../../../../../asset/image/station_number2.svg'
import {CDispatchCourseWithStudent} from '../../../../../../../model/Dispatch'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {isEmptyArray} from '../../../../../../../util/ValidationUtil'
import CourseStudent from './CourseStudent'
import {OccupancyTypeEnum} from '../../../../../../../enum/OccupancyTypeEnum'

export type Props = {
  course: CDispatchCourseWithStudent
  occupancyType: OccupancyTypeEnum
  isFirstItem: boolean
  isLastItem: boolean
  readOnly: boolean
}

export default function CourseItem(props: Props) {
  const timeInputRef = useSecureRef<TimeInputRef>(
    '[CourseIndividualItem.tsx] timeInputRef',
  )

  const CountComponent = useMemo(() => {
    if (props.readOnly) {
      return (
        <div style={{opacity: 0.2}}>
          <StationIcon source={Arrive} />
        </div>
      )
    }
    if (props.isFirstItem) {
      return <StationIcon source={Start} />
    }

    if (props.isLastItem) {
      return <StationIcon source={Arrive} />
    }

    return <StationIcon source={Number} />
  }, [props.readOnly, props.isLastItem, props.isFirstItem])

  const StationContentComponent = useMemo(() => {
    if (props.isFirstItem) {
      return <StartContent readOnly={props.readOnly}>출발</StartContent>
    }

    if (props.isLastItem) {
      return <ArriveContent readOnly={props.readOnly}>도착</ArriveContent>
    }

    return (
      <StationContent readOnly={props.readOnly}>
        {props.course.sort}
      </StationContent>
    )
  }, [props.isLastItem, props.readOnly, props.isFirstItem, props.course])

  const headerColor = useMemo(() => {
    if (props.readOnly) {
      return '#999999'
    }

    if (props.isFirstItem) {
      return '#1A468B'
    }

    if (props.isLastItem) {
      return '#404040'
    }

    return '#665300'
  }, [props.isLastItem, props.readOnly, props.isFirstItem])

  const StudentListComponent = useMemo(() => {
    if (isEmptyArray(props.course.students)) {
      return null
    }

    return (
      <ListContainer>
        {props.course.students.map((student, idx) => {
          return (
            <CourseStudent
              readOnly={props.readOnly}
              student={student}
              isChecked={student.check}
              key={idx}
            />
          )
        })}
      </ListContainer>
    )
  }, [props.course, props.readOnly])

  useEffect(() => {
    timeInputRef.current().setDefault(props.course.arrivalTime)
  }, [props.course.arrivalTime])

  return (
    <Container>
      <RowContainer>
        <StationIconContainer>
          {CountComponent}
          {StationContentComponent}
        </StationIconContainer>
        <ContentContainer>
          <RowContainer>
            <TimeContainer>
              <TimeInput
                ref={timeInputRef.ref}
                // readOnly={props.readOnly}
                readOnly={true}
                default={
                  props.occupancyType ===
                  OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE
                    ? '--:--'
                    : props.course.arrivalTime
                }
                notEditable={true}
              />
            </TimeContainer>
            <Header color={headerColor}>{props.course.stationName}</Header>
          </RowContainer>
          {StudentListComponent}
        </ContentContainer>
      </RowContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  padding: 1.2rem 0.8rem 1.2rem 0.8rem;
  border-radius: 0.8rem;
  background: #ffffff;
`

const RowContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const TimeContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const StationIconContainer = styled.div`
  width: 1.867rem;
  height: 2.133rem;

  position: relative;
  ${flexColumn};
  align-items: center;
  justify-content: center;
`

const StationIcon = styled(SVGImage)`
  width: 1.867rem;
  height: 2.133rem;
`

const StationContent = styled.div<{readOnly: boolean}>`
  position: absolute;
  color: ${props => (props.readOnly ? '#999999' : '#665300')};
  text-align: center;
  font-size: 0.8667rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const StartContent = styled(StationContent)`
  font-size: 0.73rem;
  color: ${props => (props.readOnly ? '#999999' : '#1a468b')};
`

const ArriveContent = styled(StationContent)`
  font-size: 0.73rem;
  color: ${props => (props.readOnly ? '#999999' : '#000000')};
`

const ContentContainer = styled.div`
  ${flexColumn};
  row-gap: 1.3rem;
`

const Header = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.39px;
  padding-top: 0.3rem;
`

const ListContainer = styled.div`
  ${flexRow};
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.6rem;
`
