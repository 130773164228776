import React, {ReactElement} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchRangeEmptyList(_: Props): ReactElement {
  return (
    <EmptyContainer>
      <EmptyText>해당 기간에 운행할 호차가 등록되지 않았습니다.</EmptyText>
    </EmptyContainer>
  )
}

const EmptyContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
`

const EmptyText = styled.p`
  color: #999999;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
