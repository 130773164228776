import React, {ReactElement, ReactNode} from 'react'
import styled from 'styled-components'

type ScreenContainerProps = {
  children?: ReactNode
}

export default function ScreenContainer(
  props: ScreenContainerProps,
): ReactElement {
  return <Container>{props.children}</Container>
}

const Container = styled.div``
