import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'
import {color, flexRow} from '../../../style/CommonStyle'
import DatePicker, {DatePickerRef} from '../../input/DatePicker'
import {CDate} from '../../../model/Date'
import useSecureRef from '../../../hook/useSecureRef'
import {Optional} from '../../../type/Common'
import {CDateTime} from '../../../model/DateTime'

type SelectDateProps = {
  startDate: Optional<CDate>
  endDate: Optional<CDate>
  onChangeDate?(d: CDate): void
}
export type SelectDateRef = {
  getDate(): CDate
  setDate(d: CDate): void
}

function SelectDateBase(
  props: SelectDateProps,
  ref: ForwardedRef<SelectDateRef>,
): ReactElement {
  const datePickerRef = useSecureRef<DatePickerRef>(
    '[SelectDate.tsx] datePickerRef',
  )

  const onChange = useCallback(
    (d: CDate) => {
      props.onChangeDate(d)
    },
    [props.onChangeDate],
  )

  useImperativeHandle(
    ref,
    () => ({
      getDate(): CDate {
        return datePickerRef.current().getValue()
      },
      setDate(d: CDate) {
        datePickerRef.current().setValue(d)
      },
    }),
    [],
  )

  return (
    <Container>
      <Title>승차권 삭제 시작일을 선택해 주세요.</Title>
      <Text>
        선택한 날짜를 시작으로 등록되어 있는 모든 승차권이 삭제 됩니다.
      </Text>
      <Text>
        *단, <BoldText>&nbsp;당일 승차권 삭제</BoldText>는&nbsp;
        <OrangeBoldText>불가능</OrangeBoldText>
        합니다.
      </Text>
      <DatePickerWrapper>
        <DatePicker
          ref={datePickerRef.ref}
          onChange={onChange}
          // TODO: 최선어학원 임시 당일삭제
          // minDate={CDateTime.now().add(1, BaseDateUnitEnum.DAY).toDate()}
          minDate={CDateTime.now().toDate()}
          maxDate={props.endDate}
        />
      </DatePickerWrapper>
    </Container>
  )
}

const SelectDate = forwardRef(SelectDateBase)
export default SelectDate

const Container = styled.div`
  max-height: 15.5rem;
  padding: 2rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #e7e7e7;
  background: ${color.white};
`

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
  line-height: 2.2rem;
`

const Text = styled.div`
  ${flexRow};
  color: #585858;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.8rem;
`

const BoldText = styled(Text)`
  font-weight: 700;
`

const OrangeBoldText = styled(BoldText)`
  color: #ff7900;
`

const DatePickerWrapper = styled.div`
  margin-top: 1.6rem;
`
