import React from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import InputHeader from '../../../../../../input/InputHeader'

type Props = {
  courseName: string
  onClickEdit(): void
}

export default function CourseInfoExtra(props: Props) {
  return (
    <CourseContainer>
      <InputHeaderContainer>
        <InputHeader header={'운행 노선 선택'} required={true} />
        <ButtonContainer>
          <EditButton onClick={props.onClickEdit}>수정</EditButton>
        </ButtonContainer>
      </InputHeaderContainer>

      <CourseInput hasValue={true}>
        <CourseTextContainer>
          <CourseText hasValue={true}>{props.courseName}</CourseText>
        </CourseTextContainer>
      </CourseInput>
    </CourseContainer>
  )
}

const CourseContainer = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
`

const ButtonContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const EditButton = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 16px;
  border: 0.05rem solid #2767ce;
  background: #fff;
  color: #2767ce;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 133.333% */
  letter-spacing: -0.27px;
  cursor: pointer;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  width: 100%;
`

const CourseInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: default;
  flex: 1;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const CourseTextContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const CourseText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`
