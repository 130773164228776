import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import SuccessIcon from '../../asset/image/status_success.svg'
import {flexRow} from '../../style/CommonStyle'
import SVGImage from '../common/SVGImage'

type ToastProps = {}
export type ToastRef = {
  show(): void
  setContent(v: string): void
}

function ToastBase(
  props: ToastProps,
  ref: ForwardedRef<ToastRef>,
): ReactElement {
  const [content, setContent] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const [zIndex, setZIndex] = useState<number>(-1)

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
        setZIndex(100000)
      },
      setContent(v: string): void {
        setContent(v)
      },
    }),
    [setVisible, setContent],
  )

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, 2000)

    setTimeout(() => {
      setZIndex(-1)
    }, 3000)
  }, [visible])

  return (
    <Container zIndex={zIndex}>
      <ToastWrapper zIndex={zIndex} visible={visible}>
        <Img source={SuccessIcon} />
        <Text>{content}</Text>
      </ToastWrapper>
    </Container>
  )
}

const Toast = forwardRef(ToastBase)
export default Toast

const Container = styled.div<{zIndex: number}>`
  background: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => props.zIndex};
`

const ToastWrapper = styled.div<{visible: boolean; zIndex: number}>`
  position: absolute;
  ${flexRow};
  top: 1.6rem;
  align-items: center;
  column-gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  border-radius: 12rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
  z-index: ${props => props.zIndex};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`

const Img = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const Text = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
  color: #fff;
`
