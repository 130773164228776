import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import styled from 'styled-components'
import useSecureRef from '../../../../hook/useSecureRef'
import DispatchPeriodSettingHeader from './DispatchPeriodSettingHeader'
import DispatchPeriodSettingList from './DispatchPeriodSettingList'
import {flexColumn} from '../../../../style/CommonStyle'
import Toast, {ToastRef} from '../../../modal/Toast'
import Footer from '../../../common/Footer'
import Loading, {LoadingRef} from '../../../common/Loading'

type Props = {
  years: number[]
  fetchDispatchPeriodYears(): void
  onCancel(): void
}

export default function DispatchPeriodSetting(props: Props): ReactElement {
  const loadingRef = useRef<LoadingRef | null>(null)
  const toastRef = useSecureRef<ToastRef>(
    '[DispatchPeriodSetting.tsx] toastRef',
  )

  const setToast = useCallback((tc: string) => {
    toastRef.current().setContent(tc)
    toastRef.current().show()
  }, [])

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading === true) {
        loadingRef.current?.show()
      } else {
        loadingRef.current?.hide()
      }
    },
    [loadingRef],
  )

  const SettingListComponent = useMemo(() => {
    return (
      <DispatchPeriodSettingList
        onCancel={props.onCancel}
        fetchDispatchPeriodYears={props.fetchDispatchPeriodYears}
        years={props.years}
        setToast={setToast}
        handleLoading={handleLoading}
      />
    )
  }, [props.years])

  return (
    <Container>
      <Loading ref={loadingRef}></Loading>
      <Toast ref={toastRef.ref} />
      <ContentContainer>
        <DispatchPeriodSettingHeader />
        {SettingListComponent}
        <Footer
          submitText={'확인'}
          onSubmit={props.onCancel}
          isSufficient={true}
        />
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  ${flexColumn};

  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
`
