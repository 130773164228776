import React, {useCallback} from 'react'
import styled from 'styled-components'
import {media} from '../../style/media'
import TicketList, {
  TicketListRef,
} from '../../component/ticket/ticketList/TicketList'
import TicketController, {
  TicketControllerRef,
} from '../../component/ticket/ticketController/TicketController'
import TicketDetail, {
  TicketDetailRef,
} from '../../component/ticket/ticketDetail/TicketDetail'
import NavigationMap, {
  NavigationMapRef,
} from '../../component/common/NavigationMap'
import useSecureRef from '../../hook/useSecureRef'
import {WeekdayEnum} from '../../enum/WeekdayEnum'
import {OperationTypeEnum} from '../../enum/OperationTypeEnum'
import {GetBusOperationDetailParams} from '../../service/busOperation/BusOperation'
import ScreenContainer from '../../component/layout/ScreenContainer'
import {CDispatchWithSeatOccupancy} from '../../model/Dispatch'
import {isNil} from '../../util/ValidationUtil'

function TicketManagement() {
  const controllerRef = useSecureRef<TicketControllerRef>(
    '[TicketManagement.tsx] controllerRef',
  )
  const detailRef = useSecureRef<TicketDetailRef>(
    '[TicketManagement.tsx], detailRef',
  )
  const listRef = useSecureRef<TicketListRef>('[TicketManagement.tsx] listRef')
  const mapRef = useSecureRef<NavigationMapRef>('TicketManagement.tsx mapRef')

  const getListTicket = useCallback(
    (wd: WeekdayEnum, type: OperationTypeEnum[]) => {
      listRef.current().getTicketList(wd, type)
    },
    [],
  )

  const getTicketDetail = useCallback((ticket: CDispatchWithSeatOccupancy) => {
    detailRef.current().setTicket(ticket)
  }, [])

  const setSelectedWeekday = useCallback((wd: WeekdayEnum) => {
    detailRef.current().setSelectedWeekday(wd)
  }, [])

  const getTicketMap = useCallback((data: GetBusOperationDetailParams) => {
    mapRef.current().getOperationDetailParams(data)
  }, [])

  const refreshListTicket = useCallback(() => {
    const wd = controllerRef.current().getWeekDay()
    const ot = controllerRef.current().getOperationType()
    let ots = OperationTypeEnum.ALL
    if (!isNil(ot)) {
      ots = [ot]
    }

    listRef.current().getTicketList(wd, ots)
  }, [])

  return (
    <ScreenContainer>
      {/* <PageHeaderWithAcademies>승차권 관리</PageHeaderWithAcademies> */}
      <TicketController
        ref={controllerRef.ref}
        getListTicket={getListTicket}
        setSelectedDate={setSelectedWeekday}
      />
      <Container>
        <TicketList
          ref={listRef.ref}
          getTicketDetail={getTicketDetail}
          getTicketMap={getTicketMap}
        />
        <TicketDetail
          ref={detailRef.ref}
          refreshListTicket={refreshListTicket}
        />
        <NavigationMap ref={mapRef.ref} />
      </Container>
    </ScreenContainer>
  )
}

export default TicketManagement

const Container = styled.div`
  display: grid;
  grid-template-columns: 43rem 43rem 1fr;
  column-gap: 2rem;
  justify-content: space-between;
  margin-top: 2rem;

  ${media.desktop} {
    flex-wrap: nowrap;
  }
`
