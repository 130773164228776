import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../../../style/CommonStyle'
import {CDispatchCourseWithStudent} from '../../../../../../../../model/Dispatch'
import {DispatchTypeEnum} from '../../../../../../../../enum/DispatchTypeEnum'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
} from '../../../../../../../../service/dispatch/Dispatch'
import {throwError} from '../../../../../../../../util/ErrorUtil'
import {StudentLoadTypeEnum} from '../../../../../../../../enum/StudentLoadTypeEnum'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../../recoil/Atom'
import CourseItemWithoutStudent from '../../../../common/loadStudent/CourseItemWithoutStudent'

type Props = {
  dispatchCode: string
  courseCode: string
  studentLoadType: StudentLoadTypeEnum
  type: DispatchTypeEnum
}

export default function StationInfo(props: Props) {
  const academyID = useRecoilValue(academyIDState)

  const [courses, setCourses] = useState<CDispatchCourseWithStudent[]>([])

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => setCourses(cws))
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourses],
  )

  useEffect(() => {
    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.courseCode,
      type: props.studentLoadType,
    }

    fetchCourses(data)
  }, [props.dispatchCode, props.courseCode, props.studentLoadType, academyID])

  return (
    <StationInfos>
      {courses.map((course, idx) => {
        const isFirstItem = idx === 0
        const isLastItem = idx === courses.length - 1

        return (
          <CourseItemWithoutStudent
            key={idx}
            course={course}
            isFirstItem={isFirstItem}
            isLastItem={isLastItem}
            dispatchType={props.type}
          />
        )
      })}
    </StationInfos>
  )
}

const StationInfos = styled.div`
  ${flexColumn};
  padding: 0.6rem 0.8rem;
  row-gap: 1.2rem;
  background: #ffffff;
  border: 0.1rem solid #ebebeb;
  border-top-width: 0;
  border-radius: 0 0 0.8rem 0.8rem;
`
