import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {API} from '../../Env'
import {
  CBusBrief,
  CBusDispatchSummary,
  CBusMemo,
  CBusPeriod,
  CBusVector,
} from '../../model/Bus'
import {WeekdayEnum} from '../../enum/WeekdayEnum'
import {CDate} from '../../model/Date'
import {CDispatchPeriodInfo} from '../../model/DispatchPeriod'

export type GetBusesParams = {
  academyID: string
  page?: number
  searchType?: string
  searchValue?: string
  size?: number
}

export async function getBuses(data: GetBusesParams): Promise<CBusVector> {
  const url = `${API}/${data.academyID}/buses`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      searchType: data.searchType,
      searchValue: data.searchValue,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CBusVector.create(axiosResponse.data.data)
}

export type GetBusesWithDispatchPeriodIDData = {
  academyID: string
  dispatchPeriodID: number
  page?: number
  searchType?: string
  searchValue?: string
  size?: number
}

export async function getBusesWithDispatchPeriodID(
  data: GetBusesWithDispatchPeriodIDData,
): Promise<CBusVector> {
  const url = `${API}/${data.academyID}/buses/dispatch-period`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodId: data.dispatchPeriodID,
      page: data.page,
      searchType: data.searchType,
      searchValue: data.searchValue,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CBusVector.create(axiosResponse.data.data)
}

export type PostBusData = {
  driverID: number
  name: string
  licensePlate: string
  validSeat: number
  transportCompany: string
}

export async function postBus(
  academyID: string,
  data: PostBusData,
): Promise<void> {
  const url = `${API}/${academyID}/buses`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      driverId: data.driverID,
      licensePlate: data.licensePlate,
      name: data.name,
      transportCompany: data.transportCompany,
      validSeat: data.validSeat,
    },
  }

  await axios(config)
}

export async function getBusBrief(
  academyID: string,
  busID: number,
): Promise<CBusBrief> {
  const url = `${API}/${academyID}/buses/${busID}/briefing`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return CBusBrief.create(axiosResponse.data.data)
}

export type PatchDriverParams = {
  academyId: string
  busId: string
  driverId: number
  changeDriverId: number
}

export async function patchBusDriver(data: PatchDriverParams) {
  const url = `${API}/${data.academyId}/buses/${
    data.busId
  }/driver/${data.driverId.toString()}`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      driverId: data.changeDriverId,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function deleteBuses(academyID: string, busID: string) {
  const url = `${API}/${academyID}/buses/${busID}/expire`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export async function getBusesMemo(academyID: string, busID: number) {
  const url = `${API}/${academyID}/buses/${busID}/memo`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  return CBusMemo.create(axiosResponse.data.data)
}

export async function patchBusesMemo(
  academyId: string,
  busId: string,
  memo: string,
) {
  const url = `${API}/${academyId}/buses/${busId}/memo`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      memo: memo,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data
}

export type PatchLicensePlateParams = {
  academyId: string
  busId: string
  regNum: string
}

export async function patchLicensePlate(data: PatchLicensePlateParams) {
  const url = `${API}/${data.academyId}/buses/${data.busId}/license-plate`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      regNum: data.regNum,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type PatchBusNameParams = {
  academyId: string
  busId: string
  name: string
}

export async function patchBusName(data: PatchBusNameParams) {
  const url = `${API}/${data.academyId}/buses/${data.busId}/name`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      name: data.name,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type PatchBusSeatParams = {
  academyId: string
  busId: string
  validSeat: number
}

export async function patchBusSeat(data: PatchBusSeatParams) {
  const url = `${API}/${data.academyId}/buses/${data.busId}/seat`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      validSeat: data.validSeat,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type PatchBusCompanyParams = {
  academyId: string
  busId: string
  transportCompany: string
}

export async function patchBusCompany(data: PatchBusCompanyParams) {
  const url = `${API}/${data.academyId}/buses/${data.busId}/transport-company`

  const config = {
    method: AxiosEnum.PATCH.value,
    url: url,
    data: {
      transportCompany: data.transportCompany,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}

export type GetBusesDispatchListRequestData = {
  academyID: string
  busID: number
  searchDate: CDate
}

export async function getBusesDispatchList(
  data: GetBusesDispatchListRequestData,
): Promise<CBusDispatchSummary[]> {
  const url = `${API}/${data.academyID}/buses/${data.busID}/date`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      searchDate: data.searchDate.toString(),
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data.map((el: any) =>
    CBusDispatchSummary.create(el),
  )
}

export type GetBusPeriodData = {
  academyID: string
  busID: number
  dispatchPeriodID: number
}
export async function getBusPeriod(data: GetBusPeriodData) {
  const url = `${API}/${data.academyID}/buses/${data.busID}/period`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodId: data.dispatchPeriodID,
    },
  }

  const axiosResponse = await axios(config)

  return CBusPeriod.create(axiosResponse.data.data)
}

export type ClassTimeByWeekday = {
  times: string[]
  weekday: WeekdayEnum
}
export type PostBusesInvisibleClassData = {
  academyID: string
  busID: number
  dispatchPeriodID: number
  invisible: ClassTimeByWeekday[]
}
export async function postBusInvisibleClass(data: PostBusesInvisibleClassData) {
  const url = `${API}/${data.academyID}/buses/${data.busID}/invisible-class`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      dispatchPeriodId: data.dispatchPeriodID,
      invisible: data.invisible.map(el => {
        return {
          times: el.times,
          weekday: el.weekday.value,
        }
      }),
    },
  }

  await axios(config)
}

export async function getDispatchDetailByBusID(
  academyID: string,
  busID: number,
) {
  const url = `${API}/${academyID}/buses/${busID}/dispatch-period`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)
  return axiosResponse.data.data.map((el: any) =>
    CDispatchPeriodInfo.create(el),
  )
}
