export class BusDetailLocationEnum {
  static readonly DISPATCH = new BusDetailLocationEnum('dispatch')
  static readonly BUS = new BusDetailLocationEnum('bus')

  private constructor(readonly value: string) {}

  static parse(v: string) {
    switch (v) {
      case BusDetailLocationEnum.DISPATCH.value:
        return BusDetailLocationEnum.DISPATCH
      case BusDetailLocationEnum.BUS.value:
        return BusDetailLocationEnum.BUS
      default:
        throw new Error(`invalid location. (location: ${v})`)
    }
  }
}
