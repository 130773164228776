import {API} from '../../Env'
import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {CCourseVector} from '../../model/Course'
import {DispatchTypeEnum} from '../../enum/DispatchTypeEnum'
import {CourseRouteWithCheck} from '../../component/busDetail/dispatchInfo/period/edit/operationInfo/full/extraBus/ExtraBusAdd'
import {CCourseDetail} from '../../model/Station'

export type GetCoursesData = {
  academyID: string
  page: number
  size: number
  semesterId: number
}
export async function getCourses(data: GetCoursesData): Promise<CCourseVector> {
  const url = `${API}/${data.academyID}/courses/semester`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  return CCourseVector.create(axiosResponse.data.data)
}

export type GetSearchCoursesData = {
  academyID: string
  page: number
  size: number
  keyword: string
  dispatchType: DispatchTypeEnum[]
}

export async function getSearchCourses(
  data: GetSearchCoursesData,
): Promise<CCourseVector> {
  const url = `${API}/${data.academyID}/search/courses`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      size: data.size,
      keyword: data.keyword,
      dispatchType: data.dispatchType.map(el => el.value),
    },
  }

  const axiosResponse = await axios(config)

  return CCourseVector.create(axiosResponse.data.data)
}

export async function getSearchCourseDetail(
  academyID: string,
  id: string,
  dispatchPeriodId?: number,
) {
  const url = `${API}/${academyID}/courses/${id}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodId: dispatchPeriodId,
    },
  }

  const axiosResponse = await axios(config)

  return CCourseDetail.create(axiosResponse.data.data)
}

export type PostCoursesData = {
  academyID: string
  dispatchType: DispatchTypeEnum
  name: string
  routes: CourseRouteWithCheck[]
}
export async function postCourses(data: PostCoursesData): Promise<any> {
  const url = `${API}/${data.academyID}/courses`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      dispatchType: data.dispatchType.value,
      name: data.name,
      route: data.routes.map(r => {
        return {
          stationId: r.course.stationID,
          spendTime: r.spendTime,
        }
      }),
    },
  }

  const axiosResponse = await axios(config)
  return axiosResponse.data.data
}
