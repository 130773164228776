import React, {ForwardedRef, forwardRef, ReactElement} from 'react'
import styled, {css} from 'styled-components'

import {OnBoard} from './OnBoard'
import {color} from '../../../style/CommonStyle'
import {CDispatchCourse} from '../../../model/Dispatch'

import DepartureStationIcon from '../../../asset/image/departure_station.svg'
import ArrivalStationIcon from '../../../asset/image/arrival_station.svg'
import {OffBoard} from './OffBoard'

type TicketDetailStationProps = {
  details: CDispatchCourse[]
}

export type TicketDetailStationRef = {}

function TicketDetailStationBase(
  props: TicketDetailStationProps,
  _: ForwardedRef<TicketDetailStationRef>,
): ReactElement {
  return (
    <StationUl>
      {props.details.map((d, idx) => {
        const onBoardStudents = d.station.students.filter(
          s => s.takeOffStationId !== d.station.id,
        )
        const offBoardStudents = d.station.students.filter(
          s => s.takeOffStationId === d.station.id,
        )

        return (
          <StationLi key={idx}>
            <TimeWrapper>{d.station.time}</TimeWrapper>
            <StationBox order={idx} courseCnt={props.details.length}>
              <StationNameWrapper>
                <StationName>{d.station.name}</StationName>
                <BoardWrapper>
                  <OnBoard
                    stationId={d.station.id}
                    students={onBoardStudents}
                    studentId={123}
                  />
                  <OffBoard
                    stationId={d.station.id}
                    students={offBoardStudents}
                    studentId={123}
                  />
                </BoardWrapper>
              </StationNameWrapper>
            </StationBox>
          </StationLi>
        )
      })}
    </StationUl>
  )
}

const TicketDetailStation = forwardRef(TicketDetailStationBase)
export default TicketDetailStation

const StationUl = styled.ul`
  padding: 6rem 3.2rem 3rem;
  overflow-y: scroll;
`

const StationLi = styled.li`
  display: flex;
  min-height: 11rem;
`

const TimeWrapper = styled.div`
  width: 10rem;
  font-size: 1.4rem;
`

const StationNameWrapper = styled.div`
  padding: 0 0 3.5rem 3rem;
  position: relative;
`

const StationName = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1.6rem;

  :hover {
    font-weight: 700;
    cursor: pointer;
  }
`
type StationBoxProps = {
  order: number
  courseCnt: number
}

const StationBox = styled.div<StationBoxProps>`
  flex-basis: 80%;
  ${({order, courseCnt}) =>
    order + 1 === courseCnt
      ? css`
          border-left: none;
        `
      : order !== courseCnt
        ? css`
            border-left: 0.2rem solid #000;
            position: absolute;
            top: 0;
          `
        : ''};
  position: relative;

  ::before {
    ${({order, courseCnt}) =>
      order === 0
        ? css`
            content: '${order + 1}';
            background-color: #ffcd00;
            border: 2px solid ${color.black};
            /* content: url(${DepartureStationIcon});
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 5rem; */
          `
        : order + 1 === courseCnt
          ? css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
              /* content: url(${ArrivalStationIcon});
              margin-left: 0.4rem;
              margin-top: 0.3rem; */
            `
          : css`
              content: '${order + 1}';
              background-color: #ffcd00;
              border: 2px solid ${color.black};
            `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 3rem;
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    left: -1.2rem;
    top: -0.2rem;
    line-height: 1.1;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
`
const BoardWrapper = styled.div`
  min-height: 4rem;
`
