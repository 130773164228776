import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {useNavigate, useParams} from 'react-router'
import {useLocation} from 'react-router-dom'
import {Optional} from '../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import BusIcon from '../../../asset/image/dispatch_icon.svg'
import YellowArrowDownIcon from '../../../asset/image/yellow_arrow-down.svg'
import SVGImage from '../../common/SVGImage'
import {isNil} from '../../../util/ValidationUtil'
import HalfPopup from '../../common/HalfPopUp'
import useSecureRef from '../../../hook/useSecureRef'
import ChangeBusName, {
  ModalContainerRef,
} from '../../busesManagement/popupContents/ChangeBusName'
import {DeleteBus} from '../../busesManagement/popupContents/DeleteBus'
import Toast from '../../toast/Toast'
import {
  deleteBuses,
  getBusesMemo,
  patchBusCompany,
  patchBusDriver,
  patchBusesMemo,
  patchBusName,
  patchBusSeat,
  patchLicensePlate,
} from '../../../service/buses/Buses'
import ChangeTotalSeat from '../../busesManagement/popupContents/ChangeTotalSeat'
import ChangeRegNum from '../../busesManagement/popupContents/ChangeRegNum'
import ChangeTransPortCompany from '../../busesManagement/popupContents/ChangeTransPortCompany'
import ChangeDriver from '../../busesManagement/popupContents/ChangeDriver'
import {CBusBrief} from '../../../model/Bus'
import {DisableDeleteBus} from '../../busesManagement/popupContents/DisableDeleteBus'

type Props = {
  busBrief: CBusBrief
  type?: string
  fetchBusBrief(): void
}

export default function BasicInfo(props: Props) {
  const location: any = useLocation()
  const navigate = useNavigate()
  const academyID = useRecoilValue<Optional<string>>(academyIDState)
  const params = useParams()

  const nameRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] nameRef',
  )
  const totalSeatRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] totalSeatRef',
  )
  const regNumRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] regNumRef',
  )
  const transportCompanyRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] transportCompanyRef',
  )
  const driverRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] driverRef',
  )
  const deleteDispatchRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] deleteDispatchRef',
  )
  const disableDeleteRef = useSecureRef<ModalContainerRef>(
    '[BusDetailBasicInfo.tsx] disableDeleteRef',
  )

  const [memoStatus, setMemoStatus] = useState<boolean>(false)
  const [name, setName] = useState<Optional<string>>('')
  const [validSeat, setValidSeat] = useState<Optional<number>>(null)
  const [regNum, setRegNum] = useState<Optional<string>>('')
  const [transportCompany, setTransportCompany] = useState<Optional<string>>('')
  const [driver, setDriver] = useState<Optional<string>>('')
  const [driverID, setDriverID] = useState<Optional<number>>(null)
  const [memo, setMemo] = useState<string>('')
  const [initialMemo, setInitialMemo] = useState<Optional<boolean>>(false)
  const [driverModifyStatus, setDriverModifyStatus] = useState<boolean>(false)
  const [nameToast, setNameToast] = useState<boolean>(false)
  const [totalSeatToast, setTotalSeatToast] = useState<boolean>(false)
  const [regNumToast, setRegNumToast] = useState<boolean>(false)
  const [busCompanyToast, setBusCompanyToast] = useState<boolean>(false)
  const [driverToast, setDriverToast] = useState<boolean>(false)
  const [registerMemoToast, setRegisterMemoToast] = useState<boolean>(false)
  const [deleteMemoToast, setDeleteMemoToast] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)
  const [driverPopUp, setDriverPopUp] = useState(false)
  const [keyword, setKeyword] = useState<string>('')

  const busID = useMemo(() => {
    const id = Number(params.id)

    if (isNaN(id) || isNil(id)) {
      return null
    }

    return id
  }, [params])

  const fetchBusMemo = useCallback(() => {
    if (isNil(academyID) || isNil(busID)) {
      return
    }

    getBusesMemo(academyID, busID)
      .then(bm => {
        setMemo(bm.memo)
        if (isNil(bm.memo) || bm.memo === '') {
          setInitialMemo(false)
        } else setInitialMemo(true)
      })
      .catch(error => {
        throw new Error(
          `getBusMemo() failed. (academyID: ${academyID}, busID: ${busID}, error: ${error})`,
        )
      })
  }, [academyID, busID])

  useEffect(() => {
    fetchBusMemo()
  }, [academyID, busID])

  const showBusNamePopUp = useCallback(() => {
    nameRef.current().show()
  }, [])

  const showTotalSeatPopUp = useCallback(() => {
    totalSeatRef.current().show()
  }, [])

  const showRegNumPopUp = useCallback(() => {
    regNumRef.current().show()
  }, [])

  const showTransportCompanyPopUp = useCallback(() => {
    transportCompanyRef.current().show()
  }, [])

  const showDriverPopUp = useCallback(() => {
    driverRef.current().show()
  }, [])

  const handleCloseButton = useCallback(() => {
    nameRef.current().hide()
    totalSeatRef.current().hide()
    regNumRef.current().hide()
    transportCompanyRef.current().hide()
    driverRef.current().hide()
    deleteDispatchRef.current().hide()
    setDriverPopUp(false)
    setKeyword('')
    setName('')
    setValidSeat(null)
    setRegNum('')
    setTransportCompany('')
    setError(null)
  }, [setName, setValidSeat, setRegNum, setTransportCompany, setError])

  const deleteDispatchPopUp = useCallback(() => {
    deleteDispatchRef.current().show()
  }, [])

  const onClickDeleteButton = useCallback(() => {
    deleteBuses(academyID, busID.toString())
      .then(dm => {
        if (props.type === 'bus' && dm === 'OK') {
          navigate('/buses', {state: {toast: true}})
        }
        if (dm === 'OK') {
          navigate('/buses', {state: {toast: true}})
        }
      })
      .catch(error => {
        // TODO: 탈퇴 처리안될때 에러 확인 후 표시
        deleteDispatchRef.current().hide()
        disableDeleteRef.current().show()
      })
  }, [])

  const onChangeMemo = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(e.target.value)
  }, [])

  const registerMemo = useCallback(() => {
    patchBusesMemo(academyID, busID.toString(), memo)
      .then(() => {
        setRegisterMemoToast(true)
        fetchBusMemo()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo])

  const deleteMemo = useCallback(() => {
    patchBusesMemo(academyID, busID.toString(), '')
      .then(() => {
        setDeleteMemoToast(true)
        fetchBusMemo()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo])

  const changeBusName = useCallback(() => {
    const data = {
      academyId: academyID,
      busId: busID.toString(),
      name,
    }
    patchBusName(data)
      .then(cn => {
        if (cn === 'OK') {
          nameRef.current().hide()
          setName('')
          setNameToast(true)
          props.fetchBusBrief()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [name, error, props.fetchBusBrief])

  const changeTotalSeat = useCallback(() => {
    const data = {
      academyId: academyID,
      busId: busID.toString(),
      validSeat: validSeat,
    }
    patchBusSeat(data)
      .then(cs => {
        if (cs === 'OK') {
          totalSeatRef.current().hide()
          setValidSeat(0)
          setTotalSeatToast(true)
          props.fetchBusBrief()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [validSeat, props.fetchBusBrief])

  const changeRegNum = useCallback(() => {
    const data = {
      academyId: academyID,
      busId: busID.toString(),
      regNum,
    }
    patchLicensePlate(data)
      .then(cr => {
        if (cr === 'OK') {
          regNumRef.current().hide()
          setRegNum('')
          setRegNumToast(true)
          props.fetchBusBrief()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [regNum, props.fetchBusBrief])

  const changeTransPortCompany = useCallback(() => {
    const data = {
      academyId: academyID,
      busId: busID.toString(),
      transportCompany,
    }
    patchBusCompany(data)
      .then(cc => {
        if (cc === 'OK') {
          transportCompanyRef.current().hide()
          setTransportCompany('')
          setBusCompanyToast(true)
          props.fetchBusBrief()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [transportCompany, props.fetchBusBrief])

  const changeDriver = useCallback(() => {
    const data = {
      academyId: academyID,
      busId: busID.toString(),
      driverId: props.busBrief.driver[0].id,
      changeDriverId: driverID,
    }
    patchBusDriver(data)
      .then(cd => {
        if (cd === 'OK') {
          driverRef.current().hide()
          setDriverPopUp(false)
          setKeyword('')
          setDriverID(null)
          setDriverToast(true)
          props.fetchBusBrief()
          setError(null)
        }
        setDriverModifyStatus(true)
      })
      .catch(error => setError(error.response.data.data))
  }, [driverID, props.fetchBusBrief, setDriverModifyStatus])

  useEffect(() => {
    if (nameToast) {
      const timeoutId = setTimeout(() => {
        setNameToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
    if (totalSeatToast) {
      const timeoutId = setTimeout(() => {
        setTotalSeatToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (regNumToast) {
      const timeoutId = setTimeout(() => {
        setRegNumToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (busCompanyToast) {
      const timeoutId = setTimeout(() => {
        setBusCompanyToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (driverToast) {
      const timeoutId = setTimeout(() => {
        setDriverToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (registerMemoToast) {
      const timeoutId = setTimeout(() => {
        setRegisterMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (deleteMemoToast) {
      const timeoutId = setTimeout(() => {
        setDeleteMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [
    nameToast,
    regNumToast,
    driverToast,
    busCompanyToast,
    totalSeatToast,
    registerMemoToast,
    deleteMemoToast,
  ])

  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(
    location.state?.from === 'dispatch' ? false : true,
  )

  // FoldButton 클릭 시 호출되는 함수
  const toggleInfoVisibility = useCallback(() => {
    setIsInfoVisible(prev => !prev)
  }, [])

  return (
    <Container>
      <TitleWrapper>
        <Title>기본 정보</Title>
        <FoldButton onClick={toggleInfoVisibility}>
          <YellowArrowDownIconImg
            source={YellowArrowDownIcon}
            isInfoVisible={isInfoVisible}
          />
        </FoldButton>
      </TitleWrapper>
      {isInfoVisible && (
        <InfoWrapper>
          <InfoCard>
            <DriverImgExpireWrapper>
              <DriverImg source={BusIcon} />
              <ExpireButton onClick={deleteDispatchPopUp}>삭제</ExpireButton>
            </DriverImgExpireWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>호차 정보</Category>
                <ValueButtonWrapper>
                  <Value>{props.busBrief.bus?.name}</Value>
                  <ModifyButton onClick={showBusNamePopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>가용 좌석 정보</Category>
                <ValueButtonWrapper>
                  <Value>{props.busBrief.bus?.totalSeat}개</Value>
                  <ModifyButton onClick={showTotalSeatPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>차량 번호</Category>
                <ValueButtonWrapper>
                  <Value>{props.busBrief.bus?.regNum}</Value>
                  <ModifyButton onClick={showRegNumPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>운수사</Category>
                <ValueButtonWrapper>
                  <Value>{props.busBrief.bus?.transportCompany}</Value>
                  <ModifyButton onClick={showTransportCompanyPopUp}>
                    수정
                  </ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
            <CategoryColumnWrapper style={{width: '30rem'}}>
              <CategoryWrapper>
                <Category>운행 기사</Category>
                <ValueButtonWrapper>
                  <Value>{`${props.busBrief.driver[0]?.name} | ${props.busBrief.driver[0]?.phone}`}</Value>
                  <ModifyButton onClick={showDriverPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
          </InfoCard>
          <MemoContainer>
            <HeaderWrapper>
              <Category>메모</Category>
              {!initialMemo ? (
                <MemoButton onClick={registerMemo}>메모 등록</MemoButton>
              ) : (
                <MemoButtonWrapper>
                  <ModifyMemoButton onClick={registerMemo}>
                    수정
                  </ModifyMemoButton>
                  <DeleteMemoButton onClick={deleteMemo}>삭제</DeleteMemoButton>
                </MemoButtonWrapper>
              )}
            </HeaderWrapper>
            <TextArea
              placeholder="작성된 메모가 없습니다."
              value={memo}
              onChange={e => onChangeMemo(e)}
              disabled={memoStatus}
            />
          </MemoContainer>
        </InfoWrapper>
      )}
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={deleteDispatchRef.ref}
        contents={
          <DeleteBus
            onClose={handleCloseButton}
            name={props.busBrief.bus.name}
            onClickDeleteButton={onClickDeleteButton}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={nameRef.ref}
        contents={
          <ChangeBusName
            onClose={handleCloseButton}
            confirmButton={changeBusName}
            name={props.busBrief.bus.name}
            originalName={name}
            setName={setName}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={totalSeatRef.ref}
        contents={
          <ChangeTotalSeat
            onClose={handleCloseButton}
            confirmButton={changeTotalSeat}
            totalSeat={props.busBrief.bus.totalSeat}
            originalTotalSeat={validSeat}
            setValidSeat={setValidSeat}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={regNumRef.ref}
        contents={
          <ChangeRegNum
            onClose={handleCloseButton}
            confirmButton={changeRegNum}
            regNum={props.busBrief.bus.regNum}
            originalRegNum={regNum}
            setRegNum={setRegNum}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={transportCompanyRef.ref}
        contents={
          <ChangeTransPortCompany
            onClose={handleCloseButton}
            confirmButton={changeTransPortCompany}
            transportCompany={props.busBrief.bus.transportCompany}
            originalTransportCompany={transportCompany}
            setTransportCompany={setTransportCompany}
            error={error}
          />
        }
      />

      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={driverRef.ref}
        contents={
          <ChangeDriver
            onClose={handleCloseButton}
            confirmButton={changeDriver}
            driver={`${props.busBrief.driver[0].name} | ${props.busBrief.driver[0].phone}`}
            driverId={props.busBrief.driver[0].id}
            originalDriver={driver}
            setDriverID={setDriverID}
            error={error}
            driverModifyStatus={driverModifyStatus}
            setDriverModifyStatus={setDriverModifyStatus}
            driverPopUp={driverPopUp}
            setDriverPopUp={setDriverPopUp}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
      />

      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={disableDeleteRef.ref}
        contents={
          <DisableDeleteBus
            onClose={() => {
              navigate('/buses')
              disableDeleteRef.current().hide()
            }}
          />
        }
      />

      {nameToast && (
        <Toast
          text={`호차명이 수정 되었습니다.`}
          status={nameToast ? 'toast' : ''}
        />
      )}
      {totalSeatToast && (
        <Toast
          text={`${props.busBrief.bus.name}의 좌석 정보가 수정 되었습니다.`}
          status={totalSeatToast ? 'toast' : ''}
        />
      )}
      {regNumToast && (
        <Toast
          text={`${props.busBrief.bus.name}의 차량 번호가 수정 되었습니다.`}
          status={regNumToast ? 'toast' : ''}
        />
      )}
      {busCompanyToast && (
        <Toast
          text={`${props.busBrief.bus.name}의 운수사가 수정 되었습니다.`}
          status={busCompanyToast ? 'toast' : ''}
        />
      )}
      {driverToast && (
        <Toast
          text={`${props.busBrief.bus.name}의 운행 기사가 수정 되었습니다.`}
          status={driverToast ? 'toast' : ''}
        />
      )}
      {registerMemoToast && (
        <Toast
          text={`메모 등록이 완료되었습니다.`}
          status={registerMemoToast ? 'toast' : ''}
        />
      )}
      {deleteMemoToast && (
        <Toast
          text={`${props.busBrief.bus.name}에 등록된 메모를 삭제 하였습니다.`}
          status={deleteMemoToast ? 'toast' : ''}
        />
      )}
    </Container>
  )
}

const Container = styled.div``

const HeaderWrapper = styled.div`
  ${flexRow};
  width: 100%;
  height: 1.8rem;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
`

const MemoButton = styled.button`
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  height: 1.8rem;
  color: #fff1b3;
  background: #333333;
  border-radius: 1.6rem;
  padding: 0.2rem 0.8rem;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  column-gap: 0.8rem;
`

const InfoCard = styled.div`
  ${flexRow};
  width: 100%;
  height: 13rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1.6rem;
  padding: 1.6rem 1.2rem;
`

const DriverImgExpireWrapper = styled.div`
  ${flexColumn};
  align-items: center;
  row-gap: 1.2rem;
  width: 4.8rem;
  margin-right: 1.6rem;
`

const DriverImg = styled(SVGImage)`
  width: 4.8rem;
  height: 4.8rem;
`

const ExpireButton = styled(MemoButton)``

const CategoryColumnWrapper = styled.div`
  ${flexColumn};
  width: 20rem;
  row-gap: 1.2rem;
`

const CategoryWrapper = styled.div`
  ${flexColumn};
  row-gap: 0.2rem;
`

const Category = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
  color: #1a468b;
`

const Value = styled(Category)`
  font-size: 1.4rem;
  color: #000;
  > span {
    font-size: 1.2rem;
    font-weight: 300;
  }
`

const ValueButtonWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const ModifyButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  padding: 0.2rem 0.4rem;
  color: #2767ce;
  background: #fff;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border: none;
  resize: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.65rem;

  :disabled {
    background: #fff;
  }
  :focus {
    outline: none;
    background: #fffbe5;
  }
`

const MemoContainer = styled(InfoCard)`
  ${flexColumn}

  :focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ffd100;

    > textarea {
      background: #fffbe5;
    }
  }
`

const MemoButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const ModifyMemoButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  background: #fff;
  color: #2767ce;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const DeleteMemoButton = styled.button`
  border: 0.05rem solid #333;
  background: #333;
  border-radius: 1.6rem;
  color: #fff1b3;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const TitleWrapper = styled.div`
  ${flexRow};
  align-items: center;
  gap: 5px;
  margin-bottom: 0.8rem;
`

const FoldButton = styled.div`
  font-size: 12px;
  cursor: pointer;
`

const YellowArrowDownIconImg = styled(SVGImage)<{
  isInfoVisible: boolean
}>`
  width: 14px;
  height: 14px;
  cursor: pointer;
  transform: ${props =>
    props.isInfoVisible ? 'rotate(180deg)' : 'rotate(0deg)'};
`
