import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'
import {
  CDispatchPeriod,
  CDispatchPeriodBusVector,
} from '../../../../model/DispatchPeriod'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import SelectedBookMarked from '../../../../asset/image/bookmark_checked.svg'
import BookMarked from '../../../../asset/image/bookmark_unchecked.svg'
import {isEmptyArray} from '../../../../util/ValidationUtil'
import {
  deleteDispatchPeriodBusBookmark,
  postDispatchPeriodBusBookmark,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {alertError} from '../../../../util/ErrorUtil'
import {useNavigate} from 'react-router'
import {BusDetailLocationEnum} from '../../../../enum/BusDetailLocationEnum'

type Props = {
  item: CDispatchPeriodBusVector
  dispatchPeriod: CDispatchPeriod
  onBookmark(dpID: number): void
}

export default function DispatchListItem(props: Props): ReactElement {
  const navigate = useNavigate()
  const academyID = useRecoilValue(academyIDState)
  const [isBookMarked, setIsBookMarked] = useState<boolean>(
    props.item.bus.isBookMark,
  )

  const BookMarkImageComponent = useMemo(() => {
    if (!isBookMarked) {
      return <BookMarkImage source={BookMarked} />
    }

    return <SelectedBookMarkImage source={SelectedBookMarked} />
  }, [isBookMarked])

  const busName = useMemo(() => {
    if (props.item.bus.name.length > 10) {
      return `${props.item.bus.name.slice(0, 10)}...`
    }

    return props.item.bus.name
  }, [props.item.bus.name])

  const operatingDays = useMemo(() => {
    if (isEmptyArray(props.item.bus.operatingWeekDays)) {
      return '배차미등록'
    }

    return props.item.bus.operatingWeekDays.map(wd => wd.exposure).join(' ,')
  }, [props.item])

  const addBookmark = useCallback(() => {
    postDispatchPeriodBusBookmark(
      academyID,
      props.item.bus.id,
      props.dispatchPeriod.dispatchPeriod.id,
    )
      // .then(() => props.onBookmark())
      .catch(error => {
        alertError(
          error,
          `postDispatchPeriodBusBookmark() failed. (aid: ${academyID}, dpID: ${props.dispatchPeriod.dispatchPeriod.id}, bid: ${props.item.bus.id}, error: ${error})`,
        )
      })
  }, [academyID, props.item, props.dispatchPeriod])

  const cancelBookmark = useCallback(() => {
    deleteDispatchPeriodBusBookmark(
      academyID,
      props.item.bus.id,
      props.dispatchPeriod.dispatchPeriod.id,
    )
      // .then(() => props.onBookmark())
      .catch(error => {
        alertError(
          error,
          `deleteDispatchPeriodBusBookmark() failed. (aid: ${academyID}, dpID: ${props.dispatchPeriod.dispatchPeriod.id}, bid: ${props.item.bus.id}, error: ${error})`,
        )
      })
  }, [academyID, props.item, props.dispatchPeriod])

  const onPressBookmark = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setIsBookMarked(prev => {
        if (prev) {
          cancelBookmark()
        } else {
          addBookmark()
        }

        return !prev
      })
    },
    [addBookmark, cancelBookmark, isBookMarked],
  )

  const onClickListItem = useCallback(() => {
    navigate(`/bus/${props.item.bus.id}`, {
      state: {
        from: BusDetailLocationEnum.DISPATCH.value,
        dispatchPeriodID: props.dispatchPeriod.dispatchPeriod.id,
      },
    })
  }, [navigate, props.item])

  return (
    <Container onClick={onClickListItem}>
      <BusContainer>
        <BusNameContainer>
          <BusNameText>{busName}</BusNameText>
          <BookMarkButton onClick={onPressBookmark}>
            {BookMarkImageComponent}
          </BookMarkButton>
        </BusNameContainer>
        <BusNameText>{props.item.bus.plateNumber}</BusNameText>
      </BusContainer>
      <DriverContainer>
        <DriverNameText>{props.item.driver.name}</DriverNameText>
        <DriverPhoneText>{props.item.driver.phone}</DriverPhoneText>
      </DriverContainer>
      <DispatchContainer>
        <DispatchText>운행요일 : {operatingDays}</DispatchText>
      </DispatchContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 13.7rem;
  height: 11.3rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  cursor: pointer;
`

const BusContainer = styled.div`
  width: 100%;
  height: 5.2rem;
  background: #404040;
  padding: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
  overflow: hidden;
`

const BusNameContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const BusNameText = styled.div`
  color: #ffd100;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 10rem;
`

const BookMarkButton = styled.button`
  border: none;
  width: 1.2rem;
  height: 1.2rem;
  background: #404040;

  &:hover {
    transform: scale(1.1);
  }
`

const BookMarkImage = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const SelectedBookMarkImage = styled(SVGImage)`
  width: 1.2rem;
  height: 1.2rem;
`

const DriverContainer = styled.div`
  width: 100%;
  height: 3.8rem;
  padding: 0.4rem 0.8rem;
  border-bottom: 0.1rem solid #ebebeb;
  background: #f5f5f5;
`

const DriverNameText = styled.div`
  color: #999999;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const DriverPhoneText = styled(DriverNameText)`
  font-weight: 500;
`

const DispatchContainer = styled.div`
  width: 100%;
  height: 2.3rem;
  background: #f5f5f5;
  padding: 0.4rem 0.8rem;
  border-radius: 0 0 0.8rem 0.8rem;
`

const DispatchText = styled.div`
  color: #ffac66;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
