import React from 'react'
import {Outlet} from 'react-router'
import styled from 'styled-components'
import {color} from '../../style/CommonStyle'
import axios from 'axios'
import qs from 'qs'

function AppLayout() {
  axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, {encode: false, arrayFormat: 'repeat'})
  }

  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default AppLayout

const Container = styled.div`
  width: 100%;
  background-color: ${color.grey1};
  padding: 2rem 3rem 2rem;
`
