export class OperationTypeEnum {
  static readonly INBOUND = new OperationTypeEnum('등원', '승차', 'INBOUND')
  static readonly OUTBOUND = new OperationTypeEnum('하원', '하차', 'OUTBOUND')
  static readonly CIRCULATION = new OperationTypeEnum(
    '순환',
    '순환',
    'CIRCULATION',
  )

  static readonly ALL = [this.INBOUND, this.OUTBOUND, this.CIRCULATION]

  private constructor(
    readonly exposure: string,
    readonly boarding: string,
    readonly value: string,
  ) {}

  static parseExposure(exposure: string) {
    switch (exposure) {
      case this.INBOUND.exposure:
        return this.INBOUND
      case this.OUTBOUND.exposure:
        return this.OUTBOUND
      case this.CIRCULATION.exposure:
        return this.CIRCULATION
      default:
        throw new Error(
          `invalid operation type exposure. (exposure: ${exposure})`,
        )
    }
  }

  static parseValue(value: string) {
    switch (value) {
      case this.INBOUND.value:
        return this.INBOUND
      case this.OUTBOUND.value:
        return this.OUTBOUND
      case this.CIRCULATION.value:
        return this.CIRCULATION
      default:
        throw new Error(`invalid operation type value. (value: ${value})`)
    }
  }

  getValue(): string[] {
    return [this.value]
  }
}
