import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {isNil} from '../../util/ValidationUtil'
import {
  CDispatchCirculationTime,
  CDispatchCourseWithStudent,
  CDispatchDetail,
  CDispatchDetailDate,
  CDispatchDetailDispatch,
  CDispatchDetailWithCode,
  CDispatchWeekdayWithCode,
  CDispatchWithSeatOccupancy,
  COverBooking,
} from '../../model/Dispatch'
import {OperationTypeEnum} from '../../enum/OperationTypeEnum'
import {WeekdayEnum} from '../../enum/WeekdayEnum'
import {API} from '../../Env'
import {CDate} from '../../model/Date'
import {OccupancyTypeEnum} from '../../enum/OccupancyTypeEnum'
import {StudentLoadTypeEnum} from '../../enum/StudentLoadTypeEnum'
import {Optional} from '../../type/Common'
import {CBusDispatchTable} from '../../model/Bus'

export type GetDispatchesWithSeatOccupancyParams = {
  academyId: string
  type: OperationTypeEnum[]
  weekday: WeekdayEnum
}

export async function getDispatchesWithSeatOccupancy(
  data: GetDispatchesWithSeatOccupancyParams,
): Promise<CDispatchWithSeatOccupancy[]> {
  const url = `${API}/${data.academyId}/dispatches/with-seat-occupancy`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchType: data.type?.map(d => d.value),
      weekday: data.weekday.id.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data?.map((el: any) =>
    CDispatchWithSeatOccupancy.create(el),
  )
}

export async function getDispatchesOverBooking(
  academyID: string,
  data: {
    boardingCount: number
    dispatchCode: string
    startDate: string
    endDate: string
  },
): Promise<COverBooking[]> {
  const url = `${API}/${academyID}/dispatches/over-booking`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      academyID: academyID,
      dispatchCode: data.dispatchCode,
      boardingCount: data.boardingCount,
      startDate: data.startDate,
      endDate: data.endDate,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => COverBooking.create(el))
}

export type GetDispatchesDetailData = {
  dispatchCode: string
  academyID: string
  dateRange: {start: CDate; end: CDate}
}
export async function getDispatchesDetail(
  data: GetDispatchesDetailData,
): Promise<CDispatchDetail> {
  const url = `${API}/${data.academyID}/dispatches/detail`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchCode: data.dispatchCode,
      startDate: data.dateRange.start?.toString(),
      endDate: data.dateRange.end?.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return CDispatchDetail.create(axiosResponse.data.data)
}

export type GetDispatchesCoursesWithStudentsData = {
  dispatchCode: string
  academyID: string
  courseCode: string
  type: StudentLoadTypeEnum
}
export async function getDispatchesCoursesWithStudent(
  data: GetDispatchesCoursesWithStudentsData,
): Promise<CDispatchCourseWithStudent[]> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/courses/${data.courseCode}/students`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      type: data.type.value,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((dcs: any) =>
    CDispatchCourseWithStudent.create(dcs),
  )
}

export type DeleteDispatchesCoursesWithStudentsData = {
  dispatchCode: string
  academyID: string
  courseCode: string
  type: StudentLoadTypeEnum
}
export async function deleteDispatchesCoursesWithStudents(
  data: DeleteDispatchesCoursesWithStudentsData,
): Promise<CDispatchCourseWithStudent[]> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/courses/${data.courseCode}/students`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
    params: {
      type: data.type.value,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((dcs: any) =>
    CDispatchCourseWithStudent.create(dcs),
  )
}

export type PostDispatchesCoursesStudentsData = {
  dispatchCode: string
  academyID: string
  courseCode: string
  courses: CDispatchCourseWithStudent[]
  type: StudentLoadTypeEnum
}
export async function postDispatchesCoursesStudents(
  data: PostDispatchesCoursesStudentsData,
): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/courses/${data.courseCode}/students`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: data.courses.map(c => {
      return {
        arrivalTime: c.arrivalTime,
        stationId: c.stationID,
        students: c.students.map(s => {
          return {
            check: s.check,
            id: s.id,
            name: s.name,
            nickName: s.nickName,
            parentPhone: s.parentPhone,
            studentPhone: s.studentPhone,
          }
        }),
      }
    }),
    params: {
      type: data.type.value,
    },
  }

  await axios(config)
}

export type GetDispatchesDetailWithDispatchCodeData = {
  dispatchCode: string
  academyID: string
}
export async function getDispatchesDetailWithDispatchCode(
  data: GetDispatchesDetailWithDispatchCodeData,
): Promise<CDispatchDetailWithCode[]> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/detail`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  const json = axiosResponse.data.data

  const temp = [
    {
      type: OccupancyTypeEnum.NORMAL,
      count: json.count.normal,
      dates: json.dispatchDates.normal,
      dispatches: json.normalDispatch,
    },
    {
      type: OccupancyTypeEnum.FULL_SEAT,
      count: json.count.fullSeat,
      dates: json.dispatchDates.fullSeat,
      dispatches: json.fullSeatDispatch,
    },
    {
      type: OccupancyTypeEnum.NOT_REGISTERED_SCHEDULE,
      count: json.count.notRegisteredSchedule,
      dates: json.dispatchDates.notRegisteredSchedule,
      dispatches: json.notRegisteredScheduleDispatch,
    },
  ]

  return temp.map((t: any) => CDispatchDetailWithCode.create(t))
}
export type DispatchSchedule = {
  weekday: WeekdayEnum
  dispatchCode: string
  managerID: Optional<number>
}
export type PostDispatchesData = {
  academyID: string
  busID: number
  courseCode: string
  dispatchPeriodID: number
  dispatchSchedules: DispatchSchedule[]
}
export async function postDispatches(data: PostDispatchesData): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      busId: data.busID,
      courseCode: data.courseCode,
      dispatchPeriodId: data.dispatchPeriodID,
      dispatchSchedule: data.dispatchSchedules.map(ds => {
        return {
          dispatchCode: ds.dispatchCode,
          managerId: ds.managerID,
        }
      }),
    },
  }

  await axios(config)
}

export type PostDispatchesCirculationData = {
  academyID: string
  busID: number
  courseCode: string
  circulationDispatchCode: string
  dispatchPeriodID: number
  dispatchSchedules: DispatchSchedule[]
}
export async function postDispatchesCirculation(
  data: PostDispatchesCirculationData,
): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches/circulation`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      busId: data.busID,
      courseCode: data.courseCode,
      circulationDispatchCode: data.circulationDispatchCode,
      dispatchPeriodId: data.dispatchPeriodID,
      dispatchSchedule: data.dispatchSchedules.map(ds => {
        return {
          dispatchCode: ds.dispatchCode,
          managerId: ds.managerID,
        }
      }),
    },
  }

  await axios(config)
}

export type EditDispatchesData = {
  academyID: string
  dispatchCode: string
  courseCode: string
  managerID: number
  dispatchDates: CDispatchDetailDate[]
}
export async function editDispatches(data: EditDispatchesData): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/detail`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      courseCode: data.courseCode,
      dispatchDates: data.dispatchDates.map(dd => dd.date.toString()),
      managerId: data.managerID,
    },
  }

  await axios(config)
}

export type EditDispatchesExtraBusData = {
  academyID: string
  dispatchCode: string
  busID: number
  courseCode: string
  managerID: number
  dispatchDates: CDispatchDetailDate[]
}
export async function editDispatchesExtraBus(
  data: EditDispatchesExtraBusData,
): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches/separate`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      busId: data.busID,
      courseCode: data.courseCode,
      dispatchDates: data.dispatchDates.map(dd => dd.date.toString()),
      managerId: data.managerID,
      originalDispatchCode: data.dispatchCode,
    },
  }

  await axios(config)
}

export type PostDispatchesExtraBusData = {
  academyID: string
  dispatchCode: string
  busID: number
  courseCode: string
  managerID: number
  dispatchDates: CDispatchDetailDate[]
}
export async function postDispatchesExtraBus(
  data: PostDispatchesExtraBusData,
): Promise<void> {
  const url = `${API}/${data.academyID}/dispatches/separate`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      busId: data.busID,
      courseCode: data.courseCode,
      dispatchDates: data.dispatchDates.map(dd => dd.date.toString()),
      managerId: data.managerID,
      originalDispatchCode: data.dispatchCode,
    },
  }

  await axios(config)
}

export type GetDispatchSameCourseWeekdayData = {
  dispatchCode: string
  academyID: string
}

export async function getDispatchSameCourseWeekday(
  data: GetDispatchSameCourseWeekdayData,
): Promise<CDispatchWeekdayWithCode[]> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/same`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) =>
    CDispatchWeekdayWithCode.create(el),
  )
}

export async function getDispatchCirculationTime(
  academyID: string,
  dispatchCode: string,
): Promise<CDispatchCirculationTime[]> {
  const url = `${API}/${academyID}/dispatches/${dispatchCode}/circulation-time`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) =>
    CDispatchCirculationTime.create(el),
  )
}

export type GetDispatchesDetailOneDayData = {
  dispatchCode: string
  academyID: string
  searchDate: CDate
}
export async function getDispatchesDetailOneDay(
  data: GetDispatchesDetailOneDayData,
): Promise<CDispatchDetailDispatch[]> {
  const url = `${API}/${data.academyID}/dispatches/${data.dispatchCode}/detail/one-day`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      searchDate: data.searchDate.toString(),
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) =>
    CDispatchDetailDispatch.create(el),
  )
}

export type DeleteDispatchesData = {
  academyID: string
  dispatchTables: CBusDispatchTable[]
  date: string
  busId: number
  dispatchPeriodId: number
}
export async function deleteDispatches(data: DeleteDispatchesData) {
  const url = `${API}/${data.academyID}/dispatches`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
    data: {
      dispatchCodes: data.dispatchTables.map(dt => dt.dispatch.dispatchCode),
      date: data.date,
    },
    params: {
      busId: data.busId,
      dispatchPeriodId: data.dispatchPeriodId,
    },
  }
  const result = await axios(config)
  console.log(result)
  return true
}

export type UpdateStudentsTargetData = {
  dispatchCode: string
  courseCode: string
  managerId: number
}

type UpdateStudentsData = {
  academyID: string
  dispatchPeriodId: number
  body: {
    updateDate: string
    dispatchList: UpdateStudentsTargetData[]
  }
}
export async function updateStudents(data: UpdateStudentsData) {
  console.log(data)
  const url = `${API}/${data.academyID}/dispatches/update-all`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: data.body,
    params: {
      dispatchPeriodId: data.dispatchPeriodId,
    },
  }
  const result = await axios(config)
  console.log(result)
  return true
}
