import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {Optional} from '../../type/Common'
import {alignCenter, flexRow} from '../../style/CommonStyle'
import SVGImage from '../common/SVGImage'
import Checked from '../../asset/image/checked_brown.svg'
import DisabledChecked from '../../asset/image/checked_disabled.svg'
import {isNil, orElse} from '../../util/ValidationUtil'

export type CheckMultiSelectOption<T> = {
  idx: number
  exposure: ReactNode
  disabled: boolean
  value: T
}

export function toCheckMultiSelectOptions<T>(
  data: {value: T; disabled: boolean}[],
  getExposure: (v: T, disabled: boolean) => ReactNode,
): CheckMultiSelectOption<T>[] {
  return data.map(({value, disabled}, idx) => {
    return {
      idx: idx,
      exposure: getExposure(value, disabled),
      disabled: disabled,
      value: value,
    }
  })
}

type CheckMultiSelectProps<T> = {
  defaultIndices?: number[]
  options: CheckMultiSelectOption<T>[]
  readOnly: boolean
  required: boolean
  hasAll: boolean
  placeholder?: string
  onChange(values: Optional<T[]>): void
  onClick?(value: T): void
}
export type CheckMultiSelectRef<T> = {
  getValue(): T[]
  reset(): void
}

function CheckMultiSelectBase<T>(
  props: CheckMultiSelectProps<T>,
  ref: ForwardedRef<CheckMultiSelectRef<T>>,
): ReactElement {
  const [indices, setIndices] = useState<number[]>(
    orElse(props.defaultIndices, []),
  )
  const [options, setOptions] = useState<CheckMultiSelectOption<T>[]>(
    props.options,
  )

  const onClickBox = useCallback(
    (index: number) => {
      if (props.readOnly) {
        return
      }

      if (!isNil(props.onClick)) {
        props.onClick(options[index]?.value)
      }

      const selected = indices.some(idx => idx === index)

      if (selected) {
        setIndices(prev => prev.filter(idx => idx !== index))
        return
      }

      setIndices(prev => [...prev, index])
    },
    [setIndices, indices, props.readOnly, options],
  )

  const multiSelector = useMemo(() => {
    const allSelected = options.every(
      option => indices.includes(option.idx) || option.disabled,
    )
    const enabledIndices = options
      .filter(option => !option.disabled)
      .map(option => option.idx)

    return (
      <CheckAllButton
        allSelected={allSelected}
        onClick={() => {
          if (allSelected) {
            setIndices(props.defaultIndices)
          } else {
            setIndices([...orElse(props.defaultIndices, []), ...enabledIndices])
          }
        }}>
        {/* {allSelected ? '전체 해제' : '전체 선택'} */}
        전체 요일
      </CheckAllButton>
    )
  }, [setIndices, options, props.defaultIndices, indices])

  const OptionsContainer = useMemo(() => {
    return options.map((o: CheckMultiSelectOption<T>) => {
      const selected = indices.some(idx => idx === o.idx)

      return (
        <OptionContainer
          readOnly={props.readOnly || o.disabled}
          key={o.idx}
          onClick={() => {
            if (o.disabled) {
              return
            }

            onClickBox(o.idx)
          }}>
          <CheckBox readOnly={props.readOnly || o.disabled}>
            {selected ? (
              props.readOnly || o.disabled ? (
                <DisabledCheckImage
                  readOnly={props.readOnly || o.disabled}
                  source={DisabledChecked}
                />
              ) : (
                <CheckedImage
                  readOnly={props.readOnly || o.disabled}
                  source={Checked}
                />
              )
            ) : null}
          </CheckBox>
          {o.exposure}
        </OptionContainer>
      )
    })
  }, [indices, options, props.readOnly])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): T[] {
        return options.filter(o => indices.includes(o.idx)).map(o => o.value)
      },
      reset(): void {
        setIndices([])
      },
    }),
    [indices, options, setIndices],
  )

  useEffect(() => {
    if (isNil(props.onChange)) {
      return
    }

    props.onChange(
      options.filter(o => indices.includes(o.idx)).map(o => o.value),
    )
  }, [props.onChange, indices, options])

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  return (
    <Container>
      {multiSelector}
      {OptionsContainer}
    </Container>
  )
}

const CheckMultiSelect = forwardRef(CheckMultiSelectBase)
export default CheckMultiSelect

const Container = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 1rem;
  padding: 0.6rem 0;
`
const OptionContainer = styled.div<{readOnly: boolean}>`
  ${flexRow};
  ${alignCenter};
  column-gap: 0.6rem;
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
`
const CheckBox = styled.div<{readOnly: boolean}>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.4rem;
  border: ${props =>
    props.readOnly ? '0.1rem solid #f5f5f5' : '0.1rem solid #d9d9d9'};
  background: ${props => (props.readOnly ? '#F5F5F5' : '#ffffff')};
  ${flexRow};
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
`

const CheckedImage = styled(SVGImage)<{readOnly: boolean}>`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
`
const DisabledCheckImage = styled(CheckedImage)``

const CheckAllButton = styled.div<{allSelected: boolean}>`
  --main-color: #4b84dd;

  border: 1px solid var(--main-color);
  background-color: ${props =>
    props.allSelected === true ? 'var(--main-color)' : 'transparent'};
  cursor: pointer;
  color: ${props =>
    props.allSelected === true ? '#fff' : 'var(--main-color)'};
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.33px;
  padding: 0 8px;
  border-radius: 8px;
  position: absolute;
  top: 10px;
  right: 14px;
`
