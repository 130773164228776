import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import Footer from '../../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import DispatchSettingHeader from './DispatchSettingHeader'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import {
  getSemesterOperationTime,
  GetSemesterOperationTimeData,
  postSemesterOperationTime,
  PostSemesterOperationTimeData,
} from '../../../../../service/semester/Semester'
import {
  COperationClass,
  COperationClassTime,
} from '../../../../../model/Semester'
import ClassItem from './ClassItem'
import {WeekdayEnum} from '../../../../../enum/WeekdayEnum'
import {alertError} from '../../../../../util/ErrorUtil'
import ConfirmModal, {ConfirmModalRef} from '../../../../modal/ConfirmModal'
import useSecureRef from '../../../../../hook/useSecureRef'
import AlertModal, {AlertModalRef} from '../../../../modal/AlertModal'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'

type Props = {
  busID: number
  busName: string
  dispatchPeriod: CDispatchPeriodInfo
  onSubmit(): void
  onCancel(): void
}

export default function DispatchSetting(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchSetting.tsx] confirmModalRef',
  )
  const cancelModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchSetting.tsx] cancelModalRef',
  )
  const alertModalRef = useSecureRef<AlertModalRef>(
    '[DispatchSetting.tsx] alertModalRef',
  )

  const [operationClasses, setOperationClasses] = useState<COperationClass[]>(
    [],
  )

  const fetchClassTimes = useCallback(
    (data: GetSemesterOperationTimeData) => {
      getSemesterOperationTime(data)
        .then(ocs => setOperationClasses(ocs))
        .catch(error => {
          throw new Error(
            `getSemesterOperationTime() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setOperationClasses],
  )

  const showConfirmModal = useCallback(() => {
    confirmModalRef.current().show()
  }, [])

  const showCancelModal = useCallback(() => {
    cancelModalRef.current().show()
  }, [])

  const showAlertModal = useCallback(() => {
    alertModalRef.current().show()
  }, [])

  const onSelect = useCallback(
    (wd: WeekdayEnum, time: COperationClassTime, isStart: boolean) => {
      setOperationClasses(prev => {
        return prev.map(oc => {
          if (oc.weekday !== wd) {
            return oc
          }

          if (isStart) {
            return {
              ...oc,
              startTimes: oc.startTimes.map(st => {
                if (st.time !== time.time) {
                  return st
                }

                return {
                  ...st,
                  isChecked: !st.isChecked,
                }
              }),
            }
          }

          return {
            ...oc,
            endTimes: oc.endTimes.map(et => {
              if (et.time !== time.time) {
                return et
              }

              return {
                ...et,
                isChecked: !et.isChecked,
              }
            }),
          }
        })
      })
    },
    [setOperationClasses],
  )

  const getSelectedAll = useCallback(
    (oc: COperationClass, isSelectedAll: boolean) => {
      return {
        ...oc,
        isChecked: !isSelectedAll,
        startTimes: oc.startTimes.map(st => {
          // if (!st.isEnable) {
          //   return st
          // }

          return {
            ...st,
            isChecked: !isSelectedAll,
          }
        }),
        endTimes: oc.endTimes.map(et => {
          // if (!et.isEnable) {
          //   return et
          // }

          return {
            ...et,
            isChecked: !isSelectedAll,
          }
        }),
      }
    },
    [],
  )

  const onSelectAll = useCallback(
    (wd: WeekdayEnum) => {
      setOperationClasses(prev => {
        return prev.map(oc => {
          if (oc.weekday !== wd) {
            return oc
          }

          // const enableStartTimes = oc.startTimes.filter(st => st.isEnable)
          // const checkedStartTimes = oc.startTimes.filter(st => st.isChecked)
          // const enableEndTimes = oc.endTimes.filter(et => et.isEnable)
          // const checkedEndTimes = oc.endTimes.filter(et => et.isChecked)
          //
          // const isSelectedAll =
          //   enableStartTimes.length === checkedStartTimes.length &&
          //   enableEndTimes.length === checkedEndTimes.length
          const isSelectedAll =
            oc.startTimes.length ===
            oc.startTimes.filter(st => st.isChecked).length

          return getSelectedAll(oc, isSelectedAll)
        })
      })
    },
    [setOperationClasses, getSelectedAll],
  )

  const onSubmit = useCallback(() => {
    const data: PostSemesterOperationTimeData = {
      academyID: academyID,
      busID: props.busID,
      semesterID: props.dispatchPeriod.semesterID,
      dispatchPeriodID: props.dispatchPeriod.id,
      invisible: operationClasses.map(oc => {
        const invisibleStartTimes = oc.startTimes.filter(st => !st.isChecked)
        const invisibleEndTimes = oc.endTimes.filter(et => !et.isChecked)
        const invisibleTimes = [
          ...invisibleStartTimes,
          ...invisibleEndTimes,
        ].map(t => t.time)

        return {
          weekday: oc.weekday,
          times: invisibleTimes,
        }
      }),
    }

    // showAlertModal()

    postSemesterOperationTime(data)
      .then(() => {
        props.onSubmit()
        props.onCancel()
      })
      .catch(error => {
        alertError(
          error,
          `postSemesterOperationTime() failed. data: ${JSON.stringify(
            data,
          )}, error: ${error} `,
        )
      })
  }, [
    academyID,
    operationClasses,
    props.busID,
    props.dispatchPeriod,
    props.onSubmit,
    props.onCancel,
    showAlertModal,
  ])

  useEffect(() => {
    const data: GetSemesterOperationTimeData = {
      academyID: academyID,
      busID: props.busID,
      dispatchPeriodID: props.dispatchPeriod.id,
      semesterID: props.dispatchPeriod.semesterID,
    }

    fetchClassTimes(data)
  }, [academyID, props.busID, props.dispatchPeriod])

  return (
    <Container>
      <AlertModal
        ref={alertModalRef.ref}
        header={'운행 설정 변경 불가'}
        content={
          '해당 요일과 시간에 배차가 등록되어 있어\n 수정이 불가능합니다.'
        }
        onSubmit={() => {}}
      />
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'운행 설정 등록 확인'}
        content={'운행 설정을 등록하시겠습니까?'}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        ref={cancelModalRef.ref}
        header={'운행 설정 취소 확인'}
        content={'운행 설정을 취소하시겠습니까?'}
        onSubmit={props.onCancel}
      />

      <DispatchSettingHeader busName={props.busName} />
      <PeriodWrapper>
        <PeriodContentContainer>
          <PeriodHeaderContainer>
            <PeriodHeaderText>차량 운행 요일 및 수업 시간</PeriodHeaderText>
          </PeriodHeaderContainer>
          <ListContainer>
            {operationClasses.map((oc, idx) => {
              return (
                <ClassItem
                  operationClass={oc}
                  onSelect={onSelect}
                  onSelectAll={onSelectAll}
                  key={`${idx}_${oc.weekday.exposure}`}
                />
              )
            })}
          </ListContainer>
        </PeriodContentContainer>
      </PeriodWrapper>
      <Footer
        submitText={'등록'}
        onCancel={showCancelModal}
        onSubmit={showConfirmModal}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 36.7%;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const PeriodWrapper = styled.div`
  flex: 1;
  padding: 1.6rem;
  background: #f5f5f5;
  overflow: auto;
`

const PeriodContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  row-gap: 1.6rem;
  overflow: auto;
`

const PeriodHeaderContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const PeriodHeaderText = styled.h5`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  padding-left: 0.4rem;
`

const ListContainer = styled.div`
  ${flexColumn};
  flex: 1;
  row-gap: 0.8rem;
  overflow: auto;
`
