import styled, {css} from 'styled-components'
import {generateMedia} from 'styled-media-query'
import RadioOff from '../asset/image/radio_off.svg'
import RadioOn from '../asset/image/radio_on.svg'

export const media = generateMedia({
  phone: '500px',
  mobile: '830px',
  pad: '1110px',
})

export const width = window.innerWidth

export const flexRow = css`
  display: flex;
`

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`

export const justifyCenter = css`
  justify-content: center;
`

export const alignCenter = css`
  align-items: center;
`

export const color = {
  black: '#000000',
  blackTransparent: '#00000050',
  grey1: '#f2f4f6',
  grey2: '#E0E0E0',
  grey3: '#BDBDBD',
  grey4: '#333333',
  blue1: '#174490',
  blue2: '#3182f2',
  blue3: '#2272eb',

  red1: '#ffe8e8',
  red2: '#FF4D4F',
  red3: 'rgb(255, 99, 132)',
  green1: '#079f00',
  green2: 'green',
  orange: 'orange',
  white: '#FFFFFF',
}

export const buttonStyle = {
  blackWithFullWidth: {
    backgroundColor: color.black,
    color: color.white,
  },
  white: {
    backgroundColor: color.white,
    color: color.black,
    border: '0.1rem solid black',
  },
  black: {
    backgroundColor: color.black,
    color: color.white,
  },
  redWithFullWidth: {
    backgroundColor: color.red2,
    color: color.white,
  },
  red: {
    backgroundColor: color.red2,
    color: color.white,
  },
  gray2: {
    backgroundColor: color.grey2,
    color: color.white,
  },
  gray3: {
    backgroundColor: color.grey3,
    color: color.white,
  },
  gray4: {
    backgroundColor: color.grey4,
    color: color.white,
  },
  green: {
    backgroundColor: color.green2,
    color: color.white,
  },
}

export const SearchButton = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  background-color: #ffcd00;
  width: 7.2rem;
  min-height: 4rem;
  border-radius: 3rem;
  font-weight: 700;
  font-size: 1.4rem;
  margin: 1.6rem auto 2.8rem;
  cursor: pointer;
`

export const CheckboxLabel = styled.label`
  margin: 0px 1.2rem 0px 0.4rem;
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: -0.03rem;

  :hover {
    cursor: pointer;
  }
`

export const RadioButton = styled.input`
  appearance: none;
  width: 1.8rem;
  height: 1.8rem;
  background: url(${RadioOff}) center center no-repeat;
  background-size: cover;
  border: none;
  margin: 0;

  :hover {
    cursor: pointer;
  }

  :checked {
    background: url(${RadioOn}) center center no-repeat;
  }

  :checked + ${CheckboxLabel} {
    color: black;
  }
`

type IconProps = {
  img: string
  width?: string | undefined
  height?: string | undefined
}

export const Icon = styled.div<IconProps>`
  background: url(${props => props.img}) center center no-repeat;
  display: block;
  background-size: cover;
  width: ${({width}) => (width ? width : '2rem')};
  height: ${({height}) => (height ? height : '2rem')};
`

export const Button = styled.button`
  width: 7.2rem;
  height: 3.4rem;
  border: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  ${flexRow};
  justify-content: center;
  border-radius: 3rem;
  padding: 0.8rem 2.4rem;
  line-height: 1.8rem;
`

export const ModalButton = styled.button`
  width: 9rem;
  height: 4rem;
  border: none;
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  ${flexRow};
  justify-content: center;
  border-radius: 2rem;
  line-height: 4rem;
`

export const PrimaryText = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 700;
  color: #997d00;
`

export const WarningText = styled(PrimaryText)`
  color: #ff7500;
  display: inline-block;
`
