export class DriverSearchType {
  static readonly ALL = new DriverSearchType('ALL', '전체')
  static readonly NAME = new DriverSearchType('NAME', '기사명')
  static readonly PHONE = new DriverSearchType('PHONE', '연락처')
  static readonly ID = new DriverSearchType('ID', '아이디')
  static readonly ALL_TYPES = [
    DriverSearchType.NAME,
    DriverSearchType.PHONE,
    DriverSearchType.ID,
  ]

  private constructor(
    readonly value: string,
    readonly exposure: string,
  ) {}

  static parse(value: string): DriverSearchType {
    switch (value) {
      case DriverSearchType.ALL.value:
        return DriverSearchType.ALL
      case DriverSearchType.NAME.value:
        return DriverSearchType.NAME
      case DriverSearchType.PHONE.value:
        return DriverSearchType.PHONE
      case DriverSearchType.ID.value:
        return DriverSearchType.ID
      default:
        throw new Error(`invalid driver search type. (value: ${value})`)
    }
  }
}
