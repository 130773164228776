import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'

import {CCourse} from '../../../../../../../model/Course'
import {Optional} from '../../../../../../../type/Common'
import {
  getCourseRoute,
  getSearchCourseDetail,
} from '../../../../../../../service/station/Station'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {isEmptyArray, isNil} from '../../../../../../../util/ValidationUtil'

// image
import DepartureIcon from '../../../../../../../asset/image/departure_station_blue.svg'
import ArrivalIcon from '../../../../../../../asset/image/arrival_station_black.svg'
import SelectedStationNoneShadow from '../../../../../../../asset/image/selected_station_none_shadow.svg'

// kakao
import {CustomOverlayMap, Map, Polyline} from 'react-kakao-maps-sdk'
import {CCourseStations} from '../../../../../../../model/Station'
import {CDispatchPeriodInfo} from '../../../../../../../model/DispatchPeriod'
import {CDate} from '../../../../../../../model/Date'

type CenterProps = {
  lat: number
  lng: number
}

type Props = {
  selectedCourse: Optional<CCourse>
  dispatchPeriod: CDispatchPeriodInfo
}

export default function CourseSelectMap(props: Props): ReactElement {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [courseList, setCourseList] = useState<CCourseStations[]>([])
  const [courseRouteData, setCourseRouteData] = useState<any>(null)
  const [center, setCenter] = useState<Optional<CenterProps>>(null)

  const getCourseDetail = useCallback(() => {
    getSearchCourseDetail(
      selectedAcademyID,
      String(props.selectedCourse.id),
      props.dispatchPeriod.id,
      Number(CDate.now().toYear()),
    )
      .then(cl => setCourseList(cl.course.stations))
      .catch(error => {
        throw new Error(
          `failed to get course detail. (data: ${JSON.stringify(
            selectedAcademyID,
          )}, error: ${error})`,
        )
      })
  }, [props.selectedCourse, selectedAcademyID])

  const getCoordinate = useCallback(() => {
    if (!isEmptyArray(courseList) && !isNil(selectedAcademyID)) {
      const wayPoints = courseList.slice(1, courseList.length - 1)
      const wayPointsData = wayPoints.map(w => {
        return {
          lat: w.lat,
          lng: w.lng,
          stationId: w.id,
          stationName: w.name,
        }
      })

      const data = {
        academyID: selectedAcademyID,
        stations: {
          origin: {
            lat: courseList[0].lat,
            lng: courseList[0].lng,
            stationId: courseList[0].id,
            stationName: courseList[0].name,
          },
          destination: {
            lat: courseList[courseList.length - 1].lat,
            lng: courseList[courseList.length - 1].lng,
            stationId: courseList[courseList.length - 1].id,
            stationName: courseList[courseList.length - 1].name,
          },
          waypoints: courseList.length < 3 ? [] : wayPointsData,
        },
      }
      getCourseRoute(data).then(c => setCourseRouteData(c))
    }
  }, [courseList, selectedAcademyID])

  // 모든 좌표 합치기
  const combinedVertexes = courseRouteData?.sections?.flatMap(
    (s: any) => s?.roads?.flatMap((r: {vertexes: any}) => r.vertexes),
  )

  const withoutFirstAndLast = combinedVertexes?.slice(
    2,
    combinedVertexes?.length - 2,
  )

  const vertexesAsObjects = withoutFirstAndLast?.reduce(
    (acc: {lat: any; lng: any}[], curr: any, i: number) => {
      if (i % 2 === 0) {
        acc?.push({lng: curr, lat: withoutFirstAndLast[i + 1]})
      }
      return acc
    },
    [],
  )

  useEffect(() => {
    if (isNil(selectedAcademyID) || isNil(props.selectedCourse)) {
      return
    }
    getCourseDetail()
  }, [selectedAcademyID, props.selectedCourse])

  useEffect(() => {
    if (isEmptyArray(courseList)) {
      return
    }
    getCoordinate()
  }, [courseList])

  useEffect(() => {
    if (isEmptyArray(courseList)) {
      return
    }
    setCenter({lat: courseList[0].lat, lng: courseList[0].lng})
  }, [courseList])

  return (
    !isNil(center) &&
    !isEmptyArray(courseList) && (
      <Container>
        <Map
          className="map"
          center={center}
          style={{width: '100%', height: '100%'}}
          level={6}>
          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={7}
              strokeColor="#332a00"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}
          {!isNil(courseRouteData) && (
            <Polyline
              path={[vertexesAsObjects]}
              strokeWeight={5}
              strokeColor="#FFD100"
              strokeOpacity={1}
              strokeStyle="solid"
            />
          )}
          {courseList.map((coord, i) => (
            <CustomOverlayMap key={`marker-${i}`} position={coord}>
              <StationIdxWrapper>
                <img
                  src={
                    i === 0
                      ? DepartureIcon
                      : i === courseList.length - 1
                        ? ArrivalIcon
                        : SelectedStationNoneShadow
                  }
                  style={{
                    width: '4rem',
                    height: '4re,',
                  }}
                  alt=""></img>
                <StationIdx>
                  {i === 0 ? '' : i === courseList.length - 1 ? '' : i}
                </StationIdx>
              </StationIdxWrapper>
            </CustomOverlayMap>
          ))}
        </Map>
      </Container>
    )
  )
}

const Container = styled.div`
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: 16px;
  height: calc(100vh - 27rem);
  flex: 1;
`

const StationIdxWrapper = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
`

const StationIdx = styled.div`
  position: absolute;
  top: -2rem;
  left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 500%;
  color: #665300;
`
