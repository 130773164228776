import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router'
import {color, flexColumn} from '../../../style/CommonStyle'
import {isNil} from '../../../util/ValidationUtil'
import TableList from '../../common/TableList'
import Pagination from '../../common/Pagination'
import {CBus} from '../../../model/Bus'
import {BusDetailLocationEnum} from '../../../enum/BusDetailLocationEnum'

const TITLE_MAP = {
  name: '호차',
  regNum: '차량번호',
  driver: '운행 기사',
  phone: '기사 연락처',
  validSeat: '가용 좌석',
  transportCompany: '운수사',
}

type DispatchListProps = {
  PER_PAGE: number
  dispatchState: CBus[]
  totalElements: number
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export type DispatchListRef = {
  getDispatchList(): void
}

function DispatchListBase(
  props: DispatchListProps,
  _: ForwardedRef<DispatchListRef>,
) {
  const navigate = useNavigate()

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    return props.dispatchState.map(m => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.name, <div>{m.name}</div>)
      node.set(TITLE_MAP.regNum, <div>{m.regNum}</div>)
      node.set(
        TITLE_MAP.driver,
        <div>{isNil(m.drivers) ? '-' : `${m.drivers[0].name}`}</div>,
      )
      node.set(
        TITLE_MAP.phone,
        <div>{isNil(m.drivers) ? '-' : `${m.drivers[0].phone}`}</div>,
      )
      node.set(TITLE_MAP.validSeat, <div>{m.totalSeat}</div>)
      node.set(TITLE_MAP.transportCompany, <div>{m.transportCompany}</div>)
      return node
    })
  }, [props.dispatchState])

  const onClickRow = useCallback(
    (idx: number) => {
      if (isNil(props.dispatchState)) {
        return
      }

      const busID = props.dispatchState[idx].id

      navigate(`/bus/${busID}`, {
        state: {from: BusDetailLocationEnum.BUS.value, dispatchPeriodID: null},
      })
    },
    [props.dispatchState],
  )

  return (
    <Container busCnt={props.totalElements}>
      {props.dispatchState.length > 0 && (
        <Title>{`호차 리스트 (${props.totalElements})`}</Title>
      )}
      <TableList
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        onClickRow={onClickRow}
        tableHeaderStyle={{background: '#f5f5f5'}}
        placeholder={'등록된 호차가 없습니다.'}
      />
      {props.dispatchState.length > 0 && (
        <Pagination
          num={props.totalElements}
          perPage={props.PER_PAGE}
          pageExposeCount={5}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
      )}
    </Container>
  )
}

const DispatchList = forwardRef(DispatchListBase)
export default DispatchList

type ContainerProps = {
  busCnt: number
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${color.white};
  min-height: 6.8rem;
  max-height: calc(100vh - 20rem);
  ${flexColumn};
  padding: ${props => (props.busCnt > 0 ? '1.2rem' : '1.2rem 1.2rem 3.7rem')};
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 0.8rem;
  margin-left: 1.2rem;
`
