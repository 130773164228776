import React, {ReactElement, useCallback, useState} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../../../style/CommonStyle'
import DispatchDetailInfoItem from './DispatchDetailInfoItem'
import {CDispatchDetailWithCode} from '../../../../../../model/Dispatch'
import DispatchDetailInfoHeader from './DispatchDetailInfoHeader'
import {OccupancyTypeEnum} from '../../../../../../enum/OccupancyTypeEnum'

type Props = {
  dispatchDetails: CDispatchDetailWithCode[]
}

export default function DispatchDetailInfo(props: Props): ReactElement {
  const [selectedDispatchDetail, setSelectedDispatchDetail] =
    useState<CDispatchDetailWithCode>(props.dispatchDetails[0])

  const onChangeType = useCallback(
    (dd: CDispatchDetailWithCode) => {
      setSelectedDispatchDetail(dd)
    },
    [setSelectedDispatchDetail],
  )

  return (
    <Container>
      <DispatchDetailInfoHeader
        dispatchDetails={props.dispatchDetails}
        selectedDispatchDetail={selectedDispatchDetail}
        onChangeType={onChangeType}
      />
      <DispatchDetailListContainer type={selectedDispatchDetail.type}>
        {selectedDispatchDetail.dispatches.map((dispatch, idx) => {
          return (
            <DispatchDetailInfoItem
              key={idx}
              isFirstItem={idx === 0}
              isLastItem={idx === selectedDispatchDetail.dispatches.length - 1}
              dispatch={dispatch}
              type={selectedDispatchDetail.type}
              index={idx}
            />
          )
        })}
      </DispatchDetailListContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  overflow: auto;
  border-radius: 0.8rem;
`

const DispatchDetailListContainer = styled.div<{type: OccupancyTypeEnum}>`
  ${flexColumn};
  flex: 1;
  background: #ffffff;
`
