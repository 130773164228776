import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {Button, color, flexColumn, flexRow} from '../../../style/CommonStyle'
import CloseButton from '../../../asset/image/close_button.svg'
import SVGImage from '../../common/SVGImage'
import {Optional} from '../../../type/Common'
import ModalPagination from '../modalPagination/ModalPagination'
import {
  GetNotAssignedDriverParmas,
  notAssignedDrivers,
} from '../../../service/driver/Driver'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {CDriver} from '../../../model/Driver'
import DownArrow from '../../../asset/image/down_arrow.svg'

const PER_PAGE = 10

type ModalContainerRef = {
  show(): void
  hide(): void
}

type PopUpProps = {
  setDriverID: Dispatch<SetStateAction<Optional<number>>> // required prop
  onClose?: () => void
  confirmButton?: () => void
  driver: string
  driverId: number
  originalDriver: string
  error: Optional<string>
  driverModifyStatus: boolean
  setDriverModifyStatus: React.Dispatch<React.SetStateAction<boolean>>
  driverPopUp: boolean
  setDriverPopUp: React.Dispatch<React.SetStateAction<boolean>>
  keyword: string
  setKeyword: React.Dispatch<React.SetStateAction<string>>
}

function ChangeDriver(props: PopUpProps) {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [driverState, setDriverState] = useState<Optional<CDriver[]>>(null)
  const [driverID, setDriverID] = useState<Optional<number>>(null)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [selectedDriver, setSelectedDriver] = useState<CDriver[]>([])

  const getNotAssignedDriverList = useCallback(async () => {
    if (isNil(selectedAcademyID)) {
      return
    }

    try {
      const data: GetNotAssignedDriverParmas = {
        academyID: selectedAcademyID,
        page: currentPage,
        searchValue: props.keyword,
        size: PER_PAGE,
        driverId: props.driverId,
      }

      const response = await notAssignedDrivers(data)
      setDriverState(response.drivers)
      setTotalElements(response.paging.totalElements)
    } catch (error) {
      console.error('Failed to fetch drivers:', error)
      // Handle error appropriately - maybe show toast or error message
    }
  }, [selectedAcademyID, currentPage, props.keyword, props.driverId])

  useEffect(() => {
    getNotAssignedDriverList()
  }, [getNotAssignedDriverList])

  useEffect(() => {
    props.setDriverID(driverID)
  }, [driverID, props.setDriverID])

  useEffect(() => {
    if (!isNil(driverState)) {
      const filteredDriver = driverState.filter(el => el.id === driverID)
      setSelectedDriver(filteredDriver)
    }
  }, [driverState, driverID])

  useEffect(() => {
    if (props.driverModifyStatus) {
      setSelectedDriver([])
      props.setDriverModifyStatus(false)
    }
  }, [props.driverModifyStatus])

  const isDriverSelected = selectedDriver.length > 0

  return (
    <Container>
      <ImgWrapper onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </ImgWrapper>
      <Header>운행 기사 수정</Header>
      <Noti>
        • <OrangeText>*</OrangeText> 는 필수 입력 항목입니다.
      </Noti>
      <NameWrapper>
        <OriginalInputWrapper>
          <SubTitle>기존 기사 정보</SubTitle>
          <OriginalValue>{props.driver}</OriginalValue>
        </OriginalInputWrapper>
        <Wrapper>
          <ChangeInputWrapper error={props.error}>
            <ChangeSubTitle>
              변경할 기사 선택<OrangeText>*</OrangeText>
            </ChangeSubTitle>
            <ChangeDriverWrapper onClick={() => props.setDriverPopUp(true)}>
              <Input
                error={props.error}
                value={
                  !isEmptyArray(selectedDriver)
                    ? `${selectedDriver[0]?.name} | ${selectedDriver[0]?.phone}`
                    : '변경할 기사를 선택해 주세요.'
                }
                readOnly
              />
              <ArrowImg source={DownArrow} />
            </ChangeDriverWrapper>
            {props.driverPopUp && (
              <ModalPagination
                width={'24rem'}
                onClose={() => props.setDriverPopUp(false)}
                data={driverState}
                PER_PAGE={PER_PAGE}
                totalElements={totalElements}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                keyword={props.keyword}
                setKeyword={props.setKeyword}
                setDriverID={setDriverID}
                getNotAssignedDriverList={getNotAssignedDriverList}
              />
            )}
          </ChangeInputWrapper>
        </Wrapper>
      </NameWrapper>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>취소</CancelButton>
        <RegisterButton
          disabled={!isDriverSelected}
          onClick={props.confirmButton}>
          확인
        </RegisterButton>
      </ButtonWrapper>
    </Container>
  )
}

export default ChangeDriver

const Container = styled.div`
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 1.6rem;
  position: relative;
`

const ImgWrapper = styled.div``

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1.6rem 0;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
`

const Noti = styled.div`
  height: 3rem;
  background: #ebebeb;
  padding: 0.8rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: left;
  color: #999;
`

const OrangeText = styled.span`
  color: ${color.orange};
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
`

const NameWrapper = styled.div`
  ${flexColumn};
  background: #f5f5f5;
  padding: 2rem;
  row-gap: 1.2rem;
`

const OriginalInputWrapper = styled.div`
  ${flexRow};
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  padding: 0.5rem 1.2rem;
`

const Wrapper = styled.div`
  ${flexColumn};
  text-align: left;
  position: relative;
`
type ErrorProps = {
  error: Optional<string>
}

const ChangeInputWrapper = styled(OriginalInputWrapper)<ErrorProps>`
  position: relative;
  border-color: ${props => (props.error === null ? '#d9d9d9' : '#ED766E')};
  background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};

  > input {
    background: ${props => (props.error === null ? '#fff' : '#FCE6E4')};
    color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
  }

  :focus-within {
    background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
    border-color: ${props => (props.error === null ? '#ffe880' : '#ED766E')};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);

    > input {
      background: ${props => (props.error === null ? '#fffbe5' : '#FCE6E4')};
      color: ${props => (props.error === null ? '#332A00' : '#DA291C')};
    }
  }
`

const Text = styled.div`
  color: #e74d41;
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 300;
  margin-top: 0.6rem;
`

const SubTitle = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
  min-width: 12.8rem;
  text-align: left;
  border-right: 0.1rem solid #d9d9d9;
  margin-right: 0.6rem;
`

const OriginalValue = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: #808080;
`

const ChangeSubTitle = styled(SubTitle)`
  color: #332a00;
`

type InputProps = {
  error: Optional<string>
}
const Input = styled.input<InputProps>`
  border: none;
  font-size: 1.3rem;
  width: 100%;
  max-width: 19.6rem;
  background: ${props => (props.error ? '#Fce6e4' : '#fff')};

  :focus {
    background: ${props => (props.error ? '#Fce6e4' : '#fffbe5')};
  }

  :hover {
    cursor: pointer;
  }
`
const ButtonWrapper = styled.div`
  ${flexRow};
  column-gap: 0.6rem;
  justify-content: center;
  padding: 1.2rem;
`

const CancelButton = styled(Button)``

type ButtonProps = {
  disabled: boolean
}

const RegisterButton = styled(Button)<ButtonProps>`
  background: ${props => (props.disabled ? '#FFF1B3' : '#ffcd00')};
  color: ${props => (props.disabled ? '#cccccc' : '#000')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ArrowImg = styled(SVGImage)`
  position: absolute;
  right: 1.2rem;
  top: 0.8rem;
  width: 1.4rem;
  height: 1.4rem;
`

const ChangeDriverWrapper = styled.div`
  ${flexRow}
  width:100%;
`
