import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {isNil} from '../../util/ValidationUtil'
import {CStudent} from '../../model/Dispatch'
import {API} from '../../Env'
import {CStudentNfc} from '../../model/Student'
import {Optional} from '../../type/Common'

export async function getStudents(academyID: string) {
  const url = `${API}/${academyID}/students`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CStudent.create(el))
}

export async function getStudentsWithSearch(
  academyID: string,
  keyword: string,
) {
  const url = `${API}/${academyID}/students/search?keyword=${keyword}`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CStudent.create(el))
}

export type GetStudentData = {
  academyID: string
  page?: number
  searchType?: string
  searchValue?: Optional<string>
  size?: number
}

export async function getStudentsNfc(data: GetStudentData) {
  const url = `${API}/${data.academyID}/students/with-nfc`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      page: data.page,
      searchType: data.searchType,
      searchValue: data.searchValue,
      size: data.size,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data?.data)) {
    return null
  }

  return axiosResponse.data.data
}
