import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import SearchInput, {SearchInputRef} from '../../input/SearchInput'
import FilterIcon from '../../../asset/image/filter_icon.svg'
import styled from 'styled-components'
import {alignCenter, color, flexRow} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {CStudent} from '../../../model/Dispatch'
import CheckedIcon from '../../../asset/image/yellow_checked.svg'
import useSecureRef from '../../../hook/useSecureRef'

const ALL = '전체'

type StudentListSearchBarProps = {
  students: CStudent[]
  onChangeCondition(t: string, g: string): void
}
export type StudentListSearchBarRef = {
  reset(): void
}

function StudentListSearchBarBase(
  props: StudentListSearchBarProps,
  ref: ForwardedRef<StudentListSearchBarRef>,
): ReactElement {
  const inputRef = useSecureRef<SearchInputRef>(
    '[StudentListSearchBar.tsx] inputRef',
  )

  const [selectedGrade, setSelectedGrade] = useState<string>(ALL)
  const [visibleSelect, setVisibleSelect] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>('')

  const grades = useMemo(() => {
    return props.students.reduce((acc, student) => {
      if (!acc.includes(student.nickName) && student.nickName !== '-') {
        acc.push(student.nickName)
      }
      return acc
    }, [])
  }, [props.students])

  const gradesWithAll = useMemo(() => {
    return [ALL, ...grades]
  }, [grades])

  const onClickGrade = useCallback(
    (g: string) => {
      const text = inputRef.current().getValue()

      setSelectedGrade(g)
      props.onChangeCondition(text, g)
    },
    [props.onChangeCondition],
  )

  const onChangeText = useCallback(
    (t: string) => {
      setTextValue(t)
      // props.onChangeCondition(t, selectedGrade)
    },
    [selectedGrade, props.onChangeCondition, setTextValue],
  )

  const gradeSelect = useMemo(() => {
    if (!visibleSelect) {
      return null
    }
    return (
      <SelectContainer onMouseLeave={() => setVisibleSelect(false)}>
        {gradesWithAll.map((g, idx) => {
          return (
            <ItemContainer onClick={() => onClickGrade(g)} key={`${g}_${idx}`}>
              <SelectItem key={`${g}_${idx}`}>{g}</SelectItem>
              <SelectedIcon
                visible={selectedGrade === g}
                source={CheckedIcon}
              />
            </ItemContainer>
          )
        })}
      </SelectContainer>
    )
  }, [gradesWithAll, selectedGrade, visibleSelect])

  useImperativeHandle(
    ref,
    () => ({
      reset(): void {
        setSelectedGrade(ALL)
        inputRef.current().setValue('')
      },
    }),
    [setSelectedGrade],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      props.onChangeCondition(textValue, selectedGrade)
    }
  }
  return (
    <SearchWrapper>
      <SearchInput
        onChangeText={onChangeText}
        ref={inputRef.ref}
        placeHolder={'학생 이름 혹은 학부모 전화번호 뒷자리 4자리'}
        onKeyDown={handleKeyDown}
      />
      <GradeFilter onMouseEnter={() => setVisibleSelect(true)}>
        <ContentContainer>
          <FilterImg source={FilterIcon} />
          {selectedGrade}
        </ContentContainer>
      </GradeFilter>
      {gradeSelect}
    </SearchWrapper>
  )
}

const StudentListSearchBar = forwardRef(StudentListSearchBarBase)
export default StudentListSearchBar

const SelectContainer = styled.div`
  position: absolute;
  width: 12rem;
  border-radius: 0.8rem;
  padding: 1rem 0;
  right: 2.4rem;
  top: 2.3rem;
  background-color: ${color.white};
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
`

const ItemContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  position: relative;
  cursor: pointer;
`

const SelectItem = styled.div`
  ${flexRow};
  ${alignCenter};
  padding-left: 2rem;
  width: 100%;
  height: 3.5rem;
  font-size: 1.2rem;

  &:hover {
    background-color: ${color.grey1};
  }
`

const SelectedIcon = styled(SVGImage)<{visible: boolean}>`
  width: 1.2rem;
  height: 1.2rem;
  left: 0.5rem;
  position: absolute;
  display: ${props => (props.visible ? 'block' : 'none')};
`

const SearchWrapper = styled.div`
  position: relative;
  padding: 2rem 2.4rem;
  background: #f7f7f7;
  border-radius: 1.2rem 1.2rem 0 0;
  ${flexRow};
`

const GradeFilter = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  width: 9.3rem;
  height: 3.4rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  background: #a3a3a3;
  cursor: pointer;
`
const FilterImg = styled(SVGImage)`
  width: 1.7rem;
  height: 1.7rem;
  left: -2rem;
`

const ContentContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${color.white};
`
