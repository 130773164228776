import {API} from '../../Env'
import {AxiosEnum} from '../../enum/AxiosEnum'
import axios from 'axios'
import {isNil} from '../../util/ValidationUtil'
import {CDate} from '../../model/Date'
import {WeekdayEnum} from '../../enum/WeekdayEnum'
import {
  CDispatchPeriod,
  CDispatchPeriodBusVector,
  CDispatchPeriodExist,
} from '../../model/DispatchPeriod'
import {SortEnum} from '../../enum/SortEnum'
import {CBus} from '../../model/Bus'
import {Optional} from '../../type/Common'

export async function getDispatchPeriodExtractYears(
  academyID: string,
): Promise<number[]> {
  const url = `${API}/${academyID}/dispatch-period/extract-years`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data
}

export type GetDispatchPeriodListData = {
  academyID: string
  dispatchPeriodYear: number
}

export async function getDispatchPeriods(
  data: GetDispatchPeriodListData,
): Promise<CDispatchPeriod[]> {
  const url = `${API}/${data.academyID}/dispatch-period`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      dispatchPeriodYear: data.dispatchPeriodYear,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) => CDispatchPeriod.create(el))
}

export type PostDispatchPeriodExistsData = {
  startDate: CDate
  endDate: CDate
  dispatchPeriodID: Optional<number>
}

export async function getDispatchPeriodExists(
  academyID: string,
  data: PostDispatchPeriodExistsData,
): Promise<CDispatchPeriodExist[]> {
  const url = `${API}/${academyID}/dispatch-period/exists`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      startDate: data.startDate.toString(),
      endDate: data.endDate.toString(),
      dispatchPeriodId: data.dispatchPeriodID,
    },
  }

  const axiosResponse = await axios(config)

  if (isNil(axiosResponse.data)) {
    return null
  }

  return axiosResponse.data.data.map((el: any) =>
    CDispatchPeriodExist.create(el),
  )
}

export type PostDispatchPeriodData = {
  name: string
  semesterID: number
  startDate: CDate
  endDate: CDate
  inboundBeforeMinute: number
  outboundAfterMinute: number
  weekdays: WeekdayEnum[]
}

export async function postDispatchPeriod(
  academyID: string,
  data: PostDispatchPeriodData,
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      name: data.name,
      semesterId: data.semesterID,
      startDate: data.startDate.toString(),
      endDate: data.endDate.toString(),
      inboundBeforeMinute: data.inboundBeforeMinute,
      outboundAfterMinute: data.outboundAfterMinute,
      weekdays: data.weekdays.map(wd => wd.value),
    },
  }

  await axios(config)
}

export type CopyDispatchPeriodData = {
  name: string
  semesterID: number
  dispatchPeriodID: number
  academyID: string
}

export async function copyDispatchPeriod(
  data: CopyDispatchPeriodData,
): Promise<void> {
  const url = `${API}/${data.academyID}/dispatch-period/${data.dispatchPeriodID}/copy`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      name: data.name,
      semesterId: data.semesterID,
    },
  }

  await axios(config)
}

export type PutDispatchPeriodData = {
  name: string
  startDate: CDate
  endDate: CDate
  inboundBeforeMinute: number
  outboundAfterMinute: number
  weekdays: WeekdayEnum[]
  semesterID: number
}

export async function putDispatchPeriod(
  academyID: string,
  dispatchPeriodID: number,
  data: PutDispatchPeriodData,
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}`

  const config = {
    method: AxiosEnum.PUT.value,
    url: url,
    data: {
      name: data.name,
      startDate: data.startDate.toString(),
      endDate: data.endDate.toString(),
      inboundBeforeMinute: data.inboundBeforeMinute,
      outboundAfterMinute: data.outboundAfterMinute,
      weekdays: data.weekdays.map(wd => wd.value),
      semesterId: data.semesterID,
    },
  }

  await axios(config)
}

export async function deleteDispatchPeriod(
  academyID: string,
  dispatchPeriodID: number,
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
  }
  await axios(config)
}

export async function getDispatchPeriodBuses(
  academyID: string,
  dispatchPeriodID: number,
  sort: SortEnum,
): Promise<CDispatchPeriodBusVector[]> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}/buses`

  const config = {
    method: AxiosEnum.GET.value,
    url: url,
    params: {
      sort: sort.value,
    },
  }
  const axiosResponse = await axios(config)

  return axiosResponse.data.data.map((res: any) =>
    CDispatchPeriodBusVector.create(res),
  )
}

export async function postDispatchPeriodBuses(
  academyID: string,
  dispatchPeriodID: number,
  buses: CBus[],
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}/buses`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
    data: {
      busIds: buses.map(b => b.id),
    },
  }

  await axios(config)
}

export async function postDispatchPeriodBusBookmark(
  academyID: string,
  busID: number,
  dispatchPeriodID: number,
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}/buses/${busID}/bookmark`

  const config = {
    method: AxiosEnum.POST.value,
    url: url,
  }

  await axios(config)
}

export async function deleteDispatchPeriodBusBookmark(
  academyID: string,
  busID: number,
  dispatchPeriodID: number,
): Promise<void> {
  const url = `${API}/${academyID}/dispatch-period/${dispatchPeriodID}/buses/${busID}/bookmark`

  const config = {
    method: AxiosEnum.DELETE.value,
    url: url,
  }

  await axios(config)
}
