import React, {ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import DispatchDetailOperation from './operation/DispatchDetailOperation'
import {CBusBrief} from '../../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../table/PeriodTable'
import DispatchDetailInfo from './info/DispatchDetailInfo'
import {isEmptyArray, orElse} from '../../../../../util/ValidationUtil'
import {CDispatchDetailWithCode} from '../../../../../model/Dispatch'
import DispatchDetailMap from '../../periodDispatch/detail/map/DispatchDetailMap'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  dispatchDetails: CDispatchDetailWithCode[]
  selectedBusInfo: SelectedBusInfo
}

export default function DispatchDetailManage(props: Props): ReactElement {
  const dispatchCountAll = useMemo(() => {
    return props.dispatchDetails.reduce((acc, curr) => {
      acc = acc + curr.count

      return acc
    }, 0)
  }, [props.dispatchDetails])

  const DetailComponent = useMemo(() => {
    if (isEmptyArray(props.dispatchDetails)) {
      return null
    }

    return (
      <ContentContainer>
        <HeaderContainer>
          <HeaderText>배차 상세</HeaderText>
          <HeaderInfoText>전체({dispatchCountAll})</HeaderInfoText>
        </HeaderContainer>
        <RowContainer>
          <DispatchDetailInfo dispatchDetails={props.dispatchDetails} />
          <DispatchDetailMap
            stations={orElse(
              props.dispatchDetails[0]?.dispatches.map(el => el.station),
              [],
            )}
          />
        </RowContainer>
      </ContentContainer>
    )
  }, [props.dispatchDetails, dispatchCountAll])

  return (
    <Container>
      <DispatchDetailOperation
        dispatchPeriod={props.dispatchPeriod}
        courseName={props.selectedBusInfo.dispatchTable.dispatch.courseName}
        managerPhone={props.selectedBusInfo.dispatchTable.manager.phone}
        managerName={props.selectedBusInfo.dispatchTable.manager.name}
        busBrief={props.busBrief}
      />
      {DetailComponent}
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  ${flexColumn};
  padding: 1.6rem;
  background: #f5f5f5;
  row-gap: 1.6rem;
  overflow: auto;
`

const ContentContainer = styled.div`
  ${flexColumn};
  row-gap: 0.9rem;
  flex: 1;
`

const RowContainer = styled.div`
  ${flexRow};
  flex: 1;
  column-gap: 0.8rem;
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
const HeaderInfoText = styled.div`
  color: #404040;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
