import React, {ForwardedRef, forwardRef, ReactElement, ReactNode} from 'react'
import styled from 'styled-components'
import TableList from '../../common/TableList'
import {color, flexColumn} from '../../../style/CommonStyle'

type StudentListProps = {
  listItems: Map<string, ReactNode>[]
  keys: string[]
  checkedIndices: number[]
  disabledIndices: number[]
  onClickRow(idx: number): void
  onClickAll(indices: number[]): void
}
export type StudentListRef = {
  getStudentList(): void
}

function StudentListBase(
  props: StudentListProps,
  _: ForwardedRef<StudentListRef>,
): ReactElement {
  return (
    <Container>
      <TableList
        disabledIndices={props.disabledIndices}
        defaultCheckedIndices={props.checkedIndices}
        useCheckBox={true}
        keys={props.keys}
        items={props.listItems}
        onClickRow={props.onClickRow}
        onClickAll={props.onClickAll}
        placeholder={'검색 결과가 없습니다.'}
      />
    </Container>
  )
}

const StudentList = forwardRef(StudentListBase)
export default StudentList

const Container = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 40rem;
  ${flexColumn};
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
