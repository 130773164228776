import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../../style/CommonStyle'
import {CDispatchCourseWithStudent} from '../../../../../../../../model/Dispatch'
import CourseItem from '../../common/CourseItem'
import {isNil} from '../../../../../../../../util/ValidationUtil'
import {getSearchCourseDetail} from '../../../../../../../../service/station/Station'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../../recoil/Atom'
import {Optional} from '../../../../../../../../type/Common'
import {CCourse} from '../../../../../../../../model/Course'
import {CCourseDetail} from '../../../../../../../../model/Station'
import CourseSelectMap from '../../../../add/operation/course/CourseSelectMap'
import {OccupancyTypeEnum} from '../../../../../../../../enum/OccupancyTypeEnum'
import {CDispatchPeriodInfo} from '../../../../../../../../model/DispatchPeriod'

export type CourseContainerProps = {
  dispatchCourses: CDispatchCourseWithStudent[]
  selectedCourse: Optional<CCourse>
  occupancyType: OccupancyTypeEnum
  dispatchPeriod: CDispatchPeriodInfo
}

export default function CourseContainer(props: CourseContainerProps) {
  const readOnly = useMemo(() => {
    return props.dispatchCourses.every(d => d.students.length === 0)
  }, [props.dispatchCourses])

  const MapComponent = useMemo(() => {
    if (readOnly) {
      return null
    }

    return (
      <CourseSelectMap
        selectedCourse={props.selectedCourse}
        dispatchPeriod={props.dispatchPeriod}
      />
    )
  }, [readOnly])

  return (
    <Container>
      <ContentContainer>
        {props.dispatchCourses.map((dc, idx) => {
          return (
            <CourseItem
              key={idx}
              course={dc}
              occupancyType={props.occupancyType}
              isFirstItem={idx === 0}
              isLastItem={idx === props.dispatchCourses.length - 1}
              readOnly={readOnly}
            />
          )
        })}
      </ContentContainer>
      {MapComponent}
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  margin-top: 0.8rem;
`

const ContentContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  flex: 1;
  background: #f5f5f5;
`
