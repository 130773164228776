import React, {useCallback, useEffect, useState} from 'react'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../../model/Dispatch'
import Footer from '../../../../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'
import {
  getDispatchesCoursesWithStudent,
  GetDispatchesCoursesWithStudentsData,
  postDispatchesCoursesStudents,
  PostDispatchesCoursesStudentsData,
} from '../../../../../../../service/dispatch/Dispatch'
import {StudentLoadTypeEnum} from '../../../../../../../enum/StudentLoadTypeEnum'
import {Optional} from '../../../../../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import {alertError, throwError} from '../../../../../../../util/ErrorUtil'
import {isNil} from '../../../../../../../util/ValidationUtil'
import {DispatchTypeEnum} from '../../../../../../../enum/DispatchTypeEnum'
import {CCourse} from '../../../../../../../model/Course'
import CoursesNormal from './CoursesNormal'
import {SelectedBusInfo} from '../../../table/PeriodTable'

type Props = {
  dispatchCode: string
  course: Optional<CCourse>
  studentLoadType: StudentLoadTypeEnum
  onCancel(): void
  onSubmit(): void
  selectedBusInfo: SelectedBusInfo
  defaultDispatchCourses?: CDispatchCourseWithStudent[]
}
export type ChangeStudentData = {
  course: CDispatchCourseWithStudent
  student?: CStudentWithCheck
  time?: string
}

export default function LoadStudentNormal(props: Props) {
  const academyID = useRecoilValue(academyIDState)
  const [courses, setCourses] = useState<CDispatchCourseWithStudent[]>([])

  const onSubmit = useCallback(() => {
    const data: PostDispatchesCoursesStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.course.code,
      courses: courses,
      type: props.studentLoadType,
    }

    postDispatchesCoursesStudents(data)
      .then(() => {
        props.onSubmit()
        props.onCancel()
      })
      .catch(error => {
        alertError(
          error,
          `postDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    courses,
    props.course,
    props.dispatchCode,
    props.studentLoadType,
    props.onSubmit,
    props.onCancel,
  ])

  const onChangeStudent = useCallback(
    (data: ChangeStudentData) => {
      setCourses(prev =>
        prev.map((course, idx) => {
          if (course.stationID === data.course.stationID) {
            if (isNil(data.student) && !isNil(data.time)) {
              return {
                ...course,
                arrivalTime: data.time,
              }
            }

            return {
              ...course,
              students: course.students.map(ss => {
                if (ss.id === data.student.id) {
                  return {
                    ...ss,
                    check: !data.student.check,
                  }
                }

                return ss
              }),
            }
          }

          if (isNil(data.student) && !isNil(data.time)) {
            return course
          }

          if (
            props.course.dispatchType || props.selectedBusInfo.isStart === true
              ? DispatchTypeEnum.INBOUND
              : DispatchTypeEnum.OUTBOUND === DispatchTypeEnum.INBOUND &&
                idx === prev.length - 1
          ) {
            return {
              ...course,
              students: course.students.map(ss => {
                if (ss.id === data.student.id) {
                  return {
                    ...ss,
                    check: !data.student.check,
                  }
                }

                return ss
              }),
            }
          }

          if (
            props.course.dispatchType || props.selectedBusInfo.isStart === true
              ? DispatchTypeEnum.INBOUND
              : DispatchTypeEnum.OUTBOUND === DispatchTypeEnum.OUTBOUND &&
                idx === 0
          ) {
            return {
              ...course,
              students: course.students.map(ss => {
                if (ss.id === data.student.id) {
                  return {
                    ...ss,
                    check: !data.student.check,
                  }
                }

                return ss
              }),
            }
          }

          return course
        }),
      )
    },
    [setCourses, props.course],
  )

  const fetchCourses = useCallback(
    (data: GetDispatchesCoursesWithStudentsData) => {
      getDispatchesCoursesWithStudent(data)
        .then(cws => {
          if (props.defaultDispatchCourses) {
            console.log('props.course', props.defaultDispatchCourses)
            console.log('cws', cws)
          }
          setCourses(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [setCourses],
  )

  useEffect(() => {
    const data: GetDispatchesCoursesWithStudentsData = {
      dispatchCode: props.dispatchCode,
      academyID: academyID,
      courseCode: props.course.code,
      type: props.studentLoadType,
    }

    fetchCourses(data)
  }, [props.dispatchCode, props.course, props.studentLoadType, academyID])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>
          {props.defaultDispatchCourses ? '이용 학생 관리' : '학생 불러 오기'}
        </HeaderText>
      </HeaderContainer>
      <CourseContainer>
        <CoursesNormal
          courseWithStudent={courses}
          dispatchType={
            props.course.dispatchType || props.selectedBusInfo.isStart === true
              ? DispatchTypeEnum.INBOUND
              : DispatchTypeEnum.OUTBOUND
          }
          onChangeStudent={onChangeStudent}
        />
      </CourseContainer>
      <Footer
        submitText={'확인'}
        onCancel={props.onCancel}
        onSubmit={onSubmit}
        isSufficient={true}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 54rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`
const HeaderContainer = styled.div`
  ${flexRow};
  border-radius: 1.6rem 0 0 0;
  border-bottom: 0.1rem solid #d9d9d9;
  column-gap: 0.4rem;
  align-items: center;
  background: #ffffff;
  height: 5.6rem;
  padding: 1.6rem;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const CourseContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  flex: 1;
  overflow: auto;
  padding: 1.6rem;
  background: #f5f5f5;
  margin-bottom: 1.6rem;

  ::-webkit-scrollbar {
    display: none;
  }
`
