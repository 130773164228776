import React, {ForwardedRef, forwardRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Optional} from '../../../type/Common'
import ScreenContainer from '../../layout/ScreenContainer'
import PageHeaderWithAcademies from '../../common/PageHeader'
import {flexColumn} from '../../../style/CommonStyle'
import ManagerBasicInfo, {ManagerNameRef} from '../managerInfo/ManagerBasicInfo'
import useSecureRef from '../../../hook/useSecureRef'

import BackArrow from '../../../asset/image/back_page.svg'
import SVGImage from '../../common/SVGImage'
import {useNavigate} from 'react-router'
import ManagerBuses from '../managerInfo/ManagerBusese'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'

type ManagerDetailProps = {}

export type ManagerDetailRef = {}

function ManagerDetailBase(
  props: ManagerDetailProps,
  ref: ForwardedRef<ManagerDetailRef>,
) {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const navigate = useNavigate()
  const nameRef = useSecureRef<ManagerNameRef>('[ManagerDetail.tsx] nameRef')

  // TODO: ref로 받아오는 로직으로 변경필요
  const [managerName, setManagerName] = useState<Optional<string>>(null)

  useEffect(() => {
    if (selectedAcademyID) {
      localStorage.setItem('academyID', selectedAcademyID)
    }
  }, [])

  useEffect(() => {
    const savedAcademyID = localStorage.getItem('academyID')

    if (selectedAcademyID !== savedAcademyID) {
      navigate('/manager')
    }
  }, [selectedAcademyID])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>
        <ImgWrapper onClick={() => navigate(-1)}>
          <BackImg source={BackArrow} />
        </ImgWrapper>
        <ManagerName>{managerName}</ManagerName> 매니저님 상세 정보
      </PageHeaderWithAcademies>
      <Container>
        <ManagerBasicInfo ref={nameRef.ref} setManagerName={setManagerName} />
        <ManagerBuses />
      </Container>
    </ScreenContainer>
  )
}

const ManagerDetail = forwardRef(ManagerDetailBase)
export default ManagerDetail

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 13.8rem);
`

const ManagerName = styled.span`
  color: #2767ce;
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 3rem;
`

const ImgWrapper = styled.div``

const BackImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`
