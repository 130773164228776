import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from 'react'
import InputHeader from '../../../../../../../input/InputHeader'
import TextInput from '../../../../../../../input/TextInput'
import {isNil} from '../../../../../../../../util/ValidationUtil'
import ArrowDown from '../../../../../../../../asset/image/arrow_down_thin.svg'
import BusSelect, {BusSelectRef} from './BusSelect'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../../common/SVGImage'
import useSecureRef from '../../../../../../../../hook/useSecureRef'
import {Optional} from '../../../../../../../../type/Common'
import {BusInfo} from '../../../DispatchEdit'

type Props = {
  defaultBusName: string
  extraBusName: Optional<string>
  dispatchPeriodID: number
  onChange(bi: BusInfo): void
}

export default function BusSelectContainer(props: Props) {
  const busSelectRef = useSecureRef<BusSelectRef>(
    '[BusSelectContainer.tsx] busSelectRef',
  )

  const busInputContainerRef = useRef<HTMLDivElement>(null)

  const ExtraBusComponent = useMemo((): ReactNode => {
    if (isNil(props.extraBusName)) {
      return <BusInputText hasValue={false}>호차 선택</BusInputText>
    }

    return (
      <BusInputTextContainer>
        <BusInputText hasValue={true}>{props.extraBusName}</BusInputText>
      </BusInputTextContainer>
    )
  }, [props.extraBusName])

  const onClickSelectBus = useCallback(() => {
    busSelectRef.current().show()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        busInputContainerRef.current &&
        !busInputContainerRef.current.contains(event.target as Node)
      ) {
        busSelectRef.current().hide()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [busSelectRef])

  return (
    <Container>
      <Header>쪽차 추가</Header>
      <PickersContainer>
        <PickerContainer>
          <InputHeaderContainer>
            <InputHeader header={'기존 호차'} required={true} />
          </InputHeaderContainer>
          <TextInput
            readOnly={true}
            required={false}
            default={props.defaultBusName}
            placeholder={'노선명을 입력해주세요.'}
          />
        </PickerContainer>

        <PickerContainer>
          <InputHeaderContainer>
            <InputHeader header={'추가 쪽차'} required={true} />
          </InputHeaderContainer>
          <BusInputContainer
            onClick={onClickSelectBus}
            ref={busInputContainerRef}>
            <BusInput hasValue={!isNil(props.extraBusName)}>
              {ExtraBusComponent}
              <ArrowDownImage source={ArrowDown} />
            </BusInput>
            <BusSelect
              ref={busSelectRef.ref}
              onChange={props.onChange}
              busName={props.extraBusName}
              disabled={true}
              dispatchPeriodID={props.dispatchPeriodID}
              busNamesToExclude={[props.defaultBusName]}
            />
          </BusInputContainer>
        </PickerContainer>
      </PickersContainer>
    </Container>
  )
}

const Container = styled.div``

const Header = styled.div`
  color: #332a00;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const PickersContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  margin-top: 0.8rem;
`

const PickerContainer = styled.div`
  ${flexRow};
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  margin-left: 0.2rem;
  width: 12.4rem;
`

const BusInputContainer = styled.div`
  position: relative;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 0.8rem;
  flex: 1;
  height: 3rem;
`

const BusInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const BusInputTextContainer = styled.div`
  ${flexRow};
  ${alignCenter};
`

const BusInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`
const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
