import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Footer from '../../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import {CBusBrief} from '../../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import {SelectedBusInfo} from '../table/PeriodTable'
import DispatchDetailManage from './DispatchDetailManage'
import SVGImage from '../../../../common/SVGImage'
import Pencil from '../../../../../asset/image/pencil_icon.svg'
import DispatchEditSelect from '../edit/DispatchEditSelect'
import Modal, {ModalRef} from '../../../../common/Modal'
import useSecureRef from '../../../../../hook/useSecureRef'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../recoil/Atom'
import {CDispatchDetailWithCode} from '../../../../../model/Dispatch'
import {
  deleteDispatchesCoursesWithStudents,
  DeleteDispatchesCoursesWithStudentsData,
  getDispatchesDetailWithDispatchCode,
  GetDispatchesDetailWithDispatchCodeData,
} from '../../../../../service/dispatch/Dispatch'
import DispatchEdit from '../edit/DispatchEdit'
import {Optional} from '../../../../../type/Common'
import {DispatchTypeEnum} from '../../../../../enum/DispatchTypeEnum'
import DispatchPeriod from '../../../../dispatch/dispatchPeriod/DispatchPeriod'
import {throwError} from '../../../../../util/ErrorUtil'
import {StudentLoadTypeEnum} from '../../../../../enum/StudentLoadTypeEnum'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  selectedBusInfo: SelectedBusInfo
  hide(): void
  setToast?(tc: string): void
  onSubmitEdit?(): void
}

export default function DispatchDetail(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const editSelectRef = useSecureRef<ModalRef>(
    '[DispatchInfoNormal.tsx] editSelectRef',
  )
  const editModalRef = useSecureRef<ModalRef>(
    '[DispatchInfoNormal.tsx] editModalRef',
  )

  const [dispatchDetails, setDispatchDetails] = useState<
    CDispatchDetailWithCode[]
  >([])
  const [selectedDispatchDetail, setSelectedDispatchDetail] =
    useState<Optional<CDispatchDetailWithCode>>(null)

  const fetchDispatchesDetailWithDispatchCode = useCallback(
    (aid: string, dispatchCode: string) => {
      const data: GetDispatchesDetailWithDispatchCodeData = {
        academyID: aid,
        dispatchCode: dispatchCode,
      }
      getDispatchesDetailWithDispatchCode(data)
        .then(res => {
          setDispatchDetails(res)
        })
        .catch(error => {
          throw new Error(
            `getDispatchesDetailWithDispatchCode() failed. (data: ${JSON.stringify(
              data,
            )} error: ${error})`,
          )
        })
    },
    [academyID],
  )

  const showEditSelect = useCallback(() => {
    editSelectRef.current().show()
  }, [])

  const hideEditSelect = useCallback(() => {
    editSelectRef.current().hide()
  }, [])

  const onSubmitEditSelect = useCallback(
    (dd: CDispatchDetailWithCode) => {
      setSelectedDispatchDetail(dd)
      editModalRef.current().show()
    },
    [setSelectedDispatchDetail],
  )

  const deleteRadisSavedTempDispatchDetails = useCallback(
    (data: DeleteDispatchesCoursesWithStudentsData) => {
      deleteDispatchesCoursesWithStudents(data)
        .then(cws => {
          // console.log(cws)
        })
        .catch(error => {
          throwError(
            error,
            `getDispatchesCoursesStudents() failed. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  const hideEditModal = useCallback(() => {
    const data: DeleteDispatchesCoursesWithStudentsData = {
      dispatchCode: props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
      academyID: academyID,
      courseCode: props.selectedBusInfo.dispatchTable.course.code,
      type: StudentLoadTypeEnum.EDIT,
    }
    deleteRadisSavedTempDispatchDetails(data)

    editModalRef.current().hide()
  }, [])

  const onSubmitEdit = useCallback(() => {
    props.onSubmitEdit()
    hideEditModal()
    props.hide()
  }, [props.onSubmitEdit, hideEditModal, props.hide])

  const HeaderComponent = useMemo(() => {
    return (
      <HeaderContainer>
        <HeaderContentContainer>
          <HeaderText>{props.selectedBusInfo.weekday.exposure}요일</HeaderText>
          <EmphasizedHeaderText isStart={props.selectedBusInfo.isStart}>
            {props.selectedBusInfo.classTime}(
            {props.selectedBusInfo.isStart ? '등원' : '하원'})
          </EmphasizedHeaderText>
          <HeaderText> {props.busBrief.bus.name} 배차 상세</HeaderText>
        </HeaderContentContainer>
        {props.selectedBusInfo.dispatchTable.dispatch.dispatchType ===
        DispatchTypeEnum.CIRCULATION ? null : (
          <HeaderButton onClick={showEditSelect}>
            <HeaderButtonImage source={Pencil} />
            <HeaderButtonText>배차 수정</HeaderButtonText>
          </HeaderButton>
        )}
      </HeaderContainer>
    )
  }, [props.selectedBusInfo, props.busBrief])

  useEffect(() => {
    fetchDispatchesDetailWithDispatchCode(
      academyID,
      props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
    )
  }, [academyID, props.selectedBusInfo])

  return (
    <Container>
      <Modal ref={editSelectRef.ref}>
        <DispatchEditSelect
          onCancel={hideEditSelect}
          dispatchDetails={dispatchDetails}
          onSubmit={onSubmitEditSelect}
        />
      </Modal>
      <Modal ref={editModalRef.ref}>
        <DispatchEdit
          dispatchDetail={selectedDispatchDetail}
          busBrief={props.busBrief}
          dispatchPeriod={props.dispatchPeriod}
          selectedBusInfo={props.selectedBusInfo}
          onCancel={hideEditModal}
          setToast={props.setToast}
          onSubmit={onSubmitEdit}
        />
      </Modal>
      {HeaderComponent}
      <DispatchDetailManage
        busBrief={props.busBrief}
        dispatchDetails={dispatchDetails}
        dispatchPeriod={props.dispatchPeriod}
        selectedBusInfo={props.selectedBusInfo}
      />

      <Footer submitText={'확인'} onSubmit={props.hide} isSufficient={true} />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const HeaderContentContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const HeaderContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  height: 5.6rem;
  padding: 1.6rem;
  background: #ffffff;
  border-bottom: 0.1rem solid #d9d9d9;
  border-radius: 1.6rem 0 0 0;
`

const HeaderButton = styled.div`
  display: flex;
  padding: 4px 12px 4px 8px;
  align-items: center;
  background: #332a00;
  border-radius: 34px;
  column-gap: 0.2rem;
  cursor: pointer;
`

const HeaderButtonImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const HeaderButtonText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: -0.36px;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const EmphasizedHeaderText = styled.h5<{isStart: boolean}>`
  color: ${props => (props.isStart ? '#009A17' : '#4b84dd')};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`
