import React from 'react'
import styled from 'styled-components'
import SVGImage from '../../common/SVGImage'
import CloseIcon from '../../../asset/image/close_button.svg'
import {ModalButton} from '../../../style/CommonStyle'
import {Optional} from '../../../type/Common'

type ModalProps = {
  onClose: () => void
  deleteCourse: () => void
  courseName: Optional<string>
}

function CourseDelete(props: ModalProps) {
  return (
    <Container>
      <CloseButton onClick={props.onClose}>
        <CloseImg source={CloseIcon} />
      </CloseButton>
      <Title>노선 삭제</Title>
      <Contents>
        <StationName>{props.courseName}</StationName> 노선을
        <br /> 삭제 하시겠습니까?
      </Contents>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
        <ConfirmButton onClick={props.deleteCourse}>예</ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

export default CourseDelete

const Container = styled.div`
  padding: 1.6rem 1.2rem;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const CloseButton = styled.div``

const CloseImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.9rem;
  right: 1.5rem;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
`

const Contents = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a468b;
  margin: 1.6rem 0 2.4rem;
`

const StationName = styled.span`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 800;
  color: #1a468b;
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`
const CancelButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
`
const ConfirmButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
  background: #ffcd00;
`
