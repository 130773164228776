import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Footer from '../../../common/Footer'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import {CBusBrief, CBusDispatchSummary} from '../../../../model/Bus'
import {CDispatchPeriodInfo} from '../../../../model/DispatchPeriod'
import {CDispatchDetailDispatch} from '../../../../model/Dispatch'
import {
  getDispatchesDetailOneDay,
  GetDispatchesDetailOneDayData,
} from '../../../../service/dispatch/Dispatch'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import DispatchDetailOperation from '../period/detail/operation/DispatchDetailOperation'
import {CDate} from '../../../../model/Date'
import DispatchDetailOneDayInfo from './DispatchDetailOneDayInfo'
import DispatchDetailMap from '../periodDispatch/detail/map/DispatchDetailMap'
import {isEmptyArray} from '../../../../util/ValidationUtil'

type Props = {
  busBrief: CBusBrief
  dispatchPeriod: CDispatchPeriodInfo
  dispatchSummary: CBusDispatchSummary
  date: CDate
  hide(): void
}

export default function DispatchDetailOneDay(props: Props) {
  const academyID = useRecoilValue(academyIDState)

  const [dispatches, setDispatches] = useState<CDispatchDetailDispatch[]>([])

  const fetchDispatchesDetail = useCallback(
    (aid: string, dispatchCode: string, date: CDate) => {
      const data: GetDispatchesDetailOneDayData = {
        academyID: aid,
        dispatchCode: dispatchCode,
        searchDate: date,
      }
      getDispatchesDetailOneDay(data)
        .then(res => {
          setDispatches(res)
        })
        .catch(error => {
          throw new Error(
            `getDispatchesDetailOneDay() failed. (data: ${JSON.stringify(
              data,
            )} error: ${error})`,
          )
        })
    },
    [academyID],
  )

  useEffect(() => {
    fetchDispatchesDetail(
      academyID,
      props.dispatchSummary.dispatch.code,
      props.date,
    )
  }, [props.dispatchSummary, props.date, academyID])

  //TODO:[gun] header finish
  const HeaderComponent = useMemo(() => {
    return (
      <HeaderContainer>
        <HeaderContentContainer>
          <HeaderText>{props.date.toString()} </HeaderText>

          {/*<HeaderText>{props.weekday.exposure}요일</HeaderText>*/}
          {/*<EmphasizedHeaderText isStart={props.isStart}>*/}
          {/*  {props.classTime}({props.isStart ? '등원' : '하원'})*/}
          {/*</EmphasizedHeaderText>*/}
          <HeaderText> {props.busBrief.bus.name} 배차 상세</HeaderText>
        </HeaderContentContainer>
      </HeaderContainer>
    )
  }, [props.busBrief])

  if (isEmptyArray(dispatches)) {
    return null
  }

  //TODO:[gun] manager phone number
  return (
    <Container>
      {HeaderComponent}
      <ContentContainer>
        <DispatchDetailOperation
          dispatchPeriod={props.dispatchPeriod}
          courseName={props.dispatchSummary.dispatch.name}
          managerPhone={'----'}
          managerName={props.dispatchSummary.manager.name}
          busBrief={props.busBrief}
        />
        <DetailContentContainer>
          <HeaderText2>배차 상세</HeaderText2>
          <RowContainer>
            <DispatchDetailOneDayInfo
              busBrief={props.busBrief}
              dispatches={dispatches}
              dispatchSummary={props.dispatchSummary}
            />
            <DispatchDetailMap stations={dispatches.map(el => el.station)} />
          </RowContainer>
        </DetailContentContainer>
      </ContentContainer>

      <Footer submitText={'확인'} onSubmit={props.hide} isSufficient={true} />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: #f5f5f5;
`

const ContentContainer = styled.div`
  flex: 1;
  ${flexColumn};
  padding: 1.6rem;
  background: #f5f5f5;
  row-gap: 1.6rem;
  overflow: auto;
`

const HeaderContentContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`

const HeaderContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  height: 5.6rem;
  padding: 1.6rem;
  background: #ffffff;
  border-bottom: 0.1rem solid #d9d9d9;
  border-radius: 1.6rem 0 0 0;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const HeaderText2 = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const DetailContentContainer = styled.div`
  ${flexColumn};
  row-gap: 0.9rem;
  flex: 1;
`

const RowContainer = styled.div`
  ${flexRow};
  flex: 1;
  column-gap: 0.8rem;
`

const EmphasizedHeaderText = styled.h5<{isStart: boolean}>`
  color: ${props => (props.isStart ? '#009A17' : '#4b84dd')};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`
