import React, {useCallback, useMemo, useState} from 'react'
import DispatchSemesterSelectInputModal, {
  DispatchSemesterSelectInputModalRef,
} from '../semesterSelect/DispatchSemesterSelectInputModal'
import {isNil} from '../../../../util/ValidationUtil'
import ArrowDown from '../../../../asset/image/arrowDown.svg'
import styled from 'styled-components'
import InputHeader from '../../../input/InputHeader'
import {flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import useSecureRef from '../../../../hook/useSecureRef'
import {Optional} from '../../../../type/Common'
import {CSemester} from '../../../../model/Semester'

type Props = {
  originalSemesterID: number
  onChangeSemesterID(sid: number): void
}

export default function SemesterSelect(props: Props) {
  const inputModalRef = useSecureRef<DispatchSemesterSelectInputModalRef>(
    '[DispatchSemesterSelectContainer.tsx] inputModalRef',
  )
  const [selectedSemester, setSelectedSemester] =
    useState<Optional<CSemester>>(null)

  const onChangeSemester = useCallback(
    (ss: CSemester) => {
      setSelectedSemester(ss)
      props.onChangeSemesterID(ss.id)
    },
    [setSelectedSemester, props.onChangeSemesterID],
  )

  const inputText = useMemo(() => {
    if (isNil(selectedSemester)) {
      return '배차 등록할 학기와 수업시간을 선택해주세요.'
    }

    return selectedSemester.name
  }, [selectedSemester])

  const showModal = useCallback(() => {
    inputModalRef.current().show()
  }, [])

  return (
    <SelectContainer>
      <StyledInputHeader header={'등록학기 및 수업시간 선택'} required={true} />
      <InputContainer>
        <DispatchSemesterSelectInputModal
          isCopy={true}
          ref={inputModalRef.ref}
          onChange={onChangeSemester}
          selectedSemesterID={props.originalSemesterID}
        />
        <TimeScheduleInput
          hasValue={!isNil(selectedSemester)}
          onClick={showModal}>
          <TimeScheduleInputText hasValue={!isNil(selectedSemester)}>
            {inputText}
          </TimeScheduleInputText>
          <ArrowDownImage source={ArrowDown}></ArrowDownImage>
        </TimeScheduleInput>
      </InputContainer>
    </SelectContainer>
  )
}

const SelectContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 0.8rem;
  ${flexRow};
  align-items: center;
  padding: 0.8rem 1.2rem;
  column-gap: 0.4rem;
  border: 0.1rem solid #ebebeb;
`

const StyledInputHeader = styled(InputHeader)`
  width: 16rem;
  padding: 0.5rem 0.2rem;
`

const InputContainer = styled.div`
  flex: 1;
  position: relative;
`

const TimeScheduleInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  width: 100%;
  height: 3rem;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  border: 0.1rem solid #ebebeb;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const TimeScheduleInputText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`
