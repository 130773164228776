import {CPaging} from './Paging'

export class CDriverDispatches {
  private constructor(
    readonly busName: string,
    readonly dispatchName: string,
    readonly driverName: string,
    readonly remainingSeatCount: number,
    readonly willBoardCount: number,
    readonly wontBoardCount: number,
  ) {}

  static create(json: any) {
    return new CDriverDispatches(
      json.busName,
      json.dispatchName,
      json.driverName,
      json.remainingSeatCount,
      json.willBoardCount,
      json.wontBoardCount,
    )
  }
}

export class CDriverBus {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly regNum: string,
  ) {}

  static create(json: any): CDriverBus {
    return new CDriverBus(json.id, json.name, json.regNum)
  }
}

export class CDriver {
  private constructor(
    readonly buses: CDriverBus[],
    readonly createdAt: string,
    readonly id: number,
    readonly name: string,
    readonly phone: string,
    readonly userId: string,
  ) {}

  static create(json: any): CDriver {
    return new CDriver(
      json.buses.map((b: any) => CDriverBus.create(b)),
      json.createdAt,
      json.id,
      json.name,
      json.phone,
      json.userId,
    )
  }
}

export class CDriverVector {
  private constructor(
    readonly drivers: CDriver[],
    readonly paging: CPaging,
  ) {}

  static create(json: any): CDriverVector {
    return new CDriverVector(
      json.drivers.map((d: any) => CDriver.create(d)),
      json.paging,
    )
  }
}
