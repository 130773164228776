import React, {ForwardedRef, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {color, flexColumn, flexRow} from '../../../style/CommonStyle'
import useSecureRef from '../../../hook/useSecureRef'
import HalfPopup, {PopUpRef} from '../../common/HalfPopUp'
import {RegisterDriverPopUp} from '../../driverManagement/popupContents/RegisterDriverPopUp'
import {
  CancelDriverRegisterPopUp,
  CancelRegisterPopUp,
} from './CancelRegisterDispatch'
import {
  existDriver,
  patchDriver,
  postDriver,
  postNonExistDriver,
} from '../../../service/driver/Driver'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../../type/Common'
import {academyIDState} from '../../../recoil/Atom'
import TextInput, {TextInputRef} from '../../input/TextInput'
import {NumberInputRef} from '../../input/NumberInput'
import PasswordInput from '../../input/PasswordInput'
import {isEmptyString, isNil} from '../../../util/ValidationUtil'
import {
  TimeInput,
  TimeInputContainer,
} from '../../managerManagement/registerManager/RegisterManager'
import {ExistDriver} from '../../driverManagement/popupContents/ExistDriver'

type RegisterDriverProps = {
  hideRegisterModal(): void
  getNotAssignedDriverList(): void
}

export type RegisterDriverRef = {
  reset(): void
}

export type DriverProps = {
  id: number
  name: string
  phone: string
}

function RegisterDriver(
  props: RegisterDriverProps,
  ref: ForwardedRef<RegisterDriverRef>,
) {
  const cancelRef = useSecureRef<PopUpRef>('[RegisterDriver.tsx] cancelRef')
  const registerRef = useSecureRef<PopUpRef>('[RegisterDriver.tsx] registerRef')
  const existDriverRef = useSecureRef<PopUpRef>(
    '[RegisterDriver.tsx] existDriverRef',
  )
  const nameInputRef = useSecureRef<TextInputRef>(
    '[RegisterDriver.tsx] nameInputRef',
  )
  const idInputRef = useSecureRef<TextInputRef>(
    '[RegisterDriver.tsx] idInputRef',
  )
  const passwordInputRef = useSecureRef<TextInputRef>(
    '[RegisterDriver.tsx] passwordInputRef',
  )
  const numberInputRef = useSecureRef<NumberInputRef>(
    '[RegisterDriver.tsx] numberInputRef',
  )
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [submitPressable, setSubmitPressable] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<Optional<number>>(null)
  const [id, setId] = useState<string>('')
  const [pw, setPw] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [existDriverState, setExistDriverState] =
    useState<Optional<DriverProps>>(null)

  const handleCancelButton = useCallback(() => {
    cancelRef.current().show()
  }, [])

  const handleRegisterButton = useCallback(() => {
    if (!submitPressable) {
      return
    }
    return registerRef.current().show()
  }, [submitPressable])

  const onChangeName = useCallback(
    (s: string) => {
      return setName(s)
    },
    [setName],
  )

  const onChangePhone = useCallback(
    (n: number) => {
      return setPhone(n)
    },
    [setPhone],
  )

  const onChangeId = useCallback(
    (s: string) => {
      return setId(s)
    },
    [setId],
  )

  const onChangePw = useCallback(
    (s: string) => {
      return setPw(s)
    },
    [setPw],
  )

  const onClickCancelButton = useCallback(() => {
    registerRef.current().hide()
    cancelRef.current().hide()
  }, [])

  const onConfirmCancel = useCallback(() => {
    props.hideRegisterModal()
    cancelRef.current().hide()
    nameInputRef.current().reset()
    numberInputRef.current().reset()
    idInputRef.current().reset()
    passwordInputRef.current().reset()
    setName('')
    setId('')
    setPw('')
    setPhone(null)
    setErrorMessage('')
  }, [])

  const onSubmit = useCallback(() => {
    if (!submitPressable) {
      return
    }

    if (submitPressable) {
      const data = {
        name: name,
        password: pw,
        phone: '0' + String(phone),
        userID: id,
      }

      const existData = {
        academyID: selectedAcademyID,
        phone: '0' + String(phone),
      }

      existDriver(existData)
        .then(res => {
          if (res.status) {
            registerRef.current().hide()
            existDriverRef.current().show()
            setExistDriverState({id: res.id, name: res.name, phone: res.phone})
          }
          if (!res.status) {
            postNonExistDriver(selectedAcademyID, data)
              .then(res => {
                if (res === 'OK') {
                  registerRef.current().hide()
                  props.hideRegisterModal()
                  props.getNotAssignedDriverList()
                  setName(name)
                  nameInputRef.current().setValue('')
                  idInputRef.current().setValue('')
                  passwordInputRef.current().setValue('')
                  numberInputRef.current().reset()
                  setName('')
                  setId('')
                  setPw('')
                  setPhone(null)
                  setErrorMessage('')
                }
              })
              .catch(error => {
                setErrorMessage(error.response.data.data)
                registerRef.current().hide()
              })
          }
        })
        .catch(error => {
          setErrorMessage(error.response.data.data)
          registerRef.current().hide()
        })
    }
  }, [submitPressable, name, pw, phone, id])

  // 기사 중복 등록
  const registerExistDriver = useCallback(() => {
    if (!isNil(existDriverState)) {
      patchDriver(selectedAcademyID, String(existDriverState?.id))
        .then(res => {
          if (res === 'OK') {
            existDriverRef.current().hide()
            props.hideRegisterModal()
            setName(existDriverState.name)
            nameInputRef.current().setValue('')
            idInputRef.current().setValue('')
            passwordInputRef.current().setValue('')
            numberInputRef.current().reset()
            setName('')
            setId('')
            setPw('')
            setPhone(null)
            setErrorMessage('')
          }
        })
        .catch(error => {
          setErrorMessage(error.response.data.data)
          existDriverRef.current().hide()
        })
    }
  }, [existDriverState])

  useEffect(() => {
    if (name !== '' && !isNil(phone) && id !== '' && pw !== '') {
      setSubmitPressable(true)
    } else setSubmitPressable(false)
  }, [name, phone, id, pw])

  return (
    <Container>
      <Header>신규 기사 등록</Header>
      <Noti>
        • <span>*</span> 는 필수 입력 항목입니다.
      </Noti>
      <BodyWrapper>
        <Title>기본 정보</Title>
        <Card>
          <SubTitle>
            기사명 <span>*</span>
          </SubTitle>
          <CustomTextInput
            ref={nameInputRef.ref}
            onChange={onChangeName}
            placeholder={'이름을 입력해주세요.'}
            value={name}
            error={errorMessage.includes('name')}
            readOnly={false}
            required={false}
          />
          {errorMessage?.includes('name') && (
            <Error>
              이름은 한글, 영문, 숫자를 이용하여 최소 2자 ~ 10자까지 입력할 수
              있습니다.
            </Error>
          )}
        </Card>
        <Card>
          <SubTitle>
            연락처 <span>*</span>
          </SubTitle>
          <TimeInputContainer>
            <CustomTimeInput
              ref={numberInputRef.ref}
              maxLength={11}
              required={false}
              readOnly={false}
              placeholder={'전화번호를 입력해주세요. (`-`제외)'}
              value={phone}
              error={
                errorMessage.includes('phone') || errorMessage.includes('R902')
              }
              resetOff={true}
              onChange={onChangePhone}
            />
          </TimeInputContainer>
          {errorMessage?.includes('R902') && (
            <Error>이미 등록된 전화번호 입니다.</Error>
          )}
          {errorMessage?.includes('phone') && (
            <Error>올바른 연락처 양식이 아닙니다.</Error>
          )}
        </Card>
        <Title>계정 정보</Title>
        <Card>
          <SubTitle>
            아이디 <span>*</span>
          </SubTitle>
          <CustomTextInput
            ref={idInputRef.ref}
            onChange={onChangeId}
            placeholder={'영문, 숫자 조합 최소 5자 ~ 최대 20자'}
            value={id}
            error={
              errorMessage.includes('userId') || errorMessage.includes('R901')
            }
            readOnly={false}
            required={false}
          />
          {errorMessage?.includes('userId') && (
            <Error>
              아이디는 영문, 숫자를 이용하여 최소 5자 ~ 최대 20자까지 입력
              가능합니다.
            </Error>
          )}
          {errorMessage?.includes('R901') && (
            <Error>이미 등록되어 있는 아이디입니다.</Error>
          )}
        </Card>
        <Card>
          <SubTitle>
            비밀번호 <span>*</span>
          </SubTitle>
          <CustomPasswordInput
            ref={passwordInputRef.ref}
            onChange={onChangePw}
            placeholder={'영문, 숫자, 특수문자 조합 최소 10자 ~ 최대 30자'}
            value={pw}
            error={errorMessage.includes('password')}
            readOnly={false}
            required={false}
          />
          {errorMessage?.includes('password') && (
            <Error>
              비밀번호는 영문, 숫자, 특수문자 기입 가능하며 최소 10자 ~ 최대
              30자 까지 입력 가능합니다.
            </Error>
          )}
        </Card>
      </BodyWrapper>
      <Bottom>
        <CancelButton onClick={handleCancelButton}>취소</CancelButton>
        <RegisterButton
          pressable={submitPressable}
          onClick={handleRegisterButton}>
          등록
        </RegisterButton>
      </Bottom>
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={registerRef.ref}
        contents={
          <RegisterDriverPopUp
            onClose={onClickCancelButton}
            onSubmit={onSubmit}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={cancelRef.ref}
        contents={
          <CancelDriverRegisterPopUp
            onClose={onClickCancelButton}
            onClickEditButton={onConfirmCancel}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={existDriverRef.ref}
        contents={
          <ExistDriver
            onClose={onClickCancelButton}
            handleRegisterExistDriver={registerExistDriver}
            existDriver={existDriverState}
          />
        }
      />
    </Container>
  )
}

export default RegisterDriver

const Container = styled.div``

export const Header = styled.div`
  height: 6rem;
  padding: 2rem;
  background: ${color.white};
  border-bottom: 0.1rem solid #ebebeb;
  color: #332a00;
  font-size: 1.8rem;
  font-weight: 700;
  border-radius: 1.6rem 0 0 0;
`

const Noti = styled.div`
  height: 3rem;
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #999;

  span {
    color: #ff7500;
    font-size: 1.1rem;
  }
`

const BodyWrapper = styled.div`
  ${flexColumn};
  width: 100%;
  height: calc(100vh - 15.8rem);
  padding: 0 1.6rem;
  background: #f5f5f5;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Bottom = styled.div`
  height: 6.8rem;
  padding: 1.6rem 2.4rem;
  background: ${color.white};
  border-radius: 0 0 0 1.6rem;
  justify-content: end;
  ${flexRow}
`

export const CancelButton = styled.button`
  padding: 1rem 2.2rem;
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 3rem;
  margin-left: 0.4rem;
`

const RegisterButton = styled(CancelButton)<{pressable: boolean}>`
  background: #ffcd00;
  opacity: ${props => (props.pressable ? 1 : 0.3)};
  cursor: ${props => (props.pressable ? 'pointer' : 'not-allowed')};
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin-top: 1.6rem;
`

const Card = styled.div`
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #fff;
  border: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
  margin-top: 0.8rem;
`

const SubTitle = styled.div`
  padding: 0.2rem;
  line-height: 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  color: #997d00;
  span {
    color: #ff7500;
    font-size: 1.3rem;
  }
`

type InputProps = {
  error: boolean
}

const Input = styled.input<InputProps>`
  border: 0.1rem solid #ebebeb;
  border-color: ${props => (props.error ? 'red' : '#ebebeb')};
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  width: 100%;
  line-height: 1.8rem;
  color: ${props => (props.error ? '#DA291C' : 'black')};
  background: ${props => (props.error ? '#FCE6E4' : '')};

  :focus {
    background: ${props => (props.error ? '' : '#fffbe5')};
  }
`

const Error = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #e74d41;
  margin-top: 0.6rem;
`

type CustomTextInputProps = {
  error: boolean
  value: string
}
const CustomTextInput = styled(TextInput)<CustomTextInputProps>`
  font-weight: 500;
  background: ${props =>
    isEmptyString(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props =>
    props.error ? '0.1rem solid #ED766E' : '0.1rem solid #EBEBEB'};
  color: ${props => (props.error ? '#DA291C' : '#000')};
  &::placeholder {
    font-weight: 300;
  }
`

type CustomTimeInputProps = {
  error: boolean
  value: Optional<number>
}
const CustomTimeInput = styled(TimeInput)<CustomTimeInputProps>`
  font-weight: 500;
  background: ${props =>
    isNil(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props => (props.error ? '0.1rem solid #ED766E' : 'none')};
  color: ${props => (props.error ? '#DA291C' : '#000')};
  &::placeholder {
    font-weight: 300;
  }
`

type CustomPasswordInputProps = {
  error: boolean
  value: string
}
const CustomPasswordInput = styled(PasswordInput)<CustomPasswordInputProps>`
  font-weight: 500;
  background: ${props =>
    isEmptyString(props.value) ? '#fff' : props.error ? '#FCE6E4' : '#FFFBE5'};
  border: ${props =>
    props.error ? '0.1rem solid #ED766E' : '0.1rem solid #ebebeb'};
  color: ${props => (props.error ? '#DA291C' : '#000')};
  &::placeholder {
    font-weight: 300;
  }
`
