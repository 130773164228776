import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
  justifyCenter,
} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import ADD from '../../../../asset/image/plus_icon.svg'
import Picker, {toPickerOptions} from '../../../input/Picker'
import {
  deleteDispatchPeriod,
  GetDispatchPeriodListData,
  getDispatchPeriods,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState, academyState} from '../../../../recoil/Atom'
import {CDispatchPeriod} from '../../../../model/DispatchPeriod'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import DispatchPeriodSettingListItem from './DispatchPeriodSettingListItem'
import {CDate} from '../../../../model/Date'
import Modal, {ModalRef} from '../../../common/Modal'
import DispatchPeriodAdd from '../add/DispatchPeriodAdd'
import useSecureRef, {createSecureRef} from '../../../../hook/useSecureRef'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import AlertModal, {AlertModalRef} from '../../../modal/AlertModal'
import {alertError} from '../../../../util/ErrorUtil'
import {Optional} from '../../../../type/Common'

type Props = {
  years: number[]
  setToast(tc: string): void
  onCancel(): void
  fetchDispatchPeriodYears(): void
  handleLoading(isLoading: boolean): void
}

export default function DispatchPeriodSettingList(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const academy = useRecoilValue(academyState)
  const addModalRef = useSecureRef<ModalRef>(
    '[DispatchPeriodSettingList.tsx] addModalRef',
  )
  const deleteConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodSettingListItem.tsx] deleteModalRef',
  )
  const alertModalRef = useSecureRef<AlertModalRef>(
    '[DispatchPeriodSettingListItem.tsx] alertModalRef',
  )

  const [selectedYear, setSelectedYear] = useState<Optional<number>>(null)
  const [dispatchPeriods, setDispatchPeriods] = useState<CDispatchPeriod[]>([])

  const fetchDispatchPeriods = useCallback(
    (year: number) => {
      props.handleLoading(true)
      const data: GetDispatchPeriodListData = {
        academyID: academyID,
        dispatchPeriodYear: year,
      }
      getDispatchPeriods(data)
        .then(dps => {
          setDispatchPeriods(dps)
          props.handleLoading(false)
        })
        .catch(error => {
          props.handleLoading(false)
          throw new Error(
            `getDispatchPeriods() failed. (error: ${error}, aid: ${academyID}, year:${year})`,
          )
        })
    },
    [setDispatchPeriods, academyID],
  )

  const onChangeYear = useCallback(
    (year: number) => {
      setSelectedYear(year)
    },
    [setSelectedYear],
  )

  const yearOptions = useMemo(() => {
    return toPickerOptions(
      props.years,
      v => <div style={{fontSize: 13, paddingLeft: 6}}>{v}년</div>,
      v => <div style={{fontSize: 13}}>{v}년</div>,
    )
  }, [props.years])

  const defaultIdx = useMemo(() => {
    return props.years.findIndex((y: number) => y === selectedYear)
  }, [selectedYear, props.years])

  const showAddModal = useCallback(() => {
    addModalRef.current().show()
  }, [])

  const hideAddModal = useCallback(() => {
    addModalRef.current().hide()
  }, [])

  const showSuccessModal = useCallback(() => {
    alertModalRef.current().show()
  }, [])

  const onAdd = useCallback(
    (toastContent: string, year: number) => {
      props.setToast(toastContent)
      setSelectedYear(year)
      props.fetchDispatchPeriodYears()
      fetchDispatchPeriods(year)
    },
    [
      props.setToast,
      setSelectedYear,
      props.fetchDispatchPeriodYears,
      fetchDispatchPeriods,
    ],
  )

  const onDelete = useCallback(
    (dispatchPeriodID: number) => {
      props.handleLoading(true)
      deleteDispatchPeriod(academyID, dispatchPeriodID)
        .then(() => {
          showSuccessModal()
          props.fetchDispatchPeriodYears()
          props.handleLoading(false)
        })
        .catch(error => {
          props.handleLoading(false)
          alertError(
            error,
            `deleteDispatchPeriod() failed. (error:${error}, dispatchPeriodID: ${dispatchPeriodID})`,
          )
        })
    },
    [academyID, props.fetchDispatchPeriodYears, props.handleLoading],
  )

  const ListComponent = useMemo(() => {
    if (isEmptyArray(dispatchPeriods)) {
      return (
        <ListContainer>
          <EmptyExposure>등록된 배차 기간 리스트가 없습니다.</EmptyExposure>
        </ListContainer>
      )
    }

    return (
      <ListContainer>
        <AlertModal
          ref={alertModalRef.ref}
          header={'삭제 완료'}
          content={'삭제되었습니다.'}
          onSubmit={() => fetchDispatchPeriods(selectedYear)}
        />

        {dispatchPeriods.map((dp, idx) => {
          const editable = dp.dispatchPeriod.endDate.isAfter(CDate.now())
          const ref = createSecureRef<ConfirmModalRef>(
            String(dp.dispatchPeriod.id),
          )

          return (
            <Fragment key={`dp_${idx}_${dp.dispatchPeriod.id}`}>
              <ConfirmModal
                ref={ref.ref}
                header={'배차 삭제'}
                content={
                  '등록된 기간의 모든 배차 정보를 삭제 하시겠습니까? \n(삭제된 정보는 복원되지 않습니다.)'
                }
                onSubmit={() => onDelete(dp.dispatchPeriod.id)}
              />
              <DispatchPeriodSettingListItem
                key={`dp_${idx}`}
                item={dp}
                deletable={true}
                editable={editable}
                onRefresh={() => {
                  props.fetchDispatchPeriodYears()
                  fetchDispatchPeriods(selectedYear)
                }}
                onPressDelete={() => ref.current().show()}
                setToast={props.setToast}
                handleLoading={props.handleLoading}
              />
            </Fragment>
          )
        })}
      </ListContainer>
    )
  }, [
    deleteConfirmModalRef,
    dispatchPeriods,
    fetchDispatchPeriods,
    props.setToast,
  ])

  useEffect(() => {
    if (isNil(selectedYear)) {
      return
    }

    fetchDispatchPeriods(selectedYear)
  }, [selectedYear])

  return (
    <Container>
      <Modal ref={addModalRef.ref}>
        <DispatchPeriodAdd
          onSubmit={onAdd}
          onCancel={hideAddModal}
          handleLoading={props.handleLoading}
        />
      </Modal>
      <PickerContainer>
        <HeaderContainer>
          <TitleContainer>
            <Title>배차 기간 리스트</Title>
            <RegisteredAcademy>
              등록 학원 정보 : {academy.name}
            </RegisteredAcademy>
          </TitleContainer>
          <AddButton onClick={showAddModal}>
            <AddImage source={ADD} />
            <AddText>배차 기간 등록</AddText>
          </AddButton>
        </HeaderContainer>
        <Picker
          defaultIdx={defaultIdx}
          options={yearOptions}
          minWidth={138}
          height={36}
          onChange={onChangeYear}
        />
      </PickerContainer>
      {ListComponent}
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  ${flexColumn};
  padding: 1.6rem;
  row-gap: 1rem;
  overflow: hidden;
`

const PickerContainer = styled.div`
  ${flexColumn};
  row-gap: 1rem;
  flex: 1;
`

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  align-items: center;
`

const Title = styled.h5`
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 150%;
`

const RegisteredAcademy = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 150%;
  color: #3575d9;
`

const AddButton = styled.button`
  border: none;
  width: 10.6rem;
  height: 2.6rem;
  background: #ffd100;
  border-radius: 3.4rem;
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
`
const AddImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const AddText = styled.div`
  font-size: 1.2rem;
`
const EmptyExposure = styled.div`
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  color: #999999;
  margin-top: 0.6rem;
`

const ListContainer = styled.div`
  ${flexColumn};
  height: 100%;
  row-gap: 0.8rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
