import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  alignCenter,
  color,
  flexColumn,
  flexRow,
  justifyCenter,
} from '../../../style/CommonStyle'
import {CBusNameWithID, CBusOperation} from '../../../model/Bus'
import {
  getBuses,
  GetBusesData,
  GetBusOperationDetailParams,
  getBusOperationsQuery,
  GetBusOperationsQueryParams,
} from '../../../service/busOperation/BusOperation'
import {isEmptyArray, isNil} from '../../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {CDispatchWithSeatOccupancy} from '../../../model/Dispatch'
import Select, {SelectRef, toSelectOptions} from '../../input/Select'
import {
  getDispatchesWithSeatOccupancy,
  GetDispatchesWithSeatOccupancyParams,
} from '../../../service/dispatch/Dispatch'
import {OperationTypeEnum} from '../../../enum/OperationTypeEnum'
import useSecureRef from '../../../hook/useSecureRef'
import {CDate} from '../../../model/Date'
import DatePicker, {DatePickerRef} from '../../input/DatePicker'
import Header from '../../common/Header'
import {Optional} from '../../../type/Common'
import TableList from '../../common/TableList'
import Loading, {LoadingRef} from '../../common/Loading'

const TITLE_MAP = {
  busName: '버스 / 배차명',
  status: '상태',
  shareRate: '점유율',
}

export type BusOperationState = {
  originalBusOperations: CBusOperation[]
  filteredBusOperations: CBusOperation[]
}

type OperationListProps = {
  onChangeDate(date: CDate): void
  fetchBusOperationDetail(data: GetBusOperationDetailParams): void
}

export type OperationListRef = {}

function OperationListBase(
  props: OperationListProps,
  ref: ForwardedRef<OperationListRef>,
): ReactElement {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const dateRef = useSecureRef<DatePickerRef>(
    '[OperationListHeader.tsx] dateRef',
  )
  const busRef = useSecureRef<SelectRef<string>>(
    '[OperationListHeader.tsx] busRef',
  )
  const timeRef = useSecureRef<SelectRef<string>>(
    '[OperationListHeader.tsx] timeRef',
  )

  const loadingRef = useRef<LoadingRef | null>(null)

  const handleLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading === true) {
        loadingRef.current?.show()
      } else {
        loadingRef.current?.hide()
      }
    },
    [loadingRef],
  )

  const [dispatches, setDispatches] = useState<CDispatchWithSeatOccupancy[]>([])
  const [busOperationState, setBusOperationState] =
    useState<Optional<BusOperationState>>(null)
  const [selectedDate, setSelectedDate] = useState<Optional<CDate>>(null)
  const [busList, setBusList] = useState<Optional<CBusNameWithID[]>>(null)

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    if (isNil(busOperationState)) {
      return []
    }

    return busOperationState.filteredBusOperations.map(bo => {
      const node = new Map<string, ReactNode>()
      node.set(TITLE_MAP.busName, <div>{bo.dispatch.name}</div>)
      node.set(
        TITLE_MAP.status,
        <BusStatusContainer colors={bo.status.getBackgroundColor()}>
          {bo.status.exposure}
        </BusStatusContainer>,
      )
      node.set(
        TITLE_MAP.shareRate,
        <div>
          {bo.usedSeat} / {bo.validSeat} ({bo.realSeat})
        </div>,
      )
      return node
    })
  }, [busOperationState])

  const fetchBusOperations = useCallback(
    (data: GetBusOperationsQueryParams) => {
      handleLoading(true)
      getBusOperationsQuery(data)
        .then(bos => {
          setBusOperationState({
            filteredBusOperations: bos,
            originalBusOperations: bos,
          })
          handleLoading(false)
        })
        .catch(error => {
          handleLoading(false)
          throw new Error(
            `failed to get bus operations query. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [listItems],
  )

  const fetchDispatches = useCallback(
    (data: GetDispatchesWithSeatOccupancyParams) => {
      getDispatchesWithSeatOccupancy(data)
        .then(res => setDispatches(res))
        .catch(error => {
          throw new Error(
            `failed to get dispatches with seat occupancy. (data: ${JSON.stringify(
              data,
            )}, error: ${error})`,
          )
        })
    },
    [],
  )

  const onChangeDate = useCallback(
    (date: CDate) => {
      setSelectedDate(date)
      props.onChangeDate(date)

      const boData: GetBusOperationsQueryParams = {
        date: date,
        busId: null,
        academyId: selectedAcademyID,
      }
      fetchBusOperations(boData)

      const dsData = {
        academyId: selectedAcademyID,
        type: OperationTypeEnum.ALL,
        weekday: date.toDay(),
      }
      fetchDispatches(dsData)
    },
    [
      selectedAcademyID,
      fetchBusOperations,
      fetchDispatches,
      props.onChangeDate,
    ],
  )

  const onClickRow = useCallback(
    (idx: number) => {
      const sbo = busOperationState.filteredBusOperations[idx]
      const data: GetBusOperationDetailParams = {
        date: sbo.serviceDate,
        academyId: selectedAcademyID,
        code: sbo.dispatch.code,
      }
      props.fetchBusOperationDetail(data)
    },
    [busOperationState, selectedAcademyID],
  )

  const defaultDate = useMemo(() => {
    return CDate.now()
  }, [])

  const getBusList = useCallback(() => {
    const data: GetBusesData = {
      academyId: selectedAcademyID,
      date: isNil(selectedDate) ? defaultDate : selectedDate,
    }

    getBuses(data)
      .then(res => setBusList(res))
      .catch(error => {
        throw new Error(
          `failed to get bus list. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [selectedDate, selectedAcademyID])

  const buses = useMemo(() => {
    if (isNil(busOperationState)) {
      return []
    }

    return busList?.map(el => el.name)
  }, [busOperationState, selectedAcademyID, busList])

  const busOptions = useMemo(() => {
    if (isEmptyArray(buses)) {
      return []
    }
    return toSelectOptions(
      buses.sort().sort((a, b) => {
        const aNum = parseInt(a.replace(/[^0-9]/g, ''))
        const bNum = parseInt(b.replace(/[^0-9]/g, ''))

        if (aNum === bNum) {
          return a.localeCompare(b)
        }

        return aNum - bNum
      }),
      (b: string) => {
        return b
      },
      true,
    )
  }, [buses])

  const ds = useMemo(() => {
    if (isNil(busOperationState)) {
      return []
    }

    const dss = busOperationState.originalBusOperations.map(bo => {
      return dispatches.find(d => d.dispatch.code === bo.dispatch.code)
    })

    if (dss.some(d => isNil(d))) {
      return []
    }

    return dss
  }, [busOperationState, dispatches])

  const times = useMemo(() => {
    if (isEmptyArray(ds)) {
      return []
    }

    return ds.reduce((acc, curr) => {
      if (!acc.includes(curr.bus.time)) {
        acc.push(curr.bus.time)
      }

      return acc
    }, [])
  }, [ds])

  const timeOptions = useMemo(() => {
    const ts = times.sort()

    if (isEmptyArray(ts)) {
      return []
    }

    return toSelectOptions(ts, t => t, true)
  }, [times])

  const resetSelectBoxes = useCallback(() => {
    if (!busRef.isSecure() || !timeRef.isSecure()) {
      return
    }

    busRef.current().setIdx(0)
    timeRef.current().setIdx(0)
  }, [])

  const onSubmit = useCallback(() => {
    const bus = busRef.current().getValue()
    const time = timeRef.current().getValue()

    const data = {
      busName: isNil(bus) ? null : bus.value,
      time: isNil(time) ? null : time.value,
    }

    const selectBusID: CBusNameWithID[] = busList?.filter(
      el => el.name === bus.value,
    )

    const d: GetBusOperationsQueryParams = {
      date: isNil(selectedDate) ? defaultDate : selectedDate,
      academyId: selectedAcademyID,
      busId: isEmptyArray(selectBusID) ? null : Number(selectBusID[0]?.id),
    }

    getBusOperationsQuery(d)
      .then(bos => {
        setBusOperationState(prev => {
          return {
            originalBusOperations: bos,
            filteredBusOperations: bos.filter(bo => {
              if (isNil(data.busName) && isNil(data.time)) {
                return bo
              }

              if (isNil(data.time)) {
                return bo.bus.name === data.busName
              }

              if (isNil(data.busName)) {
                return (
                  dispatches.find(d => d.dispatch.code === bo.dispatch.code)
                    ?.bus.time === data.time
                )
              }

              return (
                bo.bus?.name === data.busName &&
                dispatches.find(d => d.dispatch.code === bo.dispatch.code)?.bus
                  .time === data.time
              )
            }),
          }
        })
      })
      .catch(error => {
        throw new Error(
          `failed to get bus operations query. (data: ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [
    setBusOperationState,
    selectedDate,
    dispatches,
    selectedAcademyID,
    busList,
  ])

  useEffect(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetBusOperationsQueryParams = {
      date: defaultDate,
      academyId: selectedAcademyID,
      busId: null,
    }

    fetchDispatches({
      academyId: selectedAcademyID,
      type: OperationTypeEnum.ALL,
      weekday: isNil(selectedDate) ? data.date.toDay() : selectedDate.toDay(),
    })

    fetchBusOperations(data)

    resetSelectBoxes()
  }, [selectedAcademyID])

  useEffect(() => {
    busRef.current().setIdx(0)
    timeRef.current().setIdx(0)
  }, [selectedDate])

  useEffect(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    getBusList()
  }, [selectedDate, selectedAcademyID])

  useImperativeHandle(ref, () => ({}), [])

  return (
    <Container>
      <StyledLoading ref={loadingRef} />
      <TopContainer>
        <HeaderContainer>
          <OperationHeader>운행 목록</OperationHeader>
          <DatePicker
            ref={dateRef.ref}
            defaultDate={defaultDate}
            onChange={onChangeDate}
          />
        </HeaderContainer>
        <SearchContainer>
          <BusSelectContainer>
            <BusSelectHeader>버스</BusSelectHeader>
            <BusSelect
              ref={busRef.ref}
              options={busOptions}
              height={3.2}
              fontSize={1.2}
              borderRadius={3}
              boxShadow={false}
            />
          </BusSelectContainer>
          <TimeSelectContainer>
            <TimeSelectHeader>시간</TimeSelectHeader>
            <TimeSelect
              ref={timeRef.ref}
              placeholder={'--:--'}
              options={timeOptions}
              width={7}
              height={3.2}
              fontSize={1.2}
              borderRadius={3}
              boxShadow={false}
            />
          </TimeSelectContainer>
        </SearchContainer>
      </TopContainer>
      <SearchButton onClick={onSubmit}>검색</SearchButton>
      <TableList
        items={listItems}
        keys={Object.values(TITLE_MAP)}
        onClickRow={onClickRow}
        placeholder={'해당 요일에 대한 배차가 없습니다.'}
      />
    </Container>
  )
}

const OperationList = forwardRef<OperationListRef, OperationListProps>(
  OperationListBase,
)
export default OperationList

const Container = styled.div`
  flex: 1;
  background-color: ${color.white};
  width: 100%;
  height: calc(100vh - 4rem);
  ${flexColumn};
  padding: 2rem;
  border-radius: 3rem;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.15);
  position: relative;
`

const BusStatusContainer = styled.div<{
  colors: {color: string; backgroundColor: string}
}>`
  width: 100%;
  height: 2.2rem;
  border-radius: 0.3rem;
  color: ${props => props.colors.color};
  background-color: ${props => props.colors.backgroundColor};
  font-size: 1rem;
  font-weight: 700;
  padding: 0.3rem 0;
  ${flexRow};
  ${justifyCenter};
  ${alignCenter};
`
const BusSelect = styled(Select)``
const TimeSelect = styled(Select)``

const TopContainer = styled.div`
  width: 100%;
  ${flexColumn};
  ${alignCenter};
  row-gap: 1rem;
`

const HeaderContainer = styled.div`
  width: 100%;
  ${flexRow};
  ${alignCenter};
`
const OperationHeader = styled.h2`
  width: 10rem;
  color: #174490;
  font-size: 1.8rem;
`

const SearchContainer = styled.div`
  width: 100%;
  padding: 1.2rem 1.4rem;
  border-radius: 0.4rem;
  background: #f7f7f7;
  column-gap: 1.2rem;
  align-items: center;
  ${flexRow};
  justify-content: space-between;
`

const BusSelectContainer = styled.div`
  ${flexRow};
  align-items: center;
  width: 100%;
`

const TimeSelectContainer = styled.div`
  ${flexRow}
  ${alignCenter};
`

const BusSelectHeader = styled(Header)`
  width: 3rem;
  color: #174490;
  font-size: 1.3rem;
  line-height: 3rem;
`

const TimeSelectHeader = styled(BusSelectHeader)``

const SearchButton = styled.div`
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  background-color: #ffcd00;
  width: 7.2rem;
  min-height: 4rem;
  border-radius: 3rem;
  font-weight: 700;
  font-size: 1.4rem;
  margin: 1.6rem auto 2.8rem;
  cursor: pointer;
`

const StyledLoading = styled(Loading)`
  border-radius: 3rem;
`
