import React from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../../../style/CommonStyle'
import {SelectedBusInfo} from '../table/PeriodTable'

type Props = {
  selectedBusInfo: SelectedBusInfo
  busName: string
}

export default function DispatchEditHeader(props: Props) {
  return (
    <HeaderContainer>
      <HeaderContentContainer>
        <HeaderText>{props.selectedBusInfo.weekday.exposure}요일</HeaderText>
        <EmphasizedHeaderText isStart={props.selectedBusInfo.isStart}>
          {props.selectedBusInfo.classTime}(
          {props.selectedBusInfo.isStart ? '등원' : '하원'})
        </EmphasizedHeaderText>
        <HeaderText> {props.busName} 배차 수정</HeaderText>
      </HeaderContentContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  height: 5.6rem;
  padding: 1.6rem;
  background: #ffffff;
  border-bottom: 0.1rem solid #d9d9d9;
  border-radius: 1.6rem 0 0 0;
`

const HeaderText = styled.h5`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const EmphasizedHeaderText = styled.h5<{isStart: boolean}>`
  color: ${props => (props.isStart ? '#009A17' : '#4b84dd')};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.48px;
`

const HeaderContentContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`
