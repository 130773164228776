import React, {ReactElement} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchPeriodHeaderInfoBase(_: Props): ReactElement {
  return (
    <InfoContainer>
      <InfoTextContainer>
        <Dot>·</Dot>
        <Text style={{marginRight: 2}}>배차</Text>
        <EmphasizedText>당일 등록 및 수정은 불가</EmphasizedText>
        <Text style={{marginRight: 2}}>하며, 등록된 배차 기간은</Text>
        <EmphasizedText>
          운행이 시작 된 이후 삭제 할 수 없습니다.
        </EmphasizedText>
      </InfoTextContainer>
      <InfoTextContainer>
        <Dot>·</Dot>
        <div>
          <Text style={{marginRight: 2}}>
            배차 시작일은 등록일 기준 이전 일로 변경 할 수 없으며, 배차 종료일은
            등록일 보다 뒤로 미룰 수 없습니다.
          </Text>
          <InfoTextContainer>
            <Text style={{marginRight: 2}}>
              단, 시작일을 뒤로 미루거나 종료일을 앞으로 당기는 경우 수정
              가능하며 이때,
            </Text>
            <EmphasizedText>
              변경일 이외의 배차는 모두 삭제 됩니다.
            </EmphasizedText>
          </InfoTextContainer>
        </div>
      </InfoTextContainer>
      <InfoTextContainer>
        <Dot>·</Dot>
        <Text style={{marginRight: 2}}>배차 기간에 배차가 등록된 경우,</Text>
        <EmphasizedText>
          등하원 도착 출발 시간 변경은 할 수 없습니다.
        </EmphasizedText>
      </InfoTextContainer>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  padding: 0.8rem 1.6rem;
  background: #ebebeb;
  ${flexColumn};
  row-gap: 0.3rem;
`

const InfoTextContainer = styled.div`
  ${flexRow};
  align-items: flex-start;
`
const Dot = styled.p`
  font-size: 1.1rem;
  margin-right: 0.6rem;
  color: #999999;
`

const Text = styled.pre`
  color: #999999;
  font-size: 1.1rem;
`

const EmphasizedText = styled.pre`
  color: #ff7500;
  font-size: 1.1rem;
`
