import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import Search from '../../../../../../../asset/image/search.svg'
import PaginationContainer from '../../../../../../common/PaginationContainer'
import styled from 'styled-components'
import {
  alignCenter,
  flexColumn,
  flexRow,
} from '../../../../../../../style/CommonStyle'
import SVGImage from '../../../../../../common/SVGImage'
import TextInput, {TextInputRef} from '../../../../../../input/TextInput'
import {isEmptyArray} from '../../../../../../../util/ValidationUtil'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../../../../recoil/Atom'
import useSecureRef from '../../../../../../../hook/useSecureRef'
import {Optional} from '../../../../../../../type/Common'
import {getDispatchCirculationTime} from '../../../../../../../service/dispatch/Dispatch'
import {SelectedBusInfo} from '../../../table/PeriodTable'
import {CDispatchCirculationTime} from '../../../../../../../model/Dispatch'

const LIMIT = 10
const PAGE_EXPOSE_COUNT = 3

type ClassTimePickerProps = {
  selectedCirculationTIme: Optional<CDispatchCirculationTime>
  selectedBusInfo: SelectedBusInfo
  onChange(c: CDispatchCirculationTime): void
}
export type ClassTimePickerRef = {
  show(): void
}

function ClassTimePickerBase(
  props: ClassTimePickerProps,
  ref: ForwardedRef<ClassTimePickerRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const inputRef = useSecureRef<TextInputRef>('CoursePicker.tsx inputRef')

  const [visible, setVisible] = useState<boolean>(false)
  const [circulationTimes, setCirculationTimes] = useState<
    CDispatchCirculationTime[]
  >([])

  const fetchCirculationTimes = useCallback(
    (aid: string, dispatchCode: string) => {
      getDispatchCirculationTime(aid, dispatchCode)
        .then(ct => setCirculationTimes(ct))
        .catch(error => {
          throw new Error(
            `getDispatchCirculationTime() failed. (academyID: ${aid},dispatchCode: ${dispatchCode}, error: ${error})`,
          )
        })
    },
    [setCirculationTimes],
  )

  const onChangePage = useCallback(
    (page: number) => {
      const keyword = inputRef.current().getValue()
    },
    [academyID, fetchCirculationTimes],
  )

  const onChangeText = useCallback(
    (t: string) => {},
    [academyID, fetchCirculationTimes],
  )

  const onClickCirculationTime = useCallback(
    (c: CDispatchCirculationTime) => {
      props.onChange(c)
      setVisible(false)
    },
    [props.onChange, setVisible],
  )

  const ListComponent = useMemo(() => {
    if (isEmptyArray(circulationTimes)) {
      return (
        <EmptyListContainer>검색 결과가 존재하지 않습니다.</EmptyListContainer>
      )
    }

    return (
      <ListContainer>
        {circulationTimes.map((value, idx) => {
          return (
            <SelectContainer
              key={`${value.dispatchCode}_${idx}`}
              onClick={() => onClickCirculationTime(value)}>
              <TitleContainer>
                <Text
                  selected={
                    props.selectedCirculationTIme?.dispatchTime ===
                    value.dispatchTime
                  }>
                  {value.dispatchTime}
                </Text>
              </TitleContainer>
            </SelectContainer>
          )
        })}
      </ListContainer>
    )
  }, [circulationTimes, props.selectedCirculationTIme, onClickCirculationTime])

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
    }),
    [setVisible],
  )

  useEffect(() => {
    fetchCirculationTimes(
      academyID,
      props.selectedBusInfo.dispatchTable.dispatch.dispatchCode,
    )
  }, [academyID, props.selectedBusInfo])

  if (!visible) {
    return null
  }

  return (
    <Container>
      <ContentContainer>
        <SearchContainer>
          <SearchImage source={Search} />
          <StyledTextInput
            ref={inputRef.ref}
            onChange={onChangeText}
            placeholder={'검색어를 입력해주세요.'}
            readOnly={false}
            required={false}
          />
        </SearchContainer>
        {ListComponent}
      </ContentContainer>
      <PaginationContainer
        totalElementCount={1}
        pageExposeCount={PAGE_EXPOSE_COUNT}
        perPage={LIMIT}
        onChange={onChangePage}
      />
    </Container>
  )
}

const ClassTimePicker = forwardRef(ClassTimePickerBase)
export default ClassTimePicker

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.8rem;
  ${flexColumn};
  background: #ffffff;
  flex: 1;
  z-index: 100;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
`

const ContentContainer = styled.div`
  padding: 0.8rem 0.6rem;
  ${flexColumn};
`

const SearchContainer = styled.div`
  padding: 0.6rem 0.8rem;
  background: #f5f5f5;
  ${flexRow};
  border-bottom: 0.1rem solid #ebebeb;
  border-radius: 0.8rem;
`

const SearchImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`
const StyledTextInput = styled(TextInput)`
  height: 1.9rem;
  border: 0;
  box-shadow: none;
  background: #f5f5f5;

  &::placeholder {
    color: #cccccc;
    font-size: 1.4rem;
  }
`

const ListContainer = styled.div`
  ${flexColumn};
  column-gap: 1.2rem;
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
`

const EmptyListContainer = styled.div`
  flex: 1;
  width: 100%;
  padding: 1.2rem;
  background: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 0 0 0.8rem 0.8rem;
  margin-top: 1rem;
`

const SelectContainer = styled.div`
  ${flexColumn};
  width: 100%;
  padding: 1.2rem 0 1.2rem 1.2rem;
  border-bottom: 0.1rem solid #ebebeb;
  cursor: pointer;
  &:hover {
    background: #fffbe5;
    border-radius: 0.8rem;

    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`

const TitleContainer = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
`

const Text = styled.h1<{selected: boolean}>`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: ${props => (props.selected ? 800 : 500)};
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#332A00')};
`
