import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {isEmptyArray, isNil} from '../../util/ValidationUtil'
import SVGImage from '../common/SVGImage'
import ArrowDown from '../../asset/image/arrowDown.svg'
import ArrowDownDisabled from '../../asset/image/arrowDownGray.svg'

import ArrowUp from '../../asset/image/arrowUp.svg'
import Check from '../../asset/image/checked_yellow.svg'

import {flexColumn, flexRow} from '../../style/CommonStyle'
import {Optional} from '../../type/Common'

export type PickerOption<T> = {
  idx: number
  optionExposure: ReactNode
  boxExposure: ReactNode
  value: Optional<T>
}

export function toPickerOptions<T>(
  values: T[],
  getOptionExposure: (v: T) => ReactNode,
  getBoxExposure: (v: T) => ReactNode,
  allExposure?: {
    getOptionExposure: () => ReactNode
    getBoxExposure: () => ReactNode
  },
): PickerOption<T>[] {
  if (!isNil(allExposure)) {
    return [
      {
        idx: 0,
        optionExposure: allExposure.getOptionExposure(),
        boxExposure: allExposure.getBoxExposure(),
        value: null,
      },
      ...values.map((v, idx) => {
        return {
          idx: idx + 1,
          optionExposure: getOptionExposure(v),
          boxExposure: getBoxExposure(v),
          value: v,
        }
      }),
    ]
  }

  return values.map((v, idx) => {
    return {
      idx: idx,
      optionExposure: getOptionExposure(v),
      boxExposure: getBoxExposure(v),
      value: v,
    }
  })
}

type PickerProps<T> = {
  defaultIdx?: number
  disabledIdx?: Optional<number>
  placeholder?: ReactNode
  boxStyle?: CSSProperties
  extraNode?: ReactNode
  readOnly?: boolean
  minWidth: number
  maxWidth?: number
  height: number
  options: PickerOption<T>[]
  zIndex?: number
  onChange?(value: T): void
}

export type PickerRef<T> = {
  getValue(): PickerOption<T>
  setIdx(value: number): void
}

function PickerBase<T>(
  props: PickerProps<T>,
  ref: ForwardedRef<PickerRef<T>>,
): ReactElement {
  const [idx, setIdx] = useState<Optional<number>>(props.defaultIdx)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const showOptions = useCallback(() => {
    if (props.readOnly) {
      return
    }

    setIsFocused(true)
  }, [setIsFocused, props.readOnly])

  const hideOptions = useCallback(() => {
    setIsFocused(false)
  }, [setIsFocused])

  const onChangeOption = useCallback(
    (idx: number) => {
      setIdx(idx)
      hideOptions()

      if (!isNil(props.onChange)) {
        const o = props.options[idx]
        if (isNil(o)) {
          return
        }
        props.onChange(o.value)
      }
    },
    [props.options, hideOptions, props.onChange],
  )

  const handleClickOption = useCallback(
    (idx: number) => {
      if (idx === props.disabledIdx) {
        return
      }

      onChangeOption(idx)
    },
    [props.disabledIdx, onChangeOption],
  )

  const boxExposure = useMemo(() => {
    const selectedOption = props.options[idx]
    if (!isNil(selectedOption)) {
      return <BoxNode>{selectedOption.boxExposure}</BoxNode>
    }

    if (!isNil(props.placeholder)) {
      return <Placeholder>{props.placeholder}</Placeholder>
    }

    return <Placeholder>선택해주세요.</Placeholder>
  }, [props.options, idx, props.placeholder])

  const BoxComponent = useMemo(() => {
    return (
      <BoxContainer
        onClick={showOptions}
        hasValue={!isNil(idx)}
        style={props.boxStyle}
        minWidth={props.minWidth}
        height={props.height}
        zIndex={props.zIndex}
        readOnly={props.readOnly}>
        <BoxNodeContainer>
          {props.extraNode}
          {boxExposure}
        </BoxNodeContainer>
        <Arrow source={props.readOnly ? ArrowDownDisabled : ArrowDown} />
      </BoxContainer>
    )
  }, [
    showOptions,
    idx,
    props.extraNode,
    props.zIndex,
    props.boxStyle,
    props.readOnly,
    boxExposure,
  ])

  const FocusedBoxComponent = useMemo(() => {
    if (isFocused) {
      return (
        <FocusedBoxContainer
          hasValue={!isNil(idx)}
          minWidth={props.minWidth}
          height={props.height}
          zIndex={props.zIndex}
          onClick={e => {
            hideOptions()
            e.stopPropagation()
          }}>
          <BoxNodeContainer>
            {props.extraNode}
            {boxExposure}
          </BoxNodeContainer>
          <Arrow source={ArrowUp} />
        </FocusedBoxContainer>
      )
    }

    return null
  }, [isFocused, hideOptions, props.extraNode, props.zIndex, boxExposure, idx])

  const OptionsComponent = useMemo(() => {
    if (isFocused) {
      return (
        <OptionContainer
          zIndex={props.zIndex}
          height={props.height}
          onBlur={hideOptions}>
          {props.options.map(option => {
            const selected = idx === option.idx

            return (
              <OptionContentContainer
                onClick={_ => {
                  handleClickOption(option.idx)
                }}
                key={option.idx}>
                <Option
                  minWidth={props.minWidth}
                  height={props.height}
                  disabled={option.idx === props.disabledIdx}
                  selected={selected}>
                  {option.optionExposure}
                </Option>
                {selected ? <CheckImage source={Check} /> : null}
              </OptionContentContainer>
            )
          })}
        </OptionContainer>
      )
    }

    return null
  }, [
    idx,
    isFocused,
    hideOptions,
    onChangeOption,
    handleClickOption,
    props.options,
    props.zIndex,
    props.disabledIdx,
  ])

  useImperativeHandle(
    ref,
    () => ({
      getValue(): PickerOption<T> {
        return props.options[idx]
      },
      setIdx(value: number): void {
        setIdx(value)
      },
    }),
    [props.options, idx, setIdx],
  )

  useEffect(() => {
    setIdx(props.defaultIdx)
  }, [props.defaultIdx])

  if (isEmptyArray(props.options)) {
    return null
  }

  return (
    <Container tabIndex={1}>
      {BoxComponent}
      {FocusedBoxComponent}
      {OptionsComponent}
    </Container>
  )
}

const Picker = forwardRef(PickerBase)
export default Picker

const Container = styled.div`
  position: relative;
`

const BoxContainer = styled.div<{
  hasValue: boolean
  readOnly: boolean
  minWidth: number
  height: number
  zIndex?: number
}>`
  position: relative;
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background: ${props =>
    props.readOnly ? '#f5f5f5' : props.hasValue ? '#fffbe5' : '#ffffff'};
  border: 0.1rem solid #ebebeb;
  padding: 0.6rem;
  min-width: ${props => props.minWidth}px;
  height: ${props => props.height}px;
  border-radius: 0.8rem;
  z-index: ${props => (props.zIndex ? props.zIndex : 1)};
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'pointer')};
`

const FocusedBoxContainer = styled.div<{
  hasValue: boolean
  minWidth: number
  height: number
  zIndex?: number
}>`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
  background: ${props => (props.hasValue ? '#fffbe5' : '#ffffff')};
  padding: 0.6rem;
  min-width: ${props => props.minWidth}px;
  height: ${props => props.height}px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border: 0.1rem solid #fff183;
  z-index: ${props => (props.zIndex ? props.zIndex : 10)};
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
`

const Arrow = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const BoxNodeContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const BoxNode = styled.div`
  ${flexRow};
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
`

const Placeholder = styled(BoxNode)`
  color: #cccccc;
  font-size: 1.2rem;
`

const OptionContainer = styled.div<{height: number; zIndex: number}>`
  ${flexColumn};
  position: absolute;
  top: ${props => props.height + 2}px;
  row-gap: 0.8rem;
  padding: 0.8rem 0.6rem;
  background: #ffffff;
  width: 100%;
  max-height: 50rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #fff183;
  cursor: pointer;
  z-index: ${props => (props.zIndex ? props.zIndex : 10)};
  overflow: auto;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);

  ::-webkit-scrollbar {
    display: none;
  }
`

const OptionContentContainer = styled.div`
  ${flexRow};
  justify-content: space-between;
  align-items: center;

  :hover {
    background: #fffbe5;
    border-radius: 0.8rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  }
`
const Option = styled.div<{
  selected: boolean
  disabled: boolean
  minWidth: number
  height: number
}>`
  min-width: ${props => props.minWidth}px;
  height: ${props => props.height}px;
  ${flexRow};
  font-size: 1.6rem;
  color: ${props =>
    props.selected ? '#665300' : props.disabled ? '#999' : '#000000'};
  font-weight: ${props => (props.selected ? 700 : 500)};
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const CheckImage = styled(SVGImage)`
  width: 1.5rem;
  height: 1.5rem;
`
