import {atom} from 'recoil'
import {RecoilKeyEnum} from './Key'
import {Optional} from '../type/Common'
import {ACADEMY_ID} from '../Env'
import {isEmptyString} from '../util/ValidationUtil'
import {CAcademy} from '../model/Academy'

export const isSignedInState = atom<boolean>({
  key: RecoilKeyEnum.IS_SIGNED_IN.value,
  default: true,
})

export const academyIDState = atom<Optional<string>>({
  key: RecoilKeyEnum.SELECTED_ACADEMY.value,
  default: isEmptyString(ACADEMY_ID) ? null : ACADEMY_ID,
  // default: null,
})

export const academyState = atom<Optional<CAcademy>>({
  key: RecoilKeyEnum.ACADEMY.value,
  default: null,
})
