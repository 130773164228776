import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {color} from '../../style/CommonStyle'

type PopUpProps = {
  contents?: ReactElement
  children?: ReactNode
  width?: string
  height?: string
  top?: string
  right?: string
  left?: string
}
export type PopUpRef = {
  show(): void
  hide(): void
}

function HalfPopUpBase(
  props: PopUpProps,
  ref: ForwardedRef<PopUpRef>,
): ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const show = useCallback(() => setIsVisible(true), [])
  const hide = useCallback(() => setIsVisible(false), [])

  useImperativeHandle(
    ref,
    () => ({
      show: show,
      hide: hide,
    }),
    [show, hide],
  )

  return (
    <Container isVisible={isVisible}>
      <BackgroundLayer />
      <Contents
        width={props.width}
        height={props.height}
        top={props.top}
        right={props.right}>
        <div>
          {props.contents}
          {props.children}
        </div>
      </Contents>
    </Container>
  )
}

const HalfPopup = forwardRef(HalfPopUpBase)
export default HalfPopup

type ContainerProps = {
  isVisible: boolean
}

const Container = styled.div<ContainerProps>`
  display: ${({isVisible: isVisible}) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  padding: 0;
`

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`

type ContentsProps = {
  width: string
  height: string
  top: string
  right?: string
  left?: string
}

const Contents = styled.div<ContentsProps>`
  width: ${props => (props.width ? props.width : '50%')};
  height: ${props => (props.height ? props.height : '50%')};
  position: absolute;
  top: ${props => (props.top ? props.top : '20%')};
  right: ${props => (props.right ? props.right : '20%')};
  left: ${props => (props.left ? props.left : 'none')};
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  background-color: ${color.white};
  z-index: 100;
  border-radius: 1.2rem;
`
