import React, {ReactElement, useCallback, useEffect, useState} from 'react'
import LeftArrow from '../../../../asset/image/left_arrow_black.svg'
import DatePicker from '../../../input/DatePicker'
import {CDate} from '../../../../model/Date'
import RightArrow from '../../../../asset/image/right_arrow_black.svg'
import {CDateTime} from '../../../../model/DateTime'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import SVGImage from '../../../common/SVGImage'
import {BaseDateUnitEnum} from '../../../../enum/DateUnitEnum'

type Props = {
  date: CDate
  startDate: CDate
  endDate: CDate
  onChangeDate(d: CDate): void
}

export default function DateHeader(props: Props): ReactElement {
  const [dispatchDate, setDispatchDate] = useState<CDate>(props.date)

  const onClickPrevDay = useCallback(() => {
    setDispatchDate(prev => {
      const prevDay = prev.add(-1, BaseDateUnitEnum.DAY)

      props.onChangeDate(prevDay)

      return prevDay
    })
  }, [setDispatchDate, props.onChangeDate])

  const onClickNextDay = useCallback(() => {
    setDispatchDate(prev => {
      const nextDay = prev.add(+1, BaseDateUnitEnum.DAY)
      props.onChangeDate(nextDay)

      return nextDay
    })
  }, [setDispatchDate, props.onChangeDate])

  const onClickToday = useCallback(() => {
    const now = CDate.now()
    setDispatchDate(now)
    props.onChangeDate(now)
  }, [props.onChangeDate, props.startDate, props.endDate])

  const onChangeDate = useCallback(
    (d: CDate) => {
      setDispatchDate(d)
      props.onChangeDate(d)
    },
    [setDispatchDate, props.onChangeDate],
  )

  return (
    <DateWrapper>
      <ImgWrapper onClick={onClickPrevDay}>
        <Img source={LeftArrow} />
      </ImgWrapper>
      <Date>
        <DatePicker
          onChange={onChangeDate}
          theme={'blue'}
          defaultDate={dispatchDate}
          minDate={props.startDate}
          maxDate={props.endDate}
        />
      </Date>
      <ImgWrapper onClick={onClickNextDay}>
        <Img source={RightArrow} />
      </ImgWrapper>
      <TodayButton onClick={onClickToday}>오늘</TodayButton>
    </DateWrapper>
  )
}

const DateWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.6rem;
`

const ImgWrapper = styled.div``

const Img = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const Date = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.2rem;
`

const TodayButton = styled.button`
  border: 0.1rem solid #ebebeb;
  border-radius: 3.4rem;
  background: #fff;
  padding: 0.4rem 0.8rem;

  :hover {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  }
`
