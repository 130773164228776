import React from 'react'
import InputHeader from '../../../../../../input/InputHeader'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../style/CommonStyle'

type Props = {
  busName: string
  onClickEdit(): void
  onClickDelete(): void
}

export default function BusInfoExtra(props: Props) {
  return (
    <Container>
      <InputHeaderContainer>
        <InputHeader header={'운행 호차'} required={false} />
        <ButtonContainer>
          <EditButton onClick={props.onClickEdit}>수정</EditButton>
          <DeleteButton onClick={props.onClickDelete}>삭제</DeleteButton>
        </ButtonContainer>
      </InputHeaderContainer>
      <TextBox>{props.busName}</TextBox>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 0.4rem;
  height: 7.4rem;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 17.6rem;
`

const InputHeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
`

const EditButton = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 16px;
  border: 0.05rem solid #2767ce;
  background: #fff;
  color: #2767ce;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 133.333% */
  letter-spacing: -0.27px;
  cursor: pointer;
`

const DeleteButton = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 16px;
  background: #333;
  color: #fff1b3;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 133.333% */
  cursor: pointer;
`

const TextBox = styled.div`
  width: 100%;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  padding-left: 0.8rem;
  border-radius: 0.6rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  ${flexRow};
  align-items: center;
  background: #fffbe5;

  &::placeholder {
    color: #cccccc;
  }
`
