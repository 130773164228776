import React, {ReactElement} from 'react'
import CloseButton from '../../../asset/image/close_background_grey.svg'
import {CStudent} from '../../../model/Dispatch'
import styled from 'styled-components'
import {
  alignCenter,
  color,
  flexColumn,
  flexRow,
  justifyCenter,
} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import {isNil} from '../../../util/ValidationUtil'

type Props = {
  selectedStudents: CStudent[]
  onClickCancel(id: number): void
}

export default function StudentListSelected(props: Props): ReactElement {
  return (
    <SelectedContainer>
      {props.selectedStudents.map((ss, idx) => {
        return (
          <SelectedBox key={`${ss.id}_${ss.name}_${idx}`}>
            <SelectedContent>
              {ss.nickName === '-' ? '' : ss.nickName + ' /'}{' '}
              {`${ss?.name} ${
                !isNil(ss.parentPhone) ? ` (${ss.parentPhone?.slice(-4)})` : ''
              }`}
            </SelectedContent>
            <SmallCancelButton onClick={() => props.onClickCancel(ss.id)}>
              <CloseImg source={CloseButton} />
            </SmallCancelButton>
          </SelectedBox>
        )
      })}
    </SelectedContainer>
  )
}

const SelectedContainer = styled.div`
  ${flexColumn};
  row-gap: 0.8rem;
  margin-top: 0.8rem;
`

const SelectedBox = styled.div`
  ${flexRow};
  ${alignCenter};
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  height: 3rem;
  border: 0.1rem solid ${color.grey2};
  border-radius: 0.4rem;
`

const SelectedContent = styled.p`
  font-size: 1.2rem;
`

const SmallCancelButton = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  ${flexRow};
  ${alignCenter};
  ${justifyCenter};
  color: ${color.white};
  background-color: ${color.grey2};
  border-radius: 10rem;
  cursor: pointer;
`

const CloseImg = styled(SVGImage)`
  width: 100%;
  height: 100%;
`
