import React, {ForwardedRef, forwardRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Optional} from '../../../type/Common'
import ScreenContainer from '../../layout/ScreenContainer'
import PageHeaderWithAcademies from '../../common/PageHeader'
import {flexColumn} from '../../../style/CommonStyle'
import ManagerBasicInfo, {DriverNameRef} from '../driverInfo/DriverBasicInfo'
import useSecureRef from '../../../hook/useSecureRef'
import BackArrow from '../../../asset/image/back_page.svg'
import SVGImage from '../../common/SVGImage'
import {useNavigate} from 'react-router'
import ManagerBuses from '../driverInfo/DriverBuses'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'

type DriverDetailProps = {}

export type DriverDetailRef = {}

function DriverDetailBase(
  props: DriverDetailProps,
  ref: ForwardedRef<DriverDetailRef>,
) {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const navigate = useNavigate()
  const nameRef = useSecureRef<DriverNameRef>('[DriverDetail.tsx] nameRef')

  // TODO: ref로 받아오는 로직으로 변경필요
  const [driverName, setDriverName] = useState<Optional<string>>(null)

  useEffect(() => {
    if (selectedAcademyID) {
      localStorage.setItem('academyID', selectedAcademyID)
    }
  }, [])

  useEffect(() => {
    const savedAcademyID = localStorage.getItem('academyID')

    if (selectedAcademyID !== savedAcademyID) {
      navigate('/driver')
    }
  }, [selectedAcademyID])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>
        <ImgWrapper onClick={() => navigate(-1)}>
          <BackImg source={BackArrow} />
        </ImgWrapper>
        <DriverName>{driverName}</DriverName> 기사님 상세 정보
      </PageHeaderWithAcademies>
      <Container>
        <ManagerBasicInfo ref={nameRef.ref} setDriverName={setDriverName} />
        <ManagerBuses />
      </Container>
    </ScreenContainer>
  )
}

const DriverDetail = forwardRef(DriverDetailBase)
export default DriverDetail

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 13.8rem);
`

const DriverName = styled.span`
  color: #2767ce;
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 3rem;
`

const ImgWrapper = styled.div``

const BackImg = styled(SVGImage)`
  width: 2.4rem;
  height: 2.4rem;
`
