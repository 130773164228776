import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import useSecureRef from '../../../../hook/useSecureRef'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import DispatchPeriodInputContainer, {
  DispatchPeriodInputContainerRef,
  PeriodInformation,
} from '../manage/DispatchPeriodInputContainer'
import {
  CDispatchPeriod,
  CDispatchPeriodExist,
} from '../../../../model/DispatchPeriod'
import {Optional} from '../../../../type/Common'
import {
  isEmptyArray,
  isEmptyString,
  isNil,
} from '../../../../util/ValidationUtil'
import {
  getDispatchPeriodExists,
  putDispatchPeriod,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {alertError} from '../../../../util/ErrorUtil'
import DispatchPeriodHeaderInfo from '../manage/DispatchPeriodHeaderInfo'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'
import Header from '../../../common/Header'
import {CDate} from '../../../../model/Date'
import DispatchPeriodEditExistModal, {
  DispatchPeriodEditExistModalRef,
} from './DispatchPeriodEditExistModal'
import Footer from '../../../common/Footer'
import {WeekdayEnum} from '../../../../enum/WeekdayEnum'
import {
  getSemesterTime,
  GetSemesterTimeData,
} from '../../../../service/semester/Semester'

type Props = {
  dispatchPeriod: CDispatchPeriod
  onSubmit(): void
  onCancel(): void
  handleLoading(isLoading: boolean): void
}

export default function DispatchPeriodEdit(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodEdit.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodEdit.tsx] confirmModalRef',
  )
  const existModalRef = useSecureRef<DispatchPeriodEditExistModalRef>(
    '[DispatchPeriodEdit.tsx] existModalRef',
  )
  const inputContainerRef = useSecureRef<DispatchPeriodInputContainerRef>(
    '[DispatchPeriodEdit.tsx] inputContainerRef',
  )

  const [isSufficient, setIsSufficient] = useState<boolean>(true)
  const [selectableWeekdays, setSelectableWeekdays] = useState<WeekdayEnum[]>(
    [],
  )

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const getConfirmModalContent = useCallback(
    (endDate: CDate, dp: CDispatchPeriod) => {
      if (endDate.isAfter(dp.dispatchPeriod.endDate)) {
        return `배차 종료일을 ${endDate.toString()}일로
수정 하시겠습니까?
(배차에 등록된 모든 승차권도 ${endDate.toString()}일까지 
연장됩니다.)`
      }

      return `배차 종료일을 ${endDate}일로
수정하시겠습니까?
(${endDate}일 이후의 배차 및 승차권이 삭제됩니다.)`
    },
    [],
  )

  const editDispatchPeriod = useCallback(() => {
    props.handleLoading(true)
    const data = {
      ...inputContainerRef.current().getInputValues(),
      semesterID: props.dispatchPeriod.dispatchPeriod.semesterID,
    }

    putDispatchPeriod(academyID, props.dispatchPeriod.dispatchPeriod.id, data)
      .then(() => {
        props.onSubmit()
        props.onCancel()
        props.handleLoading(false)
      })
      .catch(error => {
        props.handleLoading(false)
        alertError(
          error,
          `patchDispatchPeriod() failed. (error: ${error}, aid:${academyID}, did:${
            props.dispatchPeriod.dispatchPeriod.id
          } data: ${JSON.stringify(data)})`,
        )
      })
  }, [props.dispatchPeriod, academyID, props.onSubmit])

  const onPressEditButton = useCallback(async () => {
    if (!isSufficient) {
      alert('필수 항목을 입력해주세요!')
      return
    }

    const d = inputContainerRef.current().getInputValues()
    if (isNil(d)) {
      alert('필수 항목을 입력해주세요.')
      return
    }

    let dpes: CDispatchPeriodExist[] = []
    try {
      dpes = await getDispatchPeriodExists(academyID, {
        startDate: d.startDate,
        endDate: d.endDate,
        dispatchPeriodID: props.dispatchPeriod.dispatchPeriod.id,
      })
    } catch (error) {
      alertError(
        error,
        `getDispatchPeriodExists() failed. (error: ${error}, aid: ${academyID})`,
      )
    }

    if (isEmptyArray(dpes)) {
      const content = getConfirmModalContent(d.endDate, props.dispatchPeriod)
      confirmModalRef.current().setContent(content)
      confirmModalRef.current().show()
      return
    }

    const content = `중복된 배차 기간명
    ${dpes
      .map(
        dpe =>
          `${
            dpe.dispatchPeriodName
          }(${dpe.startDate.toString()}~${dpe.endDate.toString()})`,
      )
      .join('\n')}`
    existModalRef.current().setPeriodText(content)
    existModalRef.current().show()
  }, [isSufficient, getConfirmModalContent, props.dispatchPeriod])

  const cancelEditDispatchPeriod = useCallback(() => {
    props.onCancel()
  }, [props.onCancel])

  const onChangeInformation = useCallback(
    (pi: Optional<PeriodInformation>) => {
      if (isNil(pi)) {
        setIsSufficient(false)
        return
      }

      setIsSufficient(
        !isEmptyString(pi.name) &&
          !isNil(pi.startDate) &&
          !isNil(pi.endDate) &&
          !isNil(pi.inboundBeforeMinute) &&
          !isNil(pi.outboundAfterMinute) &&
          !isEmptyArray(pi.weekdays),
      )
    },
    [setIsSufficient],
  )

  const defaultInputData = useMemo((): PeriodInformation => {
    if (isNil(props.dispatchPeriod)) {
      return null
    }

    return {
      name: props.dispatchPeriod.dispatchPeriod.name,
      startDate: props.dispatchPeriod.dispatchPeriod.startDate,
      endDate: props.dispatchPeriod.dispatchPeriod.endDate,
      inboundBeforeMinute: props.dispatchPeriod.operation.inboundBeforeMinute,
      outboundAfterMinute: props.dispatchPeriod.operation.outboundAfterMinute,
      weekdays: props.dispatchPeriod.operation.weekdays,
    }
  }, [props.dispatchPeriod])

  const fetchSemestersTime = useCallback(
    (data: GetSemesterTimeData) => {
      getSemesterTime(data)
        .then(st => {
          setSelectableWeekdays(st.map(el => el.weekday))
        })
        .catch(error => {
          throw new Error(
            `getSemestersTime() failed. (aid: ${data.academyID}, sid: ${data.semesterID}, error: ${error}))`,
          )
        })
    },
    [setSelectableWeekdays],
  )

  useEffect(() => {
    if (isNil(props.dispatchPeriod)) {
      return
    }

    fetchSemestersTime({
      academyID: academyID,
      semesterID: props.dispatchPeriod.dispatchPeriod.semesterID,
    })
  }, [academyID, props.dispatchPeriod])

  return (
    <Container>
      <DispatchPeriodEditExistModal ref={existModalRef.ref} />
      <ConfirmModal
        ref={confirmModalRef.ref}
        header={'배차 기간 수정'}
        content={''}
        onSubmit={editDispatchPeriod}
      />
      <ConfirmModal
        ref={cancelConfirmModalRef.ref}
        header={'배차 기간 수정 취소'}
        content={
          '작성중인 내용은 저장되지 않습니다.\n배차 기간 수정을 취소 하시겠습니까?'
        }
        onSubmit={cancelEditDispatchPeriod}
      />

      <StyledHeader>배차 기간 수정</StyledHeader>
      <DispatchPeriodHeaderInfo />
      <InputsContainer>
        <DispatchPeriodInputContainer
          ref={inputContainerRef.ref}
          selectedSemester={null}
          hasData={true}
          readOnly={
            !isNil(defaultInputData) &&
            defaultInputData.startDate.isBefore(CDate.now())
          }
          defaultData={defaultInputData}
          selectableWeekdays={selectableWeekdays}
          onChangeInformation={onChangeInformation}
        />
      </InputsContainer>
      <Footer
        submitText={'수정'}
        onSubmit={onPressEditButton}
        onCancel={showCancelConfirmModal}
        isSufficient={isSufficient}
      />
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InputsContainer = styled.div`
  flex: 1;
  padding: 1.6rem;
`
