import React from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../../style/CommonStyle'
import {CDispatchCourseWithStudent} from '../../../../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../../../../enum/OccupancyTypeEnum'
import CourseItem from '../../common/CourseItem'

type Props = {
  busName: string
  courses: CDispatchCourseWithStudent[]
  occupancyType: OccupancyTypeEnum
}

export default function CourseBox(props: Props) {
  return (
    <StationSelectBox>
      <StationSelectHeader>{props.busName}</StationSelectHeader>
      <ContentContainer>
        {props.courses.map((course, idx) => {
          return (
            <CourseItem
              key={idx}
              course={course}
              occupancyType={props.occupancyType}
              isFirstItem={idx === 0}
              isLastItem={idx === props.courses.length - 1}
              readOnly={false}
            />
          )
        })}
      </ContentContainer>
    </StationSelectBox>
  )
}

const StationSelectBox = styled.div`
  ${flexColumn};
  border-radius: 0.8rem;
  flex: 1;
  background: #ffffff;
`

const StationSelectHeader = styled.div`
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #ebebeb;
  background: #332a00;
  height: 4.4rem;
  ${flexRow};
  align-items: center;
  padding-left: 1.2rem;
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const ContentContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  flex: 1;
  background: #f5f5f5;
`
