import React, {ReactElement, useCallback} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../../style/CommonStyle'
import Plus from '../../../../asset/image/plus_icon.svg'
import SVGImage from '../../../common/SVGImage'
import {useRecoilValue} from 'recoil'
import {academyState} from '../../../../recoil/Atom'

type Props = {
  showAddBusModal(): void
}

export default function DispatchBusSettingListHeaderBase(
  props: Props,
): ReactElement {
  const academy = useRecoilValue(academyState)

  const onClickButton = useCallback(() => {
    props.showAddBusModal()
  }, [props.showAddBusModal])

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>호차 선택</HeaderText>
        <AcademyName>등록 학원 정보 : {academy.name}</AcademyName>
      </HeaderContainer>
      <BusesSettingButton onClick={onClickButton}>
        <SettingImage source={Plus} />
        <SettingText>호차 등록</SettingText>
      </BusesSettingButton>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
`
const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`
const HeaderText = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const AcademyName = styled.div`
  color: #3575d9;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const BusesSettingButton = styled.button`
  border: none;
  border-radius: 3.4rem;
  width: 8.4rem;
  height: 2.6rem;
  ${flexRow};
  column-gap: 0.2rem;
  justify-content: center;
  align-items: center;
  background: #ffcd00;
`

const SettingImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const SettingText = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`
