import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import TextInput, {TextInputRef} from './TextInput'
import useSecureRef from '../../hook/useSecureRef'
import {Optional} from '../../type/Common'
import {isEmptyString, isNil} from '../../util/ValidationUtil'

type NumberInputProps = {
  readOnly: boolean
  required: boolean
  default?: number
  resetOff?: boolean
  onChange(value: Optional<number>): void
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue' | 'onChange'>
export type NumberInputRef = {
  getValue(): Optional<number>
  setValue(t: number): void
  reset(): void
}

function NumberInputBase(
  props: NumberInputProps,
  ref: ForwardedRef<NumberInputRef>,
): ReactElement {
  const inputRef = useSecureRef<TextInputRef>('[NumberInput.tsx] inputRef')

  const onChange = useCallback(
    (t: string) => {
      const n = Number(t)

      if (isNaN(n) || isEmptyString(t)) {
        props.onChange(null)
        const numericValue = t.replace(/[^0-9]/g, '')
        inputRef.current().setValue(numericValue)
        // inputRef.current().setValue('')
        return
      }

      props.onChange(n)
    },
    [props.onChange],
  )

  const defaultValue = useMemo(() => {
    if (isNil(props.default)) {
      return ''
    }

    return String(props.default)
  }, [props.default])

  useImperativeHandle(ref, () => ({
    getValue(): Optional<number> {
      const v = inputRef.current().getValue()
      const n = Number(v)

      if (isNaN(n) || isEmptyString(v)) {
        return null
      }

      return n
    },

    setValue(n: number): void {
      const t = String(n)

      inputRef.current().setValue(t)
    },
    reset() {
      inputRef.current().reset()
    },
  }))

  return (
    <TextInput
      ref={inputRef.ref}
      {...props}
      readOnly={props.readOnly}
      required={props.required}
      default={defaultValue}
      resetOff={props.resetOff}
      onChange={onChange}
    />
  )
}

const NumberInput = forwardRef(NumberInputBase)
export default NumberInput
