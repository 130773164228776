import React, {useCallback} from 'react'
import {CStudentWithCheck} from '../../../../../../model/Dispatch'
import styled from 'styled-components'
import {flexRow} from '../../../../../../style/CommonStyle'
import CheckBox from '../../../../../common/CheckBox'

type Props = {
  student: CStudentWithCheck
  isChecked: boolean
  readOnly: boolean
  onChangeStudent(s: CStudentWithCheck): void
}

export default function StudentItem(props: Props) {
  const onClick = useCallback(() => {
    if (props.readOnly) {
      return
    }

    props.onChangeStudent(props.student)
  }, [props.readOnly, props.onChangeStudent])

  return (
    <Container onClick={onClick}>
      <CheckBox readOnly={props.readOnly} isChecked={props.isChecked} />
      <Name readOnly={props.readOnly} isChecked={props.isChecked}>
        {props.student.name}
      </Name>
      <Phone readOnly={props.readOnly} isChecked={props.isChecked}>
        ({props.student.nickName}
        {props.student.parentPhone.slice(9)})
      </Phone>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.4rem;
  cursor: pointer;
`

const Name = styled.div<{isChecked: boolean; readOnly: boolean}>`
  color: ${props =>
    props.readOnly ? '#cccccc' : props.isChecked ? '#000000' : '#999999'};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const Phone = styled.div<{isChecked: boolean; readOnly: boolean}>`
  color: ${props =>
    props.readOnly ? '#cccccc' : props.isChecked ? '#000000' : '#999999'};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
