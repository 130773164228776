import React, {ForwardedRef, forwardRef, ReactElement, useCallback} from 'react'
import OperationDetail, {
  OperationDetailRef,
} from '../../component/operationControl/opertaionDetail/OperationDetail'
import useSecureRef from '../../hook/useSecureRef'
import {GetBusOperationDetailParams} from '../../service/busOperation/BusOperation'
import styled from 'styled-components'
import {media} from '../../style/media'
import NavigationMap, {
  NavigationMapRef,
} from '../../component/common/NavigationMap'
import {CDate} from '../../model/Date'
import ScreenContainer from '../../component/layout/ScreenContainer'
import OperationList from '../../component/operationControl/operationList/OperationList'

type OperationControlProps = {}
export type OperationControlRef = {}

function OperationControlBase(
  _: OperationControlProps,
  __: ForwardedRef<OperationControlRef>,
): ReactElement {
  const detailRef = useSecureRef<OperationDetailRef>(
    '[OperationControl.tsx], detailRef',
  )
  const mapRef = useSecureRef<NavigationMapRef>('[OperationControl.tsx] mapRef')

  const fetchBusOperationDetail = useCallback(
    (data: GetBusOperationDetailParams) => {
      detailRef.current().getBusOperationDetailParams(data)
      mapRef.current().getOperationDetailParams(data)
    },
    [],
  )

  const onChangeDate = useCallback((date: CDate) => {
    mapRef.current().getOperationDetailParams(null)
    detailRef.current().getBusOperationDetailParams(null)
    detailRef.current().setDate(date)
  }, [])

  return (
    <ScreenContainer>
      {/* <PageHeaderWithAcademies>버스 운행 관제</PageHeaderWithAcademies> */}

      <Container>
        <OperationList
          onChangeDate={onChangeDate}
          fetchBusOperationDetail={fetchBusOperationDetail}
        />
        <OperationDetail ref={detailRef.ref} />
        <NavigationMap ref={mapRef.ref} />
      </Container>
    </ScreenContainer>
  )
}

const OperationControl = forwardRef(OperationControlBase)
export default OperationControl

const Container = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 43rem 43rem 1fr;
  column-gap: 2rem;
  justify-content: space-between;

  ${media.desktop} {
    flex-wrap: nowrap;
  }
`
