import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import DispatchBusSettingListHeader from './DispatchBusSettingListHeader'
import {getBuses} from '../../../../service/buses/Buses'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {CBus, CBusVector} from '../../../../model/Bus'
import {Optional} from '../../../../type/Common'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import TableList, {TableListRef} from '../../../common/TableList'
import Checked from '../../../../asset/image/checked_brown.svg'
import useSecureRef from '../../../../hook/useSecureRef'
import {CDispatchPeriodBusVector} from '../../../../model/DispatchPeriod'

const TITLE_MAP = {
  name: '호차',
  regNum: '차량번호',
  driver: '운행 기사',
  validSeat: '가용 좌석',
  transportCompany: '운수사',
}

type Props = {
  showAddBusModal(): void
  onChange(isSufficient: boolean): void
  defaultBusVectors: CDispatchPeriodBusVector[]
}
export type DispatchBusSettingListRef = {
  fetchBuses(): void
  getSelectedBuses(): CBus[]
}

function DispatchBusSettingListBase(
  props: Props,
  ref: ForwardedRef<DispatchBusSettingListRef>,
): ReactElement {
  const academyID = useRecoilValue(academyIDState)

  const listRef = useSecureRef<TableListRef>(
    '[DispatchBusSettingList.tsx] listRef',
  )

  const [busVector, setBusVector] = useState<Optional<CBusVector>>(null)

  const fetchBuses = useCallback(() => {
    const data = {
      academyID: academyID,
      size: 1000,
    }

    getBuses(data)
      .then(bv => setBusVector(bv))
      .catch(error => {
        throw new Error(
          `getBuses() failed. (aid: ${academyID}, error: ${error})`,
        )
      })
  }, [academyID])

  const onChangeIndices = useCallback(
    (indices: number[]) => {
      props.onChange(!isEmptyArray(indices))
    },
    [props.onChange],
  )

  const listItems = useMemo((): Map<string, ReactNode>[] => {
    if (isNil(busVector)) {
      return null
    }

    return busVector.buses.map(b => {
      const node = new Map<string, ReactNode>()
      const driverInfo = isEmptyArray(b.drivers)
        ? '-'
        : `${b.drivers[0].name}(${b.drivers[0].phone})`

      node.set(TITLE_MAP.name, <TableText>{b.name}</TableText>)
      node.set(TITLE_MAP.regNum, <TableText>{b.regNum}</TableText>)
      node.set(TITLE_MAP.driver, <TableText>{driverInfo}</TableText>)
      node.set(TITLE_MAP.validSeat, <TableText>{b.totalSeat}</TableText>)
      node.set(
        TITLE_MAP.transportCompany,
        <TableText>{b.transportCompany}</TableText>,
      )
      return node
    })
  }, [busVector])
  const tableContainerStyle = useMemo(() => {
    return {borderRadius: 8, border: '0.1rem solid #ebebeb'}
  }, [])

  const tableStyle = useMemo(() => {
    return {border: 'none', borderRadius: 8}
  }, [])

  const tableHeaderStyle = useMemo(() => {
    return {background: '#f5f5f5', height: 36}
  }, [])

  const tableBodyStyle = useMemo(() => {
    return {background: '#ffffff', height: 36}
  }, [])

  const checkBoxStyle = useMemo(() => {
    return {
      width: '1.4rem',
      height: '1.4rem',
    }
  }, [])

  const defaultIndices = useMemo(() => {
    if (isNil(busVector) || isNil(props.defaultBusVectors)) {
      return []
    }

    return busVector.buses.reduce((acc, curr, idx) => {
      if (props.defaultBusVectors.some(bv => bv.bus.id === curr.id)) {
        acc.push(idx)
      }

      return acc
    }, [])
  }, [props.defaultBusVectors, busVector])

  const disabledIndices = useMemo(() => {
    if (isNil(busVector) || isNil(props.defaultBusVectors)) {
      return []
    }

    return busVector.buses.reduce((acc, curr, idx) => {
      if (
        props.defaultBusVectors.some(
          bv => bv.bus.id === curr.id && bv.bus.isExistsDispatch === true,
        )
      ) {
        acc.push(idx)
      }

      return acc
    }, [])
  }, [props.defaultBusVectors, busVector])

  const ListComponent = useMemo(() => {
    if (isNil(busVector)) {
      return <EmptyContainer>등록된 호차가 없습니다.</EmptyContainer>
    }

    return (
      <TableList
        ref={listRef.ref}
        useCheckBox={true}
        checkedIconSource={Checked}
        keys={Object.values(TITLE_MAP)}
        items={listItems}
        defaultCheckedIndices={defaultIndices}
        disabledIndices={disabledIndices}
        onClickRow={() => {}}
        onChangeIndices={onChangeIndices}
        tableContainerStyle={tableContainerStyle}
        tableStyle={tableStyle}
        tableHeaderStyle={tableHeaderStyle}
        tableBodyStyle={tableBodyStyle}
        checkBoxStyle={checkBoxStyle}
        placeholder={'등록된 호차가 없습니다.'}
      />
    )
  }, [
    busVector,
    listItems,
    tableContainerStyle,
    tableStyle,
    tableHeaderStyle,
    tableBodyStyle,
    checkBoxStyle,
  ])

  useEffect(() => {
    fetchBuses()
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      fetchBuses: fetchBuses,
      getSelectedBuses(): CBus[] {
        if (isNil(busVector)) {
          return []
        }

        return busVector.buses.filter((_, idx) =>
          listRef.current().getCheckedIndices().includes(idx),
        )
      },
    }),
    [fetchBuses, busVector],
  )

  return (
    <Container>
      <DispatchBusSettingListHeader showAddBusModal={props.showAddBusModal} />
      {ListComponent}
    </Container>
  )
}

const DispatchBusSettingList = forwardRef(DispatchBusSettingListBase)
export default DispatchBusSettingList

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  padding: 1.6rem;
  overflow: auto;
  row-gap: 0.7rem;
`

const EmptyContainer = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  color: #999999;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-top: 1.2rem;
`
const TableText = styled.div`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  opacity: 0.96;
`
