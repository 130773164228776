import React, {useMemo} from 'react'
import {DispatchTypeEnum} from '../../../../../../enum/DispatchTypeEnum'
import {CDispatchCourseWithStudent} from '../../../../../../model/Dispatch'
import Start from '../../../../../../asset/image/station_start.svg'
import Arrive from '../../../../../../asset/image/station_arrive.svg'
import Number from '../../../../../../asset/image/station_number2.svg'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../style/CommonStyle'
import SVGImage from '../../../../../common/SVGImage'

type Props = {
  isFirstItem: boolean
  isLastItem: boolean
  dispatchType: DispatchTypeEnum
  course: CDispatchCourseWithStudent
}

export default function CourseItemWithoutStudent(props: Props) {
  const CountComponent = useMemo(() => {
    if (props.isFirstItem) {
      return <StationIcon source={Start} />
    }

    if (props.isLastItem) {
      return <StationIcon source={Arrive} />
    }

    return <StationIcon source={Number} />
  }, [props.isLastItem, props.isFirstItem])

  const StationContentComponent = useMemo(() => {
    if (props.isFirstItem) {
      return <StartContent>출발</StartContent>
    }

    if (props.isLastItem) {
      return <ArriveContent>도착</ArriveContent>
    }

    return <StationContent>{props.course.sort - 1}</StationContent>
  }, [props.isLastItem, props.isFirstItem, props.course])

  const headerColor = useMemo(() => {
    if (props.isFirstItem) {
      return '#1A468B'
    }

    if (props.isLastItem) {
      return '#404040'
    }

    return '#665300'
  }, [props.isLastItem, props.isFirstItem])

  return (
    <Container>
      <TimeContainer>
        <StationIconContainer>
          {CountComponent}
          {StationContentComponent}
        </StationIconContainer>
      </TimeContainer>
      <ContentContainer>
        <TimeText>{props.course.arrivalTime}</TimeText>
        <Header color={headerColor}>{props.course.stationName}</Header>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
  border-radius: 0.8rem;
  background: #ffffff;
`

const TimeContainer = styled.div`
  ${flexRow};
  column-gap: 0.8rem;
`

const TimeText = styled.div`
  color: #332a00;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`

const StationIconContainer = styled.div`
  width: 1.867rem;
  height: 2.133rem;

  position: relative;
  ${flexColumn};
  align-items: center;
  justify-content: center;
`

const StationIcon = styled(SVGImage)`
  width: 1.867rem;
  height: 2.133rem;
`

const StationContent = styled.div`
  position: absolute;
  color: #665300;
  text-align: center;
  font-size: 0.8667rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const StartContent = styled(StationContent)`
  font-size: 0.73rem;
  color: #1a468b;
`

const ArriveContent = styled(StationContent)`
  font-size: 0.73rem;
  color: #000000;
`

const ContentContainer = styled.div`
  ${flexColumn};
  justify-content: flex-start;
  align-items: flex-start;
`

const Header = styled.div<{color: string}>`
  color: ${props => props.color};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.39px;
  padding-top: 0.3rem;
`
