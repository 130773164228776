import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../style/CommonStyle'

type AlertModalProps = {
  header: string
  content: string
  onSubmit(): void
}
export type AlertModalRef = {
  show(): void
}

function AlertModalBase(
  props: AlertModalProps,
  ref: ForwardedRef<AlertModalRef>,
): ReactElement {
  const [visible, setVisible] = useState<boolean>(false)

  useImperativeHandle(
    ref,
    () => ({
      show(): void {
        setVisible(true)
      },
    }),
    [setVisible],
  )

  const hide = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const onSubmit = useCallback(() => {
    props.onSubmit()
    hide()
  }, [props.onSubmit, hide])

  if (!visible) {
    return null
  }

  return (
    <Container>
      <BackgroundLayer />
      <ContentContainer>
        <Header>{props.header}</Header>
        <Content>{props.content}</Content>
        <ButtonContainer>
          <ConfirmButton onClick={onSubmit}>확인</ConfirmButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

const AlertModal = forwardRef(AlertModalBase)
export default AlertModal

const Container = styled.div`
  background: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
`

const ContentContainer = styled.div`
  width: 32rem;
  padding: 1.6rem 1.2rem;
  background: #ffffff;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.6rem;
  ${flexColumn};
  align-items: center;
  z-index: 101;
`

const Content = styled.pre`
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-bottom: 2.4rem;
  text-align: center;
  color: #1a468b;
`

const Header = styled.h5`
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  margin-bottom: 1.6rem;
`

const ButtonContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
`

const ConfirmButton = styled.button`
  ${flexRow};
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 3.4rem;
  color: #000000;
  border: none;
  font-size: 1.2rem;
  background: #ffcd00;
  font-weight: 800;
  border-radius: 3.4rem;
`
