import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from 'react'
import StationInfo from './StationInfo'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../../../../style/CommonStyle'
import {DispatchTypeEnum} from '../../../../../../../../enum/DispatchTypeEnum'
import useSecureRef from '../../../../../../../../hook/useSecureRef'
import {isNil} from '../../../../../../../../util/ValidationUtil'
import {CCourse} from '../../../../../../../../model/Course'
import ArrowDown from '../../../../../../../../asset/image/arrowDown.svg'
import SVGImage from '../../../../../../../common/SVGImage'
import {Optional} from '../../../../../../../../type/Common'
import {ModalRef} from '../../../../../../../common/Modal'
import CoursePicker from '../../../../add/operation/course/CoursePicker'
import {StudentLoadTypeEnum} from '../../../../../../../../enum/StudentLoadTypeEnum'

type Props = {
  busName: string
  type: DispatchTypeEnum
  dispatchCode: string
  course: Optional<CCourse>
  onChange(c: CCourse): void
  isAddingBus?: boolean
}

export default function StationSelect(props: Props) {
  const coursePickerRef = useSecureRef<ModalRef>(
    '[StationSelect.tsx] coursePickerRef',
  )

  const onClickCourse = useCallback(() => {
    coursePickerRef.current().show()
  }, [])

  const CourseComponent = useMemo((): ReactNode => {
    if (isNil(props.course)) {
      return (
        <CourseText hasValue={!isNil(props.course)}>
          운행노선을 선택해주세요.
        </CourseText>
      )
    }

    if (props.course.dispatchType === DispatchTypeEnum.CIRCULATION) {
      return (
        <CourseTextContainer>
          <CourseText hasValue={!isNil(props.course)}>
            {props.course.name}
          </CourseText>
          <CourseDispatchTypeText>
            {props.course.dispatchType.exposure}
          </CourseDispatchTypeText>
        </CourseTextContainer>
      )
    }

    return (
      <CourseText hasValue={!isNil(props.course)}>
        {props.course.name}
      </CourseText>
    )
  }, [props.course])

  const courseContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        courseContainerRef.current &&
        !courseContainerRef.current.contains(event.target as Node)
      ) {
        coursePickerRef.current().hide()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [coursePickerRef])

  return (
    <StationSelectBox>
      <StationSelectHeader>{props.busName}</StationSelectHeader>
      <StationSelectInputContainer hasCourse={!isNil(props.course)}>
        <CourseContainer ref={courseContainerRef}>
          <CoursePicker
            ref={coursePickerRef.ref}
            onChange={props.onChange}
            selectedCourse={props.course}
            dispatchType={props.type}
            columnCount={1}
            isAddingBus={props.isAddingBus}
          />
          <CourseInput hasValue={!isNil(props.course)} onClick={onClickCourse}>
            {CourseComponent}
            <ArrowDownImage source={ArrowDown}></ArrowDownImage>
          </CourseInput>
        </CourseContainer>
      </StationSelectInputContainer>

      {isNil(props.course) ? null : (
        <StationInfo
          type={props.type}
          courseCode={props.course.code}
          dispatchCode={props.dispatchCode}
          studentLoadType={StudentLoadTypeEnum.EDIT}
        />
      )}
    </StationSelectBox>
  )
}

const StationSelectBox = styled.div`
  ${flexColumn};
  border-radius: 0.8rem;
  flex: 1;
`

const StationSelectHeader = styled.div`
  border-radius: 0.8rem 0.8rem 0 0;
  border-bottom: 0.1rem solid #ebebeb;
  background: #332a00;
  height: 4.4rem;
  ${flexRow};
  align-items: center;
  padding-left: 1.2rem;
  color: #ffffff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`

const StationSelectInputContainer = styled.div<{hasCourse: boolean}>`
  width: 100%;
  padding: 0.8rem;
  background: #ffffff;
  border: 0.1rem solid #ebebeb;
  border-top-width: 0;
  border-radius: ${props => (!props.hasCourse ? '0 0 0.8rem 0.8rem' : 0)};
`

const CourseContainer = styled.div`
  position: relative;
`

const CourseInput = styled.div<{hasValue: boolean}>`
  ${flexRow};
  justify-content: space-between;
  padding: 0 0.8rem;
  height: 3rem;
  border: 0.1rem solid #ebebeb;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 100%;
  background: ${props => (props.hasValue ? '#FFFBE5' : '#ffffff')};
`

const ArrowDownImage = styled(SVGImage)`
  width: 1.4rem;
  height: 1.4rem;
`

const CourseTextContainer = styled.div`
  ${flexRow};
  align-items: center;
`

const CourseText = styled.div<{hasValue: boolean}>`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 150%;
  font-weight: ${props => (props.hasValue ? 500 : 300)};
  color: ${props => (props.hasValue ? '#000000' : '#cccccc')};
  margin-right: 0.2rem;
`

const CourseDispatchTypeText = styled.div`
  color: #997d00;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 0.2rem;
`
