import React, {ReactElement, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import RadioOn from '../../../../../asset/image/radio btn.svg'
import RadioOff from '../../../../../asset/image/radio_off.svg'
import Cancel from '../../../../../asset/image/close_button.svg'

import SVGImage from '../../../../common/SVGImage'

import {CDispatchDetailWithCode} from '../../../../../model/Dispatch'
import {OccupancyTypeEnum} from '../../../../../enum/OccupancyTypeEnum'
import {Optional} from '../../../../../type/Common'
import {isEmptyArray, isNil} from '../../../../../util/ValidationUtil'

type Props = {
  dispatchDetails: CDispatchDetailWithCode[]
  onCancel(): void
  onSubmit(dd: CDispatchDetailWithCode): void
}

type DispatchDetailTypeAndCount = {
  type: OccupancyTypeEnum
  count: number
}

export default function DispatchEditSelect(props: Props): ReactElement {
  const [selectedItem, setSelectedItem] =
    useState<Optional<DispatchDetailTypeAndCount>>(null)

  const dispatchCountAll = useMemo(() => {
    return props.dispatchDetails.reduce((acc, curr) => {
      acc = acc + curr.count

      return acc
    }, 0)
  }, [props.dispatchDetails])

  const Items = useMemo(() => {
    return [
      {type: OccupancyTypeEnum.ALL, count: dispatchCountAll},
      ...props.dispatchDetails.map(dd => {
        return {
          type: dd.type,
          count: dd.count,
        }
      }),
    ]
  }, [props.dispatchDetails, dispatchCountAll])

  const renderRadioIconComponent = useCallback(
    (s: DispatchDetailTypeAndCount, disabled: boolean) => {
      if (selectedItem?.type === s.type) {
        return <RadioIcon source={RadioOn} disabled={disabled}></RadioIcon>
      }

      return <RadioIcon source={RadioOff} disabled={disabled}></RadioIcon>
    },
    [selectedItem],
  )

  const getTypeExposure = useCallback((type: OccupancyTypeEnum) => {
    if (type === OccupancyTypeEnum.ALL) {
      return '전체 배차'
    }

    if (type === OccupancyTypeEnum.NORMAL) {
      return '정상 배차'
    }

    if (type === OccupancyTypeEnum.FULL_SEAT) {
      return '만차 배차'
    }

    return '미등록 배차'
  }, [])

  const onSelect = useCallback(
    (item: DispatchDetailTypeAndCount) => {
      setSelectedItem(item)
    },
    [setSelectedItem],
  )

  const onSubmit = useCallback(() => {
    if (isNil(selectedItem)) {
      alert('수정하려는 배차 유형을 선택해 주세요.')
      return
    }

    if (selectedItem.type === OccupancyTypeEnum.ALL) {
      const temp = props.dispatchDetails.reduce(
        (acc, curr) => {
          acc = {
            count: acc.count + curr.count,
            dispatches: isEmptyArray(acc.dispatches)
              ? curr.dispatches
              : acc.dispatches.map(d => {
                  return {
                    station: d.station,
                    students: [
                      ...d.students,
                      ...curr.dispatches.find(
                        dp => dp.station.id === d.station.id,
                      ).students,
                    ],
                  }
                }),
            dates: [...acc.dates, ...curr.dates],
            type: OccupancyTypeEnum.ALL,
          }

          return acc
        },
        {
          count: 0,
          dispatches: [],
          dates: [],
          type: OccupancyTypeEnum.ALL,
        },
      )

      props.onCancel()
      props.onSubmit(temp)
      return
    }

    const temp = props.dispatchDetails.find(dd => dd.type === selectedItem.type)
    props.onSubmit(temp)
    props.onCancel()
  }, [props.onSubmit, props.onCancel, selectedItem])

  return (
    <Container>
      <SelectContainer>
        <HeaderContainer>
          <Header>배차 수정 선택</Header>
          <CancelIconButton onClick={props.onCancel}>
            <CancelImage source={Cancel} />
          </CancelIconButton>
        </HeaderContainer>
        <SelectInfo1>수정하려는 배차 유형을 선택해 주세요.</SelectInfo1>
        <SelectInfo2>
          {
            '단, 전체 배차를 선택하실 경우 수정 가능한 일자를 기준으로 \n등록된 학생 정보는 모두 초기화 됩니다.'
          }
        </SelectInfo2>
        <SelectItemsContainer>
          {Items.map(item => {
            const disabled = item.count === 0
            return (
              <SelectItemContainer
                onClick={() => {
                  if (disabled) {
                    return
                  }

                  onSelect(item)
                }}
                key={item.type.exposure}>
                {renderRadioIconComponent(item, disabled)}
                <SelectItemText selected={selectedItem?.type === item.type}>
                  {getTypeExposure(item.type)}({item.count})
                </SelectItemText>
              </SelectItemContainer>
            )
          })}
        </SelectItemsContainer>
        <FooterContainer>
          <CancelButton onClick={props.onCancel}>취소</CancelButton>
          <SubmitButton onClick={onSubmit}>선택</SubmitButton>
        </FooterContainer>
      </SelectContainer>
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  align-items: center;
  justify-content: center;
  flex: 1;
  position: absolute;
  right: 0;
  width: 68rem;
  height: 100%;
  -webkit-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  background-color: transparent;
`

const SelectContainer = styled.div`
  width: 32rem;
  background: #fff;
  border-radius: 1.6rem;
  padding: 1.6rem 1.2rem;
  ${flexColumn};
  row-gap: 1.6rem;
`

const HeaderContainer = styled.div`
  ${flexRow};
  justify-content: center;
  align-items: center;
  position: relative;
`

const CancelIconButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
`

const CancelImage = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const Header = styled.div`
  color: #0a1a34;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`
const SelectInfo1 = styled.div`
  color: #1a468b;
  text-align: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`

const SelectInfo2 = styled.div`
  color: #ff7500;
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  padding: 0 2rem;
`

const SelectItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.9rem;
`

const SelectItemContainer = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.6rem;
  cursor: default;
  padding-left: 2rem;
`

const SelectItemText = styled.div<{selected: boolean}>`
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${props => (props.selected ? '#665300' : '#999999')};
`

const RadioIcon = styled(SVGImage)<{disabled: boolean}>`
  width: 1.4rem;
  height: 1.4rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

const FooterContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
`

const CancelButton = styled.div`
  width: 7.2rem;
  height: 3.4rem;
  background: #ebebeb;
  ${flexRow};
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  color: #332a00;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
`

const SubmitButton = styled.div`
  width: 7.2rem;
  height: 3.4rem;
  background: #ffd100;
  ${flexRow};
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  color: #332a00;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  cursor: pointer;
`
