import React from 'react'
import styled from 'styled-components'
import {ModalButton, flexColumn} from '../../../style/CommonStyle'
import SVGImage from '../../common/SVGImage'
import CloseButton from '../../../asset/image/close_button.svg'
import {DriverProps} from '../registerDriver/RegisterDriver'

type PopUpProps = {
  onClose: () => void
  handleRegisterExistDriver?: () => void
  existDriver: DriverProps
}

export function ExistDriver(props: PopUpProps) {
  return (
    <Container>
      <div onClick={props.onClose}>
        <CloseImg source={CloseButton} />
      </div>
      <Title>타캠퍼스 기사 등록</Title>
      <SubTitle>
        이미 타캠퍼스에 등록된 기사입니다. <br />
        타캠퍼스에 등록된 기사의 정보를 현재 캠퍼스에 동일하게 등록
        하시겠습니까?
      </SubTitle>
      <Text>
        타캠퍼스 등록 기사 정보
        <br />
        {`이름 : ${props.existDriver?.name} / 연락처 : ${props.existDriver?.phone}`}
      </Text>
      <ButtonWrapper>
        <CancelButton onClick={props.onClose}>아니오</CancelButton>
        <ConfirmButton onClick={props.handleRegisterExistDriver}>
          예
        </ConfirmButton>
      </ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding: 1.6rem 1.2rem;
  ${flexColumn};
  justify-content: center;
  text-align: center;
  border-radius: 3rem;
  position: relative;
`
const CloseImg = styled(SVGImage)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`
const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
`
const SubTitle = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1.6rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a468b;
  > span {
    font-size: 1.3rem;
    font-weight: 800;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
`
const CancelButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
`
const ConfirmButton = styled(ModalButton)`
  width: 7.2rem;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.2rem;
  background: #ffcd00;
`

const Text = styled.div`
  margin-bottom: 2.4rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: #997d00;
`
