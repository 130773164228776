import React, {ReactElement, useCallback} from 'react'
import Check from '../../../../../asset/image/checked_yellow.svg'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../../style/CommonStyle'
import SVGImage from '../../../../common/SVGImage'
import {COperationClassTime} from '../../../../../model/Semester'

type Props = {
  time: COperationClassTime
  isChecked: boolean
  isEnable: boolean
  onSelect(time: COperationClassTime): void
}

export default function TimeItem(props: Props): ReactElement {
  const onSelect = useCallback(() => {
    // if (!props.isEnable) {
    //   return
    // }

    props.onSelect(props.time)
  }, [props.time, props.isEnable])

  return (
    <Container isEnable={true} selected={props.isChecked} onClick={onSelect}>
      <CheckIconContainer>
        <CheckIcon source={Check} selected={props.isChecked} />
      </CheckIconContainer>
      <TimeNode isEnable={true} selected={props.isChecked}>
        {props.time.time}
      </TimeNode>
    </Container>
  )
}

const Container = styled.div<{selected: boolean; isEnable: boolean}>`
  width: 7rem;
  height: 1.8rem;
  border: ${props =>
    props.selected ? '0.1rem solid #fffbe5' : '0.1rem solid #ebebeb'};
  border-radius: 12rem;
  ${flexRow};
  align-items: center;
  background: ${props =>
    !props.isEnable ? '#f5f5f5' : props.selected ? '#FFFBE5' : '#ffffff'};
  cursor: ${props => (props.isEnable ? 'pointer' : 'not-allowed')};

  &:hover {
    box-shadow: ${props =>
      props.isEnable ? '0 0 1rem 0 rgba(0, 0, 0, 0.1)' : 'none'};
  }
`

const TimeNode = styled.div<{selected: boolean; isEnable: boolean}>`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${props =>
    !props.isEnable ? '#cccccc' : props.selected ? '#665300' : '#999999'};
`

const CheckIconContainer = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  ${flexRow};
  align-items: center;
  justify-content: center;
`
const CheckIcon = styled(SVGImage)<{selected: boolean}>`
  display: ${props => (props.selected ? 'block' : 'none')};
  width: 1.2rem;
  height: 1.2rem;
`
