import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'
import DispatchListHeader, {DispatchListHeaderRef} from './DispatchListHeader'
import {
  CDispatchPeriod,
  CDispatchPeriodBusVector,
} from '../../../../model/DispatchPeriod'
import {getDispatchPeriodBuses} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {SortEnum} from '../../../../enum/SortEnum'
import useSecureRef from '../../../../hook/useSecureRef'
import {isEmptyArray, isNil} from '../../../../util/ValidationUtil'
import DispatchListItem from './DispatchListItem'
import DispatchRangeEmptyList from './DispatchRangeEmptyList'

type Props = {
  dispatchPeriod: CDispatchPeriod
  showToast(t: string): void
}

export default function DispatchList(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const headerRef = useSecureRef<DispatchListHeaderRef>(
    '[DispatchList.tsx] headerRef',
  )

  const [dispatchPeriodBusVectors, setDispatchPeriodBusVectors] = useState<
    CDispatchPeriodBusVector[]
  >([])

  const fetchDispatchPeriodBuses = useCallback(
    (dpID: number, sort: SortEnum) => {
      getDispatchPeriodBuses(academyID, dpID, sort)
        .then(res => {
          // console.log(res)
          setDispatchPeriodBusVectors(res)
        })
        .catch(error => {
          throw new Error(
            `getDispatchPeriodBuses() failed. (aid: ${academyID}, dpID: ${dpID}, error: ${error})`,
          )
        })
    },
    [academyID, setDispatchPeriodBusVectors],
  )

  const onChangeSort = useCallback(
    (sort: SortEnum) => {
      fetchDispatchPeriodBuses(props.dispatchPeriod.dispatchPeriod.id, sort)
    },
    [fetchDispatchPeriodBuses, props.dispatchPeriod],
  )

  const refreshDispatchPeriodBuses = useCallback(
    (dpID: number) => {
      const sort = headerRef.current().getSort()

      if (isNil(sort)) {
        return
      }

      fetchDispatchPeriodBuses(dpID, sort)
    },
    [fetchDispatchPeriodBuses],
  )

  const ListComponent = useMemo(() => {
    if (isEmptyArray(dispatchPeriodBusVectors)) {
      return <DispatchRangeEmptyList />
    }

    return (
      <ListContainer>
        {dispatchPeriodBusVectors.map((b, idx) => {
          return (
            <DispatchListItem
              onBookmark={refreshDispatchPeriodBuses}
              item={b}
              dispatchPeriod={props.dispatchPeriod}
              key={`${b.bus.id}_${idx}`}
            />
          )
        })}
      </ListContainer>
    )
  }, [dispatchPeriodBusVectors, props.dispatchPeriod])

  useEffect(() => {
    refreshDispatchPeriodBuses(props.dispatchPeriod.dispatchPeriod.id)
  }, [refreshDispatchPeriodBuses, props.dispatchPeriod])

  return (
    <Container>
      <DispatchListHeader
        ref={headerRef.ref}
        dispatchPeriodID={props.dispatchPeriod.dispatchPeriod.id}
        dispatchPeriodBusVectors={dispatchPeriodBusVectors}
        onChangeSort={onChangeSort}
        onSubmit={refreshDispatchPeriodBuses}
        showToast={props.showToast}
        isEmpty={isEmptyArray(dispatchPeriodBusVectors)}
      />
      {ListComponent}
    </Container>
  )
}

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  height: 100%;
  background: #ffffff;
  row-gap: 1.6rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
const ListContainer = styled.div`
  ${flexRow};
  flex-wrap: wrap;
  column-gap: 0.8rem;
  row-gap: 1.2rem;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
