import React, {
  ForwardedRef,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {color, flexRow} from '../../../style/CommonStyle'
import PlusIcon from '../../../asset/image/plus_icon.svg'
import SVGImage from '../../common/SVGImage'
import Select from '../../input/Select'
import SearchInput from '../../input/SearchInput'
import useSecureRef from '../../../hook/useSecureRef'
import RegisterManager, {
  RegisterManagerRef,
} from '../registerManager/RegisterManager'
import ModalContainer, {ModalContainerRef} from '../../modal/ModalContainer'
import Toast from '../../toast/Toast'

type ManagerListProps = {
  setSelect: React.Dispatch<SetStateAction<string>>
  setKeyword: React.Dispatch<SetStateAction<string>>
  keyword: string
  fetchManagerList(): void
}
export type ManagerListRef = {}

const managerOptions = [
  {
    idx: 0,
    exposure: '매니저명',
    value: 'NAME',
  },
  {
    idx: 1,
    exposure: '연락처',
    value: 'PHONE',
  },
  {
    idx: 2,
    exposure: '아이디',
    value: 'ID',
  },
]

function SearchManagerBase(
  props: ManagerListProps,
  ref: ForwardedRef<ManagerListRef>,
) {
  const registerManagerRef = useSecureRef<RegisterManagerRef>(
    '[SearchManager.tsx] registerManagerRef',
  )
  const registerPopUpRef = useSecureRef<ModalContainerRef>(
    '[SearchManager.tsx] registerPopUpRef',
  )
  const [toast, setToast] = useState<boolean>(false)
  const [name, setName] = useState<string>('')

  const showRegisterModal = useCallback(() => {
    registerPopUpRef.current().show()
  }, [])

  const hideRegisterModal = useCallback(() => {
    registerPopUpRef.current().hide()
  }, [])

  const onChangeSelectValue = (v: string) => {
    props.setSelect(v)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const onChangeText = useCallback(
    (t: string) => {
      props.setKeyword(t)
    },
    [props.keyword],
  )

  useEffect(() => {
    if (name !== '') {
      setToast(true)
    }
  }, [name])

  useEffect(() => {
    if (toast) {
      const timeoutId = setTimeout(() => {
        setToast(false)
      }, 3000)

      const timeoutName = setTimeout(() => {
        setName('')
      }, 4000)

      return () => {
        clearTimeout(timeoutId)
        clearTimeout(timeoutName)
      }
    }
  }, [toast])

  return (
    <Container>
      <SelectSearchContainer>
        <Select
          options={managerOptions}
          onChange={(value: string) => onChangeSelectValue(value)}
          width={8.8}
          height={3.6}
          fontSize={1.2}
          defaultIdx={0}
          boxShadow={false}
          border={false}
        />
        <Line />
        <SearchInput
          onChangeText={onChangeText}
          placeHolder={'검색어를 입력해주세요.'}
          border={false}
          width={21}
          onKeyDown={handleKeyDown}
        />
      </SelectSearchContainer>
      <RegisterManagerButton onClick={showRegisterModal}>
        <PlusImg source={PlusIcon} />
        매니저 등록
      </RegisterManagerButton>
      <ModalContainer ref={registerPopUpRef.ref}>
        <RegisterManager
          ref={registerManagerRef.ref}
          hideRegisterModal={hideRegisterModal}
          fetchManagerList={props.fetchManagerList}
          setName={setName}
        />
      </ModalContainer>
      <Toast
        text={`${name} 매니저님이 등록되었습니다.`}
        status={toast ? 'toast' : ''}
      />
    </Container>
  )
}

const SearchManager = forwardRef(SearchManagerBase)
export default SearchManager

const Container = styled.div`
  width: 100%;
  background-color: ${color.white};
  height: 5.2rem;
  ${flexRow}
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
`

const SelectSearchContainer = styled.div`
  ${flexRow};
  align-items: center;
  min-height: 3.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #ebebeb;
  background-color: ${color.white};

  :focus-within {
    border-color: #ffe880;
    background: #fffbe5;

    input,
    div {
      background: #fffbe5;
    }
  }
`

const RegisterManagerButton = styled.button`
  ${flexRow};
  background-color: #ffcd00;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  align-items: center;
  border: none;
  border-radius: 3.4rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
`

const PlusImg = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.2rem;
`

const Line = styled.span`
  width: 0.1rem;
  height: 2rem;
  background: #d9d9d9;
  margin-right: 0.4rem;
`
