import React from 'react'
import StudentItem from './StudentItem'
import styled from 'styled-components'
import {flexRow} from '../../../../../../style/CommonStyle'
import {
  CDispatchCourseWithStudent,
  CStudentWithCheck,
} from '../../../../../../model/Dispatch'

type Props = {
  course: CDispatchCourseWithStudent
  readOnly: boolean
  onChangeStudent(s: CStudentWithCheck): void
}

function Students(props: Props) {
  return (
    <Container>
      {props.course.students.map((student, idx) => {
        return (
          <StudentItem
            key={idx}
            readOnly={props.readOnly}
            student={student}
            isChecked={student.check}
            onChangeStudent={props.onChangeStudent}
          />
        )
      })}
    </Container>
  )
}

export default Students

const Container = styled.div`
  ${flexRow};
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.6rem;
`
