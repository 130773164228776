import React, {ForwardedRef, forwardRef, ReactElement, useMemo} from 'react'
import styled from 'styled-components'
import {Optional} from '../../type/Common'
import {isNil} from '../../util/ValidationUtil'
import {flexRow} from '../../style/CommonStyle'

type InputHeaderProps = {
  header: Optional<string>
  headerColor?: string
  required: boolean
  description?: string
  className?: string
}
export type InputHeaderRef = {}

function InputHeaderBase(
  props: InputHeaderProps,
  _: ForwardedRef<InputHeaderRef>,
): ReactElement {
  const RequiredSignComponent = useMemo(() => {
    if (!props.required) {
      return null
    }

    return <RequiredSign>*</RequiredSign>
  }, [props.required])

  if (isNil(props.header)) {
    return null
  }

  return (
    <HeaderContainer className={props.className}>
      <Label htmlFor={props.header} style={{color: props.headerColor}}>
        {props.header}
      </Label>
      {RequiredSignComponent}
    </HeaderContainer>
  )
}

const InputHeader = forwardRef(InputHeaderBase)
export default InputHeader

const HeaderContainer = styled.div`
  ${flexRow};
  column-gap: 0.3rem;
`

const Label = styled.label`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #997d00;
`
const RequiredSign = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #ff7500;
`
