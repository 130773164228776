import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components'
import {flexColumn} from '../../../../style/CommonStyle'
import InputHeader from '../../../input/InputHeader'
import TextInput, {TextInputRef} from '../../../input/TextInput'
import useSecureRef from '../../../../hook/useSecureRef'
import {PostDriverParams} from '../../../../service/driver/Driver'
import {isEmptyString} from '../../../../util/ValidationUtil'

type Props = {
  readOnly: boolean
  onChange(isSufficient: boolean): void
}
export type DispatchDriverAddInputsRef = {
  getDriverAddInputData(): PostDriverParams
}

function DispatchDriverAddInputsBase(
  props: Props,
  ref: ForwardedRef<DispatchDriverAddInputsRef>,
): ReactElement {
  const nameRef = useSecureRef<TextInputRef>(
    '[DispatchDriverAddInputs.tsx] nameRef',
  )
  const phoneRef = useSecureRef<TextInputRef>(
    '[DispatchDriverAddInputs.tsx] phoneRef',
  )
  const userIDRef = useSecureRef<TextInputRef>(
    '[DispatchDriverAddInputs.tsx] userIDRef',
  )
  const passwordRef = useSecureRef<TextInputRef>(
    '[DispatchDriverAddInputs.tsx] passwordRef',
  )

  const onChangeName = useCallback(
    (t: string) => {
      const phone = phoneRef.current().getValue()
      const userID = userIDRef.current().getValue()
      const password = passwordRef.current().getValue()

      props.onChange(
        !isEmptyString(t) &&
          !isEmptyString(phone) &&
          !isEmptyString(userID) &&
          !isEmptyString(password),
      )
    },
    [props.onChange],
  )

  const onChangePhone = useCallback(
    (t: string) => {
      const name = nameRef.current().getValue()
      const userID = userIDRef.current().getValue()
      const password = passwordRef.current().getValue()

      props.onChange(
        !isEmptyString(t) &&
          !isEmptyString(name) &&
          !isEmptyString(userID) &&
          !isEmptyString(password),
      )
    },
    [props.onChange],
  )
  const onChangeUserID = useCallback(
    (t: string) => {
      const phone = phoneRef.current().getValue()
      const name = nameRef.current().getValue()
      const password = passwordRef.current().getValue()

      props.onChange(
        !isEmptyString(t) &&
          !isEmptyString(phone) &&
          !isEmptyString(name) &&
          !isEmptyString(password),
      )
    },
    [props.onChange],
  )
  const onChangePassword = useCallback(
    (t: string) => {
      const phone = phoneRef.current().getValue()
      const userID = userIDRef.current().getValue()
      const name = nameRef.current().getValue()

      props.onChange(
        !isEmptyString(t) &&
          !isEmptyString(phone) &&
          !isEmptyString(userID) &&
          !isEmptyString(name),
      )
    },
    [props.onChange],
  )

  useImperativeHandle(
    ref,
    () => ({
      getDriverAddInputData(): PostDriverParams {
        return {
          name: nameRef.current().getValue(),
          phone: phoneRef.current().getValue(),
          userID: userIDRef.current().getValue(),
          password: passwordRef.current().getValue(),
        }
      },
    }),
    [],
  )

  return (
    <Container>
      <StyledHeader>기본 정보</StyledHeader>
      <InputContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'기사명'} />
          <TextInput
            ref={nameRef.ref}
            readOnly={props.readOnly}
            required={true}
            placeholder={'이름을 입력해주세요.'}
            onChange={onChangeName}
          />
        </ColumnInputContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'연락처'} />
          <TextInput
            ref={phoneRef.ref}
            readOnly={props.readOnly}
            required={true}
            placeholder={'전화번호를 입력해주세요.(‘-’ 제외)'}
            onChange={onChangePhone}
          />
        </ColumnInputContainer>
      </InputContainer>

      <StyledHeader style={{marginTop: 16}}>계정 정보</StyledHeader>
      <InputContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'아이디'} />
          <TextInput
            ref={userIDRef.ref}
            readOnly={props.readOnly}
            required={true}
            placeholder={'영문, 숫자 조합 최소 5자 ~ 최대 20자'}
            onChange={onChangeUserID}
          />
        </ColumnInputContainer>
        <ColumnInputContainer>
          <InputHeader required={true} header={'비밀번호'} />
          <TextInput
            ref={passwordRef.ref}
            readOnly={props.readOnly}
            required={true}
            placeholder={'영문, 숫자 조합 최소 10자 ~ 최대 30자'}
            onChange={onChangePassword}
          />
        </ColumnInputContainer>
      </InputContainer>
    </Container>
  )
}

const DispatchDriverAddInputs = forwardRef(DispatchDriverAddInputsBase)
export default DispatchDriverAddInputs

const Container = styled.div`
  ${flexColumn};
  flex: 1;
  padding: 1.6rem;
`
const StyledHeader = styled.h2`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  margin-bottom: 0.5rem;
`

const InputContainer = styled.div`
  ${flexColumn};
`
const ColumnInputContainer = styled.div`
  ${flexColumn};
  row-gap: 0.4rem;
  width: 100%;
  padding: 0.8rem 1.2rem 1rem;
  background: #ffffff;
  border-radius: 0.8rem;
`
