import React, {
  ChangeEvent,
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router'
import {Optional} from '../../../type/Common'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../recoil/Atom'
import {flexColumn, flexRow} from '../../../style/CommonStyle'
import DriverIcon from '../../../asset/image/driver_icon.svg'
import SVGImage from '../../common/SVGImage'
import {isNil} from '../../../util/ValidationUtil'
import {CDriver} from '../../../model/Driver'
import {CDateTime} from '../../../model/DateTime'
import HalfPopup from '../../common/HalfPopUp'
import useSecureRef from '../../../hook/useSecureRef'
import ChangeName, {ModalContainerRef} from '../popupContents/ChangeName'
import {DeleteDriver} from '../popupContents/DeleteDriver'
import Toast from '../../toast/Toast'
import ChangePhone from '../popupContents/ChangePhone'
import ChangeId from '../popupContents/ChangeId'
import ChangePassword from '../popupContents/ChangePassword'
import {
  deleteDriver,
  getDriverInfo,
  getDriverMemo,
  patchDriverMemo,
  patchDriverName,
  patchDriverPassword,
  patchDriverPhone,
  patchDriverUserID,
} from '../../../service/driver/Driver'
import {DisabledDeleteDriver} from '../popupContents/DisabledDeleteDriver'

type DriverBasicInfoProps = {
  setDriverName: Dispatch<SetStateAction<Optional<string>>>
}

type StateProps = {
  state: {
    driverID: number
  }
}

export type DriverNameRef = {
  getValue(): string
}

function DriverBasicInfoBase(
  props: DriverBasicInfoProps,
  ref: ForwardedRef<DriverNameRef>,
) {
  const {state} = useLocation() as StateProps
  const navigate = useNavigate()
  const driverID = state.driverID
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [driverInfo, setDriverInfo] = useState<Optional<CDriver>>(null)
  const createdAt = CDateTime.create(driverInfo?.createdAt)
  const nameRef = useSecureRef<ModalContainerRef>(
    '[DriverBasicInfo.tsx] nameRef',
  )
  const phoneRef = useSecureRef<ModalContainerRef>(
    '[DriverBasicInfo.tsx] phoneRef',
  )
  const idRef = useSecureRef<ModalContainerRef>('[DriverBasicInfo.tsx] idRef')
  const pwRef = useSecureRef<ModalContainerRef>('[DriverBasicInfo.tsx] pwRef')
  const deleteDriverRef = useSecureRef<ModalContainerRef>(
    '[DriverBasicInfo.tsx] deleteDriverRef',
  )
  const disableDeleteDriverRef = useSecureRef<ModalContainerRef>(
    '[DriverBasicInfo.tsx] disableDeleteDriverRef',
  )
  const [memoStatus, setMemoStatus] = useState<boolean>(false)
  const [name, setName] = useState<Optional<string>>(null)
  const [phone, setPhone] = useState<Optional<string>>(null)
  const [id, setID] = useState<Optional<string>>(null)
  const [pw, setPW] = useState<Optional<string>>(null)
  const [memo, setMemo] = useState<string>('')
  const [initialMemo, setInitialMemo] = useState<Optional<boolean>>(false)
  const [nameToast, setNameToast] = useState<boolean>(false)
  const [phoneToast, setPhoneToast] = useState<boolean>(false)
  const [idToast, setIdToast] = useState<boolean>(false)
  const [pwToast, setPwToast] = useState<boolean>(false)
  const [registerMemoToast, setRegisterMemoToast] = useState<boolean>(false)
  const [deleteMemoToast, setDeleteMemoToast] = useState<boolean>(false)
  const [error, setError] = useState<Optional<string>>(null)

  const fetchDriverDetail = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    getDriverInfo(selectedAcademyID, driverID.toString())
      .then(md => {
        setDriverInfo(md)
        props.setDriverName(md?.name)
      })
      .catch(error => {
        throw new Error(`failed to get driver info. error: ${error})`)
      })

    getDriverMemo(selectedAcademyID, driverID.toString())
      .then(mm => {
        setMemo(mm.memo)
        if (isNil(mm.memo) || mm.memo === '') {
          setInitialMemo(false)
        } else setInitialMemo(true)
      })
      .catch(error => {
        throw new Error(
          `failed to get driver memo. (academyID: ${selectedAcademyID}, error: ${error})`,
        )
      })
  }, [])

  useEffect(() => {
    fetchDriverDetail()
  }, [selectedAcademyID, driverID])

  const showNamePopUp = useCallback(() => {
    nameRef.current().show()
  }, [])

  const showPhonePopUp = useCallback(() => {
    phoneRef.current().show()
  }, [])

  const showIdPopUp = useCallback(() => {
    idRef.current().show()
  }, [])

  const showPwPopUp = useCallback(() => {
    pwRef.current().show()
  }, [])

  const handleCloseButton = useCallback(() => {
    nameRef.current().hide()
    phoneRef.current().hide()
    idRef.current().hide()
    pwRef.current().hide()
    deleteDriverRef.current().hide()
    disableDeleteDriverRef.current().hide()
    setName('')
    setPhone('')
    setID('')
    setPW('')
    setError(null)
  }, [setName, setPhone, setID, setPW, setError])

  const deleteDriverPopUp = useCallback(() => {
    deleteDriverRef.current().show()
  }, [])

  const onClickDeleteButton = useCallback(() => {
    deleteDriver(selectedAcademyID, driverID.toString())
      .then(dm => {
        if (dm === 'OK') {
          navigate('/driver', {state: {name: driverInfo.name}})
        }
      })
      .catch(error => {
        setError(error.response.data.data)
        deleteDriverRef.current().hide()
      })
  }, [setError, driverInfo])

  const onChangeMemo = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(e.target.value)
  }, [])

  const registerMemo = useCallback(() => {
    patchDriverMemo(selectedAcademyID, driverID.toString(), memo)
      .then(_ => {
        setRegisterMemoToast(true)
        fetchDriverDetail()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo])

  const deleteMemo = useCallback(() => {
    patchDriverMemo(selectedAcademyID, driverID.toString(), '')
      .then(_ => {
        setDeleteMemoToast(true)
        fetchDriverDetail()
      })
      .catch(error => setError(error.response.data.data))
  }, [memo])

  const changeDriverName = useCallback(() => {
    patchDriverName(selectedAcademyID, driverID.toString(), name)
      .then(cn => {
        if (cn === 'OK') {
          nameRef.current().hide()
          setName('')
          setNameToast(true)
          fetchDriverDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [name, error])

  const changeDriverPhone = useCallback(() => {
    if (!isNil(phone)) {
      const phoneNumber =
        phone.slice(0, 3) + phone.slice(4, 8) + phone.slice(-4)
      patchDriverPhone(selectedAcademyID, driverID.toString(), phoneNumber)
        .then(cp => {
          if (cp === 'OK') {
            phoneRef.current().hide()
            setPhone('')
            setPhoneToast(true)
            fetchDriverDetail()
            setError(null)
          }
        })
        .catch(error => setError(error.response.data.data))
    }
  }, [phone])

  const changeDriverID = useCallback(() => {
    patchDriverUserID(selectedAcademyID, driverID.toString(), id)
      .then(ci => {
        if (ci === 'OK') {
          idRef.current().hide()
          setID('')
          setIdToast(true)
          fetchDriverDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [id])

  const changeDriverPw = useCallback(() => {
    patchDriverPassword(selectedAcademyID, driverID.toString(), pw)
      .then(cp => {
        if (cp === 'OK') {
          pwRef.current().hide()
          setPW('')
          setPwToast(true)
          fetchDriverDetail()
          setError(null)
        }
      })
      .catch(error => setError(error.response.data.data))
  }, [pw])

  useEffect(() => {
    if (!isNil(error)) {
      const errorMessage = error?.split(':')
      if (errorMessage[0]?.includes('ME022')) {
        disableDeleteDriverRef.current().show()
      }

      return
    }
  }, [error])

  useEffect(() => {
    if (nameToast) {
      const timeoutId = setTimeout(() => {
        setNameToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
    if (phoneToast) {
      const timeoutId = setTimeout(() => {
        setPhoneToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (idToast) {
      const timeoutId = setTimeout(() => {
        setIdToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (pwToast) {
      const timeoutId = setTimeout(() => {
        setPwToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (registerMemoToast) {
      const timeoutId = setTimeout(() => {
        setRegisterMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }

    if (deleteMemoToast) {
      const timeoutId = setTimeout(() => {
        setDeleteMemoToast(false)
      }, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [
    nameToast,
    phoneToast,
    idToast,
    pwToast,
    registerMemoToast,
    deleteMemoToast,
  ])

  return (
    <Container>
      <Title>기본 정보</Title>
      {!isNil(driverInfo) && (
        <InfoWrapper>
          <InfoCard>
            <DriverImgExpireWrapper>
              <DriverImg source={DriverIcon} />
              <ExpireButton onClick={deleteDriverPopUp}>탈퇴</ExpireButton>
            </DriverImgExpireWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>이름</Category>
                <ValueButtonWrapper>
                  <Value>{driverInfo.name}</Value>
                  <ModifyButton onClick={showNamePopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>연락처</Category>
                <ValueButtonWrapper>
                  <Value>{driverInfo.phone}</Value>
                  <ModifyButton onClick={showPhonePopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>회원상태 및 가입정보</Category>
                <ValueButtonWrapper>
                  <Value>
                    정상회원<span>({createdAt.format('YYYY-MM-DD')} 가입)</span>
                  </Value>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
            <CategoryColumnWrapper>
              <CategoryWrapper>
                <Category>아이디</Category>
                <ValueButtonWrapper>
                  <Value>{driverInfo.userId}</Value>
                  <ModifyButton onClick={showIdPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
              <CategoryWrapper>
                <Category>비밀번호</Category>
                <ValueButtonWrapper>
                  <Value>*******************</Value>
                  <ModifyButton onClick={showPwPopUp}>수정</ModifyButton>
                </ValueButtonWrapper>
              </CategoryWrapper>
            </CategoryColumnWrapper>
          </InfoCard>
          <MemoContainer>
            <HeaderWrapper>
              <Category>메모</Category>
              {!initialMemo ? (
                <MemoButton onClick={registerMemo}>메모 등록</MemoButton>
              ) : (
                <MemoButtonWrapper>
                  <ModifyMemoButton onClick={registerMemo}>
                    수정
                  </ModifyMemoButton>
                  <DeleteMemoButton onClick={deleteMemo}>삭제</DeleteMemoButton>
                </MemoButtonWrapper>
              )}
            </HeaderWrapper>
            <TextArea
              placeholder="작성된 메모가 없습니다."
              value={memo}
              onChange={e => onChangeMemo(e)}
              disabled={memoStatus}
            />
          </MemoContainer>
        </InfoWrapper>
      )}
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={nameRef.ref}
        contents={
          <ChangeName
            onClose={handleCloseButton}
            confirmButton={changeDriverName}
            name={driverInfo?.name}
            originalName={name}
            setName={setName}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={phoneRef.ref}
        contents={
          <ChangePhone
            onClose={handleCloseButton}
            confirmButton={changeDriverPhone}
            phone={driverInfo?.phone}
            originalPhone={phone}
            setPhone={setPhone}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={idRef.ref}
        contents={
          <ChangeId
            onClose={handleCloseButton}
            confirmButton={changeDriverID}
            originalId={id}
            setId={setID}
            error={error}
          />
        }
      />
      <HalfPopup
        width={'40rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={pwRef.ref}
        contents={
          <ChangePassword
            onClose={handleCloseButton}
            confirmButton={changeDriverPw}
            setPassword={setPW}
            error={error}
            originalPassword={pw}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'17.4rem'}
        top={'25%'}
        right={'60%'}
        ref={deleteDriverRef.ref}
        contents={
          <DeleteDriver
            onClose={handleCloseButton}
            name={driverInfo?.name}
            onClickDeleteButton={onClickDeleteButton}
          />
        }
      />
      <HalfPopup
        width={'32rem'}
        height={'auto'}
        top={'25%'}
        right={'60%'}
        ref={disableDeleteDriverRef.ref}
        contents={<DisabledDeleteDriver onClose={handleCloseButton} />}
      />
      {nameToast && (
        <Toast
          text={`기사님 이름이 수정 되었습니다.`}
          status={nameToast ? 'toast' : ''}
        />
      )}
      {phoneToast && (
        <Toast
          text={`${driverInfo?.name}기사님 연락처가 수정 되었습니다.`}
          status={phoneToast ? 'toast' : ''}
        />
      )}
      {idToast && (
        <Toast
          text={`${driverInfo?.name}기사님 아이디가 수정 되었습니다.`}
          status={idToast ? 'toast' : ''}
        />
      )}
      {pwToast && (
        <Toast
          text={`${driverInfo?.name} 기사님 비밀번호가 수정 되었습니다.`}
          status={pwToast ? 'toast' : ''}
        />
      )}
      {registerMemoToast && (
        <Toast
          text={`메모 등록이 완료되었습니다.`}
          status={registerMemoToast ? 'toast' : ''}
        />
      )}
      {deleteMemoToast && (
        <Toast
          text={`${driverInfo?.name}기사님에 등록된 메모를 삭제 하였습니다.`}
          status={deleteMemoToast ? 'toast' : ''}
        />
      )}
    </Container>
  )
}

const DriverBasicInfo = forwardRef(DriverBasicInfoBase)
export default DriverBasicInfo

const Container = styled.div``

const HeaderWrapper = styled.div`
  ${flexRow}
  width: 100%;
  height: 1.8rem;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
`

const MemoButton = styled.button`
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4rem;
  height: 1.8rem;
  color: #fff1b3;
  background: #333333;
  border-radius: 1.6rem;
  padding: 0.2rem 0.8rem;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.17fr 1fr;
  align-items: center;
  column-gap: 0.8rem;
`

const InfoCard = styled.div`
  ${flexRow}
  width: 100%;
  height: 18.2rem;
  box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1.6rem;
  padding: 1.6rem 1.2rem;
`

const DriverImgExpireWrapper = styled.div`
  ${flexColumn}
  align-items: center;
  row-gap: 1.2rem;
  width: 4.8rem;
  margin-right: 1.6rem;
`

const DriverImg = styled(SVGImage)`
  width: 4.8rem;
  height: 4.8rem;
`

const ExpireButton = styled(MemoButton)``

const CategoryColumnWrapper = styled.div`
  ${flexColumn}
  width: 20rem;
  row-gap: 1.2rem;
`

const CategoryWrapper = styled.div`
  ${flexColumn}
  row-gap: 0.2rem;
`

const Category = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
  color: #1a468b;
`

const Value = styled(Category)`
  font-size: 1.4rem;
  color: #000;
  > span {
    font-size: 1.2rem;
    font-weight: 300;
  }
`

const ValueButtonWrapper = styled.div`
  ${flexRow};
  align-items: center;
  column-gap: 0.8rem;
`

const ModifyButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  padding: 0.2rem 0.4rem;
  color: #2767ce;
  background: #fff;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border: none;
  resize: none;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.65rem;

  :disabled {
    background: #fff;
  }
  :focus {
    outline: none;
    background: #fffbe5;
  }
`

const MemoContainer = styled(InfoCard)`
  ${flexColumn}

  :focus-within {
    background: #fffbe5;
    border: 0.1rem solid #ffd100;

    > textarea {
      background: #fffbe5;
    }
  }
`

const MemoButtonWrapper = styled.div`
  ${flexRow}
  column-gap: 0.4rem;
`

const ModifyMemoButton = styled.button`
  border: 0.05rem solid #2767ce;
  border-radius: 1.6rem;
  background: #fff;
  color: #2767ce;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`

const DeleteMemoButton = styled.button`
  border: 0.05rem solid #333;
  background: #333;
  border-radius: 1.6rem;
  color: #fff1b3;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in;

  &:hover {
    transform: translateY(-0.2rem);
  }
`
