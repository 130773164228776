import React, {ReactElement} from 'react'
import styled from 'styled-components'
import {flexColumn, flexRow} from '../../../../style/CommonStyle'

type Props = {}

export default function DispatchEmptyList(_: Props): ReactElement {
  return (
    <EmptyContainer>
      <EmptyText>현재 등록되어 있는 배차가 존재하지 않습니다.</EmptyText>
      <EmptyTextContainer>
        <EmptyText>[</EmptyText>배차 기간 설정<EmptyText>]</EmptyText>
        <EmptyText style={{marginLeft: 2}}>
          버튼을 통해 배차기간을 먼저 등록해주세요.
        </EmptyText>
      </EmptyTextContainer>
    </EmptyContainer>
  )
}

const EmptyContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  height: 8.4rem;
`

const EmptyTextContainer = styled.div`
  ${flexRow};
  color: #ff7900;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`

const EmptyText = styled.p`
  color: #999999;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`
