import React, {Fragment, ReactElement, useCallback, useState} from 'react'
import Modal, {ModalRef} from '../../../common/Modal'
import styled from 'styled-components'
import useSecureRef from '../../../../hook/useSecureRef'
import {flexColumn} from '../../../../style/CommonStyle'
import {Optional} from '../../../../type/Common'
import {CSemester} from '../../../../model/Semester'
import {
  isEmptyArray,
  isEmptyString,
  isNil,
} from '../../../../util/ValidationUtil'
import ConfirmModal, {ConfirmModalRef} from '../../../modal/ConfirmModal'
import {
  getDispatchPeriodExists,
  postDispatchPeriod,
  PostDispatchPeriodData,
} from '../../../../service/dispatchPeriod/DispatchPeriod'
import {useRecoilValue} from 'recoil'
import {academyIDState} from '../../../../recoil/Atom'
import {CDispatchPeriodExist} from '../../../../model/DispatchPeriod'
import {alertError} from '../../../../util/ErrorUtil'
import DispatchSemesterSelect from '../semesterSelect/DispatchSemesterSelect'
import DispatchPeriodInputContainer, {
  DispatchPeriodInputContainerRef,
  PeriodInformation,
} from '../manage/DispatchPeriodInputContainer'
import DispatchPeriodHeaderInfo from '../manage/DispatchPeriodHeaderInfo'
import DispatchPeriodSemesterPicker from '../manage/DispatchPeriodSemesterPicker'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import {WeekdayEnum} from '../../../../enum/WeekdayEnum'
import DispatchPeriodAddExistModal from './DispatchPeriodAddExist'

const TOAST_CONTENT = '배차 기간 등록이 완료 되었습니다.'

type Props = {
  onSubmit(tc: string, year: number): void
  onCancel(): void
  handleLoading(isLoading: boolean): void
}

export default function DispatchPeriodAdd(props: Props): ReactElement {
  const academyID = useRecoilValue(academyIDState)
  const semesterSelectModal = useSecureRef<ModalRef>(
    '[DispatchPeriodAdd.tsx] innerModalRef',
  )
  const cancelConfirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodAdd.tsx] cancelConfirmModalRef',
  )
  const confirmModalRef = useSecureRef<ConfirmModalRef>(
    '[DispatchPeriodAdd.tsx] confirmModalRef',
  )
  const existCheckModalRef = useSecureRef<ModalRef>(
    '[DispatchPeriodAdd.tsx] existCheckModalRef',
  )
  const inputContainerRef = useSecureRef<DispatchPeriodInputContainerRef>(
    '[DispatchPeriodAdd.tsx] infoRef',
  )

  const [selectableWeekdays, setSelectableWeekdays] = useState<WeekdayEnum[]>(
    [],
  )
  const [isSufficient, setIsSufficient] = useState<boolean>(false)
  const [dispatchPeriodExists, setDispatchPeriodExists] = useState<
    CDispatchPeriodExist[]
  >([])

  const [selectedSemester, setSelectedSemester] =
    useState<Optional<CSemester>>(null)

  const showSemesterSelectModal = useCallback(() => {
    semesterSelectModal.current().show()
  }, [])

  const showConfirmModal = useCallback(() => {
    if (!isSufficient) {
      alert('필수 항목을 입력해주세요!')
      return
    }

    confirmModalRef.current().show()
  }, [isSufficient])

  const addDispatchPeriod = useCallback(() => {
    props.handleLoading(true)
    const d = inputContainerRef.current().getInputValues()

    if (isNil(selectedSemester) || isNil(d)) {
      alert('필수 항목을 입력해주세요!!')
      return
    }

    const data: PostDispatchPeriodData = {
      semesterID: selectedSemester.id,
      ...d,
    }

    postDispatchPeriod(academyID, data)
      .then(() => {
        existCheckModalRef.current().hide()
        props.onSubmit(TOAST_CONTENT, selectedSemester.startDate.toYear())
        props.onCancel()
        props.handleLoading(false)
      })
      .catch(error => {
        props.handleLoading(false)
        alertError(
          error,
          `postDispatchPeriod() failed. (error: ${error}, aid:${academyID}, data: ${JSON.stringify(
            data,
          )})`,
        )
      })
  }, [selectedSemester, academyID, props.onSubmit])

  const onConfirmExistModal = useCallback(
    (agree: boolean) => {
      if (!agree) {
        addDispatchPeriod()
        return
      }

      addDispatchPeriod()
    },
    [addDispatchPeriod],
  )

  const checkDispatchPeriodExists = useCallback(() => {
    const inputValues = inputContainerRef.current().getInputValues()

    if (isNil(inputValues)) {
      alert('필수 항목을 입력해주세요.')
      return
    }

    const startDate = inputValues.startDate
    const endDate = inputValues.endDate

    getDispatchPeriodExists(academyID, {
      startDate: startDate,
      endDate: endDate,
      dispatchPeriodID: null,
    })
      .then((dpes: CDispatchPeriodExist[]) => {
        if (isEmptyArray(dpes)) {
          addDispatchPeriod()
          return
        }

        setDispatchPeriodExists(dpes)
        existCheckModalRef.current().show()
      })
      .catch(error => {
        alertError(
          error,
          `getDispatchPeriodExists() failed. (error: ${error}, aid: ${academyID})`,
        )
      })
  }, [addDispatchPeriod])

  const onCancel = useCallback(() => {
    setSelectedSemester(null)

    props.onCancel()
  }, [props.onCancel, setSelectedSemester])

  const onChangeInformation = useCallback(
    (pi: Optional<PeriodInformation>) => {
      if (isNil(pi)) {
        setIsSufficient(false)
        return
      }

      setIsSufficient(
        !isNil(selectedSemester) &&
          !isEmptyString(pi.name) &&
          !isNil(pi.startDate) &&
          !isNil(pi.endDate) &&
          !isNil(pi.inboundBeforeMinute) &&
          !isNil(pi.outboundAfterMinute) &&
          !isEmptyArray(pi.weekdays),
      )
    },
    [selectedSemester, setIsSufficient],
  )
  const hideSelectSemesterModal = useCallback(() => {
    semesterSelectModal.current().hide()
  }, [])

  const onSubmitSelectSemester = useCallback(
    (ss: CSemester) => {
      setSelectedSemester(ss)
      hideSelectSemesterModal()
    },
    [setSelectedSemester, hideSelectSemesterModal],
  )

  const showCancelConfirmModal = useCallback(() => {
    cancelConfirmModalRef.current().show()
  }, [])

  const hideExistCheckModal = useCallback(() => {
    existCheckModalRef.current().hide()
  }, [])

  const onChangeSelectableWeekdays = useCallback(
    (wds: WeekdayEnum[]) => {
      setSelectableWeekdays(wds)
    },
    [setSelectableWeekdays],
  )

  return (
    <Fragment>
      <Modal ref={semesterSelectModal.ref}>
        <DispatchSemesterSelect
          onSubmit={onSubmitSelectSemester}
          onCancel={hideSelectSemesterModal}
          onChangeSelectableWeekdays={onChangeSelectableWeekdays}
        />
      </Modal>
      <Container>
        <Modal ref={existCheckModalRef.ref}>
          <DispatchPeriodAddExistModal
            dispatchPeriodExists={dispatchPeriodExists}
            onSubmit={onConfirmExistModal}
            onCancel={hideExistCheckModal}
          />
        </Modal>

        <ConfirmModal
          ref={confirmModalRef.ref}
          header={'배차 기간 등록 확인'}
          content={'배차 기간을 등록하시겠습니까?'}
          onSubmit={checkDispatchPeriodExists}
        />
        <ConfirmModal
          ref={cancelConfirmModalRef.ref}
          header={'배차 기간 등록 취소'}
          content={
            '작성중인 내용은 저장되지 않습니다.\n배차 기간 등록을 취소 하시겠습니까?'
          }
          onSubmit={onCancel}
        />

        <StyledHeader>배차 기간 등록</StyledHeader>
        <DispatchPeriodHeaderInfo />
        <InputsContainer>
          <DispatchPeriodSemesterPicker
            selectedSemester={selectedSemester}
            showSemesterSelectModal={showSemesterSelectModal}
          />
          <DispatchPeriodInputContainer
            ref={inputContainerRef.ref}
            selectedSemester={selectedSemester}
            selectableWeekdays={selectableWeekdays}
            onChangeInformation={onChangeInformation}
            defaultData={null}
            hasData={false}
            readOnly={false}
          />
        </InputsContainer>
        <Footer
          submitText={'등록'}
          onSubmit={showConfirmModal}
          onCancel={showCancelConfirmModal}
          isSufficient={isSufficient}
        />
      </Container>
    </Fragment>
  )
}

const Container = styled.div`
  ${flexColumn};
  position: absolute;
  right: 0;
  width: 52rem;
  height: 100%;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  border-radius: 16px 0 0 16px;
  background-color: #f5f5f5;
`

const StyledHeader = styled(Header)`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem;
  line-height: 150%;
  font-size: 1.6rem;
  font-weight: 800;
  border-bottom: 0.1rem solid #d9d9d9;
`

const InputsContainer = styled.div`
  flex: 1;
  padding: 1.6rem;
`
