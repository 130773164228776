import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {useRecoilValue} from 'recoil'
import {Optional} from '../../type/Common'
import {academyIDState} from '../../recoil/Atom'
import ScreenContainer from '../../component/layout/ScreenContainer'
import PageHeaderWithAcademies from '../../component/common/PageHeader'
import styled from 'styled-components'
import {flexColumn} from '../../style/CommonStyle'
import StudentSearch from '../../component/student/studentSearch/StudentSearch'
import StudentList from '../../component/student/studentList/StudentList'
import {isEmptyString, isNil} from '../../util/ValidationUtil'
import {GetStudentData, getStudentsNfc} from '../../service/student/Student'
import {CStudentNfc} from '../../model/Student'

type StudentManagementProps = {}
export type StudentManagementRef = {}

const PER_PAGE = 20

function StudentManagementBase(
  props: StudentManagementProps,
  ref: ForwardedRef<StudentManagementRef>,
) {
  const selectedAcademyID = useRecoilValue<Optional<string>>(academyIDState)
  const [studentState, setStudentState] = useState<CStudentNfc[]>([])
  const [totalElements, setTotalElements] = useState<Optional<number>>(null)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [select, setSelect] = useState<string>('ID')
  const [keyword, setKeyword] = useState<string>('')

  const fetchStudentList = useCallback(() => {
    if (isNil(selectedAcademyID)) {
      return
    }

    const data: GetStudentData = {
      academyID: selectedAcademyID,
      page: currentPage,
      searchType: select,
      searchValue: keyword,
      size: PER_PAGE,
    }

    getStudentsNfc(data)
      .then(sl => {
        setStudentState(sl.students)
        setTotalElements(sl.paging.totalElements)
      })
      .catch(error => {
        throw new Error(
          `failed to get student nfc list. (data : ${JSON.stringify(
            data,
          )}, error: ${error})`,
        )
      })
  }, [selectedAcademyID, currentPage, keyword])

  useEffect(() => {
    setCurrentPage(0)
  }, [keyword, selectedAcademyID])

  useEffect(() => {
    fetchStudentList()
  }, [selectedAcademyID, currentPage, keyword, fetchStudentList])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const savedPageIdx = urlParams.get('page')

    if (savedPageIdx !== null) {
      const pageIndex = parseInt(savedPageIdx) - 1
      const timer = setTimeout(() => {
        setCurrentPage(pageIndex)
      }, 50)
      return () => clearTimeout(timer)
    } else {
      setCurrentPage(0)
    }
  }, [])

  return (
    <ScreenContainer>
      <PageHeaderWithAcademies>학생 관리</PageHeaderWithAcademies>
      <Container>
        <StudentSearch
          setSelect={setSelect}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        <StudentList
          studentState={studentState}
          PER_PAGE={PER_PAGE}
          totalElements={totalElements}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </ScreenContainer>
  )
}

const StudentManagement = forwardRef(StudentManagementBase)

export default StudentManagement

const Container = styled.ul`
  ${flexColumn};
  width: 100%;
  row-gap: 1.2rem;
  min-height: calc(100vh - 11rem);
`
